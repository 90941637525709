define('ember-cli-testing/services/mocks', ['exports', 'ember'], function (exports, _ember) {

    /**
    * A service that provides mock services and components a mechanism to register themselves
    * so that a test can reference each mock through this service.
    */
    exports['default'] = _ember['default'].Service.extend({

        components: {},
        services: {},

        /**
        * Sets a mock service as a property of the `services object`.
        *
        * @param {Object} mockService
        * @param {string} name
        */
        registerComponent: function registerComponent(mockComponent, name) {

            this.components[name] = mockComponent;
        },

        /**
        * Sets a mock service as a property of the `services object`.
        *
        * @param {Object} mockService
        * @param {string} name
        */
        registerService: function registerService(mockService, name) {

            this.services[name] = mockService;
        },

        /**
        * Resets all of the mock services and components.
        */
        reset: function reset() {
            var _this = this;

            var mockServices = Object.keys(this.services).map(function (name) {
                return _this.services[name];
            });

            // Call `reset()` on services, since they are singletons.
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = mockServices[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var mockService = _step.value;

                    if (mockService.reset) {
                        mockService.reset();
                    }
                }

                // Clear out components, since they are not singletons.
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator['return']) {
                        _iterator['return']();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            this.components = {};
        }
    });
});