define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/jira/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/jira/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const ISSUE_CREATED = 'jira:issue_created';
    const ISSUE_UPDATED = 'jira:issue_updated';
    const I18N_BASE = 'chat.jira.';

    const JiraWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-jira'],
        layout: _template.default,

        expanded: false,

        webhooksHeader: _ember.default.computed(function () {
            const intl = this.get('intl');
            const widget = this.get('message.widget.data');
            var type;
            if (widget.notificationType === ISSUE_CREATED) {
                type = intl.t(I18N_BASE + 'issueCreated');
            } else if (widget.notificationType === ISSUE_UPDATED) {
                type = intl.t(I18N_BASE + 'issueUpdated');
            }
            return intl.t(I18N_BASE + 'issueHeader', {
                type: type,
                key: widget.issueKey,
                summary: widget.issueSummary
            });
        }),

        webhooksTitle: _ember.default.computed(function () {
            const intl = this.get('intl');
            const widget = this.get('message.widget.data');
            if (widget.notificationType === ISSUE_CREATED) {
                return intl.t(I18N_BASE + 'issueCreatedTitle', {
                    creator: widget.issueCreator,
                    url: widget.issueUrl,
                    key: widget.issueKey,
                    project: widget.project
                });
            } else if (widget.notificationType === ISSUE_UPDATED) {
                return intl.t(I18N_BASE + 'issueUpdatedTitle', {
                    updater: widget.issueUpdater,
                    url: widget.issueUrl,
                    key: widget.issueKey,
                    project: widget.project
                });
            }
            return '';
        }),

        webhooksDetails: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return this.get('intl').t(I18N_BASE + 'issueDetails', {
                summary: widget.issueSummary,
                type: widget.issueType,
                status: widget.issueStatus
            });
        }),

        jiraCreator: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return this.get('intl').t(I18N_BASE + 'issueCreator', {
                creator: widget.issueCreator
            });
        }),

        actions: {
            toggleExpanded() {
                this.toggleProperty('expanded');
            }
        }
    });

    exports.default = JiraWidget;
});