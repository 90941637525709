define("ember-purecloud-components/utils/jid-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compareJids = compareJids;
  _exports.getEntityObjectId = getEntityObjectId;
  _exports.isAcdJid = isAcdJid;
  _exports.isDirectoryId = isDirectoryId;
  _exports.isGroupJid = isGroupJid;
  _exports.isMeetingJid = isMeetingJid;
  _exports.isPersonJid = isPersonJid;
  _exports.isRoomJid = isRoomJid;
  _exports.isScreenRecordingJid = isScreenRecordingJid;
  _exports.isSoftphoneJid = isSoftphoneJid;
  _exports.isSupervisorJid = isSupervisorJid;

  function compareJids(left, right) {
    return left === right || getEntityObjectId(left) === getEntityObjectId(right);
  }

  function getEntityObjectId(jid) {
    if (!jid) {
      return '';
    }

    var jidSplit = jid.split('@');
    return jidSplit ? jidSplit[0] : null;
  }

  function isDirectoryId(id) {
    if (!id) {
      return false;
    }

    return !!id.match(/^[a-f0-9]{24}$/);
  }

  function isPersonJid(jid) {
    if (typeof jid !== 'string') {
      return false;
    }

    return jid && !jid.match(/@conference/);
  }

  function isRoomJid(jid) {
    if (typeof jid !== 'string') {
      return false;
    }

    return jid && !!jid.match(/@conference/);
  }

  function isGroupJid(jid) {
    if (typeof jid !== 'string') {
      return false;
    }

    return jid && !jid.startsWith('conference-') && !jid.startsWith('videoconference-') && !!jid.match(/[a-f0-9]{24}@conference/);
  }

  function isSupervisorJid(jid) {
    return jid && jid.startsWith('supervisorassistance');
  }

  function isAcdJid(jid) {
    return jid && jid.startsWith('acd-');
  }

  function isScreenRecordingJid(jid) {
    return jid && jid.startsWith('screenrecording-');
  }

  function isMeetingJid(jid) {
    return jid && jid.startsWith('meeting-');
  }

  function isSoftphoneJid(jid) {
    if (!jid) {
      return false;
    }

    return !!jid.match(/.*@.*gjoll.*/i);
  }
});