define('ember-chat-components/components/chat-panel/roster/room-entry/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/roster/room-entry/template', 'ember-purecloud-components/utils/display-geolocation'], function (exports, _ember, _template, _displayGeolocation) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed, inject, observer } = _ember.default; /* eslint-disable ember/use-brace-expansion */

    const BADGE_TOGGLE_ID = 'gcucc.ui.chatBadging';

    exports.default = Component.extend({
        classNames: ['room-entry'],
        classNameBindings: ['active:active:clickable', 'hasUnseenActivity:unread-messages'],
        layout: _template.default,

        chat: inject.service(),
        timeline: inject.service(),
        sidebar: inject.service(),
        session: inject.service(),
        intl: inject.service(),
        volt: inject.service(),

        includeCloseButton: false,
        showActiveState: true,
        showUnreadBadge: true,

        popout: null,

        joinError: computed.reads('room.joinError'),

        mentions: computed.reads('room.mentions'),
        unseen: computed.reads('room.unseen'),
        hasUnseenActivity: computed.or('mentions', 'unseen'),

        participantCount: computed.reads('room.participants.length'),
        targetPresence: computed.reads('room.oneToOneTarget.activityPresenceClass'),

        active: computed.and('room.active', 'showActiveState'),
        entity: computed.or('room.oneToOneTarget', 'room.group'),

        alwaysPopoutVideo: computed.reads('volt.shouldPopout'),

        showFavorite: computed(function () {
            return true;
        }),

        badgeCount: computed('room', 'unseen', 'mentions', function () {
            const count = this.get('room').isOneToOneRoom() ? this.get('unseen') : this.get('mentions');

            return count > 9 ? '9+' : count;
        }),

        // eslint-disable-next-line ember/no-observers
        sendRosterPosition: observer('hasUnseenActivity', function () {
            let id = this.get('elementId');
            let type = this.get('room').isOneToOneRoom() ? 'people' : 'groups';

            if (this.get('hasUnseenActivity')) {
                this.get('setRoomPosition')(id, type);
            } else {
                this.get('setRoomPosition')(id, type, 'remove');
            }
        }).on('didInsertElement'),

        // eslint-disable-next-line ember/no-observers
        checkScroll: observer('room.{active,activated}', function () {
            _ember.default.run.scheduleOnce('afterRender', this, this._scrollIntoView);
        }).on('init'),

        location: computed('entity.geolocation', function () {
            return (0, _displayGeolocation.default)(this.get('entity'), this.get('session.person'));
        }),

        authenticatedUserIsRoomOwner: computed.readOnly('room.authenticatedUserIsRoomOwner'),

        subject: computed('room.{oneToOneTarget.state,subject}', function () {
            const state = this.get('room.oneToOneTarget.state');

            if (state && state.toLowerCase() === 'deleted') {
                return `${this.get('room.subject')} - ${this.get('intl').t('item.state.inactive')}`;
            }
            return this.get('room.subject');
        }),

        displayIntegrationBadge: _ember.default.computed('session.features', 'room.oneToOneTarget', function () {
            return this.get('session.features')[BADGE_TOGGLE_ID] && this.get('room.oneToOneTarget');
        }),

        fallbackToRoom: computed('room', 'entity', function () {
            const entity = this.get('entity');
            if (!entity) {
                return true;
            }
            // The entity came over some kind of request that could not
            // be fulfilled. This happens when you dont have permissions
            // to load a group, so a placeholder was put into the store
            // by favorites service or batch-requests.
            if (!entity.get('guid') || entity.get('id') !== entity.get('guid')) {
                return true;
            }
            return false;
        }),

        actions: {
            closeRoom() {
                this.sendAction('closeRoom', this.get('room'));
            },

            openWebrtc() {
                this.sendAction('selectRoom', this.get('room'));
                this.get('sidebar').openPanel('webrtc');
            },
            checkForEnter(event) {
                if (event.code === 'Enter') {
                    _ember.default.$(event.target).trigger('click');
                }
            }
        },

        click(event) {
            const favoriteClicked = event.target.classList.contains('favorite');
            const popout = this.get('room.popout') && !this.get('room.popout.closed');
            if (favoriteClicked) {
                return;
            }

            if (event.shiftKey && !popout) {
                this.get('chat').popoutChatRoom(this.get('room'));
            } else if (popout) {
                this.get('room.popout').focus();
            } else {
                this.sendAction('selectRoom', this.get('room'));
            }

            this.get('timeline').markRead('room', this.get('room.objectId'));
        },

        _scrollIntoView() {
            if (!this.get('active') || !this.$()) {
                return;
            }

            const $el = this.$();
            const $parent = $el.parent();

            if ($el.position().top > $parent.height() + $parent.scrollTop() || $el.position().top < 0) {
                let scrollTop = $parent.scrollTop() + $el.position().top;

                $parent.animate({ scrollTop });
            }
        }
    });
});