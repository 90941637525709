define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/default/component', ['exports', 'ember', 'ember-purecloud-components/utils/dates', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/default/template'], function (exports, _ember, _dates, _baseWidgetComponent, _template) {
    'use strict';

    exports.__esModule = true;


    const { inject } = _ember.default;

    const INTERNAL_DL_MATCHER = /\/api\/v[12]\/downloads\/([\w-+]+)\/.*$/i;

    const DefaultWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-default'],
        layout: _template.default,

        chat: inject.service(),
        application: inject.service(),
        session: inject.service(),
        permissions: inject.service(),
        logger: inject.service('stash-logger'),
        ajax: inject.service(),

        message: null,
        isShowingModal: false,
        showLoader: false,

        editTooltipConstraints: null,

        showLocalTime: false,

        requestEditPrevious: () => {}, // passed down via conversation-pane

        init() {
            this._super(...arguments);

            this.set('editTooltipConstraints', [{
                to: 'scrollParent',
                attachment: 'together',
                pin: ['left', 'right']
            }]);

            // On initial component render, the chat message could have been editable.  Store
            // this value so that during teardown of the component we can clean up resources.
            if (this.get('message.isEditable')) {
                this.set('wasEditable', true);
            }
        },
        didRender() {
            this._super(...arguments);

            /**
             * When a chat message is displayed, it goes a markdown process which wraps the plain text in html elements.
             * The majority as can be seen cover most of them. In this part we inject dir="auto" attribute to those elements,
             * so RTL languages would be supported.
             * this is done identically in "inline-reply" and "search-pane" components
             * refer to https://www.markdownguide.org/basic-syntax/ and https://www.markdownguide.org/basic-syntax/
             * for more information.
             */
            const markdownedElements = this.element.querySelectorAll('p, li, h1, h2, h3, h4, h5, h6');
            markdownedElements.forEach(element => {
                element.setAttribute('dir', 'auto');
            });

            // only parse our download attachment links
            const anchors = this.element.querySelectorAll('a');
            anchors.forEach(a => {
                const hrefAttr = a.getAttribute('href');
                if (hrefAttr) {
                    const matches = INTERNAL_DL_MATCHER.exec(hrefAttr);
                    if (matches && matches[1]) {
                        a.removeAttribute('href');
                        a.removeAttribute('rel');
                        a.removeAttribute('target');
                        // overwrite onclick behavior to fetch download link
                        a.onclick = async evt => {
                            const id = matches[1];
                            const newHref = this.get('application').pcV2Uri(['api/v2/downloads', id]);
                            await this.fetchLink(newHref, hrefAttr);
                            // prevent default behavior
                            evt.preventDefault();
                            return false;
                        };
                    }
                }
            });
        },

        colors: _ember.default.computed('message.messageDisplay.raw', function () {
            const raw = this.get('message.messageDisplay.raw');
            if (!raw) {
                return null;
            }
            const tokens = raw.split(' ').map(token => {
                return token.match(/(#[0-9A-F]{6}$)/gi);
            });
            const matches = (tokens || []).filter(token => !!token).flat();
            if (matches.length > 0) {
                return [...new Set(matches)].map(hex => ({ hex, copied: false }));
            }
            return null;
        }),

        didEditMessage: _ember.default.computed('message.isCorrection', 'isAttachmentDeleted', function () {
            return this.get('message.isCorrection') && !this.get('isAttachmentDeleted');
        }),

        isAttachment: _ember.default.computed.notEmpty('message.files'),

        canDeleteAttachment: _ember.default.computed('isAttachment', 'permissions.canDeleteFiles', 'message.{isYou,isCorrection,room.group}', function () {
            if (!this.get('isAttachment')) {
                return _ember.default.RSVP.resolve(false);
            }

            // If you have the permission to delete chat attachments.
            if (this.get('permissions.canDeleteFiles')) {
                return _ember.default.RSVP.resolve(true);
            }

            // If the message sender === the signed in user && has an attachment without a message correction (!deleted yet).
            if (this.get('message.isYou') && this.get('isAttachment') && !this.get('message.isCorrection')) {
                return _ember.default.RSVP.resolve(true);
            }

            // If signed in user === group owner
            const group = this.get('message.room.group');
            if (group) {
                return group.reload().then(() => {
                    const owners = group.get('owners.owners');
                    const filteredOwners = owners ? owners.filterBy('guid', this.get('session.person.id')) : [];
                    return filteredOwners.length > 0;
                });
            }

            return _ember.default.RSVP.resolve(this.get('session.person.isAdmin'));
        }),

        isAttachmentDeleted: _ember.default.computed('isAttachment', 'message.{isCorrection,correction.files,isDeletedAttachment}', function () {
            const files = this.get('messages.correction.files');
            // store boolean check for messages that are not using in-place edits
            const attachmentIsDeleted = this.get('isAttachment') && this.get('message.isCorrection') && (!files || files.length === 0);
            return attachmentIsDeleted || this.get('message').get('isDeletedAttachment');
        }),

        attachmentDeletedTime: _ember.default.computed('message.correction.time', 'isAttachmentDeleted', 'showLocalTime', function () {
            if (this.get('showLocalTime')) {
                return (0, _dates.default)().format('l');
            }
            const time = this.get('message.correction.time');
            if (this.get('isAttachmentDeleted') && time) {
                return (0, _dates.default)(time).format('l');
            }
            return null;
        }),

        /**
         * Fetch our download/attachment links through Public API
         * @param {string} url 
         */
        async fetchLink(url, oldUrl) {
            const urlObj = new URL(url);
            // clear the existing params
            urlObj.search = '';
            urlObj.searchParams.append('issueRedirect', false);
            urlObj.searchParams.append('redirectToAuth', false);
            let openOldUrl = false;
            try {
                const response = await this.get('ajax').ajaxGet(urlObj.toString());
                if (response.url) {
                    window.open(response.url, '', 'dependent=1,location=0');
                } else {
                    this.get('logger').warn('<chat-attachment> Fetching attachment did not have url!');
                    openOldUrl = true;
                }
            } catch (err) {
                this.get('logger').error('<chat-attachment> Fetching attachment failed!', err);
                openOldUrl = true;
            }
            if (openOldUrl) {
                this.get('logger').debug('<chat-attachment> Opening old url: ', oldUrl);
                window.open(oldUrl, '', 'dependent=1,location=0');
            }
        },

        actions: {
            performEdit() {
                if (this.get('message.canEdit')) {
                    this.requestEditPrevious();
                }
            },
            showModal() {
                this.set('isShowingModal', true);
            },

            closeModal() {
                this.set('isShowingModal', false);
            },

            deleteAttachment() {
                this.set('showLoader', true);

                const attachment = this.get('message.files.firstObject');
                this.get('chat').removeAttachment(attachment).then(() => {
                    this.set('showLocalTime', true);
                }).catch(err => {
                    if (err) {
                        _ember.default.Logger.error('chat-attachment-delete: could not delete the attachment', {
                            error: err
                        });
                    }
                }).finally(() => {
                    this.set('isShowingModal', false);
                    this.set('showLoader', false);
                });
            }
        }
    });

    exports.default = DefaultWidget;
});