define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/inline-image/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/inline-image/template', 'ember-purecloud-components/utils/net'], function (exports, _ember, _template, _net) {
    'use strict';

    exports.__esModule = true;


    const { computed, Component } = _ember.default;

    const INTERNAL_DL_MATCHER = /\/api\/v[12]\/downloads\/([\w-+]+)\/.*$/i;

    const InlineImageComponent = Component.extend({
        classNames: ['media-item-inline-image'],
        session: _ember.default.inject.service(),
        application: _ember.default.inject.service(),
        ajax: _ember.default.inject.service(),
        logger: _ember.default.inject.service('stash-logger'),

        layout: _template.default,
        mediaItem: null,

        embeddedUrl: computed('mediaItem.url', function () {
            const url = this.get('mediaItem.url');
            try {
                // Only transform internal PC urls
                if (!_net.default.isExternalDomain(url)) {
                    const matches = INTERNAL_DL_MATCHER.exec(url);
                    if (matches && matches[1]) {
                        const id = matches[1];
                        return `${this.get('application').pcV2Uri(['api/v2/apps/downloads', id])}?contentDisposition=INLINE`;
                    }
                }
            } catch (ignored) {
                // Failed to check URL, returning unmodified url below
            }

            return url;
        }),

        didInsertElement() {
            this._super(...arguments);
            this.scrollOnLoad();
        },

        actions: {
            async processImageClick() {
                const isGCImage = this.get('embeddedUrl').indexOf('api/v2/apps/downloads') > -1;
                if (isGCImage) {
                    const urlObj = new URL(this.get('mediaItem.url'));
                    // clear the existing params
                    urlObj.search = '';
                    urlObj.searchParams.append('issueRedirect', false);
                    urlObj.searchParams.append('redirectToAuth', false);
                    let useOldUrl = false;
                    try {
                        const response = await this.get('ajax').ajaxGet(urlObj.toString());
                        if (response.url) {
                            window.open(response.url, '', 'dependent=1,location=0');
                        } else {
                            useOldUrl = true;
                            this.get('logger').warn('<inline-image> Fetching image did not have url!');
                        }
                    } catch (err) {
                        useOldUrl = true;
                        this.get('logger').error('<inline-image> Fetching image failed!', err);
                    }
                    if (useOldUrl) {
                        const oldUrl = this.get('mediaItem.url');
                        this.get('logger').debug('<inline-image> Opening old url: ', oldUrl);
                        window.open(oldUrl, '', 'dependent=1,location=0');
                    }
                } else {
                    this.get('logger').error('<inline-image> not a download url!', {
                        embeddedUrl: this.get('embeddedUrl'),
                        mediaItemUrl: this.get('mediaItem.url')
                    });
                }
                return false;
            }
        },

        scrollOnLoad() {
            if (!this.get('mediaItem.collapsed')) {
                this.$('img').on('load', () => {
                    this._safeScroll();
                }).on('error', () => {
                    this._safeScroll();
                });
            }
        },

        _safeScroll() {
            _ember.default.run(() => {
                _ember.default.run.scheduleOnce('afterRender', () => {
                    this.bubbleAction({
                        action: 'safeScroll'
                    });
                });
            });
        }
    });

    exports.default = InlineImageComponent;
});