define("ember-purecloud-components/components/entity-image/component", ["exports", "ember-purecloud-components/components/entity-image/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var placeholderImages = {
    isInitialized: false,
    person: '',
    group: '',
    location: '',
    floor: ''
  };

  function placeholderImage(entity) {
    if (!placeholderImages.isInitialized) {
      var baseUri = this.get('applicationUrlPrefix') + 'assets/images/svg/';
      placeholderImages.person = baseUri + 'person.svg';
      placeholderImages.group = baseUri + 'group.svg';
      placeholderImages.location = baseUri + 'location.svg';
      placeholderImages.floor = baseUri + 'floor.svg';
      placeholderImages.queue = baseUri + 'queue.svg';
      placeholderImages.isInitialized = true;
    }

    var type;

    if (entity && entity.get && typeof entity.get === 'function') {
      type = entity.get('type');

      if (!type) {
        type = 'person';
      }
    } else {
      type = 'person';
    }

    return placeholderImages[type];
  }

  var isNameLatin = /^[\w\s'"-,.]+$/i;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-image'],
    classNameBindings: ['chatPresenceClass', 'microCard', 'showPresenceAndChatEnabled:chat-presence', 'isPlaceholderImageAndUseInitials:show-initials', 'entityIsContact:external-contact'],
    presenceSubscription: Ember.inject.service(),
    // parameters used by entity image
    entity: null,
    size: 200,
    type: 'profile',
    isLinkable: false,
    applicationUrlPrefix: '',
    subscribeToPresence: false,
    showStatusQuote: false,
    imageLoadError: false,
    isPlaceholderImage: true,
    showPresence: false,
    canViewChat: true,
    canViewVideo: true,
    // parameters passed through to entity hover card
    self: null,
    showFavorite: true,
    useInitialsPlaceholder: false,
    enableMiniCard: false,
    isChatEnabled: false,
    phoneServiceEnabled: false,
    phoneAvailable: false,
    isOneToOneOfflineOrBusy: true,
    isGeolocationEnabled: false,
    videoTooltipTitle: '',
    isVideoButtonEnabled: false,
    // entity image logic
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this._managePresenceSubscription);
    },
    callEntity: function callEntity() {},
    toggleFavorite: function toggleFavorite() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var $img = this.$("#".concat(this.get('uniqueId')));

      if ($img && $img.length) {
        $img.on('load', function () {
          var func = _this.get('componentUpdated');

          if (func && typeof func === 'function') {
            Ember.run(function () {
              return _this.get('componentUpdated')();
            });
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removePresenceSubscription();

      var $img = this.$("#".concat(this.get('uniqueId')));

      if ($img && $img.length) {
        $img.off();
      }

      if (this.get('_animationRun')) {
        Ember.run.cancel(this.get('_animationRun'));
      }
    },
    managePresenceSubscription: Ember.observer('isChatEnabled', 'entity.type', 'entity.jid', 'subscribeToPresence', function () {
      Ember.run.once(this, this._managePresenceSubscription);
    }),
    displayStatusQuote: Ember.computed.and('showPresenceAndChatEnabled', 'showStatusQuote', 'entity.hasChatStatus'),
    chatPresence: Ember.computed.reads('entity.activityPresence'),
    chatPresenceClass: Ember.computed.reads('entity.activityPresenceClass'),
    uniqueId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-entity-image");
    }),
    entityType: Ember.computed('entity.type', function () {
      return "entity-".concat(this.get('entity.type'));
    }),
    linkToProfileRoute: Ember.computed('entity.type', function () {
      if (this.get('entity.type') === 'person') {
        return 'person.detail';
      } else if (this.get('entity.type') === 'group') {
        return 'group.detail';
      }

      return 'person.detail';
    }),
    imageUrl: Ember.computed('entity', 'entity.isLoaded', 'entity.profilePicture', 'entity.profilePictureSizes', function () {
      if (this.get('type') === 'profile') {
        return this.getProfilePicture();
      } else {
        return '';
      }
    }),
    name: Ember.computed('entity.name', function () {
      return this.get('entity.name');
    }),
    nameInitials: Ember.computed('initialsPlaceholderSupported', 'entity.name', function () {
      var name = Ember.$.trim(this.get('entity.name'));

      if (this.get('initialsPlaceholderSupported') && name) {
        var commaIndex = name.indexOf(',') > -1;
        var spaceIndex = name.indexOf(' ') > -1;
        var leftInitial = name[0].toUpperCase();
        var rightInitial = name.charAt(name.indexOf(' ') + 1).toUpperCase();

        if (commaIndex) {
          return rightInitial + leftInitial;
        } else if (!spaceIndex) {
          return leftInitial;
        } else {
          return leftInitial + rightInitial;
        }
      }

      return null;
    }),
    initialsPlaceholderSupported: Ember.computed('entity.name', 'entity.type', function () {
      var name = this.get('entity.name');

      if (name) {
        return isNameLatin.test(name) || this.get('entity.type') === 'externalContact';
      }

      return false;
    }),
    isPlaceholderImageAndUseInitials: Ember.computed('useInitialsPlaceholder', 'isPlaceholderImage', 'nameInitials', function () {
      return this.get('useInitialsPlaceholder') && this.get('isPlaceholderImage') && !!this.get('nameInitials');
    }),
    _animationRun: null,
    _currentPresence: null,
    isStatusAnimating: false,
    animateOnPresenceChange: Ember.observer('chatPresence', function () {
      var _this2 = this;

      var nextPresence = this.get('chatPresence');
      var prevPresence = this.get('_currentPresence');
      this.set('_currentPresence', nextPresence);

      if (nextPresence !== prevPresence) {
        this.set('isStatusAnimating', true);
        var animationPeriod = 1250;

        if (this.get('_animationRun')) {
          Ember.run.cancel(this.get('_animationRun'));
        }

        this.set('_animationRun', Ember.run.later(function () {
          if (!_this2.isDestroying && !_this2.isDestroyed) {
            _this2.set('isStatusAnimating', false);

            _this2.set('_animationRun', null);
          }
        }, animationPeriod));
      }
    }),
    showPresenceAndChatEnabled: Ember.computed('isChatEnabled', 'showPresence', 'entity.type', function () {
      return this.get('isChatEnabled') && this.get('showPresence') && this.get('entity.type') === 'person';
    }),
    entityIsContact: Ember.computed('entity.type', function () {
      return this.get('entity.type') === 'externalContact';
    }),
    entityDeleted: Ember.computed('entity.state', function () {
      var state = this.get('entity.state');
      return state && state.toLowerCase() === 'deleted';
    }),
    placeholderImage: placeholderImage,
    getProfilePicture: function getProfilePicture(size) {
      var profilePicture = this.get('entity.profilePicture');
      var deviceSize = this.sizeForDevice(size);

      if (!Ember.isEmpty(profilePicture)) {
        Ember.run.scheduleOnce('afterRender', this, this.modifyPlaceholderImage, false);
        var exactMatch = profilePicture["x".concat(deviceSize)];

        if (exactMatch) {
          return exactMatch;
        }
      }

      Ember.run.scheduleOnce('afterRender', this, this.modifyPlaceholderImage, true);
      return this.placeholderImage(this.get('entity'));
    },
    modifyPlaceholderImage: function modifyPlaceholderImage(value) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.set('isPlaceholderImage', value);
    },
    sizeForDevice: function sizeForDevice(size) {
      size = size || +this.get('size');
      var profilePicture = this.get('entity.profilePicture');
      var sizes = this.get('entity.profilePictureSizes');

      if (profilePicture && sizes) {
        // Note: window.devicePixelRatio changes based on which monitor the browser is on in a multi-monitor setup.
        var ratio = window.devicePixelRatio > 0 ? window.devicePixelRatio : 1;
        var deviceSize = size * ratio;
        (sizes || []).forEach(function (s) {
          if (s >= deviceSize) {
            size = s;
            return false;
          }
        });
      }

      return size;
    },
    _removePresenceSubscription: function _removePresenceSubscription() {
      if (this.get('isChatEnabled') && this.get('subscribeToPresence')) {
        this.get('presenceSubscription').autoSubscribe(null, this);
      }
    },
    _managePresenceSubscription: function _managePresenceSubscription() {
      if (this.get('isChatEnabled') && this.get('subscribeToPresence') && this.get('entity.type') === 'person' && this.get('entity.jid')) {
        this.get('presenceSubscription').autoSubscribe([this.get('entity')], this);
      }
    }
  });

  _exports.default = _default;
});