define('ember-cli-testing/services/mock-service', ['exports', 'ember', 'sinon'], function (exports, _ember, _sinon) {
    exports.createMockFromService = createMockFromService;

    /*jshint loopfunc: true */

    /**
    * A mock implementation of a service created by `createMockForService`. Each mock method is
    * wrapped in a sinon spy and also has additional properties for specifying the responses it returns:
    *
    * @property {Function} mock[methodName]                 - Mock implementation of a service's method, wrapped in a sinon spy.
    * @property {Array}    mock[methodName].responses       - Each time the method is invoked, a response is popped from the array.
    * @property {*}        mock[methodName].defaultResponse - What the method returns if `responses` is empty (default: {}).
    * @property {Function} mock.reset                       - Resets all of the methods' spies and responses.
    */
    var MockService = _ember['default'].Service.extend({

        namesOfMethodsSpiedOn: [],

        reset: function reset() {
            var _this = this;

            this.get('namesOfMethodsSpiedOn').forEach(function (methodName) {
                _this[methodName].reset();
                _this[methodName].responses = [];
                _this[methodName].defaultResponse = {};
            });
        }
    });

    exports['default'] = MockService;

    /**
    * Recursively gets all of the 'public' method names for an object and its prototype chain.
    */
    function _getPublicMethodNames(_x3) {
        var _arguments = arguments;
        var _again = true;

        _function: while (_again) {
            var instance = _x3;
            _again = false;
            var methodNames = _arguments.length <= 1 || _arguments[1] === undefined ? [] : _arguments[1];

            // Don't include Ember.Object's methods.
            var methodNamesToIgnore = Object.keys(Object.getPrototypeOf(_ember['default'].Object.create()));

            var prototype = Object.getPrototypeOf(instance);

            if (!prototype) {
                return methodNames;
            }

            methodNames = methodNames.concat(Object.keys(prototype).filter(function (propertyName) {
                var propertyValue = prototype[propertyName];
                return typeof propertyValue === 'function' && propertyName[0] !== '_' && !methodNamesToIgnore.includes(propertyName);
            }));

            _arguments = [_x3 = prototype, methodNames];
            _again = true;
            methodNames = methodNamesToIgnore = prototype = undefined;
            continue _function;
        }
    }

    /**
    * Recursively gets all of the 'public' properties (excluding functions) for an object and its prototype chain.
    */
    function _getPublicProperties(_x4) {
        var _arguments2 = arguments;
        var _again2 = true;

        _function2: while (_again2) {
            var instance = _x4;
            _again2 = false;
            var properties = _arguments2.length <= 1 || _arguments2[1] === undefined ? {} : _arguments2[1];

            // Don't include Ember.Object's properties.
            var propertyNamesToIgnore = Object.keys(Object.getPrototypeOf(_ember['default'].Object.create()));
            // Due to an Ember 2.10 change, we should ignore these as well
            propertyNamesToIgnore.push('isDestroyed', 'isDestroying');

            var prototype = Object.getPrototypeOf(instance);

            if (!prototype) {
                return properties;
            }

            for (var propertyName in prototype) {
                var propertyValue = prototype[propertyName];
                if (typeof propertyValue !== 'function' && propertyName[0] !== '_' && !propertyNamesToIgnore.includes(propertyName) && properties[propertyName] === undefined) {

                    properties[propertyName] = propertyValue;
                }
            }

            _arguments2 = [_x4 = prototype, properties];
            _again2 = true;
            properties = propertyNamesToIgnore = prototype = propertyName = propertyValue = undefined;
            continue _function2;
        }
    }

    /**
    * Generates a MockService for the given Ember.Service subclass. A mock implementation is included for each
    * of the given service's public methods and the mock also includes copies of the service's public properties.
    *
    * @param   {Class} serviceClass                         - Subclass of Ember.Service
    * @returns {Class} mockClass                            - Subclass of MockService
    *
    * @example - Creating a mock
    * const AccountService = Ember.Service.extend({
    *    fetch() {
    *        // Do real stuff in the real implementation.
    *    }
    * });
    *
    * export default createMockFromService(AccountService);
    *
    * @example - Using the mock in a test's setup (assumes the mock is available as mocks.accounts)
    * mocks.accounts.fetch.responses.push({ id: '000', name: 'I3'}); // Add the response for the first request
    * mocks.accounts.fetch.responses.push({ id: '001', name: 'I4'}); // Add the response for the second request
    * mocks.accounts.fetch.defaultResponse({ id: '005', name: 'I6'}) // Set a default response for subsequent requests.
    * // later on:
    * expect(mocks.accounts.fetch.callCount).to.equal(3);
    */

    function createMockFromService(serviceClass) {

        // I found that when trying to get the prototype from serviceClass.prototype, that prototype would not
        // possess all of its properties until *after* an instance was created with `create()`. So, we get
        // the prototype from an instance here just to be safe.
        var instance = serviceClass.create(),
            mock = {};

        var methodNames = _getPublicMethodNames(instance);

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            var _loop = function () {
                var methodName = _step.value;

                mock[methodName] = _sinon['default'].spy(function () {
                    var response = mock[methodName].responses.length ? mock[methodName].responses.pop() : mock[methodName].defaultResponse;
                    return _ember['default'].RSVP.Promise.resolve(response);
                });

                mock[methodName].responses = [];
                mock[methodName].defaultResponse = {};
            };

            for (var _iterator = methodNames[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                _loop();
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator['return']) {
                    _iterator['return']();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        mock.namesOfMethodsSpiedOn = methodNames;
        Object.assign(mock, _getPublicProperties(instance));

        return MockService.extend(mock);
    }
});