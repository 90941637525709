define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/salesforce/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/salesforce/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const SalesForceWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-salesforce'],
        layout: _template.default,

        expanded: false,

        webhooksHeader: _ember.default.computed(function () {
            return this.get('message.widget.data.title');
        }),

        webhooksTitle: _ember.default.computed(function () {
            return this.get('message.widget.data.message');
        }),

        actions: {
            toggleExpanded() {
                this.toggleProperty('expanded');
            }
        }
    });

    exports.default = SalesForceWidget;
});