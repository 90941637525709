define('ember-chat-components/components/chat-panel/conversation/conversation-pane/inline-reply/component', ['exports', 'ember', 'ember-data', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/inline-reply/template', 'ember-purecloud-components/utils/dates', 'ember-purecloud-components/utils/jid-helpers'], function (exports, _ember, _emberData, _template, _dates, _jidHelpers) {
    'use strict';

    exports.__esModule = true;


    const { inject, computed, Component } = _ember.default;

    exports.default = Component.extend({
        classNameBindings: [':inline-reply', 'message::hidden'],
        layout: _template.default,

        chat: inject.service(),
        intl: inject.service(),
        session: inject.service(),
        logger: inject.service('stash-logger'),

        message: null,
        rawMessage: null,
        messageRoom: null,
        messageUser: null,
        messageTime: null,
        roomStream: true,
        directStream: false,
        hasInitialRender: false,

        // keepMention will keep the mention on the quoted user
        // if keepMention is false, the mention is removed  (XMPP and link removed)
        keepMention: true,

        activeRoom: computed({
            get() {
                return null;
            },
            set(prop, room) {
                if (this.get('hasInitialRender')) {
                    const oldRoom = this.get('activeRoom.jid');
                    const newRoom = room != null ? room.get('jid') : void 0;
                    if (oldRoom && newRoom && newRoom !== oldRoom) {
                        _ember.default.run.once(this, this._changeToRoomAndAnimate);
                    }
                }
                return room;
            }
        }),

        didRender() {
            this._super(...arguments);
            const markdownedElements = this.element.querySelector('.messages').querySelectorAll('p, li, h1, h2, h3, h4, h5, h6');
            markdownedElements.forEach(element => {
                element.setAttribute('dir', 'auto');
            });
        },

        didInsertElement() {
            this._super(...arguments);
            this.setProperties({
                rawMessage: this.get('message.raw'),
                messageRoom: this.get('message.room'),
                messageUser: this.get('message.chatUser'),
                messageTime: this.get('message.time'),
                messageTimeStamp: this.get('message.messageTime'),
                hasInitialRender: true
            });
        },

        _changeToRoomAndAnimate() {
            if (!this.get('hasInitialRender')) {
                return;
            }
            this.resetStreamSelection();
            _ember.default.run(() => {
                this.$('.reply-options .selected').addClass('animated tada');
                _ember.default.run.later(() => {
                    const selectedStream = this.$('.reply-options .selected');
                    if (selectedStream) {
                        selectedStream.removeClass('animated tada');
                    }
                }, 1000);
            });
        },

        quoteHeader: computed('messageUser.{name,id}', 'messageTime', 'messageTimeStamp', 'keepMention', function () {
            const intl = this.get('intl');
            const name = this.get('messageUser.name');
            const jid = this.get('messageUser.jid');
            const id = (0, _jidHelpers.getEntityObjectId)(jid) || this.get('messageUser.id');
            const time = this.get('messageTime');
            const timeStamp = this.get('messageTimeStamp');
            const room = this.get('messageRoom');

            const isToday = (0, _dates.default)().isSame(time, 'day');
            const isDate = !isToday;
            const isPerson = room.isOneToOneRoom();

            let promise = _ember.default.RSVP.resolve(room.getRawDisplaySubject());
            if (isPerson) {
                // Can't even right now...
                const to = this.get('message.to') || _ember.default.get(this.get('message'), 'to');

                // Perform a JID lookup
                promise = this.get('store').findMany('person', [to]).then(people => {
                    if (people && people.length > 0) {
                        const person = people[0];
                        return person.get('name');
                    }
                    return room.getRawDisplaySubject();
                }).catch(() => room.getRawDisplaySubject());
            }
            return _emberData.default.PromiseObject.create({
                promise: promise.then(subject => {
                    const timeSegment = isDate ? 'Date' : 'Time';
                    const roomSegment = isPerson ? 'Person' : 'Room';
                    const key = `chat.quoteHeader${timeSegment}${roomSegment}`;
                    const quoteHeader = intl.t(key, { timeStamp, subject }).trim();

                    // if we're not keeping the mention
                    // strip out the @ symbol
                    if (!this.get('keepMention')) {
                        return `###### [${name}](#/person/${id}) _${quoteHeader}_`;
                    }
                    return `###### [@${name}](#/person/${id}) _${quoteHeader}_`;
                })
            });
        }),

        quotedMessage: computed('rawMessage', 'quoteHeader', function () {
            return _emberData.default.PromiseObject.create({
                promise: this.get('quoteHeader').then(header => {
                    const message = this.get('rawMessage');
                    const combined = `> ${header}\n${message}`;
                    return combined.replace(/\n/g, '\n>');
                })
            });
        }),

        directStreamSupported: computed('messageUser.jid', 'activeRoom.oneToOneTarget', function () {
            const quotingSelf = (0, _jidHelpers.compareJids)(this.get('messageUser.jid'), this.get('session.person.jid'));
            const roomIsOneToOne = this.get('activeRoom.oneToOneTarget.jid') === this.get('messageUser.jid');
            return !quotingSelf && !roomIsOneToOne;
        }),

        actions: {
            dismiss() {
                this.resetStreamSelection();
                this.sendAction('cancelReply');
            },

            setKeepMention(val) {
                this.set('keepMention', val);
            },

            changeStream(stream) {
                this.setStream(stream);
            },

            async sendMessage(text, room, options, isSequence) {
                if (room.get('jid') !== this.getTargetRoomJid()) {
                    try {
                        const newRoom = await this.get('chat').getChatRoom(this.getTargetRoomJid());
                        this.get('chat').changeActiveRoom(newRoom);
                        room = newRoom;
                        if (options) {
                            options.to = room.get('jid');
                        }
                    } catch (err) {
                        const logger = this.get('logger');
                        logger.error('ember-chat-components: Failed to get chat room for inline-reply when changing stream', {
                            err,
                            roomJid: room.get('jid'),
                            targetRoomJid: this.getTargetRoomJid()
                        });
                    }
                }
                return this.get('quotedMessage').then(quoted => {
                    let message = text;
                    if (!isSequence) {
                        message = `${quoted}\n\n${text}`;
                    }
                    const time = (0, _dates.default)();
                    if (!options) {
                        options = {
                            to: this.getTargetRoomJid(),
                            body: message,
                            children: []
                        };
                    }

                    // if user decides to keep the mentions
                    // add an XMPP reference
                    if (this.get('keepMention')) {
                        options.children.push({
                            name: 'reference',
                            attributes: {
                                xmlns: 'urn:xmpp:reference:0',
                                type: 'mention',
                                uri: this.get('message.from')
                            }
                        });
                    }

                    options.body = message;
                    const messagePromise = this.get('chat').sendMessageProxy(room.get('jid'), room, message, options).catch(err => {
                        this.get('chat').messageFailedToSend(room, time, err);
                    });
                    this.resetStreamSelection();
                    this.sendAction('cancelReply');
                    return messagePromise;
                });
            }
        },

        resetStreamSelection() {
            this.setStream('room');
        },

        setStream(stream) {
            this.set('roomStream', stream === 'room');
            this.set('directStream', stream === 'direct');
        },

        getTargetRoomJid() {
            if (this.get('roomStream')) {
                return this.get('activeRoom.jid');
            }
            if (this.get('directStream')) {
                return this.get('messageUser.jid');
            }
        }
    });
});