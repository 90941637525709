define('ember-engine-custom-fields/components/form-preview/component', ['exports', 'ember-engine-custom-fields/components/form-preview/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    exports.default = Component.extend({
        layout: _template.default,
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var overlay = Ember.$('.schema-overlay');

            Ember.$('.form-preview').on('keydown', this.handleKeyDown.bind(this));
            if (overlay.length > 0) {
                overlay.on('click', this.closePreview);
            }
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            Ember.$('.form-preview').off('keydown');
            Ember.$('.schema-overlay').off('click');
        },
        handleKeyDown: function handleKeyDown(event) {
            var code = event.keyCode;
            var str = keystroke.codeToString(code);

            if (keystroke.isEscape(code) || str === 'ENTER') {
                this.send('closePreview');
            }
        },

        actions: {
            closePreview: function closePreview() {
                this.set('isShowingPreview', false);
                $('.schema-overlay').addClass('hidden');
            }
        }
    });
});