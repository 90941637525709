define("ember-purecloud-components/helpers/flair", ["exports", "ember-purecloud-components/utils/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.flair = flair;

  var _flairRegEx = new RegExp(/^(flair:)(#[0-9a-f]{3}:|#[0-9a-f]{6}:){0,1}(.+)/i); // flair:[#hex:]message


  function flair(params, namedArgs) {
    var status = params[0];
    var hideIfText = namedArgs ? namedArgs.hideIfText : false;
    var color = '#aaaaaa';

    var match = _flairRegEx.exec(status);

    if (match) {
      if (match[2]) {
        color = match[2].substring(0, match[2].length - 1);
      }

      var emojiText = (0, _emoji.emojifyString)(Ember.Handlebars.Utils.escapeExpression(match[3]));
      return "<div class=\"flair\" style=\"background-color: ".concat(color, "\"> ").concat(emojiText, " </div>");
    } else if (hideIfText) {
      return '';
    } else {
      return Ember.Handlebars.Utils.escapeExpression(status);
    }
  }

  var _default = Ember.Helper.helper(flair);

  _exports.default = _default;
});