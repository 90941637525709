define('ember-custom-fields-components/models/tag', ['exports', 'ember-data/attr', 'ember-custom-fields-components/models/field', 'ember-custom-fields-components/utils/field-validation', 'ember-custom-fields-components/mixins/limits'], function (exports, _attr, _field, _fieldValidation, _limits) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Tag = _field.default.extend(_limits.default, {
        minItems: (0, _attr.default)('number'),
        maxItems: (0, _attr.default)('number'),
        minLength: (0, _attr.default)('number'),
        maxLength: (0, _attr.default)('number'),

        isItemsBoundariesValid: Ember.computed('minItems', 'maxItems', function () {
            return _fieldValidation.default.validateLengthBoundaries(this.get('minItems'), this.get('maxItems'));
        }),

        isLengthBoundariesValid: Ember.computed('minLength', 'maxLength', function () {
            return _fieldValidation.default.validateLengthBoundaries(this.get('minLength'), this.get('maxLength'));
        }),

        isMinItemsValid: Ember.computed('minItems', function () {
            return _fieldValidation.default.validateInteger(this.get('minItems'));
        }),

        isMaxItemsValid: Ember.computed('maxItems', function () {
            return _fieldValidation.default.validateInteger(this.get('maxItems'));
        }),

        isNewMinItemsLessRestrictive: Ember.computed('isNew', 'minItems', function () {
            var changedAttributes = this.changedAttributes();
            if (!this.get('isNew') && changedAttributes.minItems) {
                return _fieldValidation.default.validateNewMinValueIsLessRestrictive(this.get('minItems'), changedAttributes.minItems[0]);
            }

            return true;
        }),

        isNewMaxItemsLessRestrictive: Ember.computed('maxItems', function () {
            var changedAttributes = this.changedAttributes();
            if (!this.get('isNew') && changedAttributes.maxItems) {
                return _fieldValidation.default.validateNewMaxValueIsLessRestrictive(this.get('maxItems'), changedAttributes.maxItems[0]);
            }

            return true;
        }),

        isMinLengthValid: Ember.computed('minLength', function () {
            return _fieldValidation.default.validateInteger(this.get('minLength'));
        }),

        isMaxLengthValid: Ember.computed('maxLength', function () {
            return _fieldValidation.default.validateInteger(this.get('maxLength'));
        }),

        isNewMinLengthLessRestrictive: Ember.computed('minLength', function () {
            var changedAttributes = this.changedAttributes();
            if (changedAttributes.minLength) {
                return _fieldValidation.default.validateNewMinValueIsLessRestrictive(this.get('minLength'), changedAttributes.minLength[0]);
            }

            return true;
        }),

        isNewMaxLengthLessRestrictive: Ember.computed('maxLength', function () {
            var changedAttributes = this.changedAttributes();
            if (changedAttributes.maxLength) {
                return _fieldValidation.default.validateNewMaxValueIsLessRestrictive(this.get('maxLength'), changedAttributes.maxLength[0]);
            }

            return true;
        }),

        isConfigValid: Ember.computed('isItemsBoundariesValid', 'isLengthBoundariesValid', 'isMinItemsValid', 'isMaxItemsValid', 'isMinItemsWithinLimit', 'isMaxItemsWithinLimit', 'isNewMinItemsLessRestrictive', 'isNewMaxItemsLessRestrictive', 'isMinLengthValid', 'isMaxLengthValid', 'isMinLengthWithinLimit', 'isMaxLengthWithinLimit', 'isNewMinLengthLessRestrictive', 'isNewMaxLengthLessRestrictive', 'isTitleValid', 'isKeyValid', function () {
            return this.get('isItemsBoundariesValid') && this.get('isLengthBoundariesValid') && this.get('isMinItemsValid') && this.get('isMaxItemsValid') && this.get('isMinItemsWithinLimit') && this.get('isMaxItemsWithinLimit') && this.get('isNewMinItemsLessRestrictive') && this.get('isNewMaxItemsLessRestrictive') && this.get('isMinLengthValid') && this.get('isMaxLengthValid') && this.get('isMinLengthWithinLimit') && this.get('isMaxLengthWithinLimit') && this.get('isNewMinLengthLessRestrictive') && this.get('isNewMaxLengthLessRestrictive') && this.get('isTitleValid') && this.get('isKeyValid');
        })
    });

    exports.default = Tag;
});