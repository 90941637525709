define('ember-custom-fields-components/models/number', ['exports', 'ember-data/attr', 'ember-custom-fields-components/models/field', 'ember-custom-fields-components/utils/field-validation', 'ember-custom-fields-components/mixins/limits'], function (exports, _attr, _field, _fieldValidation, _limits) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Number = _field.default.extend(_limits.default, {
        minimum: (0, _attr.default)('number'),
        maximum: (0, _attr.default)('number'),

        isBoundariesValid: Ember.computed('minimum', 'maximum', function () {
            return _fieldValidation.default.validateNumberValueBoundaries(this.get('minimum'), this.get('maximum'));
        }),

        isMinimumValid: Ember.computed('minimum', function () {
            return _fieldValidation.default.validateNumber(this.get('minimum'));
        }),

        isMaximumValid: Ember.computed('maximum', function () {
            return _fieldValidation.default.validateNumber(this.get('maximum'));
        }),

        isNewMinimumLessRestrictive: Ember.computed('isNew', 'minimum', function () {
            var changedAttributes = this.changedAttributes();

            if (!this.get('isNew') && changedAttributes.minimum) {
                return _fieldValidation.default.validateNewMinValueIsLessRestrictive(this.get('minimum'), changedAttributes.minimum[0]);
            }

            return true;
        }),

        isNewMaximumLessRestrictive: Ember.computed('isNew', 'maximum', function () {
            var changedAttributes = this.changedAttributes();

            if (!this.get('isNew') && changedAttributes.maximum) {
                return _fieldValidation.default.validateNewMaxValueIsLessRestrictive(this.get('maximum'), changedAttributes.maximum[0]);
            }

            return true;
        }),

        isConfigValid: Ember.computed('isBoundariesValid', 'isMinimumValid', 'isMaximumValid', 'isMinimumWithinLimit', 'isMaximumWithinLimit', 'isNewMinimumLessRestrictive', 'isNewMaximumLessRestrictive', 'isTitleValid', 'isKeyValid', function () {
            return this.get('isBoundariesValid') && this.get('isMinimumValid') && this.get('isMaximumValid') && this.get('isMinimumWithinLimit') && this.get('isMaximumWithinLimit') && this.get('isNewMinimumLessRestrictive') && this.get('isNewMaximumLessRestrictive') && this.get('isTitleValid') && this.get('isKeyValid');
        })
    });

    exports.default = Number;
});