enifed('ember-template-compiler/plugins/transform-item-class', ['exports'], function (exports) {
  'use strict';

  exports.default = TransformItemClass;
  function TransformItemClass() {
    this.syntax = null;
  }

  TransformItemClass.prototype.transform = function (ast) {
    var b = this.syntax.builders;
    var walker = new this.syntax.Walker();

    walker.visit(ast, function (node) {
      var i, pair, key, value, propName, params, sexprParams, sexpr;

      if (!validate(node)) {
        return;
      }

      for (i = 0; i < node.hash.pairs.length; i++) {
        pair = node.hash.pairs[i];
        key = pair.key, value = pair.value;


        if (key !== 'itemClass') {
          return;
        }
        if (value.type === 'StringLiteral') {
          return;
        }

        propName = value.original;
        params = [value];
        sexprParams = [b.string(propName), b.path(propName)];


        params.push(b.sexpr(b.string('-normalize-class'), sexprParams));
        sexpr = b.sexpr(b.string('if'), params);


        pair.value = sexpr;
      }
    });

    return ast;
  };

  function validate(node) {
    return (node.type === 'BlockStatement' || node.type === 'MustacheStatement') && node.path.original === 'collection';
  }
});