define("@ember-decorators/utils/computed", ["exports", "@ember-decorators/utils/decorator", "@ember-decorators/utils/-private/descriptor", "@ember-decorators/utils/-private/modifier-meta"], function (_exports, _decorator, _descriptor, _modifierMeta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedDecorator = computedDecorator;
  _exports.computedDecoratorWithParams = computedDecoratorWithParams;
  _exports.computedDecoratorWithRequiredParams = computedDecoratorWithRequiredParams;
  Object.defineProperty(_exports, "computedDescriptorFor", {
    enumerable: true,
    get: function get() {
      return _descriptor.computedDescriptorFor;
    }
  });
  Object.defineProperty(_exports, "getModifierMeta", {
    enumerable: true,
    get: function get() {
      return _modifierMeta.getModifierMeta;
    }
  });
  Object.defineProperty(_exports, "getOrCreateModifierMeta", {
    enumerable: true,
    get: function get() {
      return _modifierMeta.getOrCreateModifierMeta;
    }
  });

  /**
   * A macro that receives a decorator function which returns a ComputedProperty,
   * and defines that property using `Ember.defineProperty`. Conceptually, CPs
   * are custom property descriptors that require Ember's intervention to apply
   * correctly. In the future, we will use finishers to define the CPs rather than
   * directly defining them in the decorator function.
   *
   * @param {Function} fn - decorator function
   */
  function computedDecoratorInner(fn) {
    return function (desc) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      // All computeds are methods
      desc.kind = 'method';
      desc.placement = 'prototype';

      desc.finisher = function initializeComputedProperty(target) {
        var prototype = target.prototype;
        var key = desc.key;
        (false && !(!(0, _descriptor.computedDescriptorFor)(prototype, key)) && Ember.assert("ES6 property getters/setters only need to be decorated once, '".concat(key, "' was decorated on both the getter and the setter"), !(0, _descriptor.computedDescriptorFor)(prototype, key)));
        var computedDesc = fn(desc, params);
        (false && !((0, _descriptor.isComputedDescriptor)(computedDesc)) && Ember.assert("computed decorators must return an instance of an Ember ComputedProperty descriptor, received ".concat(computedDesc), (0, _descriptor.isComputedDescriptor)(computedDesc)));
        var modifierMeta = (0, _modifierMeta.getModifierMeta)(prototype);

        if (modifierMeta !== undefined && modifierMeta[key] !== undefined) {
          computedDesc[modifierMeta[key]]();
        }

        if (!false
        /* HAS_NATIVE_COMPUTED_GETTERS */
        ) {
          // Until recent versions of Ember, computed properties would be defined
          // by just setting them. We need to blow away any predefined properties
          // (getters/setters, etc.) to allow Ember.defineProperty to work correctly.
          Object.defineProperty(prototype, key, {
            configurable: true,
            writable: true,
            enumerable: true,
            value: undefined
          });
        }

        Ember.defineProperty(prototype, key, computedDesc);

        if (false) {
          // There's currently no way to disable redefining the property when decorators
          // are run, so return the property descriptor we just assigned
          desc.descriptor = Object.getOwnPropertyDescriptor(prototype, key);
        }

        return target;
      };
    };
  }

  function computedDecorator(fn) {
    return (0, _decorator.decorator)(computedDecoratorInner(fn));
  }

  function computedDecoratorWithParams(fn) {
    return (0, _decorator.decoratorWithParams)(computedDecoratorInner(fn));
  }

  function computedDecoratorWithRequiredParams(fn, name) {
    return (0, _decorator.decoratorWithRequiredParams)(computedDecoratorInner(fn), name);
  }
});