define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/editor/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/editor/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component', 'ember-purecloud-components/utils/dates'], function (exports, _ember, _template, _baseWidgetComponent, _dates) {
    'use strict';

    exports.__esModule = true;


    const { run, inject, computed } = _ember.default;

    const EditorWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-editor'],
        layout: _template.default,

        chat: inject.service(),
        analytics: inject.service(),
        session: inject.service(),

        attrs: {
            doneEditing: () => {}
        },

        didInsertElement() {
            this._super(...arguments);

            this.scrollToBottom();
        },

        scrollToBottom() {
            run.scheduleOnce('afterRender', this, this._scrollToBottom);
        },

        _scrollToBottom() {
            const textarea = this.$('textarea').get(0);
            if (textarea) {
                textarea.scrollTop = textarea.scrollHeight;
            }
        },

        initialValue: computed('message.correction', function () {
            const correction = this.get('message.correction.raw');
            if (correction) {
                return correction;
            }
            return this.get('message.raw');
        }),

        actions: {
            cancelEditMessage() {
                this.get('doneEditing')();
            },

            sendMessageEdit(body, _, options) {
                const message = this.get('message');
                let textToCheck = message.get('raw');
                if (message.get('isCorrection')) {
                    textToCheck = message.get('correction.raw');
                }
                if (textToCheck === body) {
                    this.get('doneEditing')();
                    return _ember.default.RSVP.resolve();
                }
                if (message.get('isEditable')) {
                    const roomJid = message.get('room.jid');
                    const time = (0, _dates.default)();
                    let opts;
                    if (!options) {
                        opts = {
                            body,
                            to: roomJid,
                            corrects: message.id
                        };
                    } else {
                        // use the provided options
                        // update the corrects
                        options.corrects = message.id;
                        opts = options;
                    }

                    let messagePromise = this.get('chat').sendMessageProxy(roomJid, message.get('room'), body, opts).catch(err => {
                        this.get('chat').messageFailedToSend(message.get('room'), time, err);
                    });
                    this.get('doneEditing')();
                    return messagePromise;
                }
                this.get('doneEditing')();
            }
        }
    });

    exports.default = EditorWidget;
});