define('rate-limit-computed/debounced', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = debouncedProperty;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var debounce = run.debounce;
  var join = run.join;
  var cancel = run.cancel;

  function debouncedProperty() {

    var args = [].slice.apply(arguments);
    var rate = args.pop();
    var method = args.pop();

    var __value = null;
    var __next = null;
    var __onDestroy = false;
    var __isNotifying = false;

    var methodFn = function methodFn(key, value, oldValue) {

      if (!this.get('isDestroyed')) {
        if (!__isNotifying) {
          __isNotifying = true;
          __value = method.call(this, key, value, oldValue);
          if (!this.get('isDestroying')) {
            join(this, this.propertyDidChange, key);
          }
        } else {
          __isNotifying = false;
        }
      }
    };

    args.push(function (key, value, oldValue) {
      if (!__onDestroy) {
        var _super = this.willDestroy;
        this.willDestroy = function () {
          cancel(__next);
          _super();
        };
        __onDestroy = true;
      }
      __next = debounce(this, methodFn, key, value, oldValue, rate, false);
      return __value;
    });
    return computed.apply(this, args);
  }
});