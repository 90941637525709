define('ember-chat-components/components/chat-panel/conversation/search-result/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/search-result/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const ChatSearchResultComponent = _ember.default.Component.extend({
        classNames: ['search-result'],
        classNameBindings: ['selected'],
        layout: _template.default,

        didRender() {
            this._super(...arguments);
            const markdownedElements = this.element.querySelector('.search-result-body').querySelectorAll('p, li, h1, h2, h3, h4, h5, h6');
            markdownedElements.forEach(element => {
                element.setAttribute('dir', 'auto');
            });
        },

        click() {
            return this.sendAction('loadItem', this.get('result'));
        },

        selected: _ember.default.computed('selectedResult', function () {
            return this.get('result') && this.get('result.id') === this.get('selectedResult.id');
        })
    });

    exports.default = ChatSearchResultComponent;
});