define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/twitter-card/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/twitter-card/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, observer } = _ember.default;

    exports.default = Component.extend({
        classNames: ['twitter-card'],
        layout: _template.default,

        loaded: false,
        mediaItem: null,
        collapsed: null,

        init() {
            this._super(...arguments);

            this.loadTwitter();
        },

        loadTwitter() {
            if (window.twttr && window.twttr.widgets) {
                this.set('loaded', true);
                return;
            }

            _ember.default.$.getScript('https://platform.twitter.com/widgets.js', () => {
                this.set('loaded', true);
            });
        },

        // eslint-disable-next-line ember/no-observers
        makeTwitterCard: observer('loaded', 'collapsed', function () {
            let tweet = this.get('mediaItem');
            let id = tweet.get('tweetId');
            if (!id || !this.get('loaded') || this.get('collapsed')) {
                return;
            }
            _ember.default.run.scheduleOnce('afterRender', this, function () {
                let elem = this.$(`.tweet-container`)[0];
                window.twttr.widgets.createTweet(id, elem, {}).then(el => {
                    let handle = _ember.default.$(el).contents().find('.TweetAuthor-screenName').text();
                    if (handle) {
                        tweet.set('title', handle);
                    }
                });
            });
        }).on('init')
    });
});