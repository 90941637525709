define('ember-custom-fields-components/models/checkbox', ['exports', 'ember-custom-fields-components/models/field'], function (exports, _field) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Checkbox = _field.default.extend({});

  exports.default = Checkbox;
});