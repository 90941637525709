define('ember-user-activity/mixins/scroll-activity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scrollActivity: Ember.inject.service(),
    scrollElement: null,

    didScroll: function didScroll() {},
    // no-op function

    scrollSubscribe: Ember.on('didInsertElement', function () {
      var element = this.get('scrollElement') || this.get('element'); // Fallback to the component's DOM element
      this.get('scrollActivity').subscribe(this, element, Ember.run.bind(this, this.didScroll));
    }),

    scrollUnsubscribe: Ember.on('willDestroyElement', function () {
      this.get('scrollActivity').unsubscribe(this);
    })
  });
});