define("ember-purecloud-components/services/conversation", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_logger.default, Ember.Evented, {
    feature: Ember.inject.service(),
    pigeon: Ember.inject.service(),
    // The services required in order to use this service in your application
    // They are looked up dynamically
    store: null,
    session: null,
    activeConversations: null,
    currentUser: null,
    currentUserId: Ember.computed.reads('session.person.guid'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('activeConversations', Ember.A());
      var owner = Ember.getOwner(this);
      var store = owner.lookup('service:store');
      var session = owner.lookup('service:session');

      if (!store || !session) {
        this.logger.error('Conversation Service: Store and session service are required, but not found');
        return;
      }

      this.set('store', store);
      this.set('session', session);

      this._afterAuth();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.get('pigeon').has('my-conversations')) {
        this.get('pigeon').off('my-conversations', this, this._handleConversationUpdate);
      }
    },
    _afterAuth: Ember.observer('session.isAuthenticated', function () {
      if (!this.get('session.isAuthenticated')) {
        return;
      }

      if (!this.get('isInitialized')) {
        this.initServiceProperties();

        this._loadConversations();
      }
    }),
    initServiceProperties: function initServiceProperties() {
      this.set('activeConversations', Ember.A());
      this.get('pigeon').on('my-conversations', this, this._handleConversationUpdate);
    },
    _handleConversationUpdate: function _handleConversationUpdate(update) {
      var store = this.get('store');
      var active = this.get('activeConversations');
      this.logger.info('handling conversation update', {
        conversationId: update.id
      });
      var conversation = active.findBy('id', update.id);

      if (conversation) {
        conversation.update(update);
      } else {
        var normalized = store.normalize('conversation', update);
        conversation = store.push(normalized);
        conversation.set('userId', this.get('currentUserId'));
        active.pushObject(conversation);
        this.trigger('conversation', conversation);
      }

      var activeCommunications = this.getActiveCommunicationsOnConversationForCurrentUser(conversation);
      var hasActiveScreenRecording = conversation.get('participantsScreenRecording').findBy('userId', this.get('currentUserId'));

      if ((!activeCommunications || !Object.keys(activeCommunications).length) && !hasActiveScreenRecording) {
        active.removeObject(conversation);
        this.get('store').unloadRecord(conversation);
      }
    },
    _loadConversations: function _loadConversations() {
      var _this = this;

      if (this.get('isInitialized')) {
        return;
      }

      this.set('isInitialized', true);
      return this.get('store').findAll('conversation').then(function (data) {
        return data.content.map(function (conversation) {
          return conversation.getRecord();
        });
      }).then(function (conversations) {
        _this.set('activeConversations', Ember.A(conversations));

        conversations.forEach(function (conversation) {
          return conversation.set('userId', _this.get('currentUserId'));
        });
      }).catch(function (err) {
        _this.logger.error('Conversation Service: Unable to load conversations', {
          error: err
        });

        Ember.RSVP.reject(Ember.A());
      });
    },
    getActiveCommunicationsOnConversationForCurrentUser: function getActiveCommunicationsOnConversationForCurrentUser(conversation) {
      return conversation.get("activeCommunications.".concat(this.get('currentUserId')));
    },
    getActiveCommunicationsOnConversationForCurrentUserById: function getActiveCommunicationsOnConversationForCurrentUserById(conversationId) {
      var conversation = this.get('activeConversations').findBy('id', conversationId);
      return this.getActiveCommunicationsOnConversationForCurrentUser(conversation);
    },
    conversationsWithActiveScreenRecordingForCurrentUser: Ember.computed('activeConversations.@each.participantsScreenRecording', function () {
      var _this2 = this;

      return this.get('activeConversations').filter(function (conversation) {
        return conversation.get('participantsScreenRecording').findBy('userId', _this2.get('currentUserId'));
      });
    })
  });

  _exports.default = _default;
});