define('ember-custom-fields-components/components/custom-fields/controls/date-time/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/datetimepicker', 'ember-custom-fields-components/components/custom-fields/controls/date-time/template', 'moment'], function (exports, _datetimepicker, _template, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _datetimepicker.default.extend({
        layout: _template.default,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey) || null;

            if (savedData) {
                this.set('control.value', savedData);
            }
        },

        fieldValue: Ember.computed('control', 'fieldData', function () {
            var control = this.get('control');
            var fieldData = this.get('fieldData');

            if (control && fieldData) {
                var fieldKey = control.get('key');
                var fieldValue = fieldData[fieldKey];

                return (0, _moment.default)(fieldValue).format('lll');
            } else {
                return null;
            }
        })
    });
});