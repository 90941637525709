define('ember-engine-custom-fields/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _routes.default)(function () {
        this.route('data-models', { path: '/' }, function () {
            this.route('external-orgs');
            this.route('external-org', { path: 'external-orgs/:schema_id' });
            this.route('contacts');
            this.route('contact', { path: 'contacts/:schema_id' });
        });
    });
});