define("ember-purecloud-components/utils/newrelic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addPageAction = addPageAction;
  _exports.setCustomAttribute = setCustomAttribute;

  /**
  * This is a simple wrapper for New Relic's browser agent.  The goal is to no-op
  * when the browser agent is not available on the page (due to adblockers,
  * unit-tests, etc).
  */
  function addPageAction() {
    if (typeof window !== 'undefined' && window !== null && window.newrelic && typeof window.newrelic.addPageAction === 'function') {
      var _window$newrelic;

      (_window$newrelic = window.newrelic).addPageAction.apply(_window$newrelic, arguments);
    }
  }

  function setCustomAttribute() {
    if (typeof window !== 'undefined' && window !== null && window.newrelic && typeof window.newrelic.setCustomAttribute === 'function') {
      var _window$newrelic2;

      (_window$newrelic2 = window.newrelic).setCustomAttribute.apply(_window$newrelic2, arguments);
    }
  }
});