define("ember-purecloud-components/mixins/conversation/chats", ["exports", "ember-purecloud-components/utils/conversation-states", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _conversationStates, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activeChats: Ember.computed('chats.[]', function () {
      var chats = this.get('chats');
      var result = (chats || []).filter(function (chat) {
        return chat.state !== _conversationStates.CONVERSATION_STATES.DISCONNECTED && chat.state !== _conversationStates.CONVERSATION_STATES.TERMINATED;
      });
      return Ember.A(result);
    }),
    chats: Ember.computed('currentUserParticipant.chats', function () {
      var currentUserParticipant = this.get('currentUserParticipant');

      if (!currentUserParticipant) {
        return [];
      }

      var userChats = currentUserParticipant.get('chats');
      return userChats.toArray();
    }),
    currentChat: Ember.computed.reads('activeChats.lastObject'),
    chatProvider: Ember.computed.reads('currentChat.provider'),
    isInternalChatProvider: Ember.computed.equal('chatProvider', 'PureCloud Webchat'),
    lastChatMessageTime: Ember.computed.reads('room.messages.lastObject.time'),
    lastChatMessageWasCustomer: Ember.computed('room.messages.lastObject.chatUser.id', 'customerParticipant.address', function () {
      var error = new Error('DEPRECATION WARNING: ember-purecloud-components > chats.lastChatMessageWasCustomer is deprecated');
      Ember.Logger.warn(error.message, {
        stack: error.stack
      });

      if (this.get('room.messages.lastObject.chatUser')) {
        var address = this.get('customerParticipant.address');
        var customerId = (0, _jidHelpers.getEntityObjectId)(address);
        var chatUserId = this.get('room.messages.lastObject.chatUser.id');
        return customerId === chatUserId;
      }

      return false;
    })
  });

  _exports.default = _default;
});