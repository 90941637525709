define("ember-purecloud-components/components/audio-player-v2/waveform-graph/component", ["exports", "d3"], function (_exports, _d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var scaleSqrt = _d.default.scaleSqrt,
      select = _d.default.select; // these constants generate a width:height ratio
  // svg will preserve that ratio and automagically
  // scale our graph for us

  var AUDIO_PLAYER_WIDTH = 800;
  var AUDIO_PLAYER_HEIGHT = 80;

  var _default = Ember.Component.extend({
    // config
    tagName: 'svg',
    // attributes
    classNames: ['audio-player__waveform'],
    classNameBindings: ['mirror:audio-player__waveform--mirror', 'isDarkTheme:audio-player__waveform--dark'],
    attributeBindings: ['viewBox', 'preserveAspectRatio'],
    isDarkTheme: Ember.computed.equal('theme', 'dark'),
    viewBox: "0 0 ".concat(AUDIO_PLAYER_WIDTH, " ").concat(AUDIO_PLAYER_HEIGHT),
    preserveAspectRatio: 'none',
    smoothedWaveformData: Ember.computed('waveformData', function () {
      var waveformData = this.get('waveformData');
      var normalized = waveformData.map(function (val) {
        return window.Math.max(val - 0.5, 0);
      });

      if (normalized.length < 5) {
        return normalized;
      }

      var smoothed = normalized.map(function (val, index, collection) {
        if ([0, 1, collection.length - 2, collection.length - 1].includes(index)) {
          return 0;
        }

        return 0.06136 * collection[index - 2] + 0.24477 * collection[index - 1] + 0.38774 * collection[index] + 0.24477 * collection[index + 1] + 0.06136 * collection[index + 2];
      });
      return smoothed;
    }),
    // lifecycle
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        return _this.renderWaveform();
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        return _this2.reRenderWaveform();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.destroyWaveform();
    },
    // rendering
    renderWaveform: function renderWaveform() {
      var element = this.element;
      var data = this.get('smoothedWaveformData');
      var barWidth = AUDIO_PLAYER_WIDTH / data.length;
      var y = scaleSqrt() // scale options: linear, sqrt, log, pow
      .domain([0, 0.5]).range([AUDIO_PLAYER_HEIGHT, 0]);
      var chart = select(element);
      var bar = chart.selectAll('g').data(data).enter().append('g').attr('transform', function (d, i) {
        return "translate(".concat(i * barWidth, ", 0)");
      });
      bar.append('rect').attr('shape-rendering', 'crispEdges').attr('y', 0).attr('width', barWidth).attr('height', function (d) {
        return y(d);
      });
    },
    reRenderWaveform: function reRenderWaveform() {
      this.destroyWaveform();
      this.renderWaveform();
    },
    destroyWaveform: function destroyWaveform() {
      this.$('g').remove();
    }
  });

  _exports.default = _default;
});