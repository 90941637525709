define("ember-webrtc-components/components/webrtc-debug-modal/component", ["exports", "ember-purecloud-components/utils/dates", "ember-webrtc-components/components/webrtc-debug-modal/template"], function (_exports, _dates, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    layout: _template.default,
    webrtc: inject.service(),
    intl: computed.reads('webrtc.intl'),
    showModal: false,
    debugInfos: computed.reads('webrtc.debugInfos'),
    recording: computed.reads('webrtc.recording'),
    application: computed.reads('webrtc.application'),
    clientInfo: computed.reads('webrtc.clientInfo'),
    canExportRecording: computed('recording', function () {
      return !this.get('recording') && this.get('webrtc.snapshots.length');
    }),
    request: function request(data) {
      return {
        topic: 'Webrtc Debug Report',
        description: 'Snapshot(s) of current webrtc call state',
        data: {
          userAgent: navigator.userAgent,
          clientJid: this.get('session.person.jid'),
          clientLocation: window.location.toString(),
          clientTimestamp: (0, _dates.default)().format(),
          clientOrgName: this.get('application.orgName'),
          clientLocale: this.get('intl.isoLocale'),
          clientType: this.get('application.hostedContext') ? 'Desktop' : 'Browser',
          clientVersion: this.get('application.semver'),
          clientLogs: data
        }
      };
    },
    actions: {
      closeModal: function closeModal() {
        this.set('webrtc.showDebugModal', false);
      },
      toggleInfo: function toggleInfo(conversationInfo) {
        conversationInfo.toggleProperty('collapsed');
      },
      rerollColor: function rerollColor(participant) {
        this.get('webrtc').rerollParticipantColor(participant);
      },
      exportSnapshot: function exportSnapshot() {
        var _this = this;

        var snapshot = this.get('webrtc').createSnapshot();
        this.get('application').sendDiagnostics(this.request(snapshot)).catch(function (err) {
          _this.logger.error('Error submitting report', err);
        });
      },
      toggleRecording: function toggleRecording() {
        this.toggleProperty('recording');
      },
      exportRecording: function exportRecording() {
        var _this2 = this;

        this.get('application').sendDiagnostics(this.request(this.get('webrtc.snapshots'))).catch(function (err) {
          _this2.logger.error('Error submitting report', err);
        });
      }
    }
  });

  _exports.default = _default;
});