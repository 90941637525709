define("ember-purecloud-components/components/duration-format/component", ["exports", "ember-purecloud-components/utils/dates", "ember-purecloud-components/components/duration-format/template"], function (_exports, _dates, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function padZero(num) {
    return num.toString().length === 1 ? "0".concat(num) : num;
  }

  var _default = Ember.Component.extend({
    classNames: ['duration-format'],
    tagName: 'span',
    layout: _template.default,
    clock: Ember.inject.service(),
    startTime: null,
    endTime: null,
    durationInMs: null,
    tick: Ember.computed('startTime', 'endTime', function () {
      var startTime = this.get('startTime');
      var endTime = this.get('endTime');

      if (startTime && !endTime) {
        return true;
      } else if (!startTime && endTime) {
        return true;
      } // Don't tick and update when the duration is a known period.
      //
      // startTime and endTime specified
      // durationInMs specified


      return false;
    }),
    _formatDuration: function _formatDuration() {
      var startTime = this.get('startTime');
      var endTime = this.get('endTime');
      var durationInMs;

      if (startTime && endTime) {
        durationInMs = endTime.diff(startTime);
      } else if (startTime) {
        durationInMs = (0, _dates.default)().diff(startTime);
      } else if (endTime) {
        durationInMs = endTime.diff((0, _dates.default)());
      } else {
        durationInMs = this.get('durationInMs');
      }

      durationInMs = durationInMs > 0 ? durationInMs : 0; // Do not show negative durations.

      var duration = _dates.default.duration(durationInMs);

      var days = duration.days();
      var hours = duration.hours() + days * 24;
      var minutes = '';

      if (hours) {
        hours = "".concat(hours, ":");
        minutes = duration.minutes() ? "".concat(padZero(duration.minutes()), ":") : '00:';
      } else {
        hours = '';
        minutes = duration.minutes() ? "".concat(duration.minutes(), ":") : '0:';
      }

      var seconds = duration.seconds() ? "".concat(padZero(duration.seconds())) : '00';
      return "".concat(hours).concat(minutes).concat(seconds);
    },
    tickingDuration: Ember.computed('startTime', 'endTime', 'durationInMs', 'clock.second', function () {
      return this._formatDuration();
    }),
    staticDuration: Ember.computed('startTime', 'endTime', 'durationInMs', function () {
      return this._formatDuration();
    })
  });

  _exports.default = _default;
});