define('ember-chat-components/components/chat-panel/roster/header/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/roster/header/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const CMD_KEY_MODIFIER = /mac|ipad/i.test(navigator.platform);

    exports.default = _ember.default.Component.extend({
        classNames: ['chat-groups-header'],
        layout: _template.default,
        session: _ember.default.inject.service('session'),

        transportModal: false,
        showDropdown: false,
        showQuickAccess: false,

        chatQuickAccessKey: "'",
        chatQuickAccessKeyboardShortcut: _ember.default.computed('chatQuickAccessKey', function () {
            return `${CMD_KEY_MODIFIER ? '⌘' : '^'}+${this.get('chatQuickAccessKey')}`;
        }),

        didInsertElement() {
            this._super(...arguments);
            _ember.default.$(document).on('keydown.chat-panel', e => {
                if (e.key === this.get('chatQuickAccessKey') && !e.shiftKey && !e.altKey && (
                // Ctrl+' is the shortcut, but also accept Cmd+' on Apple platforms
                !e.metaKey && e.ctrlKey || CMD_KEY_MODIFIER && e.metaKey && !e.ctrlKey)) {
                    e.preventDefault();
                    _ember.default.run(() => this.send('openQuickAccess'));
                }
            });
        },

        willDestroyElement() {
            this._super(...arguments);
            _ember.default.$(document).off('keydown.chat-panel');
        },

        chatPreferencesFeatureToggleEnabled: _ember.default.computed('session.features', function () {
            return this.get('session.features')['epc.decomp.drop-down.chat-preferences'];
        }),

        actions: {
            openQuickAccess() {
                this.set('showQuickAccess', true);
            },
            closeQuickAccess() {
                this.set('showQuickAccess', false);
            },
            showModal() {
                // Open wormhole.
                this.set('transportModal', true);
            },

            closeModal() {
                // Close wormhole.
                this.set('transportModal', false);
            },

            closePreferences() {
                this.set('showDropdown', false);
            },
            openPreferences() {
                this.set('showDropdown', true);
            }
        }
    });
});