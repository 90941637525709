define("ember-nav-components/components/pager-controls-v2/component", ["exports", "ember-nav-components/components/pager-controls-v2/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['pager-controls-v2'],
    classNameBindings: ['oneVisible'],
    enablePageSizeChange: false,
    disableNext: false,
    // If false, don't validate page number selection against the known number of pages
    // Allows freeform entry of larger page number than current when total number of items or pages is unknown
    validateSelectedPage: true,
    // Number of page links to show by default; if there are fewer than this number of pages, it will show all the page links
    pagesToShow: 7,
    _validatedPagesVisible: 5,
    _validatedNumItems: 0,
    _validatedPageSize: 50,
    currentPage: 1,
    requestedPage: 1,
    hidePageNumber: Ember.computed.equal('pagesVisible', 0),
    oneVisible: Ember.computed.equal('pagesVisible', 1),
    atFirst: Ember.computed.equal('currentPage', 1),
    pageSizeOptions: Ember.computed(function () {
      return [25, 50, 100];
    }),
    atLast: Ember.computed('currentPage', 'numPages', function () {
      return this.get('currentPage') >= this.get('numPages');
    }),
    pagesVisible: Ember.computed({
      get: function get() {
        return this.get('_validatedPagesVisible');
      },
      set: function set(key, value) {
        var pages = parseInt(value, 10);

        if (pages || pages === 0) {
          this.set('_validatedPagesVisible', pages);
        }

        return this.get('_validatedPagesVisible');
      }
    }),
    numItems: Ember.computed({
      get: function get() {
        return this.get('_validatedNumItems');
      },
      set: function set(key, value) {
        var items = parseInt(value, 10);

        if ((items || items === 0) && items > -1) {
          this.set('_validatedNumItems', items);
        }

        return this.get('_validatedNumItems');
      }
    }),
    pageSize: Ember.computed({
      get: function get() {
        return this.get('_validatedPageSize');
      },
      set: function set(key, value) {
        var size = parseInt(value, 10);

        if (size && size > 0) {
          this.set('_validatedPageSize', size);
        }

        return this.get('_validatedPageSize');
      }
    }),
    numPages: Ember.computed('numItems', 'pageSize', function () {
      // Page count must be at least 1
      return Math.max(Math.ceil(this.get('numItems') / this.get('pageSize')), 1);
    }),
    allPages: Ember.computed('numPages', 'currentPage', function () {
      var arr = [1];
      var currentPage = this.get('currentPage');
      var lastPage = this.get('numPages');
      var pagesToShow = this.get('pagesToShow') > lastPage ? lastPage : this.get('pagesToShow'); // deal with small amounts of pages

      if (lastPage === 1) {
        return arr;
      }

      if (lastPage === 2) {
        arr.push(2);
        return arr;
      }

      if (lastPage === 3) {
        arr.push(2, 3);
        return arr;
      } // we have three pages no matter what: 1, currentPage, and lastPage.
      // this determines how many pages we can display on each side of currentPage to get the correct number of pagesToShow


      var pagesEachSide = Math.ceil((pagesToShow - 3) / 2);

      if (currentPage - pagesEachSide <= 1) {
        // no '...' at the front; we have space to show everything up to currentPage (near beginning)
        for (var i = 2; i <= currentPage; i++) {
          arr.push(i);
        }

        var pagesLeft = pagesToShow - arr.length - 1;

        for (var _i = 1; _i <= pagesLeft; _i++) {
          arr.push(currentPage + _i);
        }

        if (arr[arr.length - 1] !== lastPage - 1) {
          arr.push('...');
        }

        arr.push(lastPage);
      } else if (lastPage - pagesEachSide <= currentPage) {
        // no '...' at the end; we have space to show everything after currentPage (near end)
        if (lastPage - pagesToShow > 0) {
          arr.push('...');
        }

        for (var _i2 = lastPage - pagesToShow + 2; _i2 <= lastPage; _i2++) {
          arr.push(_i2);
        }
      } else {
        // need both '...'s; we don't have space to show everything before or after currentPage (near middle)
        if (currentPage - pagesEachSide !== 2) {
          arr.push('...');
        }

        for (var _i3 = 0; _i3 < pagesEachSide; _i3++) {
          arr.push(currentPage - pagesEachSide + _i3);
        }

        arr.push(currentPage);

        for (var _i4 = 1; _i4 <= pagesEachSide; _i4++) {
          arr.push(currentPage + _i4);
        }

        if (arr[arr.length - 1] !== lastPage - 1) {
          arr.push('...');
        }

        arr.push(lastPage);
      }

      return arr;
    }),
    pageNumbers: Ember.computed('currentPage', 'numPages', 'pagesVisible', function () {
      var currentPage = this.get('currentPage');
      var pagesVisible = this.get('pagesVisible');

      if (pagesVisible === 1) {
        // Only 1 page visible; show current page
        return [currentPage];
      }

      var numbers = [1];
      var numPages = this.get('numPages');

      for (var i = 2; i <= pagesVisible && i <= numPages; i++) {
        if (i === pagesVisible) {
          // Always show the last page in the last slot
          numbers.push(numPages);
        } else if (this.get('atStart')) {
          // At start; show numbers from page 1
          numbers.push(i);
        } else if (this.get('atEnd')) {
          // At end; show numbers from last page
          numbers.push(numPages - pagesVisible + i);
        } else {
          // In middle; show current page number in center
          numbers.push(currentPage - Math.floor(pagesVisible / 2) - 1 + i);
        }
      }

      return numbers;
    }),
    isNextButtonDisabled: Ember.computed('atLast', 'disableNext', 'hidePageNumber', function () {
      // (1) Always disable if disableNext flag is true
      // (2) If atLast is true and page numbers are shown, last page has been reached, so disable
      // (3) If page numbers are hidden, assume that number of pages is not known, so can't use atLast to disable
      return this.get('disableNext') || this.get('atLast') && !this.get('hidePageNumber');
    }),
    atStart: Ember.computed('currentPage', 'numPages', 'pagesVisible', function () {
      return this.get('currentPage') < this.get('pagesVisible') - 1 || this.get('numPages') <= this.get('pagesVisible');
    }),
    atEnd: Ember.computed('currentPage', 'numPages', 'pagesVisible', function () {
      return this.get('currentPage') + this.get('pagesVisible') - 2 > this.get('numPages') || this.get('numPages') <= this.get('pagesVisible');
    }),
    onPageChange: function onPageChange() {},
    onPagePropertiesChanged: function onPagePropertiesChanged(fireAction) {
      var numPages = this.get('numPages'); // Ensure the current page stays in range

      if (this.get('validateSelectedPage') && !this.get('hidePageNumber')) {
        if (this.get('currentPage') < 1) {
          this.set('currentPage', 1);
        } else if (this.get('currentPage') > numPages) {
          this.set('currentPage', numPages);
        }
      } // Reset the requested page to the validated currentPage


      this.set('requestedPage', this.get('currentPage'));
      var page = this.get('currentPage');
      var pageSize = this.get('pageSize');
      var firstItem = (page - 1) * pageSize + 1;
      var lastItem = Math.min(firstItem + pageSize - 1, this.get('numItems'));
      var oldPageInfo = this.get('pageInfo');
      var pageInfo = {
        page: page,
        pageSize: pageSize,
        firstItem: firstItem,
        lastItem: lastItem
      };

      if (fireAction && (this.get('hidePageNumber') || !oldPageInfo || Object.keys(pageInfo).reduce(function (isChanged, key) {
        return isChanged || pageInfo[key] !== oldPageInfo[key];
      }, false))) {
        // Only notify subscribers if there is actually a change in values
        Ember.run.once(this, 'onPageChange', pageInfo);
      }

      this.set('pageInfo', pageInfo);
    },
    actions: {
      previous: function previous() {
        if (!this.get('atFirst')) {
          this.send('selectPage', this.get('currentPage') - 1);
        }
      },
      next: function next() {
        if (!this.get('isNextButtonDisabled')) {
          this.send('selectPage', this.get('currentPage') + 1);
        }
      },
      selectPage: function selectPage(page) {
        var pageNumber = parseInt(page, 10);

        if (pageNumber && pageNumber > 0 && (pageNumber <= this.get('numPages') || !this.get('validateSelectedPage')) && pageNumber !== this.get('currentPage') || this.get('hidePageNumber')) {
          this.set('currentPage', pageNumber);
        } // Reset the requested page to the validated currentPage


        this.set('requestedPage', this.get('currentPage'));
        this.onPagePropertiesChanged(true);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.onPagePropertiesChanged(false);
    }
  });

  _exports.default = _default;
});