define('ember-chat-components/components/chat-panel/conversation/action-bar/component', ['exports', 'ember', 'ember-chat-components/mixins/webrtc-action-utils', 'ember-chat-components/components/chat-panel/conversation/action-bar/template'], function (exports, _ember, _webrtcActionUtils, _template) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Component.extend(_webrtcActionUtils.default, {
        classNames: ['chat-action-bar'],
        classNameBindings: ['oneToOneChat:one-to-one'],
        layout: _template.default,

        participantCount: _ember.default.computed.reads('activeRoom.sortedParticipants.length'),

        activeRoom: null,
        showDropdown: false,
        callingUserJid: null,
        canViewVideo: true,

        useMediaProviders: false,
        showCopySuccessTooltip: false,

        intl: _ember.default.inject.service(),
        phone: _ember.default.inject.service(),
        chat: _ember.default.inject.service(),
        webrtc: _ember.default.inject.service(),
        session: _ember.default.inject.service(),
        sidebar: _ember.default.inject.service(),
        permissions: _ember.default.inject.service(),
        mediaProviders: _ember.default.inject.service(),
        volt: _ember.default.inject.service(),
        notification: _ember.default.inject.service(),

        disableRoomInteraction: _ember.default.computed('activeRoom.jid', 'roomFull', function () {
            return !this.get('activeRoom.jid') || this.get('roomFull');
        }),

        roomFull: _ember.default.computed('activeRoom.joinError', function () {
            return !!this.get('activeRoom.joinError');
        }),

        activePhoneCall: _ember.default.computed.reads('phone.activeCall'),
        isPhoneButtonEnabled: _ember.default.computed.and('phone.enabled', 'oneToOneChat'),
        noStationSelected: _ember.default.computed.reads('phone.noStationSelected'),
        oneToOneChat: _ember.default.computed('activeRoom.oneToOneTarget', function () {
            const activeRoom = this.get('activeRoom');
            return activeRoom && activeRoom.isOneToOneRoom();
        }),

        activeCallIsActiveRoom: _ember.default.computed('callingUserJid', 'activeRoom.jid', function () {
            return this.get('callingUserJid') === this.get('activeRoom.jid');
        }),

        dialingOtherParty: _ember.default.computed.and('activeCallIsActiveRoom', 'activePhoneCall.isDialing'),

        onCallWithOtherParty: _ember.default.computed('activePhoneCall.{otherPartyJid,isConnected}', 'activeRoom.jid', function () {
            if (this.get('activePhoneCall.isConnected') === false) {
                return false;
            }
            let otherPartyJid = this.get('activePhoneCall.otherPartyJid');
            let jid = this.get('activeRoom.jid');
            return otherPartyJid === jid;
        }),

        disablePhoneButton: _ember.default.computed.or('dialingOtherParty', 'disableRoomInteraction', 'webrtcPhoneWithIssues'),

        phoneTooltipTitle: _ember.default.computed('noStationSelected', 'webrtcPhoneWithIssues', 'webrtc.webrtcAudioCallIssueKey', function () {
            let key = 'tooltip.contactIcon.phone';
            if (this.get('noStationSelected')) {
                key = 'components.navMenu.noStationSelected';
            } else if (this.get('webrtcPhoneWithIssues')) {
                key = this.get('webrtc.webrtcAudioCallIssueKey');
            }
            return this.get('intl').t(key).toString();
        }),

        isParticipantsButtonHidden: _ember.default.computed('activeRoom.oneToOneTarget', function () {
            const activeRoom = this.get('activeRoom');
            return activeRoom && activeRoom.isOneToOneRoom();
        }),

        roomMuted: _ember.default.computed('activeRoom.jid', 'chat.mutedRooms.[]', function () {
            const activeJid = this.get('activeRoom.jid');
            const muted = this.get('chat.mutedRooms');

            return activeJid && muted && muted.any(jid => jid === activeJid);
        }),

        showingVideo: _ember.default.computed('activeRoom.showingVideo', 'mediaProviders.usingVideoProvider', function () {
            return this.get('activeRoom.showingVideo') || this.get('mediaProviders.usingVideoProvider');
        }),

        /* eslint-disable-next-line ember/no-observers */
        activeRoomChanged: _ember.default.observer('activeRoom.oneToOneTarget', function () {
            if (this.get('activeRoom.oneToOneTarget')) {
                this.get('store').findRecord('public-api-user', this.get('activeRoom.oneToOneTarget.id'), {
                    adapterOptions: {
                        expand: 'authorization'
                    }
                }).then(user => this.set('user', user));
            } else {
                this.set('user', null);
            }
        }),

        voltConference: _ember.default.computed.reads('volt.voltConference'),
        voltOneToOne: _ember.default.computed.reads('volt.voltOneToOne'),

        showVideoIcon: _ember.default.computed('webrtc.enabled', 'canViewVideo', 'useMediaProviders', function () {
            if (this.get('useMediaProviders')) {
                return true;
            }
            return this.get('webrtc.enabled') && this.get('canViewVideo');
        }),

        showCopyLink: _ember.default.computed('activeRoom.jid', 'session.features.webrtcVideoDirectLink', function () {
            const activeRoom = this.get('activeRoom');
            const isGroupChat = activeRoom && (activeRoom.get('isAdhocRoom') || activeRoom.isGroupRoom());

            return isGroupChat && this.get('session.features.webrtcVideoDirectLink');
        }),

        copyLinkTooltip: _ember.default.computed('showCopySuccessTooltip', function () {
            if (this.showCopySuccessTooltip) {
                return this.get('intl').t('volt.copyConferenceLinkSuccessShort');
            } else {
                return this.get('intl').t('volt.copyConferenceLink');
            }
        }),

        actions: {
            toggleVideo() {
                if (this.get('isVideoButtonEnabled')) {
                    this.bubbleAction({
                        action: 'openChatRoomWithVideo',
                        actionContext: [this.get('activeRoom.jid'), this.get('useMediaProviders')]
                    });
                }
            },

            toggleInvite() {
                this.sendAction('paneSelectionToggled', 'isInviteSelected');
                this.sendAction('paneDeselected', ['isParticipantsSelected', 'isSearchSelected']);
            },

            toggleParticipants() {
                this.sendAction('paneSelectionToggled', 'isParticipantsSelected');
                this.sendAction('paneDeselected', ['isInviteSelected', 'isSearchSelected']);
            },

            toggleSearch() {
                this.sendAction('paneSelectionToggled', 'isSearchSelected');
                this.sendAction('paneDeselected', ['isInviteSelected', 'isParticipantsSelected']);
            },

            callUser() {
                // putting this here instead of disabling the button because the tooltip doesn't show on a disabled button
                if (this.get('disablePhoneButton')) {
                    return;
                }
                const user = this.get('chat.activeRoom.oneToOneTarget');
                if (!this.get('noStationSelected') && user.get('guid') && !this.get('phone.phoneDisabled')) {
                    this.set('callingUserJid', user.get('jid'));
                    const phone = this.get('phone');
                    _ember.default.run.throttle(phone, phone.makeCall, user, 2000);
                }
            },

            toggleRoomMute() {
                const roomJid = this.get('activeRoom.jid');
                if (roomJid) {
                    this.get('chat').toggleRoomMuted(roomJid);
                }
            },

            copyConferenceLink() {
                const conferenceLink = this.get('volt').createVideoConferenceLink(this.get('chat.activeRoom.jid'));

                navigator.clipboard.writeText(conferenceLink.link).then(() => {
                    this.set('showCopySuccessTooltip', true);
                    setTimeout(() => {
                        this.set('showCopySuccessTooltip', false);
                    }, 2000);
                }, () => {
                    this.get('notification').error(null, this.get('intl').t('volt.copyConferenceLinkError'));
                });
            }
        }
    });
});