define('ember-chat-components/utils/giphy', ['exports', 'ember-ajax'], function (exports, _emberAjax) {
    'use strict';

    exports.__esModule = true;


    // see https://github.com/Giphy/GiphyAPI

    class Giphy {
        constructor(options) {
            options = options || {};
            this.baseUrl = 'https://api.giphy.com/v1/gifs';
            this.apiKey = options.apiKey || 'fsibTNreYj3Co';
        }

        search(query, limit, offset) {
            const url = `${this.baseUrl}/search`;
            const data = {
                q: query,
                api_key: this.apiKey,
                rating: 'pg',
                fmt: 'json',
                limit,
                offset
            };
            return (0, _emberAjax.default)(url, { data }).then(res => Array.isArray(res.data) ? res.data : []);
        }

        random(query) {
            const url = `${this.baseUrl}/random`;
            const data = {
                tag: query,
                api_key: this.apiKey
            };
            return (0, _emberAjax.default)(url, { data }).then(res => res && res.data);
        }
    }

    exports.default = new Giphy({});
});