define("ember-webrtc-components/components/webrtc-softphone-audio/component", ["exports", "ember-webrtc-components/components/webrtc-softphone-audio/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      observer = Ember.observer,
      run = Ember.run,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    layout: _template.default,
    webrtc: inject.service(),
    deviceProfilesV2: inject.service(),
    ring: computed.reads('webrtc.ring'),
    conversation: null,

    /**
     * on init we need to attach the media streams
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.get('webrtc').on('defaultDeviceChange', this, this.handleDeviceChange);
      this.get('deviceProfilesV2').on('profileDevicesChanged', this, this.handleDeviceChange);
      this.get('ring').on('callVolumeChange', this, this.handleVolumeChange);
      this.get('conversation').on('peerStreamAdded', this, this.attachMediaStreams);

      this._sessionsListener();
    },
    // TODO: is this necessary? @Jeff?
    // eslint-disable-next-line ember/no-observers
    _sessionsListener: observer('conversation.sessions.[]', function () {
      if (this.get('conversation.sessions.length')) {
        this.attachMediaStreams();
      }
    }),
    getAudioElement: function getAudioElement(session) {
      return this.$(".".concat(session.sid))[0];
    },
    createStreamWithTrack: function createStreamWithTrack(track) {
      var stream = new MediaStream();
      stream.addTrack(track);
      return stream;
    },
    attachMediaStreams: function attachMediaStreams() {
      var _this = this;

      this.get('conversation.sessions').forEach(function (session) {
        run.scheduleOnce('afterRender', _this, function () {
          var stream = session.streams.length ? session.streams[0] : null; // prefer tracks

          var receiver = session.pc.getReceivers().find(function (r) {
            return r.track;
          });

          if (receiver) {
            stream = _this.createStreamWithTrack(receiver.track);
          }

          if (stream) {
            var audioEl = _this.getAudioElement(session);

            audioEl.volume = _this.get('ring.callVolume') / 100;

            _this.get('webrtc').setDefaultOutputDevice(audioEl);

            audioEl.srcObject = stream;
          }
        });
      });
    },
    handleDeviceChange: function handleDeviceChange(devices) {
      var _this2 = this;

      var webrtc = this.get('webrtc');

      if (this.get('conversation.sessions.length') === 0) {
        return;
      }

      if (devices.outputDevice) {
        var $audios = this.$('audio');
        $audios && $audios.each(function (index, audioEl) {
          if (audioEl) {
            webrtc.setDefaultOutputDevice(audioEl);
          }
        });
      }

      if (devices.microphone) {
        webrtc.createMedia({
          requestVideo: false
        }).then(function (media) {
          _this2.get('conversation').setLocalMedia(media);
        });
      }
    },
    handleVolumeChange: function handleVolumeChange(volume) {
      if (volume) {
        var $audios = this.$('audio');
        $audios && $audios.each(function (index, audioEl) {
          if (audioEl) {
            audioEl.volume = volume / 100;
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('conversation').off('peerStreamAdded', this, this.attachMediaStreams);
      this.get('webrtc').off('defaultDeviceChange', this, this.handleDeviceChange);
    }
  });

  _exports.default = _default;
});