define("ember-softphone-integration/enums/plantronics-call-events", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getEventName = getEventName;
  var CallEvents = exports.CallEvents = {
    Unknown: 0,
    AcceptCall: 1,
    TerminateCall: 2,
    HoldCall: 3,
    ResumeCall: 4,
    Flash: 5,
    CallInProgress: 6,
    CallRinging: 7,
    CallEnded: 8,
    TransferToHeadset: 9,
    TransferToSpeaker: 10,
    Mute: 11,
    Unmute: 12,
    MobileCallRinging: 13,
    MobileCallInProgress: 14,
    MobileCallEnded: 15,
    DOn: 16,
    DOff: 17,
    CallIdle: 18,
    Play: 19,
    Pause: 20,
    Stop: 21,
    DTMLKey: 22,
    RejectCall: 23,
    MakeCall: 24,
    Hook: 25,
    HookIdle: 26,
    HookDocked: 27,
    HookUndocked: 28,
    BaseEvent: 29,
    CallAnsweredAndEnded: 30,
    CallUnansweredAndEnded: 31,
    DeviceChange: 32,
    DeviceArrived: 33,
    DeviceRemoved: 34
  };

  var reverseCallEvents = {};
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = Object.keys(CallEvents)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var eventName = _step.value;

      var code = CallEvents[eventName];
      reverseCallEvents[code] = eventName;
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  function getEventName(code) {
    return reverseCallEvents[code];
  }
});