define("ember-purecloud-components/models/call-communication", ["exports", "ember-data", "ember-purecloud-components/models/communication"], function (_exports, _emberData, _communication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _communication.default.extend({
    // ----------------------------------------------------------------------------
    // Raw Properties
    // ----------------------------------------------------------------------------
    recording: _emberData.default.attr(),
    recordingState: _emberData.default.attr(),
    muted: _emberData.default.attr(),
    confined: _emberData.default.attr(),
    held: _emberData.default.attr(),
    direction: _emberData.default.attr(),
    self: _emberData.default.attr(),
    other: _emberData.default.attr(),
    update: function update(comUpdate) {
      this._super.apply(this, arguments);

      var updatableAttrs = ['recording', 'recordingState', 'muted', 'confined', 'held', 'direction', 'self', 'other'];
      var updateProperties = {};

      for (var _i = 0, _updatableAttrs = updatableAttrs; _i < _updatableAttrs.length; _i++) {
        var updatable = _updatableAttrs[_i];
        var updatedValue = comUpdate[updatable];

        if (typeof updatedValue !== 'undefined') {
          updateProperties[updatable] = updatedValue;
        }
      }

      this.setProperties(updateProperties);
    }
  });

  _exports.default = _default;
});