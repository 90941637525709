define('ember-chat-components/models/person', ['exports'], function (exports) {
    'use strict';

    exports.__esModule = true;
    /* /directory/api/v2/people default field list (fl query parameter) */
    const BASE_PERSON_FIELDS = exports.BASE_PERSON_FIELDS = ['extMongoId', 'general', 'contactInfo', 'primaryContactInfo', 'images.profile', 'version'];

    /* /platform/api/user expand query parameter list */
    const BASE_PERSON_EXPANDS = exports.BASE_PERSON_EXPANDS = ['routingStatus', 'primaryPresence', 'conversationSummary', 'outOfOffice', 'geolocation'];

    const BASE_PERSON = exports.BASE_PERSON = {
        fl: BASE_PERSON_FIELDS.join(','),
        expand: BASE_PERSON_EXPANDS.join(',')
    };

    const FULL_PERSON = exports.FULL_PERSON = {
        fl: '*',
        expand: BASE_PERSON_EXPANDS.join(',')
    };
});