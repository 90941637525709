define("ember-purecloud-components/components/entity-favorite/component", ["exports", "ember-purecloud-components/components/entity-favorite/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'button',
    classNames: ['btn', 'btn-link', 'person-favorite', 'entity-favorite'],
    attributeBindings: ['isFavorite:ariaPressed'],
    classNameBindings: ['isFavorite'],
    entity: null,
    toggleFavorite: function toggleFavorite() {},
    isFavorite: Ember.computed.reads('entity.isFavorite'),
    click: function click() {
      var entity = this.get('entity');

      if (entity) {
        Ember.RSVP.resolve(entity).then(this.toggleFavorite.bind(this));
      }
    }
  });

  _exports.default = _default;
});