define('ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/chat-mentions/component', ['exports', 'ember', 'ember-purecloud-components/utils/emoji', 'ember-purecloud-components/utils/jid-helpers', 'keystroke', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/chat-mentions/template'], function (exports, _ember, _emoji, _jidHelpers, _keystroke, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed, run, inject, observer } = _ember.default; /* global getCaretCoordinates */

    const SHIFT = 16;

    const mentionTypes = {
        people: 'people',
        emoji: 'emoji'
    };

    function escapeName(name = '') {
        const apostrophe = _ember.default.Handlebars.Utils.escapeExpression("'");
        const escaped = _ember.default.Handlebars.Utils.escapeExpression(name);
        return escaped.replace(apostrophe, "'");
    }

    const emojiSetUnicode = (0, _emoji.getEmojiNamesUnicode)().map(function (name) {
        return { name };
    });

    const peopleTrigger = '@';
    const emojiTrigger = ':';

    exports.default = Component.extend({
        classNames: ['mentions-component'],
        layout: _template.default,
        elementSelector: null,
        textareaComponentId: null,
        maxLength: 30,
        maxResults: 5,
        delay: 150,
        onAutocompleteChange: () => {},
        onSelection: () => {},
        searchResults: null,
        room: null,

        search: inject.service(),
        application: inject.service(),
        session: inject.service(),

        $textarea: null,
        mentionType: null,

        showPresence: _ember.default.computed.reads('session.features.chatMentionPresenceIndicator'),

        urlPrefix: computed.reads('application.urlprefix'),

        init() {
            this._super(arguments);
            if (!this.textareaComponentId) {
                throw new Error('Must provide the id for the element containing the textarea');
            }
            if (!this.elementSelector) {
                throw new Error('Must provide a css selector for the textatrea');
            }

            run.scheduleOnce('afterRender', this, this.findTextarea);
        },

        findTextarea() {
            let $textarea = _ember.default.$(`#${this.textareaComponentId} ${this.elementSelector}`);

            if (!$textarea.length) {
                throw new Error('Failed to find textarea for mentions');
            }
            if ($textarea.length > 1) {
                throw new Error('Found multiple textarea. Should be limited to one');
            }

            this.set('$textarea', _ember.default.$($textarea[0]));

            this.configureTextarea();
        },

        configureTextarea() {
            this.get('$textarea').on('keydown', run.bind(this, this.keydown));
            this.get('$textarea').on('keyup', run.bind(this, this.keyup));
            this.get('$textarea').on('focus', run.bind(this, this.checkMentions));
            this.get('$textarea').on('blur', run.bind(this, () => {
                if (!this.get('mentionHasFocus')) {
                    this.set('mentionType', null);
                }
            }));
        },

        regexLastIndexOf(regex, string = '') {
            regex.lastIndex = 0;

            let lastIndexOf = -1;
            let result;
            while ((result = regex.exec(string)) != null) {
                lastIndexOf = result.index;
            }
            return lastIndexOf;
        },

        getLastIncompleteTriggerIndex(strBehindCursor, trigger, includeTrigger) {
            let index = this.regexLastIndexOf(new RegExp(`[\\s.(]${trigger}`, 'g'), strBehindCursor);
            if (index >= 0) {
                index += 1;
            } else if (strBehindCursor[0] === trigger) {
                index = 0;
            }

            if (!includeTrigger && index >= 0) {
                return index + 1;
            }

            return index;
        },

        active: computed('searchResults.[]', 'mentionType', function () {
            return !!(this.get('mentionType') && this.get('searchResults.length'));
        }),

        // eslint-disable-next-line ember/no-observers
        _notifyAutocompleteChange: observer('active', function () {
            run(this, () => {
                run.once(this, this.onAutocompleteChange, this.get('active'));
            });
        }),

        mentionStyle: computed('caretCoordinates', function () {
            if (!this.get('caretCoordinates')) {
                return '';
            }

            return _ember.default.String.htmlSafe(`bottom: -${this.get('caretCoordinates.top')}px; left: ${this.get('caretCoordinates.left') - 3}px`);
        }),

        isPeopleMention: computed('mentionType', function () {
            return this.get('mentionType') === mentionTypes.people;
        }),

        isEmojiMention: computed('mentionType', function () {
            return this.get('mentionType') === mentionTypes.emoji;
        }),

        makeSelection() {
            const strBehindCursor = this.getStringBehindCursor();
            let mentionStart;
            if (this.get('isPeopleMention')) {
                mentionStart = this.getLastIncompleteTriggerIndex(strBehindCursor, peopleTrigger, true);
            } else if (this.get('isEmojiMention')) {
                mentionStart = this.getLastIncompleteTriggerIndex(strBehindCursor, emojiTrigger, true);
            }

            this.onSelection({
                replace: strBehindCursor.substring(mentionStart),
                startIndex: mentionStart,
                selected: this.get('searchResults').objectAt(this.get('selectedIndex')),
                type: this.get('mentionType')
            });

            // select active search index
            this.setProperties({
                mentionType: null,
                searchResults: null
            });
        },

        keydown(event) {
            if (this.get('active')) {
                const key = _keystroke.default.codeToString(event.keyCode);
                if (key === 'UP_ARROW' || key === 'DOWN_ARROW') {
                    if (key === 'UP_ARROW') {
                        this.set('selectedIndex', Math.max(this.get('selectedIndex') - 1, 0));
                    } else {
                        this.set('selectedIndex', Math.min(this.get('selectedIndex') + 1, this.get('searchResults.length') - 1));
                    }

                    event.preventDefault();
                    event.stopPropagation();
                } else if (key === 'TAB') {
                    this.makeSelection();
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        },

        keyup(event) {
            const key = _keystroke.default.codeToString(event.keyCode);
            if ([SHIFT, 'CTRL', 'UP_ARROW', 'DOWN_ARROW'].includes(key)) {
                return;
            }

            if (this.get('active')) {
                if (key === 'ESCAPE') {
                    this.setProperties({
                        mentionType: null,
                        searchResults: null
                    });

                    return;
                } else if (key === 'ENTER') {
                    this.makeSelection();
                    return;
                }
            }

            const run = window.requestAnimationFrame || _ember.default.run.join;

            run(this.checkMentions.bind(this));
        },

        getStringBehindCursor() {
            const $textarea = this.get('$textarea');
            const cursorPosition = $textarea.prop('selectionStart');
            return $textarea.val().substring(0, cursorPosition);
        },

        checkMentions() {
            const strBehindCursor = this.getStringBehindCursor();

            const personMentionIndex = this.getLastIncompleteTriggerIndex(strBehindCursor, peopleTrigger);
            const emojiIndex = this.getLastIncompleteTriggerIndex(strBehindCursor, emojiTrigger);

            let mentionIndex;
            if (personMentionIndex > emojiIndex) {
                if (this.get('mentionType') !== mentionTypes.people) {
                    this.setProperties({
                        searchResults: null
                    });
                    this.set('mentionType', mentionTypes.people);
                }

                mentionIndex = personMentionIndex;
            } else {
                if (this.get('mentionType') !== mentionTypes.emoji) {
                    this.setProperties({
                        searchResults: null
                    });
                    this.set('mentionType', mentionTypes.emoji);
                }
                mentionIndex = emojiIndex;
            }

            if (mentionIndex < 0) {
                this.setProperties({
                    mentionType: null,
                    searchResults: null
                });

                return;
            }

            this.set('caretCoordinates', getCaretCoordinates(this.get('$textarea')[0], mentionIndex));

            const searchStr = strBehindCursor.substring(mentionIndex);
            const trimmedSearchStr = searchStr.trim();

            const runner = window.requestAnimationFrame || _ember.default.run.join;

            const searchPeople = this.get('isPeopleMention') && trimmedSearchStr.length > 0 && trimmedSearchStr.length <= this.get('maxLength');

            if (searchPeople) {
                runner(() => {
                    run.debounce(this, this.searchPeople, searchStr, 400);
                });
            } else if (this.get('isEmojiMention')) {
                runner(() => {
                    run.debounce(this, this.searchEmojis, searchStr, 100);
                });
            }
        },

        searchEmojis(searchStr) {
            let results;
            let emojiSetList = emojiSetUnicode;

            if (!searchStr) {
                results = emojiSetList;
            } else {
                results = emojiSetList.filter(emoji => emoji.name.startsWith(searchStr));
            }

            this.set('searchResults', _ember.default.A(results.slice(0, this.get('maxResults'))));
            this.checkSelectedIndex();
        },

        searchPeople(searchStr) {
            const terms = searchStr.split(/\s/);

            let roomMemberMatches = _ember.default.A();

            if (this.get('room')) {
                // Find all members of the current room that match the query
                roomMemberMatches = this.get('room.participants')
                // A name matches if each search term is the start of some part of the name (case insensitive)
                // e.g. "ed st" has two terms (ed and st) and matches "Stark, Eddard" (but doesn't match "Stark, Ned")
                .filter(person => {
                    const name = person.get('name');
                    if (!name) {
                        return false;
                    }
                    const nameParts = name.toLowerCase().split(/\W/);
                    return (terms || []).every(term => {
                        term = term.toLowerCase();
                        return nameParts.some(namePart => namePart.startsWith(term));
                    });
                }).map(person => {
                    const name = escapeName(person.get('name'));
                    const title = escapeName(person.get('title')) || '';
                    const entityId = (0, _jidHelpers.getEntityObjectId)(person.get('jid'));
                    const presence = this.get('showPresence') ? person.get('presence') : undefined;
                    const profilePicture = person.get('profilePicture');
                    return {
                        name,
                        title,
                        id: entityId || person.get('id') || person.get('guid'),
                        jid: person.get('jid'),
                        presence,
                        profilePicture,
                        roomJid: this.get('room.jid'),
                        sendInvite: true
                    };
                });
            }

            if (searchStr.length < 2 || roomMemberMatches.length >= this.get('maxResults')) {
                this.set('searchResults', roomMemberMatches);
                return this.checkSelectedIndex();
            }

            return this.get('search').performSearch(searchStr, 0, 'people', [], ['name'], 'QUERY_STRING', ['name', 'title', 'contactInfo', 'images', 'extMongoId']).then(({ results }) => {
                if (!results || this.isDestroyed || this.isDestroying) {
                    return;
                }

                const people = results.map(person => {
                    const personName = _ember.default.get(person, 'name');
                    const name = escapeName(personName);
                    const personTitle = _ember.default.get(person, 'title');
                    const title = escapeName(personTitle) || '';
                    const entityId = (0, _jidHelpers.getEntityObjectId)(_ember.default.get(person, 'jid'));
                    const presence = this.get('showPresence') ? _ember.default.get(person, 'presence') : undefined;
                    const profilePicture = person.get('profilePicture');
                    return {
                        name,
                        id: entityId || _ember.default.get(person, '_id') || _ember.default.get(person, 'guid'),
                        title,
                        jid: _ember.default.get(person, 'jid'),
                        presence,
                        profilePicture,
                        roomJid: this.get('room.jid'),
                        sendInvite: true
                    };
                });

                if (!results.length) {
                    this.set('selectedIndex', null);
                } else if (this.get('selectedIndex') > results.length) {
                    this.set('selectedIndex');
                }

                const resultMap = new Map();
                [...roomMemberMatches, ...people].forEach(member => {
                    if (!resultMap[member.id]) {
                        resultMap.set(member.id, member);
                    }
                });

                this.set('searchResults', _ember.default.A(Array.from(resultMap.values()).slice(0, this.get('maxResults'))));
                this.checkSelectedIndex();
            });
        },

        checkSelectedIndex() {
            if (this.get('searchResults.length') && (!this.get('selectedIndex') || this.get('selectedIndex') < 0)) {
                return this.set('selectedIndex', 0);
            }

            this.set('selectedIndex', Math.min(this.get('selectedIndex'), this.get('searchResults.length') - 1));
        },

        actions: {
            mouseEnter(index) {
                this.set('selectedIndex', index);
            },
            makeSelection() {
                this.makeSelection();
            },
            setMentionHasFocus() {
                this.set('mentionHasFocus', true);
            },
            setMentionLostFocus() {
                this.set('mentionHasFocus', false);
            }
        }
    });
});