define("@ember-decorators/object/index", ["exports", "@ember-decorators/utils/collapse-proto", "@ember-decorators/utils/decorator", "@ember-decorators/utils/computed"], function (_exports, _collapseProto, _decorator, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.volatile = _exports.unobserves = _exports.readOnly = _exports.on = _exports.off = _exports.observes = _exports.computed = _exports.action = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
    Decorator that turns the target function into an Action
  
    Adds an `actions` object to the target object and creates a passthrough
    function that calls the original. This means the function still exists
    on the original object, and can be used directly.
  
    ```js
    export default class ActionDemoComponent extends Component {
      @action
      foo() {
        // do something
      }
    }
    ```
  
    ```hbs
    <!-- template.hbs -->
    <button onclick={{action "foo"}}>Execute foo action</button>
    ```
  
    @return {Function}
  */
  var action = (0, _decorator.decorator)(function (desc) {
    (false && !(desc && desc.descriptor && typeof desc.descriptor.value === 'function') && Ember.assert('The @action decorator must be applied to functions', desc && desc.descriptor && typeof desc.descriptor.value === 'function'));

    desc.finisher = function (target) {
      var key = desc.key,
          descriptor = desc.descriptor;
      var prototype = target.prototype;
      (0, _collapseProto.default)(prototype);

      if (!prototype.hasOwnProperty('actions')) {
        var parentActions = prototype.actions;
        prototype.actions = parentActions ? Object.create(parentActions) : {};
      }

      prototype.actions[key] = descriptor.value;
      return target;
    };
  });
  /**
    Decorator that turns a native getter/setter into a computed property. Note
    that though they use getters and setters, you must still use the Ember `get`/
    `set` functions to get and set their values.
  
    ```js
    import Component from '@ember/component';
    import { computed } from '@ember-decorators/object';
  
    export default class UserProfileComponent extends Component {
      first = 'John';
      last = 'Smith';
  
      @computed('first', 'last')
      get name() {
        const first = this.get('first');
        const last = this.get('last');
  
        return `${first} ${last}`; // => 'John Smith'
      }
  
      set name(value) {
        if (typeof value !== 'string' || !value.test(/^[a-z]+ [a-z]+$/i)) {
          throw new TypeError('Invalid name');
        }
  
        const [first, last] = value.split(' ');
        this.setProperties({ first, last });
  
        return value;
      }
    }
    ```
  
    @function
    @param {...string} propertyNames - List of property keys this computed is dependent on
    @return {ComputedProperty}
  */

  _exports.action = action;
  var computed = (0, _computed.computedDecoratorWithParams)(function (_ref, params) {
    var _Ember;

    var key = _ref.key,
        descriptor = _ref.descriptor;
    (false && !('get' in descriptor || 'set' in descriptor) && Ember.assert("Attempted to apply @computed to ".concat(key, ", but it is not a native accessor function. Try converting it to `get ").concat(key, "()`"), 'get' in descriptor || 'set' in descriptor));
    (false && !('get' in descriptor && descriptor.get !== undefined) && Ember.assert("Using @computed for only a setter does not make sense. Add a getter for '".concat(key, "' as well or remove the @computed decorator."), 'get' in descriptor && descriptor.get !== undefined));
    var get = descriptor.get,
        set = descriptor.set; // Unset the getter and setter so the descriptor just has a plain value

    descriptor.get = undefined;
    descriptor.set = undefined;
    var setter;

    if (typeof set === 'function') {
      setter = function setter(key, value) {
        var ret = set.call(this, value);
        return typeof ret === 'undefined' ? get.call(this) : ret;
      };
    } else if (false
    /* DEBUG */
    && false) {
      setter = function setter(key) {
        (false && !(false) && Ember.assert("Attempted to set ".concat(key, ", but it does not have a setter. Overriding a computed property without a setter has been deprecated."), false));
      };
    }

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(params).concat([{
      get: get,
      set: setter
    }]));
  });
  /**
    Triggers the target function when the dependent properties have changed
  
    ```javascript
    import { observes } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that trigger the function
   */

  _exports.computed = computed;
  var observes = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    (false && !(desc && desc.descriptor && typeof desc.descriptor.value === 'function') && Ember.assert('The @observes decorator must be applied to functions', desc && desc.descriptor && typeof desc.descriptor.value === 'function'));

    desc.finisher = function (target) {
      var prototype = target.prototype;

      var _iterator = _createForOfIteratorHelper(params),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var path = _step.value;
          Ember.addObserver(prototype, path, null, desc.key);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return target;
    };
  }, 'observes');
  /**
    Removes observers from the target function.
  
    ```javascript
    import { observes, unobserves } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @unobserves('foo') bar;
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that no longer trigger the function
   */

  _exports.observes = observes;
  var unobserves = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    desc.finisher = function (target) {
      var prototype = target.prototype;

      var _iterator2 = _createForOfIteratorHelper(params),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var path = _step2.value;
          Ember.removeObserver(prototype, path, null, desc.key);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return target;
    };
  }, 'unobserves');
  /**
    Adds an event listener to the target function.
  
    ```javascript
    import { on } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that trigger the function
   */

  _exports.unobserves = unobserves;
  var on = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    (false && !(desc && desc.descriptor && typeof desc.descriptor.value === 'function') && Ember.assert('The @on decorator must be applied to functions', desc && desc.descriptor && typeof desc.descriptor.value === 'function'));

    desc.finisher = function (target) {
      var prototype = target.prototype;

      var _iterator3 = _createForOfIteratorHelper(params),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var eventName = _step3.value;
          Ember.addListener(prototype, eventName, null, desc.key);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return target;
    };
  }, 'on');
  /**
    Removes an event listener from the target function.
  
    ```javascript
    import { on, off } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @off('fooEvent', 'barEvent') bar;
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that no longer trigger the function
   */

  _exports.on = on;
  var off = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    desc.finisher = function (target) {
      var prototype = target.prototype;

      var _iterator4 = _createForOfIteratorHelper(params),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var eventName = _step4.value;
          Ember.removeListener(prototype, eventName, null, desc.key);
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      return target;
    };
  }, 'off');
  /**
    Decorator that modifies a computed property to be read only.
  
    ```js
    import Component from '@ember/component';
    import { computed, readOnly } from 'ember-decorators/object';
  
    export default class extends Component {
      @readOnly
      @computed('first', 'last')
      name(first, last) {
        return `${first} ${last}`;
      }
    }
    ```
  
    @return {ComputedProperty}
  */

  _exports.off = off;
  var readOnly = (0, _decorator.decorator)(function (desc) {
    desc.finisher = function (target) {
      var prototype = target.prototype;
      var key = desc.key;

      if (false
      /* DEBUG */
      ) {
        var modifierMeta = (0, _computed.getOrCreateModifierMeta)(prototype, name);
        (false && !(modifierMeta[key] !== 'volatile') && Ember.assert('A computed property cannot be both readOnly and volatile. Use a native setter instead', modifierMeta[key] !== 'volatile'));
      } // TODO(v4): We can't know for sure whether or not the computed descriptor
      // has already been added, it may have if decorators are stage 1, so we have
      // to check and modify it if it has.


      var computedDesc = (0, _computed.computedDescriptorFor)(prototype, key);

      if (computedDesc !== undefined) {
        if (false
        /* DEBUG */
        ) {
          var _modifierMeta = (0, _computed.getOrCreateModifierMeta)(prototype, name);

          _modifierMeta[key] = 'readOnly';
        }

        computedDesc.readOnly();
      } else {
        var _modifierMeta2 = (0, _computed.getOrCreateModifierMeta)(prototype, name);

        _modifierMeta2[key] = 'readOnly';
      }
    };
  });
  /**
    Decorator that modifies a computed property to be volatile.
  
    ```js
    import Component from '@ember/component';
    import { computed, readOnly } from 'ember-decorators/object';
  
    export default class extends Component {
      @volatile
      @computed('first', 'last')
      name(first, last) {
        return `${first} ${last}`;
      }
    }
    ```
  
    @return {ComputedProperty}
  */

  _exports.readOnly = readOnly;
  var volatile = (0, _decorator.decorator)(function (desc) {
    desc.finisher = function (target) {
      var prototype = target.prototype;
      var key = desc.key;

      if (false
      /* DEBUG */
      ) {
        var modifierMeta = (0, _computed.getOrCreateModifierMeta)(prototype, name);
        (false && !(modifierMeta[key] !== 'readOnly') && Ember.assert('A computed property cannot be both readOnly and volatile. Use a native getter instead', modifierMeta[key] !== 'readOnly'));
      } // TODO(v4): We can't know for sure whether or not the computed descriptor
      // has already been added, it may have if decorators are stage 1, so we have
      // to check and modify it if it has.


      var computedDesc = (0, _computed.computedDescriptorFor)(prototype, key);

      if (computedDesc !== undefined) {
        if (false
        /* DEBUG */
        ) {
          var _modifierMeta3 = (0, _computed.getOrCreateModifierMeta)(prototype, name);

          _modifierMeta3[key] = 'volatile';
        }

        computedDesc.volatile();
      } else {
        var _modifierMeta4 = (0, _computed.getOrCreateModifierMeta)(prototype, name);

        _modifierMeta4[key] = 'volatile';
      }
    };
  });
  _exports.volatile = volatile;
});