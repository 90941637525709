define('ember-engine-custom-fields/mixins/min-max-value', ['exports', 'ember-custom-fields-components/utils/field-util', 'ember-engine-custom-fields/mixins/validation', 'ember-engine-custom-fields/mixins/safe-set'], function (exports, _fieldUtil, _validation, _safeSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Mixin = Ember.Mixin,
        inject = Ember.inject,
        computed = Ember.computed,
        getProperties = Ember.getProperties,
        isBlank = Ember.isBlank;
    var not = computed.not;
    exports.default = Mixin.create(_validation.default, _safeSet.default, {
        intl: inject.service(),

        existingField: not('field.isNewField'),

        minValid: false,
        maxValid: false,

        showMinValidationError: false,
        showMaxValidationError: false,

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.scheduleOnce('actions', this, function () {
                if (_this.get('field')) {

                    var minKey = _this.get('minKey');
                    var maxKey = _this.get('maxKey');

                    var minLimit = _this.get('usesValue') ? _this.get('minLimit') : _this.get('minMin');
                    var maxLimit = _this.get('usesValue') ? _this.get('maxLimit') : _this.get('maxMax');

                    _this.set('field.' + minKey + 'Limit', minLimit);
                    _this.set('field.' + maxKey + 'Limit', maxLimit);
                }
            });
        },


        hasMinError: computed('minValid', 'showMinValidationError', function () {
            var showMinValidationError = this.get('showMinValidationError');
            var minValid = this.get('minValid');
            return showMinValidationError && !minValid;
        }),
        hasMaxError: computed('maxValid', 'showMaxValidationError', function () {
            var showMaxValidationError = this.get('showMaxValidationError');
            var maxValid = this.get('maxValid');
            return showMaxValidationError && !maxValid;
        }),

        showValidationError: computed('showMinValidationError', 'showMaxValidationError', {
            get: function get() {
                return this.get('showMinValidationError') && this.get('showMaxValidationError');
            },
            set: function set(key, value) {
                this.set('showMinValidationError', value);
                this.set('showMaxValidationError', value);
                return value;
            }
        }),

        usesItems: computed.equal('field.type', _fieldUtil.FIELD_TYPE.TAG),
        usesValue: computed('field.type', function () {
            var type = this.get('field.type');
            return type === _fieldUtil.FIELD_TYPE.NUMBER || type === _fieldUtil.FIELD_TYPE.INTEGER;
        }),
        usesLengths: computed('usesItems', 'usesValue', function () {
            return !(this.get('usesItems') || this.get('usesValue'));
        }),

        minKey: computed('usesItems', 'usesValue', 'usesLengths', function () {
            if (this.get('usesItems')) {
                return 'minItems';
            } else if (this.get('usesValue')) {
                return 'minimum';
            } else if (this.get('usesLengths')) {
                return 'minLength';
            }
        }),

        maxKey: computed('usesItems', 'usesValue', 'usesLengths', function () {
            if (this.get('usesItems')) {
                return 'maxItems';
            } else if (this.get('usesValue')) {
                return 'maximum';
            } else if (this.get('usesLengths')) {
                return 'maxLength';
            }
        }),

        minValueOrItems: computed('minKey', 'field.{minimum,minItems,minLength}', 'showMinValidationError', function () {
            var minKey = this.get('minKey');
            return Number(this.get('field.' + minKey));
        }),
        maxValueOrItems: computed('maxKey', 'field.{maximum,maxItems,maxLength}', 'showMaxValidationError', function () {
            var maxKey = this.get('maxKey');
            return Number(this.get('field.' + maxKey));
        }),

        _getSavedValue: function _getSavedValue(isMin) {
            var changedAttributes = this.get('field').changedAttributes();
            var key = isMin ? this.get('minKey') : this.get('maxKey');
            var fieldData = changedAttributes[key] || [];
            return String(fieldData[0]);
        },


        newMinValueTooRestrictiveMessage: computed('hasMinError', 'field.{minimum,minItems,minLength}', 'showMinValidationError', function () {
            if (this.get('hasMinError')) {
                var intl = this.get('intl');
                var savedMinValue = this._getSavedValue(true);
                return intl.t('emberEngineCustomFields.fieldErrors.newMinValueTooRestrictive', { savedMinValue: savedMinValue });
            }
            return '';
        }),

        newMaxValueTooRestrictiveMessage: computed('hasMaxError', 'field.{maximum,maxItems,maxLength}', 'showMaxValidationError', function () {
            if (this.get('hasMaxError')) {
                var intl = this.get('intl');
                var savedMaxValue = this._getSavedValue(false);
                return intl.t('emberEngineCustomFields.fieldErrors.newMaxValueTooRestrictive', { savedMaxValue: savedMaxValue });
            }
            return '';
        }),

        minValueWithinLimits: computed('minKey', 'minValid', 'field.{minimum,minItems,minLength,isMinimumWithinLimit,isMinItemsWithinLimit,isMinLengthWithinLimit}', 'showMinValidationError', function () {
            var minKey = this.get('minKey') || '';
            return this.get('field.is' + minKey.charAt(0).toUpperCase() + minKey.slice(1) + 'WithinLimit');
        }),

        maxValueWithinLimits: computed('maxKey', 'maxValid', 'field.{maximum,maxItems,maxLength,isMaximumWithinLimit,isMaxItemsWithinLimit,isMaxLengthWithinLimit}', 'showMaxValidationError', function () {
            var maxKey = this.get('maxKey') || '';
            return this.get('field.is' + maxKey.charAt(0).toUpperCase() + maxKey.slice(1) + 'WithinLimit');
        }),

        hasMinimumErrorMessage: computed('field.{isMinimumValid,isNewMinimumLessRestrictive}', 'minValueWithinLimits', 'showMinValidationError', function () {
            var _getProperties = getProperties(this.get('field'), 'isMinimumValid', 'isNewMinimumLessRestrictive'),
                isMinimumValid = _getProperties.isMinimumValid,
                isNewMinimumLessRestrictive = _getProperties.isNewMinimumLessRestrictive;

            var minValueWithinLimits = this.get('minValueWithinLimits');

            return !isMinimumValid || !isNewMinimumLessRestrictive || !minValueWithinLimits;
        }),

        hasMaximumErrorMessage: computed('field.{isMaximumValid,isNewMaximumLessRestrictive}', 'maxValueWithinLimits', 'showMaxValidationError', function () {
            var _getProperties2 = getProperties(this.get('field'), 'isMaximumValid', 'isNewMaximumLessRestrictive'),
                isMaximumValid = _getProperties2.isMaximumValid,
                isNewMaximumLessRestrictive = _getProperties2.isNewMaximumLessRestrictive;

            var maxValueWithinLimits = this.get('maxValueWithinLimits');

            return !isMaximumValid || !isNewMaximumLessRestrictive || !maxValueWithinLimits;
        }),

        minIsMoreThanMax: computed('field.{minimum,minItems,minLength,maximum,maxItems,maxLength}', function () {
            return !this._minLessThanMax();
        }),

        boundariesAreInvalid: computed('field.{minimum,minItems,minLength,maximum,maxItems,maxLength,isBoundariesValid,isItemsBoundariesValid,isLengthBoundariesValid}', function () {
            return !this._boundariesValid();
        }),

        _minLessThanMax: function _minLessThanMax() {
            var _getProperties3 = this.getProperties('minKey', 'maxKey'),
                minKey = _getProperties3.minKey,
                maxKey = _getProperties3.maxKey;

            var minValue = this.get('field.' + minKey);
            var maxValue = this.get('field.' + maxKey);
            return !isNaN(minValue) && !isNaN(maxValue) ? Number(minValue) <= Number(maxValue) : true;
        },
        _validateMin: function _validateMin() {
            if (this._minLessThanMax()) {
                if (this.get('usesValue')) {
                    return this.get('field.isMinimumValid') && this.get('field.isNewMinimumLessRestrictive') && this.get('field.isMinimumWithinLimit');
                } else if (this.get('usesItems')) {
                    return this.get('field.isMinItemsValid') && this.get('field.isNewMinItemsLessRestrictive') && this.get('field.isMinItemsWithinLimit');
                } else if (this.get('usesLengths')) {
                    return this.get('field.isMinLengthValid') && this.get('field.isNewMinLengthLessRestrictive') && this.get('field.isMinLengthWithinLimit');
                }
            }
            return false;
        },
        _validateMax: function _validateMax() {
            if (this._minLessThanMax()) {
                if (this.get('usesValue')) {
                    return this.get('field.isMaximumValid') && this.get('field.isNewMaximumLessRestrictive') && this.get('field.isMaximumWithinLimit');
                } else if (this.get('usesItems')) {
                    return this.get('field.isMaxItemsValid') && this.get('field.isNewMaxItemsLessRestrictive') && this.get('field.isMaxItemsWithinLimit');
                } else if (this.get('usesLengths')) {
                    return this.get('field.isMaxLengthValid') && this.get('field.isNewMaxLengthLessRestrictive') && this.get('field.isMaxLengthWithinLimit');
                }
            }
            return false;
        },
        _boundariesValid: function _boundariesValid() {
            if (this.get('usesValue')) {
                return this.get('field.isBoundariesValid');
            } else if (this.get('usesItems')) {
                return this.get('field.isItemsBoundariesValid');
            } else if (this.get('usesLengths')) {
                return this.get('field.isLengthBoundariesValid');
            }
            return false;
        },
        _validate: function _validate(validationKey) {
            var _this2 = this;

            var minValid = this._validateMin();
            var maxValid = this._validateMax();

            Ember.run.scheduleOnce('actions', this, function () {
                if (_this2._notDestroyed()) {
                    _this2.set('minValid', minValid);
                    _this2.set('maxValid', maxValid);

                    if (validationKey === 'showMinValidationError' || minValid) {
                        _this2.set('showMinValidationError', !minValid);
                    }

                    if (validationKey === 'showMaxValidationError' || maxValid) {
                        _this2.set('showMaxValidationError', !maxValid);
                    }

                    if (!_this2._boundariesValid()) {
                        _this2.set('showValidationError', true);
                    }
                }
            });
            return minValid && maxValid;
        },


        actions: {
            validateMin: function validateMin(value) {
                if (!isBlank(value)) {
                    this.get('validation').updateValidation(this, 'showMinValidationError');
                } else {
                    this.set('showMinValidationError', false);
                }
            },
            validateMax: function validateMax(value) {
                if (!isBlank(value)) {
                    this.get('validation').updateValidation(this, 'showMaxValidationError');
                } else {
                    this.set('showMaxValidationError', false);
                }
            }
        }
    });
});