enifed('ember-template-compiler/plugins/transform-has-block-syntax', ['exports'], function (exports) {
  'use strict';

  exports.default = TransformHasBlockSyntax;
  /**
   @module ember
   @submodule ember-glimmer
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{hasBlock}}
    ```
  
    with
  
    ```handlebars
   {{has-block}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */

  function TransformHasBlockSyntax() {
    // set later within Glimmer2 to the syntax package
    this.syntax = null;
  }

  var TRANSFORMATIONS = {
    hasBlock: 'has-block',
    hasBlockParams: 'has-block-params'
  };

  /**
    @private
    @method transform
    @param {AST} ast The AST to be transformed.
  */
  TransformHasBlockSyntax.prototype.transform = function (ast) {
    var _syntax = this.syntax,
        traverse = _syntax.traverse,
        b = _syntax.builders;

    traverse(ast, {
      PathExpression: function (node) {
        if (TRANSFORMATIONS[node.original]) {
          return b.sexpr(b.path(TRANSFORMATIONS[node.original]));
        }
      },
      MustacheStatement: function (node) {
        if (TRANSFORMATIONS[node.path.original]) {
          return b.mustache(b.path(TRANSFORMATIONS[node.path.original]), node.params, node.hash, null, node.loc);
        }
      },
      SubExpression: function (node) {
        if (TRANSFORMATIONS[node.path.original]) {
          return b.sexpr(b.path(TRANSFORMATIONS[node.path.original]), node.params, node.hash);
        }
      }
    });

    return ast;
  };
});