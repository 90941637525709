define("ember-purecloud-components/models/social-communication", ["exports", "ember-data", "ember-purecloud-components/models/communication", "moment"], function (_exports, _emberData, _communication, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _communication.default.extend({
    // ----------------------------------------------------------------------------
    // Raw Properties
    // ----------------------------------------------------------------------------
    expression: _emberData.default.attr(),
    update: function update(comUpdate) {
      this._super.apply(this, arguments);

      var updatableAttrs = ['expression'];
      var updateProperties = {};

      for (var _i = 0, _updatableAttrs = updatableAttrs; _i < _updatableAttrs.length; _i++) {
        var updatable = _updatableAttrs[_i];
        var updatedValue = comUpdate[updatable];

        if (typeof updatedValue !== 'undefined') {
          updateProperties[updatable] = updatedValue;
        }
      }

      this.setProperties(updateProperties);
    },
    // twitter-specific details
    retweetCount: 0,
    text: '',
    tweetCreated: (0, _moment.default)(new Date()),
    userFollowersCount: 0,
    userName: '',
    userScreenName: '',
    handleUrl: Ember.computed('userScreenName', function () {
      return 'https://www.twitter.com/' + this.get('userScreenName');
    }),
    // socialize-specific
    gistener: {
      name: '',
      id: ''
    },
    // interaction engine
    time: (0, _moment.default)(new Date())
  });

  _exports.default = _default;
});