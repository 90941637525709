define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/integer/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/integer/template', 'ember-engine-custom-fields/mixins/toggle-field-panel', 'ember-engine-custom-fields/mixins/min-max-value', 'ember-custom-fields-components/utils/field-util'], function (exports, _template, _toggleFieldPanel, _minMaxValue, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject,
        isEmpty = Ember.isEmpty;
    var alias = computed.alias;
    exports.default = Component.extend(_toggleFieldPanel.default, _minMaxValue.default, {
        layout: _template.default,
        classNames: ['field field-integer'],

        limits: inject.service(),
        field: null,

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            if (isEmpty(this.get('field.minimum'))) {
                this.set('field.minimum', this.get('minDefault'));
                this.notifyPropertyChange('field');
            }

            if (isEmpty(this.get('field.maximum'))) {
                this.set('field.maximum', this.get('maxDefault'));
                this.notifyPropertyChange('field');
            }
        },


        minLimit: alias('limits.minInteger'),
        maxLimit: alias('limits.maxInteger'),

        minDefault: computed('field.type', function () {
            return (0, _fieldUtil.fieldConfig)(this.get('field.type')).minDefault;
        }),

        maxDefault: computed('field.type', function () {
            return (0, _fieldUtil.fieldConfig)(this.get('field.type')).maxDefault;
        })
    });
});