define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/google-slides/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/google-slides/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, inject } = _ember.default;

    exports.default = Component.extend({
        layout: _template.default,

        sidebar: inject.service(),
        mediaItem: null,

        didInsertElement() {
            this._super(...arguments);
            this.scrollOnLoad();
        },

        scrollOnLoad() {
            if (!this.get('mediaItem.collapsed')) {
                this.$('iframe').on('load', () => {
                    this._safeScroll();
                }).on('error', () => {
                    this._safeScroll();
                });
            }
        },

        _safeScroll() {
            _ember.default.run(() => {
                _ember.default.run.scheduleOnce('afterRender', () => {
                    this.bubbleAction({
                        action: 'safeScroll'
                    });
                });
            });
        }
    });
});