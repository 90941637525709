define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/drop-down/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/drop-down/template', 'ember-engine-custom-fields/mixins/toggle-field-panel', 'ember-engine-custom-fields/mixins/validation'], function (exports, _template, _toggleFieldPanel, _validation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        inject = Ember.inject,
        computed = Ember.computed,
        A = Ember.A,
        set = Ember.set,
        run = Ember.run;
    exports.default = Component.extend(_toggleFieldPanel.default, _validation.default, {
        layout: _template.default,
        classNames: ['field field-drop-down'],

        limits: inject.service(),

        optionsCount: computed.alias('field.enumList.length'),
        optionsMax: computed.alias('limits.coreTypeLimits.enum.maxItems.max'),

        optionsRemaining: computed('optionsMax', 'optionsCount', function () {
            return this.get('optionsMax') - (this.get('optionsCount') || 0);
        }),
        shouldEnableAdd: computed.gt('optionsRemaining', 0),

        _validate: function _validate() {
            return this.get('field.enumList.length');
        },


        actions: {
            reorderItems: function reorderItems(group) {
                var sorted = group.get('sortedItems').mapBy('model');
                if (sorted.length) {
                    sorted.forEach(function (item, index) {
                        set(item, 'order', index);
                    });
                    this.set('field.enumList', sorted);
                }
            },
            createEnum: function createEnum() {
                var _this = this;

                if (!this.get('field.enumList')) {
                    this.set('field.enumList', A([]));
                }
                var newEnumItem = {
                    isNew: true,
                    _disabled: false,
                    key: null,
                    title: null,
                    order: this.get('field.enumList.length')
                };
                this.get('field.enumList').pushObject(newEnumItem);

                run.scheduleOnce('afterRender', this, function () {
                    _this.$('.new-item .enum-display-name').focus();
                });

                this.set('showValidationError', false);
            },
            deleteEnum: function deleteEnum(enumItem) {
                this.get('field.enumList').removeObject(enumItem);
            }
        }
    });
});