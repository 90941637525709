define("ember-purecloud-components/helpers/emoji-text", ["exports", "ember-purecloud-components/utils/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var text = params ? params[0] : null;

    if (text) {
      text = (0, _emoji.emojifyString)(Ember.Handlebars.Utils.escapeExpression(text));
      return Ember.String.htmlSafe(text);
    }

    return text;
  });

  _exports.default = _default;
});