enifed('@glimmer/compiler', ['exports', 'ember-babel', 'node-module', '@glimmer/syntax', '@glimmer/util', '@glimmer/wire-format'], function (exports, _emberBabel, _nodeModule, _syntax, _util, _wireFormat) {
    'use strict';

    exports.TemplateVisitor = exports.precompile = undefined;

    var push = Array.prototype.push;

    var Frame = function () {

        this.parentNode = null;
        this.children = null;
        this.childIndex = null;
        this.childCount = null;
        this.childTemplateCount = 0;
        this.mustacheCount = 0;
        this.actions = [];
        this.blankChildTextNodes = null;
        this.symbols = null;
    };

    var SymbolTable = function () {
        function SymbolTable(symbols) {
            var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;


            this.symbols = symbols;
            this.parent = parent;
        }

        SymbolTable.prototype.hasLocalVariable = function (name) {
            var symbols = this.symbols,
                parent = this.parent;

            return symbols.indexOf(name) >= 0 || parent && parent.hasLocalVariable(name);
        };

        return SymbolTable;
    }();

    /**
     * Takes in an AST and outputs a list of actions to be consumed
     * by a compiler. For example, the template
     *
     *     foo{{bar}}<div>baz</div>
     *
     * produces the actions
     *
     *     [['startProgram', [programNode, 0]],
     *      ['text', [textNode, 0, 3]],
     *      ['mustache', [mustacheNode, 1, 3]],
     *      ['openElement', [elementNode, 2, 3, 0]],
     *      ['text', [textNode, 0, 1]],
     *      ['closeElement', [elementNode, 2, 3],
     *      ['endProgram', [programNode]]]
     *
     * This visitor walks the AST depth first and backwards. As
     * a result the bottom-most child template will appear at the
     * top of the actions list whereas the root template will appear
     * at the bottom of the list. For example,
     *
     *     <div>{{#if}}foo{{else}}bar<b></b>{{/if}}</div>
     *
     * produces the actions
     *
     *     [['startProgram', [programNode, 0]],
     *      ['text', [textNode, 0, 2, 0]],
     *      ['openElement', [elementNode, 1, 2, 0]],
     *      ['closeElement', [elementNode, 1, 2]],
     *      ['endProgram', [programNode]],
     *      ['startProgram', [programNode, 0]],
     *      ['text', [textNode, 0, 1]],
     *      ['endProgram', [programNode]],
     *      ['startProgram', [programNode, 2]],
     *      ['openElement', [elementNode, 0, 1, 1]],
     *      ['block', [blockNode, 0, 1]],
     *      ['closeElement', [elementNode, 0, 1]],
     *      ['endProgram', [programNode]]]
     *
     * The state of the traversal is maintained by a stack of frames.
     * Whenever a node with children is entered (either a ProgramNode
     * or an ElementNode) a frame is pushed onto the stack. The frame
     * contains information about the state of the traversal of that
     * node. For example,
     *
     *   - index of the current child node being visited
     *   - the number of mustaches contained within its child nodes
     *   - the list of actions generated by its child nodes
     */
    function TemplateVisitor() {
        this.frameStack = [];
        this.actions = [];
        this.programDepth = -1;
    }
    // Traversal methods
    TemplateVisitor.prototype.visit = function (node) {
        this[node.type](node);
    };
    TemplateVisitor.prototype.Program = function (program) {
        this.programDepth++;
        var parentFrame = this.getCurrentFrame(),
            i;
        var programFrame = this.pushFrame();
        if (parentFrame) {
            program.symbols = new SymbolTable(program.blockParams, parentFrame.symbols);
        } else {
            program.symbols = new SymbolTable(program.blockParams);
        }
        var startType = void 0,
            endType = void 0;
        if (this.programDepth === 0) {
            startType = 'startProgram';
            endType = 'endProgram';
        } else {
            startType = 'startBlock';
            endType = 'endBlock';
        }
        programFrame.parentNode = program;
        programFrame.children = program.body;
        programFrame.childCount = program.body.length;
        programFrame.blankChildTextNodes = [];
        programFrame.actions.push([endType, [program, this.programDepth]]);
        programFrame.symbols = program.symbols;
        for (i = program.body.length - 1; i >= 0; i--) {
            programFrame.childIndex = i;
            this.visit(program.body[i]);
        }
        programFrame.actions.push([startType, [program, programFrame.childTemplateCount, programFrame.blankChildTextNodes.reverse()]]);
        this.popFrame();
        this.programDepth--;
        // Push the completed template into the global actions list
        if (parentFrame) {
            parentFrame.childTemplateCount++;
        }
        push.apply(this.actions, programFrame.actions.reverse());
    };
    TemplateVisitor.prototype.ElementNode = function (element) {
        var parentFrame = this.getCurrentFrame(),
            i,
            _i;
        var elementFrame = this.pushFrame();
        elementFrame.parentNode = element;
        elementFrame.children = element.children;
        elementFrame.childCount = element.children.length;
        elementFrame.mustacheCount += element.modifiers.length;
        elementFrame.blankChildTextNodes = [];
        elementFrame.symbols = parentFrame.symbols;
        var actionArgs = [element, parentFrame.childIndex, parentFrame.childCount];
        elementFrame.actions.push(['closeElement', actionArgs]);
        for (i = element.attributes.length - 1; i >= 0; i--) {
            this.visit(element.attributes[i]);
        }
        for (_i = element.children.length - 1; _i >= 0; _i--) {
            elementFrame.childIndex = _i;
            this.visit(element.children[_i]);
        }
        elementFrame.actions.push(['openElement', actionArgs.concat([elementFrame.mustacheCount, elementFrame.blankChildTextNodes.reverse()])]);
        this.popFrame();
        // Propagate the element's frame state to the parent frame
        if (elementFrame.mustacheCount > 0) {
            parentFrame.mustacheCount++;
        }
        parentFrame.childTemplateCount += elementFrame.childTemplateCount;
        push.apply(parentFrame.actions, elementFrame.actions);
    };
    TemplateVisitor.prototype.AttrNode = function (attr) {
        if (attr.value.type !== 'TextNode') {
            this.getCurrentFrame().mustacheCount++;
        }
    };
    TemplateVisitor.prototype.TextNode = function (text) {
        var frame = this.getCurrentFrame();
        if (text.chars === '') {
            frame.blankChildTextNodes.push(domIndexOf(frame.children, text));
        }
        frame.actions.push(['text', [text, frame.childIndex, frame.childCount]]);
    };
    TemplateVisitor.prototype.BlockStatement = function (node) {
        var frame = this.getCurrentFrame();
        frame.mustacheCount++;
        frame.actions.push(['block', [node, frame.childIndex, frame.childCount]]);
        if (node.inverse) {
            this.visit(node.inverse);
        }
        if (node.program) {
            this.visit(node.program);
        }
    };
    TemplateVisitor.prototype.PartialStatement = function (node) {
        var frame = this.getCurrentFrame();
        frame.mustacheCount++;
        frame.actions.push(['mustache', [node, frame.childIndex, frame.childCount]]);
    };
    TemplateVisitor.prototype.CommentStatement = function (text) {
        var frame = this.getCurrentFrame();
        frame.actions.push(['comment', [text, frame.childIndex, frame.childCount]]);
    };
    TemplateVisitor.prototype.MustacheCommentStatement = function () {
        // Intentional empty: Handlebars comments should not affect output.
    };
    TemplateVisitor.prototype.MustacheStatement = function (mustache) {
        var frame = this.getCurrentFrame();
        frame.mustacheCount++;
        frame.actions.push(['mustache', [mustache, frame.childIndex, frame.childCount]]);
    };
    // Frame helpers
    TemplateVisitor.prototype.getCurrentFrame = function () {
        return this.frameStack[this.frameStack.length - 1];
    };
    TemplateVisitor.prototype.pushFrame = function () {
        var frame = new Frame();
        this.frameStack.push(frame);
        return frame;
    };
    TemplateVisitor.prototype.popFrame = function () {
        return this.frameStack.pop();
    };
    // Returns the index of `domNode` in the `nodes` array, skipping
    // over any nodes which do not represent DOM nodes.
    function domIndexOf(nodes, domNode) {
        var index = -1,
            i,
            node;
        for (i = 0; i < nodes.length; i++) {
            node = nodes[i];

            if (node.type !== 'TextNode' && node.type !== 'ElementNode') {
                continue;
            } else {
                index++;
            }
            if (node === domNode) {
                return index;
            }
        }
        return -1;
    }

    var Block = function () {
        function Block() {

            this.type = "block";
            this.statements = [];
            this.positionals = [];
        }

        Block.prototype.toJSON = function () {
            return {
                statements: this.statements,
                locals: this.positionals
            };
        };

        Block.prototype.push = function (statement) {
            this.statements.push(statement);
        };

        return Block;
    }();

    var TemplateBlock = function (_Block) {
        (0, _emberBabel.inherits)(TemplateBlock, _Block);

        function TemplateBlock() {

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _Block.apply(this, arguments));

            _this.type = "template";
            _this.yields = new _util.DictSet();
            _this.named = new _util.DictSet();
            _this.blocks = [];
            _this.hasPartials = false;
            return _this;
        }

        TemplateBlock.prototype.toJSON = function () {
            return {
                statements: this.statements,
                locals: this.positionals,
                named: this.named.toArray(),
                yields: this.yields.toArray(),
                hasPartials: this.hasPartials
            };
        };

        return TemplateBlock;
    }(Block);

    var ComponentBlock = function (_Block2) {
        (0, _emberBabel.inherits)(ComponentBlock, _Block2);

        function ComponentBlock() {

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _Block2.apply(this, arguments));

            _this2.type = "component";
            _this2.attributes = [];
            _this2.arguments = [];
            _this2.inParams = true;
            return _this2;
        }

        ComponentBlock.prototype.push = function (statement) {
            if (this.inParams) {
                if (_wireFormat.Statements.isFlushElement(statement)) {
                    this.inParams = false;
                } else if (_wireFormat.Statements.isArgument(statement)) {
                    this.arguments.push(statement);
                } else if (_wireFormat.Statements.isAttribute(statement)) {
                    this.attributes.push(statement);
                } else if (_wireFormat.Statements.isModifier(statement)) {
                    throw new Error('Compile Error: Element modifiers are not allowed in components');
                } else {
                    throw new Error('Compile Error: only parameters allowed before flush-element');
                }
            } else {
                this.statements.push(statement);
            }
        };

        ComponentBlock.prototype.toJSON = function () {
            var args = this.arguments;
            var keys = args.map(function (arg) {
                return arg[1];
            });
            var values = args.map(function (arg) {
                return arg[2];
            });
            return {
                attrs: this.attributes,
                args: [keys, values],
                locals: this.positionals,
                statements: this.statements
            };
        };

        return ComponentBlock;
    }(Block);

    var Template = function () {
        function Template(meta) {

            this.meta = meta;
            this.block = new TemplateBlock();
        }

        Template.prototype.toJSON = function () {
            return {
                block: this.block.toJSON(),
                meta: this.meta
            };
        };

        return Template;
    }();

    var JavaScriptCompiler = function () {
        function JavaScriptCompiler(opcodes, meta) {

            this.blocks = new _util.Stack();
            this.values = [];
            this.opcodes = opcodes;
            this.template = new Template(meta);
        }

        JavaScriptCompiler.process = function (opcodes, meta) {
            var compiler = new JavaScriptCompiler(opcodes, meta);
            return compiler.process();
        };

        JavaScriptCompiler.prototype.process = function () {
            var _this3 = this;

            this.opcodes.forEach(function (_ref) {
                var opcode = _ref[0],
                    args = _ref.slice(1);

                if (!_this3[opcode]) {
                    throw new Error('unimplemented ' + opcode + ' on JavaScriptCompiler');
                }
                _this3[opcode].apply(_this3, args);
            });
            return this.template;
        };

        JavaScriptCompiler.prototype.startBlock = function (_ref2) {
            var program = _ref2[0];

            var block = new Block();
            block.positionals = program.blockParams;
            this.blocks.push(block);
        };

        JavaScriptCompiler.prototype.endBlock = function () {
            var template = this.template,
                blocks = this.blocks;

            template.block.blocks.push(blocks.pop().toJSON());
        };

        JavaScriptCompiler.prototype.startProgram = function () {
            this.blocks.push(this.template.block);
        };

        JavaScriptCompiler.prototype.endProgram = function () {};

        JavaScriptCompiler.prototype.text = function (content) {
            this.push([_wireFormat.Ops.Text, content]);
        };

        JavaScriptCompiler.prototype.append = function (trusted) {
            this.push([_wireFormat.Ops.Append, this.popValue(), trusted]);
        };

        JavaScriptCompiler.prototype.comment = function (value) {
            this.push([_wireFormat.Ops.Comment, value]);
        };

        JavaScriptCompiler.prototype.modifier = function (path) {
            var params = this.popValue();
            var hash = this.popValue();
            this.push([_wireFormat.Ops.Modifier, path, params, hash]);
        };

        JavaScriptCompiler.prototype.block = function (path, template, inverse) {
            var params = this.popValue();
            var hash = this.popValue();
            var blocks = this.template.block.blocks;
            (0, _util.assert)(typeof template !== 'number' || blocks[template] !== null, 'missing block in the compiler');
            (0, _util.assert)(typeof inverse !== 'number' || blocks[inverse] !== null, 'missing block in the compiler');
            this.push([_wireFormat.Ops.Block, path, params, hash, blocks[template], blocks[inverse]]);
        };

        JavaScriptCompiler.prototype.openElement = function (tag, blockParams) {
            if (tag.indexOf('-') !== -1) {
                this.startComponent(blockParams);
            } else {
                this.push([_wireFormat.Ops.OpenElement, tag, blockParams]);
            }
        };

        JavaScriptCompiler.prototype.flushElement = function () {
            this.push([_wireFormat.Ops.FlushElement]);
        };

        JavaScriptCompiler.prototype.closeElement = function (tag) {
            var component;

            if (tag.indexOf('-') !== -1) {
                component = this.endComponent();

                this.push([_wireFormat.Ops.Component, tag, component]);
            } else {
                this.push([_wireFormat.Ops.CloseElement]);
            }
        };

        JavaScriptCompiler.prototype.staticAttr = function (name, namespace) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.StaticAttr, name, value, namespace]);
        };

        JavaScriptCompiler.prototype.dynamicAttr = function (name, namespace) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.DynamicAttr, name, value, namespace]);
        };

        JavaScriptCompiler.prototype.trustingAttr = function (name, namespace) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.TrustingAttr, name, value, namespace]);
        };

        JavaScriptCompiler.prototype.staticArg = function (name) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.StaticArg, name.slice(1), value]);
        };

        JavaScriptCompiler.prototype.dynamicArg = function (name) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.DynamicArg, name.slice(1), value]);
        };

        JavaScriptCompiler.prototype.yield = function (to) {
            var params = this.popValue();
            this.push([_wireFormat.Ops.Yield, to, params]);
            this.template.block.yields.add(to);
        };

        JavaScriptCompiler.prototype.debugger = function () {
            this.push([_wireFormat.Ops.Debugger, null, null]);
        };

        JavaScriptCompiler.prototype.hasBlock = function (name) {
            this.pushValue([_wireFormat.Ops.HasBlock, name]);
            this.template.block.yields.add(name);
        };

        JavaScriptCompiler.prototype.hasBlockParams = function (name) {
            this.pushValue([_wireFormat.Ops.HasBlockParams, name]);
            this.template.block.yields.add(name);
        };

        JavaScriptCompiler.prototype.partial = function () {
            var params = this.popValue();
            this.push([_wireFormat.Ops.Partial, params[0]]);
            this.template.block.hasPartials = true;
        };

        JavaScriptCompiler.prototype.literal = function (value) {
            if (value === undefined) {
                this.pushValue([_wireFormat.Ops.Undefined]);
            } else {
                this.pushValue(value);
            }
        };

        JavaScriptCompiler.prototype.unknown = function (path) {
            this.pushValue([_wireFormat.Ops.Unknown, path]);
        };

        JavaScriptCompiler.prototype.arg = function (path) {
            this.template.block.named.add(path[0]);
            this.pushValue([_wireFormat.Ops.Arg, path]);
        };

        JavaScriptCompiler.prototype.get = function (path) {
            this.pushValue([_wireFormat.Ops.Get, path]);
        };

        JavaScriptCompiler.prototype.concat = function () {
            this.pushValue([_wireFormat.Ops.Concat, this.popValue()]);
        };

        JavaScriptCompiler.prototype.helper = function (path) {
            var params = this.popValue();
            var hash = this.popValue();
            this.pushValue([_wireFormat.Ops.Helper, path, params, hash]);
        };

        JavaScriptCompiler.prototype.startComponent = function (blockParams) {
            var component = new ComponentBlock();
            component.positionals = blockParams;
            this.blocks.push(component);
        };

        JavaScriptCompiler.prototype.endComponent = function () {
            var component = this.blocks.pop();
            (0, _util.assert)(component.type === 'component', "Compiler bug: endComponent() should end a component");
            return component.toJSON();
        };

        JavaScriptCompiler.prototype.prepareArray = function (size) {
            var values = [],
                i;
            for (i = 0; i < size; i++) {
                values.push(this.popValue());
            }
            this.pushValue(values);
        };

        JavaScriptCompiler.prototype.prepareObject = function (size) {
            (0, _util.assert)(this.values.length >= size, 'Expected ' + size + ' values on the stack, found ' + this.values.length);
            var keys = new Array(size),
                i;
            var values = new Array(size);
            for (i = 0; i < size; i++) {
                keys[i] = this.popValue();
                values[i] = this.popValue();
            }
            this.pushValue([keys, values]);
        };

        JavaScriptCompiler.prototype.push = function (args) {
            while (args[args.length - 1] === null) {
                args.pop();
            }
            this.blocks.current.push(args);
        };

        JavaScriptCompiler.prototype.pushValue = function (val) {
            this.values.push(val);
        };

        JavaScriptCompiler.prototype.popValue = function () {
            (0, _util.assert)(this.values.length, "No expression found on stack");
            return this.values.pop();
        };

        return JavaScriptCompiler;
    }();

    function isTrustedValue(value) {
        return value.escaped !== undefined && !value.escaped;
    }

    var TemplateCompiler = function () {
        function TemplateCompiler(options) {

            this.templateId = 0;
            this.templateIds = [];
            this.symbols = null;
            this.opcodes = [];
            this.includeMeta = false;
            this.options = options || {};
        }

        TemplateCompiler.compile = function (options, ast) {
            var templateVisitor = new TemplateVisitor();
            templateVisitor.visit(ast);
            var compiler = new TemplateCompiler(options);
            var opcodes = compiler.process(templateVisitor.actions);
            return JavaScriptCompiler.process(opcodes, options.meta);
        };

        TemplateCompiler.prototype.process = function (actions) {
            var _this4 = this;

            actions.forEach(function (_ref3) {
                var name = _ref3[0],
                    args = _ref3.slice(1);

                if (!_this4[name]) {
                    throw new Error('Unimplemented ' + name + ' on TemplateCompiler');
                }
                _this4[name].apply(_this4, args);
            });
            return this.opcodes;
        };

        TemplateCompiler.prototype.startProgram = function (program) {
            this.opcode('startProgram', program, program);
        };

        TemplateCompiler.prototype.endProgram = function () {
            this.opcode('endProgram', null);
        };

        TemplateCompiler.prototype.startBlock = function (program) {
            this.symbols = program[0].symbols;
            this.templateId++;
            this.opcode('startBlock', program, program);
        };

        TemplateCompiler.prototype.endBlock = function () {
            this.symbols = null;
            this.templateIds.push(this.templateId - 1);
            this.opcode('endBlock', null);
        };

        TemplateCompiler.prototype.text = function (_ref4) {
            var action = _ref4[0];

            this.opcode('text', action, action.chars);
        };

        TemplateCompiler.prototype.comment = function (_ref5) {
            var action = _ref5[0];

            this.opcode('comment', action, action.value);
        };

        TemplateCompiler.prototype.openElement = function (_ref6) {
            var action = _ref6[0],
                i,
                _i2;

            this.opcode('openElement', action, action.tag, action.blockParams);
            for (i = 0; i < action.attributes.length; i++) {
                this.attribute([action.attributes[i]]);
            }
            for (_i2 = 0; _i2 < action.modifiers.length; _i2++) {
                this.modifier([action.modifiers[_i2]]);
            }
            this.opcode('flushElement', null);
        };

        TemplateCompiler.prototype.closeElement = function (_ref7) {
            var action = _ref7[0];

            this.opcode('closeElement', null, action.tag);
        };

        TemplateCompiler.prototype.attribute = function (_ref8) {
            var action = _ref8[0],
                isTrusting;
            var name = action.name,
                value = action.value;

            var namespace = (0, _util.getAttrNamespace)(name);
            var isStatic = this.prepareAttributeValue(value);
            if (name.charAt(0) === '@') {
                // Arguments
                if (isStatic) {
                    this.opcode('staticArg', action, name);
                } else if (action.value.type === 'MustacheStatement') {
                    this.opcode('dynamicArg', action, name);
                } else {
                    this.opcode('dynamicArg', action, name);
                }
            } else {
                isTrusting = isTrustedValue(value);

                if (isStatic) {
                    this.opcode('staticAttr', action, name, namespace);
                } else if (isTrusting) {
                    this.opcode('trustingAttr', action, name, namespace);
                } else if (action.value.type === 'MustacheStatement') {
                    this.opcode('dynamicAttr', action, name);
                } else {
                    this.opcode('dynamicAttr', action, name, namespace);
                }
            }
        };

        TemplateCompiler.prototype.modifier = function (_ref9) {
            var action = _ref9[0];

            assertIsSimplePath(action, 'modifier');
            var parts = action.path.parts;

            this.prepareHelper(action);
            this.opcode('modifier', action, parts);
        };

        TemplateCompiler.prototype.mustache = function (_ref10) {
            var action = _ref10[0],
                to,
                params;

            if (isYield(action)) {
                to = assertValidYield(action);

                this.yield(to, action);
            } else if (isPartial(action)) {
                params = assertValidPartial(action);

                this.partial(params, action);
            } else if (isDebugger(action)) {
                assertValidDebuggerUsage(action);
                this.debugger('debugger', action);
            } else {
                this.mustacheExpression(action);
                this.opcode('append', action, !action.escaped);
            }
        };

        TemplateCompiler.prototype.block = function (_ref11) {
            var action /*, index, count*/ = _ref11[0];

            this.prepareHelper(action);
            var templateId = this.templateIds.pop();
            var inverseId = action.inverse === null ? null : this.templateIds.pop();
            this.opcode('block', action, action.path.parts, templateId, inverseId);
        };

        TemplateCompiler.prototype.arg = function (_ref12) {
            var path = _ref12[0];
            var parts = path.parts;

            this.opcode('arg', path, parts);
        };

        TemplateCompiler.prototype.mustacheExpression = function (expr) {
            if (isBuiltInHelper(expr)) {
                this.builtInHelper(expr);
            } else if (isLiteral(expr)) {
                this.opcode('literal', expr, expr.path.value);
            } else if (isArg(expr)) {
                this.arg([expr.path]);
            } else if (isHelperInvocation(expr)) {
                this.prepareHelper(expr);
                this.opcode('helper', expr, expr.path.parts);
            } else if (!isSimplePath(expr) || isSelfGet(expr) || isLocalVariable(expr, this.symbols)) {
                this.opcode('get', expr, expr.path.parts);
            } else {
                this.opcode('unknown', expr, expr.path.parts);
            }
        };

        TemplateCompiler.prototype.yield = function (to, action) {
            this.prepareParams(action.params);
            this.opcode('yield', action, to);
        };

        TemplateCompiler.prototype.debugger = function () {
            this.opcode('debugger', null);
        };

        TemplateCompiler.prototype.hasBlock = function (name, action) {
            this.opcode('hasBlock', action, name);
        };

        TemplateCompiler.prototype.hasBlockParams = function (name, action) {
            this.opcode('hasBlockParams', action, name);
        };

        TemplateCompiler.prototype.partial = function (params, action) {
            this.prepareParams(action.params);
            this.opcode('partial', action);
        };

        TemplateCompiler.prototype.builtInHelper = function (expr) {
            var name, _name;

            if (isHasBlock(expr)) {
                name = assertValidHasBlockUsage(expr.path.original, expr);

                this.hasBlock(name, expr);
            } else if (isHasBlockParams(expr)) {
                _name = assertValidHasBlockUsage(expr.path.original, expr);

                this.hasBlockParams(_name, expr);
            }
        };

        TemplateCompiler.prototype.SubExpression = function (expr) {
            if (isBuiltInHelper(expr)) {
                this.builtInHelper(expr);
            } else {
                this.prepareHelper(expr);
                this.opcode('helper', expr, expr.path.parts);
            }
        };

        TemplateCompiler.prototype.PathExpression = function (expr) {
            if (expr.data) {
                this.arg([expr]);
            } else {
                this.opcode('get', expr, expr.parts);
            }
        };

        TemplateCompiler.prototype.StringLiteral = function (action) {
            this.opcode('literal', null, action.value);
        };

        TemplateCompiler.prototype.BooleanLiteral = function (action) {
            this.opcode('literal', null, action.value);
        };

        TemplateCompiler.prototype.NumberLiteral = function (action) {
            this.opcode('literal', null, action.value);
        };

        TemplateCompiler.prototype.NullLiteral = function (action) {
            this.opcode('literal', null, action.value);
        };

        TemplateCompiler.prototype.UndefinedLiteral = function (action) {
            this.opcode('literal', null, action.value);
        };

        TemplateCompiler.prototype.opcode = function (name, action) {
            for (_len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
                args[_key - 2] = arguments[_key];
            }

            var opcode = [name].concat(args),
                _len,
                args,
                _key;
            if (this.includeMeta && action) {
                opcode.push(this.meta(action));
            }
            this.opcodes.push(opcode);
        };

        TemplateCompiler.prototype.prepareHelper = function (expr) {
            assertIsSimplePath(expr, 'helper');
            var params = expr.params,
                hash = expr.hash;

            this.prepareHash(hash);
            this.prepareParams(params);
        };

        TemplateCompiler.prototype.preparePath = function (path) {
            this.opcode('literal', path, path.parts);
        };

        TemplateCompiler.prototype.prepareParams = function (params) {
            var i, param;

            if (!params.length) {
                this.opcode('literal', null, null);
                return;
            }
            for (i = params.length - 1; i >= 0; i--) {
                param = params[i];

                (0, _util.assert)(this[param.type], 'Unimplemented ' + param.type + ' on TemplateCompiler');
                this[param.type](param);
            }
            this.opcode('prepareArray', null, params.length);
        };

        TemplateCompiler.prototype.prepareHash = function (hash) {
            var pairs = hash.pairs,
                i,
                _pairs$i,
                key,
                value;
            if (!pairs.length) {
                this.opcode('literal', null, null);
                return;
            }
            for (i = pairs.length - 1; i >= 0; i--) {
                _pairs$i = pairs[i], key = _pairs$i.key, value = _pairs$i.value;


                (0, _util.assert)(this[value.type], 'Unimplemented ' + value.type + ' on TemplateCompiler');
                this[value.type](value);
                this.opcode('literal', null, key);
            }
            this.opcode('prepareObject', null, pairs.length);
        };

        TemplateCompiler.prototype.prepareAttributeValue = function (value) {
            // returns the static value if the value is static
            switch (value.type) {
                case 'TextNode':
                    this.opcode('literal', value, value.chars);
                    return true;
                case 'MustacheStatement':
                    this.attributeMustache([value]);
                    return false;
                case 'ConcatStatement':
                    this.prepareConcatParts(value.parts);
                    this.opcode('concat', value);
                    return false;
            }
        };

        TemplateCompiler.prototype.prepareConcatParts = function (parts) {
            var i, part;

            for (i = parts.length - 1; i >= 0; i--) {
                part = parts[i];

                if (part.type === 'MustacheStatement') {
                    this.attributeMustache([part]);
                } else if (part.type === 'TextNode') {
                    this.opcode('literal', null, part.chars);
                }
            }
            this.opcode('prepareArray', null, parts.length);
        };

        TemplateCompiler.prototype.attributeMustache = function (_ref13) {
            var action = _ref13[0];

            this.mustacheExpression(action);
        };

        TemplateCompiler.prototype.meta = function (node) {
            var loc = node.loc;
            if (!loc) {
                return [];
            }
            var source = loc.source,
                start = loc.start,
                end = loc.end;

            return ['loc', [source || null, [start.line, start.column], [end.line, end.column]]];
        };

        return TemplateCompiler;
    }();

    function isHelperInvocation(mustache) {
        return mustache.params && mustache.params.length > 0 || mustache.hash && mustache.hash.pairs.length > 0;
    }
    function isSimplePath(mustache) {
        var parts = mustache.path.parts;

        return parts.length === 1;
    }
    function isSelfGet(mustache) {
        var parts = mustache.path.parts;

        return parts[0] === null;
    }
    function isLocalVariable(mustache, symbols) {
        var parts = mustache.path.parts;

        return parts.length === 1 && symbols && symbols.hasLocalVariable(parts[0]);
    }
    function isYield(_ref14) {
        var path = _ref14.path;

        return path.original === 'yield';
    }
    function isPartial(_ref15) {
        var path = _ref15.path;

        return path.original === 'partial';
    }
    function isDebugger(_ref16) {
        var path = _ref16.path;

        return path.original === 'debugger';
    }
    function isArg(_ref17) {
        var path = _ref17.path;

        return path.data;
    }
    function isLiteral(_ref18) {
        var path = _ref18.path;

        return path.type === 'StringLiteral' || path.type === 'BooleanLiteral' || path.type === 'NumberLiteral' || path.type === 'NullLiteral' || path.type === 'UndefinedLiteral';
    }
    function isHasBlock(_ref19) {
        var path = _ref19.path;

        return path.original === 'has-block';
    }
    function assertIsSimplePath(expr, context) {
        var original, line;

        if (!isSimplePath(expr)) {
            original = expr.path.original, line = expr.loc.start.line;


            throw new Error('`' + original + '` is not a valid name for a ' + context + ' on line ' + line + '.');
        }
    }
    function isHasBlockParams(_ref20) {
        var path = _ref20.path;

        return path.original === 'has-block-params';
    }
    function isBuiltInHelper(expr) {
        return isHasBlock(expr) || isHasBlockParams(expr);
    }
    function assertValidYield(_ref21) {
        var hash = _ref21.hash;

        var pairs = hash.pairs;
        if (pairs.length === 1 && pairs[0].key !== 'to' || pairs.length > 1) {
            throw new Error('yield only takes a single named argument: \'to\'');
        } else if (pairs.length === 1 && pairs[0].value.type !== 'StringLiteral') {
            throw new Error('you can only yield to a literal value');
        } else if (pairs.length === 0) {
            return 'default';
        } else {
            return pairs[0].value.value;
        }
    }
    function assertValidPartial(_ref22) {
        var params = _ref22.params,
            hash = _ref22.hash,
            escaped = _ref22.escaped,
            loc = _ref22.loc;

        if (params && params.length !== 1) {
            throw new Error('Partial found with no arguments. You must specify a template name. (on line ' + loc.start.line + ')');
        } else if (hash && hash.pairs.length > 0) {
            throw new Error('partial does not take any named arguments (on line ' + loc.start.line + ')');
        } else if (!escaped) {
            throw new Error('{{{partial ...}}} is not supported, please use {{partial ...}} instead (on line ' + loc.start.line + ')');
        }
        return params;
    }
    function assertValidHasBlockUsage(type, _ref23) {
        var params = _ref23.params,
            hash = _ref23.hash,
            loc = _ref23.loc;

        if (hash && hash.pairs.length > 0) {
            throw new Error(type + ' does not take any named arguments');
        }
        if (params.length === 0) {
            return 'default';
        } else if (params.length === 1) {
            if (params[0].type === 'StringLiteral') {
                return params[0].value;
            } else {
                throw new Error('you can only yield to a literal value (on line ' + loc.start.line + ')');
            }
        } else {
            throw new Error(type + ' only takes a single positional argument (on line ' + loc.start.line + ')');
        }
    }
    function assertValidDebuggerUsage(_ref24) {
        var params = _ref24.params,
            hash = _ref24.hash;

        if (hash && hash.pairs.length > 0) {
            throw new Error('debugger does not take any named arguments');
        }
        if (params.length === 0) {
            return 'default';
        } else {
            throw new Error('debugger does not take any positional arguments');
        }
    }

    var defaultId = function () {
        var idFn = void 0;
        return function () {
            var crypto;

            if (!idFn) {
                if (typeof _nodeModule.require === 'function') {
                    try {
                        /* tslint:disable:no-require-imports */
                        crypto = (0, _nodeModule.require)('crypto');
                        /* tslint:enable:no-require-imports */

                        idFn = function (src) {
                            var hash = crypto.createHash('sha1');
                            hash.update(src, 'utf8');
                            // trim to 6 bytes of data (2^48 - 1)
                            return hash.digest('base64').substring(0, 8);
                        };
                        idFn("test");
                    } catch (e) {
                        idFn = null;
                    }
                }
                if (!idFn) {
                    idFn = function () {
                        return null;
                    };
                }
            }
            return idFn;
        };
    }();


    exports.precompile = function (string, options) {
        var opts = options || {
            id: defaultId(),
            meta: {}
        };
        var ast = (0, _syntax.preprocess)(string, opts);

        var _TemplateCompiler$com = TemplateCompiler.compile(opts, ast),
            block = _TemplateCompiler$com.block,
            meta = _TemplateCompiler$com.meta;

        var idFn = opts.id || defaultId();
        var blockJSON = JSON.stringify(block.toJSON());
        var templateJSONObject = {
            id: idFn(JSON.stringify(meta) + blockJSON),
            block: blockJSON,
            meta: meta
        };
        // JSON is javascript
        return JSON.stringify(templateJSONObject);
    };
    exports.TemplateVisitor = TemplateVisitor;
});