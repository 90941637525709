define("ember-webrtc-components/mixins/webrtc-service/webrtc-debug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Mixin = Ember.Mixin,
      on = Ember.on,
      run = Ember.run,
      RSVP = Ember.RSVP,
      observer = Ember.observer,
      computed = Ember.computed;

  function _extractProperties(obj, properties) {
    var ret = {};
    properties.forEach(function (prop) {
      ret[prop] = obj[prop];
    });
    return ret;
  }

  var _default = Mixin.create({
    recording: false,
    snapshots: null,
    recordingTimeout: null,
    recordingDurationTimeout: null,
    options: null,
    clientInfo: null,
    debugInfos: null,
    debugTimeout: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('snapshots', Ember.A());
    },
    hideAudioTracks: computed('options.audio', function () {
      return this.get('options.audio') === false;
    }),
    hideVideoTracks: computed('options.video', function () {
      return this.get('options.video') === false;
    }),
    participants: computed.reads('activeConversation.participants'),
    hasParticipants: computed('participants.[]', function () {
      return this.get('participants.length');
    }),
    debugWebrtc: function debugWebrtc(options) {
      var _this = this;

      this._cleanup();

      options = options || {};
      this.set('options', options);
      Ember.Logger.log('starting webrtc-debugger');

      if (window.Orgspan) {
        window.Orgspan.webrtcDebugger = this;
      }

      var browserInfo = navigator.userAgent;
      var browserInfoPrefix = 'Browser: ';
      var hostedContext = this.get('applicationService.hostedContext');

      if (hostedContext && hostedContext.isHosted()) {
        browserInfoPrefix = 'Desktop App: ';
      }

      this.set('clientInfo', browserInfoPrefix + browserInfo);

      if (!this.get('activeConversation')) {
        this.logger.warn('No active webrtc conversation to debug');

        if (options.json) {
          return RSVP.resolve({});
        }
      }

      this._buildInfos();

      if (options.json) {
        var deferred = RSVP.defer();
        this.set('deferred', deferred);
        return deferred.promise;
      } else if (options.record) {
        var _deferred = RSVP.defer();

        this.set('recording', true);
        this.set('recordingDurationTimeout', run.later(function () {
          _this.set('recording', false);

          _this.logger.log('recorded snapshots', _this.get('snapshots'));

          _deferred.resolve(_this.get('snapshots'));
        }, options.record * 1000));
        return _deferred.promise;
      }

      this.set('showDebugModal', true);
      return RSVP.resolve();
    },
    _cleanup: on('willDestroy', function () {
      Ember.Logger.log('cleaning up debug info loop');
      this.set('debugInfos', Ember.A());
      run.cancel(this.get('debugTimeout'));
      run.cancel(this.get('recordingTimeout'));
      this.set('recording', false);
      Ember.Logger.log('cleanup complete');
    }),
    _cleanupDebugInfo: function _cleanupDebugInfo(debugInfo) {
      debugInfo.setProperties({
        sessionInfos: Ember.A(),
        streamInfos: Ember.A(),
        trackInfos: Ember.A()
      });
    },
    _addColorToParticipants: function _addColorToParticipants(conversationInfo) {
      var _this2 = this;

      conversationInfo.get('webrtcConversation.participants').forEach(function (p) {
        if (!p.get('colorHex')) {
          _this2.rerollParticipantColor(p);
        }

        var streamInfo = conversationInfo.get('streamInfos').find(function (i) {
          return i.streamId === p.get('cameraStream.id');
        });

        if (streamInfo) {
          streamInfo.set('participant', p);
        }
      });
    },
    _associateStreamWithParticipant: function _associateStreamWithParticipant(conversationInfo) {
      conversationInfo.get('webrtcConversation.participants').forEach(function (p) {
        var streamInfos = conversationInfo.get('streamInfos').filter(function (s) {
          return s.get('streamId') === p.get('cameraStream.id') || s.get('streamId') === p.get('screenStream.id');
        });
        streamInfos.forEach(function (stream) {
          stream.set('participant', p);
        });
      });
    },
    _stringifyMedia: function _stringifyMedia(key, value) {
      if (value instanceof RTCRtpTransceiver) {
        return _extractProperties(value, ['currentDirection', 'direction', 'mid', 'sender', 'receiver']);
      } else if (value instanceof RTCRtpReceiver || value instanceof RTCRtpSender) {
        return _extractProperties(value, ['track']);
      } else if (value instanceof MediaStreamTrack) {
        return _extractProperties(value, ['id', 'label', 'kind', 'enabled', 'muted', 'readystate']);
      }

      return value;
    },
    createSnapshot: function createSnapshot() {
      var _this3 = this;

      var snapshots = [];
      this.get('conversations').forEach(function (conversation) {
        var info = _this3.get('debugInfos').find(function (dInfo) {
          return dInfo.get('webrtcConversation') === conversation;
        });

        var participants = conversation.get('participants').map(function (p) {
          return p.getProperties('person.name', 'person.id', 'inLastN', 'audioMuted', 'videoMuted', 'local', 'person.presence');
        });
        var sessions = JSON.parse(JSON.stringify(info.get('sessionInfos')));
        var streamString = JSON.stringify(info.get('streamInfos'), function (key, value) {
          if (key === 'participant') {
            return participants.find(function (p) {
              return Ember.get(p, 'person.id') === value.get('person._id');
            });
          }

          return value;
        });
        var streams = JSON.parse(streamString);
        var transceivers = [];
        conversation.get('sessions').forEach(function (session) {
          transceivers.push.apply(transceivers, _toConsumableArray(JSON.parse(JSON.stringify(_this3.getNativePeerConnection(session).getTransceivers(), _this3._stringifyMedia))));
        });
        snapshots.push({
          type: 'webrtcDmp',
          roomJid: conversation.get('jid'),
          date: new Date(),
          clientInfo: _this3.get('clientInfo'),
          conversationId: conversation.get('conversationId'),
          transceivers: transceivers,
          participants: participants,
          sessions: sessions,
          streams: streams,
          speakers: conversation.get('rawSpeakers'),
          trackOwners: conversation.get('trackOwners'),
          dominantTracks: conversation.get('dominantStream') && JSON.parse(JSON.stringify(conversation.get('dominantStream').getTracks(), _this3._stringifyMedia)),
          camera: _this3.get('defaultCamera'),
          microphone: _this3.get('defaultMicrophone'),
          outputDevice: _this3.get('defaultOutputDevice')
        });
      });
      return snapshots;
    },
    _recordSnapshots: function _recordSnapshots() {
      var _this4 = this;

      var recordingTimeout = run.later(function () {
        _this4.get('snapshots').pushObject(_this4.createSnapshot());

        _this4._recordSnapshots();
      }, 500);
      this.set('recordingTimeout', recordingTimeout);
    },
    // eslint-disable-next-line ember/no-observers
    _handleRecording: observer('recording', function () {
      if (this.get('recording')) {
        this.set('snapshots', Ember.A());

        this._recordSnapshots();
      } else {
        run.cancel(this.get('recordingTimeout'));
        this.logger.log('snapshots', this.get('snapshots'));
      }
    }),
    rerollParticipantColor: function rerollParticipantColor(participant) {
      var maxHexDecimalVal = 16777215;
      var hex = Math.floor(Math.random() * maxHexDecimalVal).toString(16);
      participant.set('colorHex', '#' + hex);
    },
    getConversationDebugInfo: function getConversationDebugInfo(webrtcConversation) {
      var info = this.get('debugInfos').find(function (dInfo) {
        return dInfo.get('webrtcConversation') === webrtcConversation;
      });

      if (!info) {
        info = Ember.Object.create({
          webrtcConversation: webrtcConversation,
          sessionInfos: Ember.A(),
          streamInfos: Ember.A()
        });
        this.get('debugInfos').pushObject(info);
      }

      return info;
    },
    _buildInfos: function _buildInfos() {
      var _this5 = this;

      this.get('debugInfos').forEach(function (debugInfo) {
        if (!_this5.get('conversations').some(function (conversation) {
          return conversation === debugInfo.get('webrtcConversation');
        })) {
          _this5._cleanupDebugInfo(debugInfo);
        }
      });
      this.get('conversations').forEach(function (webrtcConversation) {
        var conversationInfo = _this5.getConversationDebugInfo(webrtcConversation);

        var sessionInfos = conversationInfo.get('sessionInfos');
        var streamInfos = conversationInfo.get('streamInfos');
        var activeSessions = webrtcConversation.get('sessions'); // prune old sessions and streams belonging to those sessions

        sessionInfos.forEach(function (sessionInfo) {
          if (!activeSessions.any(function (s) {
            return s.sid === sessionInfo.get('sid');
          })) {
            sessionInfos.removeObject(sessionInfo);
            var streamInfosToRemove = streamInfos.filter(function (stream) {
              return stream.sid === sessionInfo.get('sid');
            });
            streamInfos.removeObjects(streamInfosToRemove);
          }
        });

        if (webrtcConversation.get('localMedia')) {
          _this5._addUpdateStreamInfos(streamInfos, webrtcConversation.get('localMedia.localStreams'), 'local');
        } // add/update sessions and their streams


        activeSessions.forEach(function (activeSession) {
          var sessionInfo = sessionInfos.findBy('sid', activeSession.sid);

          if (!sessionInfo) {
            sessionInfo = Ember.Object.create({
              sid: activeSession.sid,
              peerID: activeSession.peerID,
              isInitiator: activeSession.isInitiator
            });
            sessionInfos.addObject(sessionInfo);
          }

          sessionInfo.setProperties({
            connectionState: activeSession._connectionState,
            state: activeSession.state,
            ended: activeSession.ended
          });

          _this5._addUpdateStreamInfos(streamInfos, activeSession.streams, activeSession.sid);
        });

        _this5._checkRemoteTracks(conversationInfo);

        _this5._addColorToParticipants(conversationInfo);

        _this5._associateStreamWithParticipant(conversationInfo);
      });
      this.set('debugTimeout', run.later(this, this._buildInfos, 500));
    },
    _addUpdateStreamInfos: function _addUpdateStreamInfos(streamInfos, streams, sid) {
      var _this6 = this;

      var existingStreamInfosForSession = Ember.A(streamInfos).filterBy('sid', sid); // remove old stream info that are no longer valid

      var existingToRemove = existingStreamInfosForSession.filter(function (existing) {
        return !streams.any(function (stream) {
          return existing.get('streamId') === stream.id;
        });
      });
      streamInfos.removeObjects(existingToRemove); // add/update streamInfos

      streams.forEach(function (stream) {
        var streamInfo = existingStreamInfosForSession.find(function (s) {
          return s.streamId === stream.id;
        });

        if (!streamInfo) {
          streamInfo = Ember.Object.create({
            sid: sid,
            trackInfos: Ember.A(),
            streamId: stream.id
          });
          streamInfos.addObject(streamInfo);
        }

        streamInfo.setProperties({
          active: stream.active,
          hasVideo: stream.getVideoTracks().length > 0,
          hasAudio: stream.getAudioTracks().length > 0
        });
        var tracks = stream.getTracks();

        if (_this6.get('hideAudioTracks')) {
          tracks = tracks.filter(function (t) {
            return t.kind === 'video';
          });
        }

        if (_this6.get('hideVideoTracks')) {
          tracks = tracks.filter(function (t) {
            return t.kind === 'audio';
          });
        }

        _this6._buildTrackInfo(streamInfo, tracks);
      });
    },
    _buildTrackInfo: function _buildTrackInfo(streamInfo, tracks) {
      var trackInfos = streamInfo.get('trackInfos'); // prune old tracks

      trackInfos.forEach(function (trackInfo) {
        if (!tracks.any(function (s) {
          return s.id === trackInfo.get('trackId');
        })) {
          trackInfos.removeObject(trackInfo);
        }
      });
      tracks.forEach(function (track) {
        var trackInfo = trackInfos.findBy('trackId', track.id);

        if (!trackInfo) {
          trackInfo = Ember.Object.create({
            trackId: track.id,
            kind: track.kind,
            bytesReceived: 0,
            local: streamInfo.get('sid') === 'local'
          });
          trackInfos.addObject(trackInfo);
        }

        trackInfo.setProperties({
          enabled: track.enabled,
          muted: track.muted,
          state: track.readyState
        });
      });
    },
    _checkRemoteTracks: function _checkRemoteTracks(conversationInfo) {
      var _this7 = this;

      conversationInfo.get('webrtcConversation.sessions').forEach(function (session) {
        session.pc.getStats(function (err, stats) {
          _this7._processStats(err, stats, conversationInfo);
        });
      });
    },
    _processStats: function _processStats(err, stats, conversationInfo) {
      if (err) {
        Ember.Logger.warn('Error reading stats for debug', {
          err: err
        });
      }

      var trackStats = Array.from(stats.values()).filter(function (s) {
        return s.type === 'ssrc';
      });
      conversationInfo.get('streamInfos').forEach(function (streamInfo) {
        if (streamInfo.get('sid') !== 'local') {
          streamInfo.get('trackInfos').forEach(function (trackInfo) {
            var stat = trackStats.find(function (s) {
              return s.googTrackId === trackInfo.get('trackId');
            });

            if (stat) {
              var received = parseInt(stat.bytesReceived);
              trackInfo.set('activelyReceiving', received > trackInfo.get('bytesReceived'));
              trackInfo.set('bytesReceived', received);
            } else {
              trackInfo.set('activelyReceiving', false);
            }
          });
        }
      });

      if (this.get('options.json')) {
        this.get('deferred').resolve(this.createSnapshot());
      }
    }
  });

  _exports.default = _default;
});