define('ember-engine-custom-fields/services/validation', ['exports', 'ember-engine-custom-fields/mixins/safe-set'], function (exports, _safeSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Service = Ember.Service,
        A = Ember.A,
        run = Ember.run,
        get = Ember.get,
        set = Ember.set,
        computed = Ember.computed;

    var VALIDATION_UPDATE_TIME = 10;
    var DEFAULT_SHOW_VALIDATION_ERROR = 'showValidationError';

    exports.default = Service.extend(_safeSet.default, {
        activeField: null,
        validationRegistry: A([]),
        isValid: true,

        isActiveFieldShowingValidationErrors: computed('activeField', 'validationRegistry', function () {
            var _this = this;

            return A(this.get('validationRegistry').filter(function (item) {
                return item.get('field') === _this.get('activeField');
            })).any(function (item) {
                return get(item, 'showValidationError');
            });
        }),

        _updateIsValid: function _updateIsValid() {
            var _this2 = this;

            var validationComponentOrValidateAll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
            var validationErrorKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

            run.scheduleOnce('actions', function () {
                var isValid = true;
                // calling validate on each component registered so they do their own local validation error display
                // if any do not validate mark as invalid and show their validation error
                var showErrorsForAllInvalid = typeof validationComponentOrValidateAll === 'boolean';

                _this2.get('validationRegistry').forEach(function (item) {
                    var itemValidate = run.bind(item, item.get('_validate'));

                    var currentErrorValidationKey = item === validationComponentOrValidateAll ? validationErrorKey || DEFAULT_SHOW_VALIDATION_ERROR : DEFAULT_SHOW_VALIDATION_ERROR;

                    // if the component is already showing validation errors we don't want to turn them off
                    var itemShowingError = get(item, currentErrorValidationKey);

                    // if the item is the current one passed in to the update validation function - it should be showing validation
                    // errors if it there are any validation errors.  If the validationComponentOrValidateAll is boolean true that means
                    // all components should show validation errors if they are present
                    var showValidationError = !showErrorsForAllInvalid ? validationComponentOrValidateAll === item || itemShowingError : showErrorsForAllInvalid;

                    // calls validate on individual items to do their individual validation pass
                    var itemIsValid = itemValidate ? itemValidate(currentErrorValidationKey) : true;

                    if (!itemIsValid) {
                        //if the item is not valid - the validation error display may or may not display depending on the context
                        set(item, currentErrorValidationKey, showValidationError);

                        // but the set value for isValid will be set nonetheless
                        isValid = false;
                    } else {
                        // if the item is not invalid then it should not have validation display set to true
                        set(item, currentErrorValidationKey, false);
                    }
                });
                if (_this2._notDestroyed()) {
                    _this2.set('isValid', isValid);
                    _this2.notifyPropertyChange('isActiveFieldShowingValidationErrors');
                }
            });
        },
        updateActiveField: function updateActiveField(field) {
            this.set('activeField', field);
        },
        updateValidation: function updateValidation(validationComponent, validationErrorKey) {
            var _this3 = this;

            run.join(function () {
                run.debounce(_this3, _this3._updateIsValid, validationComponent, validationErrorKey, VALIDATION_UPDATE_TIME);
            });
        },
        addToValidation: function addToValidation(validatable) {
            var validationRegistry = this.get('validationRegistry');
            if (!validationRegistry.includes(validatable)) {
                validationRegistry.addObject(validatable);
            }
        },
        removeFromValidation: function removeFromValidation(validatable) {
            var validationRegistry = this.get('validationRegistry');
            if (validationRegistry.includes(validatable)) {
                validationRegistry.removeObject(validatable);
            }
        }
    });
});