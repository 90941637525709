define("ember-purecloud-components/services/notification", ["exports", "lodash.merge", "ember-purecloud-components/components/desktop-notification/component"], function (_exports, _lodash, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULTS = {
    hideAfter: 8,
    forceBrowserNotification: false,
    canShowNativeChatNotification: !!window._HostedContextFunctions,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet|Silk/i.test(navigator.userAgent)
  };

  var _default = Ember.Service.extend({
    getOptions: function getOptions(type, title, message, extra) {
      return (0, _lodash.default)({}, DEFAULTS, extra, {
        title: title,
        message: message,
        type: type
      });
    },
    info: function info(title, message) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var owner = Ember.getOwner(this);
      options = this.getOptions.apply(this, ['info'].concat(Array.prototype.slice.call(arguments)));
      return _component.default.show(options, owner, false);
    },
    error: function error(title, message) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var owner = Ember.getOwner(this);
      options = this.getOptions.apply(this, ['error'].concat(Array.prototype.slice.call(arguments)));
      return _component.default.show(options, owner, false);
    },
    success: function success(title, message) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var owner = Ember.getOwner(this);
      options = this.getOptions.apply(this, ['success'].concat(Array.prototype.slice.call(arguments)));
      return _component.default.show(options, owner, false);
    },

    /**
       @desc Dismisses a notification with the given ID, hiding it in the tray. If no notification with the given ID is found, nothing will be done.
       @param {string} id
    */
    dismiss: function dismiss(id) {
      if (!id || !_component.LocalMessenger) {
        return;
      }

      var history = Ember.A(_component.LocalMessenger.history || []);
      var notification = history.find(function (n) {
        return n && n.msg && n.msg.options && n.msg.options.id === id;
      });

      if (notification && typeof notification.msg.hide === 'function') {
        notification.msg.hide();
      }

      return notification;
    },
    // Legacy support
    show: function show(options) {
      var owner = Ember.getOwner(this);
      return _component.default.show(options, owner, false);
    }
  });

  _exports.default = _default;
});