define('ember-custom-fields-components/components/custom-fields/controls/drop-down/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default', 'ember-custom-fields-components/components/custom-fields/controls/drop-down/template'], function (exports, _default, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _default.default.extend({
        layout: _template.default,
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey) || null;

            if (savedData) {
                this.set('control.value', savedData);
            }
        },

        fieldValue: Ember.computed('control', 'fieldData', function () {
            var enumList = void 0,
                enumItem = void 0;
            var control = this.get('control');
            var fieldData = this.get('fieldData');

            if (control && fieldData) {
                var fieldKey = control.get('key');
                var fieldValue = fieldData[fieldKey];
                enumList = Ember.A(control.get('enumList'));
                enumItem = enumList.findBy('key', fieldValue);
                return enumItem ? enumItem.title : fieldValue;
            } else {
                return null;
            }
        }),
        actions: {
            selectItem: function selectItem(value) {
                if (this.get('control')) {
                    this.set('control.value', value);
                }
                if (this.get('setCustomFieldValidation')) {
                    this.get('setCustomFieldValidation')();
                }
            }
        }
    });
});