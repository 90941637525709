define('ember-chat-components/components/chat-panel/conversation/participant-pane/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/participant-pane/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { computed, Component } = _ember.default;

    exports.default = Component.extend({
        classNames: ['participant-pane'],
        layout: _template.default,

        participants: _ember.default.computed.reads('activeRoom.sortedParticipants'),

        showAvatars: computed('participants.length', function () {
            return this.get('participants.length') < 15;
        })
    });
});