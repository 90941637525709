define("ember-purecloud-components/utils/messenger", ["exports", "messenger-hubspot", "messenger-hubspot-flat-theme"], function (_exports, _messengerHubspot, _messengerHubspotFlatTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Messenger = window.Messenger;
  var _default = Messenger;
  _exports.default = _default;
});