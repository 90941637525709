define('ember-engine-custom-fields/engine', ['exports', 'ember-engines/engine', 'ember-resolver', 'ember-load-initializers', 'ember-engine-custom-fields/config/environment'], function (exports, _engine, _emberResolver, _emberLoadInitializers, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var modulePrefix = _environment.default.modulePrefix;


    var Eng = _engine.default.extend({
        modulePrefix: modulePrefix,
        Resolver: _emberResolver.default,
        dependencies: {
            services: ['application', 'intl', 'session', 'stash-logger', 'ajax', 'store', 'notification', 'permissions', 'sidebar', 'adminNav', 'adminui', 'schema'],
            externalRoutes: ['personDetail', 'admin']
        }
    });

    (0, _emberLoadInitializers.default)(Eng, modulePrefix);

    exports.default = Eng;
});