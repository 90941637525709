define("webrtc-troubleshoot/utils/video-frame-checker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global WebrtcTroubleshooter */
  var _default = WebrtcTroubleshooter.VideoFrameChecker;
  _exports.default = _default;
});