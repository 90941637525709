enifed('ember-template-compiler/plugins/transform-top-level-components', ['exports'], function (exports) {
  'use strict';

  exports.default = TransformTopLevelComponents;
  function TransformTopLevelComponents() {
    // set later within HTMLBars to the syntax package
    this.syntax = null;
  }

  /**
    @private
    @method transform
    @param {AST} The AST to be transformed.
  */
  TransformTopLevelComponents.prototype.transform = function (ast) {
    hasSingleComponentNode(ast, function (component) {
      component.tag = '@' + component.tag;
      component.isStatic = true;
    });

    return ast;
  };

  function hasSingleComponentNode(program, componentCallback) {
    var loc = program.loc,
        body = program.body,
        i,
        curr;

    if (!loc || loc.start.line !== 1 || loc.start.column !== 0) {
      return;
    }

    var lastComponentNode = void 0;
    var nodeCount = 0;

    for (i = 0; i < body.length; i++) {
      curr = body[i];

      // text node with whitespace only

      if (curr.type === 'TextNode' && /^[\s]*$/.test(curr.chars)) {
        continue;
      }

      // has multiple root elements if we've been here before
      if (nodeCount++ > 0) {
        return false;
      }

      if (curr.type === 'ComponentNode' || curr.type === 'ElementNode') {
        lastComponentNode = curr;
      }
    }

    if (!lastComponentNode) {
      return;
    }

    if (lastComponentNode.type === 'ComponentNode') {
      componentCallback(lastComponentNode);
    }
  }
});