define('ember-intl/adapters/default', ['exports', 'ember', 'ember-getowner-polyfill', 'ember-intl/models/translation'], function (exports, _ember, _emberGetownerPolyfill, _emberIntlModelsTranslation) {
  var assert = _ember['default'].assert;

  function normalize(fullName) {
    assert('Lookup name must be a string', typeof fullName === 'string');

    return fullName.toLocaleLowerCase();
  }

  var DefaultIntlAdapter = _ember['default'].Object.extend({
    translationsFor: function translationsFor(localeName) {
      if (typeof localeName !== 'string') {
        throw new Error('locale name required for translation lookup');
      }

      var owner = (0, _emberGetownerPolyfill['default'])(this);
      var lookupName = 'ember-intl@translation:' + normalize(localeName);
      var Type = owner._lookupFactory('model:ember-intl-translation') || _emberIntlModelsTranslation['default'];

      if (localeName && localeName instanceof Type) {
        return localeName;
      }

      if (!owner.hasRegistration(lookupName)) {
        owner.register(lookupName, Type.extend({}));
      }

      return owner.lookup(lookupName);
    },

    has: function has(localeName, translationKey) {
      var translations = this.translationsFor(localeName);

      if (translations) {
        return translations.has(translationKey);
      }

      return false;
    },

    findTranslationByKey: function findTranslationByKey(locales, translationKey) {
      var len = locales.length;
      var i = 0;

      for (; i < len; i++) {
        var locale = locales[i];
        var translations = this.translationsFor(locale);

        if (translations && translations.has(translationKey)) {
          return translations.getValue(translationKey);
        }
      }
    }
  });

  exports['default'] = DefaultIntlAdapter;
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */