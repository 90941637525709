define("@ember-decorators/controller/index", ["exports", "@ember-decorators/utils/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.controller = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
    Decorator that injects a controller into a controller as the decorated
    property
  
     ```javascript
    export default class IndexController extends Controller {
      @controller application;
    }
    ```
  
    @function
    @param {string} controllerName? - The name of the controller to inject. If not provided, the property name will be used
    @return {Controller}
  */
  var controller = (0, _computed.computedDecoratorWithParams)(function (_ref, params) {
    var _Ember$inject;

    var key = _ref.key;
    return params.length > 0 ? (_Ember$inject = Ember.inject).controller.apply(_Ember$inject, _toConsumableArray(params)) : Ember.inject.controller(key);
  });
  _exports.controller = controller;
});