define('ember-intl/formatters/format-number', ['exports', 'ember', 'intl-format-cache', 'ember-intl/formatters/-base'], function (exports, _ember, _intlFormatCache, _emberIntlFormattersBase) {
  var computed = _ember['default'].computed;

  var FormatNumber = _emberIntlFormattersBase['default'].extend({
    formatType: 'number',

    formatter: computed({
      get: function get() {
        return (0, _intlFormatCache['default'])(Intl.NumberFormat);
      }
    }).readOnly(),

    format: function format(value, options) {
      var ctx = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      return this._format(value, this.filterSupporedOptions(options), null, ctx);
    }
  });

  FormatNumber.reopenClass({
    supportedOptions: ['localeMatcher', 'style', 'currency', 'currencyDisplay', 'useGrouping', 'minimumIntegerDigits', 'minimumFractionDigits', 'maximumFractionDigits', 'minimumSignificantDigits', 'maximumSignificantDigits']
  });

  exports['default'] = FormatNumber;
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */