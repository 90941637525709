define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/github/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/github/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const NOTIFICATION_TYPE = 'message.widget.data.notificationType';
    const ISSUE = 'issues';
    const CLOSED = 'closed';
    const OPENED = 'opened';
    const I18N_BASE = 'chat.github.';

    const GitHubWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-github'],
        layout: _template.default,

        intl: _ember.default.inject.service(),

        expanded: false,

        isIssue: _ember.default.computed.equal(NOTIFICATION_TYPE, ISSUE),

        webhooksStatus: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            if (widget.issueAction === CLOSED) {
                return 'resolved';
            } else if (widget.issueAction === OPENED) {
                return 'acknowledged';
            }
            return '';
        }),

        webhooksHeader: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            const intl = this.get('intl');
            if (this.get(NOTIFICATION_TYPE) === ISSUE) {
                return intl.t(I18N_BASE + widget.issueAction, {
                    author: widget.senderName,
                    repo: widget.repo,
                    repoUrl: widget.repoUrl
                });
            } else {
                return intl.t(I18N_BASE + 'changesPushed', {
                    repo: widget.repo
                });
            }
        }),

        webhooksTitle: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return this.get('intl').t(I18N_BASE + 'changesPushedTitle', {
                author: widget.senderName,
                repoUrl: widget.repoUrl,
                repo: widget.repo
            });
        }),

        actions: {
            toggleExpanded() {
                this.toggleProperty('expanded');
            }
        }
    });

    exports.default = GitHubWidget;
});