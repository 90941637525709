define('ember-engine-custom-fields/services/breadcrumb-nav', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject,
        A = Ember.A,
        computed = Ember.computed;


    var IS_CONTACT = true;
    var IS_ORGANIZATION = false;

    var NEW_STATE = 'new';
    var EDIT_STATE = 'edit';

    var STATES = exports.STATES = {
        contactSchemaList: {
            type: IS_CONTACT
        },
        contactSchemaNew: {
            type: IS_CONTACT,
            state: NEW_STATE
        },
        contactSchemaEdit: {
            type: IS_CONTACT,
            state: EDIT_STATE
        },
        externalOrgSchemaList: {
            type: IS_ORGANIZATION
        },
        externalOrgSchemaNew: {
            type: IS_ORGANIZATION,
            state: NEW_STATE
        },
        externalOrgSchemaEdit: {
            type: IS_ORGANIZATION,
            state: EDIT_STATE
        }
    };

    exports.default = Ember.Service.extend(Ember.Evented, {
        breadcrumbs: A([]),
        intl: inject.service(),
        session: inject.service(),
        disabled: true,

        state: computed({
            set: function set(key, stateObject) {
                if (stateObject) {
                    this.updateBreadcrumb(stateObject.type, stateObject.state);
                }
            }
        }),

        updateBreadcrumb: function updateBreadcrumb(isContact, isEdit) {
            var intl = this.get('intl');
            var breadcrumbs = A([{
                value: intl.t('emberEngineCustomFields.route.admin')
            }, {
                value: intl.t('emberEngineCustomFields.route.directory')
            }]);
            var isEditSet = typeof isEdit === 'string';
            var type = void 0;

            if (isContact === IS_CONTACT) {
                type = 'contact';
            } else {
                type = 'external-org';
            }

            var value = intl.t('emberEngineCustomFields.route.' + type + 's');
            breadcrumbs.push({
                value: value,
                hash: '#/admin/custom-fields/' + type + 's',
                class: 'breadcrumb-' + type + 's'
            });

            if (isEditSet) {
                var _value = void 0;
                if (isEdit === EDIT_STATE) {
                    _value = intl.t('emberEngineCustomFields.route.' + type + '.edit');
                } else {
                    _value = intl.t('emberEngineCustomFields.route.' + type + '.new');
                }
                breadcrumbs.push({
                    value: _value,
                    class: 'breadcrumb-' + type
                });
            }
            this.set('breadcrumbs', breadcrumbs);
        }
    });
});