define("ember-webrtc-components/models/video-participant", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ZERO_BITRATE_THRESHOLD = 2;

  var _default = Ember.Object.extend({
    webrtc: Ember.inject.service(),
    intl: Ember.computed.reads('webrtc.intl'),
    local: false,
    person: null,
    audioMuted: false,
    videoMuted: false,
    sharingScreen: false,
    // deprecated. V1 only
    major: false,
    selected: false,
    connectionState: null,
    domId: null,
    hasConnected: false,
    inLastN: false,
    cameraStream: null,
    screenStream: null,
    tracks: [],
    sessionId: null,
    isV1: false,
    startedVideoMuted: false,
    // HACK: PCDWEBK-3216; bridge doesn't play well with people who don't join with video
    associatedInteraction: null,
    zeroBitrateCount: 0,
    positiveBitrateCount: 0,
    screenTrack: null,
    cameraTrack: Ember.computed('tracks.[]', function () {
      return this.get('tracks').find(function (track) {
        return track.kind === 'video';
      });
    }),
    audioTrack: Ember.computed('tracks.[]', function () {
      return this.get('tracks').find(function (track) {
        return track.kind === 'audio';
      });
    }),
    jid: Ember.computed.reads('person.jid'),
    name: Ember.computed('person.name', 'associatedInteraction.customerName', function () {
      return this.get('person.name') || this.get('associatedInteraction.customerName') || this.get('intl').t('ewc.unknownParticipant');
    }),
    useTrackBasedActions: Ember.computed('associatedInteraction.isScreenShare', 'session.features.webrtcTrackBasedActions', 'session.features.hawkClientScreenShare', function () {
      return this.get('webrtc').canUseTrackBasedActions(this.get('associatedInteraction'));
    }),
    screenStreamStart: Ember.computed('screenStream', function () {
      return this.get('screenStream') ? (0, _moment.default)() : null;
    }),
    // HACK PCDWEBK-2982: remove when video bridge send video when you dont provide it
    noVideoTrack: Ember.computed('cameraStream', 'isV1', 'useTrackBasedActions', function () {
      if (this.get('isV1') || this.get('useTrackBasedActions')) {
        return false;
      }

      var stream = this.get('cameraStream');
      return !(stream && stream.getVideoTracks().length);
    }),
    speaking: false,
    mediaPresence: Ember.computed.reads('person.mediaPresence'),
    _getInteractionAvatarDisplay: function _getInteractionAvatarDisplay() {
      if (this.get('videoMuted')) {
        return true;
      }

      return false;
    },
    showAvatar: Ember.computed('videoMuted', 'sharingScreen', 'connectionState', 'inLastN', 'isOffline', 'noVideoTrack', 'screenStream', 'zeroBitrateCount', 'positiveBitrateCount', function () {
      if (this.get('associatedInteraction')) {
        return this._getInteractionAvatarDisplay();
      }

      var videoMuted = this.get('videoMuted');
      var sharingScreen = this.get('sharingScreen') || this.get('screenStream');
      var state = this.get('connectionState');
      var isOffline = this.get('isOffline');
      var inLastN = this.get('inLastN');
      var noVideo = this.get('noVideoTrack') && !sharingScreen;
      var zeroBitrateCount = this.get('zeroBitrateCount');

      if (['failed', 'checking', 'starting'].includes(state) || isOffline || noVideo || zeroBitrateCount >= ZERO_BITRATE_THRESHOLD) {
        return true;
      }

      if (!inLastN) {
        if (this.get('positiveBitrateCount') > 3) {
          Ember.Logger.warn('hiding avatar for !inLastN && positiveBitrateCount > 3', {
            remoteData: {
              id: this.get('person.id'),
              conversationId: this.get('associatedInteraction.id')
            }
          });
          return false;
        }

        return true;
      }

      return !sharingScreen && videoMuted;
    }),
    avatarDisplay: Ember.computed('showAvatar', function () {
      return this.get('showAvatar') ? 'avatar-visible' : 'avatar-hidden';
    }),
    isOffline: Ember.computed('connectionState', 'person.presence', 'zeroBitrateCount', function () {
      if (this.get('local') || this.get('associatedInteraction')) {
        return false;
      }

      var connectionState = this.get('connectionState');
      var presence = this.get('person.presence');
      var hasOfflinePresence = this.get('person') && (!presence || presence === 'offline');
      var zeroBitrateCount = this.get('zeroBitrateCount');
      return connectionState === 'disconnected' || connectionState === 'interrupted' || hasOfflinePresence || zeroBitrateCount >= ZERO_BITRATE_THRESHOLD;
    }),
    connectionStatus: Ember.computed('connectionState', 'isOffline', 'zeroBitrateCount', function () {
      var intl = this.get('intl');
      var state = this.get('connectionState');

      if (state === 'connected' || state === 'completed') {
        this.set('hasConnected', true);
      }

      if ((state === 'checking' || this.get('isOffline') || this.get('zeroBitrateCount') >= ZERO_BITRATE_THRESHOLD) && this.get('hasConnected')) {
        return intl.t('ewc.callStatus.recovering').toString();
      } else if (state) {
        return intl.t("ewc.callStatus.".concat(state)).toString();
      }
    }),
    canBeMuted: Ember.computed('audioMuted', 'local', 'associatedInteraction', function () {
      return !this.get('audioMuted') && !this.get('local') && !this.get('associatedInteraction');
    })
  });

  _exports.default = _default;
});