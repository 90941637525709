define('fixtable-ember/components/fixtable-column-header', ['exports', 'fixtable-ember/templates/components/fixtable-column-header'], function (exports, _fixtableColumnHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fixtableColumnHeader.default,
    tagName: 'th',

    column: null,
    sortBy: null,
    sortAscending: null,

    actions: {
      sortColumn: function sortColumn(columnKey) {
        this.sortColumn(columnKey);
      }
    }
  });
});