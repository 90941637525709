define('ember-custom-fields-components/utils/schema-serializer-util', ['exports', 'ember-custom-fields-components/utils/field-util'], function (exports, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var schemaSerializerUtil = {
        transformFieldListToObject: function transformFieldListToObject(record) {
            var object = {
                jsonSchema: {
                    $schema: record.$schema,
                    title: record.name,
                    description: record.description || '',
                    properties: {}
                }
            };

            if (record.hasOwnProperty('version')) {
                object.version = record.version;
            }

            if (record.hasOwnProperty('enabled')) {
                object.enabled = record.enabled;
            }

            var properties = Ember.A(Array.from(new Set([].concat(_toConsumableArray(record.text), _toConsumableArray(record.longtext), _toConsumableArray(record.enum), _toConsumableArray(record.identifier), _toConsumableArray(record.integer), _toConsumableArray(record.number), _toConsumableArray(record.date), _toConsumableArray(record.datetime), _toConsumableArray(record.tag), _toConsumableArray(record.checkbox), _toConsumableArray(record.url))))).sortBy('order');

            for (var i = 0; i < properties.length; i++) {

                var currentProperty = properties[i];

                var propertyObj = {},
                    $ref = '#/definitions/' + currentProperty.type;

                propertyObj.allOf = [{ $ref: $ref }];
                propertyObj.title = currentProperty.title;

                if (currentProperty.hasOwnProperty('description')) {
                    propertyObj.description = currentProperty.description || '';
                }

                if (currentProperty._disabled) {
                    propertyObj._disabled = currentProperty._disabled;
                }

                if (currentProperty.hasOwnProperty('maximum')) {
                    propertyObj.maximum = Number(currentProperty.maximum);
                }

                if (currentProperty.hasOwnProperty('minimum')) {
                    propertyObj.minimum = Number(currentProperty.minimum);
                }

                if (currentProperty.hasOwnProperty('maxItems')) {
                    propertyObj.maxItems = Number(currentProperty.maxItems);
                }

                if (currentProperty.hasOwnProperty('minItems')) {
                    propertyObj.minItems = Number(currentProperty.minItems);
                }

                if (currentProperty.hasOwnProperty('uniqueItems')) {
                    propertyObj.uniqueItems = Boolean(currentProperty.uniqueItems);
                }

                if (currentProperty.type === _fieldUtil.FIELD_TYPE.ENUM && currentProperty.enumList) {
                    var enumObject = this._transformListToEnumObject(currentProperty.enumList);
                    propertyObj.enum = enumObject.enum;
                    propertyObj._enumProperties = enumObject._enumProperties;
                }

                if (currentProperty.type === _fieldUtil.FIELD_TYPE.TAG) {
                    propertyObj.uniqueItems = true;
                    propertyObj.items = {};

                    if (currentProperty.hasOwnProperty('maxLength')) {
                        propertyObj.items.maxLength = Number(currentProperty.maxLength);
                    }

                    if (currentProperty.hasOwnProperty('minLength')) {
                        propertyObj.items.minLength = Number(currentProperty.minLength);
                    }
                } else {
                    if (currentProperty.hasOwnProperty('maxLength')) {
                        propertyObj.maxLength = Number(currentProperty.maxLength);
                    }

                    if (currentProperty.hasOwnProperty('minLength')) {
                        propertyObj.minLength = Number(currentProperty.minLength);
                    }
                }

                object.jsonSchema.properties[currentProperty.key] = propertyObj;
            }

            return object;
        },
        uniqueId: function uniqueId() {
            return new Date().toISOString() + '-' + Math.floor(Math.random() * 1000);
        },
        transformFieldObjectToList: function transformFieldObjectToList(fieldObjects) {
            var fields = {},
                index = 0;

            _fieldUtil.FIELD_TYPES.forEach(function (fieldType) {
                fields[fieldType] = [];
            });

            for (var property in fieldObjects) {
                if (fieldObjects.hasOwnProperty(property)) {

                    var currentProperty = fieldObjects[property];
                    var field = null;

                    var type = property.substring(property.lastIndexOf('_') + 1).toLowerCase();

                    field = {
                        id: this.uniqueId(),
                        key: property,
                        order: index,
                        _disabled: currentProperty._disabled,
                        propertyName: property,
                        title: currentProperty.title,
                        type: type,
                        description: currentProperty.description || ''
                    };

                    if (type === _fieldUtil.FIELD_TYPE.TEXT || type === _fieldUtil.FIELD_TYPE.LONGTEXT || type === _fieldUtil.FIELD_TYPE.IDENTIFIER || type === _fieldUtil.FIELD_TYPE.URL) {

                        field.minLength = currentProperty.minLength;
                        field.maxLength = currentProperty.maxLength;
                    }

                    if (type === _fieldUtil.FIELD_TYPE.INTEGER || type === _fieldUtil.FIELD_TYPE.NUMBER) {

                        field.minimum = currentProperty.minimum;
                        field.maximum = currentProperty.maximum;
                    }

                    if (type === _fieldUtil.FIELD_TYPE.ENUM) {
                        field.enumList = this._transformEnumObjectToList(currentProperty);
                    }

                    if (type === _fieldUtil.FIELD_TYPE.TAG) {
                        field.maxItems = currentProperty.maxItems;
                        field.minItems = currentProperty.minItems;
                        field.uniqueItems = currentProperty.uniqueItems;

                        if (currentProperty.items) {
                            field.minLength = currentProperty.items.minLength;
                            field.maxLength = currentProperty.items.maxLength;
                        }
                    }

                    fields[type].push(field);
                    index++;
                }
            }

            return fields;
        },
        _transformEnumObjectToList: function _transformEnumObjectToList(enumObject) {
            var enumList = [];

            if (enumObject.enum) {
                for (var i = 0; i < enumObject.enum.length; i++) {

                    var enumItem = { key: enumObject.enum[i] };

                    if (enumObject._enumProperties) {
                        var enumProperty = enumObject._enumProperties[enumItem.key];

                        if (enumProperty) {
                            enumItem.title = enumProperty.title;
                            enumItem._disabled = enumProperty._disabled;
                            enumItem.propertyName = enumItem.key;
                        }
                    }

                    enumList.push(enumItem);
                }
            }

            return enumList;
        },
        _transformListToEnumObject: function _transformListToEnumObject(enumList) {
            var enumObject = {
                enum: [],
                _enumProperties: {}
            };

            for (var i = 0; i < enumList.length; i++) {
                var currentEnum = enumList[i];
                enumObject.enum.push(currentEnum.key);
                enumObject._enumProperties[currentEnum.key] = {
                    title: currentEnum.title,
                    _disabled: currentEnum._disabled
                };
            }

            return enumObject;
        }
    };

    exports.default = schemaSerializerUtil;
});