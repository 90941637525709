define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/unread-banner/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/unread-banner/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Component.extend({
        classNames: ['unread-banner'],
        layout: _template.default,

        actions: {
            clear() {
                this.sendAction();
            }
        }
    });
});