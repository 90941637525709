define('ember-chat-components/components/chat-panel/conversation/header/control-buttons-panel/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/header/control-buttons-panel/template', 'ember-chat-components/mixins/chat-commands'], function (exports, _ember, _template, _chatCommands) {
    'use strict';

    exports.__esModule = true;


    const { computed, inject } = _ember.default;

    exports.default = _ember.default.Component.extend(_chatCommands.default, {
        classNames: ['control-buttons-panel'],
        layout: _template.default,

        intl: inject.service(),
        session: inject.service(),
        sidebar: inject.service(),

        showExpandButton: true,
        activeRoom: null,

        actions: {
            toggleSidebar: function () {
                this.get('sidebar').toggleCommunicationSidebarExpanded();
                this.bubbleAction({
                    action: 'toggleSidebar'
                });
                return null;
            },
            closePanel: function () {
                this.bubbleAction({
                    action: 'closePanel'
                });
                return null;
            },
            popout() {
                this.popoutChatRoom();
            }
        },

        expandButtonTitle: computed('isSidebarExpanded', function () {
            const action = this.get('isSidebarExpanded') ? 'collapse' : 'expand';
            return this.get('intl').t(`common.${action}`).toString();
        }),

        isSidebarExpanded: computed.reads('sidebar.communicationSidebarExpanded'),

        shouldShowPopout: computed('activeRoom', 'sidebar.{showAcdInteractions,showAgentAssistance}', function () {
            return !!this.get('activeRoom') && !this.get('sidebar.showAcdInteractions') && !this.get('sidebar.showAgentAssitance');
        })
    });
});