define('ember-cp-validations/validators/format', ['exports', 'ember', 'ember-cp-validations/-private/ember-validator', 'ember-validators/format'], function (exports, _ember, _emberCpValidationsPrivateEmberValidator, _emberValidatorsFormat) {
  var get = _ember['default'].get;
  var isNone = _ember['default'].isNone;

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validate over a predefined or custom regular expression.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('format', {
   *    type: 'email',
   *    allowNonTld: true
   *  })
   *  validator('format', {
   *    allowBlank: true,
   *    type: 'phone'
   *  })
   *  validator('format', {
   *    type: 'url'
   *  })
   *  validator('format', {
   *    regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,8}$/,
   *    message: 'Password must include at least one upper case letter, one lower case letter, and a number'
   *  })
   *  ```
   *
   *  If you do not want to use the predefined regex for a specific type, you can do something like this
   *
   *  ```javascript
   *  validator('format', {
   *    type: 'email',
   *    regex: /My Better Email Regexp/
   *  })
   *  ```
   *
   *  This allows you to still keep the email error message but with your own custom regex.
   *
   *  @class Format
   *  @module Validators
   *  @extends Base
   */
  exports['default'] = _emberCpValidationsPrivateEmberValidator['default'].extend({
    _evType: 'format',
    regularExpressions: _emberValidatorsFormat.regularExpressions,

    /**
     * Normalized options passed in by applying the desired regex or using the one declared
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions: function buildOptions() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var defaultOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var globalOptions = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var regularExpressions = get(this, 'regularExpressions');
      var regex = options.regex;
      var type = options.type;

      if (type && !isNone(regularExpressions[type]) && isNone(regex)) {
        if (type === 'email' && options.allowNonTld) {
          options.regex = regularExpressions.emailOptionalTld;
        } else {
          options.regex = regularExpressions[type];
        }
      }

      return this._super(options, defaultOptions, globalOptions);
    }
  });
});
/**
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */