define("ember-purecloud-components/services/feature", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend(_logger.default, {
    ajax: Ember.inject.service(),
    _endpointBaseUrl: '/platform/api/v2/featuretoggles',
    _urlMaxLength: 2000,
    _features: {},
    _pending: [],
    _pendingRequest: null,
    _pendingOrgRequest: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        _features: {},
        _pending: []
      });

      try {
        var config = Ember.getOwner(this).resolveRegistration('config:environment');

        if (config && config.APP && config.APP.FEATURE_TOGGLE_OVERRIDES) {
          this.set('_overrides', config.APP.FEATURE_TOGGLE_OVERRIDES);
        } else {
          this.set('_overrides', {});
        }
      } catch (e) {
        this.set('_overrides', {});
      }
    },
    // request values for the specified feature toggles. returns a promise resolving to the full set of feature toggles we know about so far.
    getFeatureToggles: function getFeatureToggles() {
      var _this = this;

      var makeRequest = false;

      var _features = this.get('_features');

      var _pending = this.get('_pending');

      var _overrides = this.get('_overrides');

      var args = Ember.A(Array.prototype.slice.call(arguments));
      var forceRequest = args.get('lastObject');

      if (typeof forceRequest === 'boolean') {
        makeRequest = forceRequest;
        args.removeAt(args.length - 1);
      } else {
        forceRequest = false;
      }

      args.forEach(function (feature) {
        if (typeof _features[feature] === 'undefined' || forceRequest) {
          makeRequest = true;

          _pending.push(feature);
        }
      });
      var awaitAllRequestedToggles = null;

      if (!makeRequest) {
        // no features requested that we don't already know about or require cache busting
        awaitAllRequestedToggles = Ember.RSVP.resolve();
      } else {
        // new features or cache bust requested so lets queue them and kick it off.
        awaitAllRequestedToggles = this._batchRequest();
      }

      return awaitAllRequestedToggles.then(function () {
        if (Object.keys(_overrides).length > 0) {
          _this.logger.warn('Running with feature toggle overrides:', {
            _overrides: _overrides
          });
        } // return a copy of the internal feature data so callers cannot corrupt the internal state


        return Ember.assign({}, _this.get('_features'), _overrides);
      });
    },
    wrapPlatformPath: function wrapPlatformPath(path) {
      var applicationService = Ember.getOwner(this).lookup('service:application');

      if (!applicationService || _typeof(applicationService) !== 'object' || typeof applicationService.pcV2Uri !== 'function') {
        this.logger.warn('application service does not exist or the pcV2Uri function does not exist');
        return path;
      } // if application service exists then strip away leading slash


      path = path.substring(1);
      return applicationService.pcV2Uri(path);
    },
    _batchRequest: function _batchRequest() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // run on next pass so that we batch up api calls without significant hit on response time.
        Ember.run.later(function () {
          if (_this2.isDestroying || _this2.isDestroyed) {
            reject('Service has been destroyed.');
            return;
          }

          var pending = _this2.get('_pending');

          if (!pending.length) {
            return resolve(_this2.get('_pendingRequest')); // if _pendingRequest exists then won't resolve until THAT resolves.
          }

          _this2.set('_pending', []);

          _this2.set('_pendingRequest', _this2._fetch(pending).then(function (result) {
            _this2.set('_features', Object.assign({}, _this2.get('_features'), result.toggles));

            var allKnownFeatures = _this2.get('_features');

            resolve(allKnownFeatures); // resolve the initial _batchRequest Promise

            return allKnownFeatures; // resolve the _pendingRequest promise for any batched requests waiting on it
          }).catch(function (failure) {
            if (failure.toggles) {
              // Add any successful fetches to the cache so we don't have to refetch
              _this2.set('_features', Object.assign({}, _this2.get('_features'), failure.toggles));
            }

            reject(failure.fetchFailureReason);
          }));
        }, 0);
      });
    },
    _fetch: function _fetch(toggles) {
      var url = "".concat(this.get('_endpointBaseUrl'), "?feature=").concat(toggles.join('&feature=')); // if the url is larger than browsers support then use divide and conquer to split it into multiple requests

      if (url.length > this.get('_urlMaxLength')) {
        var half = toggles.splice(0, Math.floor(toggles.length / 2)); // this won't resolve until both run() calls resolve. If either of those also gets split then this won't resolve until THOSE resolve, ad infinitum.

        return Ember.RSVP.allSettled([this._fetch(half), this._fetch(toggles)]).then(function (allResults) {
          var result = {
            fetchFailureReason: null,
            toggles: {}
          };
          allResults.forEach(function (currResult) {
            if (currResult.state === 'fulfilled') {
              Ember.assign(result.toggles, currResult.value.toggles);
            } else {
              // Add toggles from partially successful nested bisects
              if (currResult.reason.toggles) {
                Ember.assign(result.toggles, currResult.reason.toggles);
              }

              if (result.fetchFailureReason === null) {
                // Maintaining backwards compatability by returning the first network failure
                // NEXTMAJOR: Just reject with 'fetchFailed' or an array of reasons (without network details)
                result.fetchFailureReason = currResult.reason.fetchFailureReason;
              }
            }
          });
          return !result.fetchFailureReason ? result : Ember.RSVP.reject(result);
        });
      }

      return this.get('ajax').request(this.wrapPlatformPath(url)).then(function (response) {
        return {
          toggles: response
        };
      }).catch(function (fetchFailureReason) {
        return Ember.RSVP.reject({
          fetchFailureReason: fetchFailureReason,
          toggles: null
        });
      });
    }
  });

  _exports.default = _default;
});