define("ember-purecloud-components/components/entity-v2/contact-group/component", ["exports", "ember-purecloud-components/components/entity-v2/contact-group/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2-contact-group'],
    classNameBindings: ['compact'],
    intl: Ember.inject.service(),

    /**
       @type object
       @desc various config parameters
       @property {bool} linkProfile - determines whether or not to show the profile button
       @property {bool} showFavorite - determines whether or not to show the entity favorite button
       @property {bool} showEmail - determines whether or not to show the email button
       @property {bool} showChat - determines whether or not to show the chat button
       @property {bool} showPhone - determines whether or not to show the phone button
       @property {bool} showVideo - determines whether or not to show the video button
       @property {bool} showGallery - determines whether or not to show the gallery button
       @property {bool} phoneEnabled - determines whether or not the phone button should be enabled
       @property {bool} videoEnabled - determines whether or not the video button should be enabled
    */
    params: null,
    isOffline: Ember.computed('entity.presence.presenceDefinition.systemPresence', function () {
      return this.getWithDefault('entity.presence.presenceDefinition.systemPresence', 'offline').toLowerCase() === 'offline';
    }),
    email: Ember.computed('entity.primaryContactInfo.[]', function () {
      var email = this.getWithDefault('entity.primaryContactInfo', []).find(function (contact) {
        return contact.mediaType === 'EMAIL';
      });
      return email && email.address;
    }),
    mailto: Ember.computed('email', function () {
      if (this.get('email')) {
        return "mailto:".concat(this.get('email'));
      }

      return '';
    }),
    hasImages: Ember.computed('params.showGallery', 'entity.images.informal', 'entity.images.gallery', function () {
      if (!this.get('params.showGallery')) {
        return false;
      }

      var personHasImage = this.get('entity.type') === 'person' && !!this.get('entity.images.informal');
      var groupHasImage = this.get('entity.type') === 'group' && !!this.get('entity.images.gallery.length');
      return personHasImage || groupHasImage;
    }),
    linkToProfileRoute: Ember.computed('entity.type', function () {
      if (this.get('entity.type') === 'group') {
        return "#/group/".concat(this.get('entity.id'));
      }

      return "#/person/".concat(this.get('entity.id'));
    }),
    videoTooltip: Ember.computed('isOffline', function () {
      return this.get('isOffline') ? this.get('intl').t('purecloud-components.userOffline', {
        name: this.get('entity.name')
      }) : this.get('intl').t('purecloud-components.openVideoCall');
    }),

    /**
       @type function
       @desc Place a call to the current entity.
     */
    makeCall: function makeCall() {},

    /**
       @type function
       @desc Launch a video call with the current entity.
     */
    launchVideo: function launchVideo() {},

    /**
       @type function
       @desc Start a chat with the current entity.
     */
    startChat: function startChat() {},

    /**
       @type function
       @desc Open the image gallery of the current entity.
     */
    goToGallery: function goToGallery() {},

    /**
       @type function
       @param {boolean} favoriteStatus - the new value of the favorite status
       @desc Toggle entity favorite status.
     */
    toggleFavorite: function toggleFavorite() {}
  });

  _exports.default = _default;
});