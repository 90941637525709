enifed('container', ['exports', 'ember-utils', 'ember-debug', 'ember-environment'], function (exports, _emberUtils, _emberDebug, _emberEnvironment) {
  'use strict';

  exports.buildFakeContainerWithDeprecations = exports.Container = exports.privatize = exports.Registry = undefined;

  /* globals Proxy */
  var CONTAINER_OVERRIDE = (0, _emberUtils.symbol)('CONTAINER_OVERRIDE');

  /**
   A container used to instantiate and cache objects.
  
   Every `Container` must be associated with a `Registry`, which is referenced
   to determine the factory and options that should be used to instantiate
   objects.
  
   The public API for `Container` is still in flux and should not be considered
   stable.
  
   @private
   @class Container
   */
  function Container(registry, options) {
    this.registry = registry;
    this.owner = options && options.owner ? options.owner : null;
    this.cache = (0, _emberUtils.dictionary)(options && options.cache ? options.cache : null);
    this.factoryCache = (0, _emberUtils.dictionary)(options && options.factoryCache ? options.factoryCache : null);
    this.factoryManagerCache = (0, _emberUtils.dictionary)(options && options.factoryManagerCache ? options.factoryManagerCache : null);
    this.validationCache = (0, _emberUtils.dictionary)(options && options.validationCache ? options.validationCache : null);
    this._fakeContainerToInject = buildFakeContainerWithDeprecations(this);
    this[CONTAINER_OVERRIDE] = undefined;
    this.isDestroyed = false;
  }

  Container.prototype = {
    lookup: function (fullName, options) {
      true && !this.registry.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.registry.validateFullName(fullName));

      return lookup(this, this.registry.normalize(fullName), options);
    },
    lookupFactory: function (fullName, options) {
      true && !this.registry.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.registry.validateFullName(fullName));
      true && !false && (0, _emberDebug.deprecate)('Using "_lookupFactory" is deprecated. Please use container.factoryFor instead.', false, { id: 'container-lookupFactory', until: '2.13.0', url: 'http://emberjs.com/deprecations/v2.x/#toc_migrating-from-_lookupfactory-to-factoryfor' });

      return deprecatedFactoryFor(this, this.registry.normalize(fullName), options);
    },
    destroy: function () {
      destroyDestroyables(this);
      this.isDestroyed = true;
    },
    reset: function (fullName) {
      if (arguments.length > 0) {
        resetMember(this, this.registry.normalize(fullName));
      } else {
        resetCache(this);
      }
    },
    ownerInjection: function () {
      var _ref;

      return _ref = {}, _ref[_emberUtils.OWNER] = this.owner, _ref;
    },
    factoryFor: function (fullName) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var normalizedName = this.registry.normalize(fullName);

      true && !this.registry.validateFullName(normalizedName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.registry.validateFullName(normalizedName));

      if (options.source) {
        normalizedName = this.registry.expandLocalLookup(fullName, options);
        // if expandLocalLookup returns falsey, we do not support local lookup
        if (!normalizedName) {
          return;
        }
      }

      var cached = this.factoryManagerCache[normalizedName];

      if (cached) {
        return cached;
      }

      var factory = this.registry.resolve(normalizedName);

      if (factory === undefined) {
        return;
      }

      if (true && factory && typeof factory._onLookup === 'function') {
        factory._onLookup(fullName);
      }

      var manager = new FactoryManager(this, factory, fullName, normalizedName);

      manager = wrapManagerInDeprecationProxy(manager);


      this.factoryManagerCache[normalizedName] = manager;
      return manager;
    }
  };

  /*
   * Wrap a factory manager in a proxy which will not permit properties to be
   * set on the manager.
   */
  function wrapManagerInDeprecationProxy(manager) {
    var validator, m, proxiedManager;

    if (_emberUtils.HAS_NATIVE_PROXY) {
      validator = {
        get: function (obj, prop) {
          if (typeof prop === 'symbol') {
            return obj[prop];
          }
          if (prop === 'inspect') {
            return undefined; /* for nodes formatter */
          }

          if (prop !== 'class' && prop !== 'create' && prop !== 'toString') {
            throw new Error('You attempted to access "' + prop + '" on a factory manager created by container#factoryFor. "' + prop + '" is not a member of a factory manager."');
          }

          return obj[prop];
        },
        set: function (obj, prop) {
          throw new Error('You attempted to set "' + prop + '" on a factory manager created by container#factoryFor. A factory manager is a read-only construct.');
        }
      };

      // Note:
      // We have to proxy access to the manager here so that private property
      // access doesn't cause the above errors to occur.

      m = manager;
      proxiedManager = {
        class: m.class,
        create: function (props) {
          return m.create(props);
        }
      };


      return new Proxy(proxiedManager, validator);
    }

    return manager;
  }

  function isSingleton(container, fullName) {
    return container.registry.getOption(fullName, 'singleton') !== false;
  }

  function isInstantiatable(container, fullName) {
    return container.registry.getOption(fullName, 'instantiate') !== false;
  }

  function lookup(container, fullName) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (options.source) {
      fullName = container.registry.expandLocalLookup(fullName, options);

      // if expandLocalLookup returns falsey, we do not support local lookup
      if (!fullName) {
        return;
      }
    }

    if (container.cache[fullName] !== undefined && options.singleton !== false) {
      return container.cache[fullName];
    }

    return instantiateFactory(container, fullName, options);
  }

  function isSingletonClass(container, fullName, _ref2) {
    var instantiate = _ref2.instantiate,
        singleton = _ref2.singleton;

    return singleton !== false && isSingleton(container, fullName) && !instantiate && !isInstantiatable(container, fullName);
  }

  function isSingletonInstance(container, fullName, _ref3) {
    var instantiate = _ref3.instantiate,
        singleton = _ref3.singleton;

    return singleton !== false && isSingleton(container, fullName) && instantiate !== false && isInstantiatable(container, fullName);
  }

  function isFactoryClass(container, fullname, _ref4) {
    var instantiate = _ref4.instantiate,
        singleton = _ref4.singleton;

    return (singleton === false || !isSingleton(container, fullname)) && instantiate === false && !isInstantiatable(container, fullname);
  }

  function isFactoryInstance(container, fullName, _ref5) {
    var instantiate = _ref5.instantiate,
        singleton = _ref5.singleton;

    return (singleton !== false || isSingleton(container, fullName)) && instantiate !== false && isInstantiatable(container, fullName);
  }

  function instantiateFactory(container, fullName, options) {
    var factoryManager = container.factoryFor(fullName);

    if (factoryManager === undefined) {
      return;
    }

    // SomeClass { singleton: true, instantiate: true } | { singleton: true } | { instantiate: true } | {}
    // By default majority of objects fall into this case
    if (isSingletonInstance(container, fullName, options)) {
      return container.cache[fullName] = factoryManager.create();
    }

    // SomeClass { singleton: false, instantiate: true }
    if (isFactoryInstance(container, fullName, options)) {
      return factoryManager.create();
    }

    // SomeClass { singleton: true, instantiate: false } | { instantiate: false } | { singleton: false, instantiation: false }
    if (isSingletonClass(container, fullName, options) || isFactoryClass(container, fullName, options)) {
      return factoryManager.class;
    }

    throw new Error('Could not create factory');
  }

  function markInjectionsAsDynamic(injections) {
    injections._dynamic = true;
  }

  function areInjectionsDynamic(injections) {
    return !!injections._dynamic;
  }

  function buildInjections() /* container, ...injections */{
    var hash = {},
        container,
        injections,
        injection,
        i,
        markAsDynamic,
        _i;

    if (arguments.length > 1) {
      container = arguments[0];
      injections = [];
      injection = void 0;


      for (i = 1; i < arguments.length; i++) {
        if (arguments[i]) {
          injections = injections.concat(arguments[i]);
        }
      }

      container.registry.validateInjections(injections);
      markAsDynamic = false;

      for (_i = 0; _i < injections.length; _i++) {
        injection = injections[_i];
        hash[injection.property] = lookup(container, injection.fullName);
        if (!markAsDynamic) {
          markAsDynamic = !isSingleton(container, injection.fullName);
        }
      }

      if (markAsDynamic) {
        markInjectionsAsDynamic(hash);
      }
    }

    return hash;
  }

  function deprecatedFactoryFor(container, fullName) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        injections,
        factoryInjections,
        cacheable,
        injectedFactory;

    var registry = container.registry;

    if (options.source) {
      fullName = registry.expandLocalLookup(fullName, options);
      // if expandLocalLookup returns falsey, we do not support local lookup
      if (!fullName) {
        return;
      }
    }

    var cache = container.factoryCache;
    if (cache[fullName]) {
      return cache[fullName];
    }
    var factory = registry.resolve(fullName);
    if (factory === undefined) {
      return;
    }

    var type = fullName.split(':')[0];
    if (!factory || typeof factory.extend !== 'function' || !_emberEnvironment.ENV.MODEL_FACTORY_INJECTIONS && type === 'model') {
      if (factory && typeof factory._onLookup === 'function') {
        factory._onLookup(fullName);
      }

      // TODO: think about a 'safe' merge style extension
      // for now just fallback to create time injection
      cache[fullName] = factory;
      return factory;
    } else {
      injections = injectionsFor(container, fullName);
      factoryInjections = factoryInjectionsFor(container, fullName);
      cacheable = !areInjectionsDynamic(injections) && !areInjectionsDynamic(factoryInjections);


      factoryInjections[_emberUtils.NAME_KEY] = registry.makeToString(factory, fullName);
      injections._debugContainerKey = fullName;
      (0, _emberUtils.setOwner)(injections, container.owner);

      injectedFactory = factory.extend(injections);

      // TODO - remove all `container` injections when Ember reaches v3.0.0

      injectDeprecatedContainer(injectedFactory.prototype, container);
      injectedFactory.reopenClass(factoryInjections);

      if (factory && typeof factory._onLookup === 'function') {
        factory._onLookup(fullName);
      }

      if (cacheable) {
        cache[fullName] = injectedFactory;
      }

      return injectedFactory;
    }
  }

  function injectionsFor(container, fullName) {
    var registry = container.registry;
    var splitName = fullName.split(':');
    var type = splitName[0];

    var injections = buildInjections(container, registry.getTypeInjections(type), registry.getInjections(fullName));

    return injections;
  }

  function factoryInjectionsFor(container, fullName) {
    var registry = container.registry;
    var splitName = fullName.split(':');
    var type = splitName[0];

    var factoryInjections = buildInjections(container, registry.getFactoryTypeInjections(type), registry.getFactoryInjections(fullName));
    factoryInjections._debugContainerKey = fullName;

    return factoryInjections;
  }

  var INJECTED_DEPRECATED_CONTAINER_DESC = {
    configurable: true,
    enumerable: false,
    get: function () {
      true && !false && (0, _emberDebug.deprecate)('Using the injected `container` is deprecated. Please use the `getOwner` helper instead to access the owner of this object.', false, { id: 'ember-application.injected-container', until: '2.13.0', url: 'http://emberjs.com/deprecations/v2.x#toc_injected-container-access' });

      return this[CONTAINER_OVERRIDE] || (0, _emberUtils.getOwner)(this).__container__;
    },
    set: function (value) {
      true && !false && (0, _emberDebug.deprecate)('Providing the `container` property to ' + this + ' is deprecated. Please use `Ember.setOwner` or `owner.ownerInjection()` instead to provide an owner to the instance being created.', false, { id: 'ember-application.injected-container', until: '2.13.0', url: 'http://emberjs.com/deprecations/v2.x#toc_injected-container-access' });

      this[CONTAINER_OVERRIDE] = value;

      return value;
    }
  };

  // TODO - remove when Ember reaches v3.0.0
  function injectDeprecatedContainer(object) {
    if ('container' in object) {
      return;
    }
    Object.defineProperty(object, 'container', INJECTED_DEPRECATED_CONTAINER_DESC);
  }

  function destroyDestroyables(container) {
    var cache = container.cache,
        i,
        key,
        value;
    var keys = Object.keys(cache);

    for (i = 0; i < keys.length; i++) {
      key = keys[i];
      value = cache[key];


      if (isInstantiatable(container, key) && value.destroy) {
        value.destroy();
      }
    }
  }

  function resetCache(container) {
    destroyDestroyables(container);
    container.cache.dict = (0, _emberUtils.dictionary)(null);
  }

  function resetMember(container, fullName) {
    var member = container.cache[fullName];

    delete container.factoryCache[fullName];

    if (member) {
      delete container.cache[fullName];

      if (member.destroy) {
        member.destroy();
      }
    }
  }

  function buildFakeContainerWithDeprecations(container) {
    var fakeContainer = {};
    var propertyMappings = {
      lookup: 'lookup',
      lookupFactory: '_lookupFactory'
    };

    for (var containerProperty in propertyMappings) {
      fakeContainer[containerProperty] = buildFakeContainerFunction(container, containerProperty, propertyMappings[containerProperty]);
    }

    return fakeContainer;
  }

  function buildFakeContainerFunction(container, containerProperty, ownerProperty) {
    return function () {
      true && !false && (0, _emberDebug.deprecate)('Using the injected `container` is deprecated. Please use the `getOwner` helper to access the owner of this object and then call `' + ownerProperty + '` instead.', false, {
        id: 'ember-application.injected-container',
        until: '2.13.0',
        url: 'http://emberjs.com/deprecations/v2.x#toc_injected-container-access'
      });

      return container[containerProperty].apply(container, arguments);
    };
  }

  var FactoryManager = function () {
    function FactoryManager(container, factory, fullName, normalizedName) {

      this.container = container;
      this.owner = container.owner;
      this.class = factory;
      this.fullName = fullName;
      this.normalizedName = normalizedName;
      this.madeToString = undefined;
      this.injections = undefined;
    }

    FactoryManager.prototype.toString = function () {
      if (!this.madeToString) {
        this.madeToString = this.container.registry.makeToString(this.class, this.fullName);
      }

      return this.madeToString;
    };

    FactoryManager.prototype.create = function () {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var injections = this.injections;
      if (injections === undefined) {
        injections = injectionsFor(this.container, this.normalizedName);
        if (areInjectionsDynamic(injections) === false) {
          this.injections = injections;
        }
      }
      var props = (0, _emberUtils.assign)({}, injections, options);

      var lazyInjections = void 0;
      var validationCache = this.container.validationCache;
      // Ensure that all lazy injections are valid at instantiation time
      if (!validationCache[this.fullName] && this.class && typeof this.class._lazyInjections === 'function') {
        lazyInjections = this.class._lazyInjections();
        lazyInjections = this.container.registry.normalizeInjectionsHash(lazyInjections);

        this.container.registry.validateInjections(lazyInjections);
      }

      validationCache[this.fullName] = true;


      if (!this.class.create) {
        throw new Error('Failed to create an instance of \'' + this.normalizedName + '\'. Most likely an improperly defined class or' + ' an invalid module export.');
      }

      var prototype = this.class.prototype;
      if (prototype) {
        injectDeprecatedContainer(prototype, this.container);
      }

      // required to allow access to things like
      // the customized toString, _debugContainerKey,
      // owner, etc. without a double extend and without
      // modifying the objects properties
      if (typeof this.class._initFactory === 'function') {
        this.class._initFactory(this);
      } else {
        // in the non-Ember.Object case we need to still setOwner
        // this is required for supporting glimmer environment and
        // template instantiation which rely heavily on
        // `options[OWNER]` being passed into `create`
        // TODO: clean this up, and remove in future versions
        (0, _emberUtils.setOwner)(props, this.owner);
      }

      return this.class.create(props);
    };

    return FactoryManager;
  }();

  var VALID_FULL_NAME_REGEXP = /^[^:]+:[^:]+$/;

  /**
   A registry used to store factory and option information keyed
   by type.
  
   A `Registry` stores the factory and option information needed by a
   `Container` to instantiate and cache objects.
  
   The API for `Registry` is still in flux and should not be considered stable.
  
   @private
   @class Registry
   @since 1.11.0
  */
  function Registry(options) {
    this.fallback = options && options.fallback ? options.fallback : null;

    if (options && options.resolver) {
      this.resolver = options.resolver;

      if (typeof this.resolver === 'function') {
        deprecateResolverFunction(this);
      }
    }

    this.registrations = (0, _emberUtils.dictionary)(options && options.registrations ? options.registrations : null);

    this._typeInjections = (0, _emberUtils.dictionary)(null);
    this._injections = (0, _emberUtils.dictionary)(null);
    this._factoryTypeInjections = (0, _emberUtils.dictionary)(null);
    this._factoryInjections = (0, _emberUtils.dictionary)(null);

    this._localLookupCache = Object.create(null);
    this._normalizeCache = (0, _emberUtils.dictionary)(null);
    this._resolveCache = (0, _emberUtils.dictionary)(null);
    this._failCache = (0, _emberUtils.dictionary)(null);

    this._options = (0, _emberUtils.dictionary)(null);
    this._typeOptions = (0, _emberUtils.dictionary)(null);
  }

  Registry.prototype = {
    /**
     A backup registry for resolving registrations when no matches can be found.
      @private
     @property fallback
     @type Registry
     */
    fallback: null,

    /**
     An object that has a `resolve` method that resolves a name.
      @private
     @property resolver
     @type Resolver
     */
    resolver: null,

    /**
     @private
     @property registrations
     @type InheritingDict
     */
    registrations: null,

    /**
     @private
      @property _typeInjections
     @type InheritingDict
     */
    _typeInjections: null,

    /**
     @private
      @property _injections
     @type InheritingDict
     */
    _injections: null,

    /**
     @private
      @property _factoryTypeInjections
     @type InheritingDict
     */
    _factoryTypeInjections: null,

    /**
     @private
      @property _factoryInjections
     @type InheritingDict
     */
    _factoryInjections: null,

    /**
     @private
      @property _normalizeCache
     @type InheritingDict
     */
    _normalizeCache: null,

    /**
     @private
      @property _resolveCache
     @type InheritingDict
     */
    _resolveCache: null,

    /**
     @private
      @property _options
     @type InheritingDict
     */
    _options: null,

    /**
     @private
      @property _typeOptions
     @type InheritingDict
     */
    _typeOptions: null,

    container: function (options) {
      return new Container(this, options);
    },
    register: function (fullName, factory) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      true && !this.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName));

      if (factory === undefined) {
        throw new TypeError('Attempting to register an unknown factory: \'' + fullName + '\'');
      }

      var normalizedName = this.normalize(fullName);

      if (this._resolveCache[normalizedName]) {
        throw new Error('Cannot re-register: \'' + fullName + '\', as it has already been resolved.');
      }

      delete this._failCache[normalizedName];
      this.registrations[normalizedName] = factory;
      this._options[normalizedName] = options;
    },
    unregister: function (fullName) {
      true && !this.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName));

      var normalizedName = this.normalize(fullName);

      this._localLookupCache = Object.create(null);

      delete this.registrations[normalizedName];
      delete this._resolveCache[normalizedName];
      delete this._failCache[normalizedName];
      delete this._options[normalizedName];
    },
    resolve: function (fullName, options) {
      true && !this.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName));

      var factory = resolve(this, this.normalize(fullName), options),
          _fallback;
      if (factory === undefined && this.fallback) {

        factory = (_fallback = this.fallback).resolve.apply(_fallback, arguments);
      }
      return factory;
    },
    describe: function (fullName) {
      if (this.resolver && this.resolver.lookupDescription) {
        return this.resolver.lookupDescription(fullName);
      } else if (this.fallback) {
        return this.fallback.describe(fullName);
      } else {
        return fullName;
      }
    },
    normalizeFullName: function (fullName) {
      if (this.resolver && this.resolver.normalize) {
        return this.resolver.normalize(fullName);
      } else if (this.fallback) {
        return this.fallback.normalizeFullName(fullName);
      } else {
        return fullName;
      }
    },
    normalize: function (fullName) {
      return this._normalizeCache[fullName] || (this._normalizeCache[fullName] = this.normalizeFullName(fullName));
    },
    makeToString: function (factory, fullName) {
      if (this.resolver && this.resolver.makeToString) {
        return this.resolver.makeToString(factory, fullName);
      } else if (this.fallback) {
        return this.fallback.makeToString(factory, fullName);
      } else {
        return factory.toString();
      }
    },
    has: function (fullName, options) {
      if (!this.isValidFullName(fullName)) {
        return false;
      }

      var source = options && options.source && this.normalize(options.source);

      return has(this, this.normalize(fullName), source);
    },
    optionsForType: function (type, options) {
      this._typeOptions[type] = options;
    },
    getOptionsForType: function (type) {
      var optionsForType = this._typeOptions[type];
      if (optionsForType === undefined && this.fallback) {
        optionsForType = this.fallback.getOptionsForType(type);
      }
      return optionsForType;
    },
    options: function (fullName) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var normalizedName = this.normalize(fullName);
      this._options[normalizedName] = options;
    },
    getOptions: function (fullName) {
      var normalizedName = this.normalize(fullName);
      var options = this._options[normalizedName];

      if (options === undefined && this.fallback) {
        options = this.fallback.getOptions(fullName);
      }
      return options;
    },
    getOption: function (fullName, optionName) {
      var options = this._options[fullName];

      if (options && options[optionName] !== undefined) {
        return options[optionName];
      }

      var type = fullName.split(':')[0];
      options = this._typeOptions[type];

      if (options && options[optionName] !== undefined) {
        return options[optionName];
      } else if (this.fallback) {
        return this.fallback.getOption(fullName, optionName);
      }
    },
    typeInjection: function (type, property, fullName) {
      true && !this.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName));

      var fullNameType = fullName.split(':')[0];
      if (fullNameType === type) {
        throw new Error('Cannot inject a \'' + fullName + '\' on other ' + type + '(s).');
      }

      var injections = this._typeInjections[type] || (this._typeInjections[type] = []);

      injections.push({
        property: property,
        fullName: fullName
      });
    },
    injection: function (fullName, property, injectionName) {
      this.validateFullName(injectionName);
      var normalizedInjectionName = this.normalize(injectionName);

      if (fullName.indexOf(':') === -1) {
        return this.typeInjection(fullName, property, normalizedInjectionName);
      }

      true && !this.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName));

      var normalizedName = this.normalize(fullName);

      var injections = this._injections[normalizedName] || (this._injections[normalizedName] = []);

      injections.push({
        property: property,
        fullName: normalizedInjectionName
      });
    },
    factoryTypeInjection: function (type, property, fullName) {
      var injections = this._factoryTypeInjections[type] || (this._factoryTypeInjections[type] = []);

      injections.push({
        property: property,
        fullName: this.normalize(fullName)
      });
    },
    factoryInjection: function (fullName, property, injectionName) {
      var normalizedName = this.normalize(fullName);
      var normalizedInjectionName = this.normalize(injectionName);

      this.validateFullName(injectionName);

      if (fullName.indexOf(':') === -1) {
        return this.factoryTypeInjection(normalizedName, property, normalizedInjectionName);
      }

      var injections = this._factoryInjections[normalizedName] || (this._factoryInjections[normalizedName] = []);

      injections.push({
        property: property,
        fullName: normalizedInjectionName
      });
    },
    knownForType: function (type) {
      var fallbackKnown = void 0,
          resolverKnown = void 0,
          index,
          fullName,
          itemType;

      var localKnown = (0, _emberUtils.dictionary)(null);
      var registeredNames = Object.keys(this.registrations);
      for (index = 0; index < registeredNames.length; index++) {
        fullName = registeredNames[index];
        itemType = fullName.split(':')[0];


        if (itemType === type) {
          localKnown[fullName] = true;
        }
      }

      if (this.fallback) {
        fallbackKnown = this.fallback.knownForType(type);
      }

      if (this.resolver && this.resolver.knownForType) {
        resolverKnown = this.resolver.knownForType(type);
      }

      return (0, _emberUtils.assign)({}, fallbackKnown, localKnown, resolverKnown);
    },
    validateFullName: function (fullName) {
      if (!this.isValidFullName(fullName)) {
        throw new TypeError('Invalid Fullname, expected: \'type:name\' got: ' + fullName);
      }

      return true;
    },
    isValidFullName: function (fullName) {
      return !!VALID_FULL_NAME_REGEXP.test(fullName);
    },
    validateInjections: function (injections) {
      if (!injections) {
        return;
      }

      var fullName = void 0,
          i;

      for (i = 0; i < injections.length; i++) {
        fullName = injections[i].fullName;

        true && !this.has(fullName) && (0, _emberDebug.assert)('Attempting to inject an unknown injection: \'' + fullName + '\'', this.has(fullName));
      }
    },
    normalizeInjectionsHash: function (hash) {
      var injections = [];

      for (var key in hash) {
        if (hash.hasOwnProperty(key)) {
          true && !this.validateFullName(hash[key]) && (0, _emberDebug.assert)('Expected a proper full name, given \'' + hash[key] + '\'', this.validateFullName(hash[key]));

          injections.push({
            property: key,
            fullName: hash[key]
          });
        }
      }

      return injections;
    },
    getInjections: function (fullName) {
      var injections = this._injections[fullName] || [];
      if (this.fallback) {
        injections = injections.concat(this.fallback.getInjections(fullName));
      }
      return injections;
    },
    getTypeInjections: function (type) {
      var injections = this._typeInjections[type] || [];
      if (this.fallback) {
        injections = injections.concat(this.fallback.getTypeInjections(type));
      }
      return injections;
    },
    getFactoryInjections: function (fullName) {
      var injections = this._factoryInjections[fullName] || [];
      if (this.fallback) {
        injections = injections.concat(this.fallback.getFactoryInjections(fullName));
      }
      return injections;
    },
    getFactoryTypeInjections: function (type) {
      var injections = this._factoryTypeInjections[type] || [];
      if (this.fallback) {
        injections = injections.concat(this.fallback.getFactoryTypeInjections(type));
      }
      return injections;
    }
  };

  function deprecateResolverFunction(registry) {
    true && !false && (0, _emberDebug.deprecate)('Passing a `resolver` function into a Registry is deprecated. Please pass in a Resolver object with a `resolve` method.', false, { id: 'ember-application.registry-resolver-as-function', until: '3.0.0', url: 'http://emberjs.com/deprecations/v2.x#toc_registry-resolver-as-function' });

    registry.resolver = {
      resolve: registry.resolver
    };
  }

  /**
   Given a fullName and a source fullName returns the fully resolved
   fullName. Used to allow for local lookup.
  
   ```javascript
   let registry = new Registry();
  
   // the twitter factory is added to the module system
   registry.expandLocalLookup('component:post-title', { source: 'template:post' }) // => component:post/post-title
   ```
  
   @private
   @method expandLocalLookup
   @param {String} fullName
   @param {Object} [options]
   @param {String} [options.source] the fullname of the request source (used for local lookups)
   @return {String} fullName
   */
  Registry.prototype.expandLocalLookup = function (fullName, options) {
    var normalizedFullName, normalizedSource;

    if (this.resolver && this.resolver.expandLocalLookup) {
      true && !this.validateFullName(fullName) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName));
      true && !(options && options.source) && (0, _emberDebug.assert)('options.source must be provided to expandLocalLookup', options && options.source);
      true && !this.validateFullName(options.source) && (0, _emberDebug.assert)('options.source must be a proper full name', this.validateFullName(options.source));

      normalizedFullName = this.normalize(fullName);
      normalizedSource = this.normalize(options.source);


      return expandLocalLookup(this, normalizedFullName, normalizedSource);
    } else if (this.fallback) {
      return this.fallback.expandLocalLookup(fullName, options);
    } else {
      return null;
    }
  };

  function expandLocalLookup(registry, normalizedName, normalizedSource) {
    var cache = registry._localLookupCache;
    var normalizedNameCache = cache[normalizedName];

    if (!normalizedNameCache) {
      normalizedNameCache = cache[normalizedName] = Object.create(null);
    }

    var cached = normalizedNameCache[normalizedSource];

    if (cached !== undefined) {
      return cached;
    }

    var expanded = registry.resolver.expandLocalLookup(normalizedName, normalizedSource);

    return normalizedNameCache[normalizedSource] = expanded;
  }

  function resolve(registry, normalizedName, options) {
    if (options && options.source) {
      // when `source` is provided expand normalizedName
      // and source into the full normalizedName
      normalizedName = registry.expandLocalLookup(normalizedName, options);

      // if expandLocalLookup returns falsey, we do not support local lookup
      if (!normalizedName) {
        return;
      }
    }

    var cached = registry._resolveCache[normalizedName];
    if (cached !== undefined) {
      return cached;
    }
    if (registry._failCache[normalizedName]) {
      return;
    }

    var resolved = void 0;

    if (registry.resolver) {
      resolved = registry.resolver.resolve(normalizedName);
    }

    if (resolved === undefined) {
      resolved = registry.registrations[normalizedName];
    }

    if (resolved === undefined) {
      registry._failCache[normalizedName] = true;
    } else {
      registry._resolveCache[normalizedName] = resolved;
    }

    return resolved;
  }

  function has(registry, fullName, source) {
    return registry.resolve(fullName, { source: source }) !== undefined;
  }

  var privateNames = (0, _emberUtils.dictionary)(null);
  var privateSuffix = '' + Math.random() + Date.now();

  /*
  Public API for the container is still in flux.
  The public API, specified on the application namespace should be considered the stable API.
  // @module container
    @private
  */

  exports.Registry = Registry;
  exports.privatize = function (_ref6) {
    var fullName = _ref6[0];

    var name = privateNames[fullName];
    if (name) {
      return name;
    }

    var _fullName$split = fullName.split(':'),
        type = _fullName$split[0],
        rawName = _fullName$split[1];

    return privateNames[fullName] = (0, _emberUtils.intern)(type + ':' + rawName + '-' + privateSuffix);
  };
  exports.Container = Container;
  exports.buildFakeContainerWithDeprecations = buildFakeContainerWithDeprecations;
});