define('ember-purecloud-style/components/pc-style-icon-example', ['exports', 'ember-purecloud-style/templates/components/pc-style-icon-example'], function (exports, _pcStyleIconExample) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _pcStyleIconExample.default,
    classNames: ['pc-style-icon-example']
  });
});