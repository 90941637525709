define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/bitbucket/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/bitbucket/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const NOTIFICATION_TYPE = 'message.widget.data.notificationType';
    const PULL_REQUEST = 'PULL_REQUEST_POST';
    const EVENT_TITLE = 'message.widget.data.eventTitle';
    const PULL_REQUEST_CREATED = 'PULL REQUEST CREATED';
    const PULL_REQUEST_UPDATED = 'PULL REQUEST UPDATED';
    const PULL_REQUEST_MERGED = 'PULL REQUEST MERGED';
    const I18N_BASE = 'chat.bitbucket.';

    const BitBucketWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-bitbucket'],
        layout: _template.default,

        expanded: false,

        isPullRequest: _ember.default.computed(NOTIFICATION_TYPE, function () {
            return this.get(NOTIFICATION_TYPE) === PULL_REQUEST;
        }),

        isPullRequestCreated: _ember.default.computed(EVENT_TITLE, function () {
            return this.get(EVENT_TITLE) === PULL_REQUEST_CREATED;
        }),

        webhooksHeader: _ember.default.computed(function () {
            const intl = this.get('intl');
            const widget = this.get('message.widget.data');
            if (widget.notificationType === PULL_REQUEST) {
                let keypath;
                if (widget.eventTitle === PULL_REQUEST_UPDATED) {
                    keypath = 'pullRequestUpdated';
                } else if (widget.eventTitle === PULL_REQUEST_CREATED) {
                    keypath = 'pullRequestCreated';
                } else if (widget.eventTitle === PULL_REQUEST_MERGED) {
                    keypath = 'pullRequestMerged';
                }
                return intl.t(I18N_BASE + keypath, {
                    pullRequestTitle: widget.pullRequestTitle
                });
            } else {
                return intl.t(I18N_BASE + 'changesPushed', {
                    pushRepository: widget.pushRepo
                });
            }
        }),

        webhooksTitle: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            const intl = this.get('intl');
            if (widget.notificationType === PULL_REQUEST) {
                let keypath;
                if (widget.eventTitle === PULL_REQUEST_UPDATED) {
                    keypath = 'pullRequestUpdatedTitle';
                } else if (widget.eventTitle === PULL_REQUEST_CREATED) {
                    keypath = 'pullRequestCreatedTitle';
                } else if (widget.eventTitle === PULL_REQUEST_MERGED) {
                    keypath = 'pullRequestMergedTitle';
                }
                return intl.t(I18N_BASE + keypath, {
                    author: widget.pullRequestAuthorDisplayName,
                    url: widget.pullRequestUrl,
                    title: widget.pullRequestTitle,
                    repo: widget.repo,
                    repoUrl: widget.pullRequestDestinationRepoUrl
                });
            } else {
                return intl.t(I18N_BASE + 'changesPushedTitle', {
                    author: widget.pushUser,
                    repo: widget.pushRepo
                });
            }
        }),

        webhooksDetails: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return this.get('intl').t(I18N_BASE + 'pullRequestDetails', {
                source: widget.pullRequestSourceBranchName,
                destination: widget.pullRequestDestinationBranchName
            });
        }),

        actions: {
            toggleExpanded() {
                this.toggleProperty('expanded');
            }
        }
    });

    exports.default = BitBucketWidget;
});