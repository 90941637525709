define("ember-purecloud-components/components/station-disassociate-link/component", ["exports", "ember-purecloud-components/components/station-disassociate-link/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Component = Ember.Component;

  var _default = Component.extend({
    layout: _template.default,
    classNames: ['station-disassociate'],
    presence: inject.service(),
    entity: null,
    displayWarning: false,
    disassociating: false,
    actions: {
      onProceedDisassociateClick: function onProceedDisassociateClick() {
        Ember.run.debounce(this, function () {
          this.get('presence').disassociateUserFromStation(this.get('entity'));
          this.set('disassociating', true);
          this.set('displayWarning', false);
        }, 500);
      },
      onDisassociateClick: function onDisassociateClick() {
        this.set('displayWarning', true);
      }
    }
  });

  _exports.default = _default;
});