define('ember-wrap-urls/components/wrap-urls', ['exports', 'ember-wrap-urls/templates/components/wrap-urls'], function (exports, _wrapUrls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var WrapUrlsComponent = Ember.Component.extend({
    layout: _wrapUrls.default,
    tagName: '',
    parts: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('parts', this._textToParts(this.get('text')));
      this.set('urlComponent', this.getWithDefault('component', 'wrap-urls/url'));
    },
    _textToParts: function _textToParts(text) {
      text = text || '';
      text = text.toString();

      var parts = [];
      var lastIndex = 0;
      var match = void 0;
      var string = void 0;

      while (match = WrapUrlsComponent.regex.exec(text)) {
        var _match = match,
            _match2 = _slicedToArray(_match, 1),
            url = _match2[0];

        var _match3 = match,
            start = _match3.index;


        string = text.slice(lastIndex, start);

        if (string) {
          parts.push({ text: string });
        }

        parts.push({ url: url });

        lastIndex = start + url.length;
      }

      string = text.slice(lastIndex);

      if (string) {
        parts.push({ text: string });
      }

      return parts;
    }
  });

  WrapUrlsComponent.reopenClass({
    regex: /(https?|file|ftp):\/\/([a-zA-Z0-9~!@#$%^&*()_\-=+/?.:;',]*)?/g
  });

  exports.default = WrapUrlsComponent;
});