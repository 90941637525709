define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/template', 'ember-custom-fields-components/utils/field-util', 'ember-engine-custom-fields/mixins/toggle-field-panel', 'ember-engine-custom-fields/mixins/validation'], function (exports, _template, _fieldUtil, _toggleFieldPanel, _validation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    exports.default = Component.extend(_toggleFieldPanel.default, _validation.default, {
        layout: _template.default,
        classNames: ['field-configuration-editor'],
        validateNow: false,

        applyChange: function applyChange() {},
        cancelChange: function cancelChange() {},


        fields: [],
        fieldType: _fieldUtil.FIELD_TYPE,

        actions: {
            apply: function apply() {
                if (this.get('field.isConfigValid') && !this.get('hasLimitError')) {
                    this.applyChange(this.get('field'));
                } else {
                    this.updateValidateNow();
                }
            },
            cancel: function cancel() {
                this.cancelChange(this.get('field'));
                this.updateValidateNow(false);
            },
            updateLimitError: function updateLimitError(hasError) {
                this.set('hasLimitError', hasError);
            }
        }
    });
});