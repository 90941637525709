define('ember-chat-components/components/chat-panel/roster/quick-access-modal/component', ['exports', 'ember', 'keystroke', 'npm:fuse.js', 'ember-chat-components/components/chat-panel/roster/quick-access-modal/template'], function (exports, _ember, _keystroke, _npmFuse, _template) {
    'use strict';

    exports.__esModule = true;


    const FUSE_OPTIONS = {
        shouldSort: true,
        tokenize: true,
        tokenSeparator: /[\s\-_.]+/,
        threshold: 0.4,
        keys: ['subject'],
        getFn(obj, path) {
            return obj.get(path);
        }
    };

    exports.default = _ember.default.Component.extend({
        layout: _template.default,
        classNames: ['chat-quickaccess-modal'],

        chat: _ember.default.inject.service(),
        roster: _ember.default.inject.service(),

        didInsertElement() {
            this._super(...arguments);
            _ember.default.run.scheduleOnce('afterRender', this, () => {
                this.$('[autofocus]').trigger('focus');
            });
        },

        filterText: '',
        selectedIndex: 0,

        recentRooms: _ember.default.computed('chat.roomHistoryStack.[]', 'roster.allRoomsInRoster', function () {
            // roomHistoryStack is just room IDs, and most recent is in the last position,
            // so reverse to get MRU order, and map IDs to room objects from roster service
            const mruJids = (this.get('chat.roomHistoryStack') || []).slice().reverse();
            const allRooms = _ember.default.A(this.get('roster.allRoomsInRoster') || []);
            return _ember.default.A(mruJids.map(jid => allRooms.findBy('jid', jid))).compact();
        }),

        roomsWithMentionsAndRecents: _ember.default.computed.union('roster.allRoomsWithMentionsOrUnseen.[]', 'recentRooms'),

        roomsMatchingFilterText: _ember.default.computed('roster.allRoomsInRoster', 'filterText', function () {
            const allRooms = this.get('roster.allRoomsInRoster') || [];
            const filterText = this.get('filterText');
            if (filterText) {
                return _ember.default.A(this.findMatchingRooms(allRooms, filterText));
            }
            return _ember.default.A(allRooms).slice();
        }),

        items: _ember.default.computed('filterText', 'roomsMatchingFilterText', 'roomsWithMentionsAndRecents', function () {
            let rooms = this.get('filterText') ? this.get('roomsMatchingFilterText') : this.get('roomsWithMentionsAndRecents');

            return _ember.default.A(rooms).reject(room => room.get('active') || room.get('rosterless')).uniqBy('jid').map(room => {
                return {
                    id: room.get('jid'),
                    name: room.get('subject') || '',
                    highlight: room.get('unseen') > 0 || room.get('mentions') > 0,
                    count: (room.get('isOneToOne') ? room.get('unseen') : room.get('mentions')) || 0,
                    group: !room.get('isOneToOne')
                };
            }).slice(0, 10); // first 10 is plenty, otherwise it's not "quick" access
        }),

        selectedItem: _ember.default.computed('selectedIndex', 'items', function () {
            return (this.get('items') || [])[this.get('selectedIndex')];
        }),

        updateFilter(filterText) {
            this.set('filterText', filterText);
            // Always reset the selection when the filter changes since the results probably changed
            this.set('selectedIndex', 0);
        },

        findMatchingRooms(rooms, filter) {
            return new _npmFuse.default(rooms, FUSE_OPTIONS).search(filter);
        },

        moveSelection(delta) {
            if (delta) {
                const items = this.get('items') || [];
                const prevSelectedIndex = this.get('selectedIndex');
                const lastIndex = Math.max(items.length - 1, 0);
                const newSelectedIndex = Math.max(0, Math.min(lastIndex, Math.max(0, prevSelectedIndex) + delta));
                this.set('selectedIndex', newSelectedIndex);
            }
        },

        chooseItem(item) {
            const chosenItem = item || this.get('selectedItem');
            if (chosenItem && chosenItem.id) {
                this.bubbleAction({
                    action: 'openChatRoom',
                    actionContext: [chosenItem.id]
                });
                this.dismiss();
            }
        },

        dismiss() {
            this.updateFilter('');
            this.sendAction('onDismiss');
        },

        actions: {
            onSearchInput(e) {
                this.updateFilter(e.target.value);
            },
            onSearchKeydown(e) {
                const key = _keystroke.default.codeToString(e.keyCode);
                if (key === 'ENTER') {
                    e.preventDefault();
                    this.send('chooseItem');
                } else if (key === 'DOWN_ARROW' || key === 'TAB' && !e.shiftKey || key === 'J' && e.ctrlKey) {
                    e.preventDefault();
                    this.send('moveSelectionDown');
                } else if (key === 'UP_ARROW' || key === 'TAB' && e.shiftKey || key === 'K' && e.ctrlKey) {
                    e.preventDefault();
                    this.send('moveSelectionUp');
                }
            },
            dismiss() {
                this.dismiss();
            },
            chooseItem(item) {
                this.chooseItem(item);
            },
            moveSelectionDown() {
                this.moveSelection(1);
            },
            moveSelectionUp() {
                this.moveSelection(-1);
            }
        }
    });
});