define("ember-purecloud-components/utils/routing-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    INTERACTING: 'INTERACTING',
    IDLE: 'IDLE',
    NOT_RESPONDING: 'NOT_RESPONDING',
    COMMUNICATING: 'COMMUNICATING',
    OFF_QUEUE: 'OFF_QUEUE'
  };
  _exports.default = _default;
});