define("ember-purecloud-components/components/progress-underlay/component", ["exports", "ember-purecloud-components/components/progress-underlay/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // config
    layout: _template.default,
    classNames: ['progress-underlay-container'],
    init: function init() {
      this._super.apply(this, arguments);

      this._width = 0;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var newWidth = String(this.get('width'));
      var oldWidth = String(this.get('_width'));

      if (oldWidth !== newWidth) {
        this.updateWidth(newWidth);
        this.set('_width', newWidth);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var width = this.get('_width');
      this.updateWidth(width);
    },
    updateWidth: function updateWidth(percentageWidth) {
      var $underlay = this.$('.progress-underlay');

      if ($underlay) {
        percentageWidth = Number(percentageWidth);
        var isFiniteNumber = !isNaN(percentageWidth) && isFinite(percentageWidth);
        var isPositivePercentage = isFiniteNumber && this.inRange(percentageWidth, 0, 101);
        var updatedWidth;

        if (isPositivePercentage) {
          updatedWidth = "".concat(percentageWidth, "%");
        } else {
          updatedWidth = '0%';
        }

        $underlay.width(Ember.String.htmlSafe(updatedWidth));
      }
    },
    inRange: function inRange(num, init, final) {
      if (final === undefined) {
        final = init;
        init = 0;
      }

      return num >= Math.min(init, final) && num < Math.max(init, final);
    }
  });

  _exports.default = _default;
});