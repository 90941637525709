define("ember-cli-testing/utils/utils", ["exports"], function (exports) {
    exports.delay = delay;
    exports.inject = inject;
    exports.register = register;
    exports.unregister = unregister;
    exports.resolve = resolve;
    exports.lookupFactory = lookupFactory;
    exports.registerAndInject = registerAndInject;

    function delay(milliseconds) {
        return new Promise(function (resolve) {
            return setTimeout(resolve, milliseconds);
        });
    }

    /**
    * A dependency injection method compatible with both Ember.Application and Ember.Container in both Ember 1.x and 2.x.
    * This method should only be used if you need to support both versions (like this add-on does).
    *
    * @param {Ember.Application|Ember.Container} container
    * @param {Array.<string>} target       - The full name of the object into which the dependency will be injected.
    * @param {string}         injectedName - The name of the property on `this` on which the dependency will be accessible.
    * @param {string}         fullName     - The full name of the dependency in the format '{object type}:{name}'. Example: 'service:addresses'.
    */

    function inject(container, target, injectedName, fullName) {
        if (container.inject) {
            // The new public method for Ember.Application introduced in 2.1.
            return container.inject(target, injectedName, fullName);
        } else if (container.injection) {
            // Works for both Ember.Application and Ember.Container in 1.13.
            return container.injection(target, injectedName, fullName);
        } else {
            // Needed for Ember.Container in 2.5.
            return container.registry.injection(target, injectedName, fullName);
        }
    }

    /**
    * An object registration method compatible with both Ember.Application and Ember.Container in Ember 1.x and 2.x.
    * This method should only be used if you need to support both versions (like this add-on does).
    *
    * @param {Ember.Application|Ember.Container} container
    * @param {string}         fullName     - The full name of the dependency in the format '{object type}:{name}'. Example: 'service:addresses'.
    * @param {Ember.Object}   dependency   - The dependency to register
    */

    function register(container, fullName, dependency) {
        if (container.register) {
            // Works for both Ember.Application and Ember.Container in 1.13 and for Ember.Application in 2.5.
            return container.register(fullName, dependency);
        } else {
            // Needed for Ember.Container in 2.5;
            return container.registry.register(fullName, dependency);
        }
    }

    /**
    * An object unregistration method compatible with both Ember.Application and Ember.Container in Ember 1.x and 2.x.
    * This method should only be used if you need to support both versions (like this add-on does).
    *
    * @param {Ember.Application|Ember.Container} container
    * @param {string}         fullName     - The full name of the dependency in the format '{object type}:{name}'. Example: 'service:addresses'.
    */

    function unregister(container, fullName) {
        if (container.unregister) {
            // Works for Ember.Container in 1.13 and for Ember.Application in 2.5.
            return container.unregister(fullName);
        } else {
            // Needed for Ember.Container in 2.5;
            return container.registry.unregister(fullName);
        }
    }

    /**
    * A resolver method compatible with both Ember.Application and Ember.Container in Ember 1.x and 2.x.
    * This method should only be used if you need to support both versions (like this add-on does).
    *
    * @param {Ember.Application|Ember.Container} container
    * @param {string}         fullName     - The full name of the dependency in the format '{object type}:{name}'. Example: 'service:addresses'.
    */

    function resolve(container, fullName) {

        if (container.resolveRegistration) {
            // The new public method for Ember.Application in 2.1.
            return container.resolveRegistration(fullName);
        } else if (container.register) {
            // Works for Ember.Container in 1.13.
            return container.resolve(fullName);
        } else {
            // Needed for Ember.Container in 2.5.
            return container.registry.resolve(fullName);
        }
    }

    /**
    * A factory lookup method compatible with both Ember 1.x and 2.x.
    * This method should only be used if you need to support both versions (like this add-on does).
    *
    * @param {Ember.Application|Ember.Container} container
    * @param {string}         fullName     - The full name of the factory. Example: 'config:environment'.
    */

    function lookupFactory(container, fullName) {
        return container.resolveRegistration ? container.resolveRegistration(fullName) : container.lookupFactory(fullName);
    }

    /**
    * Registers an object and injects it into one or more other registered objects.
    *
    * @param {Object}          options
    * @param {Ember.Object}    options.dependency   - The object to inject
    * @param {string}          options.fullName     - In the format '{object type}:{name}'. Example: 'service:addresses'.
    * @param {string}          options.injectedName - The name of the property on `this` on which the object will be accessible.
    * @param {Array.<string>}  options.targets      - The full names of the objects into which the object will be injected.
    * @param {Ember.Container} options.container    - The container in which the injection will occur (e.g. the application or test container).
    *
    * @example
    *
    * import PureCloudApiService from '../services/purecloud-api';
    *
    * registerAndInject({
    *     dependency: PureCloudApiService,
    *     fullName: 'service:purecloud-api',
    *     injectedName: 'api',
    *     targets: [
    *         'component',       // Injects into *all* components.
    *         'service:account', // Injects into the service named 'account'.
    *         'route'            // Injects into *all* routes.
    *     ],
    *     container: application
    * });
    *
    * // Now the PureCloudApiService is available in the targets as this.api
    */

    function registerAndInject(_ref) {
        var dependency = _ref.dependency;
        var fullName = _ref.fullName;
        var injectedName = _ref.injectedName;
        var targets = _ref.targets;
        var container = _ref.container;

        register(container, fullName, dependency);
        targets.forEach(function (target) {
            return inject(container, target, injectedName, fullName);
        });
    }
});