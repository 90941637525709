define("ember-webrtc-components/mixins/webrtc-service/webrtc-message-notifications", ["exports", "ember-webrtc-components/utils/hosted-context"], function (_exports, _hostedContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      observer = Ember.observer,
      inject = Ember.inject;

  var _default = Mixin.create({
    session: inject.service(),
    incomingInvitations: Ember.A(),
    intl: inject.service(),
    ring: inject.service(),
    ringtoneSelection: Ember.computed.reads('ring.ringtoneSelection'),
    // eslint-disable-next-line ember/no-observers
    _handleIncomingConversationsUpdates: observer('incomingInvitations.[]', function () {
      var sound = this.get('sound');
      var ring = this.get('ring');

      if (this.get('session.person.presence') === 'busy') {
        return;
      }

      var invitationsWithSound = this.get('incomingInvitations').filter(function (item) {
        // indvidual rooms only
        var room = item.get('room');
        return room.isOneToOneRoom() || !room.get('group') && !room.get('oneToOneTarget');
      });

      if (!invitationsWithSound) {
        return;
      }

      if (invitationsWithSound.length > 0 && !sound.isRinging()) {
        sound.playRingtone(ring.ringerVolume, ring.getRingerOutputDeviceId(), this.get('ringtoneSelection') || 'phone');
      } else if (invitationsWithSound.length === 0 && sound.isRinging()) {
        sound.stopRingtone(this.get('ringtoneSelection') || 'phone');
      }
    }),
    // eslint-disable-next-line ember/no-observers
    _handleStartingVideo: observer('activeRoom.showingVideo', function () {
      if (!this.get('activeRoom.showingVideo')) {
        return;
      }

      var existingInvitation = this.get('incomingInvitations').findBy('room', this.get('activeRoom'));

      if (existingInvitation) {
        this.get('incomingInvitations').removeObject(existingInvitation);
      }
    }),
    getInvitationMessage: function getInvitationMessage(room, person, count) {
      var intl = this.get('intl');

      if (room.isOneToOneRoom()) {
        return intl.t('ewc.singleInvitation', {
          personName: person.get('name')
        }).toString();
      } else if (count) {
        return intl.t('ewc.countInvitation', {
          count: count,
          roomName: room.getRawDisplaySubject()
        });
      }

      return intl.t('ewc.groupInvitation', {
        personName: person.get('name'),
        roomName: room.getRawDisplaySubject()
      }).toString();
    },
    createInvitation: function createInvitation(room, person, sessionId) {
      var existing = this.get('incomingInvitations').findBy('entity', person);

      if (existing && existing.get('room') === room) {
        // same person and room
        return existing;
      } else if (existing) {
        // same person, different room
        this.get('incomingInvitations').removeObject(existing);
      }

      var existingForRoom = this.get('incomingInvitations').filterBy('room', room);
      var count;

      if (existingForRoom.length > 0) {
        count = existingForRoom.length + 1;
      }

      if (room.isOneToOneRoom()) {
        room.set('invited', true);
      }

      return Ember.Object.create({
        entity: person,
        room: room,
        message: this.getInvitationMessage(room, person, count),
        sessionId: sessionId
      });
    },
    sendVideoInvitation: function sendVideoInvitation(room, person, sessionId) {
      var invitation = this.createInvitation(room, person, sessionId);

      if (this.get('chat').isActiveRoom(room) && room.get('showingVideo')) {
        this.answerVideoByInvitation(invitation);
        return;
      }

      if (room.isOneToOneRoom()) {
        var context = this.get('application.hostedContext');
        this.get('incomingInvitations').pushObject(invitation);

        if (context && context.isHosted()) {
          context.sendEventToDesktop(_hostedContext.EVENTS.WEBRTC_CONNECTION_INCOMING, room);
        }
      }
    },
    pendingSession: null,
    getNotificationTitle: function getNotificationTitle(room, chatUser) {
      if (room.isOneToOneRoom()) {
        return chatUser.get('name');
      } else {
        var personName = chatUser.get('name');
        var roomName = room.getRawDisplaySubject();
        return this.get('intl').t('ewc.notification.titles.chatRoomMessage', {
          personName: personName,
          roomName: roomName
        }).toString();
      }
    }
  });

  _exports.default = _default;
});