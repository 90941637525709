define("ember-webrtc-components/components/webrtc-participant/component", ["exports", "ember-purecloud-components/mixins/logger", "ember-webrtc-components/mixins/video-element-utils", "ember-webrtc-components/components/webrtc-participant/template"], function (_exports, _logger, _videoElementUtils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend(_logger.default, _videoElementUtils.default, {
    classNameBindings: ['webrtc-participant', 'hide:hide', 'live:live:hidden', 'shouldUseV2:webrtc-participant-v2:webrtc-participant'],
    layout: _template.default,
    hide: false,
    isAcdVideo: false,
    webrtc: inject.service(),
    shouldUseV2: computed('isAcdVideo', 'webrtc.screenShareViaHawk', function () {
      return !this.get('isAcdVideo') || this.get('webrtc.screenShareViaHawk');
    }),
    live: computed('shouldUseV2', 'conversation.isActive', 'participant.{major,selected}', function () {
      if (!this.get('shouldUseV2')) {
        return true;
      }

      return (this.get('participant.major') || this.get('participant.selected')) && (this.get('conversation.isIdle') || this.get('conversation.isActive'));
    }),
    actions: {
      selectParticipant: function selectParticipant() {
        this.get('conversation').selectParticipant(this.get('participant'));
      },
      muteParticipant: function muteParticipant() {
        this.get('conversation').muteParticipant(this.get('participant'));
      }
    }
  });

  _exports.default = _default;
});