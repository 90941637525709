define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/text/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/text/template', 'ember-engine-custom-fields/mixins/toggle-field-panel', 'ember-engine-custom-fields/mixins/validation'], function (exports, _template, _toggleFieldPanel, _validation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    exports.default = Component.extend(_toggleFieldPanel.default, _validation.default, {
        layout: _template.default,
        classNames: ['field field-text']
    });
});