define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/zendesk/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/zendesk/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const I18N_BASE = 'chat.zendesk.';
    const NEW_TICKET = 'ticket_created';
    const UPDATE_TICKET = 'ticket_status_changed';
    const COMMENT_TICKET = 'ticket_comment';

    const ZenDeskWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-zendesk'],
        layout: _template.default,

        expanded: false,

        webhooksStatus: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            if (widget.status === 'New') {
                return 'identified';
            } else if (widget.status === 'Pending') {
                return 'monitoring';
            } else if (widget.status === 'Solved') {
                return 'success';
            }
            return '';
        }),

        webhooksHeader: _ember.default.computed(function () {
            const intl = this.get('intl');
            const widget = this.get('message.widget.data');
            let keypath;
            if (widget.notificationType === UPDATE_TICKET) {
                return intl.t(I18N_BASE + 'updateTicket', {
                    url: widget.link,
                    title: widget.title,
                    status: widget.status
                });
            } else {
                if (widget.notificationType === NEW_TICKET) {
                    keypath = 'newTicket';
                } else if (widget.notificationType === COMMENT_TICKET) {
                    keypath = 'commentTicket';
                } else {
                    keypath = 'default';
                }
                return intl.t(I18N_BASE + keypath, {
                    url: widget.link,
                    title: widget.title
                });
            }
        })
    });

    exports.default = ZenDeskWidget;
});