define('ember-engine-custom-fields/controllers/data-models/schema', ['exports', 'ember-engine-custom-fields/mixins/notification-set'], function (exports, _notificationSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Controller = Ember.Controller,
        computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias;
    exports.default = Controller.extend(_notificationSet.default, {
        intl: inject.service(),
        store: inject.service('store'),
        logger: inject.service('stash-logger'),

        fields: null,

        schema: alias('model'),

        showListView: function showListView() {},

        actions: {
            save: function save(schema) {
                var _this = this;

                schema.save().then(function () {
                    var alertTitle = 'emberEngineCustomFields.notification_titles.customFieldsSaved';
                    var alertMsg = 'emberEngineCustomFields.notification.updateMayAppearLater';
                    _this._showNotification(alertTitle, alertMsg, { isError: false });
                    _this.showListView();
                }).catch(function (error) {
                    var alertMsg = 'emberEngineCustomFields.notification.errorSaving';
                    _this._showNotification(null, alertMsg, { isError: true });
                    _this.get('logger').error('Unable to save the Custom Fields', {
                        remoteData: {
                            error: error.message
                        }

                    });
                });
            },
            cancel: function cancel() {
                this.showListView();
            }
        }
    });
});