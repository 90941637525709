define("ember-purecloud-components/models/screenshare-communication", ["exports", "ember-data", "ember-purecloud-components/models/communication"], function (_exports, _emberData, _communication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _communication.default.extend({
    // ----------------------------------------------------------------------------
    // Raw Properties
    // ----------------------------------------------------------------------------
    context: _emberData.default.attr(),
    sharing: _emberData.default.attr(),
    mediaType: _emberData.default.attr(),
    update: function update(comUpdate) {
      this._super.apply(this, arguments);

      var updatableAttrs = ['context', 'sharing', 'mediaType'];
      var updateProperties = {};

      for (var _i = 0, _updatableAttrs = updatableAttrs; _i < _updatableAttrs.length; _i++) {
        var updatable = _updatableAttrs[_i];
        var updatedValue = comUpdate[updatable];

        if (typeof updatedValue !== 'undefined') {
          updateProperties[updatable] = updatedValue;
        }
      }

      this.setProperties(updateProperties);
    }
  });

  _exports.default = _default;
});