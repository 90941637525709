define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/statuspage/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/statuspage/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const I18N_BASE = 'chat.statuspage.';

    const StatusPageWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-statuspage'],
        layout: _template.default,

        expanded: false,

        webhooksStatus: _ember.default.computed(function () {
            return this.get('message.widget.data.incidentStatus');
        }),

        webhooksHeader: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return this.get('intl').t(I18N_BASE + 'incident', {
                incidentStatus: widget.incidentStatus.capitalize(),
                incidentName: widget.incidentName,
                incidentUrl: widget.incidentUrl
            });
        })
    });

    exports.default = StatusPageWidget;
});