define("ember-purecloud-components/components/telephone-input/component", ["exports", "ember-purecloud-components/components/telephone-input/template", "ember-purecloud-components/components/validators/phone", "intl-tel-input"], function (_exports, _template, _phone, _intlTelInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    number: null,
    validationType: null,
    telInput: null,
    // initialize plugin script on the input element
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var $input = this.$('.phone-input');
      this.set('telInput', (0, _intlTelInput.default)(this.element.querySelector('.phone-input'), {
        nationalMode: false
      }));

      if ($input.val() !== '') {
        this.notifyPropertyChange('isValid');
      }

      $input.on('countrychange', function () {
        _this.notifyPropertyChange('isValid');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var $input = this.$('.phone-input');
      $input.off();

      if (this.telInput) {
        this.telInput.destroy();
      }
    },
    onblur: Ember.computed('validationType', function () {
      return this.get('validationType') === 'onblur';
    }),
    // checks if phone number is valid via plugin
    isValid: Ember.computed('number', function () {
      var valid = _phone.default.isValid(this.get('intl'), this.telInput);

      return valid;
    }),
    hasError: Ember.computed.not('isValid'),
    errorMsg: Ember.computed('isValid', function () {
      var intl = this.get('intl');
      var isValid = this.get('isValid');

      if (!isValid) {
        // https://github.com/Bluefieldscom/intl-tel-input/blob/master/lib/libphonenumber/src/utils.js#L175
        var errorCode = this.telInput.getValidationError();
        var message = '';

        switch (errorCode) {
          case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
            message = intl.t('phoneInput.validation.invalidCountryCode');
            break;

          case intlTelInputUtils.validationError.TOO_SHORT:
            message = intl.t('phoneInput.validation.tooShort');
            break;

          case intlTelInputUtils.validationError.TOO_LONG:
            message = intl.t('phoneInput.validation.tooLong');
            break;

          case intlTelInputUtils.validationError.NOT_A_NUMBER:
            message = intl.t('phoneInput.validation.notANumber');
            break;

          default:
            message = intl.t('phoneInput.validation.notANumber');
        }

        return message;
      }

      return null;
    })
  });

  _exports.default = _default;
});