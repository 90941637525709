define('ember-chat-components/components/chat-panel/conversation/participant-pane-v2/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/participant-pane-v2/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { computed, Component, inject, observer } = _ember.default;

    const BADGE_TOGGLE_ID = 'gcucc.ui.chatBadging';

    exports.default = Component.extend({
        classNames: ['participant-pane-v2'],
        layout: _template.default,

        preferences: inject.service(),
        feature: inject.service(),
        chat: inject.service(),
        permissions: inject.service(),
        session: inject.service(),
        notification: inject.service(),
        intl: inject.service(),

        currentTab: 'all',
        showAllOptions: false,
        showVideoOptions: false,
        sort: null,
        activeRoom: null,

        videoView: null,
        videoSortedAlpha: null,
        allSorted: 0,
        showRemoveParticipantModal: false,
        selectedParticipantForRemoval: null,
        removingParticipant: false,

        removeGcUserFtIsOn: computed.readOnly('chat.allowUserRemoval'),

        actions: {
            openRemoveParticipantModal(selectedParticipantForRemoval) {
                this.set('selectedParticipantForRemoval', selectedParticipantForRemoval);
                this.set('showRemoveParticipantModal', true);
            },

            closeRemoveParticipantModal() {
                this.set('showRemoveParticipantModal', false);
            },

            async removeParticipant() {
                try {
                    _ember.default.run(() => this.set('removingParticipant', true));
                    await this.get('chat').deleteParticipantFromChatRoom(this.get('activeRoom'), this.get('selectedParticipantForRemoval'));
                    this.get('notification').success(this.get('intl').t('chat.participants.notifications.removeParticipant.successHeader'), this.get('intl').t('chat.participants.notifications.removeParticipant.successMessage'), { hideAfter: 5 });
                    _ember.default.Logger.log(this.get('intl').t('chat.participants.notifications.removeParticipant.successMessage'), {
                        roomJid: this.get('activeRoom.jid'),
                        userId: this.get('selectedParticipantForRemoval.id')
                    });
                } catch (err) {
                    this.get('notification').error(this.get('intl').t('chat.participants.notifications.removeParticipant.errorHeader'), this.get('intl').t('chat.participants.notifications.removeParticipant.errorMessage'), { hideAfter: 5 });
                    _ember.default.Logger.error(this.get('intl').t('chat.participants.notifications.removeParticipant.errorMessage'), {
                        error: err,
                        roomJid: this.get('activeRoom.jid'),
                        userId: this.get('selectedParticipantForRemoval.id')
                    });
                } finally {
                    _ember.default.run(() => this.set('removingParticipant', false));
                    this.send('closeRemoveParticipantModal');
                }
            },

            changeTab(tab) {
                this.set('currentTab', tab);
            },

            selectParticipant(participant) {
                this.get('activeRoom.videoConversation').selectParticipant(participant);
            },

            muteParticipant(participant) {
                this.get('activeRoom.videoConversation').muteParticipant(participant);
            },

            setViewPreference(view) {
                this.get('preferences').setPreference('webrtc.videoView', view);
                this.set('videoView', view);
            },

            setSortPreference(sort) {
                this.get('preferences').setPreference('webrtc.videoSortedAlpha', sort);
                this.set('videoSortedAlpha', sort);
            },

            setAllPreference(sort) {
                this.get('preferences').setPreference('chat.participantSort', sort);
                this.set('allSorted', sort);
            },

            paneDeselected() {
                this.get('paneDeselected')(['isInviteSelected', 'isParticipantsSelected', 'isSettingsSelected', 'isSearchSelected']);
            }
        },

        showingVideo: computed('currentTab', function () {
            return this.get('currentTab') === 'video';
        }),

        displayIntegrationBadge: _ember.default.computed('session.features', function () {
            return this.get('session.features')[BADGE_TOGGLE_ID];
        }),

        showAvatars: computed('participants.length', function () {
            return this.get('participants.length') < 15;
        }),

        inLastN: computed('participant.{inLastN,positiveBitrateCount}', function () {
            return this.get('participant.inLastN') || this.get('participant.positiveBitrateCount') > 3;
        }),

        participants: computed('activeRoom.sortedParticipants.[]', 'activeRoom.videoConversation.{participants.[],hasJoined}', 'currentTab', function () {
            let list = this.get('activeRoom.sortedParticipants');

            if (this.get('activeRoom.videoConversation') && this.get('currentTab') === 'video') {
                if (this.get('activeRoom.videoConversation.hasJoined')) {
                    list = this.get('activeRoom.videoConversation.participants');
                }
            }

            if (!list) {
                return [];
            }
            return list;
        }),

        sortedParticipants: computed('participants.[]', 'allSorted', 'videoSortedAlpha', function () {
            let list = this.get('participants');

            if (this.get('videoSortedAlpha') && this.get('currentTab') === 'video') {
                return list.sortBy('person.name');
            } else if (this.get('allSorted') === this.get('sort.alpha') && this.get('currentTab') === 'all') {
                return list.sortBy('name');
            } else if (this.get('allSorted') === this.get('sort.available') && this.get('currentTab') === 'all') {
                return list.filterBy('isAvailable');
            }
            return list;
        }),

        // eslint-disable-next-line ember/no-observers
        setDefaultTab: observer('activeRoom.videoConversation', function () {
            if (this.get('activeRoom.videoConversation')) {
                this.set('currentTab', 'video');
            } else {
                this.set('currentTab', 'all');
            }
        }),

        authorizedToRemoveParticipant: computed('activeRoom', 'activeRoom.roomOwners.[]', 'session.person', 'permissions.canViewBusinessChat', function () {
            const room = this.get('activeRoom');
            if (!room) {
                return false;
            }
            const person = this.get('session.person');
            const canViewChat = this.get('permissions.canViewBusinessChat');
            return room.isRoomOwner(person) && canViewChat;
        }),

        init() {
            this._super(...arguments);

            this.set('sort', {
                recent: 0,
                alpha: 1,
                available: 2
            });

            this.get('preferences').getPreference('chat').then(chat => {
                chat = chat || {};
                this.set('allSorted', chat.participantSort || this.get('sort.recent'));
            });

            const prefPromise = this.get('preferences').getPreference('webrtc');

            _ember.default.RSVP.all([prefPromise]).then(([webrtcPrefs]) => {
                webrtcPrefs = webrtcPrefs || {};
                this.set('allowAvatarView', false);
                const videoView = this.get('allowAvatarView') ? webrtcPrefs.viewView : false;
                this.set('videoView', videoView);
                this.set('videoSortedAlpha', webrtcPrefs.videoSortedAlpha);
            });
        }
    });
});