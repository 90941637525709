define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/mention-item/component', ['exports', 'ember', 'ember-data', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/mention-item/template', 'ember-chat-components/models/person'], function (exports, _ember, _emberData, _template, _person) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed, inject } = _ember.default;
    const { PromiseArray } = _emberData.default;

    exports.default = Component.extend({
        classNames: ['mention-card'],
        layout: _template.default,

        store: inject.service(),

        mediaItem: null,

        mentions: computed.reads('mediaItem.mentions'),

        people: computed('mentions', function () {
            let mentionIds = this.get('mentions').mapBy('oid');
            let promise = this.get('store').findMany('person', mentionIds, _person.BASE_PERSON);
            return PromiseArray.create({ promise });
        }),

        singleMention: computed.equal('mentions.length', 1),

        didInsertElement() {
            this._super(...arguments);
            this._safeScroll();
        },

        _safeScroll() {
            _ember.default.run(() => {
                _ember.default.run.scheduleOnce('afterRender', () => {
                    this.bubbleAction({
                        action: 'safeScroll'
                    });
                });
            });
        }
    });
});