define('ember-softphone-integration/services/vendor-implementations/interface', ['exports', 'ember-softphone-integration/mixins/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      inject = Ember.inject,
      RSVP = Ember.RSVP;
  exports.default = Service.extend(_logger.default, {
    headset: inject.service(),

    vendorName: 'Not Specified',
    isConnecting: false, // trying to connect with the headset controlling software, ex: plantronics hub
    isConnected: false, // represents a connection to the headset controlling software, ex: plantronics hub
    isDeviceAttached: false,
    isMuted: false,

    deviceLabelMatchesVendor: function deviceLabelMatchesVendor() /* label */{
      throw new Error('Not implemented');
    },
    connect: function connect() {
      return RSVP.reject(new Error('Not implemented'));
    },
    disconnect: function disconnect() {
      return RSVP.reject(new Error('Not implemented'));
    },
    incomingCall: function incomingCall() /* callInfo */{
      return RSVP.reject(new Error('Not implemented'));
    },
    outgoingCall: function outgoingCall() /* callInfo */{
      return RSVP.reject(new Error('Not implemented'));
    },
    answerCall: function answerCall() /* conversationId */{
      return RSVP.reject(new Error('Not implemented'));
    },
    endCall: function endCall() /* conversationId, hasOtherActiveCalls */{
      return RSVP.reject(new Error('Not implemented'));
    },
    setMute: function setMute() /* value */{
      return RSVP.reject(new Error('Not implemented'));
    },
    setHold: function setHold() /* conversationId, value */{
      return RSVP.reject(new Error('Not implemented'));
    },


    // actions triggered from headset
    deviceAnsweredCall: function deviceAnsweredCall() {
      this.info('Headset answered the call');
      this.get('headset').triggerDeviceAnsweredCall();
    },
    deviceRejectedCall: function deviceRejectedCall(conversationId) {
      this.info('Headset rejected the call', { conversationId: conversationId });
      this.get('headset').triggerDeviceRejectedCall(conversationId);
    },
    deviceEndedCall: function deviceEndedCall() {
      this.info('Headset ended the call');
      this.get('headset').triggerDeviceEndedCall();
    },
    deviceMuteChanged: function deviceMuteChanged(isMuted) {
      this.set('isMuted', isMuted);
      this.get('headset').triggerDeviceMuteStatusChanged(isMuted);
    },
    deviceHoldStatusChanged: function deviceHoldStatusChanged(isHeld, toggle) {
      this.get('headset').triggerDeviceHoldStatusChanged(isHeld, toggle);
    },
    defaultHeadsetChanged: function defaultHeadsetChanged(deviceName, deviceInfo, deviceIds) {
      this.get('headset').triggerDefaultHeadsetChanged({ deviceInfo: deviceInfo, deviceName: deviceName, deviceIds: deviceIds });
    }
  });
});