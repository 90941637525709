define('ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/giphy-picker/component', ['exports', 'ember', 'ember-chat-components/utils/giphy', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/giphy-picker/template'], function (exports, _ember, _giphy, _template) {
    'use strict';

    exports.__esModule = true;


    const { computed, observer, run, inject } = _ember.default;

    exports.default = _ember.default.Component.extend({
        classNames: ['giphy-picker'],
        classNameBindings: ['expandView:expanded-picker'],
        layout: _template.default,

        preferences: inject.service(),

        query: '',
        focus: false,
        isLoading: false,
        gifs: null,
        preview: null,

        currentPage: 0,
        lastPage: Infinity,
        lastPageLoaded: false,
        maxPageSize: 16,
        minimumPageSize: 4,
        expandView: false,
        initialQuery: true,

        init() {
            this._super(...arguments);
            this.setProperties({
                _cancellation: {},
                gifs: []
            });
        },

        saveGiphyState() {
            const preferences = this.get('preferences');
            preferences.getPreference('chat.global').then(prefs => {
                prefs = prefs || {};
                prefs.giphyState = this.get('expandView');
                preferences.setPreference('chat.global', prefs);
            });
        },

        giphyState: computed.reads('preferences.chat.global.giphyState'),

        giphyPreference: computed.reads('preferences.chat.global.giphyKeyboard'),

        // eslint-disable-next-line ember/no-observers
        giphyDefaultPreference: observer('showPicker', function () {
            _ember.default.run.debounce(this, this.checkPreferenceState, 500);
        }),

        checkPreferenceState() {
            const giphyPreference = this.get('giphyPreference');
            const showPicker = this.get('showPicker');

            if (showPicker) {
                if (this.get('initialQuery')) {
                    this.set('initialQuery', false);

                    if (giphyPreference === 'rememberState') {
                        const giphyState = this.get('giphyState');
                        if (typeof giphyState === 'boolean') {
                            this.set('expandView', giphyState);
                        }
                    } else {
                        this.set('expandView', giphyPreference === 'expanded');
                    }
                }
            } else {
                this.set('initialQuery', true);
                this.saveGiphyState();
            }
        },

        pageSize: computed('expandView', function () {
            return this.get('expandView') ? this.get('maxPageSize') : this.get('minimumPageSize');
        }),

        requestSize: computed('maxPageSize', function () {
            return this.get('maxPageSize') * 2;
        }),

        showPicker: computed.notEmpty('query'),

        showPreview: computed('preview', function () {
            const sideBarWidth = 300;
            const containerWidth = this.$(window).width();
            const listWidth = this.$('.list-wrapper').width();
            const space = containerWidth - listWidth - sideBarWidth;
            return space > 350;
        }),

        isOnLastPage: computed('currentPage', 'lastPageLoaded', function () {
            const currentIsLast = this.get('currentPage') === this.get('lastPage');
            return this.get('lastPageLoaded') && currentIsLast;
        }),

        isEmpty: computed('lastPageLoaded', 'gifs', function () {
            return this.get('lastPageLoaded') && this.get('gifs').length === 0;
        }),

        visibleGifs: computed('gifs', 'currentPage', 'pageSize', function () {
            const gifs = this.get('gifs');
            const page = this.get('currentPage');
            const size = this.get('pageSize');
            const start = page * this.get('minimumPageSize');
            return gifs.slice(start, start + size);
        }),

        // eslint-disable-next-line ember/no-observers
        focusResults: observer('focus', function () {
            if (!this.get('focus')) {
                return;
            }

            const item = this.$('.item');
            if (item) {
                item.first().focus();
                item.last().off('blur').on('blur', () => _ember.default.run(() => this.sendAction('returnFocus')));
            }
        }),

        // eslint-disable-next-line ember/no-observers
        initialSearch: observer('query', 'showPicker', function () {
            if (!this.get('showPicker')) {
                return;
            }

            this.setProperties({
                gifs: [],
                currentPage: 0,
                lastPage: Infinity,
                lastPageLoaded: false,
                isLoading: true
            });
            run.debounce(this, this.search, 1000);
        }),

        // eslint-disable-next-line ember/no-observers
        cleanDeadPreview: observer('giphyGifs', function () {
            if (!this.get('gifs').includes(this.get('preview'))) {
                this.set('preview', null);
            }
        }),

        actions: {
            chooseGif(gif) {
                this.sendAction('gifSelected', gif);
            },

            selectGif(gif) {
                this.set('preview', gif);
            },

            deselectGif(gif) {
                if (this.get('preview') === gif) {
                    this.set('preview', null);
                }
            },

            nextPage() {
                if (this.get('isOnLastPage')) {
                    return;
                }

                const nextPage = this.getNextPageIndex();
                const bufferOffset = this.get('gifs.length') - nextPage * this.get('minimumPageSize');
                const minimumBufferDistance = this.get('maxPageSize') + this.get('minimumPageSize');
                const minimumBufferRemaining = bufferOffset > minimumBufferDistance;
                if (minimumBufferRemaining) {
                    this.set('currentPage', nextPage);
                } else {
                    const nextPageLoaded = bufferOffset >= this.get('pageSize');
                    this.search(nextPageLoaded).then(() => {
                        this.set('currentPage', nextPage);
                    });
                }
            },

            previousPage() {
                if (this.get('currentPage')) {
                    const previousPage = this.getPreviousPageIndex();
                    this.set('currentPage', Math.max(0, previousPage));
                }
            },

            toggleView() {
                this.toggleProperty('expandView');
            }
        },

        getNextPageIndex() {
            return this.get('expandView') ? this.get('currentPage') + 4 : this.get('currentPage') + 1;
        },

        getPreviousPageIndex() {
            return this.get('expandView') ? this.get('currentPage') - 4 : this.get('currentPage') - 1;
        },

        search(nextPageLoaded) {
            if (this.get('lastPageLoaded')) {
                return;
            }

            const query = this.get('query');
            const limit = this.get('requestSize');
            const offset = this.get('gifs.length');

            this.set('isLoading', !nextPageLoaded);
            const search = _giphy.default.search(query, limit, offset);
            return this.cancellable(search).then(newGifs => {
                const gifs = this.get('gifs');
                this.set('gifs', gifs.concat(newGifs));
                this.set('lastPageLoaded', newGifs.length < limit);
                this.set('lastPage', Math.ceil(offset / 4));
            }).catch(error => _ember.default.Logger.error('GIPHY PICKER: error during search', {
                error
            })).finally(() => this.set('isLoading', false));
        },

        _cancellation: null, // like a symbol
        _pendingDefer: null,
        cancellable(promise) {
            const pending = this.get('_pendingDefer');
            const cancel = this.get('_cancellation');
            if (pending) {
                pending.reject(cancel);
            }
            const defer = _ember.default.RSVP.defer();
            promise.then(defer.resolve, defer.reject);
            this.set('_pendingDefer', defer);
            return new _ember.default.RSVP.Promise((resolve, reject) => {
                defer.promise.then(resolve).catch(error => {
                    if (error !== cancel) {
                        reject(error);
                    }
                }).finally(() => {
                    if (defer === this.get('_pendingDefer')) {
                        this.set('_pendingDefer', null);
                    }
                });
            });
        }
    });
});