define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/trello/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/trello/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const I18N_BASE = 'chat.trello.';

    const TrelloWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-trello'],
        layout: _template.default,

        webhooksHeader: _ember.default.computed(function () {
            const intl = this.get('intl');
            const widget = this.get('message.widget.data');
            const actionType = this.get('message.widget.data.actionType');

            if (actionType.indexOf('Card') > -1) {
                return intl.t(I18N_BASE + actionType, {
                    boardName: widget.boardName,
                    member: widget.member,
                    url: widget.url,
                    card: widget.card
                });
            } else if (actionType.indexOf('List') > -1) {
                return intl.t(I18N_BASE + actionType, {
                    boardName: widget.boardName,
                    member: widget.member,
                    url: widget.url,
                    list: widget.list
                });
            } else {
                return intl.t(I18N_BASE + actionType, {
                    boardName: widget.boardName,
                    member: widget.member,
                    url: widget.url
                });
            }
        })
    });

    exports.default = TrelloWidget;
});