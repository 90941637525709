define("ember-purecloud-components/mixins/conversation/emails", ["exports", "ember-purecloud-components/utils/conversation-states"], function (_exports, _conversationStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      A = Ember.A;

  var _default = Ember.Mixin.create({
    emailMessages: null,
    emailDraft: null,
    // TODO: Fix this hacky trigger for reloading messages
    _shouldReloadMessages: null,
    activeEmails: computed('emails.[]', function () {
      var allEmails = this.get('emails').toArray();
      var active = (allEmails || []).filter(function (email) {
        return email.get('state') !== _conversationStates.CONVERSATION_STATES.DISCONNECTED && email.get('state') !== _conversationStates.CONVERSATION_STATES.TERMINATED;
      });
      return A(active);
    }),
    emails: computed('currentUserParticipant.emails', function () {
      var currentUserParticipant = this.get('currentUserParticipant');

      if (!currentUserParticipant) {
        return A([]);
      }

      var userEmails = currentUserParticipant.get('emails');
      return userEmails.toArray();
    }),
    currentEmail: computed.alias('activeEmails.lastObject'),
    emailProvider: computed.alias('currentEmail.provider'),
    isInternalEmailProvider: computed.equal('emailProvider', 'PureCloud Email'),
    lastEmailTime: computed.reads('emailMessages.lastObject.time')
  });

  _exports.default = _default;
});