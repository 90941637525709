define('ember-chat-components/adapters/chat-search-result', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    'use strict';

    exports.__esModule = true;
    exports.default = _emberData.default.RESTAdapter.extend({
        search: _ember.default.inject.service(),

        query(store, type, query) {
            const { value, targetJids, pageSize, pageNumber } = query;

            const data = {
                types: ['messages'],
                sortBy: 'created',
                sortOrder: 'DESC',
                expand: ['to', 'from'],
                pageNumber,
                pageSize,
                query: [{
                    type: 'SIMPLE',
                    value,
                    fields: ['body']
                }, {
                    type: 'EXACT',
                    fields: ['targetJids'],
                    values: targetJids
                }]
            };

            return this.get('search').search(data);
        }
    });
});