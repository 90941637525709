define("ember-webrtc-components/components/webrtc-troubleshoot-panel/component", ["exports", "ember-purecloud-components/mixins/logger", "ember-purecloud-components/utils/dates", "ember-webrtc-components/components/webrtc-troubleshoot-panel/template"], function (_exports, _logger, _dates, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Component.extend(_logger.default, {
    classNames: 'troubleshoot-section',
    layout: _template.default,
    intl: computed.reads('webrtc.intl'),
    application: computed.alias('webrtc.application'),
    webrtc: inject.service(),
    session: computed.reads('webrtc.session'),
    notification: computed.reads('webrtc.notification'),
    video: true,
    audio: true,
    mediaOptions: null,
    reportPending: true,
    submittingReport: false,
    submitResult: null,
    showWebrtcDebugger: computed.bool('session.features.webrtcDebugger'),
    webrtcTroubleshooterIframe: computed.reads('session.features.webrtcTroubleshooterIframe'),
    init: function init() {
      this._super.apply(this, arguments);

      this._updateMediaOptions();
    },
    isHosted: computed.reads('application.hostedContext.isHostedProp'),
    // eslint-disable-next-line ember/no-observers
    _updateMediaOptions: observer('selectedCamera', 'selectedMicrophone', function () {
      var mediaOptions = this.get('webrtc').getMediaOptions({
        camera: this.get('selectedCamera'),
        microphone: this.get('selectedMicrophone')
      });
      this.set('mediaOptions', mediaOptions);
    }),
    submitTroubleshooterResults: function submitTroubleshooterResults(onSuccess, onError) {
      this.get('application').sendDiagnostics(this.request()).then(function () {
        onSuccess();
      }).catch(function (err) {
        onError(err);
      });
    },
    request: function request() {
      var intl = this.get('intl');
      return {
        topic: "".concat(intl.t('ewc.webrtcDiagnostics'), " ").concat(intl.t('common.report')),
        description: "".concat(intl.t('ewc.webrtcDiagnostics'), " ").concat(this.get('session.user.email')),
        data: {
          userAgent: navigator.userAgent,
          clientJid: this.get('session.person.jid'),
          clientLocation: window.location.toString(),
          clientTimestamp: (0, _dates.default)().format(),
          clientOrgName: this.get('applicationService.orgName'),
          clientLocale: this.get('applicationService.locale'),
          clientType: this.get('applicationService.hostedContext') ? 'Desktop' : 'Browser',
          clientVersion: this.get('applicationService.semver'),
          clientLogs: this.get('troubleshootingLog')
        }
      };
    },
    actions: {
      triggerWebrtcDump: function triggerWebrtcDump() {
        this.get('webrtc').triggerWebrtcDump();
      },
      openDebugger: function openDebugger() {
        this.toggleProperty('settingsVisible');
        this.get('webrtc').debugWebrtc();
      },
      submitDiagnostics: function submitDiagnostics() {
        var _this = this;

        var intl = this.get('intl');

        if (this.get('submitResult')) {
          this.logger.warn('Attempted to submit results multiple times');
          return;
        }

        this.submitTroubleshooterResults(function () {
          _this.set('submitResult', 'complete');
        }, function (err) {
          var title = intl.t('ewc.feedback.errorHeader').toString();
          var message = intl.t('ewc.feedback.errorText').toString();

          _this.get('notification').error(title, message);

          _this.logger.error('Error submitting report', {
            remoteData: err
          });
        });
      },
      close: function close() {
        this.attrs.close();
      },
      results: function results(troubleshootingLog) {
        this.set('reportPending', false);
        this.set('troubleshootingLog', troubleshootingLog);
      }
    }
  });

  _exports.default = _default;
});