define("ember-purecloud-components/components/presence-label/person-activity/component", ["exports", "ember-purecloud-components/components/presence-label/person-activity/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var NOT_RESPONDING = 'NOT_RESPONDING';

  function activityCounter(contactCenter, enterprise) {
    var contactCenterCount, enterpriseCount;

    if (contactCenter && _typeof(contactCenter) === 'object') {
      contactCenterCount = contactCenter.active;
    } else {
      contactCenterCount = contactCenter || 0;
    }

    if (enterprise && _typeof(enterprise) === 'object') {
      enterpriseCount = enterprise.active;
    } else {
      enterpriseCount = enterprise || 0;
    }

    return contactCenterCount + enterpriseCount;
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['person-activity-indicator'],
    classNameBindings: ['hasAcdActivity::no-acd-activity', 'hasAnyActivity::no-activity', 'hasActivity:has-activity:'],
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    person: null,
    routingStatus: Ember.computed.reads('person.activities.routingStatus.status'),
    conversationSummary: Ember.computed.reads('person.activities.conversationSummary'),
    call: Ember.computed.reads('conversationSummary.call'),
    chat: Ember.computed.reads('conversationSummary.chat'),
    email: Ember.computed.reads('conversationSummary.email'),
    message: Ember.computed.reads('conversationSummary.message'),
    socialExpression: Ember.computed.reads('conversationSummary.socialExpression'),
    video: Ember.computed.reads('conversationSummary.video'),
    hasAnyActivity: Ember.computed.or('hasActivity', 'hasAcdActivity'),
    summaryValues: Ember.computed.reads('person.activityValues'),
    hasConversationActivity: Ember.computed('summaryValues.{calls,chats,emails}', function () {
      var _this$getProperties = this.getProperties('summaryValues'),
          summaryValues = _this$getProperties.summaryValues;

      return summaryValues && (summaryValues.calls || summaryValues.chats || summaryValues.emails);
    }),
    showConversationSummaryIndicator: Ember.computed.reads('person.showConversationSummaryIndicator'),
    hasActivity: Ember.computed('totalCalls', 'totalVideo', function () {
      var _this$getProperties2 = this.getProperties('totalCalls', 'totalVideo'),
          totalCalls = _this$getProperties2.totalCalls,
          totalVideo = _this$getProperties2.totalVideo;

      return !!totalCalls || !!totalVideo;
    }),
    acdActivityCount: function acdActivityCount(key) {
      var data = this.get("".concat(key, ".contactCenter"));

      if (data && _typeof(data) === 'object') {
        return data.active || 0;
      }

      return data || 0;
    },
    hasAcdActivity: Ember.computed('call.contactCenter', 'chat.contactCenter', 'email.contactCenter', 'message.contactCenter', 'socialExpression.contactCenter', 'video.contactCenter', 'isNotResponding', function () {
      return this.acdActivityCount('call') > 0 || this.acdActivityCount('chat') > 0 || this.acdActivityCount('email') > 0 || this.acdActivityCount('socialExpression') > 0 || this.acdActivityCount('video') > 0 || this.acdActivityCount('message') > 0 || this.acdActivityCount('isNotResponding');
    }),
    totalCalls: Ember.computed('call.{contactCenter,enterprise}', 'call.contactCenter.active', 'call.enterprise.active', function () {
      var contactCenter = this.get('call.contactCenter');
      var enterprise = this.get('call.enterprise');
      return activityCounter(contactCenter, enterprise);
    }),
    totalChats: Ember.computed('chat.{contactCenter,enterprise}', 'chat.contactCenter.active', 'chat.enterprise.active', function () {
      var contactCenter = this.get('chat.contactCenter');
      var enterprise = this.get('chat.enterprise');
      return activityCounter(contactCenter, enterprise);
    }),
    totalEmails: Ember.computed('email.{contactCenter,enterprise}', 'email.contactCenter.active', 'email.enterprise.active', function () {
      var contactCenter = this.get('email.contactCenter');
      var enterprise = this.get('email.enterprise');
      return activityCounter(contactCenter, enterprise);
    }),
    totalMessages: Ember.computed('message.{contactCenter,enterprise}', 'message.contactCenter.active', 'message.enterprise.active', function () {
      var contactCenter = this.get('message.contactCenter');
      var enterprise = this.get('message.enterprise');
      return activityCounter(contactCenter, enterprise);
    }),
    totalSocialExpression: Ember.computed('socialExpression.{contactCenter,enterprise}', 'socialExpression.contactCenter.active', 'socialExpression.enterprise.active', function () {
      var contactCenter = this.get('socialExpression.contactCenter');
      var enterprise = this.get('socialExpression.enterprise');
      return activityCounter(contactCenter, enterprise);
    }),
    totalVideo: Ember.computed('video.{contactCenter,enterprise}', 'video.contactCenter.active', 'video.enterprise.active', function () {
      var contactCenter = this.get('video.contactCenter');
      var enterprise = this.get('video.enterprise');
      return activityCounter(contactCenter, enterprise);
    }),
    isNotResponding: Ember.computed('routingStatus', 'person.isOnQueue', function () {
      var routingStatus = this.get('routingStatus');
      var isOnQueue = this.get('person.isOnQueue');
      return isOnQueue && routingStatus === NOT_RESPONDING;
    }),
    activityLabel: Ember.computed('totalCalls', 'totalVideo', function () {
      if (this.get('session.features.videoActivityIndicator') && this.get('totalVideo')) {
        return this.get('intl').t('purecloud-components.onVideo');
      }

      if (this.get('totalCalls')) {
        return this.get('intl').t('purecloud-components.onCall');
      }

      return '';
    })
  });

  _exports.default = _default;
});