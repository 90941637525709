define('ember-custom-fields-components/models/schema', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships'], function (exports, _attr, _model, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var equal = Ember.computed.equal;
    exports.default = _model.default.extend({
        limits: Ember.inject.service(),

        name: (0, _attr.default)('string'),
        version: (0, _attr.default)('number'),
        createdBy: (0, _attr.default)('string'),
        dateCreated: (0, _attr.default)('date'),
        enabled: (0, _attr.default)('boolean'),
        deleted: (0, _attr.default)('boolean'),

        appliesTo: (0, _attr.default)(),
        selfUri: (0, _attr.default)('string'),

        // jsonSchema

        title: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        $schema: (0, _attr.default)('string', { defaultValue: 'http://json-schema.org/draft-04/schema#' }),

        // properties

        text: (0, _relationships.hasMany)('text'),
        longtext: (0, _relationships.hasMany)('longtext'),
        enum: (0, _relationships.hasMany)('enum'),
        identifier: (0, _relationships.hasMany)('identifier'),
        integer: (0, _relationships.hasMany)('integer'),
        number: (0, _relationships.hasMany)('number'),
        date: (0, _relationships.hasMany)('date'),
        datetime: (0, _relationships.hasMany)('datetime'),
        checkbox: (0, _relationships.hasMany)('checkbox'),
        tag: (0, _relationships.hasMany)('tag'),
        url: (0, _relationships.hasMany)('url'),

        isNameEmpty: equal('name.length', 0),
        isNameTooLong: Ember.computed('name.length', function () {
            return this.get('name.length') > this.get('limits').getSchemaNameLimit();
        }),
        isDescTooLong: Ember.computed('description.length', function () {
            return this.get('description.length') > this.get('limits').getSchemaDescLimit();
        }),

        properties: Ember.computed('text.[]', 'longtext.[]', 'enum.[]', 'identifier.[]', 'integer.[]', 'number.[]', 'date.[]', 'datetime.[]', 'tag.[]', 'checkbox.[]', 'url.[]', {
            get: function get() {
                var properties = Array.from(new Set([].concat(_toConsumableArray(this.get('text').toArray()), _toConsumableArray(this.get('longtext').toArray()), _toConsumableArray(this.get('enum').toArray()), _toConsumableArray(this.get('identifier').toArray()), _toConsumableArray(this.get('integer').toArray()), _toConsumableArray(this.get('number').toArray()), _toConsumableArray(this.get('date').toArray()), _toConsumableArray(this.get('datetime').toArray()), _toConsumableArray(this.get('tag').toArray()), _toConsumableArray(this.get('checkbox').toArray()), _toConsumableArray(this.get('url').toArray()))));

                return Ember.A(properties).sortBy('isNewField', 'title');
            },
            set: function set(key, value) {
                return value;
            }
        })
    });
});