define("ember-nav-components/components/breadcrumb-bar/component", ["exports", "ember-nav-components/components/breadcrumb-bar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['breadcrumb-bar'],
    breadcrumbs: null,
    home: null,
    showBreadcrumbs: Ember.computed.bool('breadcrumbs'),
    alternateRoute: Ember.computed('breadcrumbs.firstObject.alternateRoute', function () {
      if (this.get('breadcrumbs.length')) {
        return this.get('breadcrumbs.firstObject.alternateRoute') || null;
      }

      return null;
    })
  });

  _exports.default = _default;
});