define("ember-purecloud-components/helpers/dasherize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dasherize = dasherize;
  _exports.default = void 0;

  function dasherize(params
  /*, hash */
  ) {
    var entity = params && params.length > 0 ? params[0] : '';
    entity = entity || '';
    entity = entity.toString();
    return Ember.String.dasherize(entity);
  }

  var _default = Ember.Helper.helper(dasherize);

  _exports.default = _default;
});