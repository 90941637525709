define("ember-webrtc-components/models/video-participant-v2", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ZERO_BITRATE_THRESHOLD = 2;
  var computed = Ember.computed,
      RSVP = Ember.RSVP;

  var _default = Ember.Object.extend({
    webrtc: Ember.inject.service(),
    intl: Ember.computed.reads('webrtc.intl'),
    store: Ember.inject.service(),
    // passed in on creation
    currentUserId: null,
    pcParticipant: null,
    pcConversation: null,
    conversation: null,
    // local state
    major: false,
    domId: null,
    hasConnected: false,
    zeroBitrateCount: 0,
    positiveBitrateCount: 0,
    selected: computed('conversation.pinnedParticipantId', function () {
      var pinnedId = this.get('conversation.pinnedParticipantId');
      return pinnedId === this.get('pcParticipant.id');
    }),
    // computed based on conversation state
    local: computed('pcParticipant.userId', 'currentUserId', function () {
      return this.get('pcParticipant.userId') === this.get('currentUserId');
    }),
    person: computed('pcParticipant.userId', function () {
      var userId = this.get('pcParticipant.userId');

      if (!userId) {
        return RSVP.resolve(null);
      }

      var p = this.get('store').peekRecord('person', userId);
      return p || this.get('store').findRecord('person', userId);
    }),
    jid: Ember.computed.reads('person.jid'),
    name: Ember.computed('person.name', 'pcParticipant.name', function () {
      return this.get('person.name') || this.get('pcParticipant.name') || this.get('intl').t('ewc.unknownParticipant');
    }),
    audioMuted: computed('pcParticipant.videos.@each.audioMuted', function () {
      return this.get('pcParticipant') && !!this.get('pcParticipant.videos').findBy('audioMuted', true);
    }),
    videoMuted: computed('pcParticipant.videos.@each.videoMuted', function () {
      return this.get('pcParticipant') && !!this.get('pcParticipant.videos').findBy('videoMuted', true);
    }),
    sharingScreen: computed('pcParticipant.videos.@each.sharingScreen', function () {
      return this.get('pcParticipant') && !!this.get('pcParticipant.videos').findBy('sharingScreen', true);
    }),
    connectionState: computed('pcParticipant.videos.@each.state', function () {
      return this.get('pcParticipant.videos.firstObject.state') || 'disconnected';
    }),
    getIncomingTracks: function getIncomingTracks(kind) {
      var tracks = this.get('conversation.sessions.firstObject.tracks');

      if (tracks && tracks.length) {
        return tracks.filter(function (track) {
          return track.kind === kind;
        });
      }

      return [];
    },
    participantTrackInfo: computed('conversation.trackOwners', 'pcParticipant.userId', function () {
      var _this = this;

      var trackOwners = this.get('conversation.trackOwners');

      if (!trackOwners) {
        return null;
      }

      return trackOwners.find(function (info) {
        return info.userId === _this.get('pcParticipant.userId');
      });
    }),
    // determines whether this participant is active on the incoming track (showing video for video track or speaking for audio track)
    isParticipantOnIncomingTrack: function isParticipantOnIncomingTrack(mediaTypes, incomingTrackId) {
      var participantInfo = this.get('participantTrackInfo');
      var participantIsOnTrack = participantInfo && participantInfo.tracks.some(function (trackInfo) {
        return mediaTypes.includes(trackInfo.mediaType) && trackInfo.sinks && trackInfo.sinks.includes(incomingTrackId);
      });
      return participantIsOnTrack;
    },
    isOnIncomingVideoTrack: computed('conversation.trackOwners', function () {
      var incomingVideoTracks = this.getIncomingTracks('video');
      var videoTrack = incomingVideoTracks.length && incomingVideoTracks[0];

      if (!videoTrack) {
        return null;
      }

      return this.isParticipantOnIncomingTrack(['video'], videoTrack.id);
    }),
    speaking: computed('conversation.trackOwners', 'audioMuted', function () {
      var incomingAudioTracks = this.getIncomingTracks('audio');
      var audioTrack = incomingAudioTracks.length && incomingAudioTracks[0];

      if (!audioTrack) {
        return null;
      }

      return !this.get('audioMuted') && this.isParticipantOnIncomingTrack(['audio'], audioTrack.id);
    }),
    screenStream: null,
    // todo: computed
    screenStreamStart: Ember.computed('screenStream', function () {
      return this.get('screenStream') ? (0, _moment.default)() : null;
    }),
    showAvatar: Ember.computed('videoMuted', 'sharingScreen', 'connectionState', 'isOffline', 'screenStream', 'zeroBitrateCount', 'positiveBitrateCount', 'local', 'pcParticipant.purpose', function () {
      if (this.get('pcParticipant.purpose') !== 'user' || this.get('local')) {
        return this.get('videoMuted');
      }

      var videoMuted = this.get('videoMuted');
      var sharingScreen = this.get('sharingScreen') || this.get('screenStream');
      var state = this.get('connectionState');
      var isOffline = this.get('isOffline');
      var zeroBitrateCount = this.get('zeroBitrateCount');

      if (['failed', 'checking', 'starting'].includes(state) || isOffline || zeroBitrateCount >= ZERO_BITRATE_THRESHOLD) {
        return true;
      }

      return !sharingScreen && videoMuted;
    }),
    avatarDisplay: Ember.computed('showAvatar', function () {
      return this.get('showAvatar') ? 'avatar-visible' : 'avatar-hidden';
    }),
    isOffline: Ember.computed('connectionState', 'person.presence', 'zeroBitrateCount', 'pcParticipant.purpose', function () {
      if (this.get('local') || this.get('pcParticipant.purpose') !== 'user') {
        return false;
      }

      var connectionState = this.get('connectionState');
      var presence = this.get('person.presence');
      var hasOfflinePresence = this.get('person') && (!presence || presence === 'offline');
      var zeroBitrateCount = this.get('zeroBitrateCount');
      return connectionState === 'disconnected' || connectionState === 'interrupted' || hasOfflinePresence || zeroBitrateCount >= ZERO_BITRATE_THRESHOLD;
    }),
    connectionStatus: Ember.computed('connectionState', 'isOffline', 'zeroBitrateCount', function () {
      var intl = this.get('intl');
      var state = this.get('connectionState');

      if (state === 'connected' || state === 'completed') {
        this.set('hasConnected', true);
      }

      if ((state === 'checking' || this.get('isOffline') || this.get('zeroBitrateCount') >= ZERO_BITRATE_THRESHOLD) && this.get('hasConnected')) {
        return intl.t('ewc.callStatus.recovering').toString();
      } else if (state) {
        return intl.t("ewc.callStatus.".concat(state)).toString();
      }
    }),
    canBeMuted: Ember.computed('audioMuted', 'local', 'pcParticipant.purpose', function () {
      return !!(!this.get('audioMuted') && !this.get('local') && this.get('pcParticipant'));
    })
  });

  _exports.default = _default;
});