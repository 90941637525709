define("ember-nav-components/components/accessible-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/new-module-imports
  var AccessibleInput = Ember.TextField.extend({
    attributeBindings: ['aria-label'],
    init: function init() {
      this._super.apply(this, arguments);

      var ariaLabel = this.get('aria-label');
      (false && !(ariaLabel) && Ember.assert('Aria label must be set on accessible input', ariaLabel));
    }
  });
  var _default = AccessibleInput;
  _exports.default = _default;
});