enifed('ember-template-compiler/plugins/deprecate-render', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = DeprecateRender;
  function DeprecateRender(options) {
    this.syntax = null;
    this.options = options;
  }

  DeprecateRender.prototype.transform = function (ast) {
    var moduleName = this.options.meta.moduleName;
    var walker = new this.syntax.Walker();

    walker.visit(ast, function (node) {
      if (!validate(node)) {
        return;
      }

      each(node.params, function (param) {
        if (param.type !== 'StringLiteral') {
          return;
        }

        true && !false && (0, _emberDebug.deprecate)(deprecationMessage(moduleName, node), false, {
          id: 'ember-template-compiler.deprecate-render',
          until: '3.0.0',
          url: 'http://emberjs.com/deprecations/v2.x#toc_code-render-code-helper'
        });
      });
    });

    return ast;
  };

  function validate(node) {
    return node.type === 'MustacheStatement' && node.path.original === 'render' && node.params.length === 1;
  }

  function each(list, callback) {
    var i, l;

    for (i = 0, l = list.length; i < l; i++) {
      callback(list[i]);
    }
  }

  function deprecationMessage(moduleName, node) {
    var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
    var componentName = node.params[0].original;


    return 'Please refactor `' + ('{{render "' + componentName + '"}}') + '` to a component and invoke via' + (' `' + ('{{' + componentName + '}}') + '`. ' + sourceInformation);
  }
});