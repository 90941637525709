define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/message/component', ['exports', 'ember', 'ember-chat-components/mixins/viewport', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/message/template'], function (exports, _ember, _viewport, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed } = _ember.default;

    const BADGE_TOGGLE_ID = 'gcucc.ui.chatBadging';

    const MessageComponent = Component.extend(_viewport.default, {
        classNameBindings: [':chat-message', 'message.isYou:you', 'message.unread:unread', 'isImportant:important', 'message.startOfBlock:start-of-block', 'message.endOfBlock:end-of-block', 'message.selected:selected', 'hideMessage:hide', 'message.firstUnread:first-unread', 'message.meAction:start-of-block'],
        layout: _template.default,

        session: _ember.default.inject.service(),
        phoneIntegrations: _ember.default.inject.service(),

        acdToggle: false,

        message: null,
        isMediaCollapsed: null,

        isEditing: false,

        index: null,
        flairColor: 'background-color: #aaaaaa',

        canViewVideo: true,

        displayIntegrationBadge: _ember.default.computed('session.features', 'userHasIntegration', function () {
            return this.get('session.features')[BADGE_TOGGLE_ID] && this.get('userHasIntegration');
        }),

        hideMessage: _ember.default.computed('message.{corrects,id}', function () {
            return this.get('message.corrects') && this.get('message.corrects') !== this.get('message.id');
        }),

        hideQuoteIndicator: computed('isEditing', 'emptyCorrection', 'acdToggle', function () {
            return this.get('isEditing') || this.get('emptyCorrection') || this.get('acdToggle');
        }),

        messageRenderer: _ember.default.computed('message.widgetType', function () {
            return 'chat-panel/conversation/conversation-pane/messages/widgets/' + this.get('message.widgetType');
        }),

        isImportant: _ember.default.computed('message.messageDisplay.raw', function () {
            const raw = this.get('message.messageDisplay.raw');
            if (!raw) {
                return false;
            }

            const text = raw.trimLeft();
            const hasMega = text.startsWith(':mega:') || text.startsWith(':loudspeaker:') || text.startsWith('📣') || text.startsWith('📢');
            return hasMega;
        }),

        emptyCorrection: _ember.default.computed('message.correction.messageBody', function () {
            const correction = this.get('message.correction');
            return correction && !correction.get('messageBody');
        }),

        hasFlair: computed('message.chatUser.chatStatus', function () {
            let status = this.get('message.chatUser.chatStatus');
            return status && status.match(/(^flair:)/g);
        }),

        userHasIntegration: _ember.default.computed('message.chatUser', 'phoneIntegrations.integrationConfigs', function () {
            const chatUser = this.get('message.chatUser');
            let userHasInt = false;

            const phoneIntegrations = this.get('phoneIntegrations');
            const integrationConfigs = phoneIntegrations.get('integrationConfigs');
            userHasInt = Boolean(integrationConfigs.find(config => {
                return chatUser.integrationContactInfo(config.key);
            }));
            return userHasInt;
        }),

        actions: {
            quoteMessage() {
                this.sendAction('replyToMessage', this.get('message'));
            }
        },

        didEnterViewport() {
            if (this.get('isDestroyed') || this.get('isDestroying')) {
                return;
            }

            this._teardown();
            const component = this.$()[0];
            this.get('messageVisible')(component);
        }
    });

    exports.default = MessageComponent;
});