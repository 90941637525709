define("ember-purecloud-components/components/entity-v2/favorite/component", ["exports", "ember-purecloud-components/components/entity-v2/favorite/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'button',
    classNames: ['person-favorite', 'entity-v2-favorite'],
    favorites: Ember.inject.service(),
    isFavorite: Ember.computed('favorites._favorites.[]', 'entity.id', function () {
      return this.get('favorites').isEntityFavorited(this.get('entity'));
    }),
    toggleFavorite: function toggleFavorite() {},
    click: function click() {
      this.toggleFavorite(!this.get('isFavorite'));
    }
  });

  _exports.default = _default;
});