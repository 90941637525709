define("ember-purecloud-components/components/alert-message/component", ["exports", "ember-purecloud-components/components/alert-message/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var types = {
    success: 'alert-success',
    info: 'alert-info',
    error: 'alert-danger'
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':alert', ':alert-message', 'typeClass', 'show::hide-message'],
    type: 'info',
    show: null,
    icon: Ember.computed('type', function () {
      var type = this.get('type');

      if (type === 'success') {
        return 'pc pc-lg pc-fw pc-check-circle';
      } else if (type === 'error') {
        return 'pc pc-lg pc-fw pc-times-circle';
      } else {
        return 'pc pc-lg pc-fw pc-info-circle';
      }
    }),
    typeClass: Ember.computed('type', function () {
      if (typeof types[this.get('type')] === 'undefined') {
        return types['info'];
      } else {
        return types[this.get('type')];
      }
    })
  });

  _exports.default = _default;
});