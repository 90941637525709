define('ember-custom-fields-components/utils/field-validation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function _isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    function _isInteger(value) {
        if (!Number.isInteger) {
            return typeof value === 'number' && isFinite(value) && value > -9007199254740992 && value < 9007199254740992 && Math.floor(value) === value;
        } else {
            return Number.isInteger(value);
        }
    }

    var fieldValidations = {

        validateNewMaxValueIsLessRestrictive: function validateNewMaxValueIsLessRestrictive(value, savedValue) {
            var isValid = true,
                numValue = Number(value);

            if (!Ember.isEmpty(savedValue)) {
                isValid = numValue >= Number(savedValue);
            }

            return isValid;
        },

        validateNewMinValueIsLessRestrictive: function validateNewMinValueIsLessRestrictive(value, savedValue) {
            var isValid = true,
                numValue = Number(value);

            if (!Ember.isEmpty(savedValue)) {
                isValid = numValue <= Number(savedValue);
            }

            return isValid;
        },

        validateInteger: function validateInteger(value) {
            var isValid = true,
                numValue = Number(value);

            if (Ember.isEmpty(value) || !_isInteger(numValue)) {
                isValid = false;
            }

            return isValid;
        },

        validateNumber: function validateNumber(value) {
            var isValid = true,
                numValue = Number(value);

            if (Ember.isEmpty(value) || !_isNumber(numValue)) {
                isValid = false;
            }

            return isValid;
        },

        validateLengthBoundaries: function validateLengthBoundaries(minValue, maxValue) {
            if (minValue < 0) {
                return false;
            }

            if (maxValue < 0) {
                return false;
            }

            if (minValue && maxValue) {
                return Number(minValue) <= Number(maxValue);
            }

            return true;
        },

        validateNumberValueBoundaries: function validateNumberValueBoundaries(minValue, maxValue) {
            if (!Ember.isEmpty(minValue) && !Ember.isEmpty(maxValue)) {
                return Number(minValue) <= Number(maxValue);
            }

            return true;
        }
    };

    exports.default = fieldValidations;
});