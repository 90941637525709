define('ember-engine-custom-fields/components/schema-grid/schema-grid-actions/disable/component', ['exports', 'ember-engine-custom-fields/components/schema-grid/schema-grid-actions/disable/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias;
    exports.default = Component.extend({
        layout: _template.default,
        schema: alias('dataRow'),
        schemaStatus: null,
        permissions: inject.service(),

        hasEditSchemaPermission: computed.reads('permissions.hasExternalContactsEditSchemaAccess'),

        init: function init() {
            this._super();
            var enabled = this.get('schema.enabled');
            this.set('schemaStatus', enabled);
        },


        actions: {
            toggleEnabled: function toggleEnabled(toggleInfo) {
                this.setProperties({
                    schemaStatus: toggleInfo,
                    'schema.enabled': toggleInfo === true
                });
                this.get('schema').save(this.get('schema'));
            }
        }
    });
});