define('fixtable-ember/components/fixtable-column-label', ['exports', 'fixtable-ember/templates/components/fixtable-column-label'], function (exports, _fixtableColumnLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fixtableColumnLabel.default,
    tagName: 'span'
  });
});