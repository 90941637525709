define("ember-purecloud-components/mixins/logger", ["exports", "ember-purecloud-components/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
    * The fact that this mixin relies on getOwner means that it should only
    * be used in classes created by the Ember container.
    * We've broken this rule in a few places, and have compensated by setting the owner
    * object as 'owner' on the object where this is mixed in.
    */
    init: function init() {
      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this) || this.get('owner');

      if (owner) {
        this.set('stashLogger', owner.lookup('service:stash-logger'));
        this.bindLogMethods();
      } else {
        Ember.Logger.warn('No container available, falling back to ember logs');

        this._bindEmberLogFunctions();
      }
    },
    _bindEmberLogFunctions: function _bindEmberLogFunctions() {
      var _this = this;

      this.logger = {};

      _logger.LOG_LEVELS.forEach(function (level) {
        if (Ember.Logger[level]) {
          _this.logger[level] = Ember.Logger[level];
        }
      });
    },
    bindLogMethods: function bindLogMethods() {
      var _this2 = this;

      this.logger = {};
      var stashLogger = this.get('stashLogger');

      _logger.LOG_LEVELS.forEach(function (level) {
        _this2.logger[level] = function (level) {
          var _stashLogger;

          stashLogger = stashLogger || _this2.get('stashLogger'); // just in case someone calls bindLogMethods without using stash logger (service)

          for (var _len = arguments.length, itemsToLog = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            itemsToLog[_key - 1] = arguments[_key];
          }

          if (!stashLogger || !stashLogger.bufferLog) {
            var _Ember$Logger;

            (_Ember$Logger = Ember.Logger)[level].apply(_Ember$Logger, [_this2._debugContainerKey].concat(itemsToLog));

            return;
          }

          (_stashLogger = stashLogger).bufferLog.apply(_stashLogger, [level, _this2._debugContainerKey].concat(itemsToLog));
        }.bind(_this2, level);
      });
    }
  });

  _exports.default = _default;
});