define('ember-engine-custom-fields/controllers/data-models/contacts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Controller = Ember.Controller,
        computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Controller.extend({
        permissions: inject.service(),
        schemaCount: inject.service(),
        rowCount: 0,

        hasAddSchemaPermission: computed.reads('permissions.hasExternalContactsAddSchemaAccess'),
        hasSchemaServiceError: false,

        init: function init() {
            this._super();
            this.set('hasSchemaServiceError', false);
        },


        actions: {
            editSchema: function editSchema(id) {
                this.transitionToRoute('data-models.contact', id);
            },
            updateRowCount: function updateRowCount(count) {
                this.set('rowCount', count);
            },
            updateServiceError: function updateServiceError(status) {
                this.set('hasSchemaServiceError', status);
            }
        }
    });
});