define("ember-purecloud-components/components/entity-hover-card/component", ["exports", "popper.js", "ember-purecloud-components/components/entity-hover-card/template"], function (_exports, _popper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var placeholderImages = {
    isInitialized: false,
    person: '',
    group: '',
    location: '',
    floor: ''
  };

  function placeholderImage(entity) {
    if (!placeholderImages.isInitialized) {
      var baseUri = this.get('applicationUrlPrefix') + 'assets/images/svg/';
      placeholderImages.person = baseUri + 'person.svg';
      placeholderImages.group = baseUri + 'group.svg';
      placeholderImages.location = baseUri + 'location.svg';
      placeholderImages.floor = baseUri + 'floor.svg';
      placeholderImages.queue = baseUri + 'queue.svg';
      placeholderImages.isInitialized = true;
    }

    var type;

    if (entity && entity.get && typeof entity.get === 'function') {
      type = entity.get('type');

      if (!type) {
        type = 'person';
      }
    } else {
      type = 'person';
    }

    return placeholderImages[type];
  }

  var isNameLatin = /^[\w\s'"-,.]+$/i;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-mini-card-container'],
    classNameBindings: ['chatPresenceClass', 'microCard', 'showPresenceAndChatEnabled:chat-presence', 'isPlaceholderImageAndUseInitials:show-initials'],
    feature: Ember.inject.service(),
    permissions: Ember.inject.service(),
    presenceSubscription: Ember.inject.service(),
    entity: null,
    user: null,
    size: 200,
    self: null,
    hoverParent: null,
    type: 'profile',
    applicationUrlPrefix: '',
    showFavorite: true,
    isPlaceholderImage: true,
    useInitialsPlaceholder: false,
    enableMiniCard: false,
    microCard: false,
    showMiniCard: false,
    showQueueToggle: false,
    showPresenceDropdown: false,
    showLogOffButton: false,
    isChatEnabled: false,
    phoneServiceEnabled: false,
    phoneAvailable: false,
    isOneToOneOfflineOrBusy: true,
    isGeolocationEnabled: false,
    videoTooltipTitle: '',
    imageLoadError: false,
    isHoveringCard: false,
    isHoveringImage: false,
    hoverTimer: null,
    isFirstHover: true,
    canViewChat: true,
    canViewVideo: true,
    // Extensions for third-party UCC affordances
    useMediaProviders: false,
    haveMediaProviders: false,
    mediaProviderComponent: null,
    useIntegrationBadges: false,
    integrationBadgesComponent: null,
    tetherConstraints: [{
      to: 'scrollParent',
      attachment: 'together',
      pin: ['left', 'right']
    }],
    hovercardOffset: '0px 0px',
    toggleFavorite: function toggleFavorite() {},
    callEntity: function callEntity() {},
    actions: {
      triggerShowMiniCard: function triggerShowMiniCard() {
        if (this.get('betterHoverClicks')) {
          if (this.get('showMiniCard')) {
            this.set('showMiniCard', false);

            this._destroyPopper();
          } else {
            Ember.run.once(this, this._displayHoverCard);
          }
        } else {
          Ember.run.once(this, this._displayHoverCard);
        }

        return false;
      },
      mouseEnterCard: function mouseEnterCard() {
        this.set('isHoveringCard', true);
        this.onHoverChanged();
      },
      mouseLeaveCard: function mouseLeaveCard() {
        this.set('isHoveringCard', false);
        this.onHoverChanged();
      },
      onContact: function onContact() {
        var _this = this;

        Ember.run.next(function () {
          return _this.set('showMiniCard', false);
        });
      }
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.get('entity.type') === 'person') {
        this.get('feature').getFeatureToggles('logoffAgents', 'betterHoverClicks').then(function (features) {
          if (_this2.isDestroyed) {
            return;
          }

          _this2.set('logoffAgents', features.logoffAgents);

          _this2.set('betterHoverClicks', features.betterHoverClicks);
        }).catch(function () {
          if (_this2.isDestroyed) {
            return;
          }

          _this2.set('logoffAgents', false);

          _this2.set('betterHoverClicks', false);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._attachListeners();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeListeners();
    },
    isHovering: Ember.computed.or('isHoveringCard', 'isHoveringImage'),
    isOnline: Ember.computed.not('entity.isOffline'),
    chatPresence: Ember.computed.reads('entity.activityPresence'),
    chatPresenceClass: Ember.computed.reads('entity.activityPresenceClass'),
    tetherSelector: Ember.computed('elementId', function () {
      return "#".concat(this.get('elementId'));
    }),
    tetherElementId: Ember.computed('elementId', function () {
      return "entity-mini-card-".concat(this.get('elementId'));
    }),
    uniqueId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-entity-mini-card");
    }),
    entityType: Ember.computed('entity.type', function () {
      return "entity-".concat(this.get('entity.type'));
    }),
    linkToProfileRoute: Ember.computed('entity.type', function () {
      if (this.get('entity.type') === 'person') {
        return 'person.detail';
      } else if (this.get('entity.type') === 'group') {
        return 'group.detail';
      }

      return 'person.detail';
    }),
    cardImageUrl: Ember.computed('entity', 'entity.isLoaded', 'entity.profilePicture', 'entity.profilePictureSizes', function () {
      if (this.get('type') === 'profile') {
        return this.getProfilePicture(75);
      } else {
        return '';
      }
    }),
    name: Ember.computed('entity.name', function () {
      return this.get('entity.name');
    }),
    nameInitials: Ember.computed('initialsPlaceholderSupported', 'entity.name', function () {
      var name = Ember.$.trim(this.get('entity.name'));

      if (this.get('initialsPlaceholderSupported') && name) {
        var commaIndex = name.indexOf(',') > -1;
        var spaceIndex = name.indexOf(' ') > -1;
        var leftInitial = name[0].toUpperCase();
        var rightInitial = name.charAt(name.indexOf(' ') + 1).toUpperCase();

        if (commaIndex) {
          return rightInitial + leftInitial;
        } else if (!spaceIndex) {
          return leftInitial;
        } else {
          return leftInitial + rightInitial;
        }
      }

      return null;
    }),
    initialsPlaceholderSupported: Ember.computed('entity.name', 'entity.type', function () {
      var name = this.get('entity.name');

      if (name) {
        return isNameLatin.test(name) || this.get('entity.type') === 'externalContact';
      }

      return false;
    }),
    isPlaceholderImageAndUseInitials: Ember.computed('useInitialsPlaceholder', 'isPlaceholderImage', 'nameInitials', function () {
      return this.get('useInitialsPlaceholder') && this.get('isPlaceholderImage') && !!this.get('nameInitials');
    }),
    showPresenceAndChatEnabled: Ember.computed('isChatEnabled', 'showPresence', 'entity.type', function () {
      return this.get('isChatEnabled') && this.get('showPresence') && this.get('entity.type') === 'person';
    }),
    entityIsGroup: Ember.computed('entity.type', function () {
      return this.get('entity.type') === 'group';
    }),
    entityDeleted: Ember.computed('entity.state', function () {
      var state = this.get('entity.state');
      return state && state.toLowerCase() === 'deleted';
    }),
    isSelfEntity: Ember.computed('entity.id', 'self.id', function () {
      return this.get('entity.id') === this.get('self.id');
    }),
    placeholderImage: placeholderImage,
    getProfilePicture: function getProfilePicture(size) {
      var profilePicture = this.get('entity.profilePicture');
      var deviceSize = this.sizeForDevice(size);

      if (!Ember.isEmpty(profilePicture)) {
        Ember.run.scheduleOnce('afterRender', this, this.set, 'isPlaceholderImage', false);
        var exactMatch = profilePicture["x".concat(deviceSize)];

        if (exactMatch) {
          return exactMatch;
        }
      }

      Ember.run.scheduleOnce('afterRender', this, this.set, 'isPlaceholderImage', true);
      return this.placeholderImage(this.get('entity'));
    },
    sizeForDevice: function sizeForDevice(size) {
      size = size || +this.get('size');
      var profilePicture = this.get('entity.profilePicture');
      var sizes = this.get('entity.profilePictureSizes');

      if (profilePicture && sizes) {
        // Note: window.devicePixelRatio changes based on which monitor the browser is on in a multi-monitor setup.
        var ratio = window.devicePixelRatio > 0 ? window.devicePixelRatio : 1;
        var deviceSize = size * ratio;
        (sizes || []).forEach(function (s) {
          if (s >= deviceSize) {
            size = s;
            return false;
          }
        });
      }

      return size;
    },
    mouseEnter: function mouseEnter() {
      this.set('isHoveringImage', true);
      this.onHoverChanged();
    },
    mouseLeave: function mouseLeave() {
      this.set('isHoveringImage', false);
      this.onHoverChanged();
    },
    _createPopper: function _createPopper() {
      var el = this.element;

      if (!el) {
        return;
      }

      var targetEl = el.querySelector('.mini-card-component-wrapper');
      var cardEl = el.querySelector('.entity-mini-card');
      this._popper = new _popper.default(targetEl, cardEl, {
        placement: 'bottom',
        modifiers: {
          flip: {
            behavior: ['bottom', 'top', 'left']
          },
          offset: {
            offset: '40%p,10px'
          },
          arrow: {
            element: '.arrow'
          },
          preventOverflow: {
            padding: 1
          }
        }
      });
    },
    _destroyPopper: function _destroyPopper() {
      if (!this._popper) {
        return;
      }

      this._popper.destroy();

      this._popper = null;
    },
    _displayHoverCard: function _displayHoverCard() {
      if (!this.get('showMiniCard') && this.get('_state') === 'inDOM' && this.get('enableMiniCard')) {
        this.set('showMiniCard', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this3 = this;

          this._createPopper();

          var image = new Image();
          image.src = this.get('cardImageUrl');

          image.onload = function () {
            _this3.set('imageLoadError', false);

            if (_this3._popper) {
              _this3._popper.scheduleUpdate();
            }
          };

          image.onerror = function () {
            _this3.set('imageLoadError', true);

            if (_this3._popper) {
              _this3._popper.scheduleUpdate();
            }
          };
        });
      }
    },
    onHoverChanged: function onHoverChanged() {
      var _this4 = this;

      var hoverTimeout = 800;
      var isHovering = this.get('isHovering');
      var timer = this.get('hoverTimer');
      Ember.run.cancel(timer);

      if (isHovering) {
        if (!this.get('enableMiniCard')) {
          return;
        }

        timer = Ember.run.later(function () {
          _this4._displayHoverCard();
        }, hoverTimeout);
      } else {
        timer = Ember.run.later(function () {
          if (!_this4.get('isHovering') && _this4.get('_state') === 'inDOM') {
            Ember.run.scheduleOnce('afterRender', _this4, function () {
              _this4.set('showMiniCard', false);

              _this4._destroyPopper();
            });
          }
        }, hoverTimeout);
      }

      this.set('hoverTimer', timer);
    },
    _attachListeners: function _attachListeners() {
      var id = this.get('uniqueId');
      Ember.$(window).on('click.' + id, Ember.run.bind(this, function (event) {
        if (!event) {
          return;
        }

        var target = Ember.$(event.target);
        var clickedHoverCard = target.parents('#' + id).length || target.parents('.entity-mini-card').length;
        var clickedHoverParent = this.get('hoverParent') ? target.parents('#' + this.get('hoverParent')).length : false;
        var checkHoverState = this.get('betterHoverClicks') ? !clickedHoverCard && !clickedHoverParent : !clickedHoverCard;

        if (checkHoverState) {
          if (this.get('showMiniCard')) {
            this.set('showMiniCard', false);

            this._destroyPopper();

            event.preventDefault();
          }
        }
      }));
    },
    _removeListeners: function _removeListeners() {
      var id = this.get('elementId');
      var uniqueId = this.get('uniqueId');
      this.$().off(".".concat(id));
      Ember.$(window).off(".".concat(uniqueId));
    }
  });

  _exports.default = _default;
});