define('ember-engine-custom-fields/routes/data-models/contacts', ['exports', 'ember-engine-custom-fields/services/breadcrumb-nav'], function (exports, _breadcrumbNav) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject,
        Route = Ember.Route;
    exports.default = Route.extend({
        adminui: inject.service(),
        limits: inject.service(),
        breadcrumbNav: inject.service(),
        schemaCount: inject.service(),
        subNav: inject.service(),

        model: function model() {
            this.get('limits').update();
        },
        afterModel: function afterModel() {
            this._super.apply(this, arguments);
            this.set('adminui.adminPath', 'custom-fields/contacts');
            this.set('breadcrumbNav.state', _breadcrumbNav.STATES.contactSchemaList);
            this.set('subNav.showNav', true);
            return true;
        },
        deactivate: function deactivate() {
            this._super();
            this.get('schemaCount').notifyPropertyChange('updateSchemas');
        }
    });
});