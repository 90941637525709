define('ember-cli-testing/utils/test-utils', ['exports', 'ember', 'sinon', 'ember-cli-testing/utils/ModuleLoader', 'ember-cli-testing/services/mock-service', 'ember-cli-testing/services/mocks', 'ember-cli-testing/utils/utils'], function (exports, _ember, _sinon, _emberCliTestingUtilsModuleLoader, _emberCliTestingServicesMockService, _emberCliTestingServicesMocks, _emberCliTestingUtilsUtils) {
    exports.registerMockComponents = registerMockComponents;
    exports.injectMockServices = injectMockServices;
    exports.setupRouter = setupRouter;
    exports.beforeInit = beforeInit;
    exports.waitForChange = waitForChange;
    exports.listenForAction = listenForAction;
    exports.promisedSpy = promisedSpy;
    exports.setupTest = setupTest;

    /**
    * Registers the mock implementations of the specified components so that they overwrite those
    * components' real implementations. In order to obtain a reference to the mock in a test,
    * you must include an action that the mock can call on its parent component in order to
    * 'register' itself (i.e. set itself as a property on the component). It would be desirable
    * to return an object with references to the mocks like `injectMockServices` does, however
    * this could not be achieved because components cannot be singletons like services.
    *
    * @param {Object}          options
    * @param {Ember.Container} options.container
    * @param {Array.<string>}  options.componentNames
    * @param {string}          [options.modulePrefix] - Allows the user to optionally specify a modulePrefix
    *                                                   instead of having the method look it up via the config.
    *                                                   Required in cases where the config can't be looked up,
    *                                                   like in unit tests (as opposed to integration tests).
    */

    function registerMockComponents(_ref) {
        var container = _ref.container;
        var componentNames = _ref.componentNames;
        var modulePrefix = _ref.modulePrefix;

        if (!modulePrefix) {
            var config = (0, _emberCliTestingUtilsUtils.lookupFactory)(container, 'config:environment');
            if (!config) {
                var message = 'No environment config found via config:environment. That indicates that you\'re probably running in a context such as a unit test where the config is unavailable. Specify the modulePrefix via the optional \'modulePrefix\' parameter instead.';
                throw new Error(message);
            }
            modulePrefix = config.modulePrefix;
        }

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = componentNames[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var componentName = _step.value;

                var component = require(modulePrefix + '/tests/mocks/components/' + componentName)['default'];
                (0, _emberCliTestingUtilsUtils.register)(container, 'component:' + componentName, component);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator['return']) {
                    _iterator['return']();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }

    /**
    * Injects mock versions of the services into the targets listed in their initializers and
    * returns an object on which all of the mock services are accessible as properties.
    *
    * @param   {Object}          options
    * @param   {Ember.Container} options.container      - The container in which the services will be injected.
    * @param   {Array.<string>}  [options.realServices] - The names of services for which the real version of the service should
    *                                                     be injected instead of the mock version.
    * @param   {string}          [options.modulePrefix] - Allows the user to optionally specify a modulePrefix
    *                                                     instead of having the method look it up via the config.
    *                                                     Required in cases where the config can't be looked up,
    *                                                     like in unit tests (as opposed to integration tests).
    * @returns {Object}          mocks                  - Object on which all of the injected dependencies are accessible.
    */

    function injectMockServices(_ref2) {
        var container = _ref2.container;
        var _ref2$realServices = _ref2.realServices;
        var realServices = _ref2$realServices === undefined ? [] : _ref2$realServices;
        var modulePrefix = _ref2.modulePrefix;

        if (!modulePrefix) {
            var config = (0, _emberCliTestingUtilsUtils.lookupFactory)(container, 'config:environment');
            if (!config) {
                var message = 'No environment config found via config:environment. That indicates that you\'re probably running in a context such as a unit test where the config is unavailable. Specify the modulePrefix via the optional \'modulePrefix\' parameter instead.';
                throw new Error(message);
            }
            modulePrefix = config.modulePrefix;
        }

        var mocks = container.lookup('service:mocks'),
            moduleLoader = new _emberCliTestingUtilsModuleLoader['default']({ modulePrefix: modulePrefix }),
            serviceNames = moduleLoader.getServiceNames();

        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
            for (var _iterator2 = serviceNames[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var serviceName = _step2.value;

                var initialize = undefined,
                    mockClass = undefined;

                try {
                    initialize = require(modulePrefix + '/initializers/' + serviceName)['default'].initialize;
                } catch (error) {
                    // There's no initializer for this service, which indicates it's not injected
                    // into other components. Let's skip it.
                    continue;
                }

                if (realServices.includes(serviceName)) {
                    // Inject the real version of the service instead of the mock version.
                    initialize(container);
                } else {
                    // Get and inject a mock version of the service.
                    try {
                        // First check whether there's a custom mock implementation in the mocks directory.
                        mockClass = require(modulePrefix + '/tests/mocks/services/' + serviceName)['default'];
                    } catch (error) {
                        // A mock doesn't exist for the service in the mocks directory, so we'll default
                        // to automatically generating one from the service.
                        var service = require(modulePrefix + '/services/' + serviceName)['default'];
                        mockClass = (0, _emberCliTestingServicesMockService.createMockFromService)(service);
                    }

                    initialize(container, mockClass);

                    if (!initialize.injectedName) {
                        continue;
                    }

                    var _mocks = container.lookup('service:mocks'),
                        mockInstance = container.lookup('service:' + serviceName);
                    _mocks.registerService(mockInstance, initialize.injectedName);
                }
            }

            // Mock the ember-data `store` service.
        } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                    _iterator2['return']();
                }
            } finally {
                if (_didIteratorError2) {
                    throw _iteratorError2;
                }
            }
        }

        var storeMockClass = (0, _emberCliTestingServicesMockService.createMockFromService)((0, _emberCliTestingUtilsUtils.resolve)(container, 'service:store'));
        (0, _emberCliTestingUtilsUtils.unregister)(container, 'service:store');
        (0, _emberCliTestingUtilsUtils.register)(container, 'service:store', storeMockClass);
        var storeMockInstance = container.lookup('service:store');
        mocks.registerService(storeMockInstance, 'store');

        mocks.reset();
        return mocks;
    }

    /**
    * Initializes the router for a test container so that the link-to helper and the
    * Router.prototype.transitionTo and Component.prototype.transitionToRoute methods
    * work propertly.
    *
    * @param {object}          options
    * @param {Ember.Container} options.container
    * @param {Ember.Router}    The router that was set up.
    */

    function setupRouter(_ref3) {
        var container = _ref3.container;

        var router = container.lookup('router:main');

        router.startRouting(true);
        return router;
    }

    /**
    * Updates a registered Ember Object prototype so that `beforeInitFunction` is called
    * in the context of new instances before the `init` hook is executed.
    *
    * @param {Ember.Container}               container
    * @param {string}                        fullName           - example: 'component:my-widget'
    * @param {Function}                      beforeInitFunction
    */

    function beforeInit(container, fullName, beforeInitFunction) {

        var factoryClass = (0, _emberCliTestingUtilsUtils.resolve)(container, fullName),
            extendedClass = factoryClass.extend({

            init: function init() {
                beforeInitFunction.call(this);
                this._super.apply(this, arguments);
            }

        });
        (0, _emberCliTestingUtilsUtils.unregister)(container, fullName);
        (0, _emberCliTestingUtilsUtils.register)(container, fullName, extendedClass);
    }

    /**
    * Returns a promise that resolves when an ember object's property changes.
    *
    * @param {Ember.Object} emberObject
    * @param {string}       propertyName
    * @param {*}            [filter]     - An optional filter function so that the
    *                                      the promise is only resolved if the function
    *                                      returns true.
    */

    function waitForChange(emberObject, propertyName, filter) {

        var resolve = undefined,
            promise = new Promise(function (res) {
            return resolve = res;
        });

        var observer = {
            propertyChanged: function propertyChanged(sender, key) {
                var value = sender.get(key);

                if (!filter || filter(value)) {
                    resolve();
                }
            }
        };

        emberObject.addObserver(propertyName, observer, 'propertyChanged');

        return promise.then(function () {
            return emberObject.get(propertyName);
        });
    }

    /**
    * Listens for an action to be sent by a given ember object and returns
    * a promise that resolves when the actions is called. The promise returned
    * resolves to the spy for the action handler.
    *
    * @param  {Ember.Object}        emberObject
    * @param  {string}              actionName
    * @return {Promise.<sinon.spy>}
    */

    function listenForAction(emberObject, actionName) {

        var resolve = undefined,
            promise = new Promise(function (res) {
            return resolve = res;
        }),
            targetObject = {};

        targetObject[actionName] = _sinon['default'].spy(function () {
            return resolve();
        });

        _ember['default'].run(function () {
            emberObject.set(actionName, actionName);
            emberObject.set('targetObject', targetObject);
        });

        return promise.then(function () {
            return targetObject[actionName];
        });
    }

    /**
    * Creates a sinon spy instance with a `promise` property that resolves when the spy method is called.
    *
    * @returns {sinon.spy} spy
    * @returns {Promise}   spy.promise
    */

    function promisedSpy() {
        var resolve = undefined,
            promise = new Promise(function (res) {
            return resolve = res;
        }),
            spy = _sinon['default'].spy(function () {
            return resolve(spy);
        });

        spy.promise = promise;
        return spy;
    }

    /**
    * A facade for many setup actions that normally need to be executed for a component integration test.
    * It could potentially be used for setting up unit tests for other types of Ember obects, too.
    *
    * @params  {Object}          options
    * @param   {Ember.Container} options.container        - The test module's container
    * @param   {string}          options.fullName         - example: 'component:my-widget'
    * @param   {Function}        [options.beforeInit]     - Function executed in the context of the componet
    *                                                       before its `init` function. This is useful for
    *                                                       obtaining a reference to the component and setting
    *                                                       up spies.
    * @param   {Array.<string>}  [options.mockComponents] - Names of mock components to register.
    * @param   {boolean}         [options.startRouter]    - Optionally start the router. Default: false.
    * @param   {Array.<string>}  [options.realServices]   - Names of services for which the real service implementations
    *                                                       should be injected instead of mock implementations.
    * @param   {string}          [options.modulePrefix]   - Allows the user to optionally specify a modulePrefix
    *                                                       instead of having the method look it up via the config.
    *                                                       Required in cases where the config can't be looked up,
    *                                                       like in unit tests (as opposed to integration tests).
    * @returns {Object}          testData
    * @returns {Object}          testData.mocks
    */

    function setupTest(options) {
        var container = options.container;
        var mockComponents = options.mockComponents;
        var fullName = options.fullName;
        var startRouter = options.startRouter;
        var realServices = options.realServices;
        var modulePrefix = options.modulePrefix;
        var beforeInitFunction = options.beforeInit;

        if (startRouter) {
            setupRouter(container);
        }

        (0, _emberCliTestingUtilsUtils.registerAndInject)({
            dependency: _emberCliTestingServicesMocks['default'],
            fullName: 'service:mocks',
            injectedName: 'mocks',
            targets: ['component'],
            container: container
        });

        var mocks = injectMockServices({ container: container, realServices: realServices, modulePrefix: modulePrefix });

        if (mockComponents && mockComponents.length) {
            registerMockComponents({ container: container, componentNames: mockComponents, modulePrefix: modulePrefix });
        }

        if (beforeInitFunction) {
            beforeInit(container, fullName, beforeInitFunction);
        }

        return { mocks: mocks };
    }
});