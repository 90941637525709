define('ember-cli-testing/utils/index', ['exports', 'ember-cli-testing/utils/test-utils', 'ember-cli-testing/utils/utils'], function (exports, _emberCliTestingUtilsTestUtils, _emberCliTestingUtilsUtils) {
  Object.defineProperty(exports, 'setupTest', {
    enumerable: true,
    get: function get() {
      return _emberCliTestingUtilsTestUtils.setupTest;
    }
  });
  Object.defineProperty(exports, 'promisedSpy', {
    enumerable: true,
    get: function get() {
      return _emberCliTestingUtilsTestUtils.promisedSpy;
    }
  });
  Object.defineProperty(exports, 'waitForChange', {
    enumerable: true,
    get: function get() {
      return _emberCliTestingUtilsTestUtils.waitForChange;
    }
  });
  Object.defineProperty(exports, 'delay', {
    enumerable: true,
    get: function get() {
      return _emberCliTestingUtilsUtils.delay;
    }
  });
});