define('ember-softphone-integration/mixins/logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({
    logger: Ember.inject.service('stash-logger'),

    debug: function debug(message) {
      var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this._log('debug', message, details);
    },
    info: function info(message) {
      var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this._log('info', message, details);
    },
    warn: function warn(message) {
      var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this._log('warn', message, details, false);
    },
    error: function error(message) {
      var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this._log('error', message, details, false);
    },
    _log: function _log(level, message) {
      var details = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var stringify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      try {
        var type = typeof details === 'undefined' ? 'undefined' : _typeof(details);
        var isError = type === 'object' && details instanceof Error;
        /* only stringify if it isn't an error */
        if (stringify && !isError && (type === 'array' || type === 'object')) {
          details = JSON.stringify(details);
        }

        this.get('logger')[level](message, details);
      } catch (error) {
        /* don't let logging errors terminate execution */
        Ember.Logger.warn('Error attempting to log from headset service', {
          error: error,
          message: message,
          details: details,
          stringify: stringify
        });
      }
    }
  });
});