define("ember-purecloud-components/adapters/recording", ["exports", "ember-data", "moment", "ember-purecloud-components/adapters/purecloud", "ember-purecloud-components/mixins/logger"], function (_exports, _emberData, _moment, _purecloud, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var retentionAttributes = ['archiveDate', 'deleteDate'];

  var _default = _purecloud.default.extend(_logger.default, {
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    pigeon: Ember.inject.service(),
    feature: Ember.inject.service(),
    namespace: 'api/v2',
    defaultSerializer: 'recording',
    buildUrl: function buildUrl(uri) {
      var host = Ember.get(this, 'host');
      var namespace = Ember.get(this, 'namespace');
      var prefix = "".concat(host, "/").concat(namespace);
      return uri ? "".concat(prefix, "/").concat(uri) : prefix;
    },
    urlForQuery: function urlForQuery(params) {
      var conversationId = params.conversationId;
      return this.buildUrl("conversations/".concat(conversationId, "/recordings"));
    },
    urlForQueryRecord: function urlForQueryRecord(params) {
      var id = params.id;
      var conversationId = params.conversationId;
      return this.buildUrl("conversations/".concat(conversationId, "/recordings/").concat(id));
    },
    topicForQuery: function topicForQuery(params) {
      return this.get('feature').getFeatureToggles('qm.hawk.topics.v2').then(function (toggles) {
        var toggleOn = toggles['qm.hawk.topics.v2'];
        var userId = params.userId;
        var conversationId = params.conversationId;

        if (toggleOn) {
          return "v2.users.".concat(userId, ".conversations.").concat(conversationId, ".recordings");
        } else {
          return "conversations.".concat(conversationId, ".recordings");
        }
      });
    },
    topicForQueryRecord: function topicForQueryRecord(params) {
      var id = params.id;
      var conversationId = params.conversationId;
      return "conversations.".concat(conversationId, ".recordings.").concat(id);
    },
    query: function query(store, type, params) {
      var _this = this;

      var conversationId = params.conversationId;
      var query = Ember.assign({}, params);
      delete query.conversationId;
      delete query.id;
      delete query.userId;
      var pigeon = Ember.get(this, 'pigeon');
      var url = this.urlForQuery(params);

      if (conversationId) {
        return this.topicForQuery(params).then(function (topic) {
          pigeon.registerPriorityTopic(topic);
          var isStreamingConnected = pigeon.isStreamingConnected();

          var awaitTopicEvent = _this._awaitTopicEvent(topic, isStreamingConnected);

          return _this._httpGetWithRetry(url, query, awaitTopicEvent).finally(function () {
            return pigeon.unRegisterPriorityTopic(topic);
          });
        });
      } else {
        this.logger.debug('Must specify conversationId when calling adapters/recording#query', {
          params: params
        });
      }
    },
    queryRecord: function queryRecord(store, type, params) {
      var id = params.id;
      var conversationId = params.conversationId;
      var query = Ember.assign({}, params);
      delete query.conversationId;

      if (id && conversationId) {
        var pigeon = Ember.get(this, 'pigeon');
        var url = this.urlForQueryRecord(params);
        var topic = this.topicForQueryRecord(params);
        pigeon.registerPriorityTopic(topic);
        var isStreamingConnected = pigeon.isStreamingConnected();

        var awaitTopicEvent = this._awaitTopicEvent(topic, isStreamingConnected);

        return this._httpGetWithRetry(url, query, awaitTopicEvent).finally(function () {
          return pigeon.unRegisterPriorityTopic(topic);
        });
      } else {
        this.logger.debug('Must specify recording id and conversationId when calling adapters/recording#queryRecord', {
          params: params
        });
      }
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var recordingId = snapshot.id;
      var recordingAttrs = snapshot.serialize();
      var conversationId = recordingAttrs.conversationId;

      if (!this._didChangeRetentionAttributes(snapshot)) {
        // resolve w/ undefined so the store thinks it was successful
        // https://www.emberjs.com/api/ember-data/2.14/classes/DS.Adapter/methods/updateRecord?anchor=updateRecord
        return Ember.RSVP.Promise.resolve();
      } // PUT /api/v2/conversations/{conversationId}/recordings/{recordingId}
      // will remove existing archive/delete values if not provided
      // we must provide the existing values for archiveDate and deleteDate
      // if they are present


      var data = retentionAttributes.reduce(function (acc, attr) {
        if (recordingAttrs[attr]) {
          acc[attr] = recordingAttrs[attr];
        }

        return acc;
      }, {
        recordingId: recordingId,
        conversationId: conversationId
      });
      var url = this.buildUrl("conversations/".concat(conversationId, "/recordings/").concat(recordingId));
      var accepts = {
        json: 'application/json'
      };
      var ajax = Ember.get(this, 'ajax');
      return ajax.put(url, {
        data: data,
        accepts: accepts
      });
    },
    _httpGetWithRetry: function _httpGetWithRetry(url, data, awaitTopicEvent) {
      var _this2 = this;

      var args = arguments;
      var accepts = {
        json: 'application/json',
        plain: 'text/plain'
      };
      var ajax = Ember.get(this, 'ajax');
      return ajax.raw(url, {
        data: data,
        accepts: accepts,
        dataType: 'text'
      }).then(function (res) {
        var jqXHR = res.jqXHR;
        var response = res.response;
        var status = jqXHR.status;

        switch (status) {
          case 200:
            return JSON.parse(response);

          case 202:
            return _this2._handleHttpAccepted(awaitTopicEvent).then(function () {
              return _this2._httpGetWithRetry.apply(_this2, _toConsumableArray(args));
            });
        }
      });
    },
    _handleHttpAccepted: function _handleHttpAccepted(awaitTopicEvent) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var RECORDING_UPDATE_TIMEOUT = 5 * 60 * 1000; // 5 minutes

        var pid = Ember.run.later(function () {
          // for long-running recording transcode jobs
          var intl = Ember.get(_this3, 'intl');
          var logger = Ember.get(_this3, 'logger');
          var err = new _emberData.default.TimeoutError([{
            code: 'recordings.errors.transcode.timeout',
            title: intl.t('recordings.errors.transcode.timeout.title'),
            description: intl.t('recordings.errors.transcode.timeout.detail')
          }]);
          logger.error('RECORDING: error - timeout handling recording', {
            error: err
          });
          reject(err);
        }, RECORDING_UPDATE_TIMEOUT);
        awaitTopicEvent.then(function (res) {
          Ember.run.cancel(pid);
          resolve(res);
        });
      });
    },
    _awaitTopicEvent: function _awaitTopicEvent(topic, isStreamingConnected) {
      var _this4 = this;

      var pigeon = Ember.get(this, 'pigeon');
      var topicParts = topic.split('.');
      var eventName = topicParts[topicParts.length - 1];

      if (isStreamingConnected) {
        return new Ember.RSVP.Promise(function (resolve) {
          pigeon.one(eventName, _this4, function (response) {
            resolve(response);
          });
        });
      }

      return Ember.RSVP.Promise.resolve();
    },
    _didChangeRetentionAttributes: function _didChangeRetentionAttributes(snapshot) {
      var attributeChanges = snapshot.changedAttributes();
      return Ember.A(Object.keys(attributeChanges)).filter(function (key) {
        return Ember.A(retentionAttributes).includes(key);
      }).filter(function (key) {
        var oldVal = attributeChanges[key][0];
        var newVal = attributeChanges[key][1]; // if both are truthy values, check if they are on the same day
        // when at least one is not truthy, compare their boolean values

        var isSameDay = !(0, _moment.default)(oldVal).isSame((0, _moment.default)(newVal), 'day');
        var boolEqual = !!oldVal === !!newVal;
        return !!oldVal && !!newVal ? isSameDay : !boolEqual;
      }).length > 0;
    }
  });

  _exports.default = _default;
});