define('ember-custom-fields-components/models/text', ['exports', 'ember-data/attr', 'ember-custom-fields-components/models/field', 'ember-custom-fields-components/utils/field-validation', 'ember-custom-fields-components/mixins/limits'], function (exports, _attr, _field, _fieldValidation, _limits) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Text = _field.default.extend(_limits.default, {
        minLength: (0, _attr.default)('number'),
        maxLength: (0, _attr.default)('number'),

        isLengthBoundariesValid: Ember.computed('minLength', 'maxLength', function () {
            return _fieldValidation.default.validateLengthBoundaries(this.get('minLength'), this.get('maxLength'));
        }),

        isMinLengthValid: Ember.computed('minLength', function () {
            return _fieldValidation.default.validateInteger(this.get('minLength'));
        }),

        isMaxLengthValid: Ember.computed('maxLength', function () {
            return _fieldValidation.default.validateInteger(this.get('maxLength'));
        }),

        isNewMinLengthLessRestrictive: Ember.computed('isNew', 'minLength', function () {
            var changedAttributes = this.changedAttributes();
            if (!this.get('isNew') && changedAttributes.minLength) {
                return _fieldValidation.default.validateNewMinValueIsLessRestrictive(this.get('minLength'), changedAttributes.minLength[0]);
            }

            return true;
        }),

        isNewMaxLengthLessRestrictive: Ember.computed('isNew', 'maxLength', function () {
            var changedAttributes = this.changedAttributes();
            if (!this.get('isNew') && changedAttributes.maxLength) {
                return _fieldValidation.default.validateNewMaxValueIsLessRestrictive(this.get('maxLength'), changedAttributes.maxLength[0]);
            }

            return true;
        }),

        isConfigValid: Ember.computed('isNew', 'maxLength', 'isLengthBoundariesValid', 'isMinLengthValid', 'isMaxLengthValid', 'isMinLengthWithinLimit', 'isMaxLengthWithinLimit', 'isNewMinLengthLessRestrictive', 'isNewMaxLengthLessRestrictive', 'isTitleValid', 'isKeyValid', function () {
            return this.get('isLengthBoundariesValid') && this.get('isMinLengthValid') && this.get('isMaxLengthValid') && this.get('isMinLengthWithinLimit') && this.get('isMaxLengthWithinLimit') && this.get('isNewMinLengthLessRestrictive') && this.get('isNewMaxLengthLessRestrictive') && this.get('isTitleValid') && this.get('isKeyValid');
        })
    });

    exports.default = Text;
});