define('fixtable-ember/components/fixtable-row', ['exports', 'fixtable-ember/templates/components/fixtable-row'], function (exports, _fixtableRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fixtableRow.default,
    tagName: 'tr',
    classNames: ['fixtable-row'],
    classNameBindings: ['active', 'expanded', 'hover', 'primary:fixtable-row-primary:fixtable-row-secondary'],
    click: function click() {
      this.get('onClick')();
    },
    mouseEnter: function mouseEnter() {
      this.set('hover', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hover', false);
    }
  });
});