define('ember-custom-fields-components/components/custom-fields/controls/tag/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default', 'ember-custom-fields-components/components/custom-fields/controls/tag/template'], function (exports, _default, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;

    var SELECTOR = 'select-2-tags';
    var TAG_CONFIG_POLL_TIMEOUT = 100;

    exports.default = _default.default.extend({
        layout: _template.default,

        isConfigured: false,
        messages: Ember.A([]),
        hasErrors: Ember.computed('messages', function () {
            return this.get('messages').length > 0;
        }),

        selector: Ember.computed('control.key', function () {
            return '.' + this.get('control.key') + ' > .' + SELECTOR;
        }),

        minNumberOfTags: alias('control.minItems'),
        maxNumberOfTags: alias('control.maxItems'),

        minLengthOfTag: alias('control.minLength'),
        maxLengthOfTag: alias('control.maxLength'),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey) || Ember.A([]);
            this.set('control.value', savedData);
            this.set('control.isValid', true);
            this._pollForSelectorAvailability();
        },
        _pollForSelectorAvailability: function _pollForSelectorAvailability() {
            var _this = this;

            var $select = Ember.$(this.get('selector'));

            if (!$select.length) {
                Ember.run.later(function () {
                    _this._pollForSelectorAvailability();
                }, TAG_CONFIG_POLL_TIMEOUT);
            } else {
                this._configure();
            }
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);

            try {
                var $select = Ember.$(this.get('selector'));
                $select.select2('remove');
            } catch (err) {
                // do nothing
            }
        },
        _configure: function _configure() {
            var _this2 = this;

            try {
                if (this.get('isConfigured')) {
                    return;
                }
                var intl = this.get('intl'),
                    $select = Ember.$(this.get('selector')),
                    options = {
                    tags: this.get('control.value'),
                    placeholder: intl.t('emberCustomFieldsComponents.controls.addTag'),
                    dropdownCssClass: 'select2-dynamic-schema-tags',
                    tokenSeparators: [',', '\t', ' ', '\n'],
                    maximumResultsForSearch: -1,
                    dropdownCss: { display: 'none' }
                };

                if ($select && $select.length) {
                    $select.val(this.get('control.value')).trigger('change'); // Load saved tags into the form field

                    $select.select2(options).on('change', function (event) {
                        _this2.set('messages', Ember.A([]));
                        _this2.set('control.isValid', true);

                        var tags = event.val,
                            isTagCountValid = _this2._isTagCountValid(tags),
                            isTagLengthValid = _this2._isTagLengthValid(tags);

                        if (isTagCountValid && isTagLengthValid) {
                            _this2.set('control.value', tags);
                        }

                        if (_this2.get('setCustomFieldValidation')) {
                            _this2.get('setCustomFieldValidation')();
                        }
                    });
                    this.set('isConfigured', true);
                }
            } catch (error) {
                // uncaught error in select-2 when rendering can occasionally happen on page disposal.
            }
        },
        _isTagCountValid: function _isTagCountValid(tags) {
            tags = Ember.A(tags);
            var intl = this.get('intl'),
                minNumberOfTags = this.get('minNumberOfTags'),
                maxNumberOfTags = this.get('maxNumberOfTags'),
                isMinOk = minNumberOfTags ? tags.length >= minNumberOfTags : true,
                isMaxOk = maxNumberOfTags ? tags.length <= maxNumberOfTags : true;

            var messages = this.get('messages'),
                errorMessage = '';

            if (!isMinOk) {
                errorMessage = minNumberOfTags > 1 ? intl.t('emberCustomFieldsComponents.fieldErrors.minTagsPlural', { minItems: minNumberOfTags }) : intl.t('emberCustomFieldsComponents.fieldErrors.minTags');

                messages.pushObject(errorMessage);
                this.set('control.isValid', false);
            }

            if (!isMaxOk) {
                errorMessage = maxNumberOfTags > 1 ? intl.t('emberCustomFieldsComponents.fieldErrors.maxTagsPlural', { maxItems: maxNumberOfTags }) : intl.t('emberCustomFieldsComponents.fieldErrors.maxTags');

                messages.pushObject(errorMessage);
                this.set('control.isValid', false);
            }

            return isMinOk && isMaxOk;
        },
        _isTagLengthValid: function _isTagLengthValid(tags) {
            tags = Ember.A(tags);
            var intl = this.get('intl'),
                minLengthOfTag = this.get('minLengthOfTag'),
                maxLengthOfTag = this.get('maxLengthOfTag');

            var isMinOk = tags.every(function (tag) {
                return minLengthOfTag ? tag.length >= minLengthOfTag : true;
            });

            var isMaxOk = tags.every(function (tag) {
                return maxLengthOfTag ? tag.length <= maxLengthOfTag : true;
            });

            var messages = this.get('messages'),
                errorMessage = '';

            if (!isMinOk) {
                errorMessage = minLengthOfTag > 1 ? intl.t('emberCustomFieldsComponents.fieldErrors.minLengthTagPlural', { minLength: minLengthOfTag }) : intl.t('emberCustomFieldsComponents.fieldErrors.minLengthTag');

                messages.pushObject(errorMessage);
                this.set('control.isValid', false);
            }

            if (!isMaxOk) {
                errorMessage = maxLengthOfTag > 1 ? intl.t('emberCustomFieldsComponents.fieldErrors.maxLengthTagPlural', { maxLength: maxLengthOfTag }) : intl.t('emberCustomFieldsComponents.fieldErrors.maxLengthTag');

                messages.pushObject(errorMessage);
                this.set('control.isValid', false);
            }

            return isMinOk && isMaxOk;
        }
    });
});