define('ember-engine-custom-fields/components/schema-editor/field-list/field-list-item/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-list/field-list-item/template', 'ember-custom-fields-components/utils/field-util'], function (exports, _template, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Component.extend({
        layout: _template.default,

        field: null,
        validation: inject.service(),

        fieldType: _fieldUtil.FIELD_TYPE,
        activeField: null,

        isActiveField: computed('activeField', function () {
            return this.get('field') === this.get('activeField');
        }),

        isInvalid: computed('field.isConfigValid', 'isActiveField', 'validation.isActiveFieldShowingValidationErrors', function () {
            var isInvalid = !this.get('field.isConfigValid');
            var isActiveField = this.get('isActiveField');

            var isActiveFieldShowingValidationErrors = this.get('validation.isActiveFieldShowingValidationErrors');

            if (isActiveField) {
                return isActiveFieldShowingValidationErrors;
            } else {
                return isInvalid;
            }
        }),

        actions: {
            editField: function editField() {
                this.editField(this.get('field'));
            },
            deleteField: function deleteField() {
                this.deleteField(this.get('field'));
            }
        }
    });
});