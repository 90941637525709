define('ember-chat-components/components/chat-panel/conversation/action-bar-v2/component', ['exports', 'ember', 'ember-chat-components/mixins/webrtc-action-utils', 'ember-chat-components/components/chat-panel/conversation/action-bar-v2/template'], function (exports, _ember, _webrtcActionUtils, _template) {
    'use strict';

    exports.__esModule = true;


    const { computed, inject, run } = _ember.default;

    exports.default = _ember.default.Component.extend(_webrtcActionUtils.default, {
        classNames: ['chat-action-bar-v2'],
        classNameBindings: ['oneToOneChat:one-to-one'],
        layout: _template.default,

        participantCount: computed.alias('activeRoom.sortedParticipants.length'),
        activeRoom: null,
        showDropdown: false,
        callingUserJid: null,
        canViewVideo: true,

        useMediaProviders: false,

        intl: inject.service(),
        phone: inject.service(),
        chat: inject.service(),
        webrtc: inject.service(),
        sidebar: inject.service(),
        permissions: inject.service(),
        mediaProviders: inject.service(),

        canViewChat: computed.reads('permissions.canViewBusinessChat'),
        disableRoomInteraction: computed('activeRoom.{jid,joinError}', function () {
            return !this.get('activeRoom.jid') || this.get('activeRoom.joinError');
        }),

        activePhoneCall: computed.reads('phone.activeCall'),
        isPhoneButtonEnabled: computed.and('phone.enabled', 'oneToOneChat'),
        noStationSelected: computed.reads('phone.noStationSelected'),
        oneToOneChat: computed('activeRoom.oneToOneTarget', function () {
            const activeRoom = this.get('activeRoom');
            return activeRoom && activeRoom.isOneToOneRoom();
        }),

        activeCallIsActiveRoom: computed('callingUserJid', 'activeRoom.jid', function () {
            return this.get('callingUserJid') === this.get('activeRoom.jid');
        }),

        dialingOtherParty: computed.and('activeCallIsActiveRoom', 'activePhoneCall.isDialing'),

        onCallWithOtherParty: computed('activePhoneCall.{isConnected,otherPartyJid}', 'activeRoom.jid', function () {
            if (this.get('activePhoneCall.isConnected') === false) {
                return false;
            }
            let otherPartyJid = this.get('activePhoneCall.otherPartyJid');
            let jid = this.get('activeRoom.jid');
            return otherPartyJid === jid;
        }),

        showUnseen: computed('activeRoom.{unseen,mentions}', function () {
            return this.get('activeRoom.unseen') && !this.get('activeRoom.mentions');
        }),

        disablePhoneButton: computed.or('dialingOtherParty', 'disableRoomInteraction', 'webrtcPhoneWithIssues'),

        phoneTooltipTitle: _ember.default.computed('noStationSelected', 'webrtcPhoneWithIssues', 'webrtc.webrtcAudioCallIssueKey', function () {
            let key = 'tooltip.contactIcon.phone';
            if (this.get('noStationSelected')) {
                key = 'components.navMenu.noStationSelected';
            } else if (this.get('webrtcPhoneWithIssues')) {
                key = this.get('webrtc.webrtcAudioCallIssueKey');
            }
            return this.get('intl').t(key).toString();
        }),

        isParticipantsButtonHidden: computed('activeRoom.{oneToOneTarget,pendingRoom}', function () {
            const activeRoom = this.get('activeRoom');
            return activeRoom && activeRoom.isOneToOneRoom();
        }),

        showingVideo: _ember.default.computed('activeRoom.showingVideo', 'mediaProviders.usingVideoProvider', function () {
            return this.get('activeRoom.showingVideo') || this.get('mediaProviders.usingVideoProvider');
        }),

        // eslint-disable-next-line ember/no-observers
        activeRoomChanged: _ember.default.observer('activeRoom.oneToOneTarget', function () {
            if (this.get('activeRoom.oneToOneTarget')) {
                this.get('store').findRecord('public-api-user', this.get('activeRoom.oneToOneTarget.id'), {
                    adapterOptions: {
                        expand: 'authorization'
                    }
                }).then(user => this.set('user', user));
            } else {
                this.set('user', null);
            }
        }),

        actions: {
            toggleVideo() {
                if (this.get('isVideoButtonEnabled')) {
                    if (this.get('useMediaProviders')) {
                        return;
                    }
                    this.sendAction('paneDeselected', ['isParticipantsSelected']);
                    this.set('activeRoom.showingVideo', true);
                    this.get('sidebar').openPanel('webrtc');
                }
            },

            toggleInvite() {
                this.sendAction('paneSelectionToggled', 'isInviteSelected');
                this.sendAction('paneDeselected', ['isParticipantsSelected', 'isSearchSelected', 'isSettingsSelected']);
            },

            toggleParticipants() {
                this.sendAction('paneSelectionToggled', 'isParticipantsSelected');
                this.sendAction('paneDeselected', ['isInviteSelected', 'isSearchSelected', 'isSettingsSelected']);
            },

            toggleSearch() {
                this.sendAction('paneSelectionToggled', 'isSearchSelected');
                this.sendAction('paneDeselected', ['isInviteSelected', 'isParticipantsSelected', 'isSettingsSelected']);
            },

            toggleSettings() {
                this.sendAction('paneSelectionToggled', 'isSettingsSelected');
                this.sendAction('paneDeselected', ['isInviteSelected', 'isParticipantsSelected', 'isSearchSelected']);
            },

            callUser() {
                // putting this here instead of disabling the button because the tooltip doesn't show on a disabled button
                if (this.get('disablePhoneButton')) {
                    return;
                }

                const user = this.get('chat.activeRoom.oneToOneTarget');
                if (!this.get('noStationSelected') && user.get('guid') && !this.get('phone.phoneDisabled')) {
                    this.set('callingUserJid', user.get('jid'));
                    const phone = this.get('phone');
                    run.throttle(phone, phone.makeCall, user, 2000);
                }
            }
        }
    });
});