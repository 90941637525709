define('ember-custom-fields-components/components/custom-fields/component', ['exports', 'ember-custom-fields-components/components/custom-fields/template', 'ember-custom-fields-components/utils/field-util', 'moment'], function (exports, _template, _fieldUtil, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reads = Ember.computed.reads;
    exports.default = Ember.Component.extend({
        layout: _template.default,
        classNames: ['custom-fields'],
        intl: Ember.inject.service(),
        title: reads('schema.name'),
        controls: reads('schema.properties'),

        fieldType: _fieldUtil.FIELD_TYPE,
        fieldValues: Ember.computed('controls', 'fieldData', function () {
            var controls = this.get('controls');
            var fieldData = this.get('fieldData');
            var fieldTypeENUM = this.get('fieldType');

            if (controls && fieldData) {
                return this.get('controls').reduce(function (acc, field) {
                    var fieldKey = field.get('key');
                    var fieldValue = fieldData[fieldKey];
                    var fieldValueType = field.get('type');
                    var title = field.get('title');
                    var value = fieldValue;
                    var enumList = void 0,
                        enumItem = void 0;

                    if (!Ember.isBlank(fieldValue)) {
                        switch (fieldValueType) {
                            case fieldTypeENUM.DATE:
                                value = (0, _moment.default)(fieldValue).format('ll');
                                break;
                            case fieldTypeENUM.DATETIME:
                                value = (0, _moment.default)(fieldValue).format('lll');
                                break;
                            case fieldTypeENUM.ENUM:
                                enumList = Ember.A(field.get('enumList'));
                                enumItem = enumList.findBy('key', fieldValue);
                                value = enumItem ? enumItem.title : fieldValue;
                                break;
                        }
                        acc.push({ title: title, value: value, fieldValueType: fieldValueType, fieldValue: fieldValue, fieldKey: fieldKey });
                    } else {
                        // If there is a checkbox in the schema, we want it to render even if no value has been saved yet
                        if (fieldValueType === fieldTypeENUM.CHECKBOX) {
                            var _value = false;
                            acc.push({ title: title, value: _value, fieldValueType: fieldValueType, fieldValue: fieldValue, fieldKey: fieldKey });
                        }
                    }

                    return acc;
                }, []);
            } else {
                return null;
            }
        }),
        isFormValid: Ember.computed('controls.@each.isValid', function () {
            var controls = this.get('controls');

            if (controls) {
                return controls.isEvery('isValid', true);
            }
        }),

        actions: {
            onSetCustomFieldValidation: function onSetCustomFieldValidation() {
                if (this.get('setCustomFieldValidation')) {
                    this.get('setCustomFieldValidation')(this.get('isFormValid'));
                }
            }
        }
    });
});