define('ember-engine-custom-fields/mixins/field-keys', ['exports', 'ember-engine-custom-fields/mixins/safe-set', 'ember-custom-fields-components/utils/field-util'], function (exports, _safeSet, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        run = Ember.run,
        get = Ember.get,
        set = Ember.set;

    var KEY_UPDATE_TIME = 500;

    exports.default = Ember.Mixin.create(_safeSet.default, {
        keyPrefixUpdateMillis: KEY_UPDATE_TIME,
        itemModel: computed.or('enumItem', 'field'),
        isNew: computed.alias('itemModel.isNew'),
        hasTitle: computed.alias('itemModel.title'),
        itemList: [],

        updateKey: function updateKey(prefix, updatePropertyName) {
            var key = (0, _fieldUtil.getFieldKey)(prefix, prefix ? this.get('type') : '');
            if (this.get('isNew') && !(this.get('isDestroyed') || this.get('isDestroying'))) {
                if (updatePropertyName) {
                    this.set('itemModel.propertyName', key);
                }
                this.set('key', key);
                this.notifyPropertyChange('key');
            }
        },


        keyPrefix: computed('key', {
            get: function get() {
                var key = this.get('key') || '';
                var type = this.get('type') || '';
                if (type) {
                    var suffix = '_' + type;
                    var clearAtIndex = key.lastIndexOf(suffix);
                    return clearAtIndex >= 0 ? key.substring(0, clearAtIndex) : key;
                } else {
                    return key;
                }
            },
            set: function set(keyName, prefix) {
                var _this = this;

                run.join(function () {
                    run.debounce(_this, _this.updateKey, prefix, true, _this.get('keyPrefixUpdateMillis'));
                });
                return prefix || '';
            }
        }),

        typeSuffix: computed('key', 'type', function () {
            var type = this.get('type');
            return '_' + type;
        }),

        isFieldKeyValid: computed('key', function () {
            var _getProperties = this.getProperties('key', 'isNew'),
                key = _getProperties.key,
                isNew = _getProperties.isNew;

            return !isNew || key && !_fieldUtil.IS_NOT_VALID_KEY.test(key);
        }),

        isFieldKeyUnique: computed('itemModel', 'key', 'itemList.@each.{key,title}', function () {
            var _getProperties2 = this.getProperties('key', 'itemList', 'isNew', 'itemModel'),
                key = _getProperties2.key,
                itemList = _getProperties2.itemList,
                isNew = _getProperties2.isNew,
                itemModel = _getProperties2.itemModel;

            return !isNew || key && (itemList || []).filter(function (item) {
                return item !== itemModel && get(item, 'key') === key;
            }).length === 0;
        }),

        showKeyModify: computed.or('showError', 'keyIsFocused'),

        showError: computed('key', 'isFieldKeyValid', 'isFieldKeyUnique', function () {
            var _getProperties3 = this.getProperties('isFieldKeyValid', 'isFieldKeyUnique', 'isNew', 'hasTitle', 'itemModel'),
                isFieldKeyValid = _getProperties3.isFieldKeyValid,
                isFieldKeyUnique = _getProperties3.isFieldKeyUnique,
                isNew = _getProperties3.isNew,
                hasTitle = _getProperties3.hasTitle,
                itemModel = _getProperties3.itemModel;

            var showError = hasTitle && isNew && (!isFieldKeyValid || !isFieldKeyUnique);

            run.next(function () {
                if (!(get(itemModel, 'isDestroyed') || get(itemModel, 'isDestroying'))) {
                    set(itemModel, 'isKeyValid', !showError);
                }
            });

            return showError;
        }),

        errorKey: computed('key', 'isFieldKeyValid', 'isFieldKeyUnique', function () {
            var _getProperties4 = this.getProperties('isFieldKeyValid', 'isFieldKeyUnique'),
                isFieldKeyValid = _getProperties4.isFieldKeyValid,
                isFieldKeyUnique = _getProperties4.isFieldKeyUnique;

            if (!isFieldKeyValid) {
                return 'emberEngineCustomFields.fieldErrors.invalidKey';
            }
            if (!isFieldKeyUnique) {
                return 'emberEngineCustomFields.fieldErrors.invalidNotUnique';
            }
            return '';
        })
    });
});