define("ember-document-title/components/document-title", ["exports", "ember", "ember-document-title/system/token-list"], function (exports, _ember, _emberDocumentTitleSystemTokenList) {

  var tokens = new _emberDocumentTitleSystemTokenList["default"]();
  var get = _ember["default"].get;
  var set = _ember["default"].set;

  exports["default"] = _ember["default"].Component.extend({
    // Public API
    separator: null,
    title: null,
    prepend: null,
    replace: false,

    isVirtual: true,
    tagName: '',
    hidden: false,

    init: function init() {
      // Clear default title
      if (tokens.length === 0) {
        document.title = '';
        if (get(this, 'separator') == null) {
          set(this, 'separator', ' | ');
        }
      }

      tokens.push(this);
      this._super.apply(this, arguments);
    },

    showSeparatorAfter: null,
    showSeparatorBefore: null,

    render: function render(buffer) {
      var titleTag = document.getElementsByTagName('title')[0];
      var previous = get(this, 'previous');
      var replace = get(this, 'replace');
      if (previous && get(previous, 'prepend')) {
        if (get(previous, 'showSeparatorBefore')) {
          var pivot = get(previous, 'previous');
          if (pivot) {
            set(pivot, 'showSeparatorAfter', !replace);
          }
          set(previous, 'showSeparatorBefore', false);
        }
        set(this, 'showSeparatorAfter', !replace);
        var firstNode = previous._morph.firstNode || previous._morph.start;
        this._morph = buffer.dom.insertMorphBefore(titleTag, firstNode);
      } else {
        set(this, 'showSeparatorBefore', !replace);
        this._morph = buffer.dom.appendMorph(titleTag);
      }
      this._super.apply(this, arguments);
    },

    willClearRender: function willClearRender() {
      tokens.remove(this);
      var morph = this._morph;
      _ember["default"].run.schedule('render', morph, morph.destroy);
      this._super.apply(this, arguments);
    }
  });
});