define("ember-purecloud-components/adapters/purecloud", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _emberData.default.RESTAdapter.extend({
    defaultSerializer: 'purecloud',
    host: '/platform',
    namespace: 'api',
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    modelFor: function modelFor(type) {
      return this.pathForType(type);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && _typeof(payload) === 'object') {
        return [{
          status: "".concat(status),
          title: payload.code,
          detail: {
            message: payload.message,
            contextId: payload.contextId
          }
        }];
      }

      return [{
        status: status,
        payload: payload
      }];
    },
    ajaxOptions: function ajaxOptions(url, type, hash) {
      // https://github.com/emberjs/data/issues/1398
      // https://github.com/emberjs/data/blob/v2.7.0/addon/adapters/rest.js#L1065
      hash = this._super(url, type, hash);

      if (type === 'DELETE') {
        hash.dataType = 'text';
      }

      return hash;
    },
    // TODO: replace this private function with request type hooks
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      if (requestType === 'query' && modelName === 'voicemail-message') {
        return this._super.apply(this, arguments);
      }

      var url = (this.host ? this.host : '') + '/' + this.namespace + '/' + this.pathForType(modelName);

      if (id) {
        url += '/' + id;
      }

      return url;
    },
    updateRecord: function updateRecord(store, type, record) {
      if (type.modelName === 'voicemail-message') {
        return this._super.apply(this, arguments);
      }

      var data = this.serialize(record, {
        includeId: true
      });
      var id = Ember.get(record, 'id');
      return this.ajax(this.buildURL(type.modelName, id), 'PUT', {
        data: data
      });
    },
    deleteRecord: function deleteRecord(store, type, record) {
      var id = Ember.get(record, 'id');
      var url = this.buildURL(type.modelName, id);
      return this.ajax(url, 'DELETE', {
        dataType: 'text'
      });
    }
  });

  _exports.default = _default;
});