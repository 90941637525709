define('ember-chat-components/components/chat-panel/conversation/search-pane/component', ['exports', 'ember', 'ember-data', 'ember-chat-components/components/chat-panel/conversation/search-pane/template'], function (exports, _ember, _emberData, _template) {
    'use strict';

    exports.__esModule = true;
    exports.DEBOUNCE_WAIT = undefined;
    exports.navigationKeyPressed = navigationKeyPressed;
    const DEBOUNCE_WAIT = exports.DEBOUNCE_WAIT = 500; // msec

    const END = 35;
    const HOME = 36;
    const LEFT_ARROW = 37;
    const UP_ARROW = 38;
    const RIGHT_ARROW = 39;
    const DOWN_ARROW = 40;
    const NAVIGATION_KEYS = [END, HOME, LEFT_ARROW, UP_ARROW, RIGHT_ARROW, DOWN_ARROW];

    function navigationKeyPressed(event) {
        return NAVIGATION_KEYS.includes(event.keyCode);
    }

    const SearchPaneComponent = _ember.default.Component.extend({
        layout: _template.default,
        classNames: ['search-pane'],
        classNameBindings: ['useMinWidth:watch-width'],

        intl: _ember.default.inject.service(),
        chat: _ember.default.inject.service(),

        searchValue: '',
        lastSuccessfulSearch: '',

        isContextAware: true,
        useMinWidth: true,
        minSearchCharCount: 4,

        results: null,

        loadMore: null,

        init() {
            this._super(...arguments);

            this.set('results', []);

            // Activate observer
            this.get('chat.activeRoom');
        },

        didInsertElement() {
            this._super(...arguments);

            this.$('.search-results').on(`scroll.${this.elementId}`, _ember.default.run.bind(this, event => {
                const scrollSize = event.target.scrollHeight - event.target.clientHeight;
                if (event.target.scrollTop > scrollSize - 400) {
                    _ember.default.run.throttle(this, this._endReached, 500);
                }
            }));
        },

        willDestroyElement() {
            this._super(...arguments);

            const searchResults = this.$('.search-results');
            if (searchResults) {
                searchResults.off();
            }
        },

        resultsTotal: _ember.default.computed.readOnly('resultMetaData.total'),

        zeroResultsFound: _ember.default.computed.empty('results'),

        itemsFoundText: _ember.default.computed('results.[]', function () {
            const resultsLength = this.get('results.length');
            const totalResultsLength = this.get('resultsTotal');
            if (this.get('hasError')) {
                return this.get('intl').t('components.searchPane.error');
            } else if (resultsLength === 1) {
                return this.get('intl').t('components.searchPane.itemFoundFor', {
                    keyword: this.get('searchValue')
                });
            } else {
                return this.get('intl').t('components.searchPane.itemsFoundFor', {
                    numItems: `${totalResultsLength}`,
                    keyword: this.get('searchValue')
                });
            }
        }),

        hasResults: _ember.default.computed('results.[]', 'resultMetaData.searchValue', function () {
            return !!this.get('resultMetaData.searchValue');
        }),

        isLoading: _ember.default.computed('loadingPromise.isPending', function () {
            if (!this.get('loadingPromise')) {
                return false;
            }
            return this.get('loadingPromise.isPending');
        }),

        // eslint-disable-next-line ember/no-observers
        focusSearchField: _ember.default.observer('isDisplaying', function () {
            if (this.get('isDisplaying')) {
                _ember.default.run.scheduleOnce('afterRender', () => {
                    this.$('.search-input input').focus();
                });
            }
        }),

        // eslint-disable-next-line ember/no-observers
        clearOnContextSwitch: _ember.default.observer('chat.activeRoom', 'isContextAware', function () {
            if (this.get('isContextAware')) {
                this.clearSearchResults();
            }
        }),

        clearSearchResults() {
            this.set('searchValue', '');
            this.clearSearch();
        },

        _endReached() {
            const loadMore = this.get('loadMore');
            if (typeof loadMore === 'function') {
                const promise = loadMore();
                if (promise && promise.then) {
                    this.set('loadingPromise', _emberData.default.PromiseArray.create({ promise }));
                }
            }
        },

        previousDebounce: null,

        _cancelInFlightDebounce() {
            if (this.get('previousDebounce')) {
                // cancel the prevoious debounce if enter is clicked.
                _ember.default.run.cancel(this.get('previousDebounce'));
            }
        },

        actions: {
            handleTyping(value, event) {
                this.$('.search-results').scrollTop(0);
                if (event.which === 13) {
                    this._cancelInFlightDebounce();
                } else if (!navigationKeyPressed(event) && value) {
                    this.set('previousDebounce', _ember.default.run.debounce(this, 'send', 'performSearch', value, DEBOUNCE_WAIT));
                } else if (!value) {
                    this._cancelInFlightDebounce();
                    this.clearSearchResults();
                }
            },

            performSearch(value) {
                if (value) {
                    const promise = this.get('performSearch')(value, this.get('resultMetaData'));

                    promise.then(() => {
                        this.set('hasError', false);
                    }).catch(e => {
                        _ember.default.Logger.error(`SEARCHPANE: Search Failed:`, {
                            error: e
                        });
                        this.set('hasError', true);
                    });

                    this.set('loadingPromise', _emberData.default.PromiseArray.create({ promise }));
                } else {
                    this.clearSearchResults();
                }
            },

            paneDeselected() {
                this.get('paneDeselected')(['isInviteSelected', 'isParticipantsSelected', 'isSettingsSelected', 'isSearchSelected']);
            }
        }
    });

    exports.default = SearchPaneComponent;
});