define('ember-chat-components/mixins/viewport', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;


    // Viewport code adapted from:
    // https://emberway.io/ember-js-detecting-if-a-dom-element-is-in-the-viewport-eafcc77a6f86

    const { computed } = _ember.default;

    const REFRESH_RATE = 200;

    function getScrollParent(comp) {
        if (!comp || !comp.$) {
            return null;
        }
        try {
            return [...comp.$().parents()].find(el => {
                const $el = _ember.default.$(el);
                return (/(auto|scroll)/.test($el.css('overflow') + $el.css('overflow-y') + $el.css('overflow-x'))
                );
            });
        } catch (err) {
            return null;
        }
    }

    exports.default = _ember.default.Mixin.create({
        enableViewport: true,

        enteredViewport: computed('boundingClientRect', 'windowHeight', function () {
            const rect = this.get('boundingClientRect');
            const windowHeight = this.get('windowHeight');
            const windowWidth = this.get('windowWidth');
            if (!rect) {
                return false;
            }

            return rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth;
        }),

        exitedViewport: computed.not('enteredViewport'),

        /**
         * Implementers of these hooks are responsible for setting up their own run loop for
         * scheduling, since this mixin is using request animation frame to handle events.
         **/
        didEnterViewport() {},
        didExitViewport() {},

        didInsertElement() {
            this._super(...arguments);

            if (this.get('enableViewport')) {
                _ember.default.run.scheduleOnce('afterRender', this, '_setup');
            }
        },

        willDestroyElement() {
            this._super(...arguments);
            this._teardown();
        },

        _setup() {
            _ember.default.run(() => {
                if (this.isDestroying || this.isDestroyed) {
                    return;
                }

                this._bindEvents();
                this._updateBoundingClientRect();
                this.set('windowHeight', window.innerHeight || document.documentElement.clientHeight);
                this.set('windowWidth', window.innerWidth || document.documentElement.clientWidth);
            });
        },

        _teardown() {
            if (!this.$()) {
                return;
            }

            const scrollParent = getScrollParent(this);
            if (!scrollParent) {
                return;
            }

            const scrollParentRef = _ember.default.$(scrollParent);
            if (scrollParentRef) {
                scrollParentRef.off(`.${this.elementId}`);
            }

            const windowRef = _ember.default.$(window);
            if (windowRef) {
                windowRef.off(`.${this.elementId}`);
            }
        },

        _bindEvents() {
            if (!this.$()) {
                return;
            }

            const scrollParent = getScrollParent(this);
            if (!scrollParent) {
                return;
            }

            const $scrollParent = _ember.default.$(scrollParent);
            const resizeHandler = this.resizeHandler.bind(this);
            $scrollParent.on(`scroll.${this.elementId}`, this.scrollHandler.bind(this));
            _ember.default.$(window).on(`resize.${this.elementId}`, resizeHandler);
        },

        _updateBoundingClientRect() {
            const el = this.element;
            if (el && el.getBoundingClientRect) {
                this.set('boundingClientRect', el.getBoundingClientRect());
            }
        },

        _updateWindowSize() {
            this.set('windowHeight', window.innerHeight || document.documentElement.clientHeight);
            this.set('windowWidth', window.innerWidth || document.documentElement.clientWidth);
        },

        scrollHandler() {
            _ember.default.run.debounce(this, '_scrollHandler', REFRESH_RATE, false);
        },

        _scrollHandler() {
            const run = window.requestAnimationFrame || _ember.default.run;
            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(this._updateBoundingClientRect.bind(this));
            } else {
                _ember.default.run.debounce(this, '_updateBoundingClientRect', 100);
            }

            if (this.get('enteredViewport')) {
                run(this.didEnterViewport.bind(this));
            } else {
                run(this.didExitViewport.bind(this));
            }
        },

        resizeHandler() {
            _ember.default.run.debounce(this, '_resizeHandler', REFRESH_RATE, false);
        },

        _resizeHandler() {
            const run = window.requestAnimationFrame || _ember.default.run;
            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(this._updateWindowSize.bind(this));
            } else {
                _ember.default.run.debounce(this, '_updateWindowSize', 100);
            }

            if (this.get('enteredViewport')) {
                run(this.didEnterViewport.bind(this));
            } else {
                run(this.didExitViewport.bind(this));
            }
        }
    });
});