define("ember-purecloud-components/components/presence-label/component", ["exports", "ember-purecloud-components/components/presence-label/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':presence-label-group', 'wrap', 'isChatEnabled::hidden'],
    wrap: false,
    showStatus: true,
    showActivity: true,
    isChatEnabled: false,
    entity: null,
    showPresenceLabel: true,
    activityPresenceClass: Ember.computed.reads('entity.activityPresenceClass'),
    presenceLabel: Ember.computed.reads('entity.presenceLabel')
  });

  _exports.default = _default;
});