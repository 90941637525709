define('ember-engine-custom-fields/mixins/toggle-field-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Mixin = Ember.Mixin,
        computed = Ember.computed;
    var not = computed.not;
    exports.default = Mixin.create({
        field: null,
        toggleFieldPanel: function toggleFieldPanel() {},

        existingField: not('field.isNew'),

        actions: {
            toggleFieldPanel: function toggleFieldPanel() {
                var toggleFieldPanel = this.get('toggleFieldPanel');

                if (toggleFieldPanel) {
                    toggleFieldPanel();
                }
            }
        }
    });
});