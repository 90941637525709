define('ember-custom-fields-components/models/datetime', ['exports', 'ember-custom-fields-components/models/field'], function (exports, _field) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DateTime = _field.default.extend({});

  exports.default = DateTime;
});