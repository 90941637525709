define('ember-engine-custom-fields/components/schema-grid/edit-schema-link/component', ['exports', 'ember-engine-custom-fields/components/schema-grid/edit-schema-link/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Component.extend({
        layout: _template.default,
        permissions: inject.service(),

        hasEditSchemaPermission: computed.reads('permissions.hasExternalContactsEditSchemaAccess')
    });
});