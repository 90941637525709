define('ember-audio-visualizer/components/audio-visualizer/component', ['exports', 'ember', 'ember-audio-visualizer/components/audio-visualizer/template'], function (exports, _ember, _emberAudioVisualizerComponentsAudioVisualizerTemplate) {
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    classNames: ['audio-visualizer'],
    layout: _emberAudioVisualizerComponentsAudioVisualizerTemplate['default'],

    audioStream: null,
    backgroundColor: '#F5FCFF',
    lineColor: '#4F7787',

    canvasEl: null,
    canvasCtx: null,
    currentAnimationFrame: null,
    hasStarted: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var canvas = this.$('canvas')[0];
      this.set('canvasEl', canvas);
      this.set('canvasCtx', canvas.getContext('2d'));

      if (this.get('audioStream') && !this.get('hasStarted')) {
        this.resetVisualization();
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._cleanupAnalyzer();
    },

    _cleanupAnalyzer: function _cleanupAnalyzer() {
      var audioCtx = this.get('audioCtx');
      if (audioCtx) {
        audioCtx.close();
        this.set('audioCtx', null);
      }

      if (this.get('currentAnimationFrame')) {
        cancelAnimationFrame(this.get('currentAnimationFrame'));
        this.set('currentAnimationFrame', null);
      }
    },

    resetVisualization: observer('audioStream', function () {
      this.set('hasStarted', true);
      this._cleanupAnalyzer();

      if (this.get('audioStream')) {
        this._visualizeAudio(this.get('audioStream'));
      }
    }),

    _visualizeAudio: function _visualizeAudio(stream) {
      var AudioCtx = window.AudioContext || window.webkitAudioContext;
      var audioCtx = new AudioCtx();
      this.set('audioCtx', audioCtx);
      var analyser = audioCtx.createAnalyser();
      analyser.minDecibels = -90;
      analyser.maxDecibels = -10;
      analyser.smoothingTimeConstant = 0.65;

      var source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyser);

      var canvas = this.get('canvasEl');
      var width = canvas.width;
      var height = canvas.height;

      var canvasCtx = this.get('canvasCtx');

      var bufferLength = analyser.fftSize = 2048;
      console.log({ bufferLength: bufferLength });
      var dataArray = new Uint8Array(bufferLength);

      canvasCtx.clearRect(0, 0, width, height);
      var _this = this;

      function draw() {

        _this.set('currentAnimationFrame', requestAnimationFrame(draw));

        analyser.getByteTimeDomainData(dataArray);

        canvasCtx.fillStyle = _this.get('backgroundColor');
        canvasCtx.fillRect(0, 0, width, height);

        canvasCtx.lineWidth = 1;
        canvasCtx.strokeStyle = _this.get('lineColor');

        canvasCtx.beginPath();

        var sliceWidth = width / bufferLength;
        var x = 0;

        for (var i = 0; i < bufferLength; i++) {

          var v = dataArray[i] / 128.0;
          var y = v * height / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();
      }

      draw();
    }
  });
});