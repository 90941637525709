define('ember-chat-components/components/chat-panel/conversation/header/subject/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/header/subject/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const ChatSubjectComponent = _ember.default.Component.extend({
        classNames: ['subject'],
        layout: _template.default,

        chat: _ember.default.inject.service(),
        intl: _ember.default.inject.service(),
        session: _ember.default.inject.service(),

        activeRoom: null,

        editing: false,
        subjectInput: '',

        inputPlaceholder: _ember.default.computed(function () {
            return this.get('intl').t('chat.changeSubjectInputPlaceholder');
        }),

        subjectEditable: _ember.default.computed('activeRoom.{group,oneToOneTarget}', function () {
            const notOneToOne = !this.get('activeRoom.oneToOneTarget');
            const notGroup = !this.get('activeRoom.group');

            return notOneToOne && notGroup;
        }),

        clearInputVisible: _ember.default.computed.notEmpty('subjectInput'),

        groupNameTooLong: _ember.default.computed('subjectInput', function () {
            return this.get('subjectInput.length') > 140;
        }),

        submitDisabled: _ember.default.computed('subjectInput', 'groupNameTooLong', function () {
            return this.get('subjectInput').trim().length === 0 || this.get('groupNameTooLong');
        }),

        // eslint-disable-next-line ember/no-observers
        disableEditWhenRoomChanges: _ember.default.observer('activeRoom', function () {
            this.set('editing', false);
        }),

        // eslint-disable-next-line ember/no-observers
        setupInputStateForEditing: _ember.default.observer('editing', function () {
            if (this.get('editing')) {
                // The subjectInput value changing code needs to go on the next run loop because Ember ignores the subject
                // input value change otherwise. The Focus code then needs to go after the next render so that the input
                // state is set for the text to be there to highlight.
                _ember.default.run.next(null, () => {
                    this.set('subjectInput', this.get('activeRoom.rawSubject'));
                    this.focusInput();
                });
            }
        }),

        actions: {
            editSubject() {
                this.set('editing', true);
                this.clearInput();
            },

            cancelEdit() {
                this.set('editing', false);
                this.clearInput();
            },

            changeSubject() {
                const room = this.get('activeRoom');
                const subject = this.get('subjectInput');

                if (subject && subject.trim().length > 0) {
                    this.get('chat').setRoomSubject(room, subject, () => {
                        this.set('editing', false);
                        this.clearInput();
                    });
                } else {
                    this.focusInput();
                }
            },

            clearInput() {
                this.clearInput();
                this.focusInput();
            }
        },

        clearInput() {
            this.set('subjectInput', '');
        },

        focusInput() {
            _ember.default.run.scheduleOnce('afterRender', null, () => {
                var input = this.$('input.form-control');
                input.trigger('focus');
                input.get(0).select();
            });
        }
    });

    exports.default = ChatSubjectComponent;
});