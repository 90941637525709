define('ember-chat-components/services/roster/badge-tracking', ['exports', 'ember', 'ember-purecloud-components/utils/jid-helpers', 'ember-purecloud-components/mixins/logger'], function (exports, _ember, _jidHelpers, _logger) {
    'use strict';

    exports.__esModule = true;


    const { Mixin, inject, observer, RSVP, computed } = _ember.default;

    const ADD_WAKE_UP_LISTENER_FT = 'gcucc.ui.addChatWakeUpListener';

    exports.default = Mixin.create(_ember.default.Evented, _logger.default, {
        chat: inject.service(),
        favicon: inject.service(),
        application: inject.service(),
        pigeon: inject.service(),
        session: inject.service(),
        permissions: inject.service(),
        ajax: inject.service(),
        intl: inject.service(),

        // eslint-disable-next-line ember/no-observers
        _afterAuth: observer('pigeon.connected', function () {
            if (!this.get('pigeon.connected') || this.get('session.useCollaborateChat')) {
                return;
            }

            return this.getAllBadgeCounts().finally(() => this._setupCarrierPigeonListener());
        }),

        useWakeUpListener: computed('session.features', function () {
            const features = this.get('session.features');
            return features && features[ADD_WAKE_UP_LISTENER_FT];
        }),

        _setupCarrierPigeonListener() {
            const guid = this.get('session.person.guid');
            this.get('pigeon').registerPriorityTopic(`v2.users.${guid}.badges.chats`, 'badge-counts');
            this.get('pigeon').on('badge-counts', this, this.handleBadgeCountUpdate);
        },

        init() {
            this._super(arguments);
            this._afterAuth();

            if (this.get('useWakeUpListener') && !this.get('session.useCollaborateChat')) {
                this.addWakeUpListener();
            }
        },

        getAllBadgeCounts(currentPageNumber = 1, isRetry = false) {
            Object.keys(this.get('chat.rooms')).forEach(jid => {
                const room = this.get('chat.rooms')[jid];

                if (room.isOneToOneRoom()) {
                    room.set('unseen', 0);
                }
                room.set('mentions', 0);
            });

            const url = this.get('application').pcV2Uri(`api/v2/badges/chats?pageSize=100&pageNumber=${currentPageNumber}`);
            return this.get('ajax').ajaxGet(url).then(({ pageNumber, pageCount, entities = [] }) => {
                entities.forEach(badgeCount => this.handleBadgeCountUpdate(badgeCount));

                if (pageNumber < pageCount) {
                    return this.getAllBadgeCounts(currentPageNumber + 1);
                }
            }, err => {
                if (!isRetry) {
                    return this.getAllBadgeCounts(currentPageNumber, true);
                }

                return _ember.default.RSVP.reject(err);
            });
        },

        handleBadgeCountUpdate(badgeCount) {
            const jid = badgeCount.entity.jabberId || badgeCount.entity.jid;
            this.get('chat').getChatRoom(jid).then(room => {
                if (badgeCount.unreadCount > 0 && this.get('chat').isRoomVisible(room.get('jid'))) {
                    return this.clearRoomNotificationCounts(room);
                }

                if (room.isOneToOneRoom()) {
                    room.set('unseen', badgeCount.unreadCount);
                } else {
                    room.set('mentions', badgeCount.unreadCount);
                }

                this.updateBadges();
            });
        },

        clearRoomNotificationCounts(room, isRetry) {
            if (this.get('session.useCollaborateChat')) {
                return;
            }
            if (room.get('isSupervisorRoom')) {
                room.set('unseen', 0);
                return RSVP.resolve();
            }

            let url = this.get('application').pcV2Uri(`api/v2/badges/chats/${room.get('jid')}`);

            const currentUnseen = room.get('unseen');
            const currentMentions = room.get('mentions');

            // Eager clearing of the badges
            // It will reset if we hit an API error
            room.set('unseen', 0);
            room.set('mentions', 0);

            return this.get('ajax').request(url, { method: 'DELETE' }).then(() => {
                this.updateBadges();
            }).catch(err => {
                this.logger.error('BadgeTracking: error clearing room notifications', {
                    error: err
                });

                if (isRetry) {
                    room.set('unseen', currentUnseen);
                    room.set('mentions', currentMentions);
                    return RSVP.reject(err);
                }

                if (err.status === 429) {
                    return this.clearRoomNotificationCounts(room, true);
                }

                room.set('unseen', currentUnseen);
                room.set('mentions', currentMentions);

                return RSVP.reject(err);
            });
        },

        incrementUnreadGroupMessage(message, room) {
            const userJid = message.get('chatUser.jid');
            const roomJid = room.get('jid');
            if (!(0, _jidHelpers.compareJids)(userJid, this.get('session.person.jid')) && !this.get('chat').isRoomVisible(roomJid) && !(0, _jidHelpers.isAcdJid)(roomJid) && message.get('unread') && !message.get('corrects') && (!room.get('muted') || message.get('hasPersonalMention'))) {
                const roster = room.get('roster');
                const isSupervisorRoom = room.get('isSupervisorRoom');

                room.incrementProperty('unseen');

                if (!isSupervisorRoom) {
                    if (roster) {
                        roster.incrementProperty('unseenGroupMessages');
                    }
                }

                this.updateBadges();
            }
        },

        updateBadges() {
            if (!this.get('permissions.canViewBusinessChat')) {
                return;
            }
            const hostedContext = this.get('application.hostedContext');
            const unseen = this.get('unseenCount');
            const mentions = this.get('mentionsCount');

            let text = 0;

            if (mentions > 0) {
                hostedContext.setUnreadMessageCount(mentions);
                if (mentions > 9) {
                    text = this.get('intl').t('chat.faviconLots');
                } else {
                    text = this.get('intl').t('chat.faviconSome', { count: mentions });
                }
            } else if (unseen > 0) {
                hostedContext.setUnreadMessageCount(-1);
                text = ' ';
            } else {
                hostedContext.setUnreadMessageCount(0);
            }

            this.get('favicon').setFaviconBubbleText(text);
        },

        addWakeUpListener() {
            const PING_RATE = 2000;
            let lastSample = Date.now();

            // recursive function called every 2 seconds that keeps track of a time interval.
            // If not invoked at the expected time that indicates that the computer has just slept.
            const getSamplePeriod = () => {
                const wasSleeping = Date.now() - lastSample >= PING_RATE * 2;

                // Woke up just now? Call updateBadges() imperatively!
                if (wasSleeping) {
                    const date = new Date().toLocaleTimeString();

                    this.logger.debug(`addWakeUpListener: Computer was sleeping! Woke up at ${date}!`);

                    this.updateBadges();
                }
                lastSample = Date.now();
                setTimeout(getSamplePeriod, PING_RATE);
            };

            getSamplePeriod();
        }
    });
});