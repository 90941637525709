define('ember-cli-testing/utils/createServiceInitializer', ['exports', 'ember-cli-testing/utils/utils'], function (exports, _emberCliTestingUtilsUtils) {
    exports['default'] = createServiceInitializer;

    /**
    * Creates an initializer function for a service that can be used both at application startup and by a test
    * to inject a mock version of the service. This function is exported from its own file so that the
    * consuming application can import it in a prod build without importing all of the test helpers and their
    * dependencies.
    *
    * @param   {Object}            options
    * @param   {Ember.Service}     options.service      - The default service implementation
    * @param   {string}            options.fullName
    * @param   {string}            options.injectedName
    * @param   {Array.<string>}    options.injectInto   - The full names of the objects into which the object will be injected.
    * @returns {Function}          initialize
    */

    function createServiceInitializer(options) {
        var service = options.service;
        var fullName = options.fullName;
        var injectedName = options.injectedName;
        var injectInto = options.injectInto;

        /**
        * Injects the service into the specified targets so it's available as this[injectedName].
        * This initializer has additional properties that allow the test utility functions to
        * automatically generate and register a mock version of this service in tests.
        *
        * @param   {Ember.Container} container
        * @param   {Object}          [dependency] - Service implementation to inject.
        */
        function initialize(container) {
            var dependency = arguments.length <= 1 || arguments[1] === undefined ? service : arguments[1];

            (0, _emberCliTestingUtilsUtils.registerAndInject)({
                dependency: dependency,
                fullName: fullName,
                injectedName: injectedName,
                targets: injectInto,
                container: container
            });
        }

        Object.assign(initialize, options);
        return initialize;
    }
});