define('ember-custom-fields-components/models/field', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-custom-fields-components/utils/field-util'], function (exports, _attr, _model, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var gt = Ember.computed.gt;
    exports.default = _model.default.extend({
        order: (0, _attr.default)('number'),
        _disabled: (0, _attr.default)('boolean', { defaultValue: false }),
        propertyName: (0, _attr.default)('string'),
        key: (0, _attr.default)('string'),
        type: (0, _attr.default)('string'),
        title: (0, _attr.default)('string'),
        description: (0, _attr.default)('string', { defaultValue: '' }),
        value: (0, _attr.default)(),

        icon: Ember.computed('type', function () {
            var type = this.get('type');
            var config = (0, _fieldUtil.fieldConfig)(type);
            return config.icon || '';
        }),

        isNewField: false,

        isEnabled: Ember.computed('_disabled', function () {
            var disabled = this.get('_disabled');

            if (Ember.isNone(disabled)) {
                return true;
            }

            return disabled === false;
        }),

        isTitleValid: gt('title.length', 0),
        isKeyValid: Ember.computed('key', function () {
            var key = this.get('key');
            return key && !_fieldUtil.IS_NOT_VALID_KEY.test(key);
        }),

        isValid: true, // Somewhere is trying to set isValid, which is a read-only property on ember-data-model
        isConfigValid: Ember.computed('isTitleValid', 'isKeyValid', function () {
            return this.get('isTitleValid') && this.get('isKeyValid');
        }),

        fieldKey: Ember.observer('title', 'propertyName', function () {
            var propertyName = this.get('propertyName'),
                title = this.get('title'),
                type = this.get('type');

            if (propertyName) {
                this.set('key', propertyName);
            } else if (title && type) {
                this.set('key', (0, _fieldUtil.getFieldKey)(title, type));
            }
        })
    });
});