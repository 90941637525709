define("ember-purecloud-components/components/entity-v2/image/component", ["exports", "ember-purecloud-components/components/entity-v2/image/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isNameLatin = /^[\w\s'"-,.]+$/i;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2-image'],
    classNameBindings: ['entityIsContact:external-contact'],
    entity: null,
    size: 100,
    applicationUrlPrefix: '',
    showStatusQuote: false,
    showPresence: false,
    imageLoadError: false,
    _currentpresenceClass: null,
    _animationTimer: null,
    displayStatusQuote: Ember.computed.and('showStatusQuote', 'entity.presence.message'),
    presenceClass: Ember.computed('entity.outOfOffice', 'entity.presence.presenceDefinition.systemPresence', function () {
      var presence = this.getWithDefault('entity.presence.presenceDefinition.systemPresence', 'offline');
      var oooActive = this.get('entity.outOfOffice.active');
      return oooActive ? 'out_of_office' : presence && Ember.String.underscore(presence.toLowerCase());
    }),
    placeholderImagesMap: Ember.computed('applicationUrlPrefix', function () {
      var prefix = this.get('applicationUrlPrefix') || '/';
      var baseUri = "".concat(prefix, "assets/images/svg");
      return {
        person: "".concat(baseUri, "/person.svg"),
        group: "".concat(baseUri, "/group.svg"),
        location: "".concat(baseUri, "/location.svg"),
        floor: "".concat(baseUri, "/floor.svg"),
        queue: "".concat(baseUri, "/queue.svg")
      };
    }),
    placeholderImage: Ember.computed('entity.type', 'placeholderImagesMap', function () {
      var type = this.get('entity.type') || 'person';
      return this.get("placeholderImagesMap.".concat(type));
    }),
    imageUrl: Ember.computed('entity', 'entity.images', function () {
      var profilePictures = this.get('entity.images');

      if (profilePictures && profilePictures.length) {
        profilePictures.sort(function (a, b) {
          return a.resolution.slice(1) - b.resolution.slice(1);
        });
        return profilePictures[profilePictures.length - 1].imageUri;
      }

      return this.get('placeholderImage');
    }),
    nameInitials: Ember.computed('initialsPlaceholderSupported', 'entity.name', function () {
      var name = this.get('entity.name').trim();

      if (this.get('initialsPlaceholderSupported') && name) {
        var commaIndex = name.indexOf(',') > -1;
        var spaceIndex = name.indexOf(' ') > -1;
        var leftInitial = name[0].toUpperCase();
        var rightInitial = name.charAt(name.indexOf(' ') + 1).toUpperCase();

        if (commaIndex) {
          return rightInitial + leftInitial;
        } else if (!spaceIndex) {
          return leftInitial;
        } else {
          return leftInitial + rightInitial;
        }
      }

      return null;
    }),
    linkToProfileRoute: Ember.computed('entity.type', function () {
      if (this.get('entity.type') === 'group') {
        return "#/group/".concat(this.get('entity.id'));
      }

      return "#/person/".concat(this.get('entity.id'));
    }),
    initialsPlaceholderSupported: Ember.computed('entity.name', 'entity.type', function () {
      var name = this.get('entity.name');

      if (name) {
        return isNameLatin.test(name) || this.get('entity.type') === 'externalContact';
      }

      return false;
    }),
    isPlaceholderImageAndUseInitials: Ember.computed('useInitialsPlaceholder', 'isPlaceholderImage', 'nameInitials', function () {
      return this.get('useInitialsPlaceholder') && !!this.get('nameInitials');
    }),
    entityIsContact: Ember.computed('entity.type', function () {
      return this.get('entity.type') === 'externalContact';
    }),
    entityDeleted: Ember.computed('entity.state', function () {
      var state = this.get('entity.state');
      return state && state.toLowerCase() === 'deleted';
    }),
    handleImageError: function handleImageError(e) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.set('useInitialsPlaceholder', true);
    },
    didUpdate: function didUpdate() {
      var _this = this;

      if (this.get('presenceClass') !== this.get('_currentPresenceClass')) {
        if (this.get('runStatusChangeAnimation')) {
          this.set('runStatusChangeAnimation', false);
          Ember.run.cancel(this.get('_animationTimer'));
        }

        this.set('runStatusChangeAnimation', true);
        this.set('_animationTimer', Ember.run.later(this, function () {
          _this.set('runStatusChangeAnimation', false);
        }, 500));
      }

      this.set('_currentPresenceClass', this.get('presenceClass'));
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.get('_animationTimer'));
    }
  });

  _exports.default = _default;
});