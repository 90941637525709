define("ember-webrtc-components/mixins/video-element-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var observer = Ember.observer,
      run = Ember.run,
      computed = Ember.computed;

  var _default = Ember.Mixin.create({
    previousCameraStream: null,
    previousCameraTrack: null,
    previousAudioTrack: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.get('webrtc').on('defaultDeviceChange', this, this._handleDeviceChange);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('webrtc').off('defaultDeviceChange', this, this._handleDeviceChange);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._attachCameraStream();

      this._attachCameraTrack();

      this._attachAudioTrack();

      this._attachScreenStream();
    },
    useTrackBasedActions: computed.reads('conversation.useTrackBasedActions'),
    _getCameraElement: function _getCameraElement() {
      var $video = this.$('video.camera');
      return $video && $video.length ? $video[0] : null;
    },
    _getAudioElement: function _getAudioElement() {
      var $audio = this.$('audio.audio');
      return $audio && $audio.length ? $audio[0] : null;
    },
    _createNewStreamWithTrack: function _createNewStreamWithTrack(track) {
      var newStream = new MediaStream();
      newStream.addTrack(track);
      return newStream;
    },
    _removeSrcFromElement: function _removeSrcFromElement($element) {
      this.$($element).attr('src', '');
    },
    // eslint-disable-next-line ember/no-observers
    _attachCameraStream: observer('participant.cameraStream', function () {
      var _this = this;

      if (this.get('useTrackBasedActions') || this.isDestroyed || this.isDestroying) {
        return;
      }

      run.scheduleOnce('afterRender', this, function () {
        var cameraVideoEl = _this._getCameraElement();

        if (!cameraVideoEl) {
          run.next(_this, _this._attachCameraStream);
          return;
        }

        if (_this.get('previousCameraStream') && !_this.get('participant.cameraStream')) {
          _this.set('previousCameraStream', null);

          _this._removeSrcFromElement(cameraVideoEl);

          return;
        }

        if (_this.get('previousCameraStream') === _this.get('participant.cameraStream')) {
          return;
        }

        _this.set('previousCameraStream', _this.get('participant.cameraStream'));

        var options = {};

        if (_this.get('alwaysMuteAudio')) {
          options.muted = true;
        }

        if (_this.get('participant.local')) {
          options = {
            muted: true,
            mirror: true
          };
        }

        _this.get('webrtc').attachMediaStream(_this.get('participant.cameraStream'), cameraVideoEl, options);

        _this._handleDeviceChange();
      });
    }),
    // eslint-disable-next-line ember/no-observers
    _attachCameraTrack: observer('participant.cameraTrack', function () {
      var _this2 = this;

      if (!this.get('useTrackBasedActions') || this.isDestroyed || this.isDestroying) {
        return;
      }

      run.scheduleOnce('afterRender', this, function () {
        var cameraVideoEl = _this2._getCameraElement();

        if (!cameraVideoEl) {
          run.next(_this2, _this2._attachCameraTrack);
          return;
        }

        if (_this2.get('previousCameraTrack') && !_this2.get('participant.cameraTrack')) {
          _this2.set('previousCameraTrack', null);

          _this2._removeSrcFromElement(cameraVideoEl);

          return;
        }

        if (!_this2.get('participant.cameraTrack') || _this2.get('previousCameraTrack') === _this2.get('participant.cameraTrack')) {
          return;
        }

        _this2.set('previousCameraTrack', _this2.get('participant.cameraTrack'));

        var options = {};

        if (_this2.get('alwaysMuteAudio')) {
          options.muted = true;
        }

        if (_this2.get('participant.local')) {
          options = {
            muted: true,
            mirror: true
          };
        }

        var newStream = _this2._createNewStreamWithTrack(_this2.get('participant.cameraTrack'));

        _this2.get('webrtc').attachMediaStream(newStream, cameraVideoEl, options);

        _this2._handleDeviceChange();
      });
    }),
    // eslint-disable-next-line ember/no-observers
    _attachAudioTrack: observer('participant.audioTrack', function () {
      var _this3 = this;

      if (!this.get('useTrackBasedActions') || this.isDestroyed || this.isDestroying || this.get('participant.local') || this.get('alwaysMuteAudio')) {
        return;
      }

      run.scheduleOnce('afterRender', this, function () {
        var audioEl = _this3._getAudioElement();

        if (!audioEl) {
          run.next(_this3, _this3._attachAudioTrack);
          return;
        }

        if (_this3.get('previousAudioTrack') && !_this3.get('participant.audioTrack')) {
          _this3.set('previousAudioTrack', null);

          _this3._removeSrcFromElement(audioEl);

          return;
        }

        if (!_this3.get('participant.audioTrack') || _this3.get('previousAudioTrack') === _this3.get('participant.audioTrack')) {
          return;
        }

        _this3.set('previousAudioTrack', _this3.get('participant.audioTrack'));

        var options = {};

        var newStream = _this3._createNewStreamWithTrack(_this3.get('participant.audioTrack'));

        _this3.get('webrtc').attachMediaStream(newStream, audioEl, options);

        _this3._handleDeviceChange();
      });
    }),
    _getScreenElement: function _getScreenElement() {
      var $video = this.$('video.screen');
      return $video && $video.length ? $video[0] : null;
    },
    // eslint-disable-next-line ember/no-observers
    _attachScreenStream: observer('participant.screenStream', function () {
      var _this4 = this;

      run.scheduleOnce('afterRender', this, function () {
        if (!_this4.get('participant.screenStream')) {
          if (_this4.get('useTrackBasedActions')) {
            _this4._attachCameraTrack();
          } else {
            _this4._attachCameraStream();
          }

          return;
        }

        var screenVideoEl = _this4._getScreenElement();

        if (!screenVideoEl) {
          run.next(_this4, _this4._attachScreenStream);
          return;
        }

        var options = null;

        if (_this4.get('participant.local')) {
          options = {
            muted: true
          };
        }

        _this4.get('webrtc').attachMediaStream(_this4.get('participant.screenStream'), screenVideoEl, options);
      });
    }),
    _handleDeviceChange: function _handleDeviceChange(devices) {
      if (!devices || !devices.outputDevice) {
        return; // no need to handle a change for a different device type
      }

      var outputDevice = devices.outputDevice;

      if (outputDevice) {
        var webrtc = this.get('webrtc');

        if (this.get('useTrackBasedActions')) {
          webrtc.setOutputDevice(this._getAudioElement(), outputDevice);
        } else {
          webrtc.setOutputDevice(this._getCameraElement(), outputDevice);
        }
      }
    }
  });

  _exports.default = _default;
});