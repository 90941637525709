define("ember-purecloud-components/utils/dates", ["exports", "moment", "moment-timezone"], function (_exports, _moment, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayDateRelativeToToday = displayDateRelativeToToday;
  _exports.generateCurrentInterval = generateCurrentInterval;
  _exports.splitIntervalString = splitIntervalString;
  _exports.timeInMillis = timeInMillis;
  _exports.tz = void 0;

  function _decideFormat(date, today) {
    if (today.isSame(date, 'day')) {
      return 'LT'; // 1:32pm
    } else if (today.isSame(date, 'week')) {
      return 'ddd LT'; // Thu 11:07 AM
    } else {
      return 'lll'; // Jan 22, 2015 11:06 AM
    }
  }

  function displayDateRelativeToToday(rawDate, locale) {
    var date = (0, _momentTimezone.default)(rawDate);
    var today = (0, _momentTimezone.default)();

    var formatString = _decideFormat(date, today);

    return date.locale(locale).format(formatString);
  }

  function generateCurrentInterval() {
    var start = (0, _momentTimezone.default)();
    var end = (0, _momentTimezone.default)();

    if (start.minutes() < 30) {
      start.startOf('hour');
      end = (0, _momentTimezone.default)(start).minutes(30).startOf('minute');
    } else {
      start.minutes(30).startOf('minute');
      end = (0, _momentTimezone.default)(start).add(1, 'hours').startOf('hour');
    }

    return start.toISOString() + '/' + end.toISOString();
  }

  function splitIntervalString(intervalStr) {
    if (typeof intervalStr === 'string') {
      return intervalStr.split('/');
    }

    return null;
  }

  function timeInMillis(dateString) {
    return (0, _momentTimezone.default)(dateString).valueOf();
  } // Save the current moment locale because defineLocale and updateLocale change the active locale for some reason


  var currentLocale = _momentTimezone.default.locale(); // Moment does not support 'no' (Norwegian) directly, so add it as an alias for 'nb' (Norwegian Bokmal) which is supported


  _momentTimezone.default.defineLocale('no', {
    parentLocale: 'nb'
  }); // Behavior changes requested by our localization team


  _momentTimezone.default.updateLocale('ko', {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'YYYY-MM-DD',
      LLL: 'YYYY년 MMMM D일 HH:mm',
      LLLL: 'YYYY년 MMMM D일 dddd HH:mm',
      lll: 'YYYY년 MMMM D일 HH:mm',
      llll: 'YYYY년 MMMM D일 dddd HH:mm'
    }
  });

  _momentTimezone.default.updateLocale('ja', {
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
  });

  _momentTimezone.default.updateLocale('zh-cn', {
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
  });

  _momentTimezone.default.updateLocale('zh-tw', {
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    longDateFormat: {
      L: 'YYYY-MM-DD'
    }
  });

  _momentTimezone.default.updateLocale('pl', {
    months: ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień']
  });

  _momentTimezone.default.locale(currentLocale);

  var _default = _momentTimezone.default;
  _exports.default = _default;
  var tz = _momentTimezone.default;
  _exports.tz = tz;
});