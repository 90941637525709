define('ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/mixins/dropzone', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;


    const { run, observer, computed, Mixin } = _ember.default;

    const dropzonePreviewTemplate = `
<div class="dz-preview dz-file-preview">
    <span class="delete-thumbnail">
        <i class="ion-close-circled"></i>
    </span>
    <div class="dz-details">
        <img data-dz-thumbnail />
    </div>
</div>
`;

    exports.default = Mixin.create({
        dropzone: null,
        dropzoneFile: null,
        dropzoneMessage: null,
        fileUploading: null,
        hasPendingFile: false,

        didInsertElement() {
            this._super(...arguments);
            run.scheduleOnce('afterRender', this, this.setupDropzone);
        },

        willDestroyElement() {
            this._super(...arguments);
            this.destroyDropzone();
        },

        roomHasUpload: computed('fileUploading', 'activeRoom.jid', function () {
            return this.get('fileUploading') === this.get('activeRoom.jid') && this.get('activeRoom.jid') !== null;
        }),

        dropzoneTarget: computed('activeRoom.jid', function () {
            return `/realtime/v1/files/${this.get('activeRoom.jid')}`;
        }),

        // eslint-disable-next-line ember/no-observers
        purgeUnsentFilesOnSwitch: observer('activeRoom.jid', function () {
            if (this.get('fileUploading')) {
                return;
            }
            const dropzone = this.get('dropzone');
            while (dropzone && dropzone.files.length > 0) {
                dropzone.removeFile(dropzone.files[0]);
            }
            this.set('hasPendingFile', false);
        }),

        // eslint-disable-next-line ember/no-observers
        pointDropzoneAtActiveRoom: observer('dropzoneTarget', function () {
            if (!this.get('disableFileUploads')) {
                run.scheduleOnce('afterRender', () => {
                    const dropzone = this.get('dropzone');
                    if (dropzone && dropzone.options) {
                        dropzone.options.url = this.get('dropzoneTarget');
                    }
                });
            }
        }),

        addThumbnail() {
            this.set('hasPendingFile', true);
            this.$('.delete-thumbnail').on('click', _ember.default.run.bind(this, () => {
                this.removeThumbnail();
                this.get('dropzone').removeAllFiles();
            }));
        },

        removeThumbnail() {
            this.set('hasPendingFile', false);
        },

        setupDropzone() {
            if (this.get('disableFileUploads')) {
                return;
            }

            // TODO: This is a fragile way to attach dropdown to the whole conversation
            // and precludes having file uploads in the 'inline-response' flow.
            const target = this.$().parent('.conversation-pane').get(0) || this.$().get(0);

            const dropzone = new window.Dropzone(target, {
                url: this.get('dropzoneTarget'),
                clickable: `#${target.id} .upload-document-realtime`,
                maxFilesize: 50,
                maxFiles: 1,
                autoProcessQueue: false,
                previewsContainer: this.$('.input-group-preview')[0],
                previewTemplate: dropzonePreviewTemplate,
                createImageThumbnails: true,
                headers: {
                    Authorization: `bearer ${this.get('chat.session.accessToken')}`
                }
            });

            dropzone.on('addedfile', event => {
                _ember.default.run(() => {
                    const length = dropzone.files.length;
                    if (event.size < 52428800) {
                        if (length > 1) {
                            dropzone.removeFile(dropzone.files[0]);
                        }

                        this.set('dropzoneFile', event.name);
                        this.addThumbnail();
                        this.focusTextarea();

                        run.scheduleOnce('afterRender', () => {
                            if (!event.type.includes('image')) {
                                const prefix = this.get('application.urlprefix');
                                const img = this.$('.input-group-preview img');
                                img.attr('class', 'generic-file-image');
                                img.attr('src', `${prefix}assets/images/icons/png/file-text.png`);
                            }
                        });
                    } else {
                        dropzone.removeFile(dropzone.files[length - 1]);
                        this.get('notification').show({
                            title: this.get('intl').t('chat.fileSizeExceededTitle'),
                            message: this.get('intl').t('chat.fileSizeExceeded'),
                            hideAfter: 8,
                            forceBrowserNotification: true
                        });
                    }
                });
            });

            dropzone.on('sending', () => {
                _ember.default.run(() => {
                    this.set('fileUploading', this.get('activeRoom.jid'));
                    this.$('.upload-document-realtime').attr('disabled', 'disabled');
                });
            });

            dropzone.on('success', () => {
                _ember.default.run(() => {
                    this.get('chat').getChatRoom(this.get('fileUploading')).then(room => {
                        this.$('.upload-document-realtime').removeAttr('disabled');
                        this.set('fileUploading', null);
                        dropzone.removeAllFiles(false);
                        this.propagateMessageEvent(this.get('dropzoneMessage'), room);
                        this.set('dropzoneMessage', null);
                    });
                });
            });

            dropzone.on('error', () => {
                _ember.default.run(() => {
                    this.$('.upload-document-realtime').removeAttr('disabled');
                    this.set('fileUploading', null);
                    dropzone.removeAllFiles();
                });
            });

            this.set('dropzone', dropzone);
        },

        destroyDropzone() {
            const dropzone = this.get('dropzone');
            if (dropzone) {
                dropzone.destroy();
            }
        }
    });
});