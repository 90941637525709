define('ember-intl/helpers/-format-base', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Helper = _ember['default'].Helper;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var getWithDefault = _ember['default'].getWithDefault;

  var assign = _ember['default'].assign || _ember['default'].merge;

  function helperFactory(formatType) {
    var helperOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    return Helper.extend({
      intl: inject.service(),
      formatType: formatType,

      formatter: computed('formatType', {
        get: function get() {
          return (0, _emberGetownerPolyfill['default'])(this).lookup('ember-intl@formatter:format-' + formatType);
        }
      }).readOnly(),

      init: function init() {
        this._super.apply(this, arguments);

        get(this, 'intl').on('localeChanged', this, this.recompute);
      },

      getValue: function getValue(_ref) {
        var _ref2 = _slicedToArray(_ref, 1);

        var value = _ref2[0];

        return value;
      },

      compute: function compute(params) {
        var hash = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        var value = this.getValue(params, hash);
        var allowEmpty = getWithDefault(hash, 'allowEmpty', helperOptions.allowEmpty);

        if (isEmpty(value)) {
          if ('fallback' in hash) {
            return hash.fallback;
          } else if (allowEmpty) {
            return;
          } else if (typeof value === 'undefined') {
            throw new Error('format-' + formatType + ' helper requires value');
          }
        }

        var intl = get(this, 'intl');
        var format = {};

        if (hash.format) {
          format = intl.getFormat(formatType, hash.format);
        }

        return get(this, 'formatter').format(value, assign(assign({}, format), hash), {
          formats: get(intl, 'formats'),
          locale: hash.locale || get(intl, '_locale')
        });
      },

      destroy: function destroy() {
        this._super.apply(this, arguments);

        get(this, 'intl').off('localeChanged', this, this.recompute);
      }
    });
  }

  exports['default'] = helperFactory;
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */