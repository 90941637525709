define("ember-webrtc-components/components/webrtc-issues/component", ["exports", "ember-webrtc-components/components/webrtc-issues/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    layout: _template.default,
    webrtc: inject.service()
  });

  _exports.default = _default;
});