define("ember-purecloud-components/models/recording", ["exports", "ember-data", "moment", "ember-purecloud-components/mixins/logger"], function (_exports, _emberData, _moment, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var thirtyDaysFromNow = function thirtyDaysFromNow() {
    return (0, _moment.default)().add(30, 'days').toISOString();
  };

  var _default = _emberData.default.Model.extend(_logger.default, {
    name: _emberData.default.attr(),
    conversation: _emberData.default.belongsTo('conversation'),
    path: _emberData.default.attr(),
    startTime: _emberData.default.attr(),
    endTime: _emberData.default.attr(),
    originalRecordingStartTime: _emberData.default.attr(),
    media: _emberData.default.attr(),
    annotations: _emberData.default.attr(),
    transcript: _emberData.default.attr(),
    emailTranscript: _emberData.default.attr(),
    fileState: _emberData.default.attr(),
    restoreExpirationTime: _emberData.default.attr(),
    mediaUris: _emberData.default.attr(),
    estimatedTranscodeTimeMs: _emberData.default.attr(),
    actualTranscodeTimeMs: _emberData.default.attr(),
    archiveDate: _emberData.default.attr(),
    archiveMedium: _emberData.default.attr(),
    deleteDate: _emberData.default.attr(),
    maxAllowedRestorationsForOrg: _emberData.default.attr(),
    remainingRestorationsAllowedForOrg: _emberData.default.attr(),
    users: _emberData.default.attr(),
    creationTime: _emberData.default.attr(),
    isArchived: Ember.computed.equal('fileState', 'ARCHIVED'),
    isDeleted: Ember.computed.equal('fileState', 'DELETED'),
    isRestored: Ember.computed.equal('fileState', 'RESTORED'),
    isRestoring: Ember.computed.equal('fileState', 'RESTORING'),
    isAvailable: Ember.computed.equal('fileState', 'AVAILABLE'),
    isViewable: Ember.computed.or('isAvailable', 'isRestored'),

    /**
     * restore - Restore a recording
     *
     * @param {string} archiveDate ISO date for recording re-archival, 30 day default.
     *
     * @returns {Promise}
     */
    restore: function restore() {
      var archiveDate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : thirtyDaysFromNow();
      var isValid = (0, _moment.default)(archiveDate, _moment.default.ISO_8601).isValid();

      if (isValid) {
        Ember.setProperties(this, {
          archiveDate: archiveDate
        });
        return this.save();
      } else {
        var reason = 'Attempted to restore a recording with an invalid date string';
        this.logger.debug(reason, {
          archiveDate: archiveDate
        });
        return Ember.RSVP.reject(reason);
      }
    },

    /**
     * download - Download a recording.
     *
     * @returns {Promise}
     */
    download: function download() {
      var _this = this;

      var conversationId = Ember.get(this, 'conversation.id');
      var id = Ember.get(this, 'id');
      return this.store.queryRecord('recording', {
        id: id,
        conversationId: conversationId,
        download: true
      }).then(function (res) {
        var media = Ember.get(res, 'media'); // audio recordings have two channels that get merged together
        // when downloading.  The merged mediaUri is keyed under
        // 'S' (presumably for 'S'ingle channel/file)

        var key = media && media === 'audio' ? 'S' : '0';
        var downloadUrl = Ember.get(res, "mediaUris.".concat(key, ".mediaUri"));

        _this._setLocation(downloadUrl);

        return downloadUrl;
      }).catch(function (err) {
        Ember.get(_this, 'logger').error('RECORDING: Error downloading recording', {
          id: id,
          err: err,
          remoteData: {
            conversationId: conversationId
          }
        });
        throw err;
      });
    },

    /**
     * downloadAllMediaUris - Download all mediaUris of a recording.
     *
     * @returns {Promise}
     */
    downloadAllMediaUris: function downloadAllMediaUris() {
      var _this2 = this;

      var conversationId = Ember.get(this, 'conversation.id');
      var id = Ember.get(this, 'id');
      return this.store.queryRecord('recording', {
        id: id,
        conversationId: conversationId,
        download: true
      }).then(function (res) {
        var downloadUrls = [];

        for (var _i = 0, _Object$values = Object.values(Ember.get(res, 'mediaUris')); _i < _Object$values.length; _i++) {
          var mediaUriObject = _Object$values[_i];
          var downloadUrl = mediaUriObject.mediaUri;
          downloadUrls.push(downloadUrl);

          _this2._setLocInNewIFrame(downloadUrl);
        }

        return downloadUrls;
      }).catch(function (err) {
        Ember.get(_this2, 'logger').error('RECORDING: Error downloading recording', {
          id: id,
          err: err,
          remoteData: {
            conversationId: conversationId
          }
        });
      });
    },

    /**
     * _setLocation - Sets window location.
     *
     * @param {string} loc URL of target.
     *
     * @returns {undefined}
     */
    _setLocation: function _setLocation(loc) {
      window.location = loc;
    },

    /**
     * _setLocInNewIFrame - Builds a new hidden IFrame for the target location.
     *
     * @param {string} loc URL of target.
     *
     * @returns {undefined}
     */
    _setLocInNewIFrame: function _setLocInNewIFrame(loc) {
      var iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.src = loc; // The hidden IFrame cleans itself up after 60 seconds, preventing it
      // from lingering on the DOM indefinitely

      setTimeout(function () {
        return document.body.removeChild(iframe);
      }, 60000);
    }
  });

  _exports.default = _default;
});