define('ember-chat-components/services/chat-commands', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Service.extend(_ember.default.Evented, {
        session: _ember.default.inject.service(),

        init() {
            this._super(...arguments);

            this.set('commands', _ember.default.A([]));
            this.set('queuedChatCommands', _ember.default.A([]));
        },

        addCommand(name, value, help = '', hidden = false) {
            let commands = this.get('commands');
            let existingCommand = commands.find(command => command.name === name);

            _ember.default.assert('A chat command with that name already exists', !existingCommand);
            _ember.default.assert('A value hook function is required', !!value);

            if (this.get('session.useCollaborateChat')) {
                this.get('queuedChatCommands').addObject({
                    name,
                    value,
                    help,
                    hidden: !hidden
                });
            }

            this.get('commands').addObject({
                name,
                value,
                help,
                hidden
            });
        },

        emptyQueuedCommands() {
            this.set('queuedChatCommands', _ember.default.A([]));
        }
    });
});