define('ember-softphone-integration/components/headset-status/component', ['exports', 'ember-softphone-integration/components/headset-status/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend({
    layout: _template.default,
    headset: inject.service(),
    intl: inject.service(),
    handleVendorChange: null,

    implementation: computed.reads('headset.selectedImplementation'),

    connectionStatusText: computed('implementation.{isConnecting,isConnected}', function () {
      var key = void 0;
      if (this.get('implementation.errorCode')) {
        key = 'esi.implErrorCode.' + this.get('implementation.errorCode');
      } else if (this.get('implementation.isConnecting')) {
        key = 'esi.connectionStatus.checking';
      } else if (this.get('implementation.isConnected')) {
        key = 'esi.connectionStatus.running';
      } else {
        key = 'esi.connectionStatus.notRunning';
      }

      return this.get('intl').lookup(key);
    }),

    showRetry: computed('implementation.{isConnecting,isConnected,disableRetry}', function () {
      var impl = this.get('implementation');
      if (impl.get('disableRetry')) {
        return false;
      }
      return impl && !impl.get('isConnecting') && !impl.get('isConnected');
    }),

    actions: {
      retryConnection: function retryConnection() {
        this.get('implementation').connect();
      }
    }
  });
});