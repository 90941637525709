define("ember-purecloud-components/models/communication", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // ----------------------------------------------------------------------------
    // Raw Properties
    // ----------------------------------------------------------------------------
    participant: _emberData.default.belongsTo('participant', {
      async: false
    }),
    state: _emberData.default.attr(),
    connectedTime: _emberData.default.attr(),
    provider: _emberData.default.attr(),
    additionalProperties: _emberData.default.attr(),
    segments: _emberData.default.attr(),
    update: function update(comUpdate) {
      var updatableAttrs = ['state', 'connectedTime', 'additionalProperties', 'provider'];
      var updateProperties = {};

      for (var _i = 0, _updatableAttrs = updatableAttrs; _i < _updatableAttrs.length; _i++) {
        var updatable = _updatableAttrs[_i];
        var updatedValue = comUpdate[updatable];

        if (typeof updatedValue !== 'undefined') {
          updateProperties[updatable] = updatedValue;
        }
      }

      this.setProperties(updateProperties);
    },
    conversationId: Ember.computed.reads('participant.conversation.id')
  });

  _exports.default = _default;
});