define("webrtc-troubleshoot/components/flashy-webrtc-troubleshooter/component", ["exports", "webrtc-troubleshoot/components/flashy-webrtc-troubleshooter/template", "webrtc-troubleshoot/components/webrtc-troubleshooter/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LOGLEVELS = ['debug', 'info', 'log', 'warn', 'error'];
  var logBuffer = [];
  var Logger = {};
  LOGLEVELS.forEach(function (level) {
    Logger[level] = function () {
      var _Ember$Logger;

      var textarea = document.getElementsByTagName('textarea')[0];
      var args = Array.prototype.slice.call(arguments);
      var toBuffer = [];
      args.forEach(function (a) {
        var toLog = a;

        if (a instanceof Error) {
          toLog = {
            message: a.message,
            details: a.details,
            name: a.name,
            status: a.status
          };
        }

        toBuffer.push(toLog);
      });
      logBuffer.push([level, toBuffer]);

      if (textarea) {
        textarea.value = JSON.stringify(logBuffer, null, 2);
      }

      (_Ember$Logger = Ember.Logger)[level].apply(_Ember$Logger, arguments);
    };
  });

  var download = function download(filename) {
    var element = document.createElement('a');
    var text = document.getElementsByTagName('textarea')[0].value;
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['flashy-webrtc-troubleshooter'],
    saveSuiteToWindow: true,
    init: function init() {
      if (!this.get('logger')) {
        this.set('logger', Logger);
      }

      this._super.apply(this, arguments);
    },
    actions: {
      downloadDiagnosticReport: function downloadDiagnosticReport() {
        download('purecloud-diagnostics-' + new Date().getTime() + '.json');
      }
    }
  });

  _exports.default = _default;
});