define("ember-purecloud-components/utils/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidEmailAddress = isValidEmailAddress;
  _exports.splitOnSpacesOrComma = splitOnSpacesOrComma;

  /* eslint no-useless-escape: off */
  function isValidEmailAddress(emailStr) {
    var supportSingleDomain = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var defaultEmailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var singleDomainSupportRe = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{1,})$/;
    var emailRe = supportSingleDomain ? singleDomainSupportRe : defaultEmailRe;
    var emailAddresses = splitOnSpacesOrComma(emailStr);

    if (emailAddresses.length === 0) {
      return false;
    }

    return emailAddresses.every(function (emailAddress) {
      return emailRe.test(emailAddress);
    });
  }

  function splitOnSpacesOrComma(emails) {
    if (!emails) {
      return [];
    }

    var emailsRe = /[ ,]+/;
    return emails.split(emailsRe).filter(function (str) {
      return str.length !== 0;
    });
  }
});