define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/tag/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/tag/template', 'ember-engine-custom-fields/mixins/toggle-field-panel', 'ember-engine-custom-fields/mixins/min-max-value', 'ember-custom-fields-components/utils/field-util'], function (exports, _template, _toggleFieldPanel, _minMaxValue, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject,
        isEmpty = Ember.isEmpty,
        getProperties = Ember.getProperties;
    var alias = computed.alias;
    exports.default = Component.extend(_toggleFieldPanel.default, _minMaxValue.default, {
        layout: _template.default,
        classNames: ['field field-tag'],

        limits: inject.service(),

        minMin: alias('limits.coreTypeLimits.tag.minItems.min'),
        minMax: alias('limits.coreTypeLimits.tag.minItems.max'),
        maxMin: alias('limits.coreTypeLimits.tag.maxItems.min'),
        maxMax: alias('limits.coreTypeLimits.tag.maxItems.max'),

        field: null,

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            if (isEmpty(this.get('field.minItems'))) {
                this.set('field.minItems', this.get('minItemsDefault'));
                this.notifyPropertyChange('field');
            }

            if (isEmpty(this.get('field.maxItems'))) {
                this.set('field.maxItems', this.get('maxItemsDefault'));
                this.notifyPropertyChange('field');
            }
        },


        minItemsDefault: computed('field.type', function () {
            return (0, _fieldUtil.fieldConfig)(this.get('field.type')).minItemsDefault;
        }),

        maxItemsDefault: computed('field.type', function () {
            return (0, _fieldUtil.fieldConfig)(this.get('field.type')).maxItemsDefault;
        }),

        hasMinItemsErrorMessage: computed('field.{isMinItemsValid,isNewMinItemsLessRestrictive}', 'minValueWithinLimits', function () {
            var _getProperties = getProperties(this.get('field'), 'isMinItemsValid', 'isNewMinItemsLessRestrictive'),
                isMinItemsValid = _getProperties.isMinItemsValid,
                isNewMinItemsLessRestrictive = _getProperties.isNewMinItemsLessRestrictive;

            var minValueWithinLimits = this.get('minValueWithinLimits');

            return !isMinItemsValid || !isNewMinItemsLessRestrictive || !minValueWithinLimits;
        }),
        hasMaxItemsErrorMessage: computed('field.{isMaxItemsValid,isNewMaxItemsLessRestrictive}', 'maxValueWithinLimits', function () {
            var _getProperties2 = getProperties(this.get('field'), 'isMaxItemsValid', 'isNewMaxItemsLessRestrictive'),
                isMaxItemsValid = _getProperties2.isMaxItemsValid,
                isNewMaxItemsLessRestrictive = _getProperties2.isNewMaxItemsLessRestrictive;

            var maxValueWithinLimits = this.get('maxValueWithinLimits');

            return !isMaxItemsValid || !isNewMaxItemsLessRestrictive || !maxValueWithinLimits;
        })
    });
});