define("ember-purecloud-components/utils/debug-backburner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debugBackburner = debugBackburner;
  _exports.disableBackburnerDebugging = disableBackburnerDebugging;
  _exports.logTimerQueue = logTimerQueue;
  var _backburnerDebugTimeoutFn = Ember.run.backburner._setTimeout;

  function secondsFromNow(timeMillis) {
    return (timeMillis - new Date().getTime()) / 1000;
  }

  function debugBackburner(config) {
    if (config && Ember.get(config, 'backburnerDebug.showStacks')) {
      console.log('showing backburner stacks');

      Ember.run.backburner._setTimeout = function (fn, executeAt) {
        var secondsToPop = secondsFromNow(executeAt);

        if (secondsToPop > config.backburnerDebug.showTimersSecs) {
          console.log("Backburner Executing Timer ".concat(secondsToPop, " seconds from now at ").concat(executeAt));
          console.trace();
        }

        return _backburnerDebugTimeoutFn.apply(Ember.run.backburner, arguments);
      };
    }

    return true;
  }

  function disableBackburnerDebugging() {
    Ember.run.backburner._setTimeout = _backburnerDebugTimeoutFn;
    return true;
  }

  function logTimerQueue() {
    var timesList = Ember.run.backburner._timers.filter(function (item, index) {
      return index % 2 === 0 && secondsFromNow(item) > 0;
    }).map(function (item) {
      return {
        secondsToPop: secondsFromNow(item),
        timestamp: item
      };
    });

    if (console.table && timesList.length > 0) {
      console.table(timesList);
    }
  }
});