define('ember-chat-components/mixins/webrtc-action-utils', ['exports', 'ember', 'ember-purecloud-components/utils/browser'], function (exports, _ember, _browser) {
    'use strict';

    exports.__esModule = true;


    const { computed, inject } = _ember.default;

    exports.default = _ember.default.Mixin.create({
        interaction: computed.reads('webrtc.interaction'),
        webrtc: inject.service(),
        streaming: inject.service(),
        intl: computed.reads('webrtc.intl'),
        phone: inject.service(),
        mediaProviders: inject.service(),
        sessionService: inject.service('session'),

        videoTooltipTitle: _ember.default.computed('videoTooltipKey', 'haveNonPurecloudMediaProvider', function () {
            let key = this.get('videoTooltipKey');
            if (this.get('haveNonPurecloudMediaProvider')) {
                key = 'ewc.open';
            }

            const params = {};

            if (key === 'ewc.userOffline' || key === 'ewc.userBusy') {
                params.name = this.get('webrtcTarget.oneToOneTarget.name');
            }

            if (this._fixTooltips) {
                _ember.default.run.scheduleOnce('afterRender', this, this._fixTooltips);
            }
            return this.get('intl').t(key, params);
        }),

        videoTooltipTitleSr: _ember.default.computed('videoTooltipTitle', 'videoTooltipeKey', 'activeRoom.videoPeers', function () {
            let key = this.get('videoTooltipKey');

            const numberOfParticipants = this.get('activeRoom.videoPeers');
            if (key == 'ewc.open' && numberOfParticipants > 0) {
                key = 'ewc.openSr';
                return this.get('intl').t(key, { number: numberOfParticipants });
            }

            return this.get('videoTooltipTitle');
        }),

        oneToOneChat: computed('webrtcTarget.oneToOneTarget', function () {
            const webrtcTarget = this.get('webrtcTarget');
            return webrtcTarget && webrtcTarget.isOneToOneRoom();
        }),

        isFirefox: _browser.default.isFirefox,

        webrtcTarget: computed.or('activeRoom', 'entity.oneToOneRoom'),

        webrtcPhoneWithIssues: _ember.default.computed('phone.station.{isWebrtc,webRtcRequireMediaHelper}', 'webrtc.webrtcAudioCallIssueKey', function () {
            return this.get('phone.station.isWebrtc') && this.get('webrtc.webrtcAudioCallIssueKey') && !this.get('phone.station.webRtcRequireMediaHelper');
        }),

        videoMediaProviders: _ember.default.computed('useMediaProviders', 'sessionService', 'mediaProviders.enabled', function () {
            if (!this.get('useMediaProviders') || !this.get('mediaProviders.enabled')) {
                return [];
            }

            return this.get('mediaProviders').getVideoProviders(this.get('sessionService.user'), this.get('webrtcTarget'));
        }),

        haveNonPurecloudMediaProvider: _ember.default.computed('videoMediaProviders.[]', function () {
            return !!this.get('videoMediaProviders').find(provider => provider.get('id') !== 'purecloud');
        }),

        videoTooltipKey: computed('webrtc.{videoCallCapable,activeRoom.showingVideo,activeBridge,canConference,webrtcAudioCallIssueKey}', 'webrtcTarget.{showingVideo,videoRoomFull}', 'interaction.activeRealtimeInteractions.[]', 'isOneToOneOfflineOrBusy', 'isFirefox', 'mediaProviders.{enabled,usingVideoProvider}', 'useMediaProviders', function () {
            const openTooltip = 'ewc.open';
            if (this.get('webrtc.webrtcAudioCallIssueKey')) {
                return this.get('webrtc.webrtcAudioCallIssueKey');
            }
            if (!this.get('webrtc.videoCallCapable') && !this.get('useMediaProviders')) {
                return 'ewc.notCapable';
            }
            if (this.get('interaction.activeRealtimeInteractions.length')) {
                return 'ewc.activeInteractions';
            }
            if (this.get('webrtcTarget.showingVideo') || this.get('webrtc.activeRoom.showingVideo') || this.get('mediaProviders.usingVideoProvider')) {
                if (this.get('webrtcTarget.jid') === this.get('webrtc.activeRoom.jid')) {
                    return 'ewc.openActiveCall';
                }
                return 'ewc.anotherActiveCall';
            }
            if (this.get('webrtcTarget.videoRoomFull')) {
                return 'ewc.atCapacity';
            }
            if (this.get('webrtcTarget.oneToOneTarget')) {
                if (this.get('mediaProviders.enabled') && this.get('useMediaProviders')) {
                    return openTooltip;
                }
                if (!this.get('isOneToOneOfflineOrBusy')) {
                    return openTooltip;
                }
                if (this.get('webrtcTarget.oneToOneTarget.isOffline')) {
                    return 'ewc.userOffline';
                }
                if (this.get('webrtcTarget.oneToOneTarget.isBusy')) {
                    return 'ewc.userBusy';
                }
            }
            if (!this.get('webrtc.canConference') && !this.get('useMediaProviders')) {
                return 'ewc.browserNotSupported';
            }
            return openTooltip;
        }),

        isOneToOneOfflineOrBusy: computed.and('oneToOneChat', 'webrtcTarget', 'webrtcTarget.oneToOneTarget.isOfflineOrBusy'),

        isVideoButtonEnabled: computed('videoTooltipKey', 'webrtc.webrtcAudioCallIssueKey', 'streaming.{connected,connectedV2}', 'haveNonPurecloudMediaProvider', 'useMediaProviders', function () {
            if (this.get('useMediaProviders') && this.get('haveNonPurecloudMediaProvider')) {
                return true;
            }

            if (this.get('webrtc.webrtcAudioCallIssueKey')) {
                return false;
            }

            const hawkIsAvailable = this.get('streaming.connectedV2');
            const realtimeIsAvailable = this.get('streaming.connected');
            if (!hawkIsAvailable && !realtimeIsAvailable) {
                return false;
            }

            return this.get('videoTooltipKey') === 'ewc.open' || this.get('videoTooltipKey') === 'ewc.openActiveCall';
        }),

        videoButtonEnabled: computed('isVideoButtonEnabled', function () {
            return this.get('isVideoButtonEnabled') ? 'enabled' : 'disabled';
        })
    });
});