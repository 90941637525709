define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/inline-audio/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/inline-audio/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component } = _ember.default;

    const InlineAudioComponent = Component.extend({
        layout: _template.default,
        mediaItem: null
    });

    exports.default = InlineAudioComponent;
});