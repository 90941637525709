define("ember-webrtc-components/models/webrtc-conversation", ["exports", "ember-webrtc-components/models/video-participant", "ember-webrtc-components/models/video-participant-v2", "ember-purecloud-components/mixins/logger", "ember-webrtc-components/mixins/webrtc-conversation-session-events", "ember-webrtc-components/mixins/webrtc-conversation-analysis", "ember-webrtc-components/utils/webrtc-conversation-types"], function (_exports, _videoParticipant, _videoParticipantV, _logger, _webrtcConversationSessionEvents, _webrtcConversationAnalysis, _webrtcConversationTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var PENDING_SESSION_WAIT = 3000;
  var computed = Ember.computed,
      observer = Ember.observer,
      inject = Ember.inject,
      Evented = Ember.Evented,
      run = Ember.run,
      RSVP = Ember.RSVP;
  var conversationStates = {
    PREPARING: 'preparing',
    PENDING: 'pending',
    ACTIVE: 'active',
    IDLE: 'idle',
    ENDING: 'ending',
    ENDED: 'ended'
  };

  var _default = Ember.Object.extend(_logger.default, Evented, _webrtcConversationSessionEvents.default, _webrtcConversationAnalysis.default, {
    id: computed.reads('pcConversation.id'),
    room: null,
    conversationId: null,
    conversationType: null,
    localMedia: null,
    currentCameraTrack: null,
    currentMicTrack: null,
    currentScreenTrack: null,
    sessions: null,
    knownSessions: null,
    pendingStreamInfos: null,
    conversationStart: null,
    hasPrepared: false,
    hasJoined: false,
    joinedWithoutMedia: false,
    preparingToJoin: false,
    outgoingSessionRequests: null,
    isOneToOne: false,
    isConnected: false,
    ending: false,
    interruptions: 0,
    foundedVideoNotifications: null,
    unfoundedVideoNotifications: null,
    currentLastN: null,
    screenRecordingStream: null,
    trackOwners: null,
    waitingForScreenStream: false,
    pinnedParticipantId: null,
    jid: null,
    application: computed.reads('webrtc.application'),
    intl: computed.reads('webrtc.intl'),
    session: computed.reads('webrtc.session'),
    presenceSubscription: computed.reads('webrtc.presenceSubscription'),
    webrtc: inject.service(),
    ajax: inject.service(),
    conversationService: computed.reads('webrtc.conversationService'),
    notification: computed.reads('webrtc.notification'),
    participantPurpose: computed.reads('conversation.myParticipants.firstObject.purpose'),
    localCameraStream: null,
    localScreenStream: null,
    _participants: null,
    participants: computed('usePcConversationModelParticipants', 'pcConversation.participants.@each.activeCommunications', '_participants', 'isOneToOne', 'isScreenShare', 'webrtc.screenShareViaHawk', function () {
      var _this = this;

      if (!this.get('usePcConversationModelParticipants')) {
        return this.get('_participants');
      }

      var conversation = this.get('pcConversation');

      if (!conversation) {
        return [];
      }

      var owner = Ember.getOwner(this) ? Ember.getOwner(this).ownerInjection() : null;
      return Ember.A(conversation.get('participants').filter(function (p) {
        var videos = p.get('activeCommunications.videos');
        var screenshares = _this.get('isAcdScreenShareViaHawk') ? p.get('activeCommunications.screenshares') : null;
        return videos && videos.length > 0 || screenshares && screenshares.length > 0;
      }).map(function (p) {
        return _videoParticipantV.default.create({
          pcParticipant: p,
          pcConversation: conversation,
          currentUserId: _this.get('session.person.guid'),
          conversation: _this
        }, owner);
      })).sortBy('person.name');
    }),
    pinnedParticipant: computed('participants', 'pinnedParticipantId', function () {
      return this.get('participants').findBy('selected', true);
    }),
    useTrackBasedActions: computed('jid', 'isScreenShare', 'session.features.webrtcTrackBasedActions', 'session.features.hawkClientScreenShare', function () {
      return this.get('webrtc').canUseTrackBasedActions(this);
    }),
    usePcConversationModelParticipants: computed('session.features.hawkClientScreenShare', 'isOneToOne', 'jid', 'isScreenShare', function () {
      if (this.get('isScreenShare')) {
        return this.get('session.features.hawkClientScreenShare');
      }

      return this.get('webrtc').shouldUseApiByJid(this.get('jid')) && !this.get('isOneToOne');
    }),
    pcConversation: computed('conversationId', 'conversationService.activeConversations.[]', function () {
      var conversationService = this.get('conversationService');
      var conversationId = this.get('conversationId');

      if (conversationId) {
        return conversationService.get('activeConversations').findBy('id', conversationId);
      }
    }),
    isPreparing: computed.equal('_state', conversationStates.PREPARING),
    isIdle: computed.equal('_state', conversationStates.IDLE),
    isActive: computed.equal('_state', conversationStates.ACTIVE),
    isPending: computed.equal('_state', conversationStates.PENDING),
    isEnding: computed.equal('_state', conversationStates.ENDING),
    isEnded: computed.equal('_state', conversationStates.ENDED),
    _state: computed('preparingToJoin', 'isConnected', 'sessions.[]', 'ending', function () {
      var sessions = this.get('sessions') || Ember.A();

      if (this.get('preparingToJoin')) {
        return conversationStates.PREPARING;
      }

      if (this.get('ending')) {
        return conversationStates.ENDING;
      }

      if (!sessions.length) {
        return conversationStates.IDLE;
      }

      if (sessions.findBy('active', true) || sessions.findBy('pending', true)) {
        return conversationStates.ACTIVE;
      }

      if (sessions.findBy('ended', true)) {
        return conversationStates.ENDED;
      }
    }),
    hasPendingSessionRequest: computed.notEmpty('outgoingSessionRequests'),
    _removeOutgoingSessionRequestById: function _removeOutgoingSessionRequestById(sessionId) {
      var pending;
      var outgoing = this.get('outgoingSessionRequests');

      if (outgoing.length === 1 && outgoing[0].id === -1) {
        pending = outgoing[0];
      } else {
        pending = outgoing.find(function (r) {
          return r.id === sessionId;
        });
      }

      if (pending) {
        this.get('outgoingSessionRequests').removeObject(pending);
      }
    },
    issue: computed('mutedByOther', 'hardwareAudioMute', 'audioBitrateZero', 'bridgeDownError', 'failedToConnectSession', 'talkingWhileMuted', function () {
      if (this.get('mutedByOther')) {
        return {
          name: 'userMute',
          info: this.get('mutedByOther')
        };
      }

      if (this.get('hardwareAudioMute')) {
        return {
          name: 'hardwareMute',
          info: null
        };
      }

      if (this.get('audioBitrateZero')) {
        return {
          name: 'bitrateZero',
          info: null
        };
      }

      if (this.get('bridgeDownError') || this.get('failedToConnectSession')) {
        return {
          name: 'networkIssue',
          info: null
        };
      }

      if (this.get('talkingWhileMuted')) {
        return {
          name: 'talkingWhileMuted',
          info: this.get('talkingWhileMuted')
        };
      }

      return null;
    }),
    hasPeers: computed('participants.[]', function () {
      var participants = this.get('participants');
      return participants.length && participants.find(function (p) {
        return !p.get('local');
      });
    }),
    localParticipant: computed('participants.[]', function () {
      return this.get('participants').find(function (p) {
        return p.get('local');
      });
    }),
    // PCM-244 translates to the media purpose used by media controller to determine type
    mediaPurpose: computed('isScreenRecording', 'isScreenShare', function () {
      if (this.get('isScreenRecording')) {
        return 'screenRecording';
      }

      return undefined;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('room')) {
        this.set('isOneToOne', this.get('room').isOneToOneRoom());
      }

      this.set('pendingStreamInfos', {}); // {msid: {stream, session, timeout}}

      this.set('_participants', Ember.A());
      this.set('outgoingSessionRequests', Ember.A());
      this.set('sessions', Ember.A());
      this.set('foundedVideoNotifications', Ember.A());
      this.set('unfoundedVideoNotifications', Ember.A());
      this.set('knownSessions', Ember.A());
      this.get('webrtc').on('mediaChange', this, this._handleMediaChange);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.get('webrtc').off('mediaChange', this, this._handleMediaChange);
    },
    cleanupLocalMedia: function cleanupLocalMedia() {
      if (this.get('localMedia')) {
        this.get('localMedia').stop();
        this.get('localMedia').stopScreenShare();
        this.set('localMedia', null);
      }
    },
    isScreenShare: computed.equal('conversationType', _webrtcConversationTypes.default.ENGAGE_SCREENSHARE),
    isSoftphone: computed.equal('conversationType', _webrtcConversationTypes.default.SOFTPHONE),
    isScreenRecording: computed.equal('conversationType', _webrtcConversationTypes.default.ENGAGE_SCREEN_RECORDING),
    isCollaborate: computed.equal('conversationType', _webrtcConversationTypes.default.COLLABORATE_VIDEO),
    isOneToOneCollaborate: computed.and('isCollaborate', 'isOneToOne'),
    isGroupCollaborate: computed('isCollaborate', 'isOneToOne', function () {
      return this.get('isCollaborate') && !this.get('isOneToOne');
    }),
    isAcdScreenShareViaHawk: computed('isScreenShare', 'jid', 'webrtc.screenShareViaHawk', function () {
      return this.get('isScreenShare') && this.get('webrtc').shouldUseHawkByJid(this.get('jid'));
    }),
    // eslint-disable-next-line ember/no-observers
    _updateMajorByLastN: observer('participants.@each.inLastN', function () {
      this._updateMajor();
    }),
    rawTrackOwners: computed('trackOwners', function () {
      if (this.get('trackOwners')) {
        return JSON.stringify(this.get('trackOwners'), null, 2);
      }

      return 'None';
    }),
    getNativePeerConnection: function getNativePeerConnection(session) {
      if (session.pc.pc) {
        return session.pc.pc;
      }

      return session.pc;
    },
    _updateMajor: function _updateMajor() {
      var participants = this.get('participants');

      if (!this.get('usePcConversationModelParticipants')) {
        if (participants.length && !participants.findBy('major', true)) {
          var majorParticipant = participants.find(function (participant) {
            return !participant.get('local');
          }) || this.get('localParticipant');
          return majorParticipant.set('major', true);
        }
      } else {
        if (participants.length === 1) {
          participants[0].set('major', true);
        } else if (participants.length > 1) {
          var newMajor = participants.find(function (p) {
            return p.get('inLastN') && !p.get('local');
          });

          if (!newMajor) {
            newMajor = participants[0];
          }

          newMajor.set('major', true);
          participants.forEach(function (p) {
            if (p !== newMajor) {
              p.set('major', false);
            }
          });
        }
      }
    },
    _getSessionPeer: function _getSessionPeer(session, msid, personId) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        if (_this2.get('isOneToOne')) {
          resolve(_this2.get('room.oneToOneTarget'));
        } else {
          run.later(_this2, _this2._getPerson, {
            deferred: {
              resolve: resolve,
              reject: reject
            },
            msid: msid,
            personId: personId,
            retryCount: 20
          }, 500);
        }
      });
    },
    _getPerson: function _getPerson(options) {
      var _this3 = this;

      var deferred = options.deferred,
          msid = options.msid,
          personId = options.personId,
          retryCount = options.retryCount,
          repaired = options.repaired;

      if (!this.get('room')) {
        return;
      }

      var participants = this.get('room.participants');
      var person = participants.find(function (participant) {
        if (personId) {
          return participant.get('id') === personId;
        }

        if (msid) {
          var participantMediaActiveInRoom = participant.get('mediaPresence.roomJid') === _this3.get('room.jid');

          var participantMedia = participant.get('mediaPresence.media');
          var matchingMsid = participantMedia && participantMedia.find(function (mediaInfo) {
            return mediaInfo.msid === msid;
          });
          return participantMediaActiveInRoom && matchingMsid;
        }

        _this3.logger.warn('No msid or personId provided');

        return false;
      });

      if (person) {
        if (person === this.get('session.person')) {
          this.logger.warn('getting session peer for self');
        }

        return deferred.resolve(person);
      }

      if (retryCount > 0) {
        options.retryCount--;
        return run.later(this, this._getPerson, options, 500);
      }

      var participantInfos = participants.map(function (participant) {
        return {
          id: participant.get('id'),
          mediaPresence: participant.get('mediaPresence')
        };
      });
      this.logger.error('Failed to get session peer for person', {
        kind: 'participantError',
        msid: msid,
        personId: personId,
        participantInfos: participantInfos
      });

      if (!repaired) {
        return this.get('webrtc').repairRoomPresences().then(function () {
          options.repaired = true;
          options.retryCount = 3;
          run.later(_this3, _this3._getPerson, options, 500);
        });
      }

      return deferred.reject(new Error('Participant Error'));
    },
    _addParticipant: function _addParticipant(peer) {
      var _this4 = this;

      var videoMuted = false;
      var audioMuted = false;

      if (peer) {
        var oneToOne = this.get('isOneToOne');
        var videoPresence = peer.get('mediaPresence.media.firstObject.video');
        var audioPresence = peer.get('mediaPresence.media.firstObject.audio');
        videoMuted = oneToOne ? false : !videoPresence || videoPresence === 'muted';
        audioMuted = oneToOne ? false : !audioPresence || audioPresence === 'muted';
      }

      var owner = Ember.getOwner(this) ? Ember.getOwner(this).ownerInjection() : null;

      var newParticipant = _videoParticipant.default.create({
        videoMuted: videoMuted,
        audioMuted: audioMuted,
        person: peer,
        associatedInteraction: this.get('room.associatedInteraction'),
        inLastN: this.get('participants.length') <= 3 // TODO: this should be unified when last n is configurable

      }, owner);

      this.get('_participants').pushObject(newParticipant);

      if (peer && this.get('isCollaborate')) {
        var knownParticipants = Ember.A(this.get('participants').filterBy('person'));
        peer.reload().then(function () {
          return _this4.get('presenceSubscription').autoSubscribe(knownParticipants.mapBy('person'), _this4);
        });
      }

      return newParticipant;
    },
    _handleDominantSpeakerChange: function _handleDominantSpeakerChange(endpoint) {
      var participants = this.get('participants');
      var localParticipant = this.get('localParticipant');
      var participant = participants.find(function (p) {
        return p.get('person.jid') === endpoint;
      });

      if (localParticipant === participant) {
        return;
      }

      if (!participants.findBy('selected', true)) {
        participants.forEach(function (p) {
          return p.set('major', p === participant && p !== localParticipant);
        });
      }

      if (!participants.findBy('major', true)) {
        var majorParticipant = participants.findBy('local', false) || this.get('localParticipant');

        if (majorParticipant) {
          majorParticipant.set('major', true);
        }
      }
    },
    _handleChannelMessage: function _handleChannelMessage(channel, messageType, peer, data) {
      var _this5 = this;

      if (!this.get('isCollaborate')) {
        this.logger.debug('Ignoring channel message for non-collaborate-video conversation');
        return;
      }

      var participants = this.get('participants');
      var peerId = peer.get('id');
      var participant = participants.findBy('person.id', peerId);

      if (!participant && messageType !== 'participantInfo') {
        this.logger.warn('[Participant Error] Could not find participant for peer', {
          remoteData: {
            peerId: peer.get('id'),
            kind: 'webrtcConversation'
          }
        });
        this.get('webrtc').repairRoomPresences();
        return;
      }

      if (channel === 'hark' && messageType === 'speaking') {
        participant.set('speaking', true);

        if (!participants.findBy('selected', true)) {
          participants.forEach(function (p) {
            return p.set('major', p === participant);
          });
        }
      } else if (channel === 'hark' && messageType === 'stoppedSpeaking') {
        participant.set('speaking', false);
      } else if (channel === 'video' && messageType === 'filter') {
        participant.set('filter', data);
      } else if (channel === 'video' && messageType === 'participantInfo') {
        if (!participant) {
          data.msids.forEach(function (msid) {
            var pending = _this5.get("pendingStreamInfos.".concat(msid));

            if (pending) {
              _this5.handleRemoteStreamIdentified(pending.stream, pending.session, peer.get('id'));
            }
          });
          participant = participants.findBy('person.id', peerId);

          if (!participant) {
            return this.logger.warn('Could not identify or match participant info with any pending streams', {
              remoteData: {
                peerId: peer.get('id'),
                kind: 'webrtcConversation'
              }
            });
          }
        }

        participant.set('endpointId', data.endpointId);
      } else if (channel === 'audio' && messageType === 'requestMute') {
        if (this.get('localParticipant.audioMuted') === false) {
          this.toggleAudioMuted();
        }

        this.set('mutedByOther', participant.get('person.name'));
      } else if (channel === 'stats') {
        if (messageType === 'perspective') {
          this.mergeStatsPerspective(participant, data);
        }
      }
    },
    // eslint-disable-next-line ember/no-observers
    _checkLastN: observer('participants.@each.jid', 'currentLastN', function () {
      var _this6 = this;

      if (this.get('usePcConversationModelParticipants')) {
        return;
      }

      var participants = this.get('participants');
      var localParticipant = this.get('localParticipant');
      participants.forEach(function (participant) {
        var currentLastN = _this6.get('currentLastN');

        var personJid = participant.get('person.jid') || '';
        var inLastN = _this6.get('isOneToOne') || currentLastN && currentLastN.find(function (personId) {
          return personJid.indexOf(personId) >= 0;
        }) || participant === localParticipant;
        participant.set('inLastN', !!inLastN);

        if (!inLastN) {
          participant.set('major', false);
        }
      });

      if (!participants.any(function (p) {
        return p.get('major');
      })) {
        var newMajor = participants.find(function (p) {
          return !p.get('local');
        }) || this.get('localParticipant');

        if (newMajor) {
          newMajor.set('major', true);
        }
      }
    }),
    // eslint-disable-next-line ember/no-observers
    _removeListenerPeer: observer('participants.@each.mediaPresence', 'usePcConversationModelParticipants', function () {
      var _this7 = this;

      if (this.get('usePcConversationModelParticipants')) {
        return;
      }

      if (!this.get('isOneToOne')) {
        run.later(this, function () {
          if (_this7.isDestroying || _this7.isDestroyed) {
            return;
          }

          var participants = _this7.get('participants');

          if (participants) {
            var participantsWithoutMediaPresence = participants.filter(function (participant) {
              return !participant.get('local') && participant.get('person') && !participant.get('person.mediaPresence');
            }).map(function (p) {
              return {
                userId: p.get('person.id')
              };
            });

            if (participantsWithoutMediaPresence.length) {
              _this7.logger.info('removing listeners', {
                remoteData: {
                  participantsWithoutMediaPresence: participantsWithoutMediaPresence
                }
              });

              _this7.get('_participants').removeObjects(participantsWithoutMediaPresence);
            }
          }
        }, 1000);
      }
    }),
    _sendMuteStates: function _sendMuteStates(session) {
      var localMedia = this.get('localMedia');
      run.later(this, function () {
        if (!localMedia) {
          session.mute(this.get('session.person.id'), 'audio');
          session.mute(this.get('session.person.id'), 'video');
        } else {
          var hasVideoTrack = localMedia.localStreams.some(function (stream) {
            return stream.getVideoTracks().length;
          });
          var hasAudioTrack = localMedia.localStreams.some(function (stream) {
            return stream.getAudioTracks().length;
          });

          if (!hasVideoTrack || !localMedia.isVideoEnabled()) {
            session.mute(this.get('session.person.id'), 'video');
          }

          if (!hasAudioTrack || !localMedia.isAudioEnabled()) {
            session.mute(this.get('session.person.id'), 'audio');
          }
        }
      }, 500);
    },
    _selectEndpoint: function _selectEndpoint(endpointId) {
      var pinnedEndpointMessage = {
        colibriClass: 'PinnedEndpointChangedEvent',
        pinnedEndpoint: endpointId
      };
      var sessions = this.get('sessions');

      if (sessions.length === 1 && sessions[0].connected && sessions[0].lastNChannel) {
        sessions[0].lastNChannel.send(JSON.stringify(pinnedEndpointMessage));
      }
    },
    _removeSession: function _removeSession(session) {
      session.callbacks = {};
      this.get('sessions').removeObject(session);
      if (session.connectionTimeout) session.connectionTimeout.cancel();
      if (session.relayCandidatesTimeout) session.relayCandidatesTimeout.cancel();
      if (session.interruptedTimeout) session.interruptedTimeout.cancel();
    },
    _addScreenStream: function _addScreenStream(stream) {
      var sessions = this.get('sessions');
      sessions.forEach(function (session) {
        session.addStream2(stream);
      });
    },
    _updateMediaToPeers: function _updateMediaToPeers(media) {
      var _this8 = this;

      var promise = RSVP.resolve();
      this.get('sessions').forEach(function (session) {
        if (_this8.getNativePeerConnection(session).connectionState === 'connected' && _this8.get('useTrackBasedActions')) {
          // remove all track and only use what's on the new media
          var clearPromises = _this8.getNativePeerConnection(session).getSenders().map(function (sender) {
            if (sender.track) {
              return sender.replaceTrack(null);
            }

            return RSVP.resolve();
          });

          promise = promise.then(function () {
            return RSVP.all(clearPromises);
          }).then(function () {
            var newTrackPromises = [];
            media.localStreams.forEach(function (stream) {
              var promises = stream.getTracks().map(function (track) {
                return session.addTrack(track);
              });
              newTrackPromises.push.apply(newTrackPromises, _toConsumableArray(promises));
            });
            return RSVP.all(newTrackPromises);
          });
        } else if (_this8.getNativePeerConnection(session).connectionState === 'connected' && media.localStreams && media.localStreams[0]) {
          var oldStream = session.pc.getLocalStreams()[0];

          var screenStream = _this8.get('localScreenStream');

          if (screenStream) {
            oldStream = session.pc.getLocalStreams().find(function (stream) {
              return stream !== screenStream;
            });
          }

          if (oldStream) {
            promise = promise.then(function () {
              return session.switchStream(oldStream, media.localStreams[0]);
            });
          } else {
            promise = promise.then(function () {
              return session.addStream(media.localStreams[0]);
            });
          }
        }
      });
      return promise;
    },
    _addVideoBack: function _addVideoBack() {
      var _this9 = this;

      return this.get('webrtc').createMedia({
        requestAudio: false
      }).then(function (media) {
        var videoTrack = media.localStreams[0].getVideoTracks()[0];

        _this9.get('localMedia.localStreams.firstObject').addTrack(videoTrack);

        _this9._updateLocalStreams();

        return _this9.get('sessions.firstObject').addTrack(videoTrack);
      });
    },
    _removeLocalVideoTrack: function _removeLocalVideoTrack() {
      var _this10 = this;

      var localMedia = this.get('localMedia');

      if (!localMedia) {
        return;
      }

      var promises = [RSVP.resolve()];
      localMedia.localStreams[0].getVideoTracks().forEach(function (track) {
        promises.push(_this10.get('sessions.firstObject').removeTrack(track));
        track.stop();
        localMedia.localStreams[0].removeTrack(track);
      });

      this._updateLocalStreams();

      return RSVP.all(promises);
    },
    _addEventsToLocalMedia: function _addEventsToLocalMedia(newMedia) {
      var _this11 = this;

      if (!this.get('usePcConversationModelParticipants')) {
        if (newMedia.localStreams.length) {
          this.set('localParticipant.cameraStream', newMedia.localStreams[0]);
        } else {
          newMedia.on('localStream', function (stream) {
            _this11.set('localParticipant.cameraStream', stream);

            _this11._updateLocalStreams();
          });
        }
      }

      newMedia.on('localScreen', function (stream) {
        _this11.set('localScreenStream', stream);

        _this11._removeLocalVideoTrack().then(function () {
          var session = _this11.get('sessions.firstObject');

          session.addTrack(stream.getVideoTracks()[0]);

          if (!_this11.get('isOneToOne') && _this11.get('webrtc.webrtcSessions').notifyScreenShareStart) {
            _this11.get('webrtc.webrtcSessions').notifyScreenShareStart(session);
          }

          _this11._updateLocalStreams();
        });
      });
      newMedia.on('speaking', function () {
        if (newMedia.hardMuted) {
          return;
        }

        var localParticipant = _this11.get('localParticipant');

        if (localParticipant) {
          localParticipant.set('speaking', true);
        }

        return _this11.sendDirectlyToAll('hark', 'speaking');
      });
      newMedia.on('stoppedSpeaking', function () {
        var localParticipant = _this11.get('localParticipant');

        if (localParticipant) {
          localParticipant.set('speaking', false);
        }

        return _this11.sendDirectlyToAll('hark', 'stoppedSpeaking');
      });
      var creatorId = this.get('session.person.id');
      newMedia.on('audioOn', function () {
        _this11.get('sessions').forEach(function (session) {
          session.unmute(creatorId, 'audio');
        });
      });
      newMedia.on('audioOff', function () {
        var localParticipant = _this11.get('localParticipant');

        localParticipant.set('speaking', false);

        _this11.get('sessions').forEach(function (session) {
          session.mute(creatorId, 'audio');
        });
      });
      newMedia.on('videoOn', function () {
        _this11.get('sessions').forEach(function (session) {
          session.unmute(creatorId, 'video');
        });
      });
      newMedia.on('videoOff', function () {
        _this11.get('sessions').forEach(function (session) {
          session.mute(creatorId, 'video');
        });
      });

      var removeScreenStream = function removeScreenStream(stream) {
        stream.getTracks().forEach(function (track) {
          return track.stop();
        });

        _this11.set('localScreenStream', null);

        var session = _this11.get('sessions.firstObject');

        if (session.pc.getSenders().some(function (sender) {
          return sender.track && sender.track === stream.getVideoTracks()[0];
        })) {
          session.removeTrack(stream.getVideoTracks()[0]);
        }

        if (_this11.get('webrtc.webrtcSessions').notifyScreenShareStop) {
          _this11.get('webrtc.webrtcSessions').notifyScreenShareStop(session);
        }

        if (_this11.get('shouldRestoreCameraStream')) {
          _this11.toggleVideoMuted();
        }
      }; // debounce this in case it's evented multiple times - only want to process the remove once


      newMedia.on('localScreenStopped', function (stream) {
        return Ember.run.debounce(_this11, removeScreenStream, stream, 500);
      });

      if (newMedia.localScreens.length) {
        return this._addScreenStream(newMedia.localScreens[0]);
      }
    },
    applyFilter: function applyFilter(filter) {
      this.sendDirectlyToAll('video', 'filter', filter);

      if (this.get('localParticipant')) {
        this.set('localParticipant.filter', filter);
      }
    },
    canMuteOthers: computed.not('conversation.isOneToOne'),
    checkForListeners: function checkForListeners() {
      var _this12 = this;

      if (!this.get('isActive') || !this.get('room') || this.get('usePcConversationModelParticipants')) {
        return;
      }

      var listeners = this.get('room.sortedParticipants').filter(function (person) {
        var mediaPresence = person.get('mediaPresence');
        return mediaPresence && mediaPresence.media[0].listener;
      });
      listeners.forEach(function (listener) {
        if (!_this12.get('participants').any(function (p) {
          return p.get('person') === listener;
        })) {
          var participant = _this12._addParticipant(listener);

          participant.setProperties({
            videoMuted: true,
            audioMuted: true,
            connectionState: 'listening'
          });
        }
      });
    },
    updateTrackOwners: function updateTrackOwners(participants) {
      this.set('trackOwners', participants);
    },
    updateSpeakers: function updateSpeakers(speakers) {
      this.set('rawSpeakers', speakers);
    },
    addLocalParticipant: function addLocalParticipant() {
      if (this.get('localParticipant')) {
        return;
      }

      var owner = Ember.getOwner(this) ? Ember.getOwner(this).ownerInjection() : null;

      var participant = _videoParticipant.default.create({
        local: true,
        major: true,
        person: this.get('session.person'),
        domId: 'local-video-container',
        inLastN: true,
        videoMuted: !this.get('webrtc.hasCamera'),
        audioMuted: !this.get('webrtc.hasMicrophone')
      }, owner);

      this.get('_participants').unshiftObject(participant);
    },
    addSession: function addSession(session, acceptImmediately) {
      var _this13 = this;

      this.get('webrtc').mapSessionToConversation(session.sid, this);
      var screenshare = session.streams.length && session.streams[0].getVideoTracks().length >= 1 && !session.streams[0].getAudioTracks().length;
      var pendingRequest = this.get('outgoingSessionRequests').findBy('id', session.sid);

      if (pendingRequest) {
        this.logger.info('Found pending outgoing session request for new session', {
          remoteData: {
            sid: session.sid,
            conversationId: this.get('conversationId')
          }
        });
        pendingRequest.get('timeoutTask').cancel();
        this.get('outgoingSessionRequests').removeObject(pendingRequest);
      }

      this._setupSessionEvents(session);

      this.get('sessions').pushObject(session);
      var promise = RSVP.resolve();

      if (session.state === 'pending' && (acceptImmediately || screenshare)) {
        promise = this.acceptSession(session);
      }

      return promise.then(function () {
        _this13.get('knownSessions').pushObject(session.sid);
      });
    },
    muteParticipant: function muteParticipant(participant) {
      if (this.get('session.person') === participant.person) {
        this.toggleAudioMuted();
      } else if (!this.get('canMuteOthers') || !participant.get('canBeMuted')) {// TODO: why is this case blank?
      } else {
        this.sendDirectly(participant.get('endpointId'), 'audio', 'requestMute');
      }
    },
    _addStreamToParticipant: function _addStreamToParticipant(stream, participant, session) {
      if (this.get('usePcConversationModelParticipants')) {
        return;
      }

      this.trigger('peerStreamAdded', stream);
      var audioTracks = stream.getAudioTracks();
      var videoTracks = stream.getVideoTracks();
      var assumeScreenshareStream = videoTracks.length === 1 && !audioTracks.length;

      if (!this.get('isOneToOne')) {
        participant.set('connectionState', 'connected');
      } else {
        participant.set('connectionState', session.connectionState);
      }

      participant.set('zeroBitrateCount', 0);

      if (assumeScreenshareStream) {
        participant.set('screenStream', stream);
        this.trigger('screenStream', participant);

        if (!this.get('participants').findBy('selected', true)) {
          this.selectParticipant(participant);
        }

        participant.set('screenTrack', videoTracks[0]);
      } else {
        participant.set('cameraStream', stream);
        this.trigger('cameraStream', participant);
        participant.set('audioTrack', audioTracks[0]);
        participant.set('videoTrack', videoTracks[0]);
      }

      if (this.get('localParticipant.major') && !this.get('localParticipant.selected')) {
        this.set('localParticipant.major', false);
        participant.set('major', true);
      }
    },
    handleRemoteStreamIdentified: function handleRemoteStreamIdentified(stream, session, personId) {
      if (this.get('usePcConversationModelParticipants')) {
        return;
      }

      var pending = this.get("pendingStreamInfos.".concat(stream.id));

      if (!pending) {
        return;
      }

      run.cancel(pending.timeout);
      delete this.get('pendingStreamInfos')[stream.id];
      var participant = this.get('participants').find(function (p) {
        return p.get('person.id') === personId || p.get('cameraStream') === stream || p.get('screenStream') === stream;
      });
      var person = this.get('room.participants').find(function (participant) {
        return participant.get('id') === personId;
      });

      if (!person) {
        this.logger.warn('stream was identified but user was not found', {
          remoteData: {
            personId: personId,
            conversationId: this.get('conversationId')
          }
        });
      }

      if (!participant) {
        participant = this._addParticipant(person);
      } else if (!participant.get('person')) {
        participant.set('person', person);
      }

      this._addStreamToParticipant(stream, participant, session);
    },
    _handleMediaChange: function _handleMediaChange(_ref) {
      var _this14 = this;

      var personId = _ref.personId,
          media = _ref.media;
      media.forEach(function (m) {
        var pending = _this14.get("pendingStreamInfos.".concat(m.msid));

        if (pending) {
          _this14.handleRemoteStreamIdentified(pending.stream, pending.session, personId);
        }
      });
    },
    _updateDominantStream: function _updateDominantStream() {
      var stream = this.get('sessions.firstObject.streams.firstObject');
      this.set('_dominantStream', stream);
    },
    _updateLocalStreams: function _updateLocalStreams() {
      var cameraStream = this.get('localMedia.localStreams.firstObject');

      if (!cameraStream || !cameraStream.getVideoTracks().length) {
        cameraStream = null;
      }

      var screenStream = this.get('localScreenStream');
      this.setProperties({
        localCameraStream: cameraStream,
        localScreenStream: screenStream
      });
      this.notifyPropertyChange('localCameraStream');
    },
    dominantStream: computed('participants.length', '_dominantStream', 'localCameraStream', 'localScreenStream', function () {
      if (!this.get('usePcConversationModelParticipants')) {
        return;
      }

      if (this.get('participants.length') <= 1) {
        return this.get('localScreenStream') || this.get('localCameraStream');
      }

      return this.get('_dominantStream');
    }),
    dominantStreamIsLocal: computed('dominantStream', function () {
      return [this.get('localCameraStream'), this.get('localScreenStream')].includes(this.get('dominantStream'));
    }),
    dominantParticipant: computed('participants.@each.isOnIncomingVideoTrack', function () {
      this.logger.info('dominant participant change', this.get('dominantStream'));

      if (!this.get('dominantStream')) {
        return null;
      }

      return this.get('participants').findBy('isOnIncomingVideoTrack', true);
    }),
    dominantStreamIsScreenShare: computed.reads('dominantParticipant.sharingScreen'),
    showAvatarForDominantParticipant: computed.reads('dominantParticipant.showAvatar'),
    handleRemoteTrackAdded: function handleRemoteTrackAdded(session, track) {
      if (this.get('usePcConversationModelParticipants')) {
        this._updateDominantStream();

        if (!this.get('isOneToOne')) {
          return;
        }
      }

      if (this.get('isSoftphone') || this.get('isScreenRecording')) {
        return;
      }

      if (!this.get('isOneToOne') && !this.get('isAcdScreenShareViaHawk')) {
        throw new Error('webrtc conferences v2 is deprecated');
      }

      var person = this.get('room.oneToOneTarget');
      var personId = person ? person.get('id') : undefined;

      if (!person) {
        this.logger.warn('stream was identified but user was not found', {
          remoteData: {
            personId: personId,
            conversationId: this.get('conversationId')
          }
        });
      }

      var participant = this.get('participants').find(function (p) {
        return p.get('person.id') === personId;
      });

      if (!participant) {
        participant = this._addParticipant(person);
      }

      participant.tracks.push(track);
    },
    handleRemoteStreamAdded: function handleRemoteStreamAdded(session, stream) {
      var _this15 = this;

      if (this.get('usePcConversationModelParticipants')) {
        this._updateDominantStream();

        return;
      }

      if (stream.id === 'default' || this.get("pendingStreamInfos.".concat(stream.id))) {
        return;
      }

      var timeout = run.later(this, function () {
        var participant = _this15._addParticipant();

        _this15._addStreamToParticipant(stream, participant, session);
      }, PENDING_SESSION_WAIT);
      this.set("pendingStreamInfos.".concat(stream.id), {
        stream: stream,
        session: session,
        timeout: timeout
      });
      var owner;

      if (this.get('isOneToOne')) {
        owner = this.get('room.oneToOneTarget');
      } else {
        owner = this.get('room.participants').find(function (participant) {
          return participant.get('mediaPresence.media') && participant.get('mediaPresence.media').find(function (media) {
            return media.msid === stream.id;
          });
        });
      }

      if (owner) {
        this.handleRemoteStreamIdentified(stream, session, owner.get('id'));
      }
    },
    handleRemoteStreamRemoved: function handleRemoteStreamRemoved(session, stream) {
      if (this.get('usePcConversationModelParticipants')) {
        this._updateDominantStream();

        return;
      }

      this.trigger('peerStreamRemoved', stream, session);
      var participants = this.get('participants');
      var participant = participants.find(function (p) {
        return p.get('cameraStream') === stream || p.get('screenStream') === stream;
      });

      if (!participant) {
        return this._updateMajor();
      }

      if (participant.get('screenStream') === stream) {
        participant.set('screenStream', null);
        participant.set('selected', false);
      } else if (participant.get('cameraStream') === stream) {
        participant.set('cameraStream', null);
        participant.set('audioTrack', null);
        participant.set('videoTrack', null);
        this.get('_removeParticipant').perform(participant, participants);
      }
    },
    _getSessionAcceptParams: function _getSessionAcceptParams(session) {
      if (session.callInfo && session.callInfo.constraints) {
        return {
          constraints: session.callInfo.constraints
        };
      } else if (this.get('isScreenRecording')) {
        return {
          constraints: {
            mandatory: {
              OfferToReceiveAudio: false,
              OfferToReceiveVideo: false
            }
          }
        };
      }
    },
    _acceptSessionWithTrackBasedActions: function _acceptSessionWithTrackBasedActions(session) {
      var _this16 = this;

      this.logger.info('preparing to accept session with track based actions', {
        remoteData: {
          sid: session.sid,
          conversationId: this.get('conversationId')
        }
      });
      var localMedia = this.get('localMedia');
      var promise = RSVP.resolve();

      if (this.get('isCollaborate')) {
        if (!localMedia || !localMedia.localStreams[0].getVideoTracks().length) {
          var videoTransceiver = this.getNativePeerConnection(session).getTransceivers().find(function (transceiver) {
            return !transceiver.sender.track && transceiver.receiver.track && transceiver.receiver.track.kind === 'video';
          }); // make sure there's a video transceiver and the direction is sendrecv so we don't have to renego later

          if (this.get('isOneToOne') && !videoTransceiver) {
            this.getNativePeerConnection(session).addTransceiver('video', {
              direction: 'sendrecv'
            });
          }

          videoTransceiver.direction = 'sendrecv';
        }
      }

      if (localMedia) {
        localMedia.localStreams[0].getTracks().forEach(function (track) {
          promise = promise.then(function () {
            return session.addTrack(track);
          });
        });
      }

      if (this.get('screenRecordingStream')) {
        // only add as many senders as we have
        var senderCount = this.getNativePeerConnection(session).getSenders().length;
        var tracks = this.get('screenRecordingStream').getVideoTracks();

        if (senderCount < tracks.length) {
          this.logger.error('More video tracks than available senders', {
            remoteData: {
              senderCount: senderCount,
              trackCount: tracks.length
            }
          });
          tracks = tracks.slice(0, senderCount);
        }

        var _iterator = _createForOfIteratorHelper(tracks),
            _step;

        try {
          var _loop = function _loop() {
            var track = _step.value;
            promise = promise.then(function () {
              _this16.logger.info('Adding track to session', {
                remoteData: {
                  track: track.id
                }
              });

              return session.addTrack(track);
            });
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      return promise.then(function () {
        var isScreenRecording = _this16.get('isScreenRecording'); // for some reason this is needed in ff


        _this16.getNativePeerConnection(session).getTransceivers().forEach(function (transceiver) {
          if (!isScreenRecording || isScreenRecording && transceiver.sender.track) {
            transceiver.direction = 'sendrecv';
          }
        });

        _this16.logger.info('sending session-accept', {
          remoteData: {
            sid: session.sid,
            conversationId: _this16.get('conversationId')
          }
        });

        return session.accept();
      }).then(function () {
        _this16.logger.info('session-accept sent', {
          remoteData: {
            sid: session.sid,
            conversationId: _this16.get('conversationId')
          }
        });

        var tracks = _this16.getNativePeerConnection(session).getReceivers().filter(function (receiver) {
          return receiver.track;
        }).map(function (receiver) {
          return receiver.track;
        });

        tracks.forEach(function (track) {
          return _this16.handleRemoteTrackAdded(session, track);
        });
      });
    },
    _acceptSessionWithStreamBasedActions: function _acceptSessionWithStreamBasedActions(session) {
      var _this17 = this;

      this.logger.info('preparing to accept session with stream based actions', {
        remoteData: {
          sid: session.sid,
          conversationId: this.get('conversationId')
        }
      });
      var localMedia = this.get('localMedia');

      if (localMedia && !this.get('isScreenRecording')) {
        localMedia.localStreams.forEach(function (stream) {
          return session.addStream(stream);
        });
      }

      if (this.get('screenRecordingStream')) {
        session.addStream(this.get('screenRecordingStream'));
      } // wait for all the tracks to actually get added before we accept the session


      this.logger.info('sending session-accept', {
        remoteData: {
          sid: session.sid,
          conversationId: this.get('conversationId')
        }
      });
      return session.accept(this._getSessionAcceptParams(session)).then(function () {
        _this17.logger.info('session-accept sent', {
          remoteData: {
            sid: session.sid,
            conversationId: _this17.get('conversationId')
          }
        });

        session.streams.forEach(function (stream) {
          return _this17.handleRemoteStreamAdded(session, stream);
        });
      });
    },
    acceptSession: function acceptSession(session) {
      var conversationStart = this.get('conversationStart');

      if (!conversationStart) {
        this.set('conversationStart', new Date());
      }

      var promise = RSVP.resolve();

      if (this.get('useTrackBasedActions')) {
        promise = this._acceptSessionWithTrackBasedActions(session);
      } else {
        if (this.get('webrtc').streamBasedActionsFallback(this)) {
          if (this.get('isGroupCollaborate')) {
            var key = this.get('application.hostedContext.isHostedProp') ? 'ewc.cantFallbackToStreamsDesktopApp' : 'ewc.cantFallbackToStreamsBrowser';
            this.get('notification').error(null, this.get('intl').t(key));
            var error = new Error('trackBasedActions are required but not supported but this client');
            this.logger.error(error, {
              remoteData: {
                conversationId: this.get('conversationId')
              }
            });
            session.end();
            return RSVP.reject(error);
          } else if (!this.get('webrtc.hasShownStreamFallbackWarning')) {
            this.set('webrtc.hasShownStreamFallbackWarning', true);

            var _key = this.get('application.hostedContext.isHostedProp') ? 'ewc.fallingBackToStreamsDesktopApp' : 'ewc.fallingBackToStreamsBrowser';

            this.get('notification').info(null, this.get('intl').t(_key));
            this.logger.warn('Falling back to streamBasedActions since trackBasedActions were requested but not supported', {
              remoteData: {
                conversationId: this.get('conversationId')
              }
            });
          }
        }

        promise = this._acceptSessionWithStreamBasedActions(session);
      }

      session.startConnectionTimeout();
      return promise;
    },
    retrySessionThatFailedToConnect: function retrySessionThatFailedToConnect(session) {
      var _this18 = this;

      this.set('isConnectRetry', true);
      this.logger.warn('session failed, retrying', {
        remoteData: {
          sessionId: session.id,
          conversationId: this.get('conversationId')
        }
      });
      return this.get('webrtc').endSessionsForRoom({
        jid: this.get('jid'),
        reason: 'alternative-session'
      }).then(function () {
        return _this18.get('webrtc').joinConversation(_this18, _this18.get('localMedia'));
      });
    },
    sendDirectlyToAll: function sendDirectlyToAll() {
      this.sendDirectly.apply(this, [''].concat(Array.prototype.slice.call(arguments)));
    },
    sendDirectly: function sendDirectly(to, channelName, topic, payload) {
      var _this19 = this;

      var sessions = this.get('sessions');
      var colibriPayload = {
        colibriClass: 'EndpointMessage',
        to: to || '',
        msgPayload: {
          channelMessage: channelName,
          channelTopic: topic,
          endpointId: this.get('session.person.id'),
          payload: payload
        }
      };
      sessions.forEach(function (session) {
        if (_this19.getNativePeerConnection(session).connectionState === 'connected' && session.sendDirectly) {
          session.sendDirectly(channelName, topic, _this19.get('session.person.id'), payload);
        } else if (_this19.getNativePeerConnection(session).connectionState === 'connected' && session.lastNChannel) {
          session.lastNChannel.send(JSON.stringify(colibriPayload));
        }
      });
    },
    toggleSharingScreen: function toggleSharingScreen(opts) {
      var _this20 = this;

      opts = opts || {};

      if (this.get('waitingForScreenStream') || this.get('preparingToJoin') || this.get('isOneToOne') && this.get('participants.length') <= 1) {
        return;
      }

      var localMedia = this.get('localMedia');

      if (!localMedia) {
        return this.logger.error('Screenshare as a listener is not yet implemented');
      } // stop


      if (opts.forceStop || this.get('localScreenStream')) {
        if (this.get('useTrackBasedActions')) {
          var stream = localMedia.localScreens[0];

          localMedia._removeStream(stream);
        } else {
          if (!this.get('localParticipant.videoMuted')) {
            localMedia.resumeVideo();
          }

          localMedia.stopScreenShare();
          this.set('localScreenStream', null);
        }

        this.set('localParticipant.screenStream', null);
        return;
      } // start


      var requestedTime = window.performance.now();
      this.set('waitingForScreenStream', true);
      return localMedia.startScreenShare(function (err, stream) {
        _this20.set('waitingForScreenStream', false);

        if (err) {
          _this20.get('webrtc').handleScreenShareError(err, requestedTime).then(_this20.toggleSharingScreen).catch(function (err) {
            return _this20.logger.error(err.message, {
              remoteData: {
                err: err
              }
            });
          });
        } else {
          // pause video when screen sharing
          if (!_this20.get('session.features.showVideoInThumbnail')) {
            localMedia.pauseVideo();
          }

          _this20.set('_screenSharingDuration', new Date());

          _this20.set('localScreenStream', stream); // replace the camera stream and add audio


          if (_this20.get('useTrackBasedActions')) {
            _this20.set('shouldRestoreCameraStream', !_this20.get('localParticipant.videoMuted'));
          } else {
            _this20._addScreenStream(stream);
          }

          if (!_this20.get('localParticipant.videoMuted')) {
            _this20.toggleVideoMuted();
          }
        }
      });
    },
    disallowVideoUnmute: computed.and('localParticipant.videoMuted', 'localScreenStream'),
    toggleVideoMuted: function toggleVideoMuted() {
      if (this.get('disallowVideoUnmute')) {
        return;
      }

      if (!this.get('webrtc.hasCamera')) {
        if (this.get('localParticipant')) {
          this.set('localParticipant.videoMuted', true);
        }
      } else {
        if (this.get('localParticipant')) {
          this.toggleProperty('localParticipant.videoMuted');
        }
      }

      var localMedia = this.get('localMedia');

      if (localMedia) {
        if (this.get('localParticipant.videoMuted')) {
          this._removeLocalVideoTrack();

          localMedia.pauseVideo();
        } else {
          this._addVideoBack().then(function () {
            return localMedia.resumeVideo();
          });
        }
      }
    },
    setLocalMedia: function setLocalMedia(newMedia) {
      var localMedia = this.get('localMedia');

      if (localMedia && localMedia === newMedia) {
        return this.logger.info('setLocalMedia called with same media');
      }

      if (localMedia) {
        this.cleanupLocalMedia();
      }

      if (newMedia) {
        if (this.get('localParticipant.audioMuted')) {
          newMedia.mute();
        }

        if (this.get('localParticipant.videoMuted')) {
          newMedia.pauseVideo();
        }

        if (!this.get('isSoftphone') && this.get('localMedia') !== newMedia) {
          this._addEventsToLocalMedia(newMedia);
        }

        this.set('localMedia', newMedia);
        var localParticipant = this.get('localParticipant');

        if (localParticipant) {
          if (this.get('useTrackBasedActions')) {
            Ember.set(localParticipant, 'tracks', newMedia.localStreams[0].getTracks());
          } else {
            Ember.set(localParticipant, 'cameraStream', newMedia.localStreams[0]);
          }
        }

        this._updateLocalStreams();

        return this._updateMediaToPeers(newMedia);
      }
    },
    toggleAudioMuted: function toggleAudioMuted() {
      var localMedia = this.get('localMedia');

      if (!this.get('webrtc.hasMicrophone')) {
        if (this.get('localParticipant')) {
          this.set('localParticipant.audioMuted', true);
        }
      } else {
        this.toggleProperty('localParticipant.audioMuted');
      }

      if (localMedia) {
        if (this.get('localParticipant.audioMuted')) {
          localMedia.mute();
        } else {
          localMedia.unmute();
          this.set('mutedByOther', false);
        }
      }
    },
    muteAudio: function muteAudio() {
      var localMedia = this.get('localMedia');

      if (this.get('localParticipant')) {
        this.set('localParticipant.audioMuted', true);
      }

      if (localMedia) {
        localMedia.mute();
      }
    },
    unmuteAudio: function unmuteAudio() {
      if (this.get('localParticipant')) {
        this.set('localParticipant.audioMuted', false);
      }

      var localMedia = this.get('localMedia');

      if (localMedia) {
        localMedia.unmute();
      }
    },
    setParticipantPin: function setParticipantPin(shouldPin, participant) {
      var _this21 = this;

      var targetParticipantId = participant.get('pcParticipant.id');
      var participantId = this.get('localParticipant.pcParticipant.id');
      var conversationId = this.get('conversationId');
      var uri = "/platform/api/v2/conversations/videos/".concat(conversationId, "/participants/").concat(participantId, "/pin");
      var promise;

      if (!shouldPin) {
        promise = this.get('ajax').delete(uri).then(function () {
          if (_this21.get('pinnedParticipantId') === targetParticipantId) {
            _this21.set('pinnedParticipantId', null);
          }
        });
      } else {
        promise = this.get('ajax').post(uri, {
          dataType: 'text',
          data: {
            targetParticipantId: targetParticipantId,
            streamType: 'video'
          }
        }).then(function () {
          _this21.set('pinnedParticipantId', targetParticipantId);
        });
      }

      var originalValue = participant.get('selected');
      participant.set('selected', shouldPin);
      return promise.catch(function (err) {
        _this21.logger.error(err);

        if (shouldPin) {
          _this21.get('notification').error(_this21.get('intl').t('ewc.failedToPin', {
            name: participant.get('name')
          }));
        } else {
          _this21.get('notification').error(_this21.get('intl').t('ewc.failedToUnpin', {
            name: participant.get('name')
          }));
        }

        participant.set('selected', originalValue);
      });
    },
    selectParticipant: function selectParticipant(participant) {
      var _this22 = this;

      if (this.get('usePcConversationModelParticipants')) {
        // if pinning, we need to remove the other participant pins
        var shouldPin = !participant.get('selected');

        if (shouldPin) {
          // unpin selected participants
          var selectedParticipants = this.get('participants').filterBy('selected', true);
          selectedParticipants.forEach(function (selectedParticipant) {
            return _this22.setParticipantPin(false, selectedParticipant);
          });
        }

        return this.setParticipantPin(shouldPin, participant);
      }

      if (participant.get('selected')) {
        participant.set('selected', false);
        return this._selectEndpoint('');
      }

      var endpointId = participant.get('endpointId');

      if (endpointId) {
        this._selectEndpoint(endpointId);
      }

      var participants = this.get('participants');
      participants.forEach(function (otherParticipant) {
        if (otherParticipant === participant) {
          otherParticipant.set('selected', true);
          otherParticipant.set('major', true);
        } else {
          otherParticipant.set('selected', false);
          otherParticipant.set('major', false);
        }
      });
    },
    dumpCurrentState: function dumpCurrentState(requestId) {
      var _this23 = this;

      this.get('webrtc').debugWebrtc({
        json: true
      }).then(function (snapshots) {
        var snapshot = snapshots.find(function (snap) {
          return snap.conversationId === _this23.get('conversationId');
        });
        snapshot = snapshot || snapshots.find(function (snap) {
          return snap.roomJid === _this23.get('jid');
        });

        _this23.logger.info('webrtc state dump', {
          requestId: requestId,
          snapshot: snapshot
        });
      });
    },
    cleanup: function cleanup() {
      this.get('webrtc').endFullScreen();
      this.get('webrtc.videoConversationsMadeByClient').removeObject(this);
      this.get('presenceSubscription').autoSubscribe(null, this);
      this.set('isConnected', false);
      this.get('_participants').clear();
      var sessions = this.get('sessions');
      sessions.forEach(function (s) {
        s.callbacks = {}; // remove all event callbacks on all sessions
      });
      sessions.clear();
      this.cleanupLocalMedia();

      if (this.get('screenRecordingStream')) {
        this.get('webrtc.orphanedScreenStreams').pushObject(this.get('screenRecordingStream'));

        if (this.get('webrtc.screenRecordingConversations.length') <= 1) {
          this.get('webrtc').endOrphanedScreenStreams();
        }
      }

      this.set('preparingToJoin', false);
      this.set('hasJoined', false);

      for (var _i = 0, _Object$keys = Object.keys(this.get('pendingStreamInfos')); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        run.cancel(this.get("pendingStreamInfos.".concat(key, ".timeout")));
      }

      this.destroy();
    }
  });

  _exports.default = _default;
});