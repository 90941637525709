define("ember-purecloud-components/serializers/conversation", ["exports", "ember-data", "ember-purecloud-components/serializers/purecloud"], function (_exports, _emberData, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      participants: {
        embedded: 'always'
      }
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.links = {
        recordings: "/api/v2/conversations/".concat(payload.id, "/recordings")
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.entities) {
        payload.entities.forEach(function (convo) {
          convo.links = {
            recordings: "/api/v2/conversations/".concat(convo.id, "/recordings")
          };
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});