define("ember-purecloud-components/instance-initializers/conversation-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupConversationService',
    initialize: function initialize(instance) {
      // Only initialize the service if this is an application, not an engine
      if (instance instanceof Ember.ApplicationInstance) {
        instance.lookup('service:conversation');
      }
    }
  };
  _exports.default = _default;
});