define("webrtc-troubleshoot/components/webrtc-troubleshooter/component", ["exports", "webrtc-troubleshoot/components/webrtc-troubleshooter/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _WebrtcTroubleshooter = WebrtcTroubleshooter,
      ERROR_CODES = _WebrtcTroubleshooter.ERROR_CODES,
      TestSuite = _WebrtcTroubleshooter.TestSuite,
      AudioTest = _WebrtcTroubleshooter.AudioTest,
      VideoTest = _WebrtcTroubleshooter.VideoTest,
      ConnectivityTest = _WebrtcTroubleshooter.ConnectivityTest,
      AdvancedCameraTest = _WebrtcTroubleshooter.AdvancedCameraTest,
      ThroughputTest = _WebrtcTroubleshooter.ThroughputTest,
      VideoBandwidthTest = _WebrtcTroubleshooter.VideoBandwidthTest,
      AudioBandwidthTest = _WebrtcTroubleshooter.AudioBandwidthTest,
      SymmetricNatTest = _WebrtcTroubleshooter.SymmetricNatTest,
      PermissionsTest = _WebrtcTroubleshooter.PermissionsTest;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['webrtc-troubleshooter'],
    checkingMicPermissions: true,
    checkingMicrophone: true,
    checkMicrophoneSuccess: false,
    noMicrophona: false,
    checkingCameraPermissions: true,
    noCamera: false,
    checkingCamera: true,
    checkCameraSuccess: false,
    checkingCameraAdvanced: true,
    checkCameraAdvancedSuccess: false,
    checkingSymmetricNat: true,
    checkingConnectivity: true,
    checkConnectivitySuccess: false,
    checkingThroughput: true,
    checkingThroughputSuccess: false,
    checkingBandwidth: true,
    checkBandwidthSuccess: false,
    showBandwidthStats: false,
    bandwidthMediaError: false,
    bandwidthIceError: false,
    saveSuiteToWindow: false,
    advancedCameraTestResults: [],
    video: true,
    audio: true,
    useLegacyPermissionCheck: false,
    logger: null,
    integrationTestMode: false,
    iceServers: null,
    advCameraResolutions: Ember.computed('advancedCameraTestResults', function () {
      var results = this.get('advancedCameraTestResults');

      if (!results.map) {
        return [];
      }

      return this.get('advancedCameraTestResults').map(function (testLog) {
        var success = testLog.status === 'passed';
        var testResolution;

        if (success) {
          testResolution = testLog.results.resolutions[0];
        } else {
          testResolution = testLog.details.resolutions[0];
        }

        var resolution = "".concat(testResolution[0], "x").concat(testResolution[1]);
        return {
          resolution: resolution,
          success: success
        };
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('logger')) {
        this.set('logger', Ember.Logger);
      }

      this.startTroubleshooter();
    },
    safeSetProperties: function safeSetProperties(obj) {
      var _this = this;

      Ember.run(function () {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }

        _this.setProperties(obj);
      });
    },
    connectivityPortAttempts: 0,
    connectivityMaxPortAttempts: 20,
    runConnectivityTest: function runConnectivityTest(iceConfig) {
      var _this2 = this;

      var connectivityTest = new ConnectivityTest(iceConfig);
      this.testSuite.addTest(connectivityTest);
      var xlowPort, lowPort, medPort, highPort;
      connectivityTest.promise.then(function () {
        var ports = [];

        connectivityTest.pc1._candidateBuffer.forEach(function (p) {
          return ports.push(p.port);
        });

        connectivityTest.pc2._candidateBuffer.forEach(function (p) {
          return ports.push(p.port);
        });

        ports.forEach(function (p) {
          var portNum = Number(p);

          if (portNum < 16384) {
            xlowPort = true;
          } else if (portNum < 32768) {
            lowPort = true;
          } else if (portNum < 49151) {
            medPort = true;
          } else {
            highPort = true;
          }
        });
        console.log('ports used', {
          ports: ports,
          xlowPort: xlowPort,
          lowPort: lowPort,
          medPort: medPort,
          highPort: highPort
        });

        if (!lowPort) {
          if (_this2.get('connectivityPortAttempts') < _this2.connectivityMaxPortAttempts) {
            _this2.incrementProperty('connectivityPortAttempts');

            return _this2.runConnectivityTest(iceConfig);
          }

          var err = new Error('Failed to find port in required range (16384-32768)');
          err.code = 'PORT_REQUIREMENT';
          return Promise.reject(err);
        }

        _this2.safeSetProperties({
          checkingConnectivity: false,
          checkConnectivitySuccess: true
        });
      }).catch(function (err) {
        _this2.logger.error('connectivityTest failed', err.code);

        _this2.safeSetProperties({
          connectivityPortError: err.code === 'PORT_REQUIREMENT',
          checkingConnectivity: false,
          checkConnectivitySuccess: false
        });
      });
    },
    startTroubleshooter: function startTroubleshooter() {
      var _this3 = this;

      if (!navigator.mediaDevices) {
        this.set('video', false);
        this.set('audio', false);
      }

      var iceConfig = {
        iceServers: this.get('iceServers') || [],
        iceTransports: 'relay',
        logger: this.get('logger')
      };
      var mediaOptions = this.get('mediaOptions') || this.getProperties(['audio', 'video', 'logger']);
      var testSuite = new TestSuite({
        logger: this.get('logger')
      });

      if (this.get('saveSuiteToWindow')) {
        window.testSuite = testSuite;
      }

      this.set('testSuite', testSuite); // TODO: logs for rejections?

      if (this.get('audio')) {
        if (!this.get('skipPermissionsCheck')) {
          var micPermissionTest = new PermissionsTest(false, this.useLegacyPermissionCheck, mediaOptions);
          micPermissionTest.promise.then(function () {
            _this3.safeSetProperties({
              checkingMicPermissions: false,
              micPermissionsSuccess: true
            });
          }).catch(function (err) {
            _this3.logger.error('audioTest failed', err);

            _this3.safeSetProperties({
              checkingMicPermissions: false,
              micPermissionsSuccess: false,
              noMicrophone: err.message === 'noDevicePermissions'
            });
          });
          testSuite.addTest(micPermissionTest);
        }

        var audioTest = new AudioTest(mediaOptions);
        audioTest.promise.then(function
          /* logs */
        () {
          _this3.safeSetProperties({
            checkingMicrophone: false,
            checkMicrophoneSuccess: true,
            checkingVolume: false,
            checkVolumeSuccess: true
          });
        }, function (err) {
          _this3.logger.error('audioTest failed', err);

          var micIssue = err.message !== 'audio timeout';
          var volumeIssue = err.message === 'audio timeout';

          _this3.safeSetProperties({
            checkingMicrophone: false,
            checkMicrophoneSuccess: !micIssue,
            checkingVolume: false,
            checkVolumeSuccess: !volumeIssue
          });
        });
        testSuite.addTest(audioTest);
      }

      if (this.get('video')) {
        if (!this.get('skipPermissionsCheck')) {
          var cameraPermissionTest = new PermissionsTest(true, this.useLegacyPermissionCheck, mediaOptions);
          cameraPermissionTest.promise.then(function () {
            _this3.safeSetProperties({
              checkingCameraPermissions: false,
              cameraPermissionsSuccess: true
            });
          }).catch(function (err) {
            _this3.logger.error('audioTest failed', err);

            _this3.safeSetProperties({
              checkingCameraPermissions: false,
              cameraPermissionsSuccess: false,
              noCamera: err.message === 'noDevicePermissions'
            });
          });
          testSuite.addTest(cameraPermissionTest);
        }

        var videoTest = new VideoTest(mediaOptions);
        videoTest.promise.then(function
          /* logs */
        () {
          _this3.safeSetProperties({
            checkingCamera: false,
            checkCameraSuccess: true
          });
        }, function (err) {
          _this3.logger.error('videoTest failed', err);

          _this3.safeSetProperties({
            checkingCamera: false,
            checkCameraSuccess: false
          });
        });
        var advancedCameraTest = new AdvancedCameraTest(mediaOptions);
        advancedCameraTest.promise.then(function (testResults) {
          _this3.logger.info('success - logs: ', testResults);

          _this3.set('advancedCameraTestResults', testResults);

          _this3.safeSetProperties({
            checkingCameraAdvanced: false,
            checkCameraAdvancedSuccess: true
          });
        }, function (err) {
          _this3.logger.info('error - logs: ', err);

          _this3.set('advancedCameraTestResults', err.details);

          _this3.logger.error('advancedCameraTest failed', err);

          _this3.safeSetProperties({
            checkingCameraAdvanced: false,
            checkCameraAdvancedSuccess: false
          });
        });
        testSuite.addTest(videoTest);
        testSuite.addTest(advancedCameraTest);
      }

      if (window.RTCPeerConnection) {
        var symmetricNatTest = new SymmetricNatTest();
        symmetricNatTest.promise.then(function (res) {
          _this3.safeSetProperties({
            checkingSymmetricNat: false,
            symmetricNatResult: "webrtcTroubleshoot.".concat(res)
          });
        }, function (err) {
          _this3.logger.error('symmetricNatTest failed', err);

          _this3.safeSetProperties({
            checkingSymmetricNat: false,
            symmetricNatResult: 'webrtcTroubleshoot.nat.error'
          });
        });
        this.runConnectivityTest(iceConfig);
        var throughputTest = new ThroughputTest(iceConfig);
        throughputTest.promise.then(function
          /* logs */
        () {
          _this3.safeSetProperties({
            checkingThroughput: false,
            checkThroughputSuccess: true
          });
        }, function (err) {
          _this3.logger.error('throughputTest failed', err);

          _this3.safeSetProperties({
            checkingThroughput: false,
            checkThroughputSuccess: false
          });
        });
        testSuite.addTest(symmetricNatTest);
        testSuite.addTest(throughputTest);
        var bandwidthTest;

        if (this.get('runVideoBandwidthTest')) {
          bandwidthTest = new VideoBandwidthTest({
            iceConfig: iceConfig,
            mediaOptions: mediaOptions
          });
        } else if (this.get('runAudioBandwidthTest')) {
          bandwidthTest = new AudioBandwidthTest({
            iceConfig: iceConfig,
            mediaOptions: mediaOptions
          });
        }

        if (bandwidthTest) {
          bandwidthTest.promise.then(function (results) {
            _this3.safeSetProperties({
              bandwidthStats: results && results.stats,
              checkingBandwidth: false,
              checkBandwidthSuccess: true
            });
          }, function (results) {
            if (results.pcCode === ERROR_CODES.ICE) {
              _this3.set('bandwidthIceError', true);
            } else if (results.pcCode === ERROR_CODES.MEDIA) {
              _this3.set('bandwidthMediaError', true);
            } else {
              _this3.set('bandWidthTestError', results);

              _this3.logger.error('bandwidthTest failed', results);
            }

            _this3.safeSetProperties({
              bandwidthStats: results && results.stats,
              checkingBandwidth: false,
              checkBandwidthSuccess: false
            });
          });
          testSuite.addTest(bandwidthTest);
        }
      }

      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        _this3.logger.log('media devices', devices);

        _this3.logger.log('mediaOptions', mediaOptions);

        if (!_this3.get('integrationTestMode')) {
          return testSuite.start();
        }
      }).then(function (results) {
        if (_this3.isDestroyed || _this3.isDestroying) {
          return;
        }

        _this3.logger.info('WebRTC Troubleshooting results (success)', results);

        _this3.sendAction('results', results);

        if (_this3.done) {
          _this3.done(results);
        }
      }).catch(function (err) {
        _this3.logger.warn('WebRTC Troubleshooting results (error)', err, err && err.details);

        _this3.sendAction('results', err);

        if (_this3.done) {
          _this3.done(err);
        }
      });
    },
    runVideoBandwidthTest: Ember.computed.or('video', 'mediaOptions.screenStream'),
    runAudioBandwidthTest: Ember.computed('audio', 'runVideoBandwidthTest', function () {
      return !this.get('runVideoBandwidthTest') && this.get('audio');
    }),
    runBandwidthTest: Ember.computed.or('runVideoBandwidthTest', 'runAudioBandwidthTest'),
    symmetricNatResultGood: Ember.computed('symmetricNatResult', function () {
      var result = this.get('symmetricNatResult');

      switch (result) {
        case 'webrtcTroubleshoot.nat.asymmetric':
          return true;

        default:
          return false;
      }
    }),
    willDestroyElement: function willDestroyElement() {
      try {
        var testSuite = this.get('testSuite');

        if (testSuite && testSuite.running) {
          testSuite.stopAllTests();
        }
      } catch (e) {
        /* don't care - just want to destroy */
      }
    },
    actions: {
      toggleBandwidthStats: function toggleBandwidthStats() {
        this.toggleProperty('showBandwidthStats');
      }
    }
  });

  _exports.default = _default;
});