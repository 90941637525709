define("ember-webrtc-components/mixins/webrtc-service/auto-save-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Mixin.create({
    webrtc: inject.service(),
    selectedOutputDevice: computed('webrtc.defaultOutputDevice', {
      get: function get() {
        return this.get('webrtc.defaultOutputDevice');
      },
      set: function set(key, val) {
        if (val && val !== this.get('webrtc.defaultOutputDevice')) {
          this.get('webrtc').updateDefaultDevices({
            outputDevice: val
          });
          return val;
        }

        return this.get('webrtc.defaultOutputDevice');
      }
    }),
    selectedMicrophone: computed('webrtc.defaultMicrophone', {
      get: function get() {
        return this.get('webrtc.defaultMicrophone');
      },
      set: function set(key, val) {
        if (val && val !== this.get('webrtc.defaultMicrophone')) {
          this.get('webrtc').updateDefaultDevices({
            microphone: val
          });
        }

        return val;
      }
    }),
    selectedCamera: computed('webrtc.defaultCamera', {
      get: function get() {
        return this.get('webrtc.defaultCamera');
      },
      set: function set(key, val) {
        if (val && val !== this.get('webrtc.defaultCamera')) {
          this.get('webrtc').updateDefaultDevices({
            camera: val
          });
        }

        return val;
      }
    }),
    selectedResolution: computed('webrtc.defaultResolution', {
      get: function get() {
        return this.get('webrtc.defaultResolution');
      },
      set: function set(key, val) {
        if (val && val !== this.get('webrtc.defaultResolution')) {
          this.get('webrtc').updateDefaultDevices({
            resolution: val
          });
        }

        return val;
      }
    }),
    selectedNotificationDevice: computed('webrtc.defaultNotificationDevice', {
      get: function get() {
        return this.get('webrtc.defaultNotificationDevice');
      },
      set: function set(key, val) {
        if (val && val !== this.get('webrtc.defaultNotificationDevice')) {
          this.get('webrtc').updateDefaultDevices({
            notificationDevice: val
          });
          return val;
        }

        return this.get('webrtc.defaultNotificationDevice');
      }
    })
  });

  _exports.default = _default;
});