define("ember-purecloud-components/components/audio-player-v2/component", ["exports", "moment", "ember-purecloud-components/components/audio-player-v2/template", "ember-purecloud-components/mixins/logger"], function (_exports, _moment, _template, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var START_END_MOMENT_FORMAT_STRING = 'LLL';
  var AUDIO_ELEMENT_SELECTOR = 'audio.playback-audio';
  var DEFAULT_PROPERTIES = {
    channelOneVolumeSetting: 1,
    channelTwoVolumeSetting: 1,
    masterVolume: 1,
    playbackRate: 1,
    isPlaying: false,
    totalDuration: null,
    currentTime: 0,
    isMasterVolumeVisible: false,
    isChannelOneVolumeVisible: false,
    isChannelTwoVolumeVisible: false,
    annontationInputText: ''
  };

  var _default = Ember.Component.extend(_logger.default, {
    // layout
    layout: _template.default,
    // audioPlayerOption
    audioPlayerOptions: {
      basicAudioPlayer: false
    },
    inErrorState: false,
    ignoredErrorCodes: [20],
    retryable4xxCodes: [416, 429],
    audioOutputDeviceId: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var newRecordingId = this.get('recording.id');
      var oldRecordingId = this.get('recordingId');

      if (newRecordingId !== oldRecordingId) {
        // we need to reset our properties
        this.setProperties(DEFAULT_PROPERTIES);
        this.set('recordingId', newRecordingId);
      }
    },
    recordingId: null,
    isBasic: Ember.computed.equal('audioPlayerOptions.basicAudioPlayer', true),
    isDarkTheme: Ember.computed.equal('theme', 'dark'),
    channelOneVolume: Ember.computed('masterVolume', 'channelOneVolumeSetting', function () {
      var channelOneSetting = this.get('channelOneVolumeSetting');
      var master = this.get('masterVolume');
      return channelOneSetting * master;
    }),
    channelTwoVolume: Ember.computed('masterVolume', 'channelTwoVolumeSetting', function () {
      var channelTwoSetting = this.get('channelTwoVolumeSetting');
      var master = this.get('masterVolume');
      return channelTwoSetting * master;
    }),
    isMasterVolumeMuted: Ember.computed('masterVolume', function () {
      return this.get('masterVolume') <= 0;
    }),
    isChannelOneMuted: Ember.computed('channelOneVolume', function () {
      return this.get('channelOneVolume') <= 0;
    }),
    isChannelTwoMuted: Ember.computed('channelTwoVolume', function () {
      return this.get('channelTwoVolume') <= 0;
    }),
    masterVolumeButtonId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-audio-playback-master-volume-button");
    }),
    channelOneParticipants: Ember.computed('participants', function () {
      var participants = this.get('participants');
      var includePurposes = Ember.A(['agent', 'user']);
      return this.pickParticipantsByPurposes(participants, includePurposes);
    }),
    channelTwoParticipants: Ember.computed('participants', function () {
      var participants = this.get('participants');
      var includePurposes = Ember.A(['customer', 'external']);
      return this.pickParticipantsByPurposes(participants, includePurposes);
    }),
    totalDurationInMs: Ember.computed('totalDuration', function () {
      // should this be based on totalDuration or a computed of start/end time?
      return this.get('totalDuration') * 1000;
    }),
    currentTimeInMs: Ember.computed('currentTime', function () {
      return this.get('currentTime') * 1000;
    }),
    loadingTotalDuration: Ember.computed('totalDuration', function () {
      var totalDuration = this.get('totalDuration');
      return !(totalDuration || totalDuration === 0);
    }),
    loadingCurrentTime: Ember.computed('currentTime', function () {
      var currentTime = this.get('currentTime');
      return !(currentTime || currentTime === 0);
    }),
    startTime: Ember.computed('recording.startTime', function () {
      return (0, _moment.default)(this.get('recording.startTime')).format(START_END_MOMENT_FORMAT_STRING);
    }),
    endTime: Ember.computed('recording.endTime', function () {
      return (0, _moment.default)(this.get('recording.endTime')).format(START_END_MOMENT_FORMAT_STRING);
    }),
    // Tether ids
    qualityEngineChannelOneVolumeId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-qualityEngineChannelOneVolume");
    }),
    qualityEngineChannelTwoVolumeId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-qualityEngineChannelTwoVolume");
    }),
    // actions
    actions: {
      playOrPause: function playOrPause() {
        var playing = this.get('isPlaying');
        playing ? this.pause() : this.play();
      },
      handlePlaying: function handlePlaying() {
        this.set('isPlaying', true);
      },
      handlePaused: function handlePaused() {
        this.set('isPlaying', false);
      },
      handlePositionChange: function handlePositionChange(percent) {
        var totalDuration = this.get('totalDuration');
        var newPosition = totalDuration * percent;
        this.setPlaybackPosition(newPosition);
      },
      handleTimeUpdate: function handleTimeUpdate(event) {
        // update currentTime based on a "master" channel (audio element with src === mediaUris['0'])
        var isMaster = event.target.src === this.get('recording.mediaUris.0.mediaUri');
        var currentTime = event.target.currentTime;

        if (isMaster && currentTime) {
          this.set('currentTime', currentTime);
        }
      },
      handleDurationChange: function handleDurationChange(event) {
        if (event.target && event.target.duration) {
          var duration = event.target.duration;
          this.set('totalDuration', duration); // converted to ms
        }
      },
      handleEnded: function handleEnded() {
        this.setPlaybackPosition(0);
      },
      setPlaybackRate: function setPlaybackRate() {
        var rate = this.get('playbackRate');
        this.set('playbackRate', rate > 1 ? 1 : 2);
      },
      downloadRecording: function downloadRecording() {
        var recordingId = this.get('recordingId');

        if (recordingId) {
          this.downloadRecording(recordingId);
        }
      },
      createAnnotation: function createAnnotation() {
        var annotationInputText = this.get('annotationInputText');
        var currentTimeInMs = this.get('currentTimeInMs');
        var recordingId = this.get('recording.id');
        this.sendAction('createAnnotation', annotationInputText, currentTimeInMs, recordingId);
        this.set('annotationInputText', '');
      },
      toggleMasterVolumeVisbility: function toggleMasterVolumeVisbility() {
        this.toggleProperty('isMasterVolumeVisible');
      },
      toggleChannelOneVolumeVisibility: function toggleChannelOneVolumeVisibility() {
        this.toggleProperty('isChannelOneVolumeVisible');
      },
      toggleChannelTwoVolumeVisibility: function toggleChannelTwoVolumeVisibility() {
        this.toggleProperty('isChannelTwoVolumeVisible');
      }
    },
    isRetryable: function isRetryable(error) {
      if (!error || !error.status) {
        return false;
      }

      return this.get('retryable4xxCodes').includes(error.status) || error.status >= 500;
    },
    play: function play() {
      var _this = this;

      var maxAttempts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;

      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      var audioOutputDeviceId = this.get('audioOutputDeviceId');
      this.$(AUDIO_ELEMENT_SELECTOR).each(function (idx, element) {
        if (audioOutputDeviceId && element.setSinkId) {
          element.setSinkId(audioOutputDeviceId);
        }

        var promise = element.play();

        if (promise && promise.then && promise.catch) {
          promise.then(function () {
            return _this.set('inErrorState', false);
          }).catch(function (error) {
            if (maxAttempts > 1 && _this.isRetryable(error)) {
              _this.logger.debug('Play error in audio-player-v2, retrying.', {
                error: error
              });

              Ember.run.later(function () {
                return _this.play(maxAttempts - 1);
              }, 1 / maxAttempts * 5000);
            } else {
              _this.logger.debug('Play error in audio-player-v2, retries exhausted.', {
                error: error
              });

              var ignoredErrorCodes = _this.get('ignoredErrorCodes');

              if (!ignoredErrorCodes.includes(error.code)) {
                _this.set('inErrorState', true);
              }
            }
          });
        }
      });
    },
    pause: function pause() {
      this.$(AUDIO_ELEMENT_SELECTOR).each(function (idx, element) {
        element.pause();
      });
    },
    setPlaybackPosition: function setPlaybackPosition(newPosition) {
      this.$(AUDIO_ELEMENT_SELECTOR).each(function (idx, element) {
        element.currentTime = newPosition;
      });
    },
    pickParticipantsByPurposes: function pickParticipantsByPurposes(participants, includePurposes) {
      return participants.filter(function (participant) {
        var purpose = Ember.get(participant, 'purpose');
        return includePurposes.includes(purpose.toLowerCase());
      });
    }
  });

  _exports.default = _default;
});