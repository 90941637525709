define('ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/component', ['exports', 'ember', 'ember-chat-components/mixins/chat-commands', 'ember-chat-components/utils/credit-card', 'ember-purecloud-components/utils/emoji', 'ember-purecloud-components/utils/jid-helpers', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/mixins/dropzone', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/mixins/textarea-events', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/template'], function (exports, _ember, _chatCommands, _creditCard, _emoji, _jidHelpers, _dropzone, _textareaEvents, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed, get, inject, observer, run } = _ember.default;

    const ACTIVE = 'active';
    const PAUSED = 'paused';
    const COMPOSING = 'composing';

    // Inline reply state is shared across rooms
    let INLINE_REPLY_STATE = '';

    function escapeName(name = '') {
        const apostrophe = _ember.default.Handlebars.Utils.escapeExpression("'");
        const escaped = _ember.default.Handlebars.Utils.escapeExpression(name);
        return escaped.replace(apostrophe, "'");
    }

    exports.default = Component.extend(_chatCommands.default, _dropzone.default, {
        classNames: ['chat-textarea'],
        classNameBindings: ['isFocused:focused'],
        layout: _template.default,

        intl: inject.service(),
        chat: inject.service(),
        timeline: inject.service(),
        application: inject.service(),
        notification: inject.service(),
        session: inject.service(),
        preferences: inject.service(),
        permissions: inject.service(),
        ajax: inject.service(),
        logger: inject.service('stash-logger'),

        disableTextArea: false,
        disableFileUploads: false,
        disableInfoDisplay: false,
        acdToggle: false,

        isMessageEdit: false,

        isAtBottom: true,

        showStatus: false,
        allowEmpty: false,
        initialValue: '',
        inputCachingEnabled: true,
        buildingAutocomplete: false,

        inlineReply: false,
        directReply: false,

        canUseGiphy: computed('permissions', function () {
            return this.get('permissions.canUseGiphy');
        }),

        giphyQuery: computed('messageInput', function () {
            const text = this.get('messageInput');
            const prefix = '/giphy';
            if (text && text.startsWith(prefix)) {
                let query = text.slice(prefix.length).trim();
                return query;
            }
            return '';
        }),
        giphyFocus: false,

        sharingDoc: false,

        mentionList: null,
        // passed in from inline-reply
        // the quoted chat user should be added to the mention list
        // so if they're not in the chat, the warning will show for them
        quotedUser: null,
        messageUser: null,

        stateResponder: null,

        // See @_updateTextarea()
        hasScroll: false,
        _$textarea: null,
        _textareaEl: null,

        messageInput: '',
        messageInputReplacement: '',

        messageBeforeSend: '',

        hideScrollButton: computed('chatPanelSelected', 'isAtBottom', function () {
            return this.get('isAtBottom') || !this.get('chatPanelSelected');
        }),

        actions: {
            handleMentionSelection(selectionInfo) {
                let message = this.get('messageInput');

                if (selectionInfo.type === 'people') {
                    message = `${message.substring(0, selectionInfo.startIndex)}[@${selectionInfo.selected.name}] ${message.substring(selectionInfo.startIndex + selectionInfo.replace.length)}`;
                    this.mentionList.push(selectionInfo.selected);
                } else if (selectionInfo.type === 'emoji') {
                    message = `${message.substring(0, selectionInfo.startIndex)}${(0, _emoji.emojifyStringUnicode)(`:${selectionInfo.selected.name}:`)} ${message.substring(selectionInfo.startIndex + selectionInfo.replace.length)}`;
                }

                this.setTextareaValue(message);
                this.focusTextarea();
            },
            handleMentionsVisible(isActive) {
                this.set('mentionsVisible', isActive);
            },
            chooseGif(gif) {
                const value = this.get('messageInput');

                this.updateTextarea();

                let url = gif.embed_url;
                let imageUrl = _ember.default.get(gif, 'images.original.url');
                if (imageUrl) {
                    url = imageUrl;
                }
                if (url) {
                    this.sendAction('sendMessage', `\`${value}\`\n\n${url}`, this.get('activeRoom'));
                } else {
                    this.sendAction('sendMessage', value, this.get('activeRoom'));
                }

                this.setTextareaValue('');
                this.focusTextarea();
            },

            scrollToBottom() {
                this.sendAction('scrollToBottom');
                this.focusTextarea();
            },

            onInput(event) {
                this.set('messageInput', get(event, 'target.value'));
                this.updateTextarea();
            },

            focus() {
                this.focusTextarea();
            },

            onFocus() {
                this.set('giphyFocus', false);
                this.set('isFocused', true);
            },

            onBlur() {
                this.set('isFocused', false);
            },

            chooseEmoji(emoji) {
                const messageInput = this.get('messageInput');

                if (emoji) {
                    this.setTextareaValue(messageInput + `${emoji}`);
                    this.focusTextarea();
                }
            },
            neverShowNotification(notification) {
                this.disableMentionNotification();
                notification.hide();
            },
            cancelAtMentionNotification(notification) {
                notification.hide();
            }
        },

        init() {
            this.set('mentionList', []);
            this._super(...arguments);
            const owner = _ember.default.getOwner(this);
            this.set('search', owner.lookup('service:search'));

            this.componentId = this.elementId;

            this.updateMentionListForQuotedUser();
        },

        hasCreditCard: computed('messageInput', function () {
            const text = this.get('messageInput');
            return (0, _creditCard.hasCreditCard)(text);
        }),

        redactCreditCard(text) {
            const intl = this.get('intl');
            return (0, _creditCard.redactCreditCard)(text, code => {
                return intl.t('chat.compose.creditCardFilter', { code });
            });
        },

        roomMessageCache: computed(function () {
            return {};
        }),

        disableRoomInteraction: computed('activeRoom.{jid,joinError}', 'messagePendingForUpload', 'disableTextArea', function () {
            return !this.get('activeRoom.jid') || this.get('activeRoom.joinError') || this.get('messagePendingForUpload') || this.get('disableTextArea');
        }),

        messagePendingForUpload: false,

        joinError: computed.reads('activeRoom.joinError'),

        activeRoom: computed({
            get() {
                return null;
            },

            set(prop, room) {
                const oldRoom = this.get('activeRoom.jid');
                const newRoom = room ? room.get('jid') : null;
                const messageCache = this.get('roomMessageCache');
                const pendingMessage = this.get('messageInput');
                const cachingEnabled = this.get('inputCachingEnabled');
                const isInlineReply = this.get('inlineReply');

                if (newRoom !== oldRoom && (newRoom || oldRoom)) {
                    if (cachingEnabled) {
                        if (isInlineReply) {
                            INLINE_REPLY_STATE = pendingMessage;
                        } else if (oldRoom && pendingMessage) {
                            messageCache[oldRoom] = pendingMessage;
                        }

                        if (newRoom && (Object.prototype.hasOwnProperty.call(messageCache, newRoom) || isInlineReply)) {
                            let content = isInlineReply ? INLINE_REPLY_STATE : messageCache[newRoom];
                            this._setTextareaValue(content);
                            delete messageCache[newRoom];
                            INLINE_REPLY_STATE = '';
                            return room;
                        }
                    }

                    this._setTextareaValue('');
                }

                return room;
            }
        }),

        helpTextResourceLink: computed('intl.isoLocale', function () {
            // TODO: Add locale information when resource center has support
            return 'http://help.mypurecloud.com/redirect/en-US/directory.formatTextChats';
        }),

        isResponding: computed.and('showStatus', 'respondingMessage'),

        roomTextareaStatus: computed.reads('activeRoom.textareaStatus'),
        roomHasTextareaStatus: computed('activeRoom', 'activeRoom.textareaStatus', function () {
            return !!this.get('activeRoom.textareaStatus');
        }),

        // eslint-disable-next-line ember/no-observers
        focusInputOnRoomChange: observer('activeRoom.jid', function () {
            if (this.get('activeRoom.jid')) {
                run.scheduleOnce('afterRender', () => {
                    this.updateTextarea();
                    this.focusTextarea();
                });
            }
        }),

        // eslint-disable-next-line ember/no-observers
        watchMessageInputReplacement: observer('messageInputReplacement', function () {
            const replacementText = this.get('messageInputReplacement');
            if (replacementText) {
                let $textbox = this.get('_$textarea'),
                    currentValue = $textbox.val(),
                    isEmpty = currentValue.length === 0;

                let [selectStart, selectEnd] = [$textbox[0].selectionStart, $textbox[0].selectionEnd];

                // cut out anything that's been selected
                let beforeSelectionString = currentValue.substring(0, selectStart),
                    afterSelectionString = currentValue.substring(selectEnd);

                // add a line break if at the end of nonempty input
                let needsNewline = !isEmpty && selectStart === currentValue.length,
                    optionalNewline = needsNewline ? '\n' : '';

                // put together the final new input text & final cursor position
                let updatedReplacementText = beforeSelectionString + optionalNewline + replacementText + afterSelectionString,
                    updatedCursorPosition = selectStart + replacementText.length + optionalNewline.length;

                this.setTextareaValue(updatedReplacementText);

                run.scheduleOnce('afterRender', () => {
                    this.set('messageInputReplacement', null);
                    this.updateTextarea();
                    this.focusTextarea();
                    this.selectTextareaRange(updatedCursorPosition, updatedCursorPosition);
                });
            }
        }),

        respondingMessage: computed('chat.responding.[]', 'activeRoom.{jid,objectId}', function () {
            const responding = this.get('chat.responding');
            if (!responding) {
                return;
            }

            let respondingList = responding[this.get('activeRoom.jid')];

            if (!respondingList || respondingList.length === 0) {
                return null;
            }

            let intlKey, options;
            if (respondingList.length === 1) {
                let [user] = respondingList;
                intlKey = 'chat.responding.single';
                options = { user };
            } else if (respondingList.length === 2) {
                let [user1, user2] = respondingList;
                intlKey = 'chat.responding.double';
                options = { user1, user2 };
            } else {
                intlKey = 'chat.responding.several';
                options = {};
            }

            return this.get('intl').t(intlKey, options);
        }),

        didInsertElement() {
            this._super(...arguments);
            run.scheduleOnce('afterRender', this, this.addBehavior);
        },

        willDestroyElement() {
            this._super(...arguments);

            const textarea = this.get('_$textarea');
            if (textarea) {
                textarea.off().atwho('destroy');
            }

            this._destroyCommandsAutoComplete(this.get('_$textarea'));

            const windowRef = _ember.default.$(window);
            if (windowRef) {
                windowRef.off(`.${this.get('elementId')}`);
            }

            this.setProperties({
                _$textarea: null,
                _textareaEl: null
            });
        },

        addBehavior() {
            const textarea = this.$('textarea');

            this.setProperties({
                _$textarea: textarea,
                _textareaEl: textarea.get(0)
            });

            this.setupInitialValue();
            this.sendMessageEvent();

            this.autoBlockPasteEvent();
            this.cleanupChatStateOnDisconnect();

            this.focusInputOnRoomChange();
        },

        setupInitialValue() {
            const initialValue = this.get('initialValue');
            if (initialValue) {
                this.setTextareaValue(initialValue);
            }
        },

        sendMessageEvent() {
            const textarea = this.get('_$textarea');

            textarea.on('keypress', _ember.default.run.bind(this, _textareaEvents.keypress));
            textarea.on('keyup', _ember.default.run.bind(this, _textareaEvents.keyup));
            textarea.on('keydown', _ember.default.run.bind(this, _textareaEvents.keydown));
        },

        updateMentionListForQuotedUser() {
            // if we're quoting a user
            // add that user to the mention list so
            // that we can pop the mention warning if they're
            // not in the chat
            const isInlineReply = this.get('inlineReply');
            if (isInlineReply) {
                this.createMentionItemFromQuotedUser();
            }
        },
        createMentionItemFromQuotedUser() {
            const quotedUser = this.get('quotedUser');
            const name = escapeName(quotedUser.get('name'));
            const title = escapeName(quotedUser.get('title')) || '';
            const entityId = (0, _jidHelpers.getEntityObjectId)(quotedUser.get('jid'));
            const profilePicture = quotedUser.get('profilePicture');
            const person = {
                name,
                title,
                id: entityId || quotedUser.get('id') || quotedUser.get('guid'),
                jid: quotedUser.get('jid'),
                profilePicture,
                roomJid: this.get('activeRoom.jid'),
                isQuotedUser: true
            };
            // push quoted user to front of mention list
            this.mentionList.unshift(person);
        },
        removeQuotedUserFromMentionList() {
            const quotedUser = this.get('quotedUser');
            const entityId = this.getMentionId(quotedUser);
            this.set('mentionList', this.mentionList.filter(mention => _ember.default.get(mention, 'id') !== entityId));
        },

        // To check if mention is enclosed inside 1 or 3 ticks
        isMentionInsideTicks(mention, regexForTicks) {
            return (mention.match(regexForTicks) || []).length % 2 === 0;
        },

        buildMentionList(message) {
            // Mention Stuff
            const mentionList = this.get('mentionList');
            const room = this.get('activeRoom');

            const parsedMessage = mentionList.reduce((message, { name, id, sendInvite }) => {
                const mentionName = sendInvite ? `[@${name}]` : `[${name}]`;

                const replaceName = `[@${name}]`;
                const safeString = this._escapeRegex(replaceName);
                const regexp = new RegExp(`${safeString}(?![(]#)`, 'g');

                const match = regexp.exec(message);
                if (match) {
                    const mention = message.substring(0, match.index);
                    const shouldReplace = this.isMentionInsideTicks(mention, /`/g) && this.isMentionInsideTicks(mention, /```/g);
                    if (shouldReplace) {
                        const fullMention = `${mentionName}(#/person/${id})`;
                        message = message.replace(regexp, fullMention);
                    }
                }
                return message;
            }, message);

            const childrenMap = new Map();
            (mentionList || []).forEach(item => {
                if (item.sendInvite && !childrenMap.has(item.id)) {
                    childrenMap.set(item.id, item);
                }
            });

            const children = [...new Set(childrenMap.values())].map(item => this.buildXMPPReference(item, parsedMessage)).flat();

            // only clear the mentions in the active room
            this.set('mentionList', mentionList.filter(mentionItem => mentionItem.roomJid !== room.get('jid')));

            return {
                parsedMessage,
                children
            };
        },

        buildXMPPReference(mentionItem, parsedMessage) {
            const { name, jid } = mentionItem;
            const replaceName = `[@${name}]`;
            const safeString = this._escapeRegex(replaceName);
            const mentionShorthandRegex = new RegExp(`${safeString}\\(#/person/([a-z0-9-]{24,36})\\)`, 'g');

            let result = mentionShorthandRegex.exec(parsedMessage);
            let stanzas = [];
            while (result) {
                const end = result.index + result[0].length;
                const reference = {
                    name: 'reference',
                    attributes: {
                        xmlns: 'urn:xmpp:reference:0',
                        type: 'mention',
                        begin: result.index,
                        uri: jid,
                        end
                    }
                };
                stanzas.push(reference);
                result = mentionShorthandRegex.exec(parsedMessage);
            }
            return stanzas;
        },

        autoBlockPasteEvent() {
            const textarea = this.get('_$textarea');

            textarea.on('drop', _ember.default.run.bind(this, _textareaEvents.drop));
            textarea.on('paste', _ember.default.run.bind(this, _textareaEvents.paste));
        },

        async propagateMessageEvent(message, room = null) {
            const dropzone = this.get('dropzone');

            if (!room) {
                room = this.get('activeRoom');
            }

            if (message && message.length > 0 || this.get('allowEmpty')) {
                const options = {
                    isMessageEdit: this.get('isMessageEdit')
                };
                message = this.checkForCommand(message, options);

                if (!message && !this.get('allowEmpty')) {
                    // the message could only have a chat command
                    // which would make the message empty so we need to
                    // clear the textarea
                    this.setTextareaValue('');
                    return;
                }

                // remove any mentions that have been erased from the message
                this.removeMentionsNotInMessage(message);

                // check if personal group or official group
                // also checks feature toggle for PURE-3112
                // if quoting a user to DM, then do not show modal
                if (this.isPersonalOrGroup(room) && !this.get('directReply')) {
                    // get the mention items not in the chat
                    const mentionsNotInChat = await this.getMentionsNotInChat(room);
                    if (mentionsNotInChat.length > 0) {
                        this.set('messageBeforeSend', message);

                        // the mention warning modal options
                        // bind the cancel and confirm methods to the textarea
                        // so we can still send the messages from mention warning modal
                        const opts = {
                            cancelMentionAdd: this.get('cancelMentionAdd').bind(this),
                            confirmMentionAdd: this.get('confirmMentionAdd').bind(this),
                            mentionsNotInChat
                        };

                        // open the mention warning modal
                        this.get('openMentionWarning')(opts);
                    } else {
                        // no new mentions
                        this.callSendMessageEvent(message, room);
                    }
                } else if (this.get('mentionList').length > 0) {
                    const filteredMentions = this.filterMentionListForOneToOne(this.get('mentionList'), room);
                    if (filteredMentions.length > 0) {
                        // A direct message that contains a mention
                        // notify that nothing is going to happen
                        const notificationTitle = this.get('intl').t('chat.dmWarning.title');
                        const notificationMessage = this.get('intl').t('chat.dmWarning.message');

                        // disable 1-1 chat mention notification
                        this.getMentionNotificationPreference().then(mentionNotificationDisabled => {
                            if (!mentionNotificationDisabled) {
                                this.get('notification').info(notificationTitle, notificationMessage, {
                                    target: this,
                                    actionHandlers: {
                                        neverShowNotification: this.get('intl').t('notification.app.neverShowAgain'),
                                        cancelAtMentionNotification: this.get('intl').t('common.cancel')
                                    }
                                });
                            }
                        });
                    }

                    // since we're removing the at-mention symbol but keeping
                    // the user links, we can reuse the cancelMentionAdd function
                    this.set('messageBeforeSend', message);
                    this.cancelMentionAdd(this.get('mentionList'));
                } else {
                    // not a personal room or group chat
                    this.callSendMessageEvent(message, room);
                }
            } else if (dropzone && dropzone.files && dropzone.files.length > 0) {
                const chat = this.get('chat');
                chat.clearRoomUnreadMessages(chat.get('activeRoom'));
            }
        },

        isPersonalOrGroup(room) {
            // feature toggle for PURE-3112
            return room.get('isAdhocRoom') || room.isGroupRoom();
        },

        /** If user removes the [@Name] from the message, try to remove any mentions associated. */
        removeMentionsNotInMessage(message) {
            // filter out mention item that aren't quoted user
            const mentionList = this.get('mentionList');
            const copyMentionList = [...mentionList.filter(m => !m.isQuotedUser)];

            // hold the count of names where name is key
            const nameMatches = {};

            // remove any mentions from mentionList
            copyMentionList.forEach(mentionItem => {
                const { name } = mentionItem;

                // init to zero
                if (!nameMatches[name]) {
                    nameMatches[name] = 0;
                }

                // increment the name count
                nameMatches[name]++;

                const replaceName = `[@${name}]`;
                const safeString = this._escapeRegex(replaceName);
                const regexp = new RegExp(`${safeString}(?![(]#)`, 'g');

                // get all occurrences of the mention in the message
                const occurrences = (message.match(regexp) || []).length;

                // if the amount of mentions with this name is greater than occurrences
                // remove from mention list
                if (nameMatches[name] > occurrences) {
                    const idx = mentionList.findIndex(mention => mention.id === mentionItem.id);
                    if (idx > -1) {
                        mentionList.splice(idx, 1);
                    }
                }
            });

            // reset the mention list
            this.set('mentionList', mentionList);
        },

        /** Get mention items not in the room */
        async getMentionsNotInChat(room) {
            // list of promises that either resolve to the mentionItem (not in chat) or null (if in chat)
            const mentionListPromises = this.uniqueMentionsById(this.get('mentionList')).map(mentionItem => {
                // check the participant list first
                // this can help reduce api calls
                const notInChat = !this.localParticipantLookup(room, mentionItem);

                if (notInChat) {
                    // if not in chat, then make api call
                    return this.apiParticipantLookup(room, mentionItem);
                }
                // user is in chat so return null to be filtered out later
                return _ember.default.RSVP.resolve(null);
            });

            const mentions = await Promise.all(mentionListPromises);

            // filter out the nulls since those are users that are in the chat
            return mentions.filter(mentionItem => mentionItem !== null);
        },
        /**
         * Filters out the current authenticated user and other DM user
         * from the mention list.
         */
        filterMentionListForOneToOne(mentions, room) {
            if (this.get('session.person')) {
                const currentUserId = this.getMentionId(this.get('session.person'));
                let otherUserId;
                if (room.get('oneToOneTarget')) {
                    otherUserId = this.getMentionId(room.get('oneToOneTarget'));
                } else if (this.get('directReply') && this.get('inlineReply') && this.get('messageUser')) {
                    // messageUser is equivalent to message.chatUser
                    // chatUser is the user that's being quoted
                    otherUserId = this.getMentionId(this.get('messageUser'));
                }

                if (otherUserId) {
                    const filteredMentions = mentions.filter(mention => {
                        const mentionId = this.getMentionId(mention);
                        return mentionId !== currentUserId && mentionId !== otherUserId;
                    });
                    return filteredMentions;
                }
            }

            return mentions;
        },
        getMentionId(entity) {
            return (0, _jidHelpers.getEntityObjectId)(_ember.default.get(entity, 'jid')) || _ember.default.get(entity, '_id') || _ember.default.get(entity, 'guid');
        },
        /** returns unique list of mentions by id */
        uniqueMentionsById(mentions) {
            return mentions.filter((mentionA, idx) => mentions.findIndex(mentionB => _ember.default.get(mentionA, 'id') === _ember.default.get(mentionB, 'id')) === idx);
        },
        localParticipantLookup(room, mentionItem) {
            const inChat = room.participants.map(person => this.getMentionId(person)).indexOf(mentionItem.id) > -1;
            return inChat;
        },
        apiParticipantLookup(room, mentionItem) {
            let url = this.get('application').pcV2Uri(`api/v2/chats/rooms/${room.get('jid')}/participants/${_ember.default.get(mentionItem, 'jid')}`);
            return this.get('ajax').request(url, { method: 'GET' }).then(() => null) // if no error, user is in chat, so we return null
            .catch(err => {
                if (err.status !== 404) {
                    // api returns a 404 if user is not a participant
                    // if status is not a 404 log the error
                    this.get('logger').error('Error querying participant in current room', {
                        err,
                        roomJid: room.get('jid'),
                        participantJid: _ember.default.get(mentionItem, 'jid')
                    });
                }
                // if error, user isn't in chat so return the mention item
                return mentionItem;
            });
        },
        confirmMentionAdd() {
            this.callSendMessageEvent(this.get('messageBeforeSend'), this.get('activeRoom'));
        },
        cancelMentionAdd(mentionsNotInChat) {
            // get the message containing the mentions
            let message = this.get('messageBeforeSend');

            // get the full mention list
            const mentionList = this.get('mentionList');

            // boolean to strip the @mention from quoted user
            if (this.get('inlineReply')) {
                const quotedUserNotInChat = mentionsNotInChat.findIndex(item => item.isQuotedUser) > -1;
                if (quotedUserNotInChat && this.get('setKeepMention')) {
                    this.get('setKeepMention')(false);
                }
            }

            // set sendInvite to false on each mention item
            mentionsNotInChat.forEach(mentionItem => {
                const mentionListItems = mentionList.filter(m => m.id === mentionItem.id);
                if (mentionListItems.length > 0) {
                    mentionListItems.forEach(item => item.sendInvite = false);
                }
            });

            // update the mention list
            this.set('mentionList', mentionList);

            // send the message
            this.callSendMessageEvent(message, this.get('activeRoom'));
        },

        disableMentionNotification() {
            const preferences = this.get('preferences');
            preferences.setPreference('chat.disableAtMentionNotification', true);
        },
        async getMentionNotificationPreference() {
            const preferences = this.get('preferences');
            try {
                const setting = await preferences.getPreference('chat.disableAtMentionNotification');
                return setting;
            } catch (err) {
                _ember.default.Logger.error('Error getting chat.disableAtMentionNotification preference:', {
                    error: err
                });
                return false;
            }
        },

        /**
         * Sends the message
         */
        callSendMessageEvent(message, room) {
            // parse the message with mentions
            const { parsedMessage, children } = this.buildMentionList(message);

            let options;
            if (children && children.length > 0) {
                options = {
                    to: room.get('jid'),
                    body: parsedMessage,
                    children
                };
            }

            // clear the text area
            this.setTextareaValue('');

            // send the message
            this.get('sendMessage')(parsedMessage, room, options).then(() => {
                this.disableMessagePendingForUploadFlag();
            });

            this.propagateChatState();

            run.scheduleOnce('afterRender', () => {
                this.updateTextarea();
            });
        },

        disableMessagePendingForUploadFlag() {
            if (this._state === 'inDOM') {
                this.set('messagePendingForUpload', false);
            }
        },

        propagateChatState() {
            const chat = this.get('chat');
            const message = (this.get('messageInput') || '').trim();

            run.cancel(this.get('stateResponder'));

            const quotingToDM = this.get('inlineReply') && this.get('directReply');
            const target = quotingToDM ? this.get('quotedUser.jid') : this.get('activeRoom.jid');

            if (!message) {
                chat.sendChatState(target, ACTIVE);
            } else {
                chat.sendChatState(target, COMPOSING);
                this.set('stateResponse', run.later(() => {
                    chat.sendChatState(target, PAUSED);
                }, _ember.default.testing ? 200 : 20000));
            }
        },

        cleanupChatStateOnDisconnect() {
            _ember.default.$(window).on(`beforeunload.${this.get('elementId')}`, () => {
                _ember.default.run(() => {
                    const activeRoom = this.get('activeRoom.jid');
                    if (activeRoom) {
                        this.get('chat').sendChatState(activeRoom, PAUSED);
                    }
                });
            });
        },

        setTextareaValue(value) {
            run.scheduleOnce('afterRender', this, this._setTextareaValue, value);
        },

        focusTextarea() {
            const textarea = this.get('_$textarea');
            if (textarea) {
                // hack for focusing the text area at the end of it's input
                const val = textarea.val();
                textarea.focus().val('').val(val);
            }
        },

        selectTextareaRange(start, end) {
            const textEl = this.get('_textareaEl');
            if (textEl) {
                textEl.selectionStart = start;
                textEl.selectionEnd = end;
            }
        },

        _setTextareaValue(value) {
            if (this.get('isDestroyed') || this.get('isDestroying')) {
                return;
            }
            const textarea = this.get('_$textarea');
            if (textarea) {
                textarea.val(value);
            }
            this.set('messageInput', value);
        },

        updateTextarea() {
            run.throttle(this, this._updateTextarea, 750);
        },

        _updateTextarea() {
            const { _$textarea, _textareaEl, hasScroll } = this.getProperties('_$textarea', '_textareaEl', 'hasScroll');

            if (!_$textarea || !_textareaEl) {
                return;
            }

            // hasScroll handling to offset emoji picker positioning.
            if (_textareaEl.scrollHeight > _textareaEl.clientHeight && !hasScroll) {
                this.set('hasScroll', true);
            } else if (_textareaEl.scrollHeight === _textareaEl.clientHeight && hasScroll) {
                this.set('hasScroll', false);
            }

            // Enlargement/resize handling for when the textarea as large content.
            _$textarea.css('height', 'auto'); // reset first

            const currentHeight = _$textarea.outerHeight();
            if (currentHeight < 60 || currentHeight > 120) {
                return;
            }

            const finalHeight = _$textarea.prop('scrollHeight');
            _$textarea.css('height', finalHeight);

            this.sendAction('onResize');
        },

        _escapeRegex(string) {
            // eslint-disable-next-line no-useless-escape
            return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        }
    });
});