define("ember-purecloud-components/components/entity-contact-group/component", ["exports", "ember-data", "ember-purecloud-components/components/entity-contact-group/template", "lodash.debounce", "lodash.isempty"], function (_exports, _emberData, _template, _lodash, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-contact-group'],
    classNameBindings: ['compact'],
    canViewChat: true,
    canViewVideo: true,
    entity: null,
    videoTooltipTitle: '',
    linkProfile: false,
    showFavorite: false,
    showImages: true,
    compact: false,
    phoneServiceEnabled: false,
    phoneAvailable: false,
    isOneToOneOfflineOrBusy: true,
    isChatEnabled: false,
    isWebrtcEnabled: false,
    isVideoButtonEnabled: false,
    onContact: function onContact() {},
    callEntity: function callEntity() {},
    toggleFavorite: function toggleFavorite() {},
    useMediaProviders: false,
    actions: {
      makeCall: (0, _lodash.default)(function () {
        if (!this.get('phoneAvailable')) {
          return;
        }

        if (this.get('isGroup')) {
          this.callEntity(this.get('entity.contactInfo.groupNumber.firstObject'));
        } else {
          this.callEntity(this.get('entity'));
        }

        this.get('onContact')();
      }, 250),
      startChat: function startChat() {
        var entity = this.get('entity');
        this.bubbleAction({
          action: 'openChatRoom',
          actionContext: [entity.get('jid')]
        });
        this.get('onContact')();
      },
      sendLove: function sendLove() {
        var entity = this.get('entity');
        this.bubbleAction({
          action: 'sendLove',
          actionContext: [entity]
        });
        this.get('onContact')();
      },
      launchWebrtc: function launchWebrtc() {
        if (this.get('isOneToOneOfflineOrBusy') || !this.get('isVideoButtonEnabled')) {
          return;
        }

        var entity = this.get('entity');
        this.bubbleAction({
          action: 'openChatRoomWithVideo',
          actionContext: [entity.get('jid'), this.get('useMediaProviders')]
        });
        this.get('onContact')();
      },
      showGallery: function showGallery() {
        this.sendAction('viewLightGallery');
        this.get('onContact')();
      }
    },
    isPerson: Ember.computed.equal('entity.type', 'person'),
    isGroup: Ember.computed.equal('entity.type', 'group'),
    linkToProfileRoute: Ember.computed('entity.type', function () {
      var type = this.get('entity.type');

      if (type === 'group') {
        return 'group.detail';
      }

      return 'person.detail';
    }),
    isSelf: Ember.computed('entity.id', 'self.id', function () {
      return this.get('entity.id') === this.get('self.id');
    }),
    hasImages: Ember.computed('entity.uploadedInformalPhotos.[]', 'entity.images.informal.[]', 'entity.images.gallery.[]', function () {
      if (!this.get('showImages')) {
        return false;
      }

      var personHasImage = this.get('entity.type') === 'person' && !!this.get('entity.images.informal.length');
      var groupHasImage = this.get('entity.type') === 'group' && !!this.get('entity.images.gallery.length');
      return personHasImage || groupHasImage;
    }),
    hasPhone: Ember.computed('entity.phone', 'entity.contactInfo.groupNumber', function () {
      if (this.get('isGroup')) {
        return !(0, _lodash2.default)(this.get('entity.contactInfo.groupNumber'));
      }

      return !(0, _lodash2.default)(this.get('entity.phone'));
    }),
    email: Ember.computed('entity.primaryContactInfo.email.firstObject.value.fieldId', 'isPerson', function () {
      if (!this.get('isPerson')) {
        return;
      }

      var entity = this.get('entity');

      if (entity instanceof _emberData.default.PromiseObject) {
        entity = entity.get('content');
      }

      if (!entity) {
        return;
      }

      var email = entity.getFieldValue('primaryContactInfo.email');
      return email.value;
    }),
    phone: Ember.computed('entity.primaryContactInfo.voice.firstObject.value.fieldId', 'isPerson', 'entity.contactInfo.groupNumber.firstObject.value.fieldId', 'isGroup', function () {
      if (!this.get('isPerson') && !this.get('isGroup')) {
        return;
      }

      var entity = this.get('entity');

      if (entity instanceof _emberData.default.PromiseObject) {
        entity = entity.get('content');
      }

      if (!entity) {
        return;
      }

      var phone = entity.getFieldValue('primaryContactInfo.voice', true);

      if (this.get('isGroup')) {
        phone = entity.getFieldValue('contactInfo.groupNumber', true);
      }

      if (phone && phone.value && phone.value.fieldPath) {
        return entity.getFieldValue(phone.value.fieldPath);
      }

      return phone;
    }),
    mailto: Ember.computed('email', 'emailEnabled', function () {
      if (this.get('emailEnabled')) {
        return "mailto:".concat(this.get('email'));
      }

      return '';
    }),
    tel: Ember.computed('phone', function () {
      var phone = this.get('phone');

      if (phone.rel) {
        return "tel:".concat(phone.rel.e164);
      }
    }),
    emailEnabled: Ember.computed.notEmpty('email'),
    // Phone button enabled if a phone is available or the entity has a phone number to use for a tel: link
    phoneEnabled: Ember.computed('phoneServiceEnabled', 'phoneAvailable', 'phone', function () {
      return this.get('phoneServiceEnabled') && this.get('phoneAvailable') || !(0, _lodash2.default)(this.get('phone'));
    }),
    showPhone: Ember.computed('phoneServiceEnabled', 'phone', 'isGroup', function () {
      if (!this.get('phoneServiceEnabled')) {
        return false;
      }

      if (this.get('isGroup')) {
        return !(0, _lodash2.default)(this.get('phone'));
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});