define('ember-chat-components/components/chat-panel/conversation/header/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/header/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed } = _ember.default;

    exports.default = Component.extend({
        classNames: ['chat-header'],
        layout: _template.default,
        canViewChat: true,
        canViewVideo: true,
        activeRoom: null,

        roomAvatar: computed('activeRoom.{oneToOneTarget,group}', function () {
            const room = this.get('activeRoom');

            if (room && room.isOneToOneRoom()) {
                return room.get('oneToOneTarget');
            }

            if (room && room.isGroupRoom()) {
                return room.get('group');
            }

            return null;
        })
    });
});