define('ember-wrap-urls/components/wrap-urls/url', ['exports', 'ember-wrap-urls/templates/components/wrap-urls/url'], function (exports, _url) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _url.default,
    tagName: 'span',
    classNames: ['wrapped-url'],
    attributeBindings: ['id']
  });
});