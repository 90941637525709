define("ember-purecloud-components/components/interaction-annotation/component", ["exports", "ember-purecloud-components/components/interaction-annotation/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // config
    layout: _template.default,
    classNames: ['annotation'],
    classNameBindings: ['flipped'],
    // lifecycle
    didRender: function didRender() {
      var _this = this;

      Ember.run.scheduleOnce('render', this, function () {
        var locationMs = _this.get('annotation.location');

        var totalDurationMs = _this.get('totalDurationMs');

        var position = locationMs / totalDurationMs * 100;
        _this.element.style.left = Ember.String.htmlSafe("".concat(position, "%"));
      });
    },
    // if the annotation less than half way along the timeline, flip it.
    flipped: Ember.computed('annotation.location', 'totalDurationMs', function () {
      var locationMs = this.get('annotation.location');
      var totalDurationMs = this.get('totalDurationMs');
      return locationMs / totalDurationMs < 0.5;
    }),
    totalDurationMs: Ember.computed('totalDuration', function () {
      return this.get('totalDuration') * 1000;
    })
  });

  _exports.default = _default;
});