define("ember-purecloud-components/serializers/participant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // When the message relationship is normalized - sms gets evaluated as a plural and depluralized to 'sm' in the process
  // This modifies the model that gets fed into the CP event and causes the first in a series
  // of updates to a conversation to have messages with type "sm" instead of type "sms".  Array below is for types like
  // this - or any type that should not have its definition manipulated.
  var RESERVED_TYPES = ['sms'];

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      videos: {
        embedded: 'always'
      },
      chats: {
        embedded: 'always'
      },
      screenshares: {
        embedded: 'always'
      },
      calls: {
        embedded: 'always'
      },
      emails: {
        embedded: 'always'
      },
      callbacks: {
        embedded: 'always'
      },
      messages: {
        embedded: 'always'
      },
      socialExpressions: {
        embedded: 'always'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(type) {
      return RESERVED_TYPES.includes(type) ? type : this._super(type);
    }
  });

  _exports.default = _default;
});