define('ember-engine-custom-fields/components/schema-editor/field-configuration-list/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-list/template', 'ember-custom-fields-components/utils/field-util'], function (exports, _template, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var A = Ember.A,
        Component = Ember.Component,
        inject = Ember.inject;
    exports.default = Component.extend({
        layout: _template.default,
        classNames: ['field-configuration-list'],

        intl: inject.service(),

        fieldTypes: A([]),

        toggleFieldPanel: null,

        init: function init() {
            var _this = this;

            this._super();

            var intl = this.get('intl');

            this.set('fieldTypes', A([]));

            _fieldUtil.FIELD_TYPES.forEach(function (fieldType) {
                _this.get('fieldTypes').pushObject({
                    type: fieldType,
                    displayableType: intl.t('emberEngineCustomFields.fieldType.' + fieldType),
                    icon: (0, _fieldUtil.fieldConfig)(fieldType).icon
                });
            });
        },


        actions: {
            createField: function createField(fieldType) {
                this.createField(fieldType);
            },
            toggleFieldPanel: function toggleFieldPanel() {
                this.toggleFieldPanel();
            }
        }
    });
});