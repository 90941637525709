define('ember-custom-fields-components/components/custom-fields/controls/text', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default'], function (exports, _default) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _default.default.extend({
        message: null,
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.send('validate');
        },


        minLengthError: 'emberCustomFieldsComponents.fieldErrors.minLengthPlural',
        maxLengthError: 'emberCustomFieldsComponents.fieldErrors.maxLengthPlural',

        actions: {
            validate: function validate(validateMaxOnly) {
                var intl = this.get('intl'),
                    value = this.get('control.value'),
                    minLength = this.get('control.minLength'),
                    maxLength = this.get('control.maxLength');

                this.set('message', '');
                this.set('control.isValid', true);

                var validateBoth = typeof validateMaxOnly !== 'boolean';
                var valueIsSet = typeof value === 'string' && value !== '';
                var minLengthInvalid = valueIsSet && typeof minLength === 'number' && value.length < minLength && validateBoth;
                var maxLengthInvalid = valueIsSet && typeof maxLength === 'number' && value.length > maxLength;

                if (minLengthInvalid) {
                    var minLengthMessage = intl.t('emberCustomFieldsComponents.fieldErrors.minLength');

                    if (minLength > 1) {
                        minLengthMessage = intl.t(this.get('minLengthError'), { minLength: minLength });
                    }

                    this.set('message', minLengthMessage);
                    this.set('control.isValid', false);
                }

                if (maxLengthInvalid) {
                    var maxLengthMessage = intl.t('emberCustomFieldsComponents.fieldErrors.maxLength');

                    if (maxLength > 1) {
                        maxLengthMessage = intl.t(this.get('maxLengthError'), { maxLength: maxLength });
                    }

                    this.set('message', maxLengthMessage);
                    this.set('control.isValid', false);
                }

                if (this.get('setCustomFieldValidation')) {
                    this.get('setCustomFieldValidation')();
                }
            }
        }
    });
});