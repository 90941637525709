define("ember-webrtc-components/components/webrtc-settings/component", ["exports", "ember-purecloud-components/mixins/logger", "ember-webrtc-components/components/webrtc-settings/template"], function (_exports, _logger, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNameBindings: [':webrtc-settings', ':settings-panel', 'settingsVisible::hide'],
    layout: _template.default,
    settingsVisible: false,
    roomJid: null,
    activeRoom: null,
    canViewChat: true,
    actions: {
      paneDeselected: function paneDeselected() {
        this.attrs.paneDeselected(['isInviteSelected', 'isParticipantsSelected', 'isSettingsSelected', 'isSearchSelected']);
      },
      toggleSettings: function toggleSettings() {
        return this.toggleProperty('settingsVisible');
      }
    }
  });

  _exports.default = _default;
});