define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/pending-state/component', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;


    const MessagePendingState = _ember.default.Component.extend({
        tagName: 'i',
        classNameBindings: [':state-indicator', 'icon.class'],
        attributeBindings: ['toggle:data-toggle', 'placement:data-placement', 'title'],

        intl: _ember.default.inject.service(),
        chat: _ember.default.inject.service(),

        toggle: 'toggle',
        placement: 'bottom',

        title: _ember.default.computed.reads('icon.title'),
        icon: _ember.default.computed('message.{pending,sendError}', 'message.correction.{pending,sendError}', function () {
            const intl = this.get('intl');
            if (this.get('message.pending') || this.get('message.correction.pending')) {
                return {
                    class: 'pc-moon pc-loading pc-spin',
                    title: intl.t('chat.tooltips.sendingMessage').toString()
                };
            }
            if (this.get('message.sendError')) {
                return {
                    class: 'ion-close-round clickable',
                    title: intl.t('chat.tooltips.resendMessage').toString()
                };
            }
            if (this.get('message.correction.sendError')) {
                return {
                    class: 'ion-close-round clickable',
                    title: intl.t('chat.tooltips.failedCorrection').toString()
                };
            }
            return {
                class: '',
                title: ''
            };
        }),

        click() {
            const message = this.get('message');
            if (message.get('sendError')) {
                if (message.get('splitMessage')) {
                    this.resendSplitMessage(message);
                } else {
                    this.resendMessage(message);
                }
            } else if (message.get('correction.sendError')) {
                this.revertCorrection(message);
            }
        },

        resendMessage(message) {
            this.get('room.messages').splice(this.get('room.messages').indexOf(message), 1);
            this.get('chat').sendMessageToRoom(message.to, message.raw).then(() => {
                return message.setProperties({
                    pending: false,
                    sendError: false
                });
            }).catch(err => {
                if (err) {
                    return message.setProperties({
                        pending: false,
                        sendError: true
                    });
                }
            });
        },

        resendSplitMessage(splitMessage) {
            const totalSplitMessages = splitMessage.get('totalMessages');
            const failedSplitMessages = splitMessage.get('failedMessages');

            if (failedSplitMessages.get('length') < totalSplitMessages) {
                splitMessage.get('messageArray').removeObjects(failedSplitMessages);
            } else {
                this.get('room.messages').removeObject(splitMessage);
                const splitMessageCache = this.get('room.splitMessageCache');
                const splitMessageId = splitMessage.get('splitMessageId');
                if (Object.prototype.hasOwnProperty.call(splitMessageCache, splitMessageId)) {
                    delete splitMessageCache[splitMessageId];
                }
            }

            const splitWidgets = failedSplitMessages.map(message => message.get('widget'));
            this.get('chat').sendSplitMessageQueue(this.get('room'), splitWidgets);
        },

        revertCorrection(message) {
            this.get('room.messages').removeObject(message.get('correction'));
            message.set('correction', null);
        }
    });
    exports.default = MessagePendingState;
});