define("ember-purecloud-components/components/queue-toggle-switch/component", ["exports", "ember-purecloud-components/components/toggle-switch/component", "ember-purecloud-components/utils/logger", "ember-purecloud-components/components/queue-toggle-switch/template"], function (_exports, _component, _logger, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var onQueue = 'on_queue';

  var _default = _component.default.extend(_logger.default, {
    layout: _template.default,
    classNames: ['on-queue-toggle'],
    classNameBindings: ['checkQueue:on-queue-active:on-queue-inactive'],
    streaming: Ember.inject.service(),
    queueToggle: null,
    entity: null,
    continueOnQueue: false,
    presence: Ember.computed.alias('queueToggle.presence'),
    checkQueue: Ember.computed.alias('queueToggle.checkQueue'),
    presenceHistory: Ember.computed.alias('queueToggle.presenceHistory'),
    disabled: Ember.computed.not('streaming.connected'),
    actions: {
      continueOnQueue: function continueOnQueue() {
        if (this.get('disabled')) {
          return;
        }

        this.get('queueToggle').continueOnQueue();
      },
      selectPhone: function selectPhone() {
        if (this.get('disabled')) {
          return;
        }

        this.get('queueToggle').selectPhone();
      },
      click: function click(ev) {
        if (!this.get('disabled')) {
          this.get('queueToggle').handleToggle(this.get('entity'), this.get('continueOnQueue'));
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var element = this.get('element');
      element.addEventListener('check', function (ev) {
        ev.preventDefault();

        _this.get('actions').click.call(_this, ev);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);
      var queueToggle = owner.lookup('service:queue-toggle');

      if (!queueToggle) {
        this.logger.error('queue-toggle-switch requires queue toggle service. Disabling queue toggle.');
        this.set('disabled', true);
      } else {
        this.set('queueToggle', queueToggle);
      }

      this.presenceListener();
    },
    isOnQueue: Ember.computed('queueToggle.checkQueue', 'entity.presence', function () {
      if (this.get('entity.presence')) {
        return this.get('entity.presence') === onQueue;
      }

      return this.get('queueToggle.checkQueue');
    }),

    /**
     * Check initially if the users presence is set from
     * outside directory client.
     */
    presenceListener: Ember.observer('presence', 'disabled', function () {
      // If an entity is provided, do not modify checkQueue as that is specific to the current logged-in user.
      // The entity could be any user.
      if (this.get('disabled') || this.get('entity')) {
        return;
      }

      var presence = this.get('presence');
      this.set('checkQueue', presence === onQueue);

      if (presence !== onQueue) {
        this.get('presenceHistory').clear();
      }
    })
  });

  _exports.default = _default;
});