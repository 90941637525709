define("ember-purecloud-components/models/conversation", ["exports", "ember-data", "ember-purecloud-components/mixins/conversation/chats", "ember-purecloud-components/mixins/conversation/emails", "ember-purecloud-components/mixins/conversation/social"], function (_exports, _emberData, _chats, _emails, _social) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function findMedias(participantSelection, mediaType) {
    return Ember.computed("".concat(participantSelection, ".@each.").concat(mediaType), function () {
      return this.get(participantSelection).reduce(function (a, b) {
        var items = b.get(mediaType);

        if (items && Array.isArray(items)) {
          return a.concat(items);
        }

        if (items && typeof items.toArray === 'function') {
          return a.concat(items.toArray());
        }

        return a;
      }, []);
    });
  }

  var MEDIA_TYPES = ['calls', 'chats', 'screenShares', 'videos', 'vrs', 'socialExpressions', 'emails', 'callbacks', 'messages'];
  var MEDIA_TYPE_COMPUTEDS = {};
  MEDIA_TYPES.forEach(function (mediaType) {
    var all = findMedias('participants', mediaType);
    var my = findMedias('myParticipants', mediaType);
    MEDIA_TYPE_COMPUTEDS[mediaType] = all;
    var myMediaTypeKey = "my".concat(Ember.String.capitalize(mediaType));
    MEDIA_TYPE_COMPUTEDS[myMediaTypeKey] = my;
  });
  var mixins = [_chats.default, _emails.default, _social.default];

  var ConversationModel = (_DS$Model = _emberData.default.Model).extend.apply(_DS$Model, [MEDIA_TYPE_COMPUTEDS].concat(mixins, [{
    // ----------------------------------------------------------------------------
    // Raw Properties
    // ----------------------------------------------------------------------------
    startTime: _emberData.default.attr(),
    endTime: _emberData.default.attr(),
    address: _emberData.default.attr(),
    participants: _emberData.default.hasMany('participant', {
      async: false
    }),
    recordings: _emberData.default.hasMany('recording', {
      async: true
    }),
    recordingState: _emberData.default.attr(),
    selfUri: _emberData.default.attr(),
    // ----------------------------------------------------------------------------
    // Update handles a raw update coming in from carrier pigeon
    // ----------------------------------------------------------------------------
    update: function update(conversation) {
      var _this = this;

      // Set all primitives from the update on the model
      Object.keys(conversation).forEach(function (key) {
        var value = conversation[key];

        var type = _typeof(conversation[key]);

        if (value === null || type === 'string' || type === 'number' || type === 'boolean') {
          _this.set(key, value);
        }
      });

      if (conversation.participants) {
        conversation.participants.forEach(function (participant) {
          var existingParticipant = _this.get('participants').findBy('id', participant.id);

          if (existingParticipant) {
            existingParticipant.update(participant);
          } else {
            var normalized = _this.get('store').normalize('participant', participant);

            var newParticipant = _this.get('store').push(normalized);

            _this.get('participants').pushObject(newParticipant);
          }
        });
      }
    },
    // ----------------------------------------------------------------------------
    // Raw Computed Properties (computed only from Raw properties)
    // ----------------------------------------------------------------------------
    participantsScreenRecording: Ember.computed('participants.@each.screenRecordingState', function () {
      var activeStates = ['requested', 'active', 'paused', 'timeout'];
      return this.get('participants').filter(function (participant) {
        var state = participant.get('screenRecordingState');
        return state && activeStates.includes(state);
      });
    }),
    activeCommunications: Ember.computed('participants.@each.activeCommunications', function () {
      var allActiveCommunications = Ember.Object.create();
      this.get('participants').forEach(function (participant) {
        if (!participant.get('userId')) {
          return;
        }

        var allActiveUserCommunications = allActiveCommunications.get(participant.get('userId'));

        if (!allActiveUserCommunications) {
          allActiveUserCommunications = allActiveCommunications.set(participant.get('userId'), Ember.Object.create());
        }

        var participantComs = participant.get('activeCommunications');

        for (var _i = 0, _Object$keys = Object.keys(participantComs); _i < _Object$keys.length; _i++) {
          var comType = _Object$keys[_i];
          var activeUserCommunications = allActiveUserCommunications.get(comType);

          if (!activeUserCommunications) {
            allActiveUserCommunications.set(comType, participantComs.get(comType));
          } else {
            activeUserCommunications.pushObjects(participantComs.get(comType));
          }
        }
      });
      return allActiveCommunications;
    }),
    // ----------------------------------------------------------------------------
    // Assigned properties - properties provided upon initialization
    //                        used for computed properties
    // ----------------------------------------------------------------------------
    userId: null,
    // ----------------------------------------------------------------------------
    // Assigned Computed properties - properties computed from Raw and Assigned
    // ----------------------------------------------------------------------------

    /*
     * myParticipants
     * returns: Array Participant - matching userId (guid)
     * notes: Old assumption of single participant for the current user is not 100%, so this is now an array
     */
    myParticipants: Ember.computed('userId', 'participants.[]', function () {
      return this.get('participants').filterBy('userId', this.get('userId'));
    }),

    /*
     * isAfterCallWorkRequired
     * returns Bool
     * notes: returns True if there are no self participants with wrapup required
     */
    isAfterCallWorkRequired: Ember.computed('myParticipants.@each.wrapupRequired', function () {
      return !!this.get('myParticipants').any(function (p) {
        return Ember.get(p, 'wrapupRequired');
      });
    }),

    /*
     * isAfterCallWorkCompleted
     * returns Bool
     * notes: returns True if there are no self participants with (wrapup required and no wrapup set)
     */
    isAfterCallWorkCompleted: Ember.computed('myParticipants.@each.wrapup', function () {
      return !this.get('myParticipants').any(function (p) {
        return Ember.get(p, 'wrapupRequired') && !Ember.get(p, 'wrapup');
      });
    }),
    // ----------------------------- //
    // Added from conversation-v2.js //
    // ----------------------------- //
    currentUserId: null,
    room: null,
    customerName: Ember.computed.reads('customerDetails.name'),
    queuId: Ember.computed.reads('currentUserParticipant.queueId'),
    userParticipants: Ember.computed('participants.@each.userId', 'currentUserId', function () {
      var currentUserId = this.get('currentUserId');
      var participants = this.get('participants');
      var userParticipants = [];
      participants.forEach(function (participant) {
        var participantIsCurrentUser = !!currentUserId && currentUserId === participant.get('userId');

        if (participantIsCurrentUser) {
          userParticipants.push(participant);
        }
      });
      return Ember.A(userParticipants);
    }),
    currentUserParticipant: Ember.computed('userParticipants.[]', function () {
      var error = new Error('DEPRECATION WARNING: ember-purecloud-components > conversation.currentUserParticipant is deprecated');
      Ember.Logger.warn(error.message, {
        stack: error.stack
      });

      var userParticipants = _toConsumableArray(this.get('userParticipants') || []).reverse();

      return userParticipants ? userParticipants.find(function (participant) {
        return !participant.get('wrapup');
      }) : null;
    }),
    acdParticipants: Ember.computed('particpants.@each.purpose', function () {
      var participants = this.get('participants');
      var filteredParticipants = [];
      participants.forEach(function (participant) {
        if (participant.get('purpose') === 'acd') {
          filteredParticipants.push(participant);
        }
      });
      return filteredParticipants;
    }),
    lastAcdParticipant: Ember.computed('acdParticipants.[]', function () {
      var error = new Error('DEPRECATION WARNING: ember-purecloud-components > conversation.lastAcdParticipant is deprecated');
      Ember.Logger.warn(error.message, {
        stack: error.stack
      });
      var acdParticipants = this.get('acdParticipants');
      return [].concat(acdParticipants).pop();
    }),
    customerParticipant: Ember.computed('participants.@each.purpose', function () {
      var error = new Error('DEPRECATION WARNING: ember-purecloud-components > conversation.customerParticipant is deprecated');
      Ember.Logger.warn(error.message, {
        stack: error.stack
      });
      var participants = this.get('participants');
      var result;
      participants.forEach(function (participant) {
        if (participant.get('purpose') === 'customer') {
          result = participant;
        }
      });
      return result;
    }),
    customerDetails: Ember.computed('customerParticipant', function () {
      var error = new Error('DEPRECATION WARNING: ember-purecloud-components > conversation.customerParticipant is deprecated');
      Ember.Logger.warn(error.message, {
        stack: error.stack
      });
      var customer = this.get('customerParticipant');
      var details = {};

      if (customer && customer.get('name')) {
        details.name = customer.get('name');
      } else if (this.get('formattedCustomerPhoneNumber')) {
        details.name = this.get('formattedCustomerPhoneNumber');
      }

      if (customer && customer.get('attributes')) {
        for (var _i2 = 0, _Object$entries = Object.entries(customer.get('attributes')); _i2 < _Object$entries.length; _i2++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i2], 2),
              property = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          var parts = property.split('.');
          property = parts[parts.length - 1];
          details[property] = value;
        }
      }

      return details;
    }),
    wrapupRequired: Ember.computed('currentUserParticipant.wrapupRequired', function () {
      var currentUserParticipant = this.get('currentUserParticipant');
      var required = currentUserParticipant.get('wrapupRequired');
      return required === 'true';
    }),
    wrapupPrompt: Ember.computed('currentUserParticipant.wrapupPrompt', function () {
      var currentUserParticipant = this.get('currentUserParticipant');
      return currentUserParticipant.get('wrapupPrompt');
    }),
    wrapup: Ember.computed('currentUserParticipant.wrapup', function () {
      var currentUserParticipant = this.get('currentUserParticipant');
      return currentUserParticipant.get('wrapup');
    }),
    wrapupComplete: Ember.computed('wrapup.code', 'wrapupRequired', function () {
      var wrapupRequired = this.get('wrapupRequired');
      var wrapupCode = this.get('wrapup.code');
      return !wrapupRequired || !!wrapupCode;
    })
  }])).reopenClass({
    MediaTypes: MEDIA_TYPES
  });

  var _default = ConversationModel;
  _exports.default = _default;
});