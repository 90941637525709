define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/github-gist/component', ['exports', 'ember', 'ember-data', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/github-gist/template'], function (exports, _ember, _emberData, _template) {
    'use strict';

    exports.__esModule = true;


    const { RSVP, Component } = _ember.default;

    function addStylesheetOnce(url) {
        let $head = _ember.default.$('head');
        if ($head.find(`link[rel="stylesheet"][href="${url}"]`).length < 1) {
            $head.append(`<link rel="stylesheet" href="${url}" type="text/css" />`);
        }
    }

    exports.default = Component.extend({
        classNames: ['github-gist-media-item'],

        mediaItem: null,
        layout: _template.default,

        init() {
            this._super(...arguments);
            this.setData();
        },

        setData() {
            let data = this.get('mediaItem.data');
            if (data) {
                return data;
            }
            let promise = _emberData.default.PromiseObject.create({
                promise: new RSVP.Promise((resolve, reject) => {
                    _ember.default.$.getJSON(this.get('mediaItem.url'), data => {
                        resolve(data);

                        // load the stylesheet, but only once…
                        addStylesheetOnce(data.stylesheet);
                    }, err => {
                        reject(err);
                    });
                })
            });
            this.set('mediaItem.data', promise);
            return promise;
        }
    });
});