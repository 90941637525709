define('ember-engine-custom-fields/utils/jid-helpers', ['exports', 'ember-purecloud-components/utils/jid-helpers'], function (exports, _jidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.keys(_jidHelpers).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _jidHelpers[key];
      }
    });
  });
});