define('ember-cli-testing/utils/ModuleLoader', ['exports'], function (exports) {
    var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

    function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

    /**
    * An interface for Ember's module loader that provides methods to facilitate dynamic module
    * loading and reflection. The purpose of this interface is to prevent code from relying
    * directly on the module loader (currently Require.js), because that may be changed in
    * the future.
    */

    var ModuleLoader = (function () {

        /**
        * @param {Object} options
        * @param {string} options.modulePrefix - The Ember application's module prefix. If you have
        *                                        access to the container, you should be able to get
        *                                        the module prefix like so:
        *                                        `container.lookupFactory('config:environment').modulePrefix`
        */

        function ModuleLoader(options) {
            _classCallCheck(this, ModuleLoader);

            var modulePrefix = options.modulePrefix;

            if (!modulePrefix) {
                throw new Error('A \'modulePrefix\' is required.');
            }
            this._modulePrefix = modulePrefix;
        }

        /**
        * The full names of all of the available modules. Modules defined in the Ember app itself
        * are paths like '{modulePrefix}/{object type}/{module name}'.
        *
        * @returns {Array.<string>}
        */

        _createClass(ModuleLoader, [{
            key: 'getServicePaths',

            /**
            * Returns the paths of the Ember application's own service modules.
            *
            * @returns {Array.<string>}
            */
            value: function getServicePaths() {
                var prefix = this._modulePrefix + '/services/';
                return this.modulePaths.filter(function (m) {
                    return m.startsWith('' + prefix);
                });
            }

            /**
            * Returns the paths of the Ember application's own service modules.
            *
            * @returns {Array.<string>}
            */
        }, {
            key: 'getServiceNames',
            value: function getServiceNames() {
                var prefix = this._modulePrefix + '/services/';
                return this.getServicePaths().map(function (p) {
                    return p.replace(prefix, '');
                });
            }
        }, {
            key: 'modulePaths',
            get: function get() {
                return Object.keys(require.entries);
            }
        }]);

        return ModuleLoader;
    })();

    exports['default'] = ModuleLoader;
});