define("ember-webrtc-components/utils/attach-media-stream", ["exports", "ember-purecloud-components/utils/browser"], function (_exports, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(stream, element, options) {
    var opts = {
      autoplay: true,
      mirror: false,
      muted: false,
      audio: false,
      disableContextMenu: false
    };

    if (options) {
      Object.keys(options).forEach(function (key) {
        opts[key] = options[key];
      });
    }

    if (!element) {
      element = document.createElement(opts.audio ? 'audio' : 'video');
    } else if (element.tagName.toLowerCase() === 'audio') {
      opts.audio = true;
    }

    if (opts.disableContextMenu) {
      element.oncontextmenu = function (e) {
        e.preventDefault();
      };
    }

    if (opts.autoplay) element.autoplay = 'autoplay';
    element.muted = !!opts.muted;

    if (!opts.audio) {
      ['', 'moz', 'webkit', 'o', 'ms'].forEach(function (prefix) {
        var styleName = prefix ? prefix + 'Transform' : 'transform';
        element.style[styleName] = opts.mirror ? 'scaleX(-1)' : 'scaleX(1)';
      });
    }

    if (_browser.default.isSafari) {
      element.setAttribute('playsinline', true);
    }

    element.srcObject = stream;
    return element;
  }
});