define('ember-engine-custom-fields/components/schema-grid/schema-grid-actions/preview/component', ['exports', 'ember-engine-custom-fields/components/schema-grid/schema-grid-actions/preview/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    exports.default = Component.extend({
        layout: _template.default
    });
});