define("ember-purecloud-components/utils/timeout-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var DEFAULT_CANCEL_TIME = 5000;

  function _default(promise) {
    var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_CANCEL_TIME;
    var label = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var TIMEOUT_IDENTIFIER = Ember.run.later(function () {
        reject("TimeoutPromise - timeout reached - ".concat(label));
      }, timeout);
      promise.then(function () {
        Ember.run.cancel(TIMEOUT_IDENTIFIER);
        resolve.apply(void 0, arguments);
      }).catch(function () {
        Ember.run.cancel(TIMEOUT_IDENTIFIER);
        reject.apply(void 0, arguments);
      });
    });
  }
});