define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/teams-notification/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/teams-notification/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;

    const { Component, computed, inject } = _ember.default;

    exports.default = Component.extend({
        classNames: ['custom-chat-message-widget', 'teams-notification-widget'],
        classNameBindings: ['haveAccess:have-access'],
        layout: _template.default,

        intl: inject.service(),
        store: inject.service(),
        session: inject.service(),
        permissions: inject.service(),
        mediaProviders: inject.service(),

        message: null,
        provider: null,

        data: computed.reads('message.widget.data'),

        actions: {
            sendProviderAction() {
                this.get('provider').action(this.get('options'));
            }
        },

        init() {
            this._super(...arguments);

            const userId = this.get('data.userId') || this.get('message.chatUser.id');
            if (userId) {
                this.get('store').findRecord('public-api-user', userId).then(user => {
                    this.set('provider', this.get('mediaProviders').getProvider('teams', user));
                });
            }
        },

        isMyMeeting: computed('message.chatUser.id', 'data.userId', function () {
            return this.get('data.userId') === this.get('message.chatUser.id');
        }),

        haveAccess: computed.bool('provider.haveAccess'),

        options: computed('data', function () {
            return {
                sendNotification: false,
                meetingId: this.get('data.meetingId'),
                url: this.get('data.url')
            };
        })
    });
});