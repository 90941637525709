define('ember-custom-fields-components/components/custom-fields/controls/default', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNameBindings: ['controlType'],
        intl: Ember.inject.service(),

        controlType: Ember.computed('control.type', function () {
            var type = this.get('control.type');
            return type + '-control';
        }),

        control: null,
        isValid: null
    });
});