define("ember-purecloud-components/components/entity-location/component", ["exports", "ember-purecloud-components/components/entity-location/template", "ember-purecloud-components/utils/display-geolocation"], function (_exports, _template, _displayGeolocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    attributeBindings: ['hoverText:title'],
    locationClass: '',
    entity: function entity() {},
    locationHoverText: '',
    noLocationHoverText: '',
    isGeolocationEnabled: false,
    self: null,
    hoverText: Ember.computed('locationHoverText', 'noLocationHoverText', 'isGeolocationEnabled', 'entity.hasGeolocation', function () {
      if (this.get('isGeolocationEnabled') && this.get('entity.hasGeolocation')) {
        return this.get('locationHoverText');
      } else if (this.get('isGeolocationEnabled')) {
        return this.get('noLocationHoverText');
      } else {
        return '';
      }
    }),
    location: Ember.computed('entity.geolocation', function () {
      return (0, _displayGeolocation.default)(this.get('entity'), this.get('self'));
    })
  });

  _exports.default = _default;
});