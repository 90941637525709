define("ember-purecloud-components/components/entity-v2/hover-card/component", ["exports", "ember-purecloud-components/components/entity-v2/hover-card/template", "popper.js"], function (_exports, _template, _popper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2-hover-card'],
    classNameBindings: ['microCard'],
    presenceService: Ember.inject.service('presence'),
    entity: null,
    self: null,
    microCard: false,
    showMiniCard: false,
    hoverTimer: null,
    isHovering: false,
    boundaryElement: null,
    // this is used to define boundaries of popper, in case of overflow, the popper can occupy some space within this element.
    isOnQueue: Ember.computed('entity.presence.presenceDefinition.systemPresence', function () {
      var presence = this.getWithDefault('entity.presence.presenceDefinition.systemPresence', 'offline').toLowerCase();
      return presence === 'on queue' || presence === 'on_queue';
    }),
    isOnline: Ember.computed('entity.presence.presenceDefinition.systemPresence', function () {
      return this.getWithDefault('entity.presence.presenceDefinition.systemPresence', 'offline').toLowerCase() !== 'offline';
    }),
    isPurecloudStatusSource: Ember.computed('entity.presence.source', function () {
      return this.getWithDefault('entity.presence.source', 'PURECLOUD').toLowerCase() === 'purecloud';
    }),
    hasStation: Ember.computed.bool('entity.station.associatedStation.id'),
    linkToProfileRoute: Ember.computed('entity.type', function () {
      if (this.get('entity.type') === 'group') {
        return "#/group/".concat(this.get('entity.id'));
      }

      return "#/person/".concat(this.get('entity.id'));
    }),
    uniqueHovercardId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-entity-mini-card");
    }),
    entityIsGroup: Ember.computed('entity.type', function () {
      return this.get('entity.type') === 'group';
    }),
    isSelfEntity: Ember.computed('entity.id', 'self.id', function () {
      return this.get('entity.id') === this.get('self.id');
    }),
    makeCall: function makeCall() {},
    launchVideo: function launchVideo() {},
    startChat: function startChat() {},
    goToGallery: function goToGallery() {},
    toggleFavorite: function toggleFavorite() {},
    toggleOnQueue: function toggleOnQueue() {
      if (this.get('isOnQueue')) {
        this.get('presenceService').setUsersPresence(this.get('entity'), 'available');
      } else {
        this.get('presenceService').setUsersPresence(this.get('entity'), 'on_queue');
      }
    },
    changeHoverState: function changeHoverState(newState) {
      this.set('isHovering', newState);
      this.onHoverChanged();
    },
    onHoverChanged: function onHoverChanged() {
      var _this = this;

      var hoverTimeout = 800;
      var isHovering = this.get('isHovering');
      Ember.run.cancel(this.get('hoverTimer'));
      this.set('hoverTimer', Ember.run.later(function () {
        if (isHovering) {
          _this._displayHoverCard();
        } else {
          _this._hideHoverCard();
        }
      }, hoverTimeout));
    },
    _createPopper: function _createPopper() {
      if (!this.element) {
        return;
      }

      var targetEl = this.element.querySelector('.mini-card-component-wrapper');
      var cardEl = this.element.querySelector('.entity-v2-mini-card');
      var popperOptions = {
        placement: 'bottom',
        modifiers: {
          flip: {
            behavior: ['bottom', 'top', 'left']
          },
          offset: {
            offset: '40%p,10px'
          },
          arrow: {
            element: '.arrow'
          },
          preventOverflow: {
            padding: 1
          }
        }
      };
      var boundaryElement = this.get('boundaryElement');

      if (boundaryElement) {
        popperOptions.modifiers.preventOverflow.boundariesElement = boundaryElement;
      }

      this._popper = new _popper.default(targetEl, cardEl, popperOptions);
    },
    _clickOutsideHovercardHandler: function _clickOutsideHovercardHandler(event) {
      if (!event) {
        return;
      }

      var target = Ember.$(event.target);
      var isClickedWithinHoverCard = target.parents('.entity-v2-hover-card').length;
      var showMiniCard = this.get('showMiniCard');

      if (showMiniCard && !isClickedWithinHoverCard) {
        this._hideHoverCard();
      }
    },
    _attachListeners: function _attachListeners() {
      Ember.$(window).on("click.".concat(this.get('uniqueHovercardId')), this._clickOutsideHovercardHandler.bind(this));
    },
    _removeListeners: function _removeListeners() {
      Ember.$(window).off(".".concat(this.get('uniqueHovercardId')));
    },
    _destroyPopper: function _destroyPopper() {
      if (!this._popper) {
        return;
      }

      this._popper.destroy();

      this._popper = null;
    },
    _displayHoverCard: function _displayHoverCard() {
      if (!this.get('showMiniCard') && this.get('_state') === 'inDOM' && this.get('params.enableMiniCard')) {
        this.set('showMiniCard', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          this._createPopper();
        });
      }
    },
    onWrapperClick: function onWrapperClick() {
      Ember.run.cancel(this.get('hoverTimer'));

      if (this.get('showMiniCard')) {
        this._hideHoverCard();
      } else {
        this._displayHoverCard();
      }
    },
    _hideHoverCard: function _hideHoverCard() {
      if (this.get('showMiniCard')) {
        this.set('showMiniCard', false);

        this._destroyPopper();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._attachListeners();

      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeListeners();

      this.element.removeEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },

    /**
     * This is invoked in several cases:
     *   - The user moves their cursor out of the hovercard
     *   - The user clicks the presence selector (firefox only). No moved-to element will be present in this case
     *
     * @param {*} e the mouseLeave event
     */
    handleMouseLeave: function handleMouseLeave(e) {
      var target = e && (e.toElement || e.relatedTarget || e.target);
      var isOutsideHovercard = !Ember.$(this.element).has(target).length;

      if (isOutsideHovercard) {
        this.changeHoverState(false);
      }
    }
  });

  _exports.default = _default;
});