define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/core-properties/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/core-properties/template', 'ember-engine-custom-fields/mixins/field-keys', 'ember-engine-custom-fields/mixins/validation'], function (exports, _template, _fieldKeys, _validation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias;


    var KEY_VALIDATION_TIMEOUT = 800;

    exports.default = Component.extend(_fieldKeys.default, _validation.default, {
        layout: _template.default,
        classNames: ['core-properties', 'form-group'],

        limits: inject.service(),
        intl: inject.service(),

        field: null,
        type: alias('field.type'),
        title: alias('field.title'),
        key: alias('field.key'),

        keyPrefixUpdateMillis: KEY_VALIDATION_TIMEOUT,
        description: alias('field.description'),
        hasTitleError: computed('field.isTitleValid', 'showValidationError', function () {
            var showValidationError = this.get('showValidationError');
            var titleValid = this.get('field.isTitleValid');
            return showValidationError && !titleValid;
        }),

        isLabelTooLong: computed('title.length', 'limits.maxFieldNameCharacters', function () {
            var maxLength = this.get('limits.maxFieldNameCharacters');
            var title = this.get('title');
            if (title && title.length > maxLength) {
                return true;
            }
            return false;
        }),

        isDescTooLong: computed('description.length', 'limits.maxFieldDescriptionCharacters', function () {
            var maxLength = this.get('limits.maxFieldDescriptionCharacters');
            var description = this.get('description');
            if (description && description.length > maxLength) {
                return true;
            }
            return false;
        }),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var fieldInput = this.$('.field-name input');
            if (fieldInput.length) {
                fieldInput.focus();
            }
        },


        fieldSettingsLabel: computed('field.type', function () {
            var intl = this.get('intl'),
                type = this.get('field.type');
            return intl.t('emberEngineCustomFields.fieldsPanel.fieldSettingsLabel', {
                type: intl.t('emberEngineCustomFields.fieldType.' + type)
            });
        }),

        _validate: function _validate() {
            return this.get('field.isTitleValid') && this.get('field.isKeyValid') && !this.get('isDescTooLong') && !this.get('isLabelTooLong');
        },


        actions: {
            checkLimitError: function checkLimitError() {
                var isLabelTooLong = this.get('isLabelTooLong');
                var isDescTooLong = this.get('isDescTooLong');
                var updateLimitError = this.get('updateLimitError');

                var isTooLong = isDescTooLong || isLabelTooLong;
                this.set('showValidationError', isTooLong);
                if (updateLimitError) {
                    updateLimitError(isTooLong);
                }
            }
        }
    });
});