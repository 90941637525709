define("ember-elm/components/elm-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: Ember.HTMLBars.template({
      "id": "+QzkJC2M",
      "block": "{\"statements\":[[18,\"default\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
      "meta": {}
    }),
    // Elm module
    src: undefined,
    // anything you want to pass to the Elm module
    flags: undefined,
    // function that is passed the Elm module's ports
    // eslint-disable-next-line
    setup: function setup(ports) {},
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.src) throw new Error("elm-component missing src object");
    },
    didInsertElement: function didInsertElement() {
      var _this$src$init = this.src.init({
        node: this.element,
        flags: this.flags
      }),
          ports = _this$src$init.ports;

      this.setup(ports);
    }
  });

  _exports.default = _default;
});