define("ember-purecloud-components/components/entity-v2/component", ["exports", "ember-purecloud-components/components/entity-v2/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
     @module entity-v2
     @type component
     @desc Displays a given entity avatar. Displays a card with more information/actions on hover
  */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2'],
    intl: Ember.inject.service(),

    /**
       @type object
       @desc Object representing the entity
    */
    entity: null,

    /**
       @type object
       @desc Object representing the entity who is currently logged-in
    */
    self: null,

    /**
       @type object
       @desc various config parameters
       @property {number} size - how many pixels square the entity image should be
       @property {string} applicationUrlPrefix - url prefix for fetching placeholder avatars
       @property {HTMLElement} boundaryElement - used to define boundaries of popper, in case of overflow, the popper can occupy some space within this element
       @property {bool} linkProfile - determines whether or not to show the profile button
       @property {bool} showFavorite - determines whether or not to show the entity favorite button
       @property {bool} showEmail - determines whether or not to show the email button
       @property {bool} showChat - determines whether or not to show the chat button
       @property {bool} showPhone - determines whether or not to show the phone button
       @property {bool} showVideo - determines whether or not to show the video button
       @property {bool} showGallery - determines whether or not to show the gallery button
       @property {bool} showLogOffButton - determines whether or not to show the log off button
       @property {bool} showPresenceDropdown - determines whether or not to show the presence selector dropdown
       @property {bool} showQueueToggle - determines whether or not to show the on queue toggle
       @property {bool} phoneEnabled - determines whether or not the phone button should be enabled
       @property {bool} videoEnabled - determines whether or not the video button should be enabled
       @property {bool} enableMiniCard - determines whether or not the hovercard should display on hover
       @property {bool} isGeolocationEnabled - determines whether or not to show the entities geolocation
       @property {bool} useInitialsPlaceholder - determines whether to use a placeholder avatar image or the initials of the entity
       @property {bool} showPresenceIndicatorDot - determines whether to show a colored dot inidcating the users presence or the entities avatar image
       @property {bool} showNotResponding - determines whether to show a special icon when the agent is on queue but not responding
       @property {bool} showIdle - determines whether to show a special icon when the agent is on queue but idle
       @property {bool} showCommunicating - determines whether to show a special icon when the agent is on queue but on a non-acd call
    */
    params: null,
    defaultParams: null,
    _params: Ember.computed('params', 'defaultParams', function () {
      return Object.assign({}, this.get('defaultParams'), this.get('params'));
    }),

    /**
       @type function
       @desc Place a call to the current entity.
     */
    makeCall: function makeCall() {},

    /**
       @type function
       @desc Launch a video call with the current entity.
     */
    launchVideo: function launchVideo() {},

    /**
       @type function
       @desc Start a chat with the current entity.
     */
    startChat: function startChat() {},

    /**
       @type function
       @desc Open the image gallery of the current entity.
     */
    goToGallery: function goToGallery() {},

    /**
       @type function
       @desc Change the favorite status of the entity.
     */
    toggleFavorite: function toggleFavorite() {},
    init: function init() {
      this._super.apply(this, arguments);

      this.set('defaultParams', {
        linkProfile: true,
        showFavorite: true,
        showEmail: true,
        showChat: true,
        showPhone: true,
        showVideo: true,
        showGallery: false,
        phoneEnabled: false,
        videoEnabled: false,
        enableMiniCard: true,
        applicationUrlPrefix: '',
        showPresenceDropdown: true,
        showQueueToggle: true,
        isGeolocationEnabled: true,
        useInitialsPlaceholder: false,
        showPresenceIndicatorDot: false,
        showNotResponding: false,
        showLogOffButton: false,
        showIdle: false,
        showCommunicating: false,
        size: 50
      });
    }
  });

  _exports.default = _default;
});