define('smoke-and-mirrors/models/radar', ['exports', 'ember', 'smoke-and-mirrors/models/satellite', 'smoke-and-mirrors/models/geography'], function (exports, _ember, _smokeAndMirrorsModelsSatellite, _smokeAndMirrorsModelsGeography) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var guidFor = _ember['default'].guidFor;
  var run = _ember['default'].run;

  function Container() {
    Object.defineProperty(this, 'scrollTop', {
      get: function get() {
        return window.scrollY || window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
      },
      set: function set(v) {
        return window.scrollY = window.pageYOffset = document.body.scrollTop = document.documentElement.scrollTop = v;
      }
    });
    Object.defineProperty(this, 'scrollLeft', {
      get: function get() {
        return window.scrollX || window.pageXOffset || document.body.scrollLeft || document.documentElement.scrollLeft;
      },
      set: function set(v) {
        return window.scrollX = window.pageXOffset = document.body.scrollLeft = document.documentElement.scrollLeft = v;
      }
    });
  }

  var Radar = (function () {
    function Radar(state) {
      _classCallCheck(this, Radar);

      this.satellites = [];
      this.setState(state || {});
    }

    _createClass(Radar, [{
      key: 'setState',
      value: function setState(state) {
        /*
         A scrollable consists of two parts, a telescope
         and a skyline.
          The telescope is the "constrained viewport", while
         the sky is the element with the full height of
         the content.
         */
        if (this.telescope && state.telescope) {
          this._teardownHandlers();
        }
        this.telescope = state.telescope;
        this.sky = state.sky;

        this.planet = this.telescope ? new _smokeAndMirrorsModelsGeography['default'](this.telescope) : null;
        this.scrollContainer = this.telescope === window ? new Container() : this.telescope;
        this.skyline = this.sky ? new _smokeAndMirrorsModelsGeography['default'](this.sky) : null;

        this.scrollX = this.scrollContainer ? this.scrollContainer.scrollLeft : 0;
        this.scrollY = this.scrollContainer ? this.scrollContainer.scrollTop : 0;
        this.posX = document.body.scrollLeft;
        this.posY = document.body.scrollTop;

        this.minimumMovement = state.minimumMovement || 15;
        this.resizeDebounce = state.resizeDebounce || 64;
        this.isTracking = state.hasOwnProperty('isTracking') ? state.isTracking : true;
        if (this.telescope && this.sky) {
          this._setupHandlers();
        }
      }
    }, {
      key: 'getSatelliteZones',
      value: function getSatelliteZones(satellite) {
        return {
          y: this.getSatelliteYZone(satellite),
          x: this.getSatelliteXZone(satellite)
        };
      }
    }, {
      key: 'getSatelliteYZone',
      value: function getSatelliteYZone(satellite) {
        var satGeo = satellite.geography;
        var distance = 0;
        var yScalar = this.planet.height;

        if (satGeo.bottom > this.planet.top) {
          distance = satGeo.bottom - this.planet.top;
          return Math.floor(distance / yScalar);
        } else if (satGeo.top < this.planet.bottom) {
          distance = satGeo.top - this.planet.bottom;
          return Math.ceil(distance / yScalar);
        }

        return 0;
      }
    }, {
      key: 'getSatelliteXZone',
      value: function getSatelliteXZone(satellite) {
        var satGeo = satellite.geography;
        var distance = 0;
        var xScalar = this.planet.width;

        if (satGeo.right > this.planet.left) {
          distance = satGeo.right - this.planet.left;
          return Math.floor(distance / xScalar);
        } else if (satGeo.left < this.planet.right) {
          distance = satGeo.left - this.planet.right;
          return Math.ceil(distance / xScalar);
        }

        return 0;
      }
    }, {
      key: 'register',
      value: function register(component) {
        this.satellites.push(new _smokeAndMirrorsModelsSatellite['default'](component, this));
      }
    }, {
      key: 'unregister',
      value: function unregister(component) {
        var key = guidFor(component);

        if (!this.satellites) {
          return;
        }

        var satellite = this.satellites.find(function (sat) {
          return sat.key === key;
        });

        if (satellite) {
          var index = this.satellites.indexOf(satellite);

          this.satellites.splice(index, 1);
          satellite.destroy();
        }
      }
    }, {
      key: 'isEarthquake',
      value: function isEarthquake(a, b) {
        return Math.abs(b - a) >= this.minimumMovement;
      }
    }, {
      key: 'willShiftSatellites',
      value: function willShiftSatellites() {}
    }, {
      key: 'didShiftSatellites',
      value: function didShiftSatellites() {}
    }, {
      key: 'willResizeSatellites',
      value: function willResizeSatellites() {}
    }, {
      key: 'didResizeSatellites',
      value: function didResizeSatellites() {}
    }, {
      key: 'willAdjustPosition',
      value: function willAdjustPosition() {}
    }, {
      key: 'didAdjustPosition',
      value: function didAdjustPosition() {}
    }, {
      key: '_resize',
      value: function _resize() {
        this.satellites.forEach(function (c) {
          c.resize();
        });
      }
    }, {
      key: 'resizeSatellites',
      value: function resizeSatellites() {
        this.willResizeSatellites();
        this._resize();
        this.didResizeSatellites();
      }
    }, {
      key: 'updateSkyline',
      value: function updateSkyline() {
        if (this.skyline) {
          this.filterMovement();
          this.skyline.setState();
        }
      }
    }, {
      key: '_shift',
      value: function _shift(dY, dX) {
        // move the satellites
        this.satellites.forEach(function (c) {
          c.shift(dY, dX);
        });

        // move the sky
        this.skyline.left -= dX;
        this.skyline.right -= dX;
        this.skyline.bottom -= dY;
        this.skyline.top -= dY;
      }
    }, {
      key: 'shiftSatellites',
      value: function shiftSatellites(dY, dX) {
        this.willShiftSatellites(dY, dX);
        this._shift(dY, dX);
        this.didShiftSatellites(dY, dX);
      }
    }, {
      key: 'silentNight',
      value: function silentNight() {
        // Keeps skyline stationary when adding elements
        var _height = this.skyline.height;
        var _width = this.skyline.width;

        this.updateSkyline();

        var _skyline = this.skyline;
        var height = _skyline.height;
        var width = _skyline.width;

        var dY = height - _height;
        var dX = width - _width;

        this.scrollY = this.scrollContainer.scrollTop += dY;
        this.scrollX = this.scrollContainer.scrollLeft += dX;
        this.skyline.left -= dX;
        this.skyline.right -= dX;
        this.skyline.bottom -= dY;
        this.skyline.top -= dY;
        this.rebuild();
      }
    }, {
      key: 'rebuild',
      value: function rebuild() {
        this.posX = document.body.scrollLeft;
        this.posY = document.body.scrollTop;
        this.satellites.forEach(function (satellite) {
          satellite.geography.setState();
        });
      }
    }, {
      key: 'filterMovement',
      value: function filterMovement() {
        // cache the scroll offset, and discard the cycle if
        // movement is within (x) threshold
        var scrollY = this.scrollContainer.scrollTop;
        var scrollX = this.scrollContainer.scrollLeft;
        var _scrollY = this.scrollY;
        var _scrollX = this.scrollX;

        if (this.isEarthquake(_scrollY, scrollY) || this.isEarthquake(_scrollX, scrollX)) {
          this.scrollY = scrollY;
          this.scrollX = scrollX;

          var dY = scrollY - _scrollY;
          var dX = scrollX - _scrollX;

          this.shiftSatellites(dY, dX);
        }
      }
    }, {
      key: 'updateScrollPosition',
      value: function updateScrollPosition() {
        var _posX = this.posX;
        var _posY = this.posY;
        var posX = document.body.scrollLeft;
        var posY = document.body.scrollTop;

        if (this.isEarthquake(_posY, posY) || this.isEarthquake(_posX, posX)) {
          this.posY = posY;
          this.posX = posX;
          this.adjustPosition(posY - _posY, posX - _posX);
        }
      }
    }, {
      key: '_adjustPosition',
      value: function _adjustPosition(dY, dX) {
        this.planet.top -= dY;
        this.planet.bottom -= dY;
        this.planet.left -= dX;
        this.planet.right -= dX;

        this._shift(dY, dX);
      }
    }, {
      key: 'adjustPosition',
      value: function adjustPosition(dY, dX) {
        this.willAdjustPosition(dY, dX);
        this._adjustPosition(dY, dX);
        this.didAdjustPosition(dY, dX);
      }
    }, {
      key: '_setupHandlers',
      value: function _setupHandlers() {
        var _this = this;

        this._resizeHandler = null;
        this._scrollHandler = null;
        this._nextResize = null;
        this._nextScroll = null;
        this._nextAdjustment = null;

        this._scrollHandler = function () {
          if (_this.isTracking) {
            _this._nextScroll = run.scheduleOnce('sync', _this, _this.filterMovement);
          }
        };
        this._resizeHandler = function () {
          _this._nextResize = run.debounce(_this, _this.resizeSatellites, _this.resizeDebounce);
        };
        this._scrollAdjuster = function () {
          _this._nextAdjustment = run.scheduleOnce('sync', _this, _this.updateScrollPosition);
        };

        window.addEventListener('resize', this._resizeHandler, true);
        this.telescope.addEventListener('scroll', this._scrollHandler, true);
        if (this.telescope !== window) {
          window.addEventListener('scroll', this._scrollAdjuster, true);
        }
      }
    }, {
      key: '_teardownHandlers',
      value: function _teardownHandlers() {
        window.removeEventListener('resize', this._resizeHandler, true);
        if (this.telescope) {
          this.telescope.removeEventListener('scroll', this._scrollHandler, true);
        }
        if (this.telescope !== window) {
          window.removeEventListener('scroll', this._scrollAdjuster, true);
        }
        run.cancel(this._nextResize);
        run.cancel(this._nextScroll);
        run.cancel(this._nextAdjustment);
        this._scrollHandler = null;
        this._resizeHandler = null;
        this._scrollAdjuster = null;
      }

      // avoid retaining memory by deleting references
      // that likely contain other scopes to be torn down
    }, {
      key: '_teardownHooks',
      value: function _teardownHooks() {
        this.willShiftSatellites = null;
        this.didShiftSatellites = null;
        this.willResizeSatellites = null;
        this.didResizeSatellites = null;
        this.willAdjustPosition = null;
        this.didAdjustPosition = null;
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this._teardownHandlers();
        this._teardownHooks();
        this.satellites.forEach(function (satellite) {
          satellite.destroy();
        });
        this.satellites = null;
        this.telescope = null;
        this.sky = null;

        this.planet.destroy();
        this.planet = null;
        this.scrollContainer = null;
        this.skyline.destroy();
        this.skyline = null;
      }
    }]);

    return Radar;
  })();

  exports['default'] = Radar;
});