define('ember-custom-fields-components/components/custom-fields/controls/integer/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default', 'ember-custom-fields-components/components/custom-fields/controls/integer/template', 'ember-custom-fields-components/utils/field-validation'], function (exports, _default, _template, _fieldValidation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _default.default.extend({
        layout: _template.default,
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey);

            if (!Ember.isBlank(savedData)) {
                this.set('control.value', savedData);
                this.send('validate');
            }
        },


        messages: Ember.A([]),
        hasErrors: Ember.computed('messages', function () {
            return this.get('messages').length > 0;
        }),

        actions: {
            validate: function validate() {
                var messages = Ember.A([]);
                var intl = this.get('intl'),
                    value = this.get('control.value'),
                    minimum = this.get('control.minimum'),
                    maximum = this.get('control.maximum');

                this.set('messages', Ember.A([]));
                this.set('control.isValid', true);

                if (!Ember.isBlank(value)) {
                    var errorMessage = '';

                    if (typeof minimum === 'number' && Number(value) < minimum) {
                        errorMessage = intl.t('emberCustomFieldsComponents.fieldErrors.minValueInteger', { minimum: minimum });
                        messages.pushObject(errorMessage);
                    }

                    if (typeof maximum === 'number' && Number(value) > maximum) {
                        errorMessage = intl.t('emberCustomFieldsComponents.fieldErrors.maxValueInteger', { maximum: maximum });
                        messages.pushObject(errorMessage);
                    }

                    if (!_fieldValidation.default.validateInteger(value)) {
                        errorMessage = intl.t('emberCustomFieldsComponents.fieldErrors.invalidInteger');
                        messages.pushObject(errorMessage);
                    }

                    if (messages.length > 0) {
                        this.set('messages', messages);
                        this.set('control.isValid', false);
                    }

                    if (this.get('setCustomFieldValidation')) {
                        this.get('setCustomFieldValidation')();
                    }
                }
            }
        }
    });
});