define('ember-engine-custom-fields/mixins/safe-set', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        _notDestroyed: function _notDestroyed() {
            return !(this.get('isDestroyed') || this.get('isDestroying'));
        },
        _safeSet: function _safeSet(property, value) {
            // Note that the calls to this method are an attempt to minimize error traces from setting
            // properties on a component that's is or is being destroyed.  They're near-term workarounds for a well-known ember idiosyncrasy exemplified
            // by this discussion:  http://discuss.emberjs.com/t/how-to-do-you-check-for-isdestroyed/9621/34
            // The longer-term solution is likely to leverage the ember-concurrency library.
            if (this._notDestroyed()) {
                this.set(property, value);
            }
        },
        _safeSetProperties: function _safeSetProperties(properties) {
            if (this._notDestroyed()) {
                this.setProperties(properties);
            }
        }
    });
});