define('ember-custom-fields-components/components/custom-fields/controls/number/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default', 'ember-custom-fields-components/components/custom-fields/controls/number/template', 'ember-custom-fields-components/utils/field-validation'], function (exports, _default, _template, _fieldValidation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _default.default.extend({
        layout: _template.default,
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey);

            if (!Ember.isBlank(savedData)) {
                this.set('control.value', savedData);
                this.send('validate');
            }
        },


        message: null,

        actions: {
            validate: function validate() {
                var intl = this.get('intl'),
                    value = this.get('control.value'),
                    minimum = this.get('control.minimum'),
                    maximum = this.get('control.maximum');

                this.set('message', '');
                this.set('control.isValid', true);

                if (!Ember.isBlank(value)) {
                    var errorMessage = '';

                    if (typeof minimum === 'number' && Number(value) < minimum) {
                        errorMessage = intl.t('emberCustomFieldsComponents.fieldErrors.minValueNumber', { minimum: minimum });
                    }

                    if (typeof maximum === 'number' && Number(value) > maximum) {
                        errorMessage = intl.t('emberCustomFieldsComponents.fieldErrors.maxValueNumber', { maximum: maximum });
                    }

                    if (!_fieldValidation.default.validateNumber(value)) {
                        errorMessage = intl.t('emberCustomFieldsComponents.fieldErrors.invalidNumber');
                    }

                    if (errorMessage) {
                        this.set('message', errorMessage);
                        this.set('control.isValid', false);
                    }

                    if (this.get('setCustomFieldValidation')) {
                        this.get('setCustomFieldValidation')();
                    }
                }
            }
        }
    });
});