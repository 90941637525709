define('ember-chat-components/models/chat-search-result', ['exports', 'ember', 'ember-data', 'ember-purecloud-components/utils/dates'], function (exports, _ember, _emberData, _dates) {
    'use strict';

    exports.__esModule = true;


    const ChatSearchResultModel = _emberData.default.Model.extend({
        intl: _ember.default.inject.service(),

        body: _emberData.default.attr('string'),
        createdDate: _emberData.default.attr(),
        to: _emberData.default.attr(),
        from: _emberData.default.attr(),
        time: _ember.default.computed('createdDate', function () {
            return (0, _dates.displayDateRelativeToToday)(this.get('createdDate'), this.get('intl.isoLocale'));
        })
    });

    exports.default = ChatSearchResultModel;
});