define('ember-custom-fields-components/models/identifier', ['exports', 'ember-custom-fields-components/models/text'], function (exports, _text) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Identifier = _text.default.extend({});

  exports.default = Identifier;
});