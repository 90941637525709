define('ember-engine-custom-fields/mixins/notification-set', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Mixin.create({
        intl: inject.service(),
        notification: inject.service(),

        _showNotification: function _showNotification(titleKey, descriptionKey) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var title = void 0,
                field = void 0,
                intl = void 0,
                notificationOptions = void 0;
            intl = this.get('intl');
            notificationOptions = Object.assign({
                forceBrowserNotification: true,
                hideAfter: 5
            }, options);
            title = intl.t(titleKey, options);
            field = intl.t(descriptionKey, options);
            var isError = options.isError;

            if (isError) {
                this.get('notification').error(title, field, notificationOptions);
            } else {
                this.get('notification').info(title, field, notificationOptions);
            }
        }
    });
});