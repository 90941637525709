define('ember-chat-components/utils/credit-card', ['exports', 'credit-card-type'], function (exports, _creditCardType) {
    'use strict';

    exports.__esModule = true;
    exports.hasCreditCard = hasCreditCard;
    exports.redactCreditCard = redactCreditCard;


    // everyone follows the same 16-digit format except AMEX
    // Note: capture groups are important for luhn checks
    const commonCardRegex = /\b(\d{4})[ \t-]*?(\d{4})[ \t-]*?(\d{4})[ \t-]*?(\d{4})\b/g;
    const amexCardRegex = /\b(\d{4})[ \t-]*?(\d{6})[ \t-]*?(\d{1})(\d{4})\b/g;

    // Luhn: filter card numbers via checksum
    // What: https://en.wikipedia.org/wiki/Luhn_algorithm
    // Perf: https://jsperf.com/credit-card-validator/16 (needForSpeed, cleaned up)
    const luhnSet = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    function isLuhn(value) {
        let len = value.length;
        let bit = 1;
        let sum = 0;
        while (len--) {
            sum += !(bit ^= 1) ? parseInt(value[len], 10) : luhnSet[value[len]];
        }
        return sum % 10 === 0 && sum > 0;
    }

    function matchesCardType(text) {
        const cleanedTextLines = text.split(/\n/);
        const types = cleanedTextLines.reduce((list, line) => list.concat((0, _creditCardType)(line.replace(/[^0-9]+/gm, ''))), []);
        return types.length > 0;
    }

    function containsLuhn(text) {
        // unlike redact we can exit early on success here
        // but must reset lastIndex at the end
        let found = false;
        let result;
        while (!found && (result = commonCardRegex.exec(text))) {
            const [, d1, d2, d3, d4] = result;
            found = isLuhn(d1 + d2 + d3 + d4);
        }

        while (!found && (result = amexCardRegex.exec(text))) {
            const [, d1, d2, d3, d4] = result;
            found = isLuhn(d1 + d2 + d3 + d4);
        }

        commonCardRegex.lastIndex = 0;
        amexCardRegex.lastIndex = 0;
        return found;
    }

    function hasCreditCard(text) {
        return matchesCardType(text) && containsLuhn(text);
    }

    function redactCreditCard(text, replacer) {
        const replacement = (match, d1, d2, d3, d4) => {
            if (isLuhn(d1 + d2 + d3 + d4)) {
                return replacer(d4); // d4: last four digits
            }
            return match;
        };
        if (!matchesCardType(text)) {
            return text;
        }
        return text.replace(commonCardRegex, replacement).replace(amexCardRegex, replacement);
    }
});