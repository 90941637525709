define("ember-purecloud-components/components/entity-v2/presence-indicator-dot/component", ["exports", "ember-purecloud-components/components/entity-v2/presence-indicator-dot/template", "ember-purecloud-components/utils/presence"], function (_exports, _template, _presence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2-presence-indicator-dot'],
    classNameBindings: ['presenceClass'],
    entity: null,
    showNotResponding: false,
    showIdle: false,
    showCommunicating: false,
    presenceClass: Ember.computed('isOnQueue', 'entity.presence.presenceDefinition.systemPresence', 'entity.outOfOffice.active', 'isNotResponding', 'isIdle', 'showNotResponding', 'showIdle', 'isCommunicating', 'showCommunicating', function () {
      if (this.get('isNotResponding') && this.get('showNotResponding')) {
        return 'not_responding';
      }

      if (this.get('isIdle') && this.get('showIdle')) {
        return 'idle';
      }

      if (this.get('isOnQueue') && this.get('isCommunicating') && this.get('showCommunicating')) {
        return 'communicating';
      }

      var presence = (this.get('entity.presence.presenceDefinition.systemPresence') || 'offline').toLowerCase();

      if (presence === 'idle') {
        // use system_idle to differentiate from on-queue idle
        presence = 'system_idle';
      }

      var oooActive = this.get('entity.outOfOffice.active');
      return oooActive ? 'out_of_office' : Ember.String.underscore(presence);
    }),
    isNotResponding: Ember.computed('entity.routingStatus.status', function () {
      return this.get('entity.routingStatus.status') === 'NOT_RESPONDING';
    }),
    isIdle: Ember.computed('entity.routingStatus.status', function () {
      return this.get('entity.routingStatus.status') === 'IDLE';
    }),
    isCommunicating: Ember.computed('entity.routingStatus.status', function () {
      return this.get('entity.routingStatus.status') === 'COMMUNICATING';
    }),
    isOnQueue: Ember.computed('entity.presence.presenceDefinition.systemPresence', function () {
      return (0, _presence.isPresenceEqual)(_presence.systemPresences.ON_QUEUE, this.get('entity.presence.presenceDefinition.systemPresence'));
    })
  });

  _exports.default = _default;
});