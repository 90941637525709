define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed } = _ember.default;

    const EMBEDDED_SUPPORTED_TYPES = ['github-repo'];

    const WithMediaComponent = Component.extend({
        classNames: ['widgets-default', 'media-item'],
        classNameBindings: ['mediaTypeClass', 'collapsed', 'hide'],
        layout: _template.default,

        preferences: _ember.default.inject.service(),

        mediaItem: null,
        isMediaCollapsed: null,

        mediaTypeClass: computed('mediaItem.mediaType', function () {
            const mediaType = this.get('mediaItem.mediaType');
            if (mediaType) {
                return `media-item-${mediaType}`;
            }
        }),

        hide: computed('mediaItem.{showEmbedConfirmation,shouldEmbed}', 'embedConfirmationSupported', function () {
            const showEmbedConfirmation = this.get('mediaItem.showEmbedConfirmation');
            const embedConfirmationSupported = this.get('embedConfirmationSupported');
            if (showEmbedConfirmation && embedConfirmationSupported) {
                return false;
            }

            return !this.get('mediaItem.shouldEmbed');
        }),

        _collapsed: null,
        collapsed: computed('_collapsed', 'mediaItem.collapsed', 'preferences.chat.global.mediaExpanded', {
            get: function () {
                const itemCollapsed = this.get('mediaItem.collapsed');
                const componentCollapsed = this.get('_collapsed');
                const isMediaCollapsed = this.get('isMediaCollapsed');
                const mediaExpandedPreference = this.get('preferences.chat.global.mediaExpanded');
                let preferenceCollapse = !this.get('preferences.chat.global.mediaExpanded');
                if (mediaExpandedPreference === undefined) {
                    preferenceCollapse = false; // this is the default if preference doesn't exist
                }
                const hideCollapse = this.get('hideCollapse');

                // for certain media items, always display & hide the collapse option
                if (typeof hideCollapse === 'boolean') {
                    return !hideCollapse;
                }
                // When the init state 'isMediaCollapsed' is provided, precedence is given to the component
                if (typeof isMediaCollapsed === 'boolean') {
                    if (typeof componentCollapsed === 'boolean') {
                        return componentCollapsed;
                    }
                    if (typeof itemCollapsed === 'boolean') {
                        return itemCollapsed;
                    }
                } else {
                    // when isMediaCollapsed is not provided, precedence is given to the item over the component
                    if (typeof itemCollapsed === 'boolean') {
                        return itemCollapsed;
                    }
                    if (typeof componentCollapsed === 'boolean') {
                        return componentCollapsed;
                    }
                }
                //default to the preference
                return preferenceCollapse;
            },
            set: function (key, value) {
                this.set('_collapsed', value);

                //when isMediaCollapsed is provided, it doesn't set the item state
                let isMediaCollapsed = this.get('isMediaCollapsed');
                if (isMediaCollapsed === null) {
                    this.set('mediaItem.collapsed', value);
                }

                return value;
            }
        }),

        embedConfirmationSupported: computed('mediaItem.mediaType', function () {
            return EMBEDDED_SUPPORTED_TYPES.includes(this.get('mediaItem.mediaType'));
        }),

        hideCollapse: computed('mediaItem.mediaType', function () {
            if (this.get('mediaItem.mediaType') === 'mention-item') {
                return true;
            }

            return null;
        }),

        init() {
            this._super(...arguments);
            this.initCollapseState();
        },

        initCollapseState() {
            let mediaCollapsed = this.get('isMediaCollapsed');
            if (mediaCollapsed !== null) {
                return this.set('_collapsed', mediaCollapsed);
            }

            let shouldEmbed = this.get('mediaItem.shouldEmbed');
            if (shouldEmbed === undefined) {
                this.set('mediaItem.shouldEmbed', true);
            }
        },

        actions: {
            toggleMediaCollapsed() {
                this.toggleProperty('collapsed');
            },

            setEmbedPreference(pref, mediaItem) {
                mediaItem.setEmbedPreference(pref);
            }
        }
    });

    exports.default = WithMediaComponent;
});