define("ember-purecloud-components/components/volume-input/component", ["exports", "ember-purecloud-components/components/volume-input/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.$(window).on('click', Ember.run.bind(this, this.handleWindowClickEvent));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.$(window).off('click', this.handleWindowClickEvent);
    },
    backgroundColorClass: Ember.computed('theme', function () {
      var theme = this.get('theme') || 'light';
      return "audio-playback-channel-volume--".concat(theme);
    }),
    markerPositionClass: Ember.computed('markerPosition', function () {
      return "audio-playback-channel-volume--marker-".concat(this.get('markerPosition'));
    }),
    handleWindowClickEvent: function handleWindowClickEvent(event) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      var $target = this.$(event.target) || null;

      if ($target && ($target.closest("#".concat(this.elementId)).length || $target.closest(this.get('toggleButtonSelector')).length)) {} else {
        this.set('isVisible', false);
      }
    }
  });

  _exports.default = _default;
});