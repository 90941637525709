define('ember-chat-components/mixins/chat-commands', ['exports', 'ember', 'ember-purecloud-components/utils/dates', 'ember-chat-components/utils/giphy'], function (exports, _ember, _dates, _giphy) {
    'use strict';

    exports.__esModule = true;


    const MD_SPECIAL_CHAR_REG_EXP = new RegExp('([_`\\\\])', 'g'); /* eslint-disable ember/closure-actions */

    exports.default = _ember.default.Mixin.create({
        session: _ember.default.inject.service(),
        chat: _ember.default.inject.service(),
        notification: _ember.default.inject.service(),
        permissions: _ember.default.inject.service(),
        phone: _ember.default.inject.service(),
        webrtc: _ember.default.inject.service(),
        settings: _ember.default.inject.service(),
        sidebar: _ember.default.inject.service(),
        preferences: _ember.default.inject.service(),
        cobrowse: _ember.default.inject.service(),
        documents: _ember.default.inject.service(),
        geolocation: _ember.default.inject.service(),
        intl: _ember.default.inject.service(),
        presenceSubscription: _ember.default.inject.service(),
        roster: _ember.default.inject.service(),
        chatCommands: _ember.default.inject.service(),

        textarea: null,
        sequenceMappings: _ember.default.computed('session.features.extendedChatCommands', function () {
            /* eslint-disable quotes */
            const sequences = [{
                key: '/dance',
                value: '`DANCE PARTY!`',
                sequence: ["`<('.'<)`", "`(>'.')>`", "`<('.'<)`", "`<('.'^)`", "`^('.'^)`", "`(^'.')>`", "`(>'.')>`", "`<('.'<)`", "`(>'.')>`", '`<(^.^)>`']
            }];
            /* eslint-enable quotes */

            return sequences;
        }),

        internalMappings: _ember.default.computed(function () {
            const internalMappings = [{
                name: 'shrug',
                value: '¯\\_(ツ)_/¯',
                hidden: true,
                inlineReplacement: true
            }, {
                name: 'tableflip',
                value: '(╯°□°)╯︵ ┻━┻',
                hidden: true,
                inlineReplacement: true
            }, {
                name: 'simmerdown',
                value: '┬─┬﻿ ノ( ゜-゜ノ)',
                hidden: true,
                inlineReplacement: true
            }, {
                name: 'rage',
                value: '┻━┻ ︵ヽ(`Д´)ﾉ︵﻿ ┻━┻',
                hidden: true,
                inlineReplacement: true
            }, {
                name: 'respecttables',
                value: '┬─┬ ︵ヽ(ಠ_ಠ)ﾉ︵ ┬─┬',
                hidden: true,
                inlineReplacement: true
            }, {
                name: 'cry',
                value: '(ಥ﹏ಥ)',
                hidden: true,
                inlineReplacement: true
            }, {
                name: 'map',
                value: this.showLocation
            }, {
                name: 'weather',
                value: this.showWeather
            }, {
                name: 'roll',
                value: this.rollDice
            }, {
                name: 'edit',
                hidden: true,
                command: 's/',
                value: this.editMessage,
                expression: '^s/'
            }];

            return internalMappings;
        }),

        publicMappings: _ember.default.computed('session.features.cobrowseAssist', function () {
            const publicMappings = [{
                name: 'afk',
                value: this.afk
            }, {
                name: 'collapse',
                value: this.collapseMedia
            }, {
                name: 'dnd',
                value: this.dnd
            }, {
                name: 'expand',
                value: this.expandMedia
            }, {
                name: 'giphy',
                value: this.sendGiphyGif
            }, {
                name: 'last',
                value: this.openLastRoom
            }, {
                name: 'leave',
                value: this.leaveCurrentRoom
            }, {
                name: 'mute',
                value: this.muteRoom
            }, {
                name: 'phone',
                value: this.makePhoneCall,
                oneToOne: true
            }, {
                name: 'popout',
                value: this.popoutChatRoom
            }, {
                name: 'prefs',
                value: this.openPreferences
            }, {
                name: 'presence',
                value: this.setPresence
            }, {
                name: 'selfie',
                value: this.takeSelfie
            }, {
                name: 'unmute',
                value: this.unmuteRoom
            }, {
                name: 'whisper',
                value: this.sendWhisper
            }];

            if (this.get('session.features.cobrowseAssist')) {
                publicMappings.push({
                    name: 'cobrowse',
                    value: this.startStopCobrowse
                });
            }
            publicMappings.push({
                name: 'search',
                value: this.searchCommand
            });

            return publicMappings;
        }),

        shortcutMappings: _ember.default.computed('session.features.extendedChatCommands', 'internalMappings.[]', 'publicMappings.[]', 'chatCommands.commands.[]', function () {
            let mappings;
            const serviceCommands = this.get('chatCommands.commands');
            if (!this.get('session.features.extendedChatCommands')) {
                mappings = [].concat(this.get('publicMappings'), serviceCommands);
            } else {
                mappings = [].concat(this.get('internalMappings'), this.get('publicMappings'), serviceCommands);
            }

            return (mappings || []).map(mapping => {
                const result = Object.assign({}, mapping);
                if (!result.command) {
                    result.command = `/${result.name}`;
                }

                let expression = result.expression;
                if (!expression) {
                    expression = result.inlineReplacement ? `^${result.command}$` : result.command;
                }
                result.regExp = new RegExp(expression);

                if (typeof result.value === 'string') {
                    result.value = result.value.replace(MD_SPECIAL_CHAR_REG_EXP, '\\$1');
                }
                return result;
            }).sort(function (cmd1, cmd2) {
                // Sort inline to last so proper commands have priority
                const val1 = cmd1.inlineReplacement ? 1 : 0;
                const val2 = cmd2.inlineReplacement ? 1 : 0;
                return val1 - val2;
            });
        }),

        filteredMappings: _ember.default.computed('shortcutMappings.[]', 'chatCommands.commands.[]', 'chat.activeRoom.oneToOneTarget', 'webrtc.{enabled,videoCallCapable,hasCamera}', function () {
            const mappings = this.get('shortcutMappings');
            const phoneEnabled = this.get('phone.enabled') && this.get('chat.activeRoom.oneToOneTarget');
            const videoEnabled = this.get('webrtc.enabled') && this.get('webrtc.videoCallCapable');
            const cameraEnabled = this.get('webrtc.videoCallCapable') && this.get('webrtc.hasCamera');
            const docEnabled = this.get('chat.activeRoom.oneToOneTarget');
            const giphyEnabled = this.get('permissions.canUseGiphy');
            const intl = this.get('intl');
            return _ember.default.A(mappings.filter(function (mapping) {
                if (mapping.hidden) {
                    return false;
                }
                if ('phone' === mapping.name) {
                    return phoneEnabled;
                }
                if ('video' === mapping.name) {
                    return videoEnabled;
                }
                if ('documents' === mapping.name) {
                    return docEnabled;
                }
                if ('map' === mapping.name) {
                    return false;
                }
                if ('selfie' === mapping.name) {
                    return cameraEnabled;
                }
                if ('giphy' === mapping.name) {
                    return giphyEnabled;
                }
                return true;
            }).map(function (mapping) {
                if (intl.exists(`chat.commands.${mapping.name}.command`)) {
                    return {
                        help: intl.t(`chat.commands.${mapping.name}.description`).toString(),
                        name: '/' + intl.t(`chat.commands.${mapping.name}.command`).toString(),
                        value: mapping.value
                    };
                }
                return {
                    help: '',
                    name: `/${mapping.name}`,
                    value: mapping.value
                };
            })).sortBy('name');
        }),

        actions: {
            confirmModalUpload(file) {
                this.set('showUploadModal', false);
                if (!(file instanceof File)) {
                    if (typeof file === 'string') {
                        this.showError(file);
                    } else {
                        this.showError(this.get('intl').t('chat.commands.selfie.error').toString());
                    }
                }
                const dropzone = this.get('dropzone');
                dropzone.addFile(file);
                dropzone.processQueue();
                this.removeThumbnail();
            },
            cancelModalUpload() {
                this.set('showUploadModal', false);
            }
        },

        loadAtwho: _ember.default.on('didInsertElement', function () {
            if (!this.get('acdToggle')) {
                this._initCommandsAutoComplete();
            }
        }),

        initializeBlankAtWho() {
            const textarea = this.$('textarea');
            if (textarea) {
                textarea.atwho('load', '', []).atwho('run');
            }
        },

        _errorHandler(jqXHR /*, textStatus, errorThrown*/) {
            _ember.default.onerror(jqXHR);
        },

        _initCommandsAutoComplete(mappings) {
            let xhr = null;
            if (!mappings) {
                mappings = this.get('filteredMappings');
            }
            const textarea = this.$('textarea');
            if (!textarea) {
                return;
            }
            this.set('textarea', textarea);
            const matcher = (flag, subtext) => {
                const regexp = new RegExp(`^${flag}([A-z]*)+$`);

                // this is for cases where Regex exec will take several minutes, or fail completely, if the subtext matches the below pattern, for reasons unknown
                const testCase = new RegExp(/^(\/[A-z0-9_\\]*\/)/);
                if (testCase.exec(subtext)) {
                    return;
                }

                const match = regexp.exec(subtext);
                if (match && match.length >= 1) {
                    return match[0];
                } else {
                    return null;
                }
            };

            const remoteFilter = (query, renderView) => {
                const searchTerm = _ember.default.$.trim(query);
                const currentText = this.get('messageInput') === null ? '' : this.get('messageInput');

                if (!currentText.startsWith('/') || currentText.split('~').length > 1) {
                    textarea.atwho('hide');
                    return [];
                } else if (!currentText.startsWith('/documents')) {
                    renderView(this.get('filteredMappings'));
                } else {
                    const searchStr = searchTerm;
                    if (xhr) {
                        xhr.abort();
                    }
                    const query = {
                        pageNumber: 1,
                        pageSize: 10,
                        queryPhrase: searchStr,
                        sort: [{
                            name: 'dateCreated',
                            ascending: false
                        }],
                        filters: [{
                            systemFilter: false,
                            id: 'workspaceId',
                            type: 'STRING',
                            name: 'workspaceId',
                            operator: 'EQUALS',
                            values: [this.get('session.person.guid')]
                        }]
                    };
                    const sanitizeExpression = _ember.default.Handlebars.Utils.escapeExpression;
                    const request = {
                        url: this.get('application').pcUri('api/v2/contentmanagement/query'),
                        type: 'post',
                        contentType: 'application/json; charset=UTF-8',
                        dataType: 'json',
                        data: JSON.stringify(query),
                        cache: false,
                        success: function (response) {
                            xhr = null;
                            const dataSource = [];
                            (response.results.entities || []).forEach(value => {
                                const data = value.body;
                                const name = sanitizeExpression(data.name);
                                if (data !== null) {
                                    dataSource.push({
                                        name: name,
                                        sharingUri: data.sharingUri,
                                        type: data.contentType,
                                        id: data.id,
                                        contentUri: data.contentUri
                                    });
                                }
                            });
                            this.set('documentsList', dataSource);
                            renderView(dataSource);
                        },
                        context: this
                    };
                    xhr = _ember.default.$.ajax(request);
                }
                return null;
            };

            textarea.atwho({
                at: '/',
                alias: 'at-commands',
                /* eslint-disable no-template-curly-in-string */
                displayTpl: '<li>\n    <span class="command-name">${name}</span> <span class="help">${help}</span>\n</li>',
                insertTpl: '${name}',
                /* eslint-enable no-template-curly-in-string */
                limit: mappings.length,
                callbacks: {
                    matcher: matcher,
                    remoteFilter: remoteFilter
                },
                scrollDuration: 10
            });

            textarea.on('inserted-at-commands.atwho', event => {
                _ember.default.run(() => this.set('messageInput', event.target.value));
            });

            textarea.on('shown-at-commands.atwho', () => {
                this.set('buildingAutocomplete', true);
            });

            textarea.on('hidden-at-commands.atwho', (jqEvent, event) => {
                if (!event || event.type === 'click') {
                    this.set('buildingAutocomplete', false);
                }
            });
        },

        _destroyCommandsAutoComplete(textarea) {
            // call this to destroy commands autocomplete in consumer of mixin, required
            if (textarea) {
                textarea.atwho('destroy');
            }
        },

        addChatCommand(name, action) {
            const commands = this.get('publicMappings');
            commands.addObject({
                name,
                value: action
            });
        },

        checkForCommand(message, options) {
            if (this.get('acdToggle')) {
                return message;
            }
            message = this.checkForShortcutCommand(message, options);
            message = this.checkForSequenceCommand(message);
            return message;
        },

        checkForShortcutCommand(message, options) {
            const shortcutMappings = this.get('shortcutMappings') || [];

            let exclusiveShortcut = null;
            let inlineShortcuts = [];

            for (let i = 0; i < shortcutMappings.length; i++) {
                const currMapping = shortcutMappings[i];
                const firstIndex = message.indexOf(currMapping.command);

                if (firstIndex === 0 && !currMapping.inlineReplacement) {
                    exclusiveShortcut = currMapping;
                    // Short-circuit the loop if we find a proper command
                    break;
                } else if (firstIndex >= 0 && currMapping.inlineReplacement) {
                    inlineShortcuts.push(currMapping);
                }
            }

            if (exclusiveShortcut) {
                if (typeof exclusiveShortcut.value === 'function') {
                    return exclusiveShortcut.value.call(this, exclusiveShortcut.name, message.replace(exclusiveShortcut.regExp, '').trim(), options, message) || '';
                } else {
                    message = message.replace(exclusiveShortcut.regExp, exclusiveShortcut.value);
                    return message;
                }
            } else if (inlineShortcuts.length > 0) {
                const tokens = message.split(' ');
                (tokens || []).forEach((currToken, index) => {
                    if (currToken.indexOf('/') === 0) {
                        inlineShortcuts.forEach(currShortcut => {
                            const matchResult = currShortcut.regExp.exec(currToken);
                            if (matchResult) {
                                tokens[index] = currShortcut.value;
                                return false;
                            }
                        });
                    }
                });
                return tokens.join(' ');
            }
            return message;
        },

        checkForSequenceCommand(message) {
            const sequenceMappings = this.get('sequenceMappings');
            const mapping = sequenceMappings.find(mapping => {
                if (typeof message !== 'string' || !message.indexOf) {
                    return false;
                }
                return message.indexOf(mapping.key) === 0;
            });

            if (mapping) {
                const sequenceArgs = message.slice(mapping.key.length);
                this.triggerSequence(mapping.sequence, sequenceArgs);
                if (mapping.silent) {
                    return null;
                }
            }

            return message;
        },

        triggerSequence(sequence, args) {
            if (Array.isArray(sequence)) {
                _ember.default.run.later(this, this._handleSequenceArray, sequence, 500);
            } else {
                _ember.default.run.later(this, this._handleSequenceFunction, sequence, args, true, 500);
            }
        },

        _handleSequenceArray(sequence) {
            if (!sequence || sequence.length === 0) {
                return;
            }

            const [message, ...rest] = sequence;

            this.sendAction('sendMessage', message, this.get('chat').get('activeRoom'), null, true);

            _ember.default.run.later(this, this._handleSequenceArray, rest, 500);
        },

        _handleSequenceFunction(func, args, initial) {
            if (!(initial || args)) {
                return;
            }

            const result = func(args, initial);

            this.sendAction('sendMessage', result.message, this.get('activeRoom'));

            _ember.default.run.later(this, this._handleSequenceFunction, func, result.args, false, 500);
        },

        leaveCurrentRoom(cmd, arg) {
            if (!arg) {
                arg = '';
            }
            const chat = this.get('chat');
            const roster = this.get('roster');
            const leaveRooms = function (rooms) {
                return (rooms || []).forEach(function (room) {
                    return chat.closeRoomAndClearCounts(room);
                });
            };
            let rosters = {
                active: false,
                favorites: false,
                people: false,
                groups: false,
                adhocs: false
            };
            if (!arg) {
                rosters.active = true;
            } else {
                const items = arg.split(' ');
                (items || []).forEach(target => {
                    if (target === 'room' || target === 'active' || target === 'current') {
                        rosters.active = true;
                    } else if (target === 'all') {
                        rosters = {
                            active: true,
                            favorites: true,
                            people: true,
                            groups: true,
                            adhocs: true
                        };
                    } else if (target === 'favorites') {
                        rosters.favorites = true;
                    } else if (target === 'people' || target === 'directs' || target === 'direct') {
                        rosters.people = true;
                    } else if (target === 'official' || target === 'groups' || target === 'group') {
                        rosters.groups = true;
                    } else if (target === 'personal' || target === 'adhocs' || target === 'adhoc') {
                        rosters.adhocs = true;
                    }
                });
            }
            if (rosters.active) {
                leaveRooms([chat.get('activeRoom')]);
            }
            if (rosters.favorites) {
                leaveRooms(roster.get('favorites'));
            }
            if (rosters.people) {
                leaveRooms(roster.get('people'));
            }
            if (rosters.groups) {
                leaveRooms(roster.get('groups'));
            }
            if (rosters.adhocs) {
                leaveRooms(roster.get('adhocs'));
            }
        },

        shareDoc(docName) {
            const sourceList = this.get('documentsList');
            const source = (sourceList || []).find(doc => doc.name === docName);
            const shareData = {
                sharedEntityType: 'DOCUMENT',
                sharedEntity: {
                    id: source.id
                },
                memberType: 'USER',
                member: {
                    id: this.get('chat.activeRoom.oneToOneTarget.guid')
                }
            };
            const successHandler = () => /*data, textStatus, jqXHR*/{
                this.set('sharingDoc', false);
                return this.sendAction('sendMessage', `${source.sharingUri}`, this.get('activeRoom'));
            };
            this.setProperties({
                messageInput: '',
                sharingDoc: true
            });
            this.setTextareaValue('');
            this.get('documents').shareDocument(shareData).then(successHandler).catch(this._errorHandler.bind(this));
        },

        openLastRoom() {
            var chat, historyStack, lastJid;
            chat = this.get('chat');
            historyStack = chat.get('roomHistoryStack');
            if (historyStack.length <= 1) {
                return;
            }
            lastJid = historyStack.get(historyStack.length - 2);
            this.set('messageInput', '');
            return chat.getChatRoom(lastJid).then(function (room) {
                return chat.changeActiveRoom(room);
            });
        },

        makePhoneCall() {
            var phone;
            phone = this.get('phone');
            if (this.get('chat.activeRoom.oneToOneTarget.type') === 'person') {
                return phone.makeCall(this.get('chat.activeRoom.oneToOneTarget'));
            }
        },

        sendGiphyGif(cmd, args, cmdOptions = {}) {
            const giphyString = `/${cmd} ${args}`;

            const isGiphyDisabled = !this.get('filteredMappings').find(mapping => mapping.name === '/giphy');
            if (isGiphyDisabled) {
                return giphyString;
            }

            const query = args;
            _giphy.default.random(query).catch(error => {
                _ember.default.Logger.error('Failed to send giphy', {
                    error
                });
                return null;
            }).then(gif => {
                if (gif) {
                    const message = `\`${giphyString}\`\n\n ${gif.url}`;
                    if (cmdOptions.isMessageEdit) {
                        return message;
                    } else {
                        this.sendAction('sendMessage', message, this.get('activeRoom'));
                    }
                } else {
                    if (cmdOptions.isMessageEdit) {
                        return giphyString;
                    } else {
                        this.sendAction('sendMessage', giphyString, this.get('activeRoom'));
                    }
                }
            });
        },

        popoutChatRoom() {
            this._popoutRoom(this.get('activeRoom'));
        },

        _popoutRoom(room) {
            let popout = room.get('popout') && !room.get('popout.closed');
            if (popout || window.location.hash.indexOf('chat-room') === -1) {
                const showingVideo = room.get('showingVideo');
                const joinCallImmediately = !!this.get('webrtc.activeConversation.hasJoined');
                const startVideoMuted = this.get('webrtc.activeConversation.localParticipant.videoMuted');
                const startAudioMuted = this.get('webrtc.activeConversation.localParticipant.audioMuted');
                this.get('chat').popoutChatRoom(room, showingVideo, joinCallImmediately, startVideoMuted, startAudioMuted);
                if (showingVideo && !popout) {
                    // This is an an idea for improving the popout - the current video stays active
                    // for a few seconds until the popout bootstraps, then disconnects. If we do this,
                    // we'd need to add messaging to the user to show why it hasn't disconnected yet.
                    // Something like 'reconnecting your video in the popout window. This may take a few moments.''
                    //
                    // Ember.run.later(this, () => {
                    //     this.get('webrtc').endConversation({jid: room.get('jid'), reason: 'alternative-session'});
                    // }, 11000);
                    this.get('webrtc').endConversation({ jid: room.get('jid'), reason: 'alternative-session' });
                }
                this.get('sidebar').openPanel('conversations');
            }
        },

        rollDice(cmd, args, cmdOptions = {}) {
            const random = function (min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            };
            if (!args) {
                return;
            }

            let match = args.match(/(\d+)d(\d+)/);
            if (!match || match.length !== 3) {
                return;
            }

            // eslint-disable-next-line no-unused-vars
            const [head, ...tail] = match;
            match = tail;

            const min = 1;
            let max = [].concat(match).pop();
            let number = match[0];

            if (number > 6) {
                number = 6;
            }
            if (max > 24) {
                max = 24;
            }

            const roll = function () {
                const results = [];
                for (let i = 0; i < number; i++) {
                    results.push(random(min, max));
                }
                return results;
            }().join(' ');

            const dice = number + 'd' + max;
            const message = this.get('intl').t('chat.commands.roll.message', {
                roll: roll,
                dice: dice
            }).toString();

            if (cmdOptions.isMessageEdit) {
                return message;
            } else {
                this.sendAction('sendMessage', message, this.get('activeRoom'));
            }
        },

        editMessage(cmd, message, cmdOptions = {}, fullMessage) {
            if (!this.get('chat.canCorrectMessages')) {
                if (cmdOptions.isMessageEdit) {
                    return fullMessage;
                } else {
                    return;
                }
            }

            const room = this.get('activeRoom');
            const [replaceText, replaceWith] = message.split('/');

            if (!replaceText || !replaceWith) {
                return fullMessage;
            }

            const messages = [...(room.get('messages') || [])].reverse();
            const prevMessage = messages.find(message => {
                return message.from === this.get('session.person.jid') && !message.get('corrects');
            });

            if (!prevMessage || !prevMessage.get('id') || !prevMessage.get('isEditable') || cmdOptions.isMessageEdit) {
                return fullMessage;
            }

            const body = prevMessage.get('messageDisplay.raw').replace(replaceText, replaceWith);
            const options = {
                body,
                to: room.get('jid'),
                corrects: prevMessage.get('id')
            };

            this.get('sendMessage')(body, room, options).then(() => {
                this.disableMessagePendingForUploadFlag();
            });
        },

        searchCommand(cmd, args) {
            this.sendAction('triggerChatSearch', args);
        },

        openPreferences() {
            this.get('settings').open();
        },

        muteRoom() {
            this.get('chat').setRoomMuted(this.get('activeRoom.jid'), true);
        },

        unmuteRoom() {
            this.get('chat').setRoomMuted(this.get('activeRoom.jid'), false);
        },

        takeSelfie() {
            this.set('showUploadModal', true);
        },

        _toggleAllMedia(state) {
            (this.get('activeRoom.messages') || []).forEach(message => {
                (message.get('mediaItems') || []).forEach(item => {
                    return item.set('collapsed', state);
                });
            });
        },

        collapseMedia() {
            this._toggleAllMedia(true);
        },

        expandMedia() {
            this._toggleAllMedia(false);
        },

        setPresence(cmd, args) {
            if (!args || !args.trim()) {
                args = 'available';
            }
            this.setSessionUsersPresence(args);
        },

        afk() {
            this.get('presenceSubscription').changePresence(this.get('session.person.presence') === 'away' ? 'available' : 'away');
        },

        dnd() {
            this.get('presenceSubscription').changePresence(this.get('session.person.presence') === 'busy' ? 'available' : 'busy');
        },

        setSessionUsersPresence(presenceString) {
            const presence = this.get('session').getPresence(presenceString);
            if (presence && presence.id) {
                this.get('chat').setOrganizationPresenceId(presence.id);
            }
        },

        showLocation(cmd, args, cmdOptions = {}) {
            let message;
            if (!this.get('geolocation.isEnabled')) {
                _ember.default.Logger.error('Tried to show location with no geolocation capabilities (chat command)');
                message = this.get('intl').t('chat.commands.map.error').toString();
                return;
            }
            let zoom = Number(args) || 12;
            if (zoom > 16) {
                zoom = 16;
            } else if (zoom < 0) {
                zoom = 1;
            }
            const position = this.get('geolocation.currentPosition');
            if (!position) {
                message = this.get('intl').t('chat.commands.map.error').toString();
                this.showError(message);
            } else {
                const lat = position.coords.latitude;
                const lon = position.coords.longitude;
                const coords = `${lat},${lon}`;
                const lang = this.get('intl.isoLocale').substring(0, 2);
                const imgUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${coords}&size=500x500&zoom=${zoom}&maptype=roadmap&markers=color:red%7Clabel:C%7C${coords}&language=${lang}#.jpg`;

                if (cmdOptions.isMessageEdit) {
                    return imgUrl;
                } else {
                    this.sendAction('sendMessage', imgUrl, this.get('activeRoom'));
                }
            }
        },

        showWeather(cmd, args, cmdOptions = {}) {
            let message;
            if (!this.get('geolocation.isEnabled')) {
                _ember.default.Logger.error('Tried to use location with no geolocation capabilities (chat command)');
                message = this.get('intl').t('chat.commands.weather.error').toString();
                this.showError(message);
                return;
            }
            const position = this.get('geolocation.currentPosition');
            if (!position || !position.coords) {
                message = this.get('intl').t('chat.commands.weather.error').toString();
                this.showError(message);
            } else {
                const lat = position.coords.latitude;
                const lon = position.coords.longitude;
                const coords = `${lat},${lon}`;
                const lang = this.get('intl.isoLocale').substring(0, 2);
                let units = 'us';
                if (navigator.language.toLowerCase() !== 'en-us') {
                    units = 'si';
                }
                const request = `https://api.forecast.io/forecast/49a79bf65564064f470261f904ad135a/${coords}`;
                _ember.default.$.ajax({
                    url: request,
                    data: {
                        lang: lang,
                        units: units
                    },
                    dataType: 'jsonp'
                }).then(_ember.default.run.bind(this, data => {
                    data.currently.precipProbability = `${data.currently.precipProbability * 100}`;
                    message = this.get('intl').t('chat.commands.weather.message', data.currently).toString();

                    if (cmdOptions.isMessageEdit) {
                        return message;
                    } else {
                        this.sendAction('sendMessage', message, this.get('activeRoom'));
                    }
                }));
            }
        },

        sendWhisper(cmd, args) {
            const split = args.split(']') || [];
            const name = split[0] + ']';
            const list = this.get('mentionList');
            const id = list[list.indexOf(name) + 1];

            // remove the first mention
            const message = args.replace(/\[@[A-Za-z,\s]+\]\(#\/person\/[a-z0-9]+\)\s/, '');

            let roomJid = null;
            this.store.find('person', id).then(function (data) {
                roomJid = data.get('jid');
            }).then(() => {
                const time = (0, _dates.default)();
                const room = this.get('chat').getChatRoom(roomJid);
                this.get('chat').sendMessageToRoom(roomJid, message).catch(err => {
                    return this.get('chat').messageFailedToSend(room, time, err);
                });
            });
        },

        startStopCobrowse(cmd, args, cmdOptions = {}) {
            const activeSession = this.get('cobrowse.activeSession');
            if (activeSession) {
                return this.get('cobrowse').stopCobrowse();
            } else {
                return this.get('cobrowse').startCobrowse().then(activeSession => {
                    const viewerUrl = activeSession.getViewerUrl();

                    const message = this.get('intl').t('chat.commands.cobrowse.sessionStart', {
                        viewerUrl: activeSession.getViewerUrl()
                    }).toString();

                    const onSessionStateChange = event => {
                        const sessionState = event.newValue;
                        if (sessionState.isEnded()) {
                            activeSession.removeListener('change:sessionState', onSessionStateChange);
                            const message = this.get('intl').t('chat.commands.cobrowse.sessionStop', {
                                viewerUrl
                            }).toString();

                            if (cmdOptions.isMessageEdit) {
                                return message;
                            } else {
                                this.sendAction('sendMessage', message, this.get('activeRoom'));
                            }
                        }
                    };

                    activeSession.on('change:sessionState', onSessionStateChange);

                    if (cmdOptions.isMessageEdit) {
                        return message;
                    } else {
                        this.sendAction('sendMessage', message, this.get('activeRoom'));
                    }
                });
            }
        },

        showError(message) {
            this.get('notification').error(null, message, {
                forceBrowserNotification: true
            });
        }
    });
});