define("ember-purecloud-components/serializers/purecloud", ["exports", "ember-data", "lodash.merge"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      data[primaryModelClass.modelName] = payload;
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      var type = primaryModelClass.modelName;

      if (payload.entities) {
        data[type] = payload.entities;
        delete payload.entities;
        payload = (0, _lodash.default)(data, {
          meta: payload
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});