define('ember-chat-components/components/chat-panel/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Component.extend({
        layout: _template.default,
        classNames: ['chat-panel'],

        chat: _ember.default.inject.service(),
        session: _ember.default.inject.service(),

        disableFileUploads: _ember.default.computed('session.org', function () {
            try {
                let prefs = JSON.parse(this.get('session.org').getFieldValue('preferences.preferences').value);
                let disableUploads = _ember.default.get(prefs, 'realtime.disableFileUploads');
                let unuseable = disableUploads === null || disableUploads === undefined;
                return unuseable ? false : disableUploads;
            } catch (err) {
                return false;
            }
        })
    });
});