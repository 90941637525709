define('ember-chat-components/components/chat-panel/conversation/conversation-pane/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const BADGE_TOGGLE_ID = 'gcucc.ui.chatBadging';

    const autosize = window.autosize;
    const DEBOUNCE_WAIT = 500;

    const ConversationPaneComponent = _ember.default.Component.extend({
        classNames: ['conversation-pane'],
        classNameBindings: ['displayDensity'],
        layout: _template.default,

        chat: _ember.default.inject.service(),
        intl: _ember.default.inject.service(),
        application: _ember.default.inject.service(),
        sidebarService: _ember.default.inject.service('sidebar'),
        session: _ember.default.inject.service(),

        displayDensity: _ember.default.computed.reads('preferences.chat.global.displayDensity'),

        activeRoom: null,
        isAtBottom: true,
        loadingHistory: false,
        hoverOverPerson: false,
        showMiniCard: false,
        focused: false,
        editingMessage: null,
        disableFileUploads: false,
        acdToggle: false,
        distanceToFetchHistory: 5,
        joinErrorMessageDismissed: false,
        disableTextArea: false,

        actions: {
            toggleParticipants() {
                if (this.paneSelectionToggled) {
                    this.paneSelectionToggled('isParticipantsSelected');
                }
                if (this.paneDeselected) {
                    this.paneDeselected(['isInviteSelected', 'isSearchSelected']);
                }
            },
            sendMessage(message, room, options) {
                if (!room) {
                    room = this.get('activeRoom');
                }
                const jid = room.get('jid');
                return this.get('chat').sendMessageProxy(jid, room, message, options).catch(id => {
                    room.setSendError(id);
                });
            },

            clearUnread() {
                return this.get('chat').clearRoomUnreadMessages(this.get('activeRoom'));
            },

            loadMoreHistory() {
                this.get('activeRoom').set('historyError', false);

                const $messagePane = this.$('.message-pane');
                const $lastMessage = this.$('.message-pane .chat-message').first();
                const messageId = $lastMessage.attr('id');
                const promise = this._loadHistoryBefore(this.get('activeRoom'));
                return promise.then(messages => {
                    this._scrollToPreviousTop(messages, $messagePane, messageId);
                    return messages;
                });
            },

            loadMoreHistoryBefore(room) {
                return this._loadHistoryBefore(room);
            },

            loadMoreHistoryAfter(room) {
                return this.get('chat').loadMoreRoomHistoryAfter(room);
            },

            safeScroll() {
                _ember.default.run(() => _ember.default.run.scheduleOnce('afterRender', this, this.safeScroll));
                return null;
            },

            forceScroll() {
                _ember.default.run(() => _ember.default.run.scheduleOnce('afterRender', this, this.forceScroll));
                return null;
            },

            clickMessageLink(message, event) {
                return this.send('clickForCard', message, event);
            },

            replyToMessage(message) {
                this.get('chat').setMessageReply(message.get('correction') || message);
                _ember.default.run.scheduleOnce('afterRender', this, () => {
                    this.$('.inline-reply .chat-textarea textarea').trigger('focus');
                });
            },

            cancelReply() {
                this.get('chat').setMessageReply(null);
                _ember.default.run.scheduleOnce('afterRender', this, () => {
                    this.$('>.chat-textarea textarea').trigger('focus');
                });
            },

            closeContextModal() {
                this.set('searchResultContextVisible', false);
                this.sendAction('deselectSearchResult');
                return null;
            },

            requestEditPrevious() {
                if (this.get('activeRoom.isAcdRoom')) {
                    return;
                }

                const prevMessage = [...(this.get('activeRoom.messages') || [])].reverse().find(message => {
                    return message.get('isYou') && !message.get('corrects') && message.get('widgetType') === 'default';
                });
                if (this.get('editingMessage')) {
                    this.send('doneEditing');
                }
                if (prevMessage && prevMessage.get('isEditable') && !prevMessage.get('files') && !prevMessage.get('pending')) {
                    prevMessage.startEditing();
                    this.set('editingMessage', prevMessage);
                }
            },

            doneEditing() {
                const editingMessage = this.get('editingMessage');
                if (editingMessage) {
                    editingMessage.stopEditing();
                    this.set('editingMessage', null);
                }
                _ember.default.run.scheduleOnce('afterRender', this, () => {
                    this.$('>.chat-textarea textarea').trigger('focus');
                });
            },

            handleResize() {
                _ember.default.run(() => {
                    const $messagePane = this.$('.message-pane');
                    const $textarea = this.$('> .chat-textarea textarea');
                    const $textareaContainer = $textarea.parents('.chat-textarea');
                    const height = $textareaContainer.outerHeight();
                    $messagePane.css({
                        height: `calc(100%-${height}px)`
                    });
                    $textarea.css({
                        'max-height': 130,
                        'overflow-y': 'auto'
                    });
                    this.send('safeScroll');
                });
            },

            messageVisible(component) {
                const previousDistanceToTop = component.getBoundingClientRect().top;

                if (!this.get('activeRoom.loadingHistory') && this.get('activeRoom.moreHistoryAvailable')) {
                    this._loadHistoryBefore(this.get('activeRoom')).then(() => {
                        _ember.default.run.schedule('afterRender', this, function () {
                            const currentDistanceToTop = component.getBoundingClientRect().top;
                            const distanceDifference = currentDistanceToTop - previousDistanceToTop;
                            const scrollPosition = _ember.default.$('.message-pane').scrollTop();
                            const newScrollPosition = scrollPosition + distanceDifference;

                            _ember.default.$('.message-pane').animate({
                                scrollTop: newScrollPosition
                            }, 0);
                        });
                    });
                }
            }
        },

        replyMessage: _ember.default.computed.readOnly('chat.replyMessage'),
        displayJoinError: _ember.default.computed.reads('activeRoom.joinError'),
        displayHistoryError: _ember.default.computed.reads('activeRoom.historyError'),

        showIntegrationBadgeToggle: _ember.default.computed('session.features', 'activeRoom.oneToOneTarget', function () {
            return this.get('session.features')[BADGE_TOGGLE_ID] && this.get('activeRoom.oneToOneTarget');
        }),

        // eslint-disable-next-line ember/no-observers
        dismissJoinErrorMessage: _ember.default.observer('isAtBottom', function () {
            this.set('joinErrorMessageDismissed', true);
        }),

        joinErrorMessage: _ember.default.computed('activeRoom.joinError.message', function () {
            let translationKey = this.getTranslationKey(this.get('activeRoom.joinError.message'));
            return this.get('intl').t(`chat.roomErrors.${translationKey}`);
        }),

        didInsertElement() {
            this._super(...arguments);

            this.attachToScrollEvent();
            this.attachToTextarea();
        },

        willDestroyElement() {
            this._super(...arguments);
            this.detachEvents();
        },

        attachToScrollEvent() {
            const elementId = `.${this.get('elementId')}`;

            this.$('.message-pane').on(`scroll${elementId}`, () => {
                _ember.default.run.debounce(() => this.checkScrolledToBottom(), DEBOUNCE_WAIT);
            });
            this.$('.message-pane').on(`scroll${elementId}`, () => {
                _ember.default.run.debounce(() => this.checkScrolledToTop(), DEBOUNCE_WAIT);
            });
            _ember.default.$(window).on(`resize${elementId}`, () => {
                _ember.default.run.debounce(() => this.safeScroll(), DEBOUNCE_WAIT);
            });
        },

        attachToTextarea() {
            const $textarea = this.$('>.chat-textarea textarea');
            const $textareaContainer = $textarea.parents('.chat-textarea');

            $textarea.on('focus', () => _ember.default.run(() => this.set('focused', true))).on('blur', () => _ember.default.run(() => this.set('focused', false)));

            const $messagePane = this.$('.message-pane');

            const handleResize = () => {
                const height = $textareaContainer.outerHeight();
                $messagePane.css({ height: `calc(100% - ${height}px)` });
                $textarea.css({
                    'max-height': 130,
                    'overflow-y': 'auto'
                });
                this.safeScroll();
            };

            if (autosize) {
                autosize($textarea).on('autosize:resized', handleResize).on('autosize:update', handleResize);
            }
        },

        detachEvents() {
            const messagePane = this.$('.message-pane');
            if (messagePane) {
                messagePane.off(`${this.get('elementId')}`);
            }

            const windowRef = _ember.default.$(window);
            if (windowRef) {
                windowRef.off(`.${this.get('elementId')}`);
            }

            const textarea = this.$('>.chat-textarea textarea');
            if (textarea) {
                textarea.off('focus');
                textarea.off('blur');

                if (autosize) {
                    autosize.destroy(textarea);
                }
            }
        },

        // eslint-disable-next-line ember/no-observers
        watchForCommunicationSidebarExpanded: _ember.default.observer('sidebarService.communicationSidebarExpanded', function () {
            _ember.default.run.scheduleOnce('afterRender', this, this.safeScroll);
        }),

        // eslint-disable-next-line ember/no-observers
        watchActiveRoomChange: _ember.default.observer('activeRoom', function () {
            this.set('joinErrorMessageDismissed', false);
            this.set('isAtBottom', true);
            this.send('closeContextModal');
        }),

        // eslint-disable-next-line ember/no-observers
        scrollToBottomOnFocus: _ember.default.observer('focused', function () {
            if (this.get('focused')) {
                _ember.default.run.scheduleOnce('afterRender', this, this.safeScroll);
            }
        }),

        // eslint-disable-next-line ember/no-observers
        watchForNewMessages: _ember.default.observer('activeRoom.lastMessageTime', function () {
            _ember.default.run.scheduleOnce('afterRender', this, this.safeScroll);
        }),

        getTranslationKey(errorMessage) {
            switch (errorMessage) {
                case 'Room full':
                    return 'fullRoom';
                default:
                    return 'generic';
            }
        },

        checkScrolledToTop() {
            const $messagePane = this.$('.message-pane');

            const parent = this.$('.chat-messages');

            const $child = parent && parent.children()[0];

            if ($child && $messagePane.scrollTop() === 0 && this.get('activeRoom.moreHistoryAvailable') && !this.get('activeRoom.loadingHistory')) {
                const previousDistanceToTop = $child.getBoundingClientRect().top;

                this._loadHistoryBefore(this.get('activeRoom')).then(() => {
                    _ember.default.run.schedule('afterRender', this, function () {
                        const currentDistanceToTop = $child.getBoundingClientRect().top;
                        const distanceDifference = currentDistanceToTop - previousDistanceToTop;
                        const scrollPosition = _ember.default.$('.message-pane').scrollTop();
                        const newScrollPosition = scrollPosition + distanceDifference;

                        _ember.default.$('.message-pane').animate({
                            scrollTop: newScrollPosition
                        }, 0);
                    });
                });
            }
        },

        _scrollToPreviousTop(messages, $messagePane, messageId) {
            if (messages.length > 0) {
                _ember.default.run.scheduleOnce('afterRender', () => {
                    const $target = this.$(`#${messageId}`);
                    const targetHeight = $target.position().top + $messagePane.scrollTop();
                    $messagePane.animate({ scrollTop: targetHeight + 'px' });

                    const loadingDividerClass = 'loading-divider';
                    $target.addClass(loadingDividerClass);
                    _ember.default.run.later(() => {
                        _ember.default.run.scheduleOnce('afterRender', () => {
                            $target.removeClass(loadingDividerClass);
                        });
                    }, 5000);
                });
            }
        },

        _loadHistoryBefore(room) {
            return this.get('chat').loadMoreRoomHistory(room);
        },

        checkScrolledToBottom() {
            _ember.default.run(() => {
                const $messagePane = this.$('.message-pane');
                if (!$messagePane) {
                    return;
                }
                const $newMessageView = this.$('.unread-banner');
                const hasMessageViews = $newMessageView && $newMessageView.length > 0;
                const scroll = $messagePane.prop('scrollHeight') - $messagePane.scrollTop();
                const outer = $messagePane.outerHeight();
                const isScrollUnderOuter = scroll <= outer + 5;
                if (!this.get('isAtBottom') && hasMessageViews && isScrollUnderOuter) {
                    _ember.default.run.later(() => {
                        this.get('chat').clearRoomUnreadMessages(this.get('activeRoom'));
                    }, 1000);
                }
                this.set('isAtBottom', isScrollUnderOuter);
            });
        },

        safeScroll() {
            _ember.default.run(() => {
                if (this.get('isAtBottom')) {
                    this._scrollToBottom();
                }
            });
        },

        forceScroll() {
            this._scrollToBottom();
        },

        _scrollToBottom() {
            const $messagePane = this.$('.message-pane');
            const $newMessageView = this.$('.unread-banner');
            if (!($messagePane && $newMessageView)) {
                _ember.default.Logger.debug('_scrollToBottom called before DOM insertion');
                return;
            }

            let scrollPosition;
            if ($newMessageView && $newMessageView.length > 0) {
                scrollPosition = $newMessageView.position().top + $messagePane.scrollTop();
            } else {
                scrollPosition = $messagePane.prop('scrollHeight') + 1000;
            }

            if ($messagePane && $messagePane.length > 0) {
                $messagePane.scrollTop(scrollPosition);
                this.checkScrolledToBottom();
            }
        }
    });

    exports.default = ConversationPaneComponent;
});