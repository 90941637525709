define("ember-purecloud-components/utils/markdown", ["exports", "highlight.js/lib/highlight.js", "markdown-it", "highlight.js/lib/languages/bash", "highlight.js/lib/languages/clojure", "highlight.js/lib/languages/coffeescript", "highlight.js/lib/languages/cs", "highlight.js/lib/languages/css", "highlight.js/lib/languages/elm", "highlight.js/lib/languages/go", "highlight.js/lib/languages/xml", "highlight.js/lib/languages/handlebars", "highlight.js/lib/languages/java", "highlight.js/lib/languages/javascript", "highlight.js/lib/languages/json", "highlight.js/lib/languages/python", "highlight.js/lib/languages/sql", "highlight.js/lib/languages/swift", "highlight.js/lib/languages/typescript"], function (_exports, _highlight, _markdownIt, _bash, _clojure, _coffeescript, _cs, _css, _elm, _go, _xml, _handlebars, _java, _javascript, _json, _python, _sql, _swift, _typescript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initializeMarkdownRendering = initializeMarkdownRendering;
  _exports.markdownToHtml = markdownToHtml;
  _exports.md = void 0;

  _highlight.default.registerLanguage('bash', _bash.default);

  _highlight.default.registerLanguage('clojure', _clojure.default);

  _highlight.default.registerLanguage('coffeescript', _coffeescript.default);

  _highlight.default.registerLanguage('cs', _cs.default);

  _highlight.default.registerLanguage('css', _css.default);

  _highlight.default.registerLanguage('elm', _elm.default);

  _highlight.default.registerLanguage('go', _go.default);

  _highlight.default.registerLanguage('xml', _xml.default);

  _highlight.default.registerLanguage('handlebars', _handlebars.default);

  _highlight.default.registerLanguage('java', _java.default);

  _highlight.default.registerLanguage('javascript', _javascript.default);

  _highlight.default.registerLanguage('json', _json.default);

  _highlight.default.registerLanguage('python', _python.default);

  _highlight.default.registerLanguage('sql', _sql.default);

  _highlight.default.registerLanguage('swift', _swift.default);

  _highlight.default.registerLanguage('typescript', _typescript.default);

  _highlight.default.configure({
    languages: ['bash', 'clojure', 'coffeescript', 'cs', 'css', 'elm', 'go', 'xml', 'handlebars', 'java', 'javascript', 'json', 'python', 'sql', 'swift', 'typescript']
  });

  var md = (0, _markdownIt.default)({
    html: false,
    linkify: true,
    typographer: false,
    highlight: function highlight(code, lang) {
      if (lang === 'no-highlight') {
        return Ember.Handlebars.Utils.escapeExpression(code);
      } else if (lang) {
        try {
          return _highlight.default.highlight(lang, code).value;
        } catch (e) {
          return Ember.Handlebars.Utils.escapeExpression(code);
        }
      } else {
        return _highlight.default.highlightAuto(code).value;
      }
    }
  });
  _exports.md = md;

  function initializeMarkdownRendering(isApplicationRoute) {
    var defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      var token = tokens[idx];
      var targetIndex = token.attrIndex('href');

      if (targetIndex !== -1) {
        var href = token.attrGet('href');

        if (!isApplicationRoute(href)) {
          tokens[idx].attrPush(['target', '_blank']);
          tokens[idx].attrPush(['rel', 'noopener noreferrer']);
          var pattern = /:\/\//i;

          if (!pattern.test(href)) {
            token.attrSet('href', "http://".concat(href));
          }
        }
      } // pass token to default renderer.


      return defaultRender(tokens, idx, options, env, self);
    };
  }

  function markdownToHtml(markdown) {
    return markdown ? md.render(markdown) : '';
  }
});