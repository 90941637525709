define("ember-purecloud-components/utils/conversation-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONVERSATION_STATES = void 0;
  var CONVERSATION_STATES = {
    ALERTING: 'alerting',
    CONNECTED: 'connected',
    CONNECTING: 'connecting',
    DIALING: 'dialing',
    DISCONNECTED: 'disconnected',
    TERMINATED: 'terminated'
  };
  _exports.CONVERSATION_STATES = CONVERSATION_STATES;
});