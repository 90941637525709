define("ember-purecloud-components/components/font-icon/component", ["exports", "ember-purecloud-components/components/font-icon/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'i',
    classNameBindings: ['actionName:clickable:'],
    intl: Ember.inject.service(),
    // Typically an i18n key or a Handlebars.SafeString which is already translated.
    title: null,
    placement: 'bottom',
    actionName: null,
    actions: {
      click: function click() {
        var actionName = this.get('actionName');

        if (actionName) {
          this.triggerAction({
            action: actionName
          });
        }
      }
    },
    _tooltip: Ember.computed('title', function () {
      var intl = this.get('intl');
      var title = this.get('title');

      if (!title) {
        return title;
      } // If title is already a SafeString, assume the property is already translated.
      // If translation does not exist, just use the raw string


      if (Ember.String.isHTMLSafe(title) || !intl.exists(title)) {
        return title.toString();
      }

      return intl.t(title);
    }),
    click: function click() {
      this.send('click');
    }
  });

  _exports.default = _default;
});