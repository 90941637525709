define('fixtable-ember/components/fixtable-footer', ['exports', 'fixtable-ember/templates/components/fixtable-footer'], function (exports, _fixtableFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fixtableFooter.default,
    currentPage: null,
    totalPages: null,
    pageSize: null,
    pageSizeOptions: null,

    actions: {
      goToPreviousPage: function goToPreviousPage() {
        this.goToPreviousPage();
      },
      goToNextPage: function goToNextPage() {
        this.goToNextPage();
      }
    }
  });
});