define('ember-custom-fields-components/models/enum', ['exports', 'ember-data/attr', 'ember-custom-fields-components/models/field', 'ember-custom-fields-components/utils/field-util'], function (exports, _attr, _field, _fieldUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Enum = _field.default.extend({
        enumList: (0, _attr.default)(),

        isEnumListLengthValid: Ember.computed.gt('enumList.length', 0),

        isEnumListValid: Ember.computed('enumList.@each.{key,title}', function () {
            var enumList = this.get('enumList') || [];
            var keys = {};
            return enumList && !enumList.any(function (item) {
                var key = Ember.get(item, 'key') || '';
                var title = Ember.get(item, 'title') || '';
                if (keys[key]) {
                    return true;
                } else {
                    keys[key] = true;
                    return _fieldUtil.IS_NOT_VALID_KEY.test(key) || !key.length || !title.length;
                }
            });
        }),

        isConfigValid: Ember.computed('isTitleValid', 'isKeyValid', 'isEnumListValid', 'isEnumListLengthValid', function () {
            return this.get('isTitleValid') && this.get('isKeyValid') && this.get('isEnumListValid') && this.get('isEnumListLengthValid');
        })
    });

    exports.default = Enum;
});