define("ember-webrtc-components/utils/webrtc-conversation-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var webrtcConversationTypes = {
    SOFTPHONE: 'softphone',
    ENGAGE_SCREENSHARE: 'engage-screenshare',
    ENGAGE_VIDEO: 'engage-video',
    ENGAGE_VR: 'engage-vr',
    COLLABORATE_VIDEO: 'collaborate-video',
    ENGAGE_SCREEN_RECORDING: 'engage-screen-recording'
  };
  var _default = webrtcConversationTypes;
  _exports.default = _default;
});