define("ember-purecloud-components/utils/pigeon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPigeonMaxPriority = getPigeonMaxPriority;
  _exports.topicPriority = _exports.newTopicPriority = void 0;
  var PRIORITY_MAXIMIZER = 10;
  var topicPriority = {
    presence: 1,
    routingStatus: 2,
    conversationsummary: 3,
    other: 4,
    outofoffice: 5,
    geolocation: 6
  };
  _exports.topicPriority = topicPriority;
  var newTopicPriority = {
    presence: 30,
    routingStatus: 25,
    conversationsummary: 20,
    other: 15,
    outofoffice: 10,
    geolocation: 5
  };
  _exports.newTopicPriority = newTopicPriority;

  function getPigeonMaxPriority() {
    var priorities = Object.values(newTopicPriority); // Add 10 to give priority topics the max priority

    return Math.max.apply(null, priorities) + PRIORITY_MAXIMIZER;
  }
});