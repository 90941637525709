define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/pivotal-tracker/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/pivotal-tracker/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const I18N_BASE = 'chat.pivotaltracker.';
    const STORY_UPDATE = 'story_update_activity';
    const STORY_DELETE = 'story_delete_activity';
    const STORY_CREATE = 'story_create_activity';

    const PivotalTrackerWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-pivotal-tracker'],
        layout: _template.default,

        expanded: false,

        webhooksHeader: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            let key;
            if (widget.notificationType === STORY_CREATE) {
                key = 'storyCreated';
            } else if (widget.notificationType === STORY_UPDATE) {
                key = 'storyUpdated';
            } else if (widget.notificationType === STORY_DELETE) {
                key = 'storyDeleted';
            }
            return this.get('intl').t(I18N_BASE + key, {
                project: widget.projectName,
                name: widget.performedBy.name
            });
        }),

        actions: {
            toggleExpanded() {
                this.toggleProperty('expanded');
            }
        }
    });

    exports.default = PivotalTrackerWidget;
});