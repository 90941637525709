define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/multimedia-request/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/multimedia-request/template'], function (exports, _ember, _baseWidgetComponent, _template) {
    'use strict';

    exports.__esModule = true;


    const { computed, inject } = _ember.default;

    exports.default = _baseWidgetComponent.default.extend({
        classNames: ['widgets-multimedia-request'],
        classNameBindings: ['customerHasDeclinedSessionShare'],
        layout: _template.default,

        chat: inject.service(),
        interaction: inject.service(),

        customerHasDeclinedSessionShare: computed.bool('message.widget.data.declinedSessionShare'),
        customerHasCanceledSessionShare: computed.bool('message.widget.data.sessionShareCanceled'),
        customerHadErrorInSessionShare: computed.bool('message.widget.data.sessionShareError'),
        isMessageHistory: computed.bool('message.history'),

        didReceiveAttrs() {
            this._super(...arguments);

            let seenMessages = this.get('interaction.selectedInteraction.seenMessages') || [];
            let oid = this.get('message.oid') || this.get('message.id');

            let hasSeen = seenMessages.includes(oid);

            if (this.get('isMessageHistory') || hasSeen) {
                return;
            }

            const interactionId = this.get('interaction.selectedInteraction.id');
            const data = this.get('message.widget.data');
            if (this.get('customerHasDeclinedSessionShare')) {
                this.get('chat').trigger('customerHasDeclinedSessionShare', interactionId, data);
            } else if (this.get('customerHasCanceledSessionShare')) {
                this.get('chat').trigger('customerHasCanceledSessionShare', interactionId, data);
            } else if (this.get('customerHadErrorInSessionShare')) {
                this.get('chat').trigger('customerHadErrorInSessionShare', interactionId, data);
            }

            seenMessages.push(oid);

            this.set('interaction.selectedInteraction.seenMessages', seenMessages);
        }
    });
});