define('ember-engine-custom-fields/utils/presence', ['exports', 'ember-purecloud-components/utils/presence'], function (exports, _presence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.keys(_presence).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _presence[key];
      }
    });
  });
});