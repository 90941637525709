define('ember-chat-components/components/chat-entity-search/component', ['exports', 'ember', 'ember-chat-components/components/chat-entity-search/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { run, assert, inject, computed, Component, Handlebars } = _ember.default;

    const ENTITY_TYPE_CHOICES = ['all', 'person', 'group'];

    exports.default = Component.extend({
        layout: _template.default,
        classNames: ['chat-entity-search'],
        classNameBindings: ['entityType'],

        intl: inject.service(),
        search: inject.service(),
        session: inject.service(),
        notification: inject.service(),
        logger: inject.service('stash-logger'),

        entityType: null,
        placeholder: null,

        multiSelect: false,
        participants: null,
        select: null,

        _inputFocused: false,

        actions: {
            performSearch(event) {
                this.set('searchQuery', event.target.value);

                if (this.get('currentRequest') && event.target.value.length < 2) {
                    this.set('currentRequest', null);
                    this.set('searchResults', null);
                }

                run.debounce(this, this.performSearch, event.target.value, 250);
            },

            submitSearch() {
                const entities = this.get('wrappedSearchResults');
                if (entities.length === 1) {
                    const chosen = entities[0];
                    if (!chosen.invited) {
                        this.send('click', chosen);
                    }
                }
            },

            click(wrappedEntity) {
                if (this.get('multiSelect')) {
                    this.get('participants').addObject(wrappedEntity.entity);
                    this.get('select')(wrappedEntity.entity);
                    this.send('clearSearch');
                } else {
                    if (this.get('entityType') === 'group' && wrappedEntity.entity.setIdForGroupEntity) {
                        // need to double check if group has _id set
                        wrappedEntity.entity.setIdForGroupEntity();
                    }
                    if (wrappedEntity.entity.get('_id')) {
                        this.openRoom(wrappedEntity.entity.get('jid'));
                    } else {
                        // in the event the _id is undefined, we can at least alert the user
                        const title = this.get('intl').t('groups.modal.missingJid.title');
                        const message = this.get('intl').t('groups.modal.missingJid.message');
                        this.get('notification').error(title, message, {
                            target: this,
                            hideAfter: 10
                        });
                        this.get('logger').warn('<chat/chat-entity-search> official group does not have a _id');
                    }
                }
            },

            toggleFocus(value) {
                this.set('_inputFocused', value);
            },

            clearSearch() {
                this.$('input').val('');
                this.set('searchQuery', '');
                this.set('currentRequest', null);
                this.set('searchResults', null);
            }
        },

        init() {
            this._super(...arguments);

            assert('`entityType` must be one of "all", "group", or "person"', ENTITY_TYPE_CHOICES.includes(this.get('entityType')));
            assert('When using multi-select, be sure to pass `select` action function', this.get('multiSelect') ? typeof this.get('select') === 'function' : true);

            this.set('participants', []);
        },

        isResults: computed('searchQuery', function () {
            return !!this.get('searchQuery');
        }),

        safeSearchQuery: computed('searchQuery', function () {
            return Handlebars.Utils.escapeExpression(this.get('searchQuery'));
        }),

        wrappedSearchResults: computed('searchResults.[]', 'session.person.id', 'participants', function () {
            const results = this.get('searchResults');

            if (!results) {
                return [];
            }

            return results.map(result => {
                const isMe = result.get('id') === this.get('session.person.id');
                const selected = this.get('participants') && this.get('participants').includes(result);

                return {
                    selected,
                    entity: result,
                    invited: isMe
                };
            });
        }),

        searchType: computed('entityType', function () {
            return {
                person: 'users',
                group: 'groups',
                all: 'all'
            }[this.get('entityType')];
        }),

        performSearch(query) {
            if (query.length < 2) {
                return;
            }

            const promise = this.get('search').performSearch(query, 0, this.get('searchType'), [], ['name'], 'QUERY_STRING').then(({ results }) => {
                const currentUser = this.get('session.person');
                this.set('searchResults', results.filter(result => {
                    return result.get('id') !== currentUser.get('id');
                }));
            });

            this.set('currentRequest', promise);

            return promise;
        },

        openRoom(jid) {
            this.bubbleAction({
                action: 'openChatRoom',
                actionContext: [jid]
            });
            this.bubbleAction({
                action: 'closeModal'
            });
        }
    });
});