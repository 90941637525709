define("ember-purecloud-components/utils/presence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.systemPresences = _exports.normalizePresence = _exports.isPresenceEqual = void 0;
  // See /api/v2/systempresences
  var systemPresences = {
    // Green
    AVAILABLE: 'AVAILABLE',
    // Orange
    AWAY: 'AWAY',
    BREAK: 'BREAK',
    MEAL: 'MEAL',
    TRAINING: 'TRAINING',
    IDLE: 'IDLE',
    // Red
    BUSY: 'BUSY',
    MEETING: 'MEETING',
    // Gray
    OFFLINE: 'OFFLINE',
    // Blue
    ON_QUEUE: 'ON_QUEUE'
  };
  /**
    @desc Normalizes a presence string by replacing hyphens and whitespace with underscores and converting to upper case.
    @param {string} s - Presence string
    @returns {string} Normalized presence string, or the unchanged passed value if it's not a string
  */

  _exports.systemPresences = systemPresences;

  var normalizePresence = function normalizePresence(s) {
    return typeof s === 'string' ? s.toUpperCase().replace(/-|\s/g, '_') : s;
  };
  /**
    @desc Compares two presence values.
    @param {string} left - First presence string.
    @param {string} right - Second presence string.
    @returns {Boolean} True if the presences are both truthy and equivalent, otherwise false.
  */


  _exports.normalizePresence = normalizePresence;

  var isPresenceEqual = function isPresenceEqual(left, right) {
    return left && right && normalizePresence(left) === normalizePresence(right);
  };

  _exports.isPresenceEqual = isPresenceEqual;
});