define('fixtable-ember/components/fixtable-row-toggle', ['exports', 'fixtable-ember/templates/components/fixtable-row-toggle'], function (exports, _fixtableRowToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fixtableRowToggle.default,
    classNames: ['fixtable-row-toggle'],
    click: function click() {
      this.toggleProperty('rowExpanded');
    }
  });
});