define("ember-purecloud-components/components/entity-v2/presence-label/component", ["exports", "ember-purecloud-components/components/entity-v2/presence-label/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2-presence-label'],
    classNameBindings: ['wrap', 'isChatEnabled::hidden'],
    intl: Ember.inject.service(),
    wrap: false,
    showStatus: true,
    showActivity: true,
    isChatEnabled: false,
    entity: null,
    presenceClass: Ember.computed('entity.outOfOffice.active', 'entity.presence.presenceDefinition.systemPresence', function () {
      var presence = this.getWithDefault('entity.presence.presenceDefinition.systemPresence', 'offline');
      var oooActive = this.get('entity.outOfOffice.active');
      return oooActive ? 'out_of_office' : presence && Ember.String.underscore(presence.toLowerCase());
    }),
    presenceLabel: Ember.computed('entity.outOfOffice.active', 'entity.presence.presenceDefinition.systemPresence', function () {
      var presence = this.getWithDefault('entity.presence.presenceDefinition.systemPresence', 'offline');
      var oooActive = this.get('entity.outOfOffice.active');
      return oooActive ? this.get('intl').t('chat.presence.outOfOffice.title') : this.get('intl').t("chat.presence.".concat(Ember.String.camelize(presence.toLowerCase())));
    })
  });

  _exports.default = _default;
});