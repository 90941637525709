define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/template', 'ember-purecloud-components/utils/dates', 'ember-purecloud-components/services/streaming'], function (exports, _ember, _template, _dates, _streaming) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Component.extend({
        classNames: ['chat-messages'],
        layout: _template.default,

        chat: _ember.default.inject.service(),
        streaming: _ember.default.inject.service(),
        logger: _ember.default.inject.service('stash-logger'),
        permissions: _ember.default.inject.service('permissions'),
        session: _ember.default.inject.service(),
        room: null,
        isMediaCollapsed: null,

        isEditing: _ember.default.computed.reads('chat.isEditing'),

        canViewVideo: _ember.default.computed.reads('permissions.canViewCollaborateVideo'),

        actions: {
            replyToMessage(message) {
                this.sendAction('replyToMessage', message);
            },

            clearUnreadMessages() {
                const chat = this.get('chat');
                chat.refocusActiveRoom();
                chat.clearRoomUnreadMessages(this.get('room'));
            }
        },

        init() {
            this._super(...arguments);

            this.get('streaming').subscribeToConnection(this);
        },

        didRender() {
            this._super(...arguments);

            this.sendAction('safeScroll');
            this.set('rendered', true);
        },

        willDestroyElement() {
            this.set('rendered', false);
            this.get('streaming').unsubscribeFromConnection(this);

            this._super(...arguments);
        },

        connect: _streaming.default.streamingEvent(function () {
            if (!this.get('rendered') || this.isDestroyed || this.isDestroying) {
                return;
            }

            const regex = /^[\d]{1,2}:[\d]{1,2}(:?\s[PAM]+)?$/i;
            const currentDate = (0, _dates.default)();
            const messages = this.get('room.messages');

            if (!messages || messages.length === 0) {
                return;
            }

            const logger = this.get('logger');

            _ember.default.run.join(() => {
                try {
                    logger.info('Attempting to recalculate message times on connect');
                    messages.filter(message => {
                        const matches = regex.test(message.get('messageTime'));
                        const isNotToday = !message.get('time').isSame(currentDate, 'day');
                        return matches && isNotToday;
                    }).forEach(message => message.notifyPropertyChange('time'));
                } catch (error) {
                    logger.error('An error recalculating message times on connect', {
                        error
                    });
                }
            });
        })
    });
});