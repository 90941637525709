define("ember-webrtc-components/mixins/webrtc-service/mms-probe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      observer = Ember.observer,
      RSVP = Ember.RSVP;
  var INITIAIL_WAIT_TIME = 60000;
  var PROBE_TIMEOUT = 10000;
  var overrideMap = {
    localhost: 'use1.dev-pure.cloud',
    'inindca.com': 'use1.dev-pure.cloud',
    'inintca.com': 'use1.test-pure.cloud',
    'mypurecloud.com': 'use1.pure.cloud',
    'mypurecloud.com.au': 'apse2.pure.cloud',
    'mypurecloud.jp': 'apne1.pure.cloud',
    'mypurecloud.ie': 'euw1.pure.cloud',
    'mypurecloud.de': 'euc1.pure.cloud'
  };
  var ipAddressesByDns = {
    'use1.dev-pure.cloud': ['52.0.109.55', '18.235.150.255', '18.210.78.136'],
    'use1.test-pure.cloud': ['52.129.97.253', '52.129.97.254', '52.129.97.255'],
    'use1.pure.cloud': ['52.129.99.253', '52.129.99.254', '52.129.99.255'],
    'usw2.pure.cloud': ['52.129.100.253', '52.129.100.254', '52.129.100.255'],
    'aps1.pure.cloud': ['52.129.102.253', '52.129.102.254', '52.129.102.255'],
    'apne1.pure.cloud': ['52.129.101.253', '52.129.101.254', '52.129.101.255'],
    'apne2.pure.cloud': ['52.129.107.255', '52.129.107.254', '52.129.107.253'],
    'apse2.pure.cloud': ['52.129.103.253', '52.129.103.254', '52.129.103.255'],
    'cac1.pure.cloud': ['52.129.104.253', '52.129.104.254', '52.129.104.255'],
    'euc1.pure.cloud': ['52.129.106.253', '52.129.106.254', '52.129.106.255'],
    'euw1.pure.cloud': ['52.129.108.253', '52.129.108.254', '52.129.108.255'],
    'euw2.pure.cloud': ['52.129.109.253', '52.129.109.254', '52.129.109.255'],
    'sae1.pure.cloud': ['52.129.105.253', '52.129.105.254', '52.129.105.255'],
    'use2.us-gov-pure.cloud': ['164.152.64.253', '164.152.64.254', '164.152.64.255']
  };

  function getRandomArrayElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  var _default = Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.probeDns();
    },
    // eslint-disable-next-line ember/no-observers
    _waitForFeatureToggle: observer('session.features.webrtcMMSDnsCheck', function () {
      var _this = this;

      // wait an amount of time for the app to bootstrap
      // i know this is ugly though
      setTimeout(function () {
        _this.probeDns();
      }, INITIAIL_WAIT_TIME);
    }),
    probeDns: function probeDns() {
      var _this2 = this;

      if (!this.get('session.features.webrtcMMSDnsCheck') || this.isDestroyed || this.isDestroying) {
        return;
      }

      var currentEnv = this.get('application.envDetails.envTld');
      var dnsHost = overrideMap[currentEnv] || currentEnv;
      var host = "netdiag.".concat(dnsHost);
      this.logger.debug('MMS DNS probe starting', {
        host: host
      });
      this.check(host).then(function () {
        return _this2.logger.info('MMS DNS probe success');
      }).catch(function (error) {
        var ipaddress = getRandomArrayElement(ipAddressesByDns[dnsHost]);

        _this2.logger.warn('MMS DNS probe failed, attempting MMS probe with ip address', {
          host: host,
          reason: error.message
        });

        return _this2.check(ipaddress).then(function () {
          return _this2.logger.info('MMS probe with ip success', {
            remoteData: {
              ipaddress: ipaddress
            }
          });
        }).catch(function (err) {
          return _this2.logger.error('MMS probe with ip address failed', {
            remoteData: {
              ipaddress: ipaddress,
              reason: err.message
            }
          });
        });
      });
    },
    // default port is the turn port
    check: function check(host) {
      var port = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3478;
      var pc;
      return new RSVP.Promise(function (resolve, reject) {
        pc = new RTCPeerConnection({
          iceServers: [{
            urls: "stun:".concat(host, ":").concat(port)
          }]
        });
        pc.createDataChannel('');
        pc.createOffer().then(function (offer) {
          return pc.setLocalDescription(offer);
        });

        pc.onicecandidate = function (ice) {
          if (!ice || !ice.candidate || !ice.candidate.candidate) {
            return;
          }

          if (ice.candidate.type !== 'host') {
            resolve();
          }
        };

        setTimeout(function () {
          return reject({
            message: 'Probe timeout '
          });
        }, PROBE_TIMEOUT);
      }).finally(function () {
        pc && pc.close();
      });
    }
  });

  _exports.default = _default;
});