define('ember-chat-components/services/roster/roster', ['exports', 'ember', 'ember-chat-components/services/roster/badge-tracking'], function (exports, _ember, _badgeTracking) {
    'use strict';

    exports.__esModule = true;


    const { Service, inject, computed } = _ember.default;

    exports.default = Service.extend(_badgeTracking.default, {
        chat: inject.service(),

        favorites: null,
        people: null,
        groups: null,
        adhocs: null,
        closed: null,

        init() {
            this._super(arguments);
            this.setProperties({
                favorites: _ember.default.A(),
                people: _ember.default.A(),
                groups: _ember.default.A(),
                adhocs: _ember.default.A(),
                closed: _ember.default.A()
            });
        },

        allRoomsInRoster: computed.union('favorites', 'people', 'groups', 'adhocs', 'closed'),

        /**
         * Computed array of all the rooms in the roster with mentions or unseens.
         * The results are chunked by "priority": [person unseens, group mentions, group unseens]
         * Within those chunks, favorited rooms occur before non-favorited rooms.
         */
        allRoomsWithMentionsOrUnseen: computed('allRoomsInRoster.@each.{unseen,mentions}', function () {
            const personUnseens = [];
            const groupMentions = [];
            const groupUnseens = [];
            this.get('allRoomsInRoster').forEach(room => {
                const roomHasMentions = room.get('mentions') > 0;
                const roomHasUnseens = room.get('unseen') > 0;
                if (room.isOneToOneRoom()) {
                    if (roomHasMentions || roomHasUnseens) {
                        personUnseens.push(room);
                    }
                } else {
                    if (roomHasMentions) {
                        groupMentions.push(room);
                    } else if (roomHasUnseens) {
                        groupUnseens.push(room);
                    }
                }
            });
            return [...personUnseens, ...groupMentions, ...groupUnseens];
        }),

        unseenCount: computed('allRoomsInRoster.@each.unseen', function () {
            let unseen = 0;

            this.get('allRoomsInRoster').forEach(room => {
                if (room.get('isSupervisorRoom')) {
                    return;
                }

                if (!room.isOneToOneRoom()) {
                    unseen += room.get('unseen');
                }
            });

            return unseen;
        }),

        mentionsCount: computed('allRoomsInRoster.@each.{unseen,mentions}', function () {
            let mentions = 0;

            this.get('allRoomsInRoster').forEach(room => {
                if (room.get('isSupervisorRoom')) {
                    return;
                }

                if (!room.isOneToOneRoom()) {
                    mentions += room.get('mentions');
                } else {
                    mentions += room.get('unseen');
                }
            });

            return mentions;
        }),

        unseenPersonalMessages: computed('people.@each.unseen', function () {
            return this.get('people').mapBy('unseen').reduce((a, b) => a + b, 0);
        }),

        unseenGroupMessages: computed('groups.@each.unseen', function () {
            return this.get('groups').mapBy('unseen').reduce((a, b) => a + b, 0);
        }),

        unseenAdhocMessages: computed('adhocs.@each.unseen', function () {
            return this.get('adhocs').mapBy('unseen').reduce((a, b) => a + b, 0);
        }),

        unseenGroupFavoritesMessages: computed('favorites.@each.unseen', function () {
            const favoriteGroups = this.get('favorites').filter(room => !room.isOneToOneRoom());
            return _ember.default.A(favoriteGroups).mapBy('unseen').reduce((a, b) => a + b, 0);
        }),

        unseenPersonalFavoritesMessages: computed('favorites.@each.unseen', function () {
            const personalFavorites = this.get('favorites').filter(room => room.isOneToOneRoom());
            return _ember.default.A(personalFavorites).mapBy('unseen').reduce((a, b) => a + b, 0);
        }),

        allUnseenGroupMessages: computed('unseenGroupMessages', 'unseenAdhocMessages', 'unseenGroupFavoritesMessages', function () {
            return this.get('unseenGroupMessages') + this.get('unseenAdhocMessages') + this.get('unseenGroupFavoritesMessages');
        }),

        allUnseenDirectMessages: computed('unseenPersonalMessages', 'unseenPersonalFavoritesMessages', function () {
            return this.get('unseenPersonalMessages') + this.get('unseenPersonalFavoritesMessages');
        }),

        addRoomToFavorites(room) {
            var favorites, ref;
            if (room.get('isClosed') || !room.get('roster')) {
                return;
            }
            if ((ref = room.get('roster')) != null) {
                ref.removeObject(room);
            }
            favorites = this.get('favorites');
            favorites.addObject(room);
            return room.set('roster', favorites);
        },

        removeRoomFromFavorites(room) {
            if (room.get('isClosed') || !room.get('roster')) {
                return;
            }

            const groups = this.get('groups');
            const people = this.get('people');
            const adhocs = this.get('adhocs');
            const roster = room.get('roster');

            if (roster) {
                roster.removeObject(room);
            }

            if (room.get('isAdhocRoom') && !room.isGroupRoom()) {
                adhocs.addObject(room);
                room.set('roster', adhocs);
            } else if (room.isGroupRoom()) {
                groups.addObject(room);
                room.set('roster', groups);
            } else if (room.isOneToOneRoom()) {
                people.addObject(room);
                room.set('roster', people);
            }
        },

        addRoomToClosed(room) {
            room.set('isClosed', true);
            return this.get('closed').addObject(room);
        },

        removeRoomFromClosed(room) {
            if (room.get('isClosed')) {
                room.set('isClosed', false);
                return this.get('closed').removeObject(room);
            }
        },

        reestablishPresenceToStreamingService() {
            const activatedRooms = this.get('favorites').concat(this.get('people'), this.get('adhocs'), this.get('groups')).filter(room => room.get('activated'));
            const deferred = _ember.default.RSVP.defer();
            this.recursivelyReactivateRooms(_ember.default.A(activatedRooms), deferred);
            return deferred.promise;
        },

        recursivelyReactivateRooms(activatedRooms, deferred) {
            if (activatedRooms.length === 0) {
                return deferred.resolve();
            }
            const room = activatedRooms.pop();
            this.get('chat').joinRoom(room.get('jid'), true).then(() => {
                this.recursivelyReactivateRooms(activatedRooms, deferred);
            });
        },

        getNextRoomFromActiveSection() {
            const choice = this.getFirstNonDuplicateFromRoster(this.get('people')) || this.getFirstNonDuplicateFromRoster(this.get('groups')) || this.getFirstNonDuplicateFromRoster(this.get('adhocs'));

            return Promise.resolve(choice);
        },

        getFirstNonDuplicateFromRoster(roster) {
            if (roster.get('firstObject.jid') !== this.get('activeRoom.jid')) {
                return roster.get('firstObject');
            } else {
                return roster.objectAt(1);
            }
        }
    });
});