define('ember-chat-components/components/chat-panel/conversation/conversation-pane/history-bar/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/history-bar/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    var ChatHistoryBar = _ember.default.Component.extend({
        classNames: ['history-bar'],
        layout: _template.default,

        isForward: false,
        noMoreHistoryText: '',

        shouldShowSpinner: _ember.default.computed('activeRoom.{loadingHistory,messages.[]}', 'isForward', function () {
            //we are not going to initially show the spinner if we are a forward history bar to avoid double spinnas
            return this.get('activeRoom.loadingHistory') && (!this.get('isForward') || this.get('activeRoom.messages.length') !== 0);
        }),

        actions: {
            loadMoreHistory() {
                this.get('loadMoreHistory')();
            }
        }
    });

    exports.default = ChatHistoryBar;
});