define('ember-chat-components/components/chat-panel/roster/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/roster/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { Component, computed, observer, inject } = _ember.default;

    exports.default = Component.extend({
        classNames: ['chat-roster'],
        classNameBindings: ['displayDensity'],
        layout: _template.default,

        chat: inject.service(),
        roster: inject.service(),
        preferences: inject.service(),

        displayDensity: computed.reads('preferences.chat.global.displayDensity'),
        sortPref: computed.reads('preferences.chat.global.sortedAlpha'),

        activeTab: 'all',

        roomPositions: null,
        topScrollIndicator: 0,
        bottomScrollIndicator: 0,

        init() {
            this._super(...arguments);
            this.set('roomPositions', []);

            this.setProperties({
                closedRoomSortFields: ['roomClosedTimeRadix:desc'],
                sortRecentDesc: ['lastMessageTimeRadix:desc'],
                sortAlphaAsc: ['subject']
            });
        },

        didInsertElement() {
            this._super(...arguments);
            this.addScrollIndicatorListeners();
        },

        willDestroyElement() {
            this._super(...arguments);
            this.removeScrollIndicatorListeners();
        },

        showPeopleUnreadBadge: computed('activeTab', 'roster.allUnseenDirectMessages', function () {
            const activeTab = this.get('activeTab');

            return activeTab === 'groups' && this.get('roster.allUnseenDirectMessages') > 0;
        }),

        showGroupUnreadBadge: computed('activeTab', 'roster.allUnseenGroupMessages', function () {
            const activeTab = this.get('activeTab');

            return activeTab === 'people' && this.get('roster.allUnseenGroupMessages') > 0;
        }),

        closedRoomSortFields: null,
        sortRecentDesc: null,
        sortAlphaAsc: null,
        sortFields: computed('sortPref', function () {
            // Default sort is recent
            if (this.get('sortPref') === undefined) {
                return this.get('sortRecentDesc');
            }

            return this.get('sortPref') === 'recent' ? this.get('sortRecentDesc') : this.get('sortAlphaAsc');
        }),

        filteredFavorites: computed('roster.favorites.[]', 'activeTab', function () {
            const favorites = this.get('roster.favorites');
            const tab = this.get('activeTab');

            return favorites.filter(this.filterFromActiveTab(tab));
        }),

        filteredClosed: computed('roster.closed.[]', 'activeTab', function () {
            const closed = this.get('roster.closed');
            const tab = this.get('activeTab');

            return closed.filter(this.filterFromActiveTab(tab));
        }),

        favorites: computed.sort('filteredFavorites', 'sortFields'),
        people: computed.sort('roster.people', 'sortFields'),
        adhocs: computed.sort('roster.adhocs', 'sortFields'),
        groups: computed.sort('roster.groups', 'sortFields'),
        closed: computed.sort('filteredClosed', 'closedRoomSortFields'),

        // eslint-disable-next-line ember/no-observers
        checkScrollIndicators: observer('activeTab', 'favorites', 'people', 'adhocs', 'groups', 'closed', function () {
            this.setRoomEntryScrollIndicators();
        }).on('didInsertElement'),

        actions: {
            changeTab(tab) {
                this.set('activeTab', tab);
            },

            selectRoom(room) {
                if (room && !room.get('active')) {
                    this.get('chat').changeActiveRoom(room);
                }
            },

            closeRoom(room) {
                if (room) {
                    this.get('chat').closeRoomAndClearCounts(room);
                }
            },

            setRoomPosition(roomId, type, action) {
                if (action === 'remove' && this.get('roomPositions') && this.get('roomPositions').length > 0) {
                    var place = this.get('roomPositions').mapBy('id').indexOf(roomId);
                    if (place !== -1) {
                        this.get('roomPositions').removeAt(place);
                        this.setRoomEntryScrollIndicators();
                    }
                } else if (action !== 'remove' && !this.get('roomPositions').mapBy('id').includes(roomId)) {
                    this.get('roomPositions').push({ id: roomId, type: type });
                    this.setRoomEntryScrollIndicators();
                }
            }
        },

        addScrollIndicatorListeners() {
            var container = this.$('.scrollable-container');
            container.on('scroll', () => _ember.default.run(() => this.setRoomEntryScrollIndicators()));
            container.on('resize', () => _ember.default.run(() => this.setRoomEntryScrollIndicators()));
        },

        removeScrollIndicatorListeners() {
            const scrollableContainer = this.$('.scrollable-container');
            if (scrollableContainer) {
                scrollableContainer.off();
            }
        },

        setRoomEntryScrollIndicators() {
            _ember.default.run.debounce(this, this._scheduleRoomEntryScrollIndicators, 100);
        },

        _scheduleRoomEntryScrollIndicators() {
            _ember.default.run.scheduleOnce('afterRender', this, this._setRoomEntryScrollIndicators);
        },

        _setRoomEntryScrollIndicators() {
            //check each room, see if any fall above or below, set indicators appropriately
            //clear rooms currently in view
            const rooms = this.get('roomPositions');
            const top = 0;
            const bottom = this.$('.scrollable-container').height();
            let showTop = 0;
            let showBottom = 0;
            let removals = [];

            (rooms || []).forEach(room => {
                if (room) {
                    if (this.get('activeTab') === 'all' || this.get('activeTab') === room.type || room.type === 'all') {
                        let position = this.$(`#${room.id}`).position();

                        // removes 'duplicate' keys created by tab rerenders
                        if (position) {
                            position = position.top;
                        } else {
                            removals.push(room);
                        }

                        if (top <= position && position < bottom) {
                            removals.push(room);
                        } else if (position < top) {
                            showTop++;
                        } else if (bottom <= position) {
                            showBottom++;
                        }
                    }
                }
            });

            this.set('topScrollIndicator', showTop);
            this.set('bottomScrollIndicator', showBottom);

            this.get('roomPositions').removeObjects(removals);
        },

        filterFromActiveTab(tab) {
            return tab === 'all' ? () => true : tab === 'people' ? room => room.isOneToOneRoom() : room => !room.isOneToOneRoom();
        }
    });
});