define('ember-chat-components/components/chat-panel/conversation/mention-warning/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/mention-warning/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const MentionWarningComponent = _ember.default.Component.extend({
        classNames: ['mention-warning-area'],

        layout: _template.default,

        mentionsNotInChat: null,

        didInsertElement() {
            this._super(...arguments);

            // focus the modal
            _ember.default.run.scheduleOnce('afterRender', this, this.focusModal);
        },

        focusModal() {
            const mentionWarningDiv = this.$('.mention-warning');

            // focus the div to capture keydown
            mentionWarningDiv.trigger('focus');
        },

        keydown(evt) {
            // if escape is pressed, close the modal
            if (evt.type === 'keydown' && evt.code.toLowerCase() === 'escape') {
                this.send('closeModal');
            }
        },

        // eslint-disable-next-line ember/no-observers
        closeOnContextSwitch: _ember.default.observer('chatService.activeRoom', function () {
            this.send('closeModal');
        }),

        actions: {
            closeModal() {
                this.get('close')();
            },
            handleCancel() {
                // call the method passed in for cancel
                this.get('cancelMentionAdd')(this.get('mentionsNotInChat'));

                this.send('closeModal');
            },
            handleConfirm() {
                // call the method passed in for confirm
                this.get('confirmMentionAdd')();

                this.send('closeModal');
            }
        }
    });

    exports.default = MentionWarningComponent;
});