define("ember-purecloud-components/utils/display-geolocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = displayRelativeGeolocation;

  var displayGeolocation = function displayGeolocation(entity) {
    if (Ember.get(entity, 'geolocation')) {
      var city = Ember.get(entity, 'geolocation.city');
      var region = Ember.get(entity, 'geolocation.region');
      var country = Ember.get(entity, 'geolocation.country');

      if (city && country) {
        return "".concat(city, ", ").concat(country);
      } else if (city && region && city !== region) {
        return "".concat(city, ", ").concat(region);
      } else if (city && region) {
        return city;
      } else if (region && country) {
        return "".concat(region, ", ").concat(country);
      } else if (city) {
        return city;
      } else if (country) {
        return country;
      } else {
        return region;
      }
    } else {
      return '';
    }
  };

  var USA = 'United States';

  function displayRelativeGeolocation(entity, sourceEntity) {
    if (sourceEntity && Ember.get(sourceEntity, 'geolocation') && Ember.get(entity, 'geolocation')) {
      var sourceCountry = Ember.get(sourceEntity, 'geolocation.country');
      var city = Ember.get(entity, 'geolocation.city');
      var region = Ember.get(entity, 'geolocation.region');
      var country = Ember.get(entity, 'geolocation.country');

      if (sourceCountry === country && city && region && city !== region) {
        return "".concat(city, ", ").concat(region);
      } else if (sourceCountry === country && city && region && city === region) {
        return "".concat(city);
      } else if (country === USA && city && country && region) {
        return "".concat(city, ", ").concat(region, ", ").concat(country);
      }
    }

    return displayGeolocation(entity);
  }
});