define('ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/mixins/textarea-events', ['exports', 'ember', 'keystroke'], function (exports, _ember, _keystroke) {
    'use strict';

    exports.__esModule = true;
    exports.drop = drop;
    exports.keyup = keyup;
    exports.keydown = keydown;
    exports.paste = paste;
    exports.keypress = keypress;


    const { get, run } = _ember.default;

    function renameFile(file, imageName = 'pasted-image') {
        let fileName = file.name;
        let extension = '.png';
        try {
            if (file.type) {
                const filetype = file.type.toLowerCase();
                if (filetype.includes('gif')) {
                    extension = '.gif';
                } else if (filetype.includes('jpg') || filetype.includes('jpeg')) {
                    extension = '.jpg';
                }
            }
            fileName = `${imageName}${extension}`;
            file.name = fileName;
        } catch (e) {
            // can't write teh file name in some browsers, ignore
        }
        return fileName;
    }

    function drop() {
        const data = event.dataTransfer.getData('Text');
        if (data) {
            this._setTextareaValue(data);
        }
    }

    function keyup(event) {
        const textarea = this.get('_$textarea');
        const codeString = _keystroke.default.codeToString(event.keyCode);
        if (codeString === 'ESCAPE') {
            if (this.get('initialValue') || !textarea.val()) {
                this.sendAction('clearContext');
                this.get('timeline').markRead('room', this.get('activeRoom.objectId'));
            }
        } else if (this.get('chat.canCorrectMessages') && codeString === 'UP_ARROW' && !textarea.val()) {
            this.sendAction('requestEditPrevious');
            run.scheduleOnce('afterRender', () => {
                this.sendAction('scrollToBottom');
            });
        } else {
            run.throttle(this, this.propagateChatState, _ember.default.testing ? 200 : 5000);
        }
    }

    function keydown(event) {
        const usingGiphy = this.get('giphyQuery').length;
        const code = event.keyCode;
        const didTab = _keystroke.default.codeToString(code) === 'TAB';
        if (usingGiphy && didTab) {
            this.set('giphyFocus', true);
            event.preventDefault();
        }
        if (_keystroke.default.codeToString(code) === 'ENTER' && !event.shiftKey) {
            event.preventDefault();

            const uploadTarget = this.get('dropzoneTarget');
            const room = this.get('activeRoom');
            const dropzone = this.get('dropzone');

            let message = this.get('messageInput');
            message = this.redactCreditCard(message);

            if (!this.get('disableFileUploads') && dropzone && get(dropzone, 'files.length') > 0 && get(dropzone, 'files.0.status') === 'queued') {
                this.set('dropzoneMessage', message);

                dropzone.options.url = uploadTarget;
                dropzone.options.currentTarget = room;
                const file = get(dropzone, 'files')[0];
                dropzone.options.renameFilename = () => {
                    return renameFile(file, this.get('intl').t('chat.pastedImageFilename').toString());
                };
                dropzone.processQueue();

                this.removeThumbnail();
                this.setTextareaValue('');
                return;
            }

            if (this.get('buildingAutocomplete')) {
                this.set('buildingAutocomplete', false);
            } else if (!this.get('mentionsVisible')) {
                this.propagateMessageEvent((message || '').trim(), room);
            }
        }
    }

    function pasteDropzoneItem(item, dropzone, intl) {
        if (item.type.includes('image')) {
            const file = item.getAsFile();
            renameFile(file, intl.t('chat.pastedImageFilename').toString());
            dropzone.addFile(file);
        }
    }

    function paste(event) {
        const textarea = this.get('_$textarea');
        const dropzone = this.get('dropzone');
        const clipboardData = event.originalEvent.clipboardData;
        const disableUploads = this.get('disableFileUploads');
        const joinError = this.get('joinError');
        const startingText = textarea.val();
        if (clipboardData && clipboardData.items && !disableUploads && !joinError) {
            const items = clipboardData.items;

            if (items.length === 0) {
                return;
            }

            const pastedText = clipboardData.getData('text/plain');
            const haveFiles = clipboardData.types.includes('Files');

            // Might be dealing with Microsoft Office data, which includes HTML content on copy
            // And so does Chrome, so we can't rely on that check at the moment
            if (haveFiles) {
                if (items.length === 2) {
                    const image = Array.from(clipboardData.items).find(item => item.type.indexOf('image') > -1);
                    // Dealing with a situation where a browser added the text and image to the clipboard. We just
                    // want the image and discard the text
                    if (pastedText && image) {
                        pasteDropzoneItem(image, dropzone, this.get('intl'));
                        event.preventDefault();
                        return;
                    } else if (!pastedText && image) {
                        // Handle the origin image upload here
                        pasteDropzoneItem(image, dropzone, this.get('intl'));
                    }
                } else {
                    Array.from(items || []).forEach(item => {
                        pasteDropzoneItem(item, dropzone, this.get('intl'));
                    });
                }
            }

            if (!pastedText) {
                return;
            }

            const el = textarea[0];
            const selectionStart = el.selectionStart;
            const selectionEnd = el.selectionEnd;

            run.later(() => {
                // timeout causes code to run after text has been added to the textarea
                const charCount = pastedText.length;
                const lineCount = pastedText.split('\n').length;

                if (charCount > 500 || lineCount > 7) {
                    let replacement = pastedText;
                    const first = startingText.substring(0, selectionStart);
                    const rest = startingText.substring(selectionEnd, startingText.length);
                    const parts = [first, rest];

                    if (!startingText.includes('```')) {
                        replacement = '```no-highlight\n' + pastedText + '\n```\n';
                    } else if (!rest.includes('```')) {
                        replacement = pastedText + '\n```\n';
                    } else {
                        replacement = pastedText;
                    }

                    const finalMessage = parts.join(replacement);

                    this._setTextareaValue(finalMessage);
                    textarea.scrollTop(textarea[0].scrollHeight);
                }
            }, 10);
        }
    }

    function keypress() {}
});