define("@ember-decorators/utils/decorator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decorator = decorator;
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.decoratorWithRequiredParams = decoratorWithRequiredParams;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function isDescriptor(possibleDesc) {
    var isDescriptor = isStage2Descriptor(possibleDesc);

    if (false) {
      isDescriptor = isDescriptor || isStage1Descriptor(possibleDesc);
    }

    return isDescriptor;
  }

  function isStage1Descriptor(possibleDesc) {
    if (possibleDesc.length === 3) {
      var _possibleDesc = _slicedToArray(possibleDesc, 3),
          target = _possibleDesc[0],
          key = _possibleDesc[1],
          desc = _possibleDesc[2];

      return _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined // TS compatibility
      );
    } else if (possibleDesc.length === 1) {
      var _possibleDesc2 = _slicedToArray(possibleDesc, 1),
          _target = _possibleDesc2[0];

      return typeof _target === 'function' && 'prototype' in _target;
    }

    return false;
  }

  function isStage2Descriptor(possibleDesc) {
    return possibleDesc && possibleDesc.toString() === '[object Descriptor]';
  }

  function kindForDesc(desc) {
    if ('value' in desc && desc.enumerable === true) {
      return 'field';
    } else {
      return 'method';
    }
  }

  function placementForKind(kind) {
    return kind === 'method' ? 'prototype' : 'own';
  }

  function convertStage1ToStage2(desc) {
    if (desc.length === 3) {
      // Class element decorator
      var _desc = _slicedToArray(desc, 3),
          key = _desc[1],
          descriptor = _desc[2];

      var kind = kindForDesc(desc);
      var placement = placementForKind(kind);
      var initializer = descriptor !== undefined ? descriptor.initializer : undefined;
      return {
        descriptor: descriptor,
        key: key,
        kind: kind,
        placement: placement,
        initializer: initializer,
        toString: function toString() {
          return '[object Descriptor]';
        }
      };
    } else {
      // Class decorator
      return {
        kind: 'class',
        elements: []
      };
    }
  }

  function decorator(fn) {
    if (false) {
      return function () {
        for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
          params[_key] = arguments[_key];
        }

        if (isStage2Descriptor(params)) {
          var desc = params[0];
          return fn(desc);
        } else {
          var _desc2 = convertStage1ToStage2(params);

          fn(_desc2);

          if (typeof _desc2.finisher === 'function') {
            // Finishers are supposed to run at the end of class finalization,
            // but we don't get that with stage 1 transforms. We have to be careful
            // to make sure that we aren't doing any operations which would change
            // due to timing.
            var target = params[0];

            _desc2.finisher(target.prototype ? target : target.constructor);
          }

          if (typeof _desc2.initializer === 'function') {
            // Babel 6 / the legacy decorator transform needs the initializer back
            // on the property descriptor/ In case the user has set a new
            // initializer on the member descriptor, we transfer it back to
            // original descriptor.
            _desc2.descriptor.initializer = _desc2.initializer;
          }

          return _desc2.descriptor;
        }
      };
    } else {
      return fn;
    }
  }
  /**
   * A macro that takes a decorator function and allows it to optionally
   * receive parameters
   *
   * ```js
   * let foo = decoratorWithParams((target, desc, key, params) => {
   *   console.log(params);
   * });
   *
   * class {
   *   @foo bar; // undefined
   *   @foo('bar') baz; // ['bar']
   * }
   * ```
   *
   * @param {Function} fn - decorator function
   */


  function decoratorWithParams(fn) {
    return function () {
      for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        params[_key2] = arguments[_key2];
      }

      // determine if user called as @computed('blah', 'blah') or @computed
      if (isDescriptor(params)) {
        return decorator(fn).apply(void 0, params);
      } else {
        return decorator(function (desc) {
          return fn(desc, params);
        });
      }
    };
  }
  /**
   * A macro that takes a decorator function and requires it to receive
   * parameters:
   *
   * ```js
   * let foo = decoratorWithRequiredParams((target, desc, key, params) => {
   *   console.log(params);
   * });
   *
   * class {
   *   @foo('bar') baz; // ['bar']
   *   @foo bar; // Error
   * }
   * ```
   *
   * @param {Function} fn - decorator function
   */


  function decoratorWithRequiredParams(fn, name) {
    return function () {
      for (var _len3 = arguments.length, params = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        params[_key3] = arguments[_key3];
      }

      (false && !(!isDescriptor(params) && params.length > 0) && Ember.assert("The @".concat(name || fn.name, " decorator requires parameters"), !isDescriptor(params) && params.length > 0));
      return decorator(function (desc) {
        return fn(desc, params);
      });
    };
  }
});