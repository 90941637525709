define('ember-chat-components/components/chat-panel/conversation/conversation-pane/integration-banner/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/integration-banner/template', 'web-directory/mixins/entity-presence'], function (exports, _ember, _template, _entityPresence) {
    'use strict';

    exports.__esModule = true;


    var IntegrationBanner = _ember.default.Component.extend({
        layout: _template.default,
        // hide the banner using the '.hide' class if 'showBanner` computed property is false
        classNameBindings: ['showBanner::hide'],
        phoneIntegrations: _ember.default.inject.service(),
        session: _ember.default.inject.service(),
        intl: _ember.default.inject.service(),
        participants: _ember.default.computed.reads('activeRoom.sortedParticipants'),
        oneToOnePerson: _ember.default.computed.reads('activeRoom.oneToOneTarget'),
        isOneToOneRoom: _ember.default.computed.bool('activeRoom.oneToOneTarget'),

        /**
         * Should we display the integration banner message for a 1-to-1
         * chat room. Cross checks the user's phone integrations with the Organization's
         * integration list.
         */
        displayOneToOneIntegration: _ember.default.computed('oneToOnePerson', 'oneToOnePerson.activities.presence', 'isOneToOneRoom', 'phoneIntegrations.integrationConfigs', function () {
            const oneToOnePerson = this.get('oneToOnePerson');
            const isOneToOneRoom = this.get('isOneToOneRoom');
            const phoneIntegrations = this.get('phoneIntegrations');
            const intgs = [];

            if (isOneToOneRoom) {
                const integrationConfigs = phoneIntegrations.get('integrationConfigs');
                integrationConfigs.forEach(config => {
                    if (this.verifyGCOffline(oneToOnePerson, config)) {
                        intgs.push(config);
                    }
                });
            }

            return intgs.length > 0;
        }),

        /**
         * Should we display the integration banner message for a group
         * chat room. Cross checks each participant's phone integrations with the Organization's
         * integration list. If any participant is offline in GC and they have an integration,
         * show the integration banner for the group.
         */
        groupRoomWithInactiveParticipants: _ember.default.computed('session.person', 'participants', 'activeRoom', 'phoneIntegrations.integrationConfigs', function () {
            const participants = this.get('participants');
            const inactiveParticipants = [];
            const activeRoom = this.get('activeRoom');

            if (participants && (activeRoom.get('isAdhocRoom') || activeRoom.isGroupRoom())) {
                const sessionPerson = this.get('session.person');
                const phoneIntegrations = this.get('phoneIntegrations');
                const integrationConfigs = phoneIntegrations.get('integrationConfigs');

                integrationConfigs.forEach(config => {
                    participants.forEach(participant => {
                        if (sessionPerson.id !== participant.id && this.verifyGCOffline(participant, config)) {
                            inactiveParticipants.push(participant);
                        }
                    });
                });

                return inactiveParticipants.length > 0;
            }
            return false;
        }),
        showBanner: _ember.default.computed.or('groupRoomWithInactiveParticipants', 'displayOneToOneIntegration'),
        oneToOneDisplayMessage: _ember.default.computed('activeRoom', 'activeRoom.subject', function () {
            const name = this.get('activeRoom.subject');
            const intl = this.get('intl');
            return intl.lookup('chat.integrationBanner.single.offlineNotice', { name });
        }),
        activeRoom: null,
        actions: {
            toggleParticipants() {
                this.sendAction('toggleParticipants');
            }
        },
        /**
         * Verify if user is offline in Genesys Cloud and the user has an integration.
         * @param {Person} user Genesys Cloud User from Person model
         * @param {object} integrationConfig integration configuration
         * @returns boolean
         */
        verifyGCOffline(user, config) {
            const presenceDefinition = user.get('activities.presence.presenceDefinition');
            return !!user.integrationContactInfo(config.key) && (0, _entityPresence.isPresence)(this.get('session.presenceMap'), presenceDefinition, 'offline');
        }
    });

    exports.default = IntegrationBanner;
});