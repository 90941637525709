define('ember-chat-components/components/chat-panel/conversation/component', ['exports', 'ember', 'ember-purecloud-components/utils/jid-helpers', 'web-directory/utils/dates', 'jquery', 'ember-chat-components/components/chat-panel/conversation/template', 'web-directory/services/media-providers'], function (exports, _ember, _jidHelpers, _dates, _jquery, _template, _mediaProviders) {
    'use strict';

    exports.__esModule = true;


    const { inject, Component, computed, observer, run } = _ember.default;
    /*
     * We shouldn't import something from web-dir in an "addon". But, an "addon" comp
     * shouldn't have implicit deps on services either.
     *
     * Since the isolation is already jacked and the component is on it's way back into web-dir proper anyway,
     * going ahead and importing here.
     */


    const ParticipantSearch = _ember.default.Object.extend({
        search: _ember.default.inject.service(),
        performParticipantSearch(query) {
            return this.get('search').performSearch(query, 0, 'users').then(({ results }) => {
                return results;
            });
        }
    });

    const SearchResultMeta = _ember.default.Object.extend({
        searchValue: '',
        total: 0,
        notEnoughCharacters: false
    });

    const CHAT_SEARCH_PAGE_SIZE = 25;

    const ChatConversationComponent = Component.extend({
        classNames: ['right-chat-rail'],
        classNameBindings: ['popoutClassNames'],
        layout: _template.default,

        chat: inject.service(),
        webrtc: inject.service(),
        session: inject.service(),
        sidebar: inject.service(),
        fullscreen: inject.service(),
        permissions: inject.service(),
        mediaProviders: inject.service(),
        presenceSubscription: inject.service(),

        searchResultContextMessages: computed.alias('activeRoom.messages'),
        canViewChat: computed.reads('permissions.canViewBusinessChat'),
        canViewVideo: computed.reads('permissions.canViewCollaborateVideo'),
        mentionWarningVisible: false,
        mentionWarningOpts: null,
        acdToggle: false,
        activeRoom: null,
        searchResultContextVisible: false,
        disableFileUploads: false,
        disableTextArea: false,

        isSearchSelected: false,
        isParticipantsSelected: false,
        isInviteSelected: false,
        isSettingsSelected: false,

        selectedResult: null,
        historyRoom: null,

        chatPanelSelected: true,

        chatSearchValue: '',
        chatSearchMetaData: SearchResultMeta.create(),
        chatSearchResults: null,

        participantSearch: ParticipantSearch.create(),
        participantSearchMetaData: SearchResultMeta.create(),
        participantSearchResults: null,

        isFullScreen: false,
        preFullScreenSettings: null,

        webrtcHasMicPermissions: false,

        init() {
            this._super(...arguments);

            this.setProperties({
                chatSearchResults: [],
                participantSearchResults: [],
                preFullScreenSettings: {
                    chatPanelSelected: false,
                    isParticipantsSelected: false
                }
            });

            const owner = _ember.default.getOwner(this);
            const participantSearch = this.get('participantSearch');
            participantSearch.set('store', this.get('store'));
            _ember.default.setOwner(participantSearch, owner);
            this.setupFullscreenServiceEventListeners();

            if (!this.get('permissions.canViewBusinessChat') && !this.get('sidebar.showAcdInteractions')) {
                this.set('chatPanelSelected', false);
            }

            this.get('webrtc').ensureAudioPermissions().then(() => {
                this.set('webrtcHasMicPermissions', true);
            });
        },

        // eslint-disable-next-line ember/no-observers
        hideChat: observer('permissions.canViewBusinessChat', 'sidebar.showAcdInteractions', function () {
            if (!this.get('permissions.canViewBusinessChat') && !this.get('sidebar.showAcdInteractions')) {
                this.set('chatPanelSelected', false);
            }
        }),

        setupFullscreenServiceEventListeners() {
            const fullscreen = this.get('fullscreen');
            fullscreen.on('startFullscreen', () => {
                this._startFullScreen();
            });
            fullscreen.on('endFullscreen', () => {
                this._endFullScreen();
            });
        },

        _componentDestroyed() {
            return this.get('isDestroyed') || this.get('isDestroying');
        },

        _startFullScreen() {
            if (this._componentDestroyed() || this.get('isFullScreen')) {
                return;
            }
            this.set('preFullScreenSettings', {
                chatPanelSelected: this.get('chatPanelSelected'),
                isParticipantsSelected: this.get('isParticipantsSelected')
            });

            this.set('isFullScreen', true);
            this.set('chatPanelSelected', false);
            this.set('isParticipantsSelected', false);
        },

        _endFullScreen() {
            if (this._componentDestroyed() || !this.get('isFullScreen')) {
                return;
            }

            this.set('chatPanelSelected', this.get('preFullScreenSettings.chatPanelSelected'));
            this.set('isParticipantsSelected', this.get('preFullScreenSettings.isParticipantsSelected'));
            this.set('isFullScreen', false);
        },

        didUpdateAttrs() {
            this._super(...arguments);

            const activeRoom = this.get('activeRoom');
            const previousActiveRoom = this.get('_activeRoom');
            if (!activeRoom) {
                this.set('_activeRoom', activeRoom);
                return;
            }

            if (activeRoom !== previousActiveRoom) {
                this.send('clearNewParticipantSearch');
            }
        },

        // eslint-disable-next-line ember/no-observers
        managePresenceSubscriptions: observer('activeRoom', 'isParticipantsSelected', function () {
            const subscribeTo = this.get('isParticipantsSelected') ? this.get('activeRoom.participants') : null;
            this.get('presenceSubscription').autoSubscribe(subscribeTo, this);
        }),

        searchInputName: computed('showWebrtc', function () {
            if (this.get('showWebrtc')) {
                return 'video-invite';
            } else {
                return 'chat-invite';
            }
        }),

        popoutClassNames: computed('activeRoom.{isPopout,actionless,rosterless}', function () {
            const room = this.get('activeRoom');
            let classes = [];
            if (room && room.get('isPopout')) {
                if (room.get('actionless')) {
                    classes.push('actionless');
                }
                if (room.get('rosterless')) {
                    classes.push('rosterless');
                }
            }
            return classes.join(' ');
        }),

        videoProviders: computed('user', 'user.profile', function () {
            return this.get('mediaProviders').getVideoProviders(this.get('user'), this.get('activeRoom'));
        }),

        haveVideoMediaProviders: computed('videoProviders.@each.haveAccess', function () {
            return (0, _mediaProviders.hasSupplementalVideoProvider)(this.get('videoProviders'));
        }),

        // eslint-disable-next-line ember/no-observers
        activeRoomChanged: _ember.default.observer('activeRoom.oneToOneTarget', function () {
            if (this.get('activeRoom.oneToOneTarget')) {
                this.get('store').find('public-api-user', this.get('activeRoom.oneToOneTarget.id')).then(user => this.set('user', user));
            } else {
                this.set('user', null);
            }
        }),

        setIsInActiveRoom(person) {
            const personJid = person.get('jid');
            const activeRoom = this.get('activeRoom');
            person.set('isInRoom', (0, _jidHelpers.compareJids)(personJid, this.get('session.person.jid')) || activeRoom.isUserInRoom(personJid));
        },

        _selectResultInHistory(result, history = []) {
            const reducedHistory = history.reduce((obj, value) => {
                obj[value.get('id')] = value;
                return obj;
            }, {});

            // PCUCC-2013
            const message = this._findOriginalMessage(reducedHistory, result.get('id'));

            if (message) {
                message.set('selected', true);
            }
        },

        // Search results are retrieved by sending the search term via POST request to https://api.mypurecloud.com/api/v2/search.
        // The endpoint returns an array of results where each result corresponds to a message in history. However, for some reason
        // (probably some backend inconsistency), a retrieved result might have a different id from its matching message in history.
        // The "else" branch of the function below handles such cases, guaranteeing that a relation between the selected result and
        // a msg in history will be established. As of now (10/7/2022) this id mismatch phenomenon happens to messages more than 30 days old.
        _findOriginalMessage(reducedHistory, resultId) {
            let message;

            if (!resultId) return null;

            if (resultId in reducedHistory) {
                message = reducedHistory[resultId];
                const messageCorrects = message.get('corrects');
                const messageId = message.get('id');

                // if message has a 'corrects' property AND it points to a different id, recurse up the 'corrects' chain to find the msg!
                if (messageCorrects && messageCorrects !== messageId) {
                    return this._findOriginalMessage(reducedHistory, history, messageCorrects);
                } else {
                    return message;
                }
            } else {
                // resultId doesn't match any record in history! (it's an old message) resultId's last 16 chars should match part of a message's id though
                const last16 = resultId.slice(-16).toUpperCase();
                message = Object.values(reducedHistory).find(msg => msg.id.includes(last16));

                if (!message) {
                    // if still no message, as a last resort, let's query the DOM element for the result at the search-results list
                    // so we figure out the result's date/time. Then, search history for the closest message in time
                    const resultTime = (0, _jquery.default)('.search-result.selected > .search-result-heading > .timestamp').text();
                    const getDiff = m => Math.abs((0, _dates.default)(m.time) - (0, _dates.default)(resultTime));
                    let timeDiff = Infinity;

                    Object.values(reducedHistory).forEach(msg => {
                        if (getDiff(msg) < timeDiff) {
                            timeDiff = getDiff(msg);
                            message = msg;
                        }
                    });
                }
                return message;
            }
        },

        // DEPRECATED: should be removed when inPlaceChatEdit FT is gone [PCUCC-2013]
        _oldFindOriginalMessage(reducedHistory, resultId) {
            if (Object.prototype.hasOwnProperty.call(reducedHistory, resultId) && !reducedHistory[resultId].get('corrects')) {
                return reducedHistory[resultId];
            } else if (reducedHistory[resultId].get('corrects')) {
                return this._findOriginalMessage(reducedHistory, reducedHistory[resultId].get('corrects'));
            } else {
                return null;
            }
        },

        _scrollToSelected() {
            // I'm not real thrilled about the '10' magic number here there's some padding not accounted for
            // in the jquery below.
            const messages = this.$('.search-result-context-messages');
            if (messages) {
                messages.scrollTop(this.$('.chat-message.selected').position().top - this.$('.history-bar').outerHeight() - 10);
            }
        },

        _fetchChats(pageNumber = this._nextPage(), pageSize = CHAT_SEARCH_PAGE_SIZE) {
            const value = this.get('chatSearchValue');
            // If we get an invalid result, dont actually make the request and just
            // instantly return with zero results
            if (!value || value && value.length < 4) {
                this.set('chatSearchMetaData', SearchResultMeta.create({
                    total: 0,
                    searchValue: value,
                    notEnoughCharacters: true
                }));
                return _ember.default.RSVP.resolve([]);
            }
            return this.get('store').query('chat-search-result', {
                value,
                targetJids: [this.get('activeRoom.jid')],
                pageSize,
                pageNumber
            }).then(searchResults => {
                const results = searchResults.toArray();
                this.set('chatSearchMetaData', SearchResultMeta.create({
                    total: searchResults.get('meta.total'),
                    searchValue: this.get('chatSearchValue')
                }));
                return results;
            });
        },

        _nextPage() {
            const size = this.get('chatSearchResults.length') || 0;
            return Math.ceil(size / CHAT_SEARCH_PAGE_SIZE) + 1;
        },

        actions: {
            searchChats(value) {
                this.set('chatSearchValue', value);
                return this._fetchChats(1).then(results => {
                    this.set('chatSearchResults', results);
                    return results;
                });
            },

            triggerChatSearch(value) {
                this.set('isSearchSelected', true);
                this.set('chatSearchValue', value);
                this.send('searchChats', value);
            },

            fetchAdditionalChatSearchResults() {
                const total = this.get('chatSearchMetaData.total');
                const currentLength = this.get('chatSearchResults.length');
                if (currentLength < total) {
                    return this._fetchChats().then(results => {
                        this.get('chatSearchResults').pushObjects(results);
                        return this.get('chatSearchResults');
                    });
                }
            },

            clearChatSearch() {
                this.set('chatSearchMetaData', SearchResultMeta.create());
                this.set('chatSearchValue', '');
                this.get('chatSearchResults').clear();
            },

            clearNewParticipantSearch() {
                this.set('participantSearchMetaData', SearchResultMeta.create());
                this.get('participantSearchResults').clear();
                return this.get('participantSearch').performParticipantSearch('', () => {});
            },

            searchNewParticipants(value) {
                return this.get('participantSearch').performParticipantSearch(value).then(results => {
                    results.forEach(result => this.setIsInActiveRoom(result));
                    this.set('participantSearchMetaData', SearchResultMeta.create({
                        total: results.length,
                        searchValue: value
                    }));
                    this.set('participantSearchResults', results);
                    return results;
                });
            },

            toggleChatState() {
                this.toggleProperty('chatPanelSelected');
                this.$('.chat-textarea').trigger('blur'); // takes focus off of chat to display chat splat properly
            },

            paneSelectionToggled(property) {
                this.toggleProperty(property);
                // Triggering resize after short delay so that pane opening animation
                // will act like a window size change for chat pane to scroll PCDWEBK-2009
                run.later(() => _ember.default.$(window).trigger('resize'), 350);
            },

            paneDeselected(properties) {
                properties.forEach(item => this.set(item, false));
            },

            loadHistoricChat(result) {
                const date = result.get('createdDate');
                this.set('searchResultContextVisible', true);
                this.set('selectedResult', result);
                const activeRoomJid = this.get('activeRoom.jid');
                const chatRoom = this.get('chat').newRoom(activeRoomJid);
                chatRoom.set('rawSubject', this.get('activeRoom.rawSubject'));
                this.set('historyRoom', chatRoom);
                this.set('historyRoom.activated', this.get('activeRoom.activated'));
                this.set('historyRoom.oneToOneTarget', this.get('activeRoom.oneToOneTarget'));
                const promise = this.get('chat').loadContextualRoomHistory(chatRoom, date);
                promise.then(() => {
                    this._selectResultInHistory(result, chatRoom.messages);
                    run.scheduleOnce('afterRender', this, this._scrollToSelected);
                });
                return promise;
            },

            loadNewParticipant(result) {
                const activeRoom = this.get('activeRoom');
                this.get('chat').addPersonToRoom(activeRoom, result);
            },

            loadParticipantDetail(participant) {
                this.transitionToRoute('person.detail', participant);
            },

            deselectSearchResult() {
                this.set('selectedResult', null);
            },

            closeMentionWarning() {
                this.set('mentionWarningVisible', false);
            },
            openMentionWarning(options) {
                this.set('mentionWarningOpts', options);
                this.set('mentionWarningVisible', true);
            }
        }
    });

    exports.default = ChatConversationComponent;
});