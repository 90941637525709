define("ember-webrtc-components/mixins/webrtc-conversation-analysis", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Mixin = Ember.Mixin,
      computed = Ember.computed,
      observer = Ember.observer,
      inject = Ember.inject;
  var BANDWIDTH_LOSS_THRESHOLD = 15; // packet loss percentage

  var BANDWIDTH_RTT_THRESHOLD = 200; // rtt is ms

  var AUDIO_INPUT_LEVEL_THRESHOLD = 100;

  var _default = Mixin.create(_logger.default, {
    statReports: null,
    trackStatsLastStart: null,
    audioBitrateZero: false,
    audioBitrateIndicatesMute: false,
    wasMutedDuringLocalCheck: false,
    talkingWhileMuted: false,
    audioInputLevel: Ember.A([]),
    webrtc: inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('trackStatsLastStart', new Date());
      this.set('statReports', _defineProperty({}, this.get('session.person.id'), Ember.Object.create({
        name: this.get('session.person.name'),
        lastTrackStatsStart: this.get('trackStatsLastStart'),
        lastTrackStatsEnd: this.get('trackStatsLastStart')
      })));
    },
    hardwareAudioMute: computed('localParticipant.audioMuted', 'localParticipant.speaking', 'audioBitrateIndicatesMute', 'wasMutedDuringLocalCheck', 'wasMutedDuringLastCheck', function () {
      return !this.get('localParticipant.audioMuted') && this.get('audioBitrateIndicatesMute') && !this.get('localParticipant.speaking') && !this.get('wasMutedDuringLocalCheck') && !this.get('wasMutedDuringLastCheck');
    }),
    // eslint-disable-next-line ember/no-observers
    _logHardwareMute: observer('hardwareAudioMute', function () {
      if (this.get('hardwareAudioMute')) {
        this.logger.info('webrtc audio bitrate indicates hardware mute for local participant.', {
          remoteData: {
            conversationId: this.get('conversationId')
          }
        });
      }
    }),
    _sendLocalPerspective: function _sendLocalPerspective() {
      var perspective = this.get("statReports.".concat(this.get('session.person.id')));
      this.sendDirectlyToAll('stats', 'perspective', perspective);
    },
    _createNewPerspective: function _createNewPerspective(participantId) {
      var participant = this.get('participants').findBy('person.id', participantId) || {};
      return {
        name: Ember.get(participant, 'person.name'),
        trackStatsByTrackId: {}
      };
    },
    _getLocalPerspectiveOfParticipant: function _getLocalPerspectiveOfParticipant(participantId) {
      var perspective = this.get('statReports')[this.get('session.person.id')][participantId];

      if (!perspective) {
        perspective = this._createNewPerspective(participantId);
        this.set("statReports.".concat(this.get('session.person.id'), ".").concat(participantId), perspective);
      }

      return perspective;
    },
    _checkLocalAudio: function _checkLocalAudio(stats) {
      if (this.get('isScreenRecording') || this.get('isScreenShare')) {
        return;
      }

      var localAudio = stats.tracks.find(function (t) {
        return t.kind === 'audio';
      });

      if (!localAudio) {
        return this.logger.info('_checkLocalAudio has no local audio tracks to check', {
          remoteData: {
            conversationId: this.get('conversationId')
          }
        });
      }

      var bitrate = localAudio.bitrate;
      var audioInputLevel = localAudio.audioInputLevel;
      var audioMuted = this.get('localParticipant.audioMuted');

      if (audioInputLevel && audioMuted) {
        this.get('audioInputLevel').addObject({
          audioInputLevel: audioInputLevel
        });

        if (this.get('audioInputLevel').length > 2) {
          var isPastMaxAudioInputLevelThreshold = this.get('audioInputLevel').filter(function (element) {
            return element.audioInputLevel > AUDIO_INPUT_LEVEL_THRESHOLD;
          });

          if (isPastMaxAudioInputLevelThreshold && isPastMaxAudioInputLevelThreshold.length > 2) {
            this.set('talkingWhileMuted', true);
          }
        }
      } else {
        this.set('audioInputLevel', Ember.A([]));
        this.set('talkingWhileMuted', false);
      }

      if (bitrate === 0 && !this.get('audioBitrateZero')) {
        this.logger.warn('webrtc audio bitrate is zero which should never happen', {
          remoteData: {
            conversationId: this.get('conversationId'),
            kind: 'webrtcAudioBitrate'
          }
        });

        if (!this.get('webrtc').isV2(this.get('room'))) {
          this.get('webrtc').showZeroAudioBitrateNotification();
        }
      }

      this.setProperties({
        audioBitrateZero: bitrate === 0,
        audioBitrateIndicatesMute: bitrate === 10 || bitrate === 11,
        // normally muted is 10, but for some reason it's always 11 the next time someone leaves the call
        wasMutedDuringLastCheck: this.get('wasMutedDuringLocalCheck'),
        wasMutedDuringLocalCheck: this.get('localParticipant.audioMuted')
      });
    },
    _checkTracks: function _checkTracks(session, stats) {
      var _this = this;

      if (!stats) {
        return;
      }

      if (stats.tracks) {
        this._checkLocalAudio(stats);
      }

      if (!stats.remoteTracks || !stats.remoteTracks.length) {
        return;
      }

      var startTime = this.get('trackStatsLastStart');
      var endTime = new Date();
      this.set('trackStatsLastStart', endTime);
      var participants = this.get('participants');
      var participantsByVideoTrack = {};
      participants.forEach(function (participant) {
        participantsByVideoTrack[participant.get('videoTrack.id')] = participant;

        if (participant.get('screenTrack')) {
          participantsByVideoTrack[participant.get('screenTrack.id')] = participant;

          if (participant.get('screenStream')) {
            var screenStream = participant.get('screenStream');
            var screenTracks = screenStream.getTracks();

            if (screenTracks.length === 1) {
              participantsByVideoTrack[screenTracks[0].id] = participant;
            }
          }
        }
      });
      var statsMap = {};
      var participantsHavePositiveBitrate = {};
      stats.remoteTracks.forEach(function (trackInfo) {
        if (trackInfo.kind === 'audio') {
          return; // no problem with 0 bitrate audio - it's just muted --- maybe later make sure it's supposed to be muted
        }

        var participant = participantsByVideoTrack[trackInfo.track];

        if (!participant) {
          statsMap.unknown = statsMap.unknown || {};
          statsMap.unknown[trackInfo.track] = trackInfo;

          _this.logger.warn('Checking stream from unknown participant', {
            remoteData: {
              sid: session.sid,
              conversationId: _this.get('conversationId'),
              kind: 'webrtcTrackAnalysis',
              trackInfo: trackInfo
            }
          });

          return;
        }

        var id = participant.get('person.id') || participant.get('videoTrack.id');
        statsMap[id] = statsMap[id] || {};
        statsMap[id][trackInfo.track] = trackInfo;
      });
      Object.keys(statsMap).forEach(function (participantId) {
        var trackInfos = statsMap[participantId];

        if (participantId === 'unknown') {
          return;
        }

        var participant = _this.get('participants').find(function (p) {
          return p.get('person.id') === participantId || p.get('videoTrack.id') === participantId;
        });

        var hasBitRate = false;
        Object.keys(trackInfos).forEach(function (track) {
          var trackInfo = trackInfos[track];
          hasBitRate = hasBitRate || trackInfo.bitrate > 0;
        });

        if (hasBitRate) {
          participant.set('zeroBitrateCount', 0);
          participant.incrementProperty('positiveBitrateCount');
          participantsHavePositiveBitrate[participantId] = true;
          return;
        } // the rest of the analysis applies only to conferences, so return if this is a one to one call


        if (_this.get('isOneToOne')) {
          return;
        }

        var stream = participant.get('cameraStream');
        var track = participant.get('videoTrack');
        var logInfo = {
          stream: stream,
          track: track,
          person: {
            id: participantId
          }
        }; // this track has zero bitrate, but another one for the participant has a positive one.

        if (participantsHavePositiveBitrate[participantId]) {
          _this.logger.warn('participant has both zero and non-zero bitrate tracks', {
            remoteData: {
              conversationId: _this.get('conversationId'),
              kind: 'webrtcTrackAnalysis',
              sid: session.sid,
              logInfo: logInfo
            }
          });

          return;
        }

        participant.set('positiveBitrateCount', 0);

        if (participant.get('inLastN') && !participant.get('videoMuted')) {
          _this.logger.warn('Not receiving bytes from participant who is in lastn', {
            remoteData: {
              conversationId: _this.get('conversationId'),
              kind: 'webrtcTrackAnalysis',
              sid: session.sid,
              logInfo: logInfo
            }
          });

          participant.incrementProperty('zeroBitrateCount');
        }
      });

      if (this.get('room.associatedInteraction')) {
        this._checkBandwidthForInteraction(stats);
      }

      this._addLocalTrackStats(statsMap, startTime, endTime);

      this._sendLocalPerspective();
    },
    _checkBandwidthForInteraction: function _checkBandwidthForInteraction(stats) {
      var hasBandwidthIssue;
      stats.remoteTracks.forEach(function (track) {
        hasBandwidthIssue = hasBandwidthIssue || track.intervalLoss > BANDWIDTH_LOSS_THRESHOLD || track.rtt && track.rtt > BANDWIDTH_RTT_THRESHOLD;
      });

      if (hasBandwidthIssue) {
        if (!this.get('hasNotifiedBandwidthIssue')) {
          if (!this.get('webrtc').isV2(this.get('room'))) {
            this.get('webrtc').showBandwidthNotification();
          }

          this.set('hasNotifiedBandwidthIssue', true);
        }

        this.logger.info('Bandwidth issue detected', {
          remoteData: {
            remoteTracks: stats.remoteTracks,
            conversationId: this.get('conversationId')
          }
        });
      }
    },
    _addLocalTrackStats: function _addLocalTrackStats(statsMap, startTime, endTime) {
      var _this2 = this;

      Object.keys(statsMap).forEach(function (participantId) {
        var participantTrackInfos = statsMap[participantId];

        var perspective = _this2._getLocalPerspectiveOfParticipant(participantId);

        perspective.trackStatsByTrackId = participantTrackInfos;
      });
      var localPerspective = this.get("statReports.".concat(this.get('session.person.id')));
      localPerspective.lastTrackStatsStart = startTime;
      localPerspective.lastTrackStatsEnd = endTime;
    },
    mergeStatsPerspective: function mergeStatsPerspective(participant, perspective) {
      var report = this.get('statReports');
      report[participant.get('person.id')] = perspective;
    }
  });

  _exports.default = _default;
});