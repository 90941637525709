define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/drop-down/enum-item/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/drop-down/enum-item/template', 'ember-engine-custom-fields/mixins/field-keys', 'ember-engine-custom-fields/mixins/validation'], function (exports, _template, _fieldKeys, _validation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        get = Ember.get,
        observer = Ember.observer,
        run = Ember.run,
        inject = Ember.inject;
    var alias = computed.alias,
        not = computed.not;

    var KEY_UPDATE_TIME = 800;

    exports.default = Component.extend(_fieldKeys.default, _validation.default, {
        layout: _template.default,
        classNames: ['enum-item'],
        limits: inject.service(),

        enumItem: null,
        enabledState: null,

        allowDelete: alias('enumItem.isNew'),
        allowDisable: not('enumItem.isNew'),
        key: alias('enumItem.key'),

        keyPrefixUpdateMillis: KEY_UPDATE_TIME,
        observeTitle: observer('enumItem.title', function () {
            if (!this.get('itemModel.propertyName')) {
                this.updateKey(this.get('enumItem.title'));
            }
        }),

        itemTitleMax: computed.alias('limits.coreTypeLimits.enum.item.maxLength.max'),

        errorLocaleKey: computed('showValidationError', 'showError', function () {
            var _getProperties = this.getProperties('showValidationError', 'showError', 'errorKey'),
                showValidationError = _getProperties.showValidationError,
                showError = _getProperties.showError,
                errorKey = _getProperties.errorKey;

            var key = void 0;

            if (showValidationError) {
                key = showError ? errorKey : 'emberEngineCustomFields.fieldErrors.enumItemMustBeValid';
            }
            return key;
        }),

        init: function init() {
            this._super();
            var disabled = this.get('enumItem._disabled');
            if (disabled) {
                this.set('enabledState', 'off');
            } else {
                this.set('enabledState', 'on');
            }
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.scrollToShow();
            this.$('.enum-display-name').focus();
        },
        scrollToShow: function scrollToShow() {
            var _this = this;

            if (this.get('isNew')) {
                run.scheduleOnce('afterRender', function () {
                    try {
                        _this.$('.input-left-col').last().children().last()[0].scrollIntoView(true);
                    } catch (e) {
                        // tried scrolling last child element in editor into view
                    }
                });
            }
        },
        _validate: function _validate() {
            return this.get('isFieldKeyValid') && this.get('isFieldKeyUnique') && this.get('hasTitle');
        },


        actions: {
            deleteEnum: function deleteEnum() {
                this.deleteEnum(this.get('enumItem'));
            },
            toggleEnabled: function toggleEnabled(toggleInfo) {
                var newValue = get(toggleInfo, 'newValue');
                this.setProperties({
                    enabledState: newValue,
                    'enumItem._disabled': newValue.toLowerCase() === 'off'
                });
            },
            clearShowValidationError: function clearShowValidationError() {
                this.set('showValidationError', false);
            }
        }
    });
});