define('ember-custom-fields-components/utils/field-util', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.fieldConfig = fieldConfig;
    var FIELD_TYPE = exports.FIELD_TYPE = {
        CHECKBOX: 'checkbox',
        DATE: 'date',
        DATETIME: 'datetime',
        ENUM: 'enum',
        IDENTIFIER: 'identifier',
        INTEGER: 'integer',
        NUMBER: 'number',
        TAG: 'tag',
        TEXT: 'text',
        LONGTEXT: 'longtext',
        URL: 'url'
    };

    var FIELD_TYPES = exports.FIELD_TYPES = Object.values(FIELD_TYPE);

    function fieldConfig(type) {
        var config = {};

        switch (type) {
            case FIELD_TYPE.CHECKBOX:
                config = {
                    icon: 'pc pc-check-square-o'
                };
                break;
            case FIELD_TYPE.DATE:
                config = {
                    icon: 'pc pc-calendar'
                };
                break;
            case FIELD_TYPE.DATETIME:
                config = {
                    icon: 'pc pc-clock-o'
                };
                break;
            case FIELD_TYPE.ENUM:
                config = {
                    icon: 'pc pc-list',
                    maxItems: 50
                };
                break;
            case FIELD_TYPE.IDENTIFIER:
                config = {
                    icon: 'pc pc-id-card',
                    minLengthDefault: 0,
                    maxLengthDefault: 100
                };
                break;
            case FIELD_TYPE.INTEGER:
                config = {
                    icon: 'ion ion-calculator',
                    minDefault: 0,
                    maxDefault: 1000
                };
                break;
            case FIELD_TYPE.LONGTEXT:
                config = {
                    icon: 'pc ion ion-android-menu',
                    minLengthDefault: 0,
                    maxLengthDefault: 1000
                };
                break;
            case FIELD_TYPE.NUMBER:
                config = {
                    icon: 'pc pc-hashtag',
                    minDefault: 0,
                    maxDefault: 1000000
                };
                break;
            case FIELD_TYPE.TAG:
                config = {
                    icon: 'pc pc-tag',
                    minLengthDefault: 1,
                    maxLengthDefault: 100,
                    minItemsDefault: 0,
                    maxItemsDefault: 10
                };
                break;
            case FIELD_TYPE.TEXT:
                config = {
                    icon: 'pc pc-text-width',
                    minLengthDefault: 0,
                    maxLengthDefault: 100
                };
                break;
            case FIELD_TYPE.URL:
                config = {
                    icon: 'pc pc-desktop',
                    minLengthDefault: 0,
                    maxLengthDefault: 200
                };
                break;
        }

        return config;
    }

    var IS_NOT_VALID_KEY = exports.IS_NOT_VALID_KEY = /[^_a-z0-9]/ig;

    /**
     * Must be alphanumeric and underscore for the service.
     *
     * @param source the label name from the field label
     * @param type - the field type
     * @returns {number}
     */
    var getFieldKey = exports.getFieldKey = function getFieldKey() {
        var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        var suffix = type ? '_' + type : '';
        var prefix = /[^_a-z]/i.test(source.charAt(0)) ? '_' : '';
        var clamped = source.toLowerCase().replace(IS_NOT_VALID_KEY, '_');
        var trimmed = clamped.replace(/[_]*$/ig, '');
        return '' + prefix + trimmed + suffix;
    };
});