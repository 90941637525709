define("ember-purecloud-components/models/participant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var communicationMappings = {
    videos: 'video-communication',
    screenshares: 'screenshare-communication',
    calls: 'call-communication',
    chats: 'chat-communication',
    emails: 'email-communication',
    callbacks: 'callback-communication',
    socialExpressions: 'social-communication',
    messages: 'message-communication'
  };

  var _default = _emberData.default.Model.extend({
    // ----------------------------------------------------------------------------
    // Raw Properties
    // ----------------------------------------------------------------------------
    conversation: _emberData.default.belongsTo('conversation', {
      async: false
    }),
    startTime: _emberData.default.attr(),
    connectedTime: _emberData.default.attr(),
    userUri: _emberData.default.attr(),
    userId: _emberData.default.attr(),
    externalContactId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    purpose: _emberData.default.attr(),
    wrapupRequired: _emberData.default.attr(),
    wrapup: _emberData.default.attr(),
    attributes: _emberData.default.attr(),
    screenRecordingState: _emberData.default.attr(),
    ani: _emberData.default.attr(),
    dnis: _emberData.default.attr(),
    address: _emberData.default.attr(),
    videos: _emberData.default.hasMany(communicationMappings.videos, {
      async: false
    }),
    screenshares: _emberData.default.hasMany(communicationMappings.screenshares, {
      async: false
    }),
    calls: _emberData.default.hasMany(communicationMappings.calls, {
      async: false
    }),
    chats: _emberData.default.hasMany(communicationMappings.chats, {
      async: false
    }),
    emails: _emberData.default.hasMany(communicationMappings.emails, {
      async: false
    }),
    callbacks: _emberData.default.hasMany(communicationMappings.callbacks, {
      async: false
    }),
    socialExpressions: _emberData.default.hasMany(communicationMappings.socialExpressions, {
      async: false
    }),
    messages: _emberData.default.hasMany(communicationMappings.messages, {
      async: false
    }),
    update: function update(participantUpdate) {
      var _this = this;

      var updatableAttrs = ['purpose', 'attributes', 'startTime', 'connectedTime', 'wrapupRequired', 'wrapup', 'screenRecordingState'];
      var updateProperties = {};

      for (var _i = 0, _updatableAttrs = updatableAttrs; _i < _updatableAttrs.length; _i++) {
        var updatable = _updatableAttrs[_i];
        var updatedValue = participantUpdate[updatable];

        if (typeof updatedValue !== 'undefined') {
          updateProperties[updatable] = updatedValue;
        }
      }

      this.setProperties(updateProperties);

      var _loop = function _loop() {
        var comType = _Object$keys[_i2];
        var updatedCommunications = participantUpdate[comType];

        if (updatedCommunications) {
          updatedCommunications.forEach(function (update) {
            var existing = _this.get(comType).findBy('id', update.id);

            if (existing) {
              existing.update(update);
            } else {
              var newCommunication = _this.get('store').createRecord(communicationMappings[comType], update);

              _this.get(comType).pushObject(newCommunication);
            }
          });
        }
      };

      for (var _i2 = 0, _Object$keys = Object.keys(communicationMappings); _i2 < _Object$keys.length; _i2++) {
        _loop();
      }
    },
    activeCommunications: Ember.computed('videos.@each.state', 'calls.@each.state', 'screenshares.@each.state', 'chats.@each.state', 'emails.@each.state', 'callbacks.@each.state', 'socialExpressions.@each.state', 'messages.@each.state', function () {
      var activeCommunications = Ember.Object.create();

      for (var _i3 = 0, _Object$keys2 = Object.keys(communicationMappings); _i3 < _Object$keys2.length; _i3++) {
        var comType = _Object$keys2[_i3];
        var active = this.get(comType).filter(function (communication) {
          return communication.get('state') !== 'disconnected' && communication.get('state') !== 'terminated';
        });

        if (active.length) {
          activeCommunications.set(comType, active);
        }
      }

      return activeCommunications;
    })
  });

  _exports.default = _default;
});