define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/inline-colors/component', ['exports', 'ember', 'ember-purecloud-components/utils/browser', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/inline-colors/template'], function (exports, _ember, _browser, _template) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Component.extend({
        classNames: ['inline-colors'],
        classNameBindings: ['colors.length::hide', 'canCopy::no-copy'],
        layout: _template.default,

        attrs: {
            colors: []
        },

        actions: {
            copyColor(color) {
                if (this.get('canCopy')) {
                    let textarea = this.$('textarea')[0];
                    textarea.value = color.hex;
                    textarea.select();
                    document.execCommand('copy');

                    _ember.default.set(color, 'copied', true);

                    _ember.default.run.later(function () {
                        _ember.default.set(color, 'copied', false);
                    }, 2000);
                }
            }
        },

        canCopy: _ember.default.computed(function () {
            if (typeof document.queryCommandSupported === 'function') {
                return document.queryCommandSupported('copy') || _browser.default.isChromeOrChromium;
            }
            return typeof document.execCommand === 'function' && typeof window.getSelection === 'function';
        })
    });
});