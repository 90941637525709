define('ember-cli-testing/components/mock-component', ['exports', 'ember', 'sinon'], function (exports, _ember, _sinon) {

    /**
    * A mock component that a consuming application can extend and place in its
    * tests/mocks/components directory so that it's used in a unit test when its
    * name is specified via the `mockComponents` property of `setupTest()`.
    *
    * @property {string} name - The name of the component, which a subclass must
    *                           override. The component instance
    *                           will be available to the test as a property of that
    *                           name on the mocks service's `components` object
    *                           (i.e. mocks.components[name]).
    */
    exports['default'] = _ember['default'].Component.extend({

        mocks: _ember['default'].inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            this.mocks.registerComponent(this, this.get('name'));
            _sinon['default'].spy(this, 'didInsertElement');
        }
    });
});