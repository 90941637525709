define('ember-engine-custom-fields/mixins/validation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Mixin = Ember.Mixin,
        inject = Ember.inject,
        run = Ember.run;


    var VALIDATION_UPDATE_TIME = 1000;

    exports.default = Mixin.create({
        field: null,
        validation: inject.service(),
        showValidationError: false,

        _validate: function _validate() {
            return true;
        },
        _validateLater: function _validateLater() {
            var _this = this;

            var later = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            run.join(function () {
                run.later(function () {
                    _this.get('validation').updateValidation(_this);
                }, later);
            });
        },
        init: function init() {
            this._super.apply(this, arguments);
            this.get('validation').addToValidation(this);
        },
        activate: function activate() {
            this._super.apply(this, arguments);
            // clearing the active field if there was one - this is called automatically by routes that implement this mixin
            this.get('validation').updateActiveField(null);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            var isEnum = this.get('enumItem');
            var isField = this.get('field');
            var validateNow = this.get('validateNow');

            var isFieldOld = !this.get('field.isNewField');
            var isEnumOld = !this.get('enumItem.isNew');

            var hasFieldKey = this.get('field.key');
            var hasEnumKey = this.get('enumItem.key');

            var validate = isEnum ? isEnumOld || hasEnumKey : isField ? isFieldOld || hasFieldKey : false;

            if (validate || validateNow) {
                this.get('validation').updateValidation(true);
            }
        },
        _updateValidateNow: function _updateValidateNow(validate) {
            this.set('validateNow', validate);
        },
        updateValidateNow: function updateValidateNow() {
            var _this2 = this;

            var validateNow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            this._updateValidateNow(validateNow);

            if (validateNow) {
                run.join(function () {
                    run.debounce(_this2, _this2._updateValidateNow, false, VALIDATION_UPDATE_TIME);
                });
            } else {
                this.set('showValidationError', false);
            }
        },
        willDestroyElement: function willDestroyElement() {
            this.get('validation').removeFromValidation(this);
        },


        actions: {
            validate: function validate() {
                this.get('validation').updateValidation(true);
            }
        }
    });
});