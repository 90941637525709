enifed('@glimmer/syntax', ['exports', 'handlebars', 'simple-html-tokenizer'], function (exports, _handlebars, _simpleHtmlTokenizer) {
    'use strict';

    exports.print = exports.Walker = exports.traverse = exports.builders = exports.preprocess = undefined;

    // Statements

    // Nodes

    // Expressions

    function buildPath(original, loc) {
        if (typeof original !== 'string') return original;
        var parts = original.split('.');
        if (parts[0] === 'this') {
            parts[0] = null;
        }
        return {
            type: "PathExpression",
            original: original,
            parts: parts,
            data: false,
            loc: buildLoc(loc)
        };
    }

    // Miscellaneous
    function buildHash(pairs) {
        return {
            type: "Hash",
            pairs: pairs || []
        };
    }

    function buildSource(source) {
        return source || null;
    }
    function buildPosition(line, column) {
        return {
            line: typeof line === 'number' ? line : null,
            column: typeof column === 'number' ? column : null
        };
    }
    function buildLoc() {
        var _len, args, _key, loc, startLine, startColumn, endLine, endColumn, source;

        for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        if (args.length === 1) {
            loc = args[0];

            if (typeof loc === 'object') {
                return {
                    source: buildSource(loc.source),
                    start: buildPosition(loc.start.line, loc.start.column),
                    end: buildPosition(loc.end.line, loc.end.column)
                };
            } else {
                return null;
            }
        } else {
            startLine = args[0], startColumn = args[1], endLine = args[2], endColumn = args[3], source = args[4];


            return {
                source: buildSource(source),
                start: buildPosition(startLine, startColumn),
                end: buildPosition(endLine, endColumn)
            };
        }
    }
    var b = {
        mustache: function (path, params, hash, raw, loc) {
            return {
                type: "MustacheStatement",
                path: buildPath(path),
                params: params || [],
                hash: hash || buildHash([]),
                escaped: !raw,
                loc: buildLoc(loc)
            };
        },
        block: function (path, params, hash, program, inverse, loc) {
            return {
                type: "BlockStatement",
                path: buildPath(path),
                params: params ? params.map(buildPath) : [],
                hash: hash || buildHash([]),
                program: program || null,
                inverse: inverse || null,
                loc: buildLoc(loc)
            };
        },
        partial: function (name, params, hash, indent) {
            return {
                type: "PartialStatement",
                name: name,
                params: params || [],
                hash: hash || buildHash([]),
                indent: indent
            };
        },
        comment: function (value, loc) {
            return {
                type: "CommentStatement",
                value: value,
                loc: buildLoc(loc)
            };
        },
        mustacheComment: function (value, loc) {
            return {
                type: "MustacheCommentStatement",
                value: value,
                loc: buildLoc(loc)
            };
        },
        element: function (tag, attributes, modifiers, children, comments, loc) {
            // this is used for backwards compat prior to `comments` being added to the AST
            if (!Array.isArray(comments)) {
                loc = comments;
                comments = [];
            }
            return {
                type: "ElementNode",
                tag: tag || "",
                attributes: attributes || [],
                blockParams: [],
                modifiers: modifiers || [],
                comments: comments || [],
                children: children || [],
                loc: buildLoc(loc)
            };
        },
        elementModifier: function (path, params, hash, loc) {
            return {
                type: "ElementModifierStatement",
                path: buildPath(path),
                params: params || [],
                hash: hash || buildHash([]),
                loc: buildLoc(loc)
            };
        },
        attr: function (name, value, loc) {
            return {
                type: "AttrNode",
                name: name,
                value: value,
                loc: buildLoc(loc)
            };
        },
        text: function (chars, loc) {
            return {
                type: "TextNode",
                chars: chars || "",
                loc: buildLoc(loc)
            };
        },
        sexpr: function (path, params, hash, loc) {
            return {
                type: "SubExpression",
                path: buildPath(path),
                params: params || [],
                hash: hash || buildHash([]),
                loc: buildLoc(loc)
            };
        },
        path: buildPath,
        string: function (value) {
            return {
                type: "StringLiteral",
                value: value,
                original: value
            };
        },
        boolean: function (value) {
            return {
                type: "BooleanLiteral",
                value: value,
                original: value
            };
        },
        number: function (value) {
            return {
                type: "NumberLiteral",
                value: value,
                original: value
            };
        },
        undefined: function () {
            return {
                type: "UndefinedLiteral",
                value: undefined,
                original: undefined
            };
        },
        null: function () {
            return {
                type: "NullLiteral",
                value: null,
                original: null
            };
        },
        concat: function (parts) {
            return {
                type: "ConcatStatement",
                parts: parts || []
            };
        },
        hash: buildHash,
        pair: function (key, value) {
            return {
                type: "HashPair",
                key: key,
                value: value
            };
        },
        program: function (body, blockParams, loc) {
            return {
                type: "Program",
                body: body || [],
                blockParams: blockParams || [],
                loc: buildLoc(loc)
            };
        },
        loc: buildLoc,
        pos: buildPosition
    };

    function build(ast) {
        if (!ast) {
            return '';
        }
        var output = [],
            chainBlock,
            body,
            value,
            lines;
        switch (ast.type) {
            case 'Program':
                {
                    chainBlock = ast.chained && ast.body[0];

                    if (chainBlock) {
                        chainBlock.chained = true;
                    }
                    body = buildEach(ast.body).join('');

                    output.push(body);
                }
                break;
            case 'ElementNode':
                output.push('<', ast.tag);
                if (ast.attributes.length) {
                    output.push(' ', buildEach(ast.attributes).join(' '));
                }
                if (ast.modifiers.length) {
                    output.push(' ', buildEach(ast.modifiers).join(' '));
                }
                if (ast.comments.length) {
                    output.push(' ', buildEach(ast.comments).join(' '));
                }
                output.push('>');
                output.push.apply(output, buildEach(ast.children));
                output.push('</', ast.tag, '>');
                break;
            case 'AttrNode':
                output.push(ast.name, '=');
                value = build(ast.value);

                if (ast.value.type === 'TextNode') {
                    output.push('"', value, '"');
                } else {
                    output.push(value);
                }
                break;
            case 'ConcatStatement':
                output.push('"');
                ast.parts.forEach(function (node) {
                    if (node.type === 'StringLiteral') {
                        output.push(node.original);
                    } else {
                        output.push(build(node));
                    }
                });
                output.push('"');
                break;
            case 'TextNode':
                output.push(ast.chars);
                break;
            case 'MustacheStatement':
                {
                    output.push(compactJoin(['{{', pathParams(ast), '}}']));
                }
                break;
            case 'MustacheCommentStatement':
                {
                    output.push(compactJoin(['{{!--', ast.value, '--}}']));
                }
                break;
            case 'ElementModifierStatement':
                {
                    output.push(compactJoin(['{{', pathParams(ast), '}}']));
                }
                break;
            case 'PathExpression':
                output.push(ast.original);
                break;
            case 'SubExpression':
                {
                    output.push('(', pathParams(ast), ')');
                }
                break;
            case 'BooleanLiteral':
                output.push(ast.value ? 'true' : false);
                break;
            case 'BlockStatement':
                {
                    lines = [];

                    if (ast.chained) {
                        lines.push(['{{else ', pathParams(ast), '}}'].join(''));
                    } else {
                        lines.push(openBlock(ast));
                    }
                    lines.push(build(ast.program));
                    if (ast.inverse) {
                        if (!ast.inverse.chained) {
                            lines.push('{{else}}');
                        }
                        lines.push(build(ast.inverse));
                    }
                    if (!ast.chained) {
                        lines.push(closeBlock(ast));
                    }
                    output.push(lines.join(''));
                }
                break;
            case 'PartialStatement':
                {
                    output.push(compactJoin(['{{>', pathParams(ast), '}}']));
                }
                break;
            case 'CommentStatement':
                {
                    output.push(compactJoin(['<!--', ast.value, '-->']));
                }
                break;
            case 'StringLiteral':
                {
                    output.push('"' + ast.value + '"');
                }
                break;
            case 'NumberLiteral':
                {
                    output.push(ast.value);
                }
                break;
            case 'UndefinedLiteral':
                {
                    output.push('undefined');
                }
                break;
            case 'NullLiteral':
                {
                    output.push('null');
                }
                break;
            case 'Hash':
                {
                    output.push(ast.pairs.map(function (pair) {
                        return build(pair);
                    }).join(' '));
                }
                break;
            case 'HashPair':
                {
                    output.push(ast.key + '=' + build(ast.value));
                }
                break;
        }
        return output.join('');
    }
    function compact(array) {
        var newArray = [];
        array.forEach(function (a) {
            if (typeof a !== 'undefined' && a !== null && a !== '') {
                newArray.push(a);
            }
        });
        return newArray;
    }
    function buildEach(asts) {
        var output = [];
        asts.forEach(function (node) {
            output.push(build(node));
        });
        return output;
    }
    function pathParams(ast) {
        var name = build(ast.name);
        var path = build(ast.path);
        var params = buildEach(ast.params).join(' ');
        var hash = build(ast.hash);
        return compactJoin([name, path, params, hash], ' ');
    }
    function compactJoin(array, delimiter) {
        return compact(array).join(delimiter || '');
    }
    function blockParams(block) {
        var params = block.program.blockParams;
        if (params.length) {
            return ' as |' + params.join(' ') + '|';
        }
    }
    function openBlock(block) {
        return ['{{#', pathParams(block), blockParams(block), '}}'].join('');
    }
    function closeBlock(block) {
        return ['{{/', build(block.path), '}}'].join('');
    }

    var visitorKeys = {
        Program: ['body'],
        MustacheStatement: ['path', 'params', 'hash'],
        BlockStatement: ['path', 'params', 'hash', 'program', 'inverse'],
        ElementModifierStatement: ['path', 'params', 'hash'],
        PartialStatement: ['name', 'params', 'hash'],
        CommentStatement: [],
        MustacheCommentStatement: [],
        ElementNode: ['attributes', 'modifiers', 'children', 'comments'],
        AttrNode: ['value'],
        TextNode: [],
        ConcatStatement: ['parts'],
        SubExpression: ['path', 'params', 'hash'],
        PathExpression: [],
        StringLiteral: [],
        BooleanLiteral: [],
        NumberLiteral: [],
        NullLiteral: [],
        UndefinedLiteral: [],
        Hash: ['pairs'],
        HashPair: ['value']
    };

    function TraversalError(message, node, parent, key) {
        this.name = "TraversalError";
        this.message = message;
        this.node = node;
        this.parent = parent;
        this.key = key;
    }
    TraversalError.prototype = Object.create(Error.prototype);
    TraversalError.prototype.constructor = TraversalError;
    function cannotRemoveNode(node, parent, key) {
        return new TraversalError("Cannot remove a node unless it is part of an array", node, parent, key);
    }
    function cannotReplaceNode(node, parent, key) {
        return new TraversalError("Cannot replace a node with multiple nodes unless it is part of an array", node, parent, key);
    }
    function cannotReplaceOrRemoveInKeyHandlerYet(node, key) {
        return new TraversalError("Replacing and removing in key handlers is not yet supported.", node, null, key);
    }

    function visitNode(visitor, node) {
        var handler = visitor[node.type] || visitor.All,
            keys,
            i;
        var result = void 0;
        if (handler && handler.enter) {
            result = handler.enter.call(null, node);
        }
        if (result !== undefined && result !== null) {
            if (JSON.stringify(node) === JSON.stringify(result)) {
                result = undefined;
            } else if (Array.isArray(result)) {
                return visitArray(visitor, result) || result;
            } else {
                return visitNode(visitor, result) || result;
            }
        }
        if (result === undefined) {
            keys = visitorKeys[node.type];

            for (i = 0; i < keys.length; i++) {
                visitKey(visitor, handler, node, keys[i]);
            }
            if (handler && handler.exit) {
                result = handler.exit.call(null, node);
            }
        }
        return result;
    }
    function visitKey(visitor, handler, node, key) {
        var value = node[key],
            _result;
        if (!value) {
            return;
        }
        var keyHandler = handler && (handler.keys[key] || handler.keys.All);
        var result = void 0;
        if (keyHandler && keyHandler.enter) {
            result = keyHandler.enter.call(null, node, key);
            if (result !== undefined) {
                throw cannotReplaceOrRemoveInKeyHandlerYet(node, key);
            }
        }
        if (Array.isArray(value)) {
            visitArray(visitor, value);
        } else {
            _result = visitNode(visitor, value);

            if (_result !== undefined) {
                assignKey(node, key, _result);
            }
        }
        if (keyHandler && keyHandler.exit) {
            result = keyHandler.exit.call(null, node, key);
            if (result !== undefined) {
                throw cannotReplaceOrRemoveInKeyHandlerYet(node, key);
            }
        }
    }
    function visitArray(visitor, array) {
        var i, result;

        for (i = 0; i < array.length; i++) {
            result = visitNode(visitor, array[i]);

            if (result !== undefined) {
                i += spliceArray(array, i, result) - 1;
            }
        }
    }
    function assignKey(node, key, result) {
        if (result === null) {
            throw cannotRemoveNode(node[key], node, key);
        } else if (Array.isArray(result)) {
            if (result.length === 1) {
                node[key] = result[0];
            } else {
                if (result.length === 0) {
                    throw cannotRemoveNode(node[key], node, key);
                } else {
                    throw cannotReplaceNode(node[key], node, key);
                }
            }
        } else {
            node[key] = result;
        }
    }
    function spliceArray(array, index, result) {
        if (result === null) {
            array.splice(index, 1);
            return 0;
        } else if (Array.isArray(result)) {
            array.splice.apply(array, [index, 1].concat(result));
            return result.length;
        } else {
            array.splice(index, 1, result);
            return 1;
        }
    }
    function traverse(node, visitor) {
        visitNode(normalizeVisitor(visitor), node);
    }
    function normalizeVisitor(visitor) {
        var normalizedVisitor = {},
            handler,
            normalizedKeys,
            keys,
            keyHandler;
        for (var type in visitor) {
            handler = visitor[type] || visitor.All;
            normalizedKeys = {};

            if (typeof handler === 'object') {
                keys = handler.keys;

                if (keys) {
                    for (var key in keys) {
                        keyHandler = keys[key];

                        if (typeof keyHandler === 'object') {
                            normalizedKeys[key] = {
                                enter: typeof keyHandler.enter === 'function' ? keyHandler.enter : null,
                                exit: typeof keyHandler.exit === 'function' ? keyHandler.exit : null
                            };
                        } else if (typeof keyHandler === 'function') {
                            normalizedKeys[key] = {
                                enter: keyHandler,
                                exit: null
                            };
                        }
                    }
                }
                normalizedVisitor[type] = {
                    enter: typeof handler.enter === 'function' ? handler.enter : null,
                    exit: typeof handler.exit === 'function' ? handler.exit : null,
                    keys: normalizedKeys
                };
            } else if (typeof handler === 'function') {
                normalizedVisitor[type] = {
                    enter: handler,
                    exit: null,
                    keys: normalizedKeys
                };
            }
        }
        return normalizedVisitor;
    }

    function Walker() {
        var order = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

        this.order = order;
        this.stack = [];
    }
    Walker.prototype.visit = function (node, callback) {
        if (!node) {
            return;
        }
        this.stack.push(node);
        if (this.order === 'post') {
            this.children(node, callback);
            callback(node, this);
        } else {
            callback(node, this);
            this.children(node, callback);
        }
        this.stack.pop();
    };
    var visitors = {
        Program: function (walker, node, callback) {
            var i;

            for (i = 0; i < node.body.length; i++) {
                walker.visit(node.body[i], callback);
            }
        },
        ElementNode: function (walker, node, callback) {
            var i;

            for (i = 0; i < node.children.length; i++) {
                walker.visit(node.children[i], callback);
            }
        },
        BlockStatement: function (walker, node, callback) {
            walker.visit(node.program, callback);
            walker.visit(node.inverse, callback);
        }
    };
    Walker.prototype.children = function (node, callback) {
        var visitor = visitors[node.type];
        if (visitor) {
            visitor(this, node, callback);
        }
    };

    // Regex to validate the identifier for block parameters.
    // Based on the ID validation regex in Handlebars.
    var ID_INVERSE_PATTERN = /[!"#%-,\.\/;->@\[-\^`\{-~]/;
    // Checks the element's attributes to see if it uses block params.
    // If it does, registers the block params with the program and
    // removes the corresponding attributes from the element.
    function parseElementBlockParams(element) {
        var params = parseBlockParams(element);
        if (params) element.blockParams = params;
    }
    function parseBlockParams(element) {
        var l = element.attributes.length,
            i,
            paramsString,
            params,
            _i,
            param;
        var attrNames = [];
        for (i = 0; i < l; i++) {
            attrNames.push(element.attributes[i].name);
        }
        var asIndex = attrNames.indexOf('as');
        if (asIndex !== -1 && l > asIndex && attrNames[asIndex + 1].charAt(0) === '|') {
            // Some basic validation, since we're doing the parsing ourselves
            paramsString = attrNames.slice(asIndex).join(' ');

            if (paramsString.charAt(paramsString.length - 1) !== '|' || paramsString.match(/\|/g).length !== 2) {
                throw new Error('Invalid block parameters syntax: \'' + paramsString + '\'');
            }
            params = [];

            for (_i = asIndex + 1; _i < l; _i++) {
                param = attrNames[_i].replace(/\|/g, '');

                if (param !== '') {
                    if (ID_INVERSE_PATTERN.test(param)) {
                        throw new Error('Invalid identifier for block parameters: \'' + param + '\' in \'' + paramsString + '\'');
                    }
                    params.push(param);
                }
            }
            if (params.length === 0) {
                throw new Error('Cannot use zero block parameters: \'' + paramsString + '\'');
            }
            element.attributes = element.attributes.slice(0, asIndex);
            return params;
        }
    }
    function childrenFor(node) {
        if (node.type === 'Program') {
            return node.body;
        }
        if (node.type === 'ElementNode') {
            return node.children;
        }
    }
    function appendChild(parent, node) {
        childrenFor(parent).push(node);
    }

    var handlebarsNodeVisitors = {
        Program: function (program) {
            var node = b.program([], program.blockParams, program.loc);
            var i = void 0,
                l = program.body.length;
            this.elementStack.push(node);
            if (l === 0) {
                return this.elementStack.pop();
            }
            for (i = 0; i < l; i++) {
                this.acceptNode(program.body[i]);
            }
            // Ensure that that the element stack is balanced properly.
            var poppedNode = this.elementStack.pop();
            if (poppedNode !== node) {
                throw new Error("Unclosed element `" + poppedNode.tag + "` (on line " + poppedNode.loc.start.line + ").");
            }
            return node;
        },
        BlockStatement: function (block) {
            delete block.inverseStrip;
            delete block.openString;
            delete block.closeStrip;
            if (this.tokenizer.state === 'comment') {
                this.appendToCommentData('{{' + this.sourceForMustache(block) + '}}');
                return;
            }
            if (this.tokenizer.state !== 'comment' && this.tokenizer.state !== 'data' && this.tokenizer.state !== 'beforeData') {
                throw new Error("A block may only be used inside an HTML element or another block.");
            }
            block = acceptCommonNodes(this, block);
            var program = block.program ? this.acceptNode(block.program) : null;
            var inverse = block.inverse ? this.acceptNode(block.inverse) : null;
            var node = b.block(block.path, block.params, block.hash, program, inverse, block.loc);
            var parentProgram = this.currentElement();
            appendChild(parentProgram, node);
        },
        MustacheStatement: function (rawMustache) {
            var tokenizer = this.tokenizer;
            var path = rawMustache.path,
                params = rawMustache.params,
                hash = rawMustache.hash,
                escaped = rawMustache.escaped,
                loc = rawMustache.loc;

            var mustache = b.mustache(path, params, hash, !escaped, loc);
            if (tokenizer.state === 'comment') {
                this.appendToCommentData('{{' + this.sourceForMustache(mustache) + '}}');
                return;
            }
            acceptCommonNodes(this, mustache);
            switch (tokenizer.state) {
                // Tag helpers
                case "tagName":
                    addElementModifier(this.currentNode, mustache);
                    tokenizer.state = "beforeAttributeName";
                    break;
                case "beforeAttributeName":
                    addElementModifier(this.currentNode, mustache);
                    break;
                case "attributeName":
                case "afterAttributeName":
                    this.beginAttributeValue(false);
                    this.finishAttributeValue();
                    addElementModifier(this.currentNode, mustache);
                    tokenizer.state = "beforeAttributeName";
                    break;
                case "afterAttributeValueQuoted":
                    addElementModifier(this.currentNode, mustache);
                    tokenizer.state = "beforeAttributeName";
                    break;
                // Attribute values
                case "beforeAttributeValue":
                    appendDynamicAttributeValuePart(this.currentAttribute, mustache);
                    tokenizer.state = 'attributeValueUnquoted';
                    break;
                case "attributeValueDoubleQuoted":
                case "attributeValueSingleQuoted":
                case "attributeValueUnquoted":
                    appendDynamicAttributeValuePart(this.currentAttribute, mustache);
                    break;
                // TODO: Only append child when the tokenizer state makes
                // sense to do so, otherwise throw an error.
                default:
                    appendChild(this.currentElement(), mustache);
            }
            return mustache;
        },
        ContentStatement: function (content) {
            updateTokenizerLocation(this.tokenizer, content);
            this.tokenizer.tokenizePart(content.value);
            this.tokenizer.flushData();
        },
        CommentStatement: function (rawComment) {
            var tokenizer = this.tokenizer;
            var value = rawComment.value,
                loc = rawComment.loc;

            var comment = b.mustacheComment(value, loc);
            if (tokenizer.state === 'comment') {
                this.appendToCommentData('{{' + this.sourceForMustache(comment) + '}}');
                return;
            }
            switch (tokenizer.state) {
                case "beforeAttributeName":
                    this.currentNode.comments.push(comment);
                    break;
                case 'beforeData':
                case 'data':
                    appendChild(this.currentElement(), comment);
                    break;
                default:
                    throw new Error('Using a Handlebars comment when in the `' + tokenizer.state + '` state is not supported: "' + comment.value + '" on line ' + loc.start.line + ':' + loc.start.column);
            }
            return comment;
        },
        PartialStatement: function (partial) {
            var name = partial.name,
                loc = partial.loc;

            throw new Error('Handlebars partials are not supported: "{{> ' + name.original + '" at L' + loc.start.line + ':C' + loc.start.column);
        },
        PartialBlockStatement: function (partialBlock) {
            var name = partialBlock.name,
                loc = partialBlock.loc;

            throw new Error('Handlebars partial blocks are not supported: "{{#> ' + name.original + '" at L' + loc.start.line + ':C' + loc.start.column);
        },
        Decorator: function (decorator) {
            var loc = decorator.loc,
                path = decorator.path;

            this.sourceForMustache(decorator);

            throw new Error('Handlebars decorators are not supported: "{{* ' + path.original + '" at L' + loc.start.line + ':C' + loc.start.column);
        },
        DecoratorBlock: function (decoratorBlock) {
            var loc = decoratorBlock.loc,
                path = decoratorBlock.path;

            this.sourceForMustache(decoratorBlock);

            throw new Error('Handlebars decorator blocks are not supported: "{{#* ' + path.original + '" at L' + loc.start.line + ':C' + loc.start.column);
        },
        SubExpression: function (sexpr) {
            return acceptCommonNodes(this, sexpr);
        },
        PathExpression: function (path) {
            var original = path.original,
                loc = path.loc;

            if (original.indexOf('/') !== -1) {
                // TODO add a SyntaxError with loc info
                if (original.slice(0, 2) === './') {
                    throw new Error('Using "./" is not supported in Glimmer and unnecessary: "' + path.original + '" on line ' + loc.start.line + '.');
                }
                if (original.slice(0, 3) === '../') {
                    throw new Error('Changing context using "../" is not supported in Glimmer: "' + path.original + '" on line ' + loc.start.line + '.');
                }
                if (original.indexOf('.') !== -1) {
                    throw new Error('Mixing \'.\' and \'/\' in paths is not supported in Glimmer; use only \'.\' to separate property paths: "' + path.original + '" on line ' + loc.start.line + '.');
                }
                path.parts = [path.parts.join('/')];
            }
            delete path.depth;
            // This is to fix a bug in the Handlebars AST where the path expressions in
            // `{{this.foo}}` (and similarly `{{foo-bar this.foo named=this.foo}}` etc)
            // are simply turned into `{{foo}}`. The fix is to push it back onto the
            // parts array and let the runtime see the difference. However, we cannot
            // simply use the string `this` as it means literally the property called
            // "this" in the current context (it can be expressed in the syntax as
            // `{{[this]}}`, where the square bracket are generally for this kind of
            // escaping – such as `{{foo.["bar.baz"]}}` would mean lookup a property
            // named literally "bar.baz" on `this.foo`). By convention, we use `null`
            // for this purpose.
            if (original.match(/^this(\..+)?$/)) {
                path.parts.unshift(null);
            }
            return path;
        },
        Hash: function (hash) {
            var i;

            for (i = 0; i < hash.pairs.length; i++) {
                this.acceptNode(hash.pairs[i].value);
            }
            return hash;
        },
        StringLiteral: function () {},
        BooleanLiteral: function () {},
        NumberLiteral: function () {},
        UndefinedLiteral: function () {},
        NullLiteral: function () {}
    };
    function calculateRightStrippedOffsets(original, value) {
        if (value === '') {
            // if it is empty, just return the count of newlines
            // in original
            return {
                lines: original.split("\n").length - 1,
                columns: 0
            };
        }
        // otherwise, return the number of newlines prior to
        // `value`
        var difference = original.split(value)[0];
        var lines = difference.split(/\n/);
        var lineCount = lines.length - 1;
        return {
            lines: lineCount,
            columns: lines[lineCount].length
        };
    }
    function updateTokenizerLocation(tokenizer, content) {
        var line = content.loc.start.line;
        var column = content.loc.start.column;
        var offsets = calculateRightStrippedOffsets(content.original, content.value);
        line = line + offsets.lines;
        if (offsets.lines) {
            column = offsets.columns;
        } else {
            column = column + offsets.columns;
        }
        tokenizer.line = line;
        tokenizer.column = column;
    }
    function acceptCommonNodes(compiler, node) {
        var i;

        compiler.acceptNode(node.path);
        if (node.params) {
            for (i = 0; i < node.params.length; i++) {
                compiler.acceptNode(node.params[i]);
            }
        } else {
            node.params = [];
        }
        if (node.hash) {
            compiler.acceptNode(node.hash);
        } else {
            node.hash = b.hash();
        }
        return node;
    }
    function addElementModifier(element, mustache) {
        var path = mustache.path,
            params = mustache.params,
            hash = mustache.hash,
            loc = mustache.loc;

        var modifier = b.elementModifier(path, params, hash, loc);
        element.modifiers.push(modifier);
    }
    function appendDynamicAttributeValuePart(attribute, part) {
        attribute.isDynamic = true;
        attribute.parts.push(part);
    }

    var voidMap = Object.create(null);

    "area base br col command embed hr img input keygen link meta param source track wbr".split(" ").forEach(function (tagName) {
        voidMap[tagName] = true;
    });
    var tokenizerEventHandlers = {
        reset: function () {
            this.currentNode = null;
        },
        // Comment
        beginComment: function () {
            this.currentNode = b.comment("");
            this.currentNode.loc = {
                source: null,
                start: b.pos(this.tagOpenLine, this.tagOpenColumn),
                end: null
            };
        },
        appendToCommentData: function (char) {
            this.currentNode.value += char;
        },
        finishComment: function () {
            this.currentNode.loc.end = b.pos(this.tokenizer.line, this.tokenizer.column);
            appendChild(this.currentElement(), this.currentNode);
        },
        // Data
        beginData: function () {
            this.currentNode = b.text();
            this.currentNode.loc = {
                source: null,
                start: b.pos(this.tokenizer.line, this.tokenizer.column),
                end: null
            };
        },
        appendToData: function (char) {
            this.currentNode.chars += char;
        },
        finishData: function () {
            this.currentNode.loc.end = b.pos(this.tokenizer.line, this.tokenizer.column);
            appendChild(this.currentElement(), this.currentNode);
        },
        // Tags - basic
        tagOpen: function () {
            this.tagOpenLine = this.tokenizer.line;
            this.tagOpenColumn = this.tokenizer.column;
        },
        beginStartTag: function () {
            this.currentNode = {
                type: 'StartTag',
                name: "",
                attributes: [],
                modifiers: [],
                comments: [],
                selfClosing: false,
                loc: null
            };
        },
        beginEndTag: function () {
            this.currentNode = {
                type: 'EndTag',
                name: "",
                attributes: [],
                modifiers: [],
                comments: [],
                selfClosing: false,
                loc: null
            };
        },
        finishTag: function () {
            var _tokenizer = this.tokenizer,
                line = _tokenizer.line,
                column = _tokenizer.column;

            var tag = this.currentNode;
            tag.loc = b.loc(this.tagOpenLine, this.tagOpenColumn, line, column);
            if (tag.type === 'StartTag') {
                this.finishStartTag();
                if (voidMap[tag.name] || tag.selfClosing) {
                    this.finishEndTag(true);
                }
            } else if (tag.type === 'EndTag') {
                this.finishEndTag(false);
            }
        },
        finishStartTag: function () {
            var _currentNode = this.currentNode,
                name = _currentNode.name,
                attributes = _currentNode.attributes,
                modifiers = _currentNode.modifiers,
                comments = _currentNode.comments;

            var loc = b.loc(this.tagOpenLine, this.tagOpenColumn);
            var element = b.element(name, attributes, modifiers, [], comments, loc);
            this.elementStack.push(element);
        },
        finishEndTag: function (isVoid) {
            var tag = this.currentNode;
            var element = this.elementStack.pop();
            var parent = this.currentElement();
            validateEndTag(tag, element, isVoid);
            element.loc.end.line = this.tokenizer.line;
            element.loc.end.column = this.tokenizer.column;
            parseElementBlockParams(element);
            appendChild(parent, element);
        },
        markTagAsSelfClosing: function () {
            this.currentNode.selfClosing = true;
        },
        // Tags - name
        appendToTagName: function (char) {
            this.currentNode.name += char;
        },
        // Tags - attributes
        beginAttribute: function () {
            var tag = this.currentNode;
            if (tag.type === 'EndTag') {
                throw new Error('Invalid end tag: closing tag must not have attributes, ' + ('in `' + tag.name + '` (on line ' + this.tokenizer.line + ').'));
            }
            this.currentAttribute = {
                name: "",
                parts: [],
                isQuoted: false,
                isDynamic: false,
                start: b.pos(this.tokenizer.line, this.tokenizer.column),
                valueStartLine: null,
                valueStartColumn: null
            };
        },
        appendToAttributeName: function (char) {
            this.currentAttribute.name += char;
        },
        beginAttributeValue: function (isQuoted) {
            this.currentAttribute.isQuoted = isQuoted;
            this.currentAttribute.valueStartLine = this.tokenizer.line;
            this.currentAttribute.valueStartColumn = this.tokenizer.column;
        },
        appendToAttributeValue: function (char) {
            var parts = this.currentAttribute.parts,
                loc,
                text;
            var lastPart = parts[parts.length - 1];
            if (lastPart && lastPart.type === 'TextNode') {
                lastPart.chars += char;
                // update end location for each added char
                lastPart.loc.end.line = this.tokenizer.line;
                lastPart.loc.end.column = this.tokenizer.column;
            } else {
                // initially assume the text node is a single char
                loc = b.loc(this.tokenizer.line, this.tokenizer.column, this.tokenizer.line, this.tokenizer.column);
                // correct for `\n` as first char

                if (char === '\n') {
                    loc.start.line -= 1;
                    loc.start.column = lastPart ? lastPart.loc.end.column : this.currentAttribute.valueStartColumn;
                }
                text = b.text(char, loc);

                parts.push(text);
            }
        },
        finishAttributeValue: function () {
            var _currentAttribute = this.currentAttribute,
                name = _currentAttribute.name,
                parts = _currentAttribute.parts,
                isQuoted = _currentAttribute.isQuoted,
                isDynamic = _currentAttribute.isDynamic,
                valueStartLine = _currentAttribute.valueStartLine,
                valueStartColumn = _currentAttribute.valueStartColumn;

            var value = assembleAttributeValue(parts, isQuoted, isDynamic, this.tokenizer.line);
            value.loc = b.loc(valueStartLine, valueStartColumn, this.tokenizer.line, this.tokenizer.column);
            var loc = b.loc(this.currentAttribute.start.line, this.currentAttribute.start.column, this.tokenizer.line, this.tokenizer.column);
            var attribute = b.attr(name, value, loc);
            this.currentNode.attributes.push(attribute);
        },
        reportSyntaxError: function (message) {
            throw new Error('Syntax error at line ' + this.tokenizer.line + ' col ' + this.tokenizer.column + ': ' + message);
        }
    };
    function assembleAttributeValue(parts, isQuoted, isDynamic, line) {
        if (isDynamic) {
            if (isQuoted) {
                return assembleConcatenatedValue(parts);
            } else {
                if (parts.length === 1 || parts.length === 2 && parts[1].chars === '/') {
                    return parts[0];
                } else {
                    throw new Error('An unquoted attribute value must be a string or a mustache, ' + 'preceeded by whitespace or a \'=\' character, and ' + ('followed by whitespace, a \'>\' character, or \'/>\' (on line ' + line + ')'));
                }
            }
        } else {
            return parts.length > 0 ? parts[0] : b.text("");
        }
    }
    function assembleConcatenatedValue(parts) {
        var i, part;

        for (i = 0; i < parts.length; i++) {
            part = parts[i];

            if (part.type !== 'MustacheStatement' && part.type !== 'TextNode') {
                throw new Error("Unsupported node in quoted attribute value: " + part.type);
            }
        }
        return b.concat(parts);
    }
    function validateEndTag(tag, element, selfClosing) {
        var error = void 0;
        if (voidMap[tag.name] && !selfClosing) {
            // EngTag is also called by StartTag for void and self-closing tags (i.e.
            // <input> or <br />, so we need to check for that here. Otherwise, we would
            // throw an error for those cases.
            error = "Invalid end tag " + formatEndTagInfo(tag) + " (void elements cannot have end tags).";
        } else if (element.tag === undefined) {
            error = "Closing tag " + formatEndTagInfo(tag) + " without an open tag.";
        } else if (element.tag !== tag.name) {
            error = "Closing tag " + formatEndTagInfo(tag) + " did not match last open tag `" + element.tag + "` (on line " + element.loc.start.line + ").";
        }
        if (error) {
            throw new Error(error);
        }
    }
    function formatEndTagInfo(tag) {
        return "`" + tag.name + "` (on line " + tag.loc.end.line + ")";
    }

    var syntax = {
        parse: preprocess,
        builders: b,
        print: build,
        traverse: traverse,
        Walker: Walker
    };
    function preprocess(html, options) {
        var ast = typeof html === 'object' ? html : (0, _handlebars.parse)(html),
            i,
            l,
            plugin;
        var combined = new Parser(html, options).acceptNode(ast);
        if (options && options.plugins && options.plugins.ast) {
            for (i = 0, l = options.plugins.ast.length; i < l; i++) {
                plugin = new options.plugins.ast[i](options);

                plugin.syntax = syntax;
                combined = plugin.transform(combined);
            }
        }
        return combined;
    }
    var entityParser = new _simpleHtmlTokenizer.EntityParser(_simpleHtmlTokenizer.HTML5NamedCharRefs);

    var Parser = function () {
        function Parser(source) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


            this.elementStack = [];
            this.currentAttribute = null;
            this.currentNode = null;
            this.tokenizer = new _simpleHtmlTokenizer.EventedTokenizer(this, entityParser);
            this.options = options;
            if (typeof source === 'string') {
                this.source = source.split(/(?:\r\n?|\n)/g);
            }
        }

        Parser.prototype.acceptNode = function (node) {
            return this[node.type](node);
        };

        Parser.prototype.currentElement = function () {
            return this.elementStack[this.elementStack.length - 1];
        };

        Parser.prototype.sourceForMustache = function (mustache) {
            var firstLine = mustache.loc.start.line - 1;
            var lastLine = mustache.loc.end.line - 1;
            var currentLine = firstLine - 1;
            var firstColumn = mustache.loc.start.column + 2;
            var lastColumn = mustache.loc.end.column - 2;
            var string = [];
            var line = void 0;
            if (!this.source) {
                return '{{' + mustache.path.id.original + '}}';
            }
            while (currentLine < lastLine) {
                currentLine++;
                line = this.source[currentLine];
                if (currentLine === firstLine) {
                    if (firstLine === lastLine) {
                        string.push(line.slice(firstColumn, lastColumn));
                    } else {
                        string.push(line.slice(firstColumn));
                    }
                } else if (currentLine === lastLine) {
                    string.push(line.slice(0, lastColumn));
                } else {
                    string.push(line);
                }
            }
            return string.join('\n');
        };

        return Parser;
    }();

    for (var key in handlebarsNodeVisitors) {
        Parser.prototype[key] = handlebarsNodeVisitors[key];
    }
    for (var _key2 in tokenizerEventHandlers) {
        Parser.prototype[_key2] = tokenizerEventHandlers[_key2];
    }

    // used by ember-compiler

    exports.preprocess = preprocess;
    exports.builders = b;
    exports.traverse = traverse;
    exports.Walker = Walker;
    exports.print = build;
});