define('ember-engine-custom-fields/components/schema-editor/component', ['exports', 'ember-engine-custom-fields/mixins/safe-set', 'ember-engine-custom-fields/mixins/validation', 'ember-engine-custom-fields/components/schema-editor/template'], function (exports, _safeSet, _validation, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject,
        isBlank = Ember.isBlank,
        run = Ember.run;
    var notEmpty = computed.notEmpty;
    exports.default = Component.extend(_validation.default, _safeSet.default, {
        layout: _template.default,
        classNames: ['schema-editor'],

        store: inject.service(),
        limits: inject.service(),
        intl: inject.service(),

        isEdit: false,
        isShowingPreview: false,
        activeField: computed.alias('validation.activeField'),

        isInvalid: computed('schema.{name,properties.@each.isConfigValid}', function () {
            var fields = this.get('schema.properties') || [];
            return isBlank(this.get('schema.name')) || fields.isAny('isConfigValid', false);
        }),
        schemaFields: computed.alias('schema.properties'),
        schemaFieldsUsed: computed.alias('schema.properties.length'),
        schemaFieldLimit: computed.alias('limits.maxNumberOfFieldsPerSchema'),
        schemaFieldsRemaining: computed('schemaFieldsUsed', 'schemaFieldLimit', function () {
            return this.get('schemaFieldLimit') - this.get('schemaFieldsUsed');
        }),
        hasSchemaFieldsRemaining: computed.gt('schemaFieldsRemaining', 0),
        hasNoSchemaFieldsRemaining: computed.not('hasSchemaFieldsRemaining'),

        schemaNameLengthLimit: computed.alias('limits.maxSchemaNameCharacters'),
        schemaDescLengthLimit: computed.alias('limits.maxSchemaDescriptionCharacters'),

        isNameTooLong: computed('schema.name.length', 'schemaNameLengthLimit', function () {
            return this.get('schema.name.length') > this.get('schemaNameLengthLimit');
        }),
        isDescTooLong: computed('schema.description.length', 'schemaDescLengthLimit', function () {
            return this.get('schema.description.length') > this.get('schemaDescLengthLimit');
        }),

        hasNameError: computed.or('schema.isNameEmpty', 'isNameTooLong'),

        showFieldsConfiguration: notEmpty('activeField'),

        // passed by parent
        save: null,
        cancel: null,

        _validate: function _validate() {
            return !this.get('isInvalid');
        },
        toggleFieldPanel: function toggleFieldPanel(activeField) {
            this.set('isEdit', !!activeField);
            this.get('validation').updateActiveField(activeField);
            this.get('validation').updateValidation(this);
            this.get('isEdit') ? $('.fields-overlay').removeClass('hidden') : $('.fields-overlay').addClass('hidden');
        },
        uniqueId: function uniqueId() {
            return new Date().toISOString() + '-' + Math.floor(Math.random() * 1000);
        },


        actions: {
            createField: function createField(fieldType) {
                var _this = this;

                if (this.get('hasSchemaFieldsRemaining')) {
                    var schema = this.get('schema');

                    var newField = schema.get(fieldType).createRecord({
                        id: this.uniqueId(),
                        order: this.get('schema.properties.length'),
                        title: null,
                        description: null,
                        type: fieldType,
                        items: {},
                        isNewField: true
                    });

                    this.set('isShowingPreview', false);
                    this.toggleFieldPanel(newField);

                    run.scheduleOnce('afterRender', this, function () {
                        _this.$('.field-list li').last()[0].scrollIntoView(false);
                        _this.$('.field-configuration-editor .field-name > input').focus();

                        _this.get('validation').updateValidation(_this);
                    });
                } else {
                    var maxMsg = this.get('intl').t('emberEngineCustomFields.fieldsList.maxFieldsReached', { schemaFieldLimit: this.get('schemaFieldLimit') });
                    alert(maxMsg);
                }
            },
            applyChange: function applyChange(field) {
                field.set('rollbackValues', null);
                this.toggleFieldPanel(null);
            },
            cancelChange: function cancelChange(field) {
                var rollbackValues = field.get('rollbackValues');

                if (rollbackValues) {
                    Object.keys(rollbackValues).forEach(function (key) {
                        if (key !== 'enumList') {
                            field.set(key, rollbackValues[key]);
                        }
                    });
                    if (field.get('enumList') && rollbackValues.enumList) {
                        field.set('enumList', rollbackValues.enumList.map(function (option) {
                            return Ember.Object.create(option);
                        }));
                    }
                } else {
                    var schema = this.get('schema'),
                        fieldType = field.get('type');

                    schema.get(fieldType).removeObject(field);
                }

                field.set('rollbackValues', null);
                this.toggleFieldPanel(null);
            },
            editField: function editField(field) {
                field.set('rollbackValues', field.toJSON());
                if (field.get('enumList')) {
                    field.set('rollbackValues.enumList', field.get('enumList').map(function (option) {
                        return JSON.parse(JSON.stringify(option));
                    }));
                }

                this.toggleFieldPanel(field);
            },
            deleteField: function deleteField(field) {
                this.toggleFieldPanel(null);
                var schema = this.get('schema'),
                    fieldType = field.get('type');

                schema.get(fieldType).removeObject(field);
            },
            toggleFieldPanel: function toggleFieldPanel(field) {
                this.toggleFieldPanel(field);
            },
            save: function save() {
                this.save(this.get('schema'));
            },
            cancel: function cancel() {
                this.cancel();
            },
            togglePreview: function togglePreview() {
                this.set('isShowingPreview', !this.get('isShowingPreview'));
                this.get('isShowingPreview') ? $('.schema-overlay').removeClass('hidden') : $('.schema-overlay').addClass('hidden');
            }
        }
    });
});