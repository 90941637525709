define('ember-custom-fields-components/mixins/limits', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        minimumLimit: null,
        maximumLimit: null,
        minItemsLimit: null,
        maxItemsLimit: null,
        minLengthLimit: null,
        maxLengthLimit: null,

        _isWithinLimit: function _isWithinLimit(valueKey, minLimitKey, maxLimitKey) {
            // converting the value passed in within the value key to a number for comparison
            // if the value passed in is null, '', or undefined the conversion will result in value = 0
            // if no limits are set then the method will return true and will ostensibly be ignored
            var value = Number(this.get(valueKey));
            var minLimit = this.get(minLimitKey);
            var maxLimit = this.get(maxLimitKey);
            return typeof minLimit !== 'number' || typeof maxLimit !== 'number' ? true : minLimit <= value && value <= maxLimit;
        },


        isMinimumWithinLimit: Ember.computed(function () {
            return this._isWithinLimit('minimum', 'minimumLimit', 'maximumLimit');
        }).volatile(),
        isMaximumWithinLimit: Ember.computed(function () {
            return this._isWithinLimit('maximum', 'minimumLimit', 'maximumLimit');
        }).volatile(),

        isMinItemsWithinLimit: Ember.computed(function () {
            return this._isWithinLimit('minItems', 'minItemsLimit', 'maxItemsLimit');
        }).volatile(),
        isMaxItemsWithinLimit: Ember.computed(function () {
            return this._isWithinLimit('maxItems', 'minItemsLimit', 'maxItemsLimit');
        }).volatile(),

        isMinLengthWithinLimit: Ember.computed(function () {
            return this._isWithinLimit('minLength', 'minLengthLimit', 'maxLengthLimit');
        }).volatile(),
        isMaxLengthWithinLimit: Ember.computed(function () {
            return this._isWithinLimit('maxLength', 'minLengthLimit', 'maxLengthLimit');
        }).volatile()
    });
});