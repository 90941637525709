define('ember-engine-custom-fields/components/schema-editor/field-configuration-editor/field-length-boundaries/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-configuration-editor/field-length-boundaries/template', 'ember-custom-fields-components/utils/field-util', 'ember-engine-custom-fields/mixins/min-max-value'], function (exports, _template, _fieldUtil, _minMaxValue) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject,
        isEmpty = Ember.isEmpty,
        getProperties = Ember.getProperties;
    exports.default = Component.extend(_minMaxValue.default, {
        layout: _template.default,
        classNames: ['field-length-boundaries'],

        limits: inject.service(),

        field: null,
        fieldType: _fieldUtil.FIELD_TYPE,
        labelKey: null,

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            if (isEmpty(this.get('field.minLength'))) {
                this.set('field.minLength', this.get('minLengthDefault'));
                this.notifyPropertyChange('field');
            }

            if (isEmpty(this.get('field.maxLength'))) {
                this.set('field.maxLength', this.get('maxLengthDefault'));
                this.notifyPropertyChange('field');
            }
        },


        minLengthDefault: computed('field.type', function () {
            return (0, _fieldUtil.fieldConfig)(this.get('field.type')).minLengthDefault;
        }),

        maxLengthDefault: computed('field.type', function () {
            return (0, _fieldUtil.fieldConfig)(this.get('field.type')).maxLengthDefault;
        }),

        label: computed('labelKey', function () {
            var intl = this.get('intl'),
                labelKey = this.get('labelKey');

            if (labelKey) {
                return intl.t(labelKey);
            }

            return intl.t('emberEngineCustomFields.fieldsPanel.fieldLengthRange');
        }),
        minMin: computed('limits.coreTypeLimits', 'field.type', function () {
            return this.get('limits.coreTypeLimits.' + this.get('field.type') + '.item.minLength.min') || this.get('limits.coreTypeLimits.' + this.get('field.type') + '.minLength.min');
        }),
        minMax: computed('limits.coreTypeLimits', 'field.type', function () {
            return this.get('limits.coreTypeLimits.' + this.get('field.type') + '.item.minLength.max') || this.get('limits.coreTypeLimits.' + this.get('field.type') + '.minLength.max');
        }),
        maxMin: computed('limits.coreTypeLimits', 'field.type', function () {
            return this.get('limits.coreTypeLimits.' + this.get('field.type') + '.item.maxLength.min') || this.get('limits.coreTypeLimits.' + this.get('field.type') + '.maxLength.min');
        }),
        maxMax: computed('limits.coreTypeLimits', 'field.type', function () {
            return this.get('limits.coreTypeLimits.' + this.get('field.type') + '.item.maxLength.max') || this.get('limits.coreTypeLimits.' + this.get('field.type') + '.maxLength.max');
        }),
        hasMinLengthErrorMessage: computed('field.{isMinLengthValid,isNewMinLengthLessRestrictive}', 'minValueWithinLimits', 'showMinValidationError', function () {
            var _getProperties = getProperties(this.get('field'), 'isMinLengthValid', 'isNewMinLengthLessRestrictive'),
                isMinLengthValid = _getProperties.isMinLengthValid,
                isNewMinLengthLessRestrictive = _getProperties.isNewMinLengthLessRestrictive;

            var minValueWithinLimits = this.get('minValueWithinLimits');

            return !isMinLengthValid || !isNewMinLengthLessRestrictive || !minValueWithinLimits;
        }),
        hasMaxLengthErrorMessage: computed('field.{isMaxLengthValid,isNewMaxLengthLessRestrictive}', 'maxValueWithinLimits', 'showMaxValidationError', function () {
            var _getProperties2 = getProperties(this.get('field'), 'isMaxLengthValid', 'isNewMaxLengthLessRestrictive'),
                isMaxLengthValid = _getProperties2.isMaxLengthValid,
                isNewMaxLengthLessRestrictive = _getProperties2.isNewMaxLengthLessRestrictive;

            var maxValueWithinLimits = this.get('maxValueWithinLimits');

            return !isMaxLengthValid || !isNewMaxLengthLessRestrictive || !maxValueWithinLimits;
        })
    });
});