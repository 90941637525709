define("ember-purecloud-components/services/interval", ["exports", "ember-purecloud-components/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend(Ember.Evented, {
    current30Minutes: '',
    scheduledIntervalChange: null,
    currentInterval: Ember.computed('current30Minutes', function () {
      if (this.get('current30Minutes')) {
        return this.get('current30Minutes');
      }

      var interval = (0, _dates.generateCurrentInterval)();
      this.set('current30Minutes', interval);
      return interval;
    }),
    lastInterval: Ember.computed('currentInterval', function () {
      var currentInterval = this.get('currentInterval');
      currentInterval = currentInterval.split('/');
      var endMoment = (0, _dates.default)(currentInterval[0]);
      var startMoment = (0, _dates.default)(endMoment).subtract(30, 'minutes');
      return "".concat(startMoment.toISOString(), "/").concat(endMoment.toISOString());
    }),
    dayIntervalBeforeCurrentIntervalStart: Ember.computed('currentInterval', function () {
      var currentInterval = this.get('currentInterval');
      currentInterval = currentInterval.split('/')[0];
      var startMoment = (0, _dates.default)(currentInterval).startOf('day');
      var endMoment = (0, _dates.default)(currentInterval);
      return "".concat(startMoment.toISOString(), "/").concat(endMoment.toISOString());
    }),

    /**
     * Interval string spanning the start of "today" til the end of the current interval
     */
    dayIntervalWithCurrentInterval: Ember.computed('currentInterval', function () {
      var currentInterval = this.get('currentInterval');

      var _currentInterval$spli = currentInterval.split('/'),
          _currentInterval$spli2 = _slicedToArray(_currentInterval$spli, 2),
          intervalStart = _currentInterval$spli2[0],
          intervalEnd = _currentInterval$spli2[1]; // note: the interval end can be on another day


      var dayStartString = (0, _dates.default)(intervalStart).startOf('day').toISOString();
      var intervalEndString = (0, _dates.default)(intervalEnd).toISOString();
      return "".concat(dayStartString, "/").concat(intervalEndString);
    }),
    thirtyDayInterval: Ember.computed('currentInterval', function () {
      var currentInterval = this.get('currentInterval');
      var endMoment = (0, _dates.default)(currentInterval.split('/')[0]).endOf('day');
      var startMoment = (0, _dates.default)(endMoment).subtract(30, 'days');
      return "".concat(startMoment.toISOString(), "/").concat(endMoment.toISOString());
    }),
    scheduleNextIntervalChange: function scheduleNextIntervalChange() {
      var _generateCurrentInter = (0, _dates.generateCurrentInterval)().split('/'),
          _generateCurrentInter2 = _slicedToArray(_generateCurrentInter, 2),
          end = _generateCurrentInter2[1];

      var timeDiff = (0, _dates.default)(end).diff((0, _dates.default)(), 'milliseconds');
      this.set('scheduledIntervalChange', Ember.run.later(this, this.changeInterval, timeDiff));
    },
    changeInterval: function changeInterval() {
      var currentInterval = (0, _dates.generateCurrentInterval)();
      this.set('current30Minutes', currentInterval);
      this.trigger('intervalChanged', currentInterval);
      this.scheduleNextIntervalChange();
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        // long running runloop tasks cause problems in acceptance and integration tests
        this.scheduleNextIntervalChange();
      }
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.get('scheduledIntervalChange'));
    }
  });

  _exports.default = _default;
});