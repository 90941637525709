define('ember-chat-components/components/chat-panel/roster/header/groups-modal/component', ['exports', 'ember', 'keystroke', 'ember-chat-components/components/chat-panel/roster/header/groups-modal/template'], function (exports, _ember, _keystroke, _template) {
    'use strict';

    exports.__esModule = true;


    let ChatGroupsModalComponent = _ember.default.Component.extend({
        layout: _template.default,
        intl: _ember.default.inject.service(),
        chat: _ember.default.inject.service(),

        tabKey: 'findPerson',
        participants: null,
        loading: false,

        didInsertElement: function () {
            this._super(...arguments);

            _ember.default.$(window).on(`keyup.${this.get('elementId')}`, e => {
                _ember.default.run(() => {
                    if (_keystroke.default.isEscape(e.keyCode)) {
                        this.send('closeModal');
                    }
                });
            });

            _ember.default.run.scheduleOnce('afterRender', this, function () {
                this.$('input').trigger('focus');
            });
        },

        willDestroyElement: function () {
            this._super(...arguments);

            const windowRef = _ember.default.$(window);
            if (windowRef) {
                windowRef.off(`.${this.get('elementId')}`);
            }
        },

        init() {
            this._super(...arguments);
            this.set('participants', []);
        },

        searchForAPerson: _ember.default.computed(function () {
            return this.get('intl').t('components.personSearch.search');
        }),

        searchForAGroup: _ember.default.computed(function () {
            return this.get('intl').t('components.groupSearch.search');
        }),

        findPersonSelected: _ember.default.computed.equal('tabKey', 'findPerson'),

        findGroupSelected: _ember.default.computed.equal('tabKey', 'findGroup'),

        startGroupSelected: _ember.default.computed.equal('tabKey', 'startGroup'),

        disableSave: _ember.default.computed.or('groupNameTooLong', 'loading'),

        groupNameTooLong: _ember.default.computed('groupValue', function () {
            return this.get('groupValue.length') > 140;
        }),

        actions: {
            createRoom() {
                this.set('loading', true);
                var groupInput = this.get('groupValue'),
                    participants = this.get('participants');

                let chat = this.get('chat');
                chat.makeNewEmptyRoom().then(room => {
                    if (!groupInput || groupInput === '') {
                        groupInput = this.get('intl').t('chat.adhocNew').toString();
                    }
                    chat.setRoomSubject(room, groupInput, () => {
                        var people = participants.filterBy('person').mapBy('person');
                        (people || []).forEach(person => {
                            this.get('chat').inviteToRoom(room, person);
                        });
                        this.clearInputs();
                        this.set('loading', false);
                        this.sendAction('close');
                    });
                });
            },
            tabSelected(tab) {
                this.set('tabKey', tab);
            },
            closeModal() {
                this.clearInputs();
                this.sendAction('close');
            },
            select(person) {
                var participants = this.get('participants'),
                    flag = false;
                var unshiftParticipants = function (person) {
                    participants.unshiftObject(_ember.default.Object.create({ person }));
                };
                if (participants.length > 0) {
                    (participants || []).forEach(participant => {
                        if (participant.person.id === person.id) {
                            return flag = true;
                        }
                    });
                    if (!flag) {
                        unshiftParticipants(person);
                    }
                } else {
                    unshiftParticipants(person);
                }
            },
            remove(person) {
                this.get('participants').removeObject(person);
            }
        },
        clearInputs() {
            this.setProperties({
                findGroupInput: '',
                findPersonInput: '',
                groupInput: '',
                topicInput: '',
                inviteInput: '',
                participants: []
            });
        }
    });

    exports.default = ChatGroupsModalComponent;
});