define('ember-wrap-urls/components/wrap-urls/link', ['exports', 'ember-wrap-urls/templates/components/wrap-urls/hyperlink'], function (exports, _hyperlink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _hyperlink.default,
    tagName: 'a',

    classNames: ['wrapped-url-link'],

    attributeBindings: ['url:href', 'name', 'target', 'rel', 'id']
  });
});