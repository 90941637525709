define("ember-webrtc-components/components/webrtc-preview/component", ["exports", "ember-purecloud-components/mixins/logger", "ember-webrtc-components/mixins/video-element-utils", "ember-webrtc-components/components/webrtc-preview/template"], function (_exports, _logger, _videoElementUtils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend(_logger.default, _videoElementUtils.default, {
    classNames: ['webrtc-preview'],
    classNameBindings: ['selected:selected', 'expanded:expanded'],
    layout: _template.default,
    hide: false,
    alwaysMuteAudio: true,
    webrtc: inject.service(),
    selected: computed.reads('participant.selected'),
    showVideoInThumbnail: computed.bool('session.features.showVideoInThumbnail'),
    expanded: computed.reads('webrtc.sidebar.communicationSidebarExpanded'),
    lowBandwidth: computed.reads('participant.lowBandwidth'),
    inLastN: computed('participant.{inLastN,positiveBitrateCount}', function () {
      return this.get('participant.inLastN') || this.get('participant.positiveBitrateCount') > 3;
    }),
    showCamera: computed('showVideoInThumbnail', 'screenStream', 'showScreenShare', 'participant.cameraStream', 'participant.major', 'participant.videoMuted', function () {
      if (!this.get('showVideoInThumbnail')) {
        return this.get('participant.cameraStream') && !this.get('participant.screenStream');
      }

      return this.get('participant.cameraStream') && !this.get('participant.videoMuted') && (!this.get('showScreenShare') || this.get('participant.major'));
    }),
    showScreenShare: computed('showVideoInThumbnail', 'participant.screenStream', 'participant.major', function () {
      if (!this.get('showVideoInThumbnail')) {
        return this.get('participant.screenStream');
      }

      return this.get('participant.screenStream') && !this.get('participant.major');
    }),
    disconnected: computed('participant.connectedState', function () {
      return this.get('participant.connectedState') === 'disconnected';
    }),
    actions: {
      selectParticipant: function selectParticipant() {
        this.get('conversation').selectParticipant(this.get('participant'));
      },
      muteParticipant: function muteParticipant() {
        this.get('conversation').muteParticipant(this.get('participant'));
      }
    }
  });

  _exports.default = _default;
});