define("ember-purecloud-components/utils/debug-rsvp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debugRsvp = debugRsvp;
  _exports.debugRsvpCreate = debugRsvpCreate;
  _exports.logPendingPromises = logPendingPromises;
  _exports.lookupPromiseStack = lookupPromiseStack;
  var promiseMap = {};

  function debugRsvpCreate(config) {
    if (config && Ember.get(config, 'rsvpDebug.showStacks')) {
      Ember.RSVP.configure('instrument-with-stack', true);
      Ember.RSVP.on('created', function (event) {
        console.log('debug-rsvp: created promise', event.id, event.stack);
      });
    }
  }

  function debugRsvp(config) {
    if (config && Ember.get(config, 'rsvpDebug.enabled')) {
      Ember.RSVP.configure('instrument', true);
      Ember.RSVP.configure('instrument-with-stack', true);
      Ember.RSVP.on('created', function (event) {
        var id = event.id;
        promiseMap[id] = event;
      });
      Ember.RSVP.on('rejected', function (event) {
        var id = event.id;
        delete promiseMap[id];
      });
      Ember.RSVP.on('fulfilled', function (event) {
        var id = event.id;
        delete promiseMap[id];
      });
    }
  }

  function lookupPromiseStack(id) {
    var event = promiseMap[id];

    if (event) {
      console.log(event.stack);
    } else {
      console.log('promise gone');
    }
  }

  function logPendingPromises() {
    var promiseList = Object.values(promiseMap).map(function (event) {
      return {
        id: event.id,
        timeStamp: event.timeStamp
      };
    });

    if (console.table && promiseList.length > 0) {
      console.log('debug-rsvp:');
      console.table(promiseList);
    }
  }
});