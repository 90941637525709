define('ember-custom-fields-components/components/custom-fields/controls/datetimepicker', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default'], function (exports, _default) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;


    var TOOLTIP_KEYS = ['today', 'clear', 'close', 'selectMonth', 'prevMonth', 'nextMonth', 'selectYear', 'prevYear', 'nextYear', 'selectDecade', 'prevDecade', 'nextDecade', 'prevCentury', 'nextCentury', 'pickHour', 'incrementHour', 'decrementHour', 'pickMinute', 'incrementMinute', 'decrementMinute', 'pickSecond', 'incrementSecond', 'decrementSecond', 'togglePeriod', 'selectTime'];

    exports.default = _default.default.extend({
        locale: alias('intl.isoLocale'),
        tooltips: Ember.computed('locale', function () {
            var intlService = this.get('intl');
            return TOOLTIP_KEYS.reduce(function (accumulator, key) {
                accumulator[key] = intlService.t('emberCustomFieldsComponents.tooltips.' + key);
                return accumulator;
            }, {});
        }),

        actions: {
            dateChanged: function dateChanged() {
                if (this.get('setCustomFieldValidation')) {
                    this.get('setCustomFieldValidation')();
                }
            }
        }
    });
});