define('ember-chat-components/components/chat-panel/roster/section/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/roster/section/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;
    exports.default = _ember.default.Component.extend({
        classNameBindings: [':roster-section', 'roster.length::hidden'],
        layout: _template.default,

        title: '',
        roster: null,
        includeCloseButton: true,
        size: 10,
        expanded: false,
        intl: _ember.default.inject.service(),

        visibleRoster: _ember.default.computed('roster.[]', 'size', function () {
            const roster = this.get('roster');
            const size = this.get('size');

            return (roster || []).filter((_, index) => index < size);
        }),

        toggleableRoster: _ember.default.computed('roster.[]', 'size', function () {
            const roster = this.get('roster');
            const size = this.get('size');

            return (roster || []).filter((_, index) => index >= size);
        }),

        visibilityLinkMessage: _ember.default.computed('expanded', 'toggleableRoster.length', function () {
            if (!this.get('expanded')) {
                return this.get('intl').t('chat.roster.showMore', {
                    count: this.get('toggleableRoster.length')
                });
            } else {
                return this.get('intl').t('chat.roster.showLess');
            }
        }),

        hiddenRoomsBadgeCount: _ember.default.computed('toggleableRoster.@each.{unseen,mentions}', 'expanded', function () {
            if (this.get('expanded')) {
                return 0;
            }

            return (this.get('toggleableRoster') || []).reduce((total, room) => {
                if (room.isOneToOneRoom()) {
                    return total + room.get('unseen');
                } else {
                    return total + room.get('mentions');
                }
            }, 0);
        }),

        hiddenSectionHasMessages: _ember.default.computed('toggleableRoster.@each.unseen', 'expanded', function () {
            return !this.get('expanded') && (this.get('toggleableRoster') || []).some(room => room.get('unseen'));
        }),

        // eslint-disable-next-line ember/no-observers
        setTogglePosition: _ember.default.observer('hiddenSectionHasMessages', 'class', function () {
            var id = `${this.get('elementId')} .toggle-visibility`;
            var type = this.get('tab');

            _ember.default.run.scheduleOnce('afterRender', this, function () {
                if (this.get('hiddenSectionHasMessages')) {
                    this.get('setRoomPosition')(id, type);
                } else {
                    this.get('setRoomPosition')(id, type, 'remove');
                }
            });
        }),

        actions: {
            selectRoom(room) {
                this.sendAction('selectRoom', room);
            },

            closeRoom(room) {
                this.sendAction('closeRoom', room);
            },

            toggleExpandedVisibility() {
                this.toggleProperty('expanded');
            }
        }
    });
});