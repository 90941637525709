define("ember-purecloud-components/mixins/conversation/social", ["exports", "twitter-text", "ember-purecloud-components/utils/conversation-states"], function (_exports, _twitterText, _conversationStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      A = Ember.A;

  var _default = Ember.Mixin.create({
    _socialReplyText: null,
    maxSocialReplyChars: 140,
    isReplyingToSocialExpression: false,
    hasRepliedToSocialExpression: false,
    // socialExpressions: computed.alias('currentUserParticipant.socialExpressions'),
    socialExpressions: computed('currentUserParticipant.socialExpressions', function () {
      var currentUserParticipant = this.get('currentUserParticipant');

      if (!currentUserParticipant) {
        return A([]);
      }

      var socialExpressions = currentUserParticipant.get('socialExpressions');
      return socialExpressions.toArray();
    }),
    activeSocialExpressions: computed('socialExpressions', function () {
      var socialExpressions = this.get('socialExpressions').toArray();
      var active = (socialExpressions || []).filter(function (expression) {
        return expression.get('state') !== _conversationStates.CONVERSATION_STATES.DISCONNECTED && expression.get('state') !== _conversationStates.CONVERSATION_STATES.TERMINATED;
      });
      return A(active);
    }),
    currentSocialExpression: computed.alias('activeSocialExpressions.firstObject'),
    maySendSocialReply: computed('currentSocialExpression.state', 'hasRepliedToSocialExpression', 'isReplyingToSocialExpression', 'isValidTweetText', function () {
      return this.get('currentSocialExpression.state') === _conversationStates.CONVERSATION_STATES.CONNECTED && !this.get('hasRepliedToSocialExpression') && !this.get('isReplyingToSocialExpression') && this.get('isValidTweetText');
    }),
    isValidTweetText: computed('_socialReplyText', function () {
      var reply = this.get('_socialReplyText');

      if (reply) {
        return _twitterText.default.isValidTweetText(reply);
      }
    }),
    userNameCharCount: computed('currentSocialExpression.userScreenName', function () {
      var userName = this.get('currentSocialExpression.userScreenName');

      if (!userName) {
        return 0;
      } // We are not directly using the following replyName as test -- only
      // to determine length. However, this does demonstrate the construction
      // we expect to be inserted within the backend service.


      var replyName = "@".concat(userName); // Technically, a reply requires the userScreenName to be inserted
      // (as constructed above) before the actual tweet test.  Therefore,
      // we are counting characters with this assumption.

      return replyName.length;
    }),
    maxSocialReplyLength: computed('userNameCharCount', 'maxSocialReplyChars', function () {
      return this.get('maxSocialReplyChars') - this.get('userNameCharCount');
    }),
    socialReplyText: computed('_socialReplyText', {
      get: function get() {
        return this.get('_socialReplyText') || '';
      },
      set: function set(key, value) {
        return this.set('_socialReplyText', value.toString().substr(0, this.get('maxSocialReplyLength')));
      }
    }),
    lastSocialExpressionInteractionTime: computed.reads('socialExpressions.firstObject.time')
  });

  _exports.default = _default;
});