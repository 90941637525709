define('ember-engine-custom-fields/controllers/data-models/contact', ['exports', 'ember-engine-custom-fields/controllers/data-models/schema'], function (exports, _schema) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _schema.default.extend({
        showListView: function showListView() {
            this.transitionToRoute('data-models.contacts');
        }
    });
});