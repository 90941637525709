define("ember-purecloud-components/models/presence", ["exports", "ember-purecloud-components/utils/intl", "lodash.isempty"], function (_exports, _intl, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    intl: Ember.inject.service(),
    presenceService: Ember.inject.service('presence'),
    session: Ember.inject.service('session'),
    presence: null,
    acdDivisionAwarePresenceToggle: Ember.computed.bool('session.features.acdDivisionAwarePresenceToggle'),
    acdDivisionPresenceProfileToggle: Ember.computed.readOnly('session.features.acdDivisionPresenceProfileToggle'),
    init: function init() {
      this._super.apply(this, arguments);

      var presence = this.get('presence');

      if (presence && !presence.systemPresence) {
        this.set('presence', this.getPresenceDefinition(presence));
      }
    },
    id: Ember.computed.reads('presence.id'),
    deactivated: Ember.computed.reads('presence.deactivated'),
    key: Ember.computed('presence.systemPresence', function () {
      return this.get('presence.systemPresence').toLowerCase();
    }),
    isSecondaryPresence: Ember.computed('id', 'presence.{primary,type}', 'presenceService.primaryPresences.@each.id', 'acdDivisionAwarePresenceToggle', 'session.features', 'acdDivisionPresenceProfileToggle', function () {
      var presenceDef = this.get('presence');

      if (this.get('acdDivisionPresenceProfileToggle') && this.get('acdDivisionAwarePresenceToggle') && this.get('session.features')['presence.enableDivisionAwareDefinitionEndpoints'] && presenceDef.hasOwnProperty('type')) {
        return presenceDef.type.toLowerCase() === 'user';
      }

      if (this.get('acdDivisionAwarePresenceToggle') && this.get('session.features')['presence.enableDivisionAwareDefinitionEndpoints'] && presenceDef.hasOwnProperty('type')) {
        return presenceDef.type === 'user';
      }

      if (presenceDef && presenceDef.hasOwnProperty('primary')) {
        return !presenceDef.primary;
      }

      var primaryIds = this.get('presenceService.primaryPresences').map(function (presence) {
        return presence.get('id');
      });
      return !Ember.A(primaryIds).includes(this.get('id'));
    }),
    label: Ember.computed('key', 'intl.locale', 'presence.languageLabels', 'isSecondaryPresence', 'acdDivisionPresenceProfileToggle', function () {
      var intl = this.get('intl');
      var isSecondary = this.get('isSecondaryPresence');
      var locale = (0, _intl.formatPresenceLanguageLabelLocale)(this.get('intl.locale.0'));
      var builtIn = this.get("presence.languageLabels.".concat(locale));

      if (this.get('acdDivisionPresenceProfileToggle') && !builtIn) {
        builtIn = this.get("presence.languageLabels.".concat(locale.toLowerCase()));
      }

      var enBuiltIn = this.get('presence.languageLabels.en_US');
      var key = this.get('key');

      if (key === 'idle') {
        // Idle always displays as 'Away'
        return intl.t('chat.presence.away').toString();
      }

      if (!builtIn) {
        // We need to default to the English provided translation
        // when dealing with a secondary presence
        if (isSecondary && enBuiltIn) {
          return enBuiltIn.toString();
        }

        key = Ember.String.camelize(key);
        var lookupKey = "chat.presence.".concat(key);

        if (intl.exists(lookupKey)) {
          return intl.t(lookupKey).toString();
        }

        return enBuiltIn.toString();
      }

      return builtIn.toString();
    }),
    primaryLabel: Ember.computed('label', 'isSecondaryPresence', 'parentPresence.label', function () {
      if (this.get('isSecondaryPresence')) {
        return this.get('parentPresence.label');
      }

      return this.get('label');
    }),
    secondaryPresences: Ember.computed('key', 'presenceService.secondaryPresences', function () {
      var secondary = this.get('presenceService.secondaryPresences');
      var key = this.get('key');

      if (Ember.get(secondary, key)) {
        return Ember.get(secondary, key).sort(function (a, b) {
          var createdAtA = new Date(Ember.get(a, 'presence.createdDate')).valueOf();
          var createdAtB = new Date(Ember.get(b, 'presence.createdDate')).valueOf();
          return createdAtA > createdAtB ? 1 : createdAtB > createdAtA ? -1 : 0;
        });
      }

      return [];
    }),
    hasSecondaryPresences: Ember.computed('presence.{primary,type}', 'secondaryPresences', 'acdDivisionAwarePresenceToggle', 'session.features', function () {
      var secondary = this.get('secondaryPresences');
      return !(0, _lodash.default)(secondary) && (this.get('presence.primary') || this.get('acdDivisionAwarePresenceToggle') && this.get('session.features')['presence.enableDivisionAwareDefinitionEndpoints'] && this.get('presence.type').toLowerCase() === 'system');
    }),
    parentPresence: Ember.computed('presence', 'isSecondaryPresence', 'presenceService.primaryPresences.[]', function () {
      var _this = this;

      var primaries = this.get('presenceService.primaryPresences');
      return primaries.find(function (primary) {
        return primary.get('presence.systemPresence') === _this.get('presence.systemPresence');
      });
    }),
    getPresenceDefinition: function getPresenceDefinition() {
      var presence = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var presenceMap = this.get('presenceService.presenceDefinitions');
      return presenceMap.find(function (mapPresence) {
        return mapPresence.id === presence.id;
      });
    }
  });

  _exports.default = _default;
});