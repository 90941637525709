define('ember-engine-custom-fields/components/schema-grid/component', ['exports', 'ember-engine-custom-fields/components/schema-grid/template', 'ember-engine-custom-fields/mixins/notification-set'], function (exports, _template, _notificationSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var A = Ember.A,
        Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Component.extend(_notificationSet.default, {
        layout: _template.default,

        classNames: ['schema-grid'],

        intl: inject.service(),
        session: inject.service(),
        store: inject.service(),
        logger: inject.service('stash-logger'),

        columnHeaders: computed('model', function () {
            var intl = this.get('intl');

            var defaultHeaders = A([{
                key: 'name',
                cellClass: 'schemaNameCell',
                header: intl.t('emberEngineCustomFields.schemaList.name'),
                component: 'schema-grid/edit-schema-link'
            }, {
                key: 'description',
                cellClass: 'schemaDescCell',
                header: intl.t('emberEngineCustomFields.schemaList.description')
            }, {
                key: 'version',
                cellClass: 'schemaVersionCell',
                header: intl.t('emberEngineCustomFields.schemaList.version'),
                width: 75
            }, {
                key: 'disable',
                cellClass: 'schemaDisableCell',
                header: this.get('enabledHeader'),
                component: 'schema-grid/schema-grid-actions/disable',
                width: 100
            }, {
                key: 'preview',
                cellClass: 'schemaPreviewCell',
                header: intl.t('emberEngineCustomFields.schemaList.preview'),
                component: 'schema-grid/schema-grid-actions/preview',
                width: 75
            }]);

            return defaultHeaders;
        }),
        isLoading: false,

        schemas: null,
        schemaType: '',
        schemaCount: computed.alias('schemas.length'),

        isShowingPreview: false,
        enabledHeader: computed(function () {
            var intl = this.get('intl');
            var enabledTooltip = '<div class="tooltips schemaDisableTooltip" tabindex="0"><div class="fa fa-question-circle"></div><div class="tooltiptextWrapper tooltip-left"><span class="tooltiptext">DISABLE_TOOLTIP</span></div></div>';
            var enabledHeader = intl.t('emberEngineCustomFields.schemaList.enabled');
            return Ember.String.htmlSafe(enabledHeader + enabledTooltip);
        }),
        schemaDisableTooltip: computed(function () {
            return this.get('intl').t('emberEngineCustomFields.tooltips.schemaDisableTooltip');
        }),
        noResults: computed(function () {
            var intl = this.get('intl');
            return intl.t('emberEngineCustomFields.common.noResults');
        }),

        init: function init() {
            this._super();

            this.addObserver('schemaCount', this, '_updateCount');
        },
        didInsertElement: function didInsertElement() {
            this._super();

            if (this.get('schemaType') === 'external-org') {
                $('.contact-schemas-tab').removeClass('active');
                $('.org-schemas-tab').addClass('active');
            } else {
                $('.org-schemas-tab').removeClass('active');
                $('.contact-schemas-tab').addClass('active');
            }

            // Because of how fixtable determines cell width, the translated string has to be manually replaced after render
            var schemaDisableTooltip = $('.schemaDisableTooltip .tooltiptext');
            schemaDisableTooltip.text(schemaDisableTooltip.text().replace('DISABLE_TOOLTIP', this.get('schemaDisableTooltip')));
        },
        willDestroy: function willDestroy() {
            this._super();
            this.removeObserver('schemaCount', this, '_updateCount');
        },
        _updateCount: function _updateCount() {
            this.send('updateRowCount');
        },


        actions: {
            updatePage: function updatePage(pageNumber, pageSize) {
                var _this = this;

                this.setProperties({
                    isLoading: true,
                    schemas: []
                });

                var store = this.get('store');

                var queryParam = {
                    pageNumber: pageNumber,
                    pageSize: pageSize
                };

                store.query(this.get('schemaType'), queryParam).then(function (schemas) {
                    _this.set('schemas', schemas);
                }).catch(function (error) {
                    var alertTitle = 'emberEngineCustomFields.notification_titles.tryLater';
                    var alertMsg = 'emberEngineCustomFields.notification.errorRetrieval';
                    _this._showNotification(alertTitle, alertMsg, { isError: true });
                    _this.get('logger').error('Unable to get schema list', {
                        remoteData: {
                            error: error.message
                        }

                    });
                    _this.get('updateServiceError')(true);
                }).finally(function () {
                    if (_this.get('isDestroyed') || _this.get('isDestroying')) {
                        return;
                    }
                    _this.set('isLoading', false);
                });
            },
            editSchema: function editSchema(id) {
                this.get('editSchema')(id);
            },
            closePreview: function closePreview() {
                this.set('isShowingPreview', false);
                $('.schema-overlay').addClass('hidden');
            },
            showPreview: function showPreview(schema) {
                this.setProperties({
                    schema: schema,
                    isShowingPreview: true
                });
                $('.schema-overlay').removeClass('hidden');
            },
            updateRowCount: function updateRowCount() {
                this.sendAction('updateRowCount', this.get('schemaCount'));
            }
        }
    });
});