define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/uservoice/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/uservoice/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const NEW_COMMENT = 'new_comment';
    const NEW_SUGGESTION = 'new_suggestion';
    const SUGGESTION_STATUS_UPDATE = 'suggestion_status_update';
    const I18N_BASE = 'chat.uservoice.';

    const UservoiceWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-uservoice'],
        layout: _template.default,

        expanded: false,

        webhooksHeader: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            let keypath;
            if (widget.notificationType === NEW_COMMENT) {
                keypath = 'newComment';
            } else if (widget.notificationType === NEW_SUGGESTION) {
                keypath = 'newSuggestion';
            } else if (widget.notificationType === SUGGESTION_STATUS_UPDATE) {
                keypath = 'suggestionStatusUpdate';
            }
            return this.get('intl').t(I18N_BASE + keypath, {
                title: widget.suggestionTitle
            });
        }),

        webhooksTitle: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            let keypath, creator;
            if (widget.notificationType === NEW_COMMENT) {
                keypath = 'newCommentTitle';
                creator = widget.commentCreatorName;
            } else if (widget.notificationType === NEW_SUGGESTION) {
                keypath = 'newSuggestionTitle';
                creator = widget.suggestionCreator;
            } else if (widget.notificationType === SUGGESTION_STATUS_UPDATE) {
                keypath = 'suggestionStatusUpdateTitle';
                creator = widget.auditorName;
            }
            return this.get('intl').t(I18N_BASE + keypath, {
                creator: creator,
                url: widget.suggestionUrl,
                title: widget.suggestionTitle,
                forumName: widget.forumName
            });
        }),

        webhooksDetails: _ember.default.computed(function () {
            const intl = this.get('intl');
            const widget = this.get('message.widget.data');
            if (widget.notificationType === NEW_SUGGESTION) {
                return intl.t(I18N_BASE + 'newSuggestionDetails', {
                    description: widget.suggestionDescription,
                    forum: widget.forumName,
                    creator: widget.suggestionCreator
                });
            } else if (widget.notificationType === NEW_COMMENT) {
                return intl.t(I18N_BASE + 'newCommentDetails', {
                    comment: widget.commentText,
                    forum: widget.forumName,
                    creator: widget.commentCreatorName
                });
            } else if (widget.notificationType === SUGGESTION_STATUS_UPDATE) {
                return intl.t(I18N_BASE + 'suggestionStatusUpdateDetails', {
                    status: widget.auditFinalStatus,
                    forum: widget.forumName,
                    auditor: widget.auditorName
                });
            }
            return '';
        }),

        actions: {
            toggleExpanded() {
                this.toggleProperty('expanded');
            }
        }
    });

    exports.default = UservoiceWidget;
});