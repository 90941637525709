define("ember-purecloud-components/models/email-communication", ["exports", "ember-data", "ember-purecloud-components/models/communication"], function (_exports, _emberData, _communication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _communication.default.extend({
    held: _emberData.default.attr(),
    subject: _emberData.default.attr(),
    messagesSent: _emberData.default.attr(),
    direction: _emberData.default.attr(),
    recordingId: _emberData.default.attr(),
    errorInfo: _emberData.default.attr(),
    disconnectType: _emberData.default.attr(),
    startHoldTime: _emberData.default.attr(),
    disconnectedTime: _emberData.default.attr(),
    autoGenerated: _emberData.default.attr(),
    scriptId: _emberData.default.attr(),
    peerId: _emberData.default.attr(),
    messageId: _emberData.default.attr(),
    update: function update(comUpdate) {
      this._super.apply(this, arguments);

      var updatableAttrs = ['held', 'subject', 'messagesSent', 'direction', 'errorInfo', 'disconnectType', 'startHoldTime', 'disconnectedTime', 'autoGenerated'];
      var updateProperties = {};

      for (var _i = 0, _updatableAttrs = updatableAttrs; _i < _updatableAttrs.length; _i++) {
        var updatable = _updatableAttrs[_i];
        var updatedValue = comUpdate[updatable];

        if (typeof updatedValue !== 'undefined') {
          updateProperties[updatable] = updatedValue;
        }
      }

      this.setProperties(updateProperties);
    }
  });

  _exports.default = _default;
});