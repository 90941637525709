define('ember-custom-fields-components/models/url', ['exports', 'ember-custom-fields-components/models/text'], function (exports, _text) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var URL = _text.default.extend({});

  exports.default = URL;
});