define('ember-engine-custom-fields/components/schema-editor/field-list/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-list/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject,
        set = Ember.set;
    exports.default = Component.extend({
        layout: _template.default,
        classNames: ['field-list'],
        limits: inject.service(),

        activeField: null,
        fields: null,
        schemaFieldLimit: computed.alias('limits.maxNumberOfFieldsPerSchema'),

        actions: {
            reorderItems: function reorderItems(group) {
                var sorted = group.get('sortedItems').mapBy('model');

                if (sorted.length) {
                    sorted.forEach(function (item, index) {
                        set(item, 'order', index);
                    });
                    this.set('fields', sorted);
                }
            }
        }
    });
});