enifed('ember-debug/index', ['exports', 'ember-debug/warn', 'ember-debug/deprecate', 'ember-debug/features', 'ember-debug/error', 'ember-debug/testing', 'ember-environment', 'ember-console', 'ember/features'], function (exports, _warn2, _deprecate2, _features, _error, _testing, _emberEnvironment, _emberConsole, _features2) {
  'use strict';

  exports._warnIfUsingStrippedFeatureFlags = exports.getDebugFunction = exports.setDebugFunction = exports.deprecateFunc = exports.runInDebug = exports.debugFreeze = exports.debugSeal = exports.deprecate = exports.debug = exports.warn = exports.info = exports.assert = exports.setTesting = exports.isTesting = exports.Error = exports.isFeatureEnabled = exports.registerDeprecationHandler = exports.registerWarnHandler = undefined;
  Object.defineProperty(exports, 'registerWarnHandler', {
    enumerable: true,
    get: function () {
      return _warn2.registerHandler;
    }
  });
  Object.defineProperty(exports, 'registerDeprecationHandler', {
    enumerable: true,
    get: function () {
      return _deprecate2.registerHandler;
    }
  });
  Object.defineProperty(exports, 'isFeatureEnabled', {
    enumerable: true,
    get: function () {
      return _features.default;
    }
  });
  Object.defineProperty(exports, 'Error', {
    enumerable: true,
    get: function () {
      return _error.default;
    }
  });
  Object.defineProperty(exports, 'isTesting', {
    enumerable: true,
    get: function () {
      return _testing.isTesting;
    }
  });
  Object.defineProperty(exports, 'setTesting', {
    enumerable: true,
    get: function () {
      return _testing.setTesting;
    }
  });
  var DEFAULT_FEATURES = _features2.DEFAULT_FEATURES,
      FEATURES = _features2.FEATURES,
      featuresWereStripped,
      isFirefox,
      isChrome;

  // These are the default production build versions:
  var assert = function () {};
  var info = function () {};
  var warn = function () {};
  var debug = function () {};
  var deprecate = function () {};
  var debugSeal = function () {};
  var debugFreeze = function () {};
  var runInDebug = function () {};

  var deprecateFunc = function () {
    return arguments[arguments.length - 1];
  };

  var setDebugFunction = function () {};
  var getDebugFunction = function () {};

  exports.setDebugFunction = setDebugFunction = function (type, callback) {
    switch (type) {
      case 'assert':
        return exports.assert = assert = callback;
      case 'info':
        return exports.info = info = callback;
      case 'warn':
        return exports.warn = warn = callback;
      case 'debug':
        return exports.debug = debug = callback;
      case 'deprecate':
        return exports.deprecate = deprecate = callback;
      case 'debugSeal':
        return exports.debugSeal = debugSeal = callback;
      case 'debugFreeze':
        return exports.debugFreeze = debugFreeze = callback;
      case 'runInDebug':
        return exports.runInDebug = runInDebug = callback;
      case 'deprecateFunc':
        return exports.deprecateFunc = deprecateFunc = callback;
    }
  };

  exports.getDebugFunction = getDebugFunction = function (type) {
    switch (type) {
      case 'assert':
        return assert;
      case 'info':
        return info;
      case 'warn':
        return warn;
      case 'debug':
        return debug;
      case 'deprecate':
        return deprecate;
      case 'debugSeal':
        return debugSeal;
      case 'debugFreeze':
        return debugFreeze;
      case 'runInDebug':
        return runInDebug;
      case 'deprecateFunc':
        return deprecateFunc;
    }
  };

  /**
  @module ember
  @submodule ember-debug
  */

  /**
  @class Ember
  @public
  */

  /**
    Define an assertion that will throw an exception if the condition is not met.
     * In a production build, this method is defined as an empty function (NOP).
    Uses of this method in Ember itself are stripped from the ember.prod.js build.
     ```javascript
    // Test for truthiness
    Ember.assert('Must pass a valid object', obj);
     // Fail unconditionally
    Ember.assert('This code path should never be run');
    ```
     @method assert
    @param {String} desc A description of the assertion. This will become
      the text of the Error thrown if the assertion fails.
    @param {Boolean} test Must be truthy for the assertion to pass. If
      falsy, an exception will be thrown.
    @public
    @since 1.0.0
  */
  setDebugFunction('assert', function (desc, test) {
    if (!test) {
      throw new _error.default('Assertion Failed: ' + desc);
    }
  });

  /**
    Display a debug notice.
     * In a production build, this method is defined as an empty function (NOP).
    Uses of this method in Ember itself are stripped from the ember.prod.js build.
     ```javascript
    Ember.debug('I\'m a debug notice!');
    ```
     @method debug
    @param {String} message A debug message to display.
    @public
  */
  setDebugFunction('debug', function (message) {
    _emberConsole.default.debug('DEBUG: ' + message);
  });

  /**
    Display an info notice.
     * In a production build, this method is defined as an empty function (NOP).
    Uses of this method in Ember itself are stripped from the ember.prod.js build.
     @method info
    @private
  */
  setDebugFunction('info', function () {
    _emberConsole.default.info.apply(undefined, arguments);
  });

  /**
    Alias an old, deprecated method with its new counterpart.
     Display a deprecation warning with the provided message and a stack trace
    (Chrome and Firefox only) when the assigned method is called.
     * In a production build, this method is defined as an empty function (NOP).
     ```javascript
    Ember.oldMethod = Ember.deprecateFunc('Please use the new, updated method', Ember.newMethod);
    ```
     @method deprecateFunc
    @param {String} message A description of the deprecation.
    @param {Object} [options] The options object for Ember.deprecate.
    @param {Function} func The new function called to replace its deprecated counterpart.
    @return {Function} A new function that wraps the original function with a deprecation warning
    @private
  */
  setDebugFunction('deprecateFunc', function () {
    var _len, args, _key, message, options, func, _message, _func;

    for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (args.length === 3) {
      message = args[0], options = args[1], func = args[2];


      return function () {
        deprecate(message, false, options);
        return func.apply(this, arguments);
      };
    } else {
      _message = args[0], _func = args[1];


      return function () {
        deprecate(_message);
        return _func.apply(this, arguments);
      };
    }
  });

  /**
    Run a function meant for debugging.
     * In a production build, this method is defined as an empty function (NOP).
    Uses of this method in Ember itself are stripped from the ember.prod.js build.
     ```javascript
    Ember.runInDebug(() => {
      Ember.Component.reopen({
        didInsertElement() {
          console.log("I'm happy");
        }
      });
    });
    ```
     @method runInDebug
    @param {Function} func The function to be executed.
    @since 1.5.0
    @public
  */
  setDebugFunction('runInDebug', function (func) {
    func();
  });

  setDebugFunction('debugSeal', function (obj) {
    Object.seal(obj);
  });

  setDebugFunction('debugFreeze', function (obj) {
    Object.freeze(obj);
  });

  setDebugFunction('deprecate', _deprecate2.default);

  setDebugFunction('warn', _warn2.default);


  var _warnIfUsingStrippedFeatureFlags = void 0;

  if (true && !(0, _testing.isTesting)()) {
    /**
       Will call `Ember.warn()` if ENABLE_OPTIONAL_FEATURES or
       any specific FEATURES flag is truthy.
        This method is called automatically in debug canary builds.
        @private
       @method _warnIfUsingStrippedFeatureFlags
       @return {void}
    */
    exports._warnIfUsingStrippedFeatureFlags = _warnIfUsingStrippedFeatureFlags = function (FEATURES, knownFeatures, featuresWereStripped) {
      var keys, i, key;

      if (featuresWereStripped) {
        warn('Ember.ENV.ENABLE_OPTIONAL_FEATURES is only available in canary builds.', !_emberEnvironment.ENV.ENABLE_OPTIONAL_FEATURES, { id: 'ember-debug.feature-flag-with-features-stripped' });

        keys = Object.keys(FEATURES || {});

        for (i = 0; i < keys.length; i++) {
          key = keys[i];

          if (key === 'isEnabled' || !(key in knownFeatures)) {
            continue;
          }

          warn('FEATURE["' + key + '"] is set as enabled, but FEATURE flags are only available in canary builds.', !FEATURES[key], { id: 'ember-debug.feature-flag-with-features-stripped' });
        }
      }
    };

    // Complain if they're using FEATURE flags in builds other than canary
    FEATURES['features-stripped-test'] = true;
    featuresWereStripped = true;


    if ((0, _features.default)('features-stripped-test')) {
      featuresWereStripped = false;
    }

    delete FEATURES['features-stripped-test'];
    _warnIfUsingStrippedFeatureFlags(_emberEnvironment.ENV.FEATURES, DEFAULT_FEATURES, featuresWereStripped);

    // Inform the developer about the Ember Inspector if not installed.
    isFirefox = _emberEnvironment.environment.isFirefox;
    isChrome = _emberEnvironment.environment.isChrome;


    if (typeof window !== 'undefined' && (isFirefox || isChrome) && window.addEventListener) {
      window.addEventListener('load', function () {
        var downloadURL;

        if (document.documentElement && document.documentElement.dataset && !document.documentElement.dataset.emberExtension) {
          downloadURL = void 0;


          if (isChrome) {
            downloadURL = 'https://chrome.google.com/webstore/detail/ember-inspector/bmdblncegkenkacieihfhpjfppoconhi';
          } else if (isFirefox) {
            downloadURL = 'https://addons.mozilla.org/en-US/firefox/addon/ember-inspector/';
          }

          debug('For more advanced debugging, install the Ember Inspector from ' + downloadURL);
        }
      }, false);
    }
  }

  exports.assert = assert;
  exports.info = info;
  exports.warn = warn;
  exports.debug = debug;
  exports.deprecate = deprecate;
  exports.debugSeal = debugSeal;
  exports.debugFreeze = debugFreeze;
  exports.runInDebug = runInDebug;
  exports.deprecateFunc = deprecateFunc;
  exports.setDebugFunction = setDebugFunction;
  exports.getDebugFunction = getDebugFunction;
  exports._warnIfUsingStrippedFeatureFlags = _warnIfUsingStrippedFeatureFlags;
});