define("ember-purecloud-components/components/logoff-link/component", ["exports", "ember-purecloud-components/components/logoff-link/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    layout: _template.default,
    classNames: ['logoff'],
    presence: inject.service(),
    entity: null,
    isOnline: computed.not('entity.isOffline'),
    isOffQueue: computed.not('entity.isOnQueue'),
    displayWarning: false,
    loggingOff: false,
    actions: {
      onProceedLogOffClick: function onProceedLogOffClick() {
        Ember.run.debounce(this, function () {
          this.get('presence').logoffUser(this.get('entity'));
          this.set('loggingOff', true);
          this.set('displayWarning', false);
        }, 500);
      },
      onLogOffClick: function onLogOffClick() {
        this.set('displayWarning', true);
      }
    }
  });

  _exports.default = _default;
});