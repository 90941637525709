define("@ember-decorators/data/index", ["exports", "ember-data", "@ember-decorators/utils/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasMany = _exports.belongsTo = _exports.attr = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function computedDecoratorWithKeyReflection(fn) {
    return (0, _computed.computedDecoratorWithParams)(function (_ref, params) {
      var key = _ref.key;
      var keyName;

      if (typeof params[0] === 'string') {
        keyName = params.shift();
      } else {
        keyName = key;
      }

      return fn.apply(void 0, [keyName].concat(_toConsumableArray(params)));
    });
  }
  /**
    Decorator that turns the property into an Ember Data attribute
  
    ```js
    export default class User extends Model {
      @attr firstName;
  
      @attr('string') lastName;
  
      @attr('number', { defaultValue: 0 })
      age;
    }
    ```
  
    @function
    @param {string} type? - Type of the attribute
    @param {object} options? - Options for the attribute
  */


  var attr = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
    return _emberData.default.attr.apply(_emberData.default, _toConsumableArray(params));
  });
  /**
    Decorator that turns the property into an Ember Data `hasMany` relationship
  
    ```js
    export default class User extends Model {
      @hasMany posts;
  
      @hasMany('user') friends;
  
      @hasMany('user', { async: false })
      followers;
    }
    ```
  
    @function
    @param {string} type? - Type of relationship
    @param {object} options? - Options for the relationship
  */

  _exports.attr = attr;
  var hasMany = computedDecoratorWithKeyReflection(_emberData.default.hasMany);
  /**
    Decorator that turns the property into an Ember Data `belongsTo` relationship
  
    ```javascript
    export default class Post extends Model {
      @belongsTo user;
  
      @belongsTo('user') editor
  
      @belongsTo('post', { async: false })
      parentPost;
    }
    ```
    @function
    @param {string} type? - Type of the relationship
    @param {object} options? - Type of the relationship
  */

  _exports.hasMany = hasMany;
  var belongsTo = computedDecoratorWithKeyReflection(_emberData.default.belongsTo);
  _exports.belongsTo = belongsTo;
});