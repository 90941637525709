define('ember-engine-custom-fields/controllers/application', ['exports', 'ember-engine-custom-fields/templates/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        Controller = Ember.Controller,
        inject = Ember.inject;
    var alias = computed.alias;
    exports.default = Controller.extend({
        layout: _application.default,
        session: inject.service(),
        adminNav: inject.service(),
        adminui: inject.service(),
        breadcrumbNav: inject.service(),
        sidebar: inject.service(),
        schemaCount: inject.service(),
        subNav: inject.service(),

        showNav: alias('subNav.showNav'),
        customFieldsNavStructure: computed('session.isAuthenticated', 'adminui.adminEntries', 'adminNav.fullNav', 'session.features', function () {
            var features = this.get('session.features');
            var fullNav = null;
            if (this.get('session.isAuthenticated') && features['adminUI.ember-dashboard']) {
                fullNav = this.get('adminNav.fullNav');
            } else {
                fullNav = this.get('adminui.adminEntries');
            }

            if (Ember.isArray(fullNav)) {
                var integrationEntries = fullNav.filter(function (entry) {
                    return entry.className === 'menu-directory';
                });
                if (integrationEntries.length > 0 && Ember.isArray(integrationEntries[0].subLinks)) {
                    return [{
                        subLinks: integrationEntries[0].subLinks.map(function (_ref) {
                            var text = _ref.text,
                                route = _ref.route,
                                href = _ref.href;

                            var entry = {
                                text: text,
                                href: href
                            };
                            if (route) {
                                if (route.endsWith('custom-fields.data-models.contacts')) {
                                    entry.route = 'data-models.contacts';
                                }
                            } else {
                                entry.externalRoute = 'admin';
                            }
                            return entry;
                        })
                    }];
                }
                return integrationEntries;
            }
            return [];
        }),
        toggleNavigationSubMenu: function toggleNavigationSubMenu() {
            this.toggleProperty('showNav');
        }
    });
});