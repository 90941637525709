define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/message/toggle-arrow/component', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;


    const { Component } = _ember.default;

    exports.default = Component.extend({
        tagName: 'i',
        classNames: ['fa'],
        classNameBindings: ['collapsed:fa-caret-right:fa-caret-down'],
        collapsed: false
    });
});