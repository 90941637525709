define("ember-webrtc-components/mixins/webrtc-volume-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin;
  var VOLUME_MAX = -55;
  var VOLUME_MIN = -15;

  var _default = Mixin.create({
    audioVolumeToPercent: function audioVolumeToPercent(volume) {
      return Math.min(Math.max((volume - VOLUME_MAX) * 100 / (VOLUME_MIN - VOLUME_MAX), 0), 100);
    },
    showVolumeBar: function showVolumeBar($el, volume) {
      if (!$el) {
        return;
      }

      if (volume <= 0) {
        $el.css('display', 'none');
      } else {
        $el.css('display', 'inherit');
        $el.height("".concat(Math.min(volume, 100), "%"));
      }
    },
    pulseVolumeBorder: function pulseVolumeBorder($el, volume) {
      if (!$el) {
        return;
      }

      $el.css('border-width', "".concat(volume, "px"));
    },
    onVolumeChange: function onVolumeChange(volume) {
      if (this.get('localParticipant.audioMuted')) {
        volume = VOLUME_MAX;
      }

      volume = this.audioVolumeToPercent(volume);
      this.showVolumeBar(this.$('.local .volume-bar'), volume);
      this.pulseVolumeBorder(this.$('.local .full-width-image'), volume);
    }
  });

  _exports.default = _default;
});