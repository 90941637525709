enifed('ember-template-compiler/system/compile-options', ['exports', 'ember-utils', 'ember-template-compiler/plugins'], function (exports, _emberUtils, _plugins) {
  'use strict';

  exports.default = function (_options) {
    var options = (0, _emberUtils.assign)({ meta: {} }, _options),
        meta,
        potententialPugins,
        pluginsToAdd;

    // move `moduleName` into `meta` property
    if (options.moduleName) {
      meta = options.meta;

      meta.moduleName = options.moduleName;
    }

    if (!options.plugins) {
      options.plugins = { ast: [].concat(USER_PLUGINS, _plugins.default) };
    } else {
      potententialPugins = [].concat(USER_PLUGINS, _plugins.default);
      pluginsToAdd = potententialPugins.filter(function (plugin) {
        return options.plugins.ast.indexOf(plugin) === -1;
      });

      options.plugins.ast = options.plugins.ast.slice().concat(pluginsToAdd);
    }

    return options;
  };
  exports.registerPlugin = function (type, PluginClass) {
    if (type !== 'ast') {
      throw new Error('Attempting to register ' + PluginClass + ' as "' + type + '" which is not a valid Glimmer plugin type.');
    }

    if (USER_PLUGINS.indexOf(PluginClass) === -1) {
      USER_PLUGINS = [PluginClass].concat(USER_PLUGINS);
    }
  };
  exports.removePlugin = function (type, PluginClass) {
    if (type !== 'ast') {
      throw new Error('Attempting to unregister ' + PluginClass + ' as "' + type + '" which is not a valid Glimmer plugin type.');
    }

    USER_PLUGINS = USER_PLUGINS.filter(function (plugin) {
      return plugin !== PluginClass;
    });
  };

  var USER_PLUGINS = [];
});