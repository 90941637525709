define("ember-purecloud-components/components/drop-down/component", ["exports", "lodash.isempty", "ember-purecloud-components/components/drop-down/template"], function (_exports, _lodash, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * Component usage:
  * When using this component, you should use a class: `menu-selector` on the element that the user clicks to display the dropdown.
  * Doing this will toggle the dropdown property and also attach a listener to the document to handle hiding the dropdown when clicking off the dropdown.
  * Please user a dropdownInfo property with a description for debugging purposes
  * {{#drop-down showDropdown=someProp dropdownInfo='template-name short description'}}
  *   <button class="menu-selector>click</button>
  *   <div {{if showDropdown 'show'}}">
  *     // drop-down content
  *   </div>
  * {{/drop-down}}
  */
  var _default = Ember.Component.extend({
    iframeState: Ember.inject.service('iframe-state'),
    classNames: ['drop-down-component'],
    classNameBindings: ['optionalClasses'],
    layout: _template.default,
    showDropdown: false,
    otherParent: null,
    dropdownInfo: null,
    _verboseLogging: false,
    // set to true to enable verbose logging for all dropdowns
    init: function init() {
      this._super.apply(this, arguments);

      this._verbose('init: initialize component');

      if (this.get('showDropdown')) {
        this._verbose('init: if showDropdown then attachWindowListener');

        this.attachWindowListener();
      }

      this.get('iframeState').setupFocusListener();
      this.onExternalIframeFocus(); // Initialize observer
    },
    willDestroyElement: function willDestroyElement() {
      this._verbose('willDestroyElement');

      this.removeWindowListener();
      var $menuSelector = this.$('.menu-selector');

      if ($menuSelector && $menuSelector.length) {
        this._verbose('remove menu-selctor listener');

        $menuSelector.off(".".concat(this.get('elementId')));
      }

      this.$(document).off(".".concat(this.get('elementId')));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var hasInfo = !(0, _lodash.default)(this.get('dropdownInfo'));

      if (!hasInfo) {
        console.warn("no dropdownInfo attached on ".concat(this.element));
      }

      this._verbose('didInsertElement: attach menu-selector listener'); // Attach an event to this drop-down's .menu-selector


      this.$('.menu-selector').on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          _this._verbose('menu-selector click event');

          _this.toggleProperty('showDropdown');

          _this.broadcastDropdownChange();

          _this.attachWindowListener(); // why preventDefault?


          event.preventDefault();
        });
      });
    },
    optionalClasses: Ember.computed(function () {
      var bindings = this.get('optionalClassNameBindings');

      if (bindings) {
        return bindings;
      }
    }),

    /**
    * Bind an event to document to close the dropdown when user clicks outside
    */
    attachWindowListener: function attachWindowListener() {
      var _this2 = this;

      var id = this.get('elementId');

      this._verbose('attach window listener');

      Ember.$(document).on("click.".concat(id), function (event) {
        Ember.run(function () {
          _this2._verbose('window click event');

          var target = Ember.$(event.target);
          var targetIsInDom = Ember.$(document).find(target).length > 0;
          var parent = target.parents("#".concat(id));
          var targetChildOfDropdown = !(0, _lodash.default)(parent);
          var targetChildOfOther = false;

          if (_this2.get('otherParent')) {
            targetChildOfOther = !(0, _lodash.default)(target.parents(_this2.get('otherParent')));
          }

          if (!targetChildOfDropdown && !targetChildOfOther && targetIsInDom) {
            _this2.close();
          }
        });
      });
    },

    /**
    * Unbind listener attached in attackWindowListener
    */
    removeWindowListener: function removeWindowListener() {
      this._verbose('remove window listener');

      Ember.$(document).off(".".concat(this.get('elementId')));
    },
    onExternalIframeFocus: Ember.observer('iframeState.focused', function () {
      if (this.get('showDropdown') && this.get('attachOutsideClick') && this.get('iframeState.focused')) {
        this.close();
      }
    }),
    close: function close() {
      this._verbose('close');

      this.set('showDropdown', false);
      this.removeWindowListener();
      this.broadcastDropdownChange();
    },
    broadcastDropdownChange: function broadcastDropdownChange() {
      if (typeof this.get('onDropdownChange') === 'function') {
        this.get('onDropdownChange')(this.get('showDropdown'));
      }
    },

    /**
    * To help with debugging. Turn on _verboseLogging to log all the stuff.
    */
    _verbose: function _verbose(msg) {
      if (this.get('_verboseLogging')) {
        var log = this.get('dropdownInfo') ? "".concat(msg, " %c").concat(this.get('elementId'), " %c").concat(this.get('dropdownInfo')) : "".concat(msg, " %c").concat(this.get('elementId'));
        console.log(log, 'font-weight: bold', 'color: green');
      }
    }
  });

  _exports.default = _default;
});