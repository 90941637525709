define('ember-engine-custom-fields/instance-initializers/ember-intl', ['exports', 'ember-engine-custom-fields/config/environment', 'ember-intl/utils/links'], function (exports, _environment, _links) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.instanceInitializer = instanceInitializer;
  var warn = Ember.warn;


  function filterBy(type) {
    return Object.keys(requirejs._eak_seen).filter(function (key) {
      return key.indexOf(_environment.default.modulePrefix + '/' + type + '/') === 0;
    });
  }

  function instanceInitializer(instance) {
    var service = void 0;

    if (typeof instance.lookup === 'function') {
      service = instance.lookup('service:intl');
    } else {
      service = instance.container.lookup('service:intl');
    }

    if (typeof Intl === 'undefined') {
      warn('[ember-intl] Intl API is unavailable in this environment.\nSee: ' + _links.default.polyfill, false, {
        id: 'ember-intl-undefined-intljs'
      });
    }

    var cldrs = filterBy('cldrs');

    if (!cldrs.length) {
      warn('[ember-intl] project is missing CLDR data\nIf you are asynchronously loading translation, see: ${links.asyncTranslations}.', false, {
        id: 'ember-intl-missing-cldr-data'
      });
    } else {
      cldrs.map(function (moduleName) {
        return requirejs(moduleName, null, null, true)['default'];
      }).forEach(function (locale) {
        return locale.forEach(service.addLocaleData);
      });
    }

    filterBy('translations').forEach(function (moduleName) {
      var localeSplit = moduleName.split('\/');
      var localeName = localeSplit[localeSplit.length - 1];
      service.addTranslations(localeName, requirejs(moduleName, null, null, true)['default']);
    });
  }

  exports.default = {
    name: 'ember-intl',
    initialize: instanceInitializer
  };
});