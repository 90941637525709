define('ember-concurrency-test-waiter/with-test-waiter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = withTestWaiter;


  var registered = false;
  var taskRunCounter = 0;

  // A function that, given a task property, will register it with the test
  // waiter so async test helpers will block anytime a task instance spawned
  // from that property is running.
  function withTestWaiter(taskProperty) {
    (false && !(taskProperty.taskFn) && Ember.assert("withTestWaiter() will only work with ember-concurrency >=0.7.19 -- please upgrade", taskProperty.taskFn));


    var originalTaskFn = taskProperty.taskFn;

    taskProperty.taskFn = regeneratorRuntime.mark(function _callee() {
      var _len,
          args,
          _key,
          _args = arguments;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (Ember.testing && !registered) {
                Ember.Test.registerWaiter(function () {
                  return taskRunCounter === 0;
                });
                registered = true;
              }

              taskRunCounter += 1;
              _context.prev = 2;

              for (_len = _args.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = _args[_key];
              }

              return _context.delegateYield(originalTaskFn.apply(this, args), 't0', 5);

            case 5:
              return _context.abrupt('return', _context.t0);

            case 6:
              _context.prev = 6;

              taskRunCounter -= 1;
              return _context.finish(6);

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2,, 6, 9]]);
    });
    return taskProperty;
  }
});