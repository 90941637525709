define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/jenkins/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/jenkins/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const I18N_BASE = 'chat.jenkins.';

    const JenkinsWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-jenkins'],
        layout: _template.default,

        expanded: false,

        webhooksStatus: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return widget.buildStatus.toLowerCase();
        }),

        webhooksHeader: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            let keypath;
            let jobName = '';
            if (widget.jobName) {
                jobName = widget.jobName.replace(/-/g, ' ').capitalize();
            }
            if (widget.notificationType === 'FINALIZED') {
                if (widget.buildStatus === 'SUCCESS') {
                    keypath = 'buildSuccess';
                } else {
                    keypath = 'buildFailure';
                }
            } else if (widget.notificationType === 'STARTED') {
                keypath = 'buildStarted';
            }
            return _ember.default.String.htmlSafe(this.get('intl').t(I18N_BASE + keypath, {
                buildNumber: widget.buildNumber,
                jobName: jobName,
                buildUrl: widget.buildUrl
            }).toString());
        })
    });

    exports.default = JenkinsWidget;
});