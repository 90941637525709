define("ember-purecloud-components/utils/browser", ["exports", "browserama"], function (_exports, _browserama) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mimeTypes = {
    mp3: 'audio/mp3',
    webm: 'audio/webm',
    wav: 'audio/wav'
  };

  var mimeTypeToFormat = function mimeTypeToFormat(type) {
    if (typeof type === 'string') {
      var parts = type.split('/');
      return parts.length && parts[1] ? parts[1] : type;
    }
  };

  var formatToAudioMimeType = function formatToAudioMimeType(format) {
    if (typeof format === 'string') {
      return "audio/".concat(format);
    }
  };

  var probeAudioTypeCompatibilityFrom = function probeAudioTypeCompatibilityFrom(types) {
    var suggested;
    var audioElement = document.createElement('audio');

    if (audioElement && audioElement.canPlayType) {
      if (types.includes(mimeTypes.mp3) && audioElement.canPlayType(mimeTypes.mp3)) {
        suggested = mimeTypes.mp3;
      } else if (types.includes(mimeTypes.webm) && !_browserama.default.isFirefox && audioElement.canPlayType(mimeTypes.webm)) {
        // Need a browser check here because Firefox 'supports' webm, but
        // the seeking doesn't work due to missing cues in webm audio spec.
        // https://bugzilla.mozilla.org/show_bug.cgi?id=657791
        // Chrome and Chromium. Also, IE if the codec is installed
        suggested = mimeTypes.webm;
      } else if (types.includes(mimeTypes.wav) && audioElement.canPlayType(mimeTypes.wav)) {
        // Firefox and Safari
        suggested = mimeTypes.wav;
      }
    }

    audioElement = null;
    return suggested;
  };

  var mimeTypeKeys = Object.keys(mimeTypes);
  var mimeTypeValues = mimeTypeKeys.map(function (k) {
    return mimeTypes[k];
  });
  var shouldInstallWebM = false;
  var suggestedAudioType = probeAudioTypeCompatibilityFrom(mimeTypeValues);

  if (!suggestedAudioType) {
    shouldInstallWebM = true;
  }

  var _default = Object.assign({}, _browserama.default, {
    getSuggestedAudioTypeFrom: function getSuggestedAudioTypeFrom(desiredFormats) {
      if (Array.isArray(desiredFormats) && desiredFormats.length) {
        var desiredMimeTypes = desiredFormats.map(function (format) {
          if (typeof format === 'string') {
            return formatToAudioMimeType(format);
          } else {
            return format;
          }
        });
        var suggestedMimeType = probeAudioTypeCompatibilityFrom(desiredMimeTypes);

        if (suggestedMimeType) {
          return mimeTypeToFormat(suggestedMimeType);
        }
      }
    },

    get suggestedAudioType() {
      return suggestedAudioType;
    },

    get shouldInstallWebM() {
      return shouldInstallWebM;
    }

  });

  _exports.default = _default;
});