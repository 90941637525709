define("ember-purecloud-components/components/entity-v2/location/component", ["exports", "ember-purecloud-components/components/entity-v2/location/template", "ember-purecloud-components/utils/display-geolocation"], function (_exports, _template, _displayGeolocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['entity-v2-location'],
    attributeBindings: ['hoverText:title'],

    /**
       @type object
       @desc Object representing the entity
    */
    entity: null,

    /**
       @type object
       @desc Object representing the entity who is currently logged-in
    */
    self: null,
    locationClass: '',
    locationHoverText: '',
    noLocationHoverText: '',
    isGeolocationEnabled: false,
    hoverText: Ember.computed('locationHoverText', 'noLocationHoverText', 'isGeolocationEnabled', function () {
      if (this.get('isGeolocationEnabled')) {
        return this.get('entity.geolocation') ? this.get('locationHoverText') : this.get('noLocationHoverText');
      }

      return '';
    }),
    location: Ember.computed('entity.geolocation', function () {
      return (0, _displayGeolocation.default)(this.get('entity'), this.get('self'));
    })
  });

  _exports.default = _default;
});