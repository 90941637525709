define('ember-user-activity/services/scroll-activity', ['exports', 'ember-user-activity/mixins/fastboot-compat', 'ember-user-activity/utils/get-scroll-top', 'ember-user-activity/utils/get-scroll-left'], function (exports, _fastbootCompat, _getScrollTop, _getScrollLeft) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /*
   * Polling uses rAF and/or a setTimeout at 16ms, however rAF will run in the
   * microtask queue and might fire just after Ember's render step occurred.
   * By enforcing that the interval between a poll and the previous must be
   * below a reasonable number, we can be reasonably sure the main UI
   * thread didn't just do a lot of work.
   *
   * This number show be above the minimum polling period (16ms)
   */
  var MAX_POLL_PERIOD = 32;
  var SCROLL_EVENT_TYPE_VERTICAL = 'vertical';
  var SCROLL_EVENT_TYPE_HORIZONTAL = 'horizontal';
  var SCROLL_EVENT_TYPE_DIAGONAL = 'diagonal';

  exports.default = Ember.Service.extend(Ember.Evented, _fastbootCompat.default, {
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('_isFastBoot')) {
        return;
      }

      this._animationFrame = null;
      this._subscribers = [];
      this._lastCheckAt = new Date();
      this.subscribe(document, document, function () {}, false);

      this._pollScroll();
    },
    subscribe: function subscribe(target, element) {
      var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
      var highPriority = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      this._subscribers.push({
        target: target,
        element: element,
        callback: callback,
        highPriority: highPriority,
        scrollTop: null,
        scrollLeft: null
      });
    },
    unsubscribe: function unsubscribe(target) {
      var subscribers = this._subscribers;

      for (var i = 0; i < subscribers.length; i++) {
        var subscriber = subscribers[i];
        if (subscriber.target === target) {
          subscribers.splice(i, 1);
          break;
        }
      }
    },
    _pollScroll: function _pollScroll() {
      var _this = this;

      if (this.get('_isFastBoot')) {
        return;
      }
      if (window.requestAnimationFrame) {
        this._animationFrame = requestAnimationFrame(function () {
          return _this._checkScroll();
        });
      } else {
        this._animationFrame = setTimeout(function () {
          return _this._checkScroll();
        }, 16);
      }
    },
    _checkScroll: function _checkScroll() {
      var subscribers = this._subscribers,
          lastCheckAt = this._lastCheckAt;

      var now = new Date();
      if (subscribers.length) {
        var lowPriorityFrame = now - lastCheckAt < MAX_POLL_PERIOD;
        var hasScrolled = false;
        for (var i = 0; i < subscribers.length; i++) {
          var subscriber = subscribers[i];
          if (subscriber.highPriority || lowPriorityFrame) {
            var scrollTop = (0, _getScrollTop.default)(subscriber.element);
            var scrollLeft = (0, _getScrollLeft.default)(subscriber.element);
            if (scrollTop !== subscriber.scrollTop && scrollLeft !== subscriber.scrollLeft) {
              // If the values are changing from an initial null state to first
              // time values, do not treat it like a change.
              if (subscriber.scrollTop !== null && subscriber.scrollLeft !== null) {
                if (!hasScrolled) {
                  Ember.run.begin();
                  hasScrolled = true;
                }
                subscriber.callback(scrollTop, subscriber.scrollTop, SCROLL_EVENT_TYPE_DIAGONAL, scrollLeft, subscriber.scrollLeft);
              }
              subscriber.scrollTop = scrollTop;
              subscriber.scrollLeft = scrollLeft;
            } else if (scrollTop !== subscriber.scrollTop) {
              // If the value is changing from an initial null state to a first
              // time value, do not treat it like a change.
              if (subscriber.scrollTop !== null) {
                if (!hasScrolled) {
                  Ember.run.begin();
                  hasScrolled = true;
                }
                subscriber.callback(scrollTop, subscriber.scrollTop, SCROLL_EVENT_TYPE_VERTICAL);
              }
              subscriber.scrollTop = scrollTop;
              subscriber.scrollLeft = scrollLeft;
            } else if (scrollLeft !== subscriber.scrollLeft) {
              // If the value is changing from an initial null state to a first
              // time value, do not treat it like a change.
              if (subscriber.scrollLeft !== null) {
                if (!hasScrolled) {
                  Ember.run.begin();
                  hasScrolled = true;
                }
                subscriber.callback(scrollLeft, subscriber.scrollLeft, SCROLL_EVENT_TYPE_HORIZONTAL);
              }
              subscriber.scrollTop = scrollTop;
              subscriber.scrollLeft = scrollLeft;
            }
          }
        }
        if (hasScrolled) {
          this.trigger('scroll');
          Ember.run.end();
        }
      }
      this._lastCheckAt = now;
      this._pollScroll();
    },
    willDestroy: function willDestroy() {
      if (this.get('_isFastBoot')) {
        return;
      }
      if (window.requestAnimationFrame) {
        cancelAnimationFrame(this._animationFrame);
      } else {
        clearTimeout(this._animationFrame);
      }
      this._subscribers.length = 0;

      this._super.apply(this, arguments);
    }
  });
});