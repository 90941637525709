define('ember-custom-fields-components/components/custom-fields/controls/checkbox/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/default', 'ember-custom-fields-components/components/custom-fields/controls/checkbox/template'], function (exports, _default, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _default.default.extend({
        layout: _template.default,
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey);

            if (!Ember.isBlank(savedData)) {
                this.set('control.value', savedData);
            }
        },


        actions: {
            checkboxClicked: function checkboxClicked() {
                if (this.get('setCustomFieldValidation')) {
                    this.get('setCustomFieldValidation')();
                }
            }
        }
    });
});