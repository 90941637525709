define("ember-nav-components/components/pager-controls/item-counter/component", ["exports", "ember-nav-components/components/pager-controls/item-counter/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['item-counter'],
    // Properties for binding
    firstItem: 0,
    lastItem: 0,
    numItems: 0,
    label: '',
    action: null,
    enableClick: false,
    // The last item cannot be greater than the total number of items
    computedLastItem: Ember.computed('lastItem', 'numItems', function () {
      var numItems = this.get('numItems');
      var lastItem = this.get('lastItem');
      return numItems ? Math.min(lastItem, numItems) : lastItem;
    }),
    actions: {
      invoke: function invoke() {
        var action = this.get('action');

        if (typeof action === 'function' && this.get('enableClick')) {
          action();
        }
      }
    }
  });

  _exports.default = _default;
});