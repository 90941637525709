define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/video-notification/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/video-notification/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;

    const { Component, computed, inject } = _ember.default;

    exports.default = Component.extend({
        classNames: ['video-notification-widget'],
        classNameBindings: ['iconClass'],
        layout: _template.default,

        intl: inject.service(),

        supportedTypes: null,
        notificationType: computed.reads('message.widget.data.type'),

        init() {
            this._super(...arguments);
            this.set('supportedTypes', ['request', 'accept', 'join', 'reject', 'noAnswer', 'ended', 'left']);
        },

        iconClass: computed('notificationType', function () {
            const type = this.get('notificationType');
            const types = this.get('supportedTypes');

            return (types || []).includes(type) ? type : '';
        }),

        displayText: computed('notificationType', function () {
            const intl = this.get('intl');
            const type = this.get('notificationType');
            const types = this.get('supportedTypes');

            return (types || []).includes(type) ? intl.t(`chat.video.chatNotifications.${type}`) : '';
        })
    });
});