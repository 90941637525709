enifed('ember-template-compiler/plugins/transform-attrs-into-args', ['exports'], function (exports) {
  'use strict';

  exports.default = TransformAttrsToProps;
  /**
   @module ember
   @submodule ember-glimmer
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{attrs.foo.bar}}
    ```
  
    to
  
    ```handlebars
   {{@foo.bar}}
    ```
  
    as well as `{{#if attrs.foo}}`, `{{deeply (nested attrs.foobar.baz)}}` etc
  
    @private
    @class TransformAttrsToProps
  */

  function TransformAttrsToProps() {
    // set later within Glimmer2 to the syntax package
    this.syntax = null;
  }

  function isAttrs(node, symbols) {
    var name = node.parts[0];

    if (symbols.indexOf(name) !== -1) {
      return false;
    }

    if (name === 'attrs') {
      return true;
    }

    if (name === null && node.parts[1] === 'attrs') {
      node.parts.shift();
      node.original = node.original.slice(5);
      return true;
    }

    return false;
  }

  /**
    @private
    @method transform
    @param {AST} ast The AST to be transformed.
  */
  TransformAttrsToProps.prototype.transform = function (ast) {
    var _syntax = this.syntax,
        traverse = _syntax.traverse,
        b = _syntax.builders;

    var stack = [[]];

    traverse(ast, {
      Program: {
        enter: function (node) {
          var parent = stack[stack.length - 1];
          stack.push(parent.concat(node.blockParams));
        },
        exit: function () {
          stack.pop();
        }
      },

      PathExpression: function (node) {
        var path;

        if (isAttrs(node, stack[stack.length - 1])) {
          path = b.path(node.original.substr(6));

          path.original = '@' + path.original;
          path.data = true;
          return path;
        }
      }
    });

    return ast;
  };
});