define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/github-repo/component', ['exports', 'ember', 'ember-data', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/github-repo/template'], function (exports, _ember, _emberData, _template) {
    'use strict';

    exports.__esModule = true;


    const { RSVP, Component } = _ember.default;

    exports.default = Component.extend({
        mediaItem: null,
        layout: _template.default,

        init() {
            this._super(...arguments);

            let data = this.get('mediaItem.data');
            if (data) {
                return data;
            }

            const promise = new RSVP.Promise((resolve, reject) => {
                this.getRepoData().then(this.getContents.bind(this)).then(this.parseContent.bind(this)).then(resolve).catch(() => reject(''));
            });

            const promiseObject = _emberData.default.PromiseObject.create({
                promise
            });

            this.set('mediaItem.data', promiseObject);
        },

        getRepoData() {
            let data = _ember.default.$.getJSON(this.get('mediaItem.url'), data => {
                return data;
            });

            return RSVP.cast(data);
        },

        getContents(repoData) {
            if (this.get('mediaItem.isIssue')) {
                const issueNumber = this.get('mediaItem.issueNumber');
                const issuesUrl = repoData.issues_url.replace('{/number}', `/${issueNumber}`);
                return _ember.default.$.getJSON(issuesUrl);
            } else {
                const contentsUrl = repoData.contents_url.replace('{+path}', '');
                return _ember.default.$.getJSON(contentsUrl).then(contents => {
                    const readme = (contents || []).find(content => content.name.toLowerCase().includes('readme'));
                    if (readme && readme.git_url) {
                        return _ember.default.$.getJSON(readme.git_url);
                    }
                    return '';
                });
            }
        },

        parseContent(content) {
            if (this.get('mediaItem.isIssue')) {
                return [`## ${content.title}`, content.body].join('\n***\n');
            } else {
                if (content && content.content && content.encoding === 'base64') {
                    return window.atob(content.content);
                } else {
                    return '';
                }
            }
        }
    });
});