define("ember-webrtc-components/components/profile-selection/component", ["exports", "ember-purecloud-components/mixins/logger", "ember-webrtc-components/components/profile-selection/template", "web-directory/utils/uuid", "ember-webrtc-components/mixins/webrtc-service/auto-save-devices"], function (_exports, _logger, _template, _uuid, _autoSaveDevices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Component.extend(_logger.default, _autoSaveDevices.default, {
    layout: _template.default,
    classNameBindings: [':profile-selection'],
    webrtc: inject.service(),
    intl: inject.service(),
    profileCopy: {},
    showEditPart: false,
    audio: true,
    isReadOnly: false,
    video: true,
    troubleshoot: true,
    outputDevice: true,
    resolution: true,
    savedProfiles: computed.reads('webrtc.savedProfiles'),
    selectedProfile: computed.reads('webrtc.selectedProfile'),
    audioCallCapable: computed.reads('webrtc.audioCallCapable'),
    videoCallCapable: computed.reads('webrtc.videoCallCapable'),
    showOutputDevicePicker: computed.and('outputDevice', 'audio'),
    showResolutionPicker: computed.and('webrtc.resolutionList.length', 'webrtc.cameraList.length', 'video', 'resolution'),
    selectedProfileName: computed('selectedProfile.name', function () {
      return this.getWithDefault('selectedProfile.name', this.get('intl').t('webrtcDevices.useComputerSettings'));
    }),
    webrtcTroubleshooterIframe: computed.reads('session.features.webrtcTroubleshooterIframe'),
    canEditProfile: computed('selectedProfile.id', function () {
      return !!this.get('selectedProfile.id');
    }),
    defaultConfig: computed('webrtc.cameraList', 'webrtc.microphoneList', 'webrtc.outputDeviceList', 'webrtc.resolutionList', function () {
      return {
        camera: this.get('webrtc.cameraList').findBy('deviceId', 'default') || this.get('webrtc.cameraList')[0],
        microphone: this.get('webrtc.microphoneList').findBy('deviceId', 'default') || this.get('webrtc.microphoneList')[0],
        outputDevice: this.get('webrtc.outputDeviceList').findBy('deviceId', 'default') || this.get('webrtc.outputDeviceList')[0],
        notificationDevice: this.get('webrtc.outputDeviceList').findBy('deviceId', 'default') || this.get('webrtc.outputDeviceList')[0],
        resolution: this.get('webrtc.resolutionList').findBy('presetId', 3),
        validWithoutCamera: false
      };
    }),
    showTroubleshoot: computed('troubleshoot', function () {
      return this.get('troubleshoot') && typeof this.attrs.openTroubleshoot === 'function';
    }),
    nameInputError: computed.not('profileCopy.name'),
    showNotificationOutputDevicePicker: computed.reads('webrtc.session.features.notificationOutputPicker'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('webrtc').enumerateDevices();
      this.get('webrtc').enumerateResolutions();
      this.get('webrtc').on('showProfileSelection', function () {
        return _this.send('createNewProfile');
      });
      var recentlyAddedDevices = this.get('webrtc.recentlyConnectedDevices');

      if (recentlyAddedDevices) {
        Ember.run.later(this, this.actions.createNewProfile);
      }
    },
    // eslint-disable-next-line ember/no-observers
    _onShowEditPartChanged: observer('showEditPart', function () {
      var _this2 = this;

      setTimeout(function () {
        if (_this2.get('showEditPart')) {
          _this2.$('input:first').focus();
        } else {
          _this2.$('button:first').focus();
        }
      });
    }),
    actions: {
      openTroubleshoot: function openTroubleshoot() {
        if (typeof this.attrs.openTroubleshoot === 'function') {
          this.attrs.openTroubleshoot();
        }
      },
      showEditProfile: function showEditProfile() {
        this.set('profileCopy', Object.assign({}, this.get('selectedProfile')));
        this.set('showEditPart', true);
      },
      cancelProfileEdit: function cancelProfileEdit() {
        this.set('showEditPart', false);
      },
      saveProfileEdit: function saveProfileEdit() {
        if (this.get('nameInputError')) {
          return;
        }

        this.get('webrtc').saveAndSelectProfile(this.get('profileCopy'));
        this.set('showEditPart', false);
      },
      useComputerSettings: function useComputerSettings() {
        var _this3 = this;

        this.get('webrtc').setComputerDefaults();
        setTimeout(function () {
          _this3.$('button:first').focus();
        });
      },
      createNewProfile: function createNewProfile() {
        var deviceConfig = this.get('defaultConfig');
        var recentlyAddedDevices = this.get('webrtc.recentlyConnectedDevices');

        if (recentlyAddedDevices) {
          if (recentlyAddedDevices.camera) {
            deviceConfig.camera = recentlyAddedDevices.camera;
          }

          if (recentlyAddedDevices.microphone) {
            deviceConfig.microphone = recentlyAddedDevices.microphone;
          }

          if (recentlyAddedDevices.outputDevice) {
            deviceConfig.outputDevice = recentlyAddedDevices.outputDevice;
            deviceConfig.notificationDevice = recentlyAddedDevices.outputDevice;
          }

          this.get('webrtc').clearRecentlyConnectedDevices();
        }

        this.set('profileCopy', Object.assign({
          id: _uuid.default.generate()
        }, deviceConfig));
        this.set('showEditPart', true);
      },
      deleteProfile: function deleteProfile(profileId) {
        this.get('webrtc').deleteProfile(profileId);
      },
      changeProfile: function changeProfile(profile) {
        var _this4 = this;

        if (!profile.canBeSelected) {
          return;
        }

        this.get('webrtc').changeProfile(profile.id);
        setTimeout(function () {
          _this4.$('button:first').focus();
        });
      },
      changeCamera: function changeCamera(event) {
        this.set('profileCopy.camera', this.get('webrtc.cameraList').findBy('deviceId', event.target.value));
      },
      changeMicrophone: function changeMicrophone(event) {
        this.set('profileCopy.microphone', this.get('webrtc.microphoneList').findBy('deviceId', event.target.value));
      },
      changeOutputDevice: function changeOutputDevice(event) {
        this.set('profileCopy.outputDevice', this.get('webrtc.outputDeviceList').findBy('deviceId', event.target.value));

        if (!this.get('showNotificationOutputDevicePicker')) {
          this.set('profileCopy.notificationDevice', this.get('webrtc.outputDeviceList').findBy('deviceId', event.target.value));
        }
      },
      changeNotificationDevice: function changeNotificationDevice(event) {
        this.set('profileCopy.notificationDevice', this.get('webrtc.outputDeviceList').findBy('deviceId', event.target.value));
      },
      changeResolution: function changeResolution(event) {
        this.set('profileCopy.resolution', this.get('webrtc.resolutionList').findBy('presetId', event.target.value));
      },
      openSoundPreferences: function openSoundPreferences() {
        this.get('webrtc.settings').openSoundPreference();
      }
    }
  });

  _exports.default = _default;
});