define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/pagerduty/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/pagerduty/template', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component'], function (exports, _ember, _template, _baseWidgetComponent) {
    'use strict';

    exports.__esModule = true;


    const I18N_BASE = 'chat.pagerduty.';

    const PagerDutyWidget = _baseWidgetComponent.default.extend({
        classNames: ['widgets-pagerduty'],
        layout: _template.default,

        expanded: false,

        webhooksStatus: _ember.default.computed(function () {
            return this.get('message.widget.data.incidentStatus');
        }),

        webhooksHeader: _ember.default.computed(function () {
            const widget = this.get('message.widget.data');
            return this.get('intl').t(I18N_BASE + 'incident', {
                incidentId: widget.incidentId,
                incidentUrl: widget.incidentUrl,
                incidentStatus: widget.incidentStatus,
                service: widget.service,
                serviceUrl: widget.serviceUrl
            });
        })
    });

    exports.default = PagerDutyWidget;
});