define('ember-engine-custom-fields/routes/data-models', ['exports', 'ember-engine-custom-fields/mixins/notification-set'], function (exports, _notificationSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject,
        Route = Ember.Route;
    exports.default = Route.extend(_notificationSet.default, {
        intl: inject.service(),
        permissions: inject.service(),
        session: inject.service(),

        isAuthorized: computed.or('permissions.hasExternalContactsAddSchemaAccess', 'permissions.hasExternalContactsEditSchemaAccess', 'permissions.hasExternalContactsViewSchemaAccess'),

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);

            if (transition.isActive) {
                // User is authenticated but doesn't have correct permissions
                if (this.get('session.isAuthenticated') && !this.get('isAuthorized')) {
                    this._showNotification(null, this.get('intl').t('emberEngineCustomFields.auth.noFeaturePermissions'), { isError: true });
                    transition.abort();
                }
                // Feature flag is not active for this org
                if (!this.get('session.features.relateDynamicSchema')) {
                    transition.abort();
                    this.transitionToExternal('personDetail', this.get('session.person.id'));
                }
            }
        }
    });
});