define("ember-purecloud-components/services/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLOCK_TICK = 1000;

  var _default = Ember.Service.extend(Ember.Evented, {
    second: null,
    minute: null,
    halfHour: null,
    hour: null,
    day: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_previousTick', performance.now());

      this._pendulum(false);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this.get('runId'));
    },
    _pendulum: function _pendulum() {
      var triggerEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var thisTick = performance.now(); // Trigger clockTick event with elapsed time since last tick

      if (triggerEvent) {
        this.trigger('clockTick', thisTick - this.get('_previousTick'));
      }

      this.set('_previousTick', thisTick);

      this._tick(new Date());

      if (!Ember.testing) {
        var runId = Ember.run.later(this, this._pendulum, CLOCK_TICK);
        this.set('runId', runId);
      }
    },
    _tick: function _tick(now) {
      (false && !(now !== null) && Ember.assert('a clock _tick takes a date parameter', now !== null));
      var minute = now.getMinutes();
      this.setProperties({
        second: now.getSeconds(),
        minute: minute,
        halfHour: 60 - minute > 30,
        hour: now.getHours(),
        day: now.getDay()
      });
    }
  });

  _exports.default = _default;
});