define('ember-engine-custom-fields/components/schema-grid/footer/component', ['exports', 'ember-engine-custom-fields/components/schema-grid/footer/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Ember.Component.extend({
        layout: _template.default,
        intl: inject.service(),
        limits: inject.service(),
        schemaCount: inject.service(),

        schemaLimit: computed.reads('limits.maxNumberOfSchemasPerOrg'),
        schemaTotal: computed.reads('schemaCount.schemasTotal')
    });
});