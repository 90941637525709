enifed('ember-template-compiler/plugins/assert-reserved-named-arguments', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = AssertReservedNamedArguments;
  function AssertReservedNamedArguments(options) {
    this.syntax = null;
    this.options = options;
  }

  AssertReservedNamedArguments.prototype.transform = function (ast) {
    var moduleName = this.options.meta.moduleName;

    this.syntax.traverse(ast, {
      PathExpression: function (node) {
        if (node.original[0] === '@') {
          true && !false && (0, _emberDebug.assert)(assertMessage(moduleName, node));
        }
      }
    });

    return ast;
  };

  function assertMessage(moduleName, node) {
    var path = node.original;
    var source = (0, _calculateLocationDisplay.default)(moduleName, node.loc);

    return '\'' + path + '\' is not a valid path. ' + source;
  }
});