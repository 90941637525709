define("ember-purecloud-components/utils/net", ["exports", "ember-copy", "lodash.forown"], function (_exports, _emberCopy, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROD_REGIONS = void 0;
  _exports.getEnvCookieDomain = getEnvCookieDomain;
  _exports.getEnvDetails = getEnvDetails;
  _exports.getEnvTld = getEnvTld;
  _exports.isExternalDomain = isExternalDomain;
  var HOSTNAME_EXTRACTOR = /^\s*(?:[^:]+:\/\/)?(localhost|(?:[\w\d\-]+\.)+[\w\d]{2,})(?:[:\/?#].*)?\s*$/; //eslint-disable-line

  var LEGACY_TLD_CHECK_REG_EXP = /^.*(inin[dts]ca|mypurecloud).+/i; // eslint-disable-line

  var LOCALHOST_HOSTNAMES = ['localhost', 'localhost.mypurecloud.com'];
  var SIMPLE_ENVS = ['dca', 'tca', 'sca', 'pca'];
  var MODIFIER_MAP = {
    '-beta': 'beta'
  };
  var _PC_DEPLOYMENTS = [{
    id: 'dca',
    env: 'dca',
    envTld: 'inindca.com' // No translationKey needed; will not be exported

  }, {
    id: 'tca',
    env: 'tca',
    envTld: 'inintca.com' // No translationKey needed; will not be exported

  }, {
    id: 'sca',
    env: 'sca',
    envTld: 'ininsca.com' // No translationKey needed; will not be exported

  }, {
    id: 'us',
    env: 'pca',
    desktopId: 'global',
    envTld: 'mypurecloud.com',
    translationKey: 'components.region.global'
  }, {
    id: 'au',
    env: 'pca',
    envTld: 'mypurecloud.com.au',
    translationKey: 'components.region.australia'
  }, {
    id: 'ie',
    env: 'pca',
    envTld: 'mypurecloud.ie',
    translationKey: 'components.region.ireland'
  }, {
    id: 'jp',
    env: 'pca',
    envTld: 'mypurecloud.jp',
    translationKey: 'components.region.japan'
  }, {
    id: 'de',
    env: 'pca',
    envTld: 'mypurecloud.de',
    translationKey: 'components.region.germany'
  }, {
    id: 'usw2',
    env: 'pca',
    envTld: 'usw2.pure.cloud',
    translationKey: 'components.region.usw2'
  }, {
    id: 'euw2',
    env: 'pca',
    envTld: 'euw2.pure.cloud',
    translationKey: 'components.region.euw2'
  }, {
    id: 'apne2',
    env: 'pca',
    envTld: 'apne2.pure.cloud',
    translationKey: 'components.region.apne2'
  }, {
    id: 'cac1',
    env: 'pca',
    envTld: 'cac1.pure.cloud',
    translationKey: 'components.region.cac1'
  }, {
    id: 'aps1',
    env: 'pca',
    envTld: 'aps1.pure.cloud',
    translationKey: 'components.region.aps1'
  }];

  _PC_DEPLOYMENTS.forEach(function (currRegion) {
    currRegion.hostnameRegExp = new RegExp("".concat(currRegion.envTld, "$"));
  });

  var PROD_REGIONS = _PC_DEPLOYMENTS.filter(function (curr) {
    return curr.env === 'pca';
  }).map(function (baseRegion) {
    var decoratedRegion = (0, _emberCopy.copy)(baseRegion, true);

    if (!decoratedRegion.desktopId) {
      decoratedRegion.desktopId = decoratedRegion.id;
    }

    delete decoratedRegion.env;
    delete decoratedRegion.hostnameRegExp;
    return decoratedRegion;
  });
  /**
  * Fetch the environment details for the current env (or provided string).  [Note:] This utility does
  * some validation of the string, but it is not intended to be a bulletproof URL parsing service.
  * You should only pass in known hostname[port][path][query][hash] strings.  Garbage in; Garbage out.
  *
  * @param {string} hostAndPath - A concatinated string of the host/hostname and path to parse
  *   for environmental details.  Defaults to window.location.hostname + window.location.pathname
  *
  * @returns {Object}  An object detilaing the environmental details parsed from the provided params
  * <code><pre>
  * {
  *   local: false, // true|false (Indicates a local dev environment which is proxied to a backend.  i.e. localhost or localhost.mypurecloud.com)
  *   envTld: The highest-order sub-domain for the determined PureCloud env (e.g. localhost, inindca.com, mypurecloud.com.au, or usw2.pure.cloud)
  *   env: null, // dca, tca, sca, pca
  *   region: null, // null|'us|au|ie|jp|de|usw2|euw2|apne2|cac1|aps1' (region will be non-null if env is pca)
  *   modifier: null, // null|'beta'
  * }
  * </pre></code>
  *
  * @throws Error instances if the the hostAndPath is not a known PC environemnt
  */


  _exports.PROD_REGIONS = PROD_REGIONS;

  function getEnvDetails() {
    var hostAndPath = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.hostname + window.location.pathname;
    return extractEnvDetails(hostAndPath, true);
  }
  /**
  * Fetch the environment's top-level-domain for the current env (or provided string).
  *
  * @param {string} hostname - The hostname to parse for the TLD.  Defaults to window.location.hostname
  *
  * @returns The environment tld which will be the highest-order sub-domain for the PureCloud env (e.g. inindca.com, mypurecloud.com.au, or usw2.pure.cloud)
  *
  * @throws Error instances if the the hostAndPath is not a known PC environemnt
  */


  function getEnvTld() {
    var hostname = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.hostname;
    return extractEnvDetails(hostname, false).envTld;
  }
  /**
  * Fetch the domain for an env tld cookie
  *
  * @param {string} hostname - The hostname to parse for the TLD.  Defaults to window.location.hostname
  *
  * @returns The domain for an env tld cookie which would be applicable for the PureCloud suite; .-prefixed.
  * localhost is a special case (e.g. '', .inindca.com, or .mypurecloud.com.au)
  *
  * @throws Error instances if the the hostname is not a known PC environemnt
  */


  function getEnvCookieDomain() {
    var hostname = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.hostname;
    var result = null;
    var envTld = getEnvTld(hostname);

    if (envTld === 'localhost') {
      result = '';
    } else {
      result = ".".concat(envTld);
    }

    return result;
  }
  /**
  * Determine if the provided url's domain is not within any of purecloud's known domains.
  *
  * @param {string} url - The url to check
  *
  * @returns true if the provided url is within any PureCloud envTld; false otherwise.
  *
  * @throws Error instances if the the url is not valid/parsable
  */


  function isExternalDomain(url) {
    if (!url || typeof url !== 'string') {
      throw new Error('url argument must be a non-empty string');
    }

    var result = true;
    var hostnameResults = HOSTNAME_EXTRACTOR.exec(url);

    if (hostnameResults && hostnameResults[1]) {
      try {
        var envDetails = extractEnvDetails(hostnameResults[1], false); // Known/Supported PC url;  but let localhost through

        result = envDetails.local && envDetails.envTld.indexOf('mypurecloud') < 0;
      } catch (unknownEnv) {
        // Not a known PC url
        result = true;
      }
    } else {
      throw new Error('Unparsable URL');
    }

    return result;
  }

  var _default = {
    PROD_REGIONS: PROD_REGIONS,
    getEnvDetails: getEnvDetails,
    getEnvTld: getEnvTld,
    getEnvCookieDomain: getEnvCookieDomain,
    isExternalDomain: isExternalDomain
  };
  _exports.default = _default;

  function extractEnvDetails(hostAndPath, usePathInfo) {
    if (!hostAndPath || typeof hostAndPath !== 'string') {
      throw new Error('hostAndPath argument must be a non-empty string');
    }

    var result = {
      local: false,
      envTld: null,
      env: null,
      region: null,
      modifier: null
    };
    var pcEnvFound = false;
    var locToParse = parseUrl(hostAndPath.trim());

    if (locToParse) {
      if (LOCALHOST_HOSTNAMES.indexOf(locToParse.hostname) >= 0) {
        // localhost match
        result.local = true;
        result.envTld = locToParse.hostname; // Attempt to detect the env from the path.  If no tgtEnv is found and usePathInfo is true, this will throw

        result.env = getTgtEnv(locToParse.pathname, usePathInfo);

        if (result.env === 'pca') {
          // Extract target region
          var badProxyPathMsg = "'".concat(hostAndPath, "' does not specify a valid, known PureCloud region for proxy purposes");
          var pathSegments = locToParse.pathname.trim().split('/'); // Use 0 or 1 depending if there's a leading slash

          var envRegionSegment = pathSegments[0] || pathSegments[1];

          if (!envRegionSegment) {
            throw new Error(badProxyPathMsg);
          }

          var tokens = envRegionSegment.trim().split('-');

          if (tokens[0] !== 'pca' || tokens.length > 2) {
            throw new Error(badProxyPathMsg);
          }

          if (tokens.length === 1) {
            result.region = 'us';
          } else {
            var knownRegion = PROD_REGIONS.find(function (region) {
              return region.id === tokens[1];
            });

            if (!knownRegion) {
              throw new Error(badProxyPathMsg);
            }

            result.region = knownRegion.id;
          }
        }

        pcEnvFound = true;
      } else {
        var matchedDeployments = _PC_DEPLOYMENTS.filter(function (currDeployment) {
          return currDeployment.hostnameRegExp.test(locToParse.hostname);
        });

        if (matchedDeployments.length === 0) {
          // Unknown purecloud deployment
          // Perform legacy check that matched a portion of a known deployment but the TLD was invalid
          if (LEGACY_TLD_CHECK_REG_EXP.test(locToParse.hostname)) {
            throw new Error('\'' + hostAndPath + '\' does not have a valid, known PureCloud TLD');
          }

          throw new Error('\'' + hostAndPath + '\' is not a valid, known PureCloud app location');
        }

        result.local = false;
        result.env = matchedDeployments[0].env;
        result.envTld = matchedDeployments[0].envTld;

        if (result.env === 'pca') {
          result.region = matchedDeployments[0].id;
        } else {
          // parse modifier from path
          result.modifier = getModifier(locToParse.pathname, usePathInfo);
        }

        pcEnvFound = true;
      }
    }

    if (!pcEnvFound) {
      throw new Error('\'' + hostAndPath + '\' is not a valid, known PureCloud app location');
    }

    return result;
  }

  var ABS_URL_PATTERN = /^\s*https?:\/\//;
  /**
   * Parses the provided string as a url.  If no protocol is provided, http: will
   * be prepended for parsing pursposes however, the returned protocol will be undefined
   *
   * @param {string} url A url-type string with optional leading protocol
   * @returns {object} An object containing the protocol, hostname, port, and pathname if the url is valid; null otherwise.
   */

  function parseUrl(url) {
    if (!url || typeof url !== 'string') {
      return null;
    }

    var parser = document.createElement('a');
    var mockProtocol = false;
    var absUrl = url;

    if (!ABS_URL_PATTERN.test(url)) {
      mockProtocol = true;
      absUrl = 'http://' + url.trim();
    }

    parser.href = absUrl;

    if (!parser.hostname) {
      return null;
    }

    var result = {
      protocol: mockProtocol ? undefined : parser.protocol,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname
    }; // Prepend a slash to make IE11 consistent with other browsers

    if (result.pathname.charAt(0) !== '/') {
      result.pathname = '/' + result.pathname;
    }

    return result;
  }

  function getTgtEnv(toCheck) {
    var throwOnMiss = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var result = null;

    if (toCheck) {
      SIMPLE_ENVS.forEach(function (currSimpleEnv) {
        if (toCheck.indexOf(currSimpleEnv) >= 0) {
          result = currSimpleEnv;
          return false;
        }
      });
    }

    if (result === null && throwOnMiss === true) {
      throw new Error("'".concat(toCheck, "' is not a known environment"));
    }

    return result;
  }

  function getModifier(toCheck) {
    var result = null;

    if (toCheck) {
      (0, _lodash.default)(MODIFIER_MAP, function (modifierId, modifierToken) {
        if (toCheck.indexOf(modifierToken) >= 0) {
          result = modifierId;
          return false;
        }
      });
    }

    return result;
  }
});