define('ember-cp-validations/-private/internal-result-object', ['exports', 'ember', 'ember-cp-validations/validations/error', 'ember-cp-validations/utils/utils'], function (exports, _ember, _emberCpValidationsValidationsError, _emberCpValidationsUtilsUtils) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isNone = _ember['default'].isNone;
  var computed = _ember['default'].computed;
  var canInvoke = _ember['default'].canInvoke;
  var makeArray = _ember['default'].makeArray;
  var and = computed.and;
  var not = computed.not;
  var readOnly = computed.readOnly;
  exports['default'] = _ember['default'].Object.extend({
    model: null,
    isValid: true,
    isValidating: false,
    message: null,
    attribute: '',

    attrValue: null,
    _promise: null,
    _validator: null,
    _type: readOnly('_validator._type'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('isAsync')) {
        this._handlePromise();
      }
    },

    isInvalid: not('isValid'),
    isNotValidating: not('isValidating'),
    isTruelyValid: and('isNotValidating', 'isValid'),

    isAsync: computed('_promise', function () {
      return (0, _emberCpValidationsUtilsUtils.isPromise)(get(this, '_promise'));
    }),

    isDirty: computed('attrValue', function () {
      var model = get(this, 'model');
      var attribute = get(this, 'attribute');
      var attrValue = get(this, 'attrValue');

      // Check default model values
      if ((0, _emberCpValidationsUtilsUtils.isDsModel)(model) && canInvoke(model, 'eachAttribute')) {
        var attrMeta = model.get('constructor.attributes').get(attribute);

        if (attrMeta) {
          var defaultValue = attrMeta.options.defaultValue;

          if (!isNone(defaultValue)) {
            return defaultValue !== attrValue;
          }
        }
      }
      return !isNone(attrValue);
    }),

    messages: computed('message', function () {
      return makeArray(get(this, 'message'));
    }),

    error: computed('isInvalid', 'type', 'message', 'attribute', function () {
      if (get(this, 'isInvalid')) {
        return _emberCpValidationsValidationsError['default'].create({
          type: get(this, '_type'),
          message: get(this, 'message'),
          attribute: get(this, 'attribute')
        });
      }

      return null;
    }),

    errors: computed('error', function () {
      return makeArray(get(this, 'error'));
    }),

    _handlePromise: function _handlePromise() {
      var _this = this;

      set(this, 'isValidating', true);

      get(this, '_promise')['finally'](function () {
        set(_this, 'isValidating', false);
      });
    }
  });
});
/**
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */