enifed('ember-template-compiler/plugins/transform-quoted-bindings-into-just-bindings', ['exports'], function (exports) {
  'use strict';

  exports.default = TransformQuotedBindingsIntoJustBindings;
  function TransformQuotedBindingsIntoJustBindings() {
    // set later within HTMLBars to the syntax package
    this.syntax = null;
  }

  /**
    @private
    @method transform
    @param {AST} ast The AST to be transformed.
  */
  TransformQuotedBindingsIntoJustBindings.prototype.transform = function (ast) {
    var walker = new this.syntax.Walker();

    walker.visit(ast, function (node) {
      if (!validate(node)) {
        return;
      }

      var styleAttr = getStyleAttr(node);

      if (!validStyleAttr(styleAttr)) {
        return;
      }

      styleAttr.value = styleAttr.value.parts[0];
    });

    return ast;
  };

  function validate(node) {
    return node.type === 'ElementNode';
  }

  function validStyleAttr(attr) {
    if (!attr) {
      return false;
    }

    var value = attr.value;

    if (!value || value.type !== 'ConcatStatement' || value.parts.length !== 1) {
      return false;
    }

    var onlyPart = value.parts[0];

    return onlyPart.type === 'MustacheStatement';
  }

  function getStyleAttr(node) {
    var attributes = node.attributes,
        i;

    for (i = 0; i < attributes.length; i++) {
      if (attributes[i].name === 'style') {
        return attributes[i];
      }
    }
  }
});