define("ember-purecloud-components/components/emoji-dom/component", ["exports", "ember-purecloud-components/components/emoji-dom/template", "ember-purecloud-components/utils/emoji"], function (_exports, _template, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scheduleEmojiDom: null,
    layout: _template.default,
    // When using the block form of this component updating the content will not
    // rerender the component (didRender will not trigger a second time).
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.set('scheduleEmojiDom', Ember.run.scheduleOnce('afterRender', this, this._emojiDom));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var scheduleEmojiDom = this.get('scheduleEmojiDom');

      if (scheduleEmojiDom) {
        Ember.run.cancel(scheduleEmojiDom);
      }
    },
    _emojiDom: function _emojiDom() {
      var $this = this.$();

      if (!$this) {
        return;
      }

      var node = $this.get(0);

      if (node) {
        (0, _emoji.emojifyDomUnicode)(node);
      }

      this.set('scheduleEmojiDom', null);
    }
  });

  _exports.default = _default;
});