define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/inline-video/component', ['exports', 'ember', 'ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/media-item/inline-video/template'], function (exports, _ember, _template) {
    'use strict';

    exports.__esModule = true;


    const { computed, Component } = _ember.default;

    function contains(string, substring) {
        return string.indexOf(substring) > -1;
    }

    const InlineVideoComponent = Component.extend({
        layout: _template.default,
        mediaItem: null,

        isVimeoWithTimestamp: computed('mediaItem.videoUrl', function () {
            const url = this.get('mediaItem.videoUrl');
            return contains(url, 'vimeo') && contains(url, '#t');
        }),

        didInsertElement() {
            this._super(...arguments);
            this.scrollOnLoad();
        },

        scrollOnLoad() {
            if (!this.get('mediaItem.collapsed')) {
                this.$('iframe').on('load', event => {
                    this._updateVideo(event.target);
                    this._safeScroll();
                }).on('error', () => {
                    this._safeScroll();
                });
            }
        },

        _updateVideo(iframe) {
            if (this.get('isVimeoWithTimestamp') && iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage(JSON.stringify({ method: 'pause' }), '*');
            }
        },

        _safeScroll() {
            _ember.default.run(() => {
                _ember.default.run.scheduleOnce('afterRender', () => {
                    this.bubbleAction({
                        action: 'safeScroll'
                    });
                });
            });
        }
    });

    exports.default = InlineVideoComponent;
});