define("ember-purecloud-components/components/toggle-switch/component", ["exports", "ember-purecloud-components/components/toggle-switch/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['toggle-switch-container'],
    classNameBindings: ['check:toggled-on:toggled-off', 'disabled'],
    check: false,
    disabled: false,
    tooltip: null,
    dataOn: 'purecloud-components.toggle.on',
    dataOff: 'purecloud-components.toggle.off',
    toggleParam: null,
    // for reference with actions
    entity: null,
    actions: {
      toggleRoomMute: function toggleRoomMute() {
        if (this.get('disabled')) {
          return;
        }

        if (this.get('toggle')) {
          this.sendAction('toggle', this.get('toggleParam'), this.get('entity'));
        } else {
          this.sendAction('toggleRoomMute', this.get('toggleParam'), this.get('entity'));
        }
      }
    },
    adjustWidth: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var label = this.$('span.off-screen');
        var width = label.width();
        this.$().width(width + 38);
      });
    })
  });

  _exports.default = _default;
});