define("ember-webrtc-components/mixins/device-enumeration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.diffListsByDeviceId = diffListsByDeviceId;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Mixin = Ember.Mixin,
      RSVP = Ember.RSVP,
      computed = Ember.computed,
      run = Ember.run;
  var UA = window.navigator.userAgent.toLowerCase();
  var IS_CHROME = !!window && !!window.chrome && !!window.chrome.webstore;
  var IS_FIREFOX = window && typeof InstallTrigger !== 'undefined';
  var BROWSER_VERSION;

  if (IS_CHROME) {
    BROWSER_VERSION = UA.match(/chrom(e|ium)/) && parseInt(UA.match(/chrom(e|ium)\/([0-9]+)\./)[2], 10);
  } else if (IS_FIREFOX) {
    BROWSER_VERSION = parseInt(UA.match(/firefox\/([0-9]+)\./)[1], 10);
  }

  function arrayComparerByDeviceId(otherArray) {
    return function (current) {
      var itemInOtherArray = otherArray.filter(function (other) {
        return other.deviceId === current.deviceId;
      }).length === 0;
      return itemInOtherArray;
    };
  }

  function diffListsByDeviceId(existingList, newList) {
    var itemsRemoved = existingList.filter(arrayComparerByDeviceId(newList));
    var itemsAdded = newList.filter(arrayComparerByDeviceId(existingList));
    return {
      added: itemsAdded,
      removed: itemsRemoved
    };
  }

  var _default = Mixin.create(Ember.Evented, {
    // options
    fullHd: false,
    canListDevices: false,
    // camera and video stuff
    hasCameraPermission: false,
    cameraList: Ember.A(),
    hasCamera: computed('cameraList.[]', function () {
      return !!Ember.A(this.get('cameraList')).find(function (camera) {
        return camera.deviceId !== 'default';
      });
    }),
    // mic and audio stuff
    hasMicPermission: false,
    microphoneList: Ember.A(),
    hasMicrophone: computed.notEmpty('microphoneList'),
    hasEnumeratedDevices: false,
    callCapable: computed.and('audioCallCapable', 'videoCallCapable'),
    audioCallCapable: computed(function () {
      var PC = window.RTCPeerConnection;
      var gUM = window.navigator && window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia;
      var supportWebAudio = window.AudioContext && window.AudioContext.prototype.createMediaStreamSource;
      var support = !!(PC && gUM && (supportWebAudio || window.RTCRtpTransceiver));
      return support;
    }),
    webrtcAudioCallIssueKey: computed('audioCallCapable', 'hasMicrophone', 'hasMicPermission', function () {
      var key;

      if (!this.get('audioCallCapable')) {
        key = 'ewc.station.notCapable';
      } else if (!this.get('hasMicPermission')) {
        key = 'ewc.station.noMicPermission';
      } else if (!this.get('hasMicrophone')) {
        key = 'ewc.station.noMicrophoneDetected';
      }

      return key;
    }),
    videoCallCapable: computed('audioCallCapable', function () {
      var audioCallCapable = this.get('audioCallCapable');

      if (!audioCallCapable) {
        return false;
      }

      var videoEl = document.createElement('video');
      var supportVp8 = videoEl && videoEl.canPlayType && videoEl.canPlayType('video/webm; codecs="vp8", vorbis') === 'probably';

      if (!supportVp8) {
        return false;
      }

      return true;
    }),
    outputDeviceList: Ember.A(),
    resolutionList: Ember.A(),
    canShareScreen: computed.reads('callCapable'),
    enumerationTimer: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var timer = run.next(this, function () {
        this.enumerateDevices();
        this.enumerateResolutions();
      });
      this.set('enumerationTimer', timer);

      if (window.navigator && window.navigator.mediaDevices && Object.prototype.hasOwnProperty.call(window.navigator.mediaDevices.constructor.prototype, 'ondevicechange')) {
        $(window.navigator.mediaDevices).on('devicechange', function () {
          if (_this.isDestroyed && _this.isDestroying) {
            return;
          }

          Ember.Logger.debug('onDeviceChange fired');
          /* reset device enumeration and enumerate again */

          if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
            _this.set('hasEnumeratedDevices', false);
          }

          Ember.run.debounce(_this, _this.enumerateDevices, true, 400);
        });
      }
      /* if we haven't loaded translation yet, hook into the load promise */


      if (!this.get('intl.loaded')) {
        this.get('intl.loadedPromise').then(function () {
          if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
            _this.set('hasEnumeratedDevices', false);
          }

          _this.enumerateDevices();
        });
      }
    },
    willDestroy: function willDestroy() {
      var timer = this.get('enumerationTimer');

      if (timer) {
        run.cancel(timer);
      }

      this._super.apply(this, arguments);
    },
    updateDefaultDevices: function updateDefaultDevices() {
      var extended = !!this._super.apply(this, arguments);

      if (!extended) {
        throw new Error('updateDefaultDevices should be overridden - do you need to save preferences or change video stream?');
      }
    },
    enumerateResolutions: function enumerateResolutions() {
      var resolutions = Ember.A();
      resolutions.pushObject(Ember.Object.create({
        label: this.get('intl').t('webrtcDevices.resolutions.low').toString(),
        presetId: 1,
        constraints: {
          video: {
            width: {
              max: 320
            },
            height: {
              max: 240
            }
          }
        }
      }));
      resolutions.pushObject(Ember.Object.create({
        label: this.get('intl').t('webrtcDevices.resolutions.medium').toString(),
        presetId: 2,
        constraints: {
          video: {
            width: {
              max: 640
            },
            height: {
              max: 480
            }
          }
        }
      }));
      var hd = Ember.Object.create({
        label: this.get('intl').t('webrtcDevices.resolutions.high').toString(),
        presetId: 3,
        constraints: {
          video: {
            width: {
              min: 640,
              ideal: 1280,
              max: 1920
            },
            height: {
              min: 480,
              ideal: 720,
              max: 1080
            }
          }
        }
      });
      resolutions.pushObject(hd); // full hd is disabled by default because very few computers actually support this

      if (this.get('fullHd')) {
        resolutions.pushObject(Ember.Object.create({
          label: this.get('intl').t('webrtcDevices.resolutions.fullHd').toString(),
          presetId: 4,
          constraints: {
            video: {
              width: {
                exact: 1920
              },
              height: {
                exact: 1080
              }
            }
          }
        }));
      }

      this.set('resolutionList', resolutions);
      return resolutions;
    },
    // Returns a promise which resolves when all devices have been enumerated and loaded
    enumerateDevices: function enumerateDevices(triggerListUpdatedEvent) {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!window.navigator.mediaDevices || !window.navigator.mediaDevices.enumerateDevices) {
        return Ember.RSVP.reject();
      } // if we have already enumerated devices successfully _and_ we have mic/camera permissions
      //  (ie. if labels are present) then we do not need to enumerate devices again


      if (this.get('hasEnumeratedDevices') && this.get('hasMicPermission')) {
        return Ember.RSVP.resolve();
      }

      var cameraCount = 0;
      var microphoneCount = 0;
      var outputDeviceCount = 0;
      var cameras = [];
      var microphones = [];
      var outputDevices = [];
      var defaultDevice = {
        deviceId: 'default',
        label: this.get('intl').t('webrtcDevices.default').toString()
      };

      var addCamera = function addCamera(device, hasBrowserLabel) {
        if (!hasBrowserLabel) {
          var existingDeviceInList = _this2.get('cameraList').find(function (camera) {
            return camera.deviceId === device.deviceId;
          });

          if (device.deviceId && device.deviceId.toLowerCase() === 'default') {
            device.label = _this2.get('intl').t('webrtcDevices.default').toString();
          } else if (device.deviceId && device.deviceId.toLowerCase().indexOf('communication') === 0) {
            device.label = _this2.get('intl').t('webrtcDevices.windowsCommunication').toString();
          }
          /* if the existing device does have a label, we will use that */


          if (existingDeviceInList && existingDeviceInList.label) {
            device.label = existingDeviceInList.label;
          }

          device.label = device.label || _this2.get('intl').t('webrtcDevices.cameraLabel', {
            number: ++cameraCount
          }).toString();
        }
        /* check to make sure the translation worked */


        if (device.label && device.label.startsWith('webrtcDevices')) {
          device.label = (device.label.includes('default') || device.label.includes('Communication') ? _this2.get('intl').t(device.label) : _this2.get('intl').t('webrtcDevices.cameraLabel', {
            number: ++cameraCount
          })).toString();
        }

        _this2.set('hasCameraPermission', !!(_this2.get('hasCameraPermission') || hasBrowserLabel));

        cameras.push(Ember.Object.create(device));
      };

      var addMicrophone = function addMicrophone(device, hasBrowserLabel) {
        var existingDeviceInList = _this2.get('microphoneList').find(function (mic) {
          return mic.deviceId === device.deviceId;
        });

        if (device.deviceId) {
          if (device.deviceId.toLowerCase() === 'default') {
            device.label = _this2.get('intl').t('webrtcDevices.default').toString();
          } else if (device.deviceId.toLowerCase() === 'communications') {
            device.label = _this2.get('intl').t('webrtcDevices.defaultCommunications').toString();
          }
        }

        if (!hasBrowserLabel) {
          if (existingDeviceInList && existingDeviceInList.label) {
            device.label = existingDeviceInList.label;
          }

          if (device.deviceId && device.deviceId.toLowerCase().indexOf('communication') === 0) {
            device.label = _this2.get('intl').t('webrtcDevices.windowsCommunication').toString();
          }

          device.label = device.label || _this2.get('intl').t('webrtcDevices.microphoneLabel', {
            number: ++microphoneCount
          }).toString();
        }
        /* if labels haven't loaded yet we need to get the translation */


        if (device.label && device.label.startsWith('webrtcDevices')) {
          device.label = (device.label.includes('default') || device.label.includes('Communication') ? _this2.get('intl').t(device.label) : _this2.get('intl').t('webrtcDevices.microphoneLabel', {
            number: ++microphoneCount
          })).toString();
        }

        _this2.set('hasMicPermission', !!(_this2.get('hasMicPermission') || hasBrowserLabel));

        microphones.push(Ember.Object.create(device));
      };

      var addOutputDevice = function addOutputDevice(device, hasLabel) {
        if (!Object.prototype.hasOwnProperty.call(window.HTMLMediaElement.prototype, 'setSinkId')) {
          return;
        }

        var existingDeviceInList = _this2.get('outputDeviceList').find(function (outputDevice) {
          return outputDevice.deviceId === device.deviceId;
        });

        if (device.deviceId) {
          if (device.deviceId.toLowerCase() === 'default') {
            device.label = _this2.get('intl').t('webrtcDevices.default').toString();
          } else if (device.deviceId.toLowerCase() === 'communications') {
            device.label = _this2.get('intl').t('webrtcDevices.defaultCommunications').toString();
          }
        }

        if (!hasLabel) {
          if (existingDeviceInList && existingDeviceInList.label) {
            device.label = existingDeviceInList.label;
          }

          device.label = device.label || _this2.get('intl').t('webrtcDevices.outputDeviceLabel', {
            number: ++outputDeviceCount
          }).toString();
        }

        if (device.label && device.label.startsWith('webrtcDevices')) {
          device.label = (device.label.includes('default') || device.label.includes('Communication') ? _this2.get('intl').t(device.label) : _this2.get('intl').t('webrtcDevices.outputDeviceLabel', {
            number: ++outputDeviceCount
          })).toString();
        }

        outputDevices.push(Ember.Object.create(device));
      }; // always add a dummy default for video, since the browser doesn't give us one like microphone


      if (this.get('callCapable')) {
        addCamera(defaultDevice, false);
      }

      return window.navigator.mediaDevices.enumerateDevices().then(function (devices) {
        Ember.Logger.info({
          message: 'webrtcDevices enumerated',
          devices: devices
        });

        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }

        if (IS_FIREFOX && BROWSER_VERSION < 42) {
          _this2.set('canListDevices', false);

          addMicrophone(defaultDevice);
        } else {
          _this2.set('canListDevices', true);

          _this2.setProperties({
            hasCameraPermission: false,
            hasMicPermission: false
          });

          devices.forEach(function (device) {
            var deviceInfo = {
              deviceId: device.deviceId,
              label: device.label,
              groupId: device.deviceId && device.deviceId.toLowerCase() === 'default' ? null : device.groupId
            };
            var hasLabel = device.label;

            if (device.kind === 'audioinput') {
              addMicrophone(deviceInfo, hasLabel);
            } else if (device.kind === 'audiooutput') {
              addOutputDevice(deviceInfo, hasLabel);
            } else if (device.kind === 'videoinput') {
              addCamera(deviceInfo, hasLabel);
            }
          });
        }

        var camerasDiff = diffListsByDeviceId(_this2.get('cameraList'), cameras);
        var microphonesDiff = diffListsByDeviceId(_this2.get('microphoneList'), microphones);
        var outputDevicesDiff = diffListsByDeviceId(_this2.get('outputDeviceList'), outputDevices);

        _this2.setProperties({
          cameraList: Ember.A(cameras),
          microphoneList: Ember.A(microphones),
          outputDeviceList: Ember.A(outputDevices)
        });
        /* if we have permissions after enumerating, we don't need to enumerate again */


        if (_this2.get('hasMicPermission') && _this2.get('hasCameraPermission')) {
          if (!_this2.get('isDestroyed') && !_this2.get('isDestroying')) {
            _this2.set('hasEnumeratedDevices', true);
          }
        }

        if (triggerListUpdatedEvent) {
          var added = {
            cameras: camerasDiff.added,
            microphones: microphonesDiff.added,
            outputDevices: outputDevicesDiff.added,
            total: camerasDiff.added.length + microphonesDiff.added.length + outputDevicesDiff.added.length
          };
          var removed = {
            cameras: camerasDiff.removed,
            microphones: microphonesDiff.removed,
            outputDevices: outputDevicesDiff.removed,
            total: camerasDiff.removed.length + microphonesDiff.removed.length + outputDevicesDiff.removed.length
          };

          _this2.trigger('deviceListsUpdated', {
            added: added,
            removed: removed
          });
        }
      }).catch(function (err) {
        if (!_this2.get('isDestroyed') && !_this2.get('isDestroying')) {
          Ember.Logger.error(err);

          _this2.set('canListDevices', false);

          addMicrophone(defaultDevice);
        }
      });
    },
    setOutputDevice: function setOutputDevice(el, device) {
      if (_typeof(device) !== 'object' || typeof device.deviceId === 'undefined' || device.deviceId === null) {
        return RSVP.Promise.reject('Cannot set null device');
      }

      var outputDevices = this.get('outputDeviceList');
      var outputDevice = outputDevices.findBy('deviceId', device.deviceId);

      if (!outputDevice) {
        return RSVP.Promise.reject(new Error('Cannot set output device: device not found'));
      }

      if (typeof el.setSinkId !== 'undefined') {
        return new RSVP.Promise(function (resolve) {
          if (el.paused) {
            el.onplay = function () {
              return resolve();
            };
          } else {
            resolve();
          }
        }).then(function () {
          el.setSinkId(device.deviceId);
        }).then(function () {
          Ember.Logger.log({
            message: 'webrtcDevices outputDevice set',
            device: {
              deviceId: device.deviceId,
              label: device.label
            },
            outputDevices: outputDevices.map(function (d) {
              return {
                deviceId: d.deviceId,
                label: device.label
              };
            })
          });
        }).catch(function (err) {
          Ember.Logger.error('failed to set audio output device', err);
        });
      } else {
        Ember.Logger.error('attempted to set sink id in unsupported browser');
        return RSVP.Promise.reject('Not supported');
      }
    },
    setDefaultOutputDevice: function setDefaultOutputDevice(el) {
      var device = this.get('defaultOutputDevice');

      if (device) {
        return this.setOutputDevice(el, this.get('defaultOutputDevice'));
      }

      return RSVP.Promise.resolve();
    }
  });

  _exports.default = _default;
});