define('ember-custom-fields-components/models/integer', ['exports', 'ember-custom-fields-components/models/number', 'ember-custom-fields-components/utils/field-validation'], function (exports, _number, _fieldValidation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Integer = _number.default.extend({
        isMinimumValid: Ember.computed('minimum', function () {
            return _fieldValidation.default.validateInteger(this.get('minimum'));
        }),

        isMaximumValid: Ember.computed('maximum', function () {
            return _fieldValidation.default.validateInteger(this.get('maximum'));
        })

    });

    exports.default = Integer;
});