enifed('ember-template-compiler/plugins/transform-dot-component-invocation', ['exports'], function (exports) {
  'use strict';

  exports.default = TransFormDotComponentInvocation;

  /**
    Transforms dot invocation of closure components to be wrapped
    with the component helper. This allows for a more static invocation
    of the component.
  
    ```handlebars
   {{#my-component as |comps|}}
    {{comp.dropdown isOpen=false}}
   {{/my-component}}
    ```
  
    with
  
    ```handlebars
    {{#my-component as |comps|}}
      {{component comp.dropdown isOpen=false}}
    {{/my-component}}
    ```
    and
  
    ```handlebars
   {{#my-component as |comps|}}
    {{comp.dropdown isOpen}}
   {{/my-component}}
    ```
  
    with
  
    ```handlebars
    {{#my-component as |comps|}}
      {{component comp.dropdown isOpen}}
    {{/my-component}}
    ```
  
    and
  
    ```handlebars
    {{#my-component as |comps|}}
      {{#comp.dropdown}}Open{{/comp.dropdown}}
    {{/my-component}}
    ```
  
    with
  
    ```handlebars
    {{#my-component as |comps|}}
      {{#component comp.dropdown}}Open{{/component}}
    {{/my-component}}
    ```
  
    @private
    @class TransFormDotComponentInvocation
  */
  function TransFormDotComponentInvocation() {
    // set later within Glimmer2 to the syntax package
    this.syntax = null;
  }

  TransFormDotComponentInvocation.prototype = {
    _isMulipartPath: function (path) {
      return path.parts.length > 1;
    },
    _isInlineInvocation: function (path, params, hash) {
      if (this._isMulipartPath(path)) {
        if (params.length > 0 || hash.pairs.length > 0) {
          return true;
        }
      }

      return false;
    },
    _wrapInComponent: function (node, builder) {
      var component = node.path;
      var componentHelper = builder.path('component');
      node.path = componentHelper;
      node.params.unshift(component);
    },
    transform: function (ast) {
      var _this = this;

      var _syntax = this.syntax,
          traverse = _syntax.traverse,
          b = _syntax.builders;

      traverse(ast, {
        MustacheStatement: function (node) {
          if (_this._isInlineInvocation(node.path, node.params, node.hash)) {
            _this._wrapInComponent(node, b);
          }
        },
        BlockStatement: function (node) {
          if (_this._isMulipartPath(node.path)) {
            _this._wrapInComponent(node, b);
          }
        }
      });

      return ast;
    }
  };
});