define('ember-engine-custom-fields/components/schema-editor/field-list/field-list-item/field-actions/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-list/field-list-item/field-actions/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias,
        not = computed.not,
        reads = computed.reads;
    exports.default = Component.extend({
        layout: _template.default,
        intl: inject.service(),
        classNames: ['field-actions'],

        field: null,
        enabledState: null,

        fieldIsDisabled: reads('field._disabled'),
        allowDelete: alias('field.isNew'),
        allowDisable: not('field.isNew'),
        tooltipText: computed('fieldIsDisabled', function () {
            var intl = this.get('intl');

            if (this.get('fieldIsDisabled')) {
                return intl.t('emberEngineCustomFields.tooltips.fieldEnableTooltip');
            } else {
                return intl.t('emberEngineCustomFields.tooltips.fieldDisableTooltip');
            }
        }),

        init: function init() {
            this._super();

            this.set('enabledState', !this.get('fieldIsDisabled'));
        },


        actions: {
            editField: function editField() {
                this.editField(this.get('field'));
            },
            deleteField: function deleteField() {
                this.deleteField(this.get('field'));
            },
            toggleEnabled: function toggleEnabled(toggleInfo) {
                this.setProperties({
                    enabledState: toggleInfo,
                    'field._disabled': toggleInfo === false
                });
            }
        }
    });
});