define('ember-custom-fields-components/components/custom-fields/controls/url/component', ['exports', 'ember-custom-fields-components/components/custom-fields/controls/text', 'ember-custom-fields-components/components/custom-fields/controls/url/template'], function (exports, _text, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _text.default.extend({
        layout: _template.default,

        minLengthError: 'emberCustomFieldsComponents.fieldErrors.minLengthURL',
        maxLengthError: 'emberCustomFieldsComponents.fieldErrors.maxLengthURL',

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var fieldKey = this.get('control.key');
            var savedData = this.get('fieldData.' + fieldKey) || null;

            if (savedData) {
                this.set('control.value', savedData);
            }
        }
    });
});