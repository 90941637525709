enifed('ember-template-compiler/plugins/transform-action-syntax', ['exports'], function (exports) {
  'use strict';

  exports.default = TransformActionSyntax;
  /**
   @module ember
   @submodule ember-glimmer
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   <button {{action 'foo'}}>
   <button onblur={{action 'foo'}}>
   <button onblur={{action (action 'foo') 'bar'}}>
    ```
  
    with
  
    ```handlebars
   <button {{action this 'foo'}}>
   <button onblur={{action this 'foo'}}>
   <button onblur={{action this (action this 'foo') 'bar'}}>
    ```
  
    @private
    @class TransformActionSyntax
  */

  function TransformActionSyntax() {
    // set later within Glimmer2 to the syntax package
    this.syntax = null;
  }

  /**
    @private
    @method transform
    @param {AST} ast The AST to be transformed.
  */
  TransformActionSyntax.prototype.transform = function (ast) {
    var _syntax = this.syntax,
        traverse = _syntax.traverse,
        b = _syntax.builders;

    traverse(ast, {
      ElementModifierStatement: function (node) {
        if (isAction(node)) {
          insertThisAsFirstParam(node, b);
        }
      },
      MustacheStatement: function (node) {
        if (isAction(node)) {
          insertThisAsFirstParam(node, b);
        }
      },
      SubExpression: function (node) {
        if (isAction(node)) {
          insertThisAsFirstParam(node, b);
        }
      }
    });

    return ast;
  };

  function isAction(node) {
    return node.path.original === 'action';
  }

  function insertThisAsFirstParam(node, builders) {
    node.params.unshift(builders.path('this'));
  }
});