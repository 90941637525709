define('ember-chat-components/components/chat-panel/conversation/conversation-pane/messages/widgets/base-widget-component', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;


    const BaseWidgetComponent = _ember.default.Component.extend({
        intl: _ember.default.inject.service(),

        message: null
    });

    exports.default = BaseWidgetComponent;
});