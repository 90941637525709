define("ember-purecloud-components/utils/code-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var whitespace = /\s+/g;
  var nonAlpha = /[^A-Za-z-]+/g;
  var _default = {
    generateClassName: function generateClassName(str) {
      var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      // When receiving a SafeString
      str = str.toString();
      var classValue = str.replace(whitespace, '-').replace(nonAlpha, '-').toLowerCase();
      return "".concat(prefix).concat(classValue);
    }
  };
  _exports.default = _default;
});