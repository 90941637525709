define("ember-webrtc-components/mixins/webrtc-service/webrtc-media", ["exports", "ember-purecloud-components/utils/browser", "localmedia", "ember-purecloud-components/mixins/logger"], function (_exports, _browser, _localmedia, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      RSVP = Ember.RSVP,
      run = Ember.run,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Mixin.create(_logger.default, {
    canListDevices: false,
    waitingForMedia: null,
    // this is so we can better tell if media permissions havent been given yet or if they are denied
    mediaRequestAnswered: false,
    localVideoNotReceived: false,
    orphanedScreenStreams: null,
    pendingCreateMediaPromise: null,
    notificationOutputDeviceId: computed.reads('defaultNotificationDevice.deviceId'),
    // default to true
    micAutoGain: computed('micPrefs.autoGain', {
      get: function get() {
        if (this.get('micPrefs.autoGain') !== undefined) {
          return this.get('micPrefs.autoGain');
        }

        return true;
      },
      set: function set(key, value) {
        this.updateMicPref('autoGain', value);
        return value;
      }
    }),
    // default to true
    echoCancellation: computed('micPrefs.echoCancellation', {
      get: function get() {
        if (this.get('micPrefs.echoCancellation') !== undefined) {
          return this.get('micPrefs.echoCancellation');
        }

        return true;
      },
      set: function set(key, value) {
        this.updateMicPref('echoCancellation', value);
        return value;
      }
    }),
    // default to true
    noiseSuppression: computed('micPrefs.noiseSuppression', {
      get: function get() {
        if (this.get('micPrefs.noiseSuppression') !== undefined) {
          return this.get('micPrefs.noiseSuppression');
        }

        return true;
      },
      set: function set(key, value) {
        this.updateMicPref('noiseSuppression', value);
        return value;
      }
    }),
    initializeRetryDelay: 2000,
    t: function t() {
      var _this$get;

      return (_this$get = this.get('intl')).t.apply(_this$get, arguments);
    },
    cameraList: Ember.A(),
    microphoneList: Ember.A(),
    outputDeviceList: Ember.A(),
    resolutionList: Ember.A(),
    _isInitialized: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('orphanedScreenStreams', Ember.A());
    },
    _createEmberDevice: function _createEmberDevice(device) {
      if (!device) {
        return null;
      }

      return Ember.Object.create({
        deviceId: device.deviceId,
        kind: device.kind,
        groupId: device.groupId,
        label: device.label
      });
    },
    defaultMicrophone: computed('deviceProfilesV2.microphone', function () {
      return this._createEmberDevice(this.get('deviceProfilesV2.microphone'));
    }),
    defaultCamera: computed('deviceProfilesV2.camera', function () {
      return this._createEmberDevice(this.get('deviceProfilesV2.camera'));
    }),
    defaultOutputDevice: computed('deviceProfilesV2.outputDevice', function () {
      return this._createEmberDevice(this.get('deviceProfilesV2.outputDevice'));
    }),
    defaultNotificationDevice: computed('deviceProfilesV2.notificationDevice', function () {
      return this._createEmberDevice(this.get('deviceProfilesV2.notificationDevice'));
    }),
    enumerateDevicesWhenAuthed: Ember.on('init', // eslint-disable-next-line ember/no-observers
    observer('session.isAuthenticated', function () {
      var _this = this;

      if (!Ember.testing && this.get('session.isAuthenticated')) {
        this.enumerateDevices().then(function () {
          return _this.ensureAudioPermissions();
        }).then(function () {
          return _this.ensureInitialized();
        });
      }
    })),
    updateMicPref: function updateMicPref(name, value) {
      this.logger.info("updating pref for ".concat(name, " to be ").concat(value));
      var micPrefs = this.get('micPrefs') || this.set('micPrefs', Ember.Object.create());
      micPrefs.set(name, value); // TODO: PCM-1545 remove this someday

      this.get('preferences').setPreference('webrtc.advancedMicControls', micPrefs); // update new webrtc settings

      var advancedMicSettings = micPrefs.getProperties(['autoGain', 'echoCancellation', 'noiseSuppression']);
      this.get('webrtcSettings').updateSetting('generalSettings.advancedMicSettings', advancedMicSettings);
    },
    endOrphanedScreenStreams: function endOrphanedScreenStreams() {
      this.get('orphanedScreenStreams').forEach(function (stream) {
        stream.getTracks().forEach(function (track) {
          return track.stop();
        });
      });
      this.set('orphanedScreenStreams', Ember.A());
    },
    ensureInitialized: function ensureInitialized() {
      var _this2 = this;

      if (this.get('_initializePromise')) {
        return this.get('_initializePromise');
      }

      if (this.isDestroyed || this.isDestroying || this.get('disableProactiveDevicePermissions')) {
        return RSVP.resolve();
      }

      var preferences = this.get('preferences');

      if (!preferences) {
        this.logger.warn('Preferences not available to webrtc service');
        return RSVP.resolve();
      }

      if (this.get('cameraList').length === 0 && this.get('microphoneList').length === 0) {
        return new RSVP.Promise(function (resolve, reject) {
          var initializeLater = run.later(_this2, function () {
            this.ensureInitialized().then(resolve).catch(reject);
          }, _this2.initializeRetryDelay);

          _this2.set('initializeAfterEnumeration', initializeLater);
        });
      }

      var resolutionPromise = preferences.getPreference('webrtc.resolutionPresetId').then(function (savedResolutionPresetId) {
        var resolutions = _this2.get('resolutionList');

        var savedResolution = resolutions.findBy('presetId', savedResolutionPresetId);

        _this2.set('defaultResolution', savedResolution || resolutions[resolutions.length - 1]);
      }).catch(function (err) {
        return _this2.logger.error('Error getting resolution preference:', {
          remoteData: {
            err: err
          }
        });
      });
      this.trigger('defaultDeviceChange');
      var micPreferencesPromise = RSVP.resolve();
      var promise = RSVP.all([resolutionPromise, micPreferencesPromise]).then(function () {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }

        _this2.set('_isInitialized', true);

        _this2.trigger('defaultDeviceChange');
      });
      this.set('_initializePromise', promise);
      return promise;
    },
    // eslint-disable-next-line ember/no-observers
    _micPrefsFromWebrtcSettings: observer('webrtcSettings.settings.generalSettings.advancedMicSettings', function () {
      var micSettings = this.get('webrtcSettings.settings.generalSettings.advancedMicSettings');
      this.set('micPrefs', Ember.Object.create(micSettings));
    }),
    resetDefaultDevices: function resetDefaultDevices() {
      this.updateDefaultDevices({
        camera: this.get('cameraList.firstObject'),
        microphone: this.get('microphoneList.firstObject')
      });
    },
    ensureAudioPermissions: function ensureAudioPermissions() {
      var _this3 = this;

      if (!this.get('hasMicPermission')) {
        var existingPendingPromise = this.get('pendingCreateMediaPromise');

        if (existingPendingPromise) {
          return existingPendingPromise;
        }

        var newPendingPromise = this.createMedia({
          audio: true,
          requestVideo: false
        }).then(function (media) {
          return _this3.enumerateDevices().then(function () {
            return media.localStreams.forEach(function (stream) {
              return media.stopStream(stream);
            });
          });
        });
        this.set('pendingCreateMediaPromise', newPendingPromise);
        return newPendingPromise;
      }

      return RSVP.resolve();
    },
    ensureFullMediaPermissions: function ensureFullMediaPermissions() {
      var _this4 = this;

      if (!this.get('hasMicPermission') || !this.get('hasCameraPermission')) {
        return this.createMedia({
          audio: true,
          video: true
        }).then(function (media) {
          return _this4.enumerateDevices().then(function () {
            return media.localStreams.forEach(function (stream) {
              return media.stopStream(stream);
            });
          });
        });
      }

      return RSVP.resolve();
    },
    updateDefaultDevices: function updateDefaultDevices(devices) {
      // logging error object so we get a stack trace
      this.logger.warn(new Error('`updateDefaultDevices` was called but we should be using only the sdk devices'));
      var camera = devices.camera,
          microphone = devices.microphone,
          resolution = devices.resolution,
          outputDevice = devices.outputDevice,
          notificationDevice = devices.notificationDevice;
      var devicesActuallyChanged = false;

      if (camera && this.get('defaultCamera.deviceId') !== camera.deviceId) {
        this.set('defaultCamera', camera);
        devicesActuallyChanged = true;
      }

      if (microphone && this.get('defaultMicrophone.deviceId') !== microphone.deviceId) {
        this.set('defaultMicrophone', microphone);
        devicesActuallyChanged = true;
      }

      if (resolution) {
        this.set('defaultResolution', resolution);
      }

      if (outputDevice && this.get('defaultOutputDevice.deviceId') !== outputDevice.deviceId) {
        this.set('defaultOutputDevice', outputDevice);
        devicesActuallyChanged = true;
      }

      if (notificationDevice && this.get('defaultNotificationDevice.deviceId') !== notificationDevice.deviceId) {
        this.set('defaultNotificationDevice', notificationDevice);
        devicesActuallyChanged = true;
      }

      if (devicesActuallyChanged) {
        this.trigger('defaultDeviceChange', devices);
      } // TODO: PCM-1545 we shouldn't need this because all this should be saved as part of the device profile. This seems like a relic
      // Ember.RSVP.Promise.resolve().then(() => {
      //   if (camera) {
      //     return preferences.setPreference('webrtc.cameraId', camera.getProperties('deviceId', 'label'));
      //   }
      // }).then(() => {
      //   if (microphone) {
      //     return preferences.setPreference('webrtc.microphoneId', microphone.getProperties('deviceId', 'label'));
      //   }
      // }).then(() => {
      //   if (resolution) {
      //     return preferences.setPreference('webrtc.resolutionPresetId', resolution.presetId);
      //   }
      // }).then(() => {
      //   if (outputDevice) {
      //     return preferences.setPreference('webrtc.outputDeviceId', outputDevice.getProperties('deviceId', 'label'));
      //   }
      // }).then(() => {
      //   if (notificationDevice) {
      //     return preferences.setPreference('webrtc.notificationDeviceId', notificationDevice.getProperties('deviceId', 'label'));
      //   }
      // });


      return true; // tell the addon this has been extended
    },
    getHostedScreenMedia: function getHostedScreenMedia(screenId) {
      return window.navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: screenId,
            maxFrameRate: 3
          }
        }
      });
    },

    /**
     * @param {} conversationId
     * @returns {
     *  stream: MediaStream,
     *  metas: [
     *    {
     *      trackId: string,
     *      screenId: string,
     *      originX: number,
     *      originY: number,
     *      resolutionX: number,
     *      resolutionY: number,
     *      primary: boolean,
     *      main: boolean
     *    }
     *  ]
     * }
     */
    getScreenRecordingMedia: function getScreenRecordingMedia(conversationId) {
      var _this5 = this;

      var metas = [];
      var stream;
      var streamPromise;

      if (this.get('allowScreenRecordingInBrowser')) {
        streamPromise = this._debugScreenRecordingInBrowser().then(function (s) {
          stream = s;
        });
      } else {
        streamPromise = this.get('application.hostedContext').getShareableScreens().then(function (screens) {
          screens = screens || []; // if not multimonitor, filter out the others

          if (!_this5.get('multiMonitorScreenRecording')) {
            screens = screens.filter(function (screen) {
              return screen.isPrimary;
            });
          }

          stream = _this5.getMediaStream();
          var promises = [];
          screens.forEach(function (screen) {
            var screenId = screen.shareid;

            var promise = _this5.getVideoTrackForScreenId(screenId, conversationId).then(function (track) {
              if (!track) {
                _this5.logger.info('No track received for screen', {
                  remoteData: {
                    screenId: screenId
                  }
                });

                return;
              }

              stream.addTrack(track);
              metas.push(_this5.createTrackMetadata(screen, track));
            });

            promises.push(promise);
          });
          return RSVP.all(promises).then(function () {
            if (!stream.getTracks().length) {
              _this5.logger.error('No tracks for screenRecording were created', {
                remoteData: {
                  conversationId: conversationId,
                  screens: screens
                }
              });

              throw new Error('Failed to start screen recording media');
            }
          });
        });
      }

      return streamPromise.then(function () {
        return {
          stream: stream,
          metas: metas
        };
      });
    },
    getMediaStream: function getMediaStream() {
      return new MediaStream();
    },
    createTrackMetadata: function createTrackMetadata(screenInfo, track) {
      var isWindows = navigator.platform.indexOf('Win') > -1; // purecloud-mac uses the bottom-left corner of the screen as its origin, with positive numbers representing higher origins
      // purecloud-directory-window uses the top-left corner of the screen as its origin, with positive numbers representing lower origins

      return {
        trackId: track.id,
        screenId: screenInfo.shareid,
        originX: screenInfo.x,
        originY: isWindows ? screenInfo.y : -1 * screenInfo.y,
        resolutionX: screenInfo.width,
        resolutionY: screenInfo.height,
        primary: !!screenInfo.isPrimary,
        main: !!screenInfo.isMain
      };
    },
    // tries 3 times to add a screen to the media stream
    getVideoTrackForScreenId: function getVideoTrackForScreenId(screenId, conversationId) {
      var _this6 = this;

      var attemptsRemaining = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
      return this.getHostedScreenMedia(screenId).catch(function (err) {
        if (!attemptsRemaining) {
          _this6.logger.error('Failed to initialize screen recording for screen', {
            remoteData: {
              screenId: screenId,
              conversationId: conversationId,
              error: err
            }
          });

          return;
        }
      }).then(function (stream) {
        if (stream && stream.getVideoTracks().length) {
          return stream.getVideoTracks()[0];
        }
      });
    },
    getMediaOptions: function getMediaOptions(options) {
      var constraints = {};
      options = options || {};
      options.camera = options.camera || this.get('defaultCamera');
      options.microphone = options.microphone || this.get('defaultMicrophone');
      options.resolution = options.resolution || this.get('defaultResolution');
      options.video = this.get('hasCamera') && this.get('videoCallCapable') && options.requestVideo !== false;
      options.audio = this.get('hasMicrophone') && this.get('audioCallCapable') && options.requestAudio !== false;

      if (!options.video && !options.audio) {
        this.logger.warn('Requesting media without audio or video. This is probably an error', {
          remoteData: {
            options: options,
            videoCallCapable: this.get('videoCallCapable'),
            audioCallCapable: this.get('audioCallCapable')
          }
        });

        if (options.requestAudio === false && options.requestVideo === false) {
          throw new Error('No Media requested');
        }

        throw new Error('Requesting media when not capable');
      }

      if (options.video === false || !this.get('videoCallCapable') && this.get('webrtc.hasCamera')) {
        constraints.video = false;
      } else {
        if (_browser.default.isChromeOrChromium) {
          constraints.video = {
            googNoiseReduction: true
          };
        } else {
          constraints.video = {};
        }

        var camera = options.camera;

        if (camera && camera.deviceId !== 'default') {
          constraints.video.deviceId = {
            exact: camera.deviceId
          };
        } else if (options.preferences) {
          constraints.video.deviceId = {
            exact: this.get('defaultCamera.deviceId')
          };
        }

        if (options.resolution) {
          Ember.assign(constraints.video, options.resolution.constraints.video);
        } else if (options.preferences) {
          Ember.assign(constraints.video, this.get('defaultResolution.constraints.video'));
        }
      }

      if (options.audio === false || !this.get('audioCallCapable') || !this.get('hasMicrophone')) {
        constraints.audio = false;
      } else {
        if (_browser.default.isChromeOrChromium) {
          constraints.audio = {
            googAudioMirroring: false,
            autoGainControl: this.get('micAutoGain'),
            echoCancellation: this.get('echoCancellation'),
            noiseSuppression: this.get('noiseSuppression'),
            googDucking: false,
            googHighpassFilter: true
          };
        } else {
          constraints.audio = {};
        }

        var microphone = options.microphone;

        if (microphone === true) {
          constraints.audio = true;
        } else if (microphone && microphone.deviceId !== 'default') {
          constraints.audio.deviceId = {
            exact: microphone.deviceId
          };
        } else if (options.preferences) {
          constraints.audio.deviceId = {
            exact: this.get('defaultMicrophone.deviceId')
          };
        }
      }

      return constraints;
    },
    getMediaErrorMessage: function getMediaErrorMessage(error) {
      if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
        return this.get('intl').t('ewc.browserMediaErrors.PermissionDeniedError').toString();
      } else if (error.name === 'ConstraintNotSatisfiedError') {
        return this.get('intl').t('ewc.browserMediaErrors.ConstraintNotSatisfiedError').toString();
      } else if (error.name === 'noVideoTrack') {
        return this.get('intl').t('ewc.noCameraDetected').toString();
      }

      return this.get('intl').t('ewc.browserMediaErrors.generalError').toString();
    },
    createMedia: function createMedia() {
      var _this7 = this;

      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var retries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      var promise = RSVP.resolve();

      if (config.requestVideo) {
        promise = this.get('webrtcSdkService').ensureVideoPermissions();
      }

      if (config.requestAudio) {
        promise = promise.then(function () {
          return _this7.get('webrtcSdkService').ensureAudioPermissions();
        });
      }

      return promise.then(function () {
        return new RSVP.Promise(function (resolve, reject) {
          _this7.set('waitingForMediaTimeout', run.later(function () {
            if (_this7.isDestroying || _this7.isDestroyed) {
              return;
            }

            _this7.set('waitingForMedia', !_this7.get('mediaRequestAnswered'));
          }, 2500));

          var media = new _localmedia.default({
            detectSpeakingEvents: true,
            harkOptions: {
              interval: 200
            }
          });
          var mediaOptions;

          try {
            mediaOptions = _this7.getMediaOptions({
              camera: config.camera,
              microphone: config.microphone,
              resolution: config.resolution,
              requestVideo: config.requestVideo,
              requestAudio: config.requestAudio
            });
          } catch (err) {
            return reject(err);
          }

          media.on('localStream', function () {
            if (_this7.get('waitingForMediaTimeout')) {
              run.cancel(_this7.get('waitingForMediaTimeout'));

              _this7.set('waitingForMediaTimeout', null);
            }

            _this7.set('waitingForMedia', false);

            _this7.set('mediaRequestAnswered', true);

            resolve(media);
          });
          media.start(mediaOptions, function (err) {
            _this7.enumerateDevices();

            if (!err) {
              return;
            }

            _this7.logger.error(err.message, {
              remoteData: {
                err: err
              }
            });

            if (err.name === 'NotFoundError' && retries > 0) {
              if (config.requestVideo !== false && !_this7.get('hasCameraPermission')) {
                _this7.logger.info('Browser does not have camera permissions, creating media without video');

                err.translationCode = 'ewc.noCameraPermissions';
              }

              if (config.requestAudio !== false && !_this7.get('hasMicPermission')) {
                _this7.logger.info('Browser does not have mic permissions, creating media without audio');

                err.translationCode = 'ewc.noMicPermissions';
              }

              return reject(err);
            }

            if (err.name === 'NotAllowedError') {
              if (mediaOptions.audio) {
                _this7.set('hasMicPermission', false);

                err.translationCode = 'ewc.noMicPermissions';
              }

              if (mediaOptions.video) {
                _this7.set('hasCameraPermission', false);

                err.translationCode = 'ewc.noCameraPermissions';
              }

              if (mediaOptions.audio && mediaOptions.video) {
                err.translationCode = 'ewc.devicePermissionsNeeded';
              }

              return reject(err);
            }

            if (err.name !== 'DevicesNotFoundError' && err.name !== 'OverconstrainedError' && err.constructor.name !== 'OverconstrainedError') {
              var error = new Error('Unknown UserMediaError');
              error.translation = _this7.getMediaErrorMessage(err);

              _this7.logger.error(err.message, {
                remoteData: {
                  err: err
                }
              });

              return reject(error);
            }

            _this7.logger.warn('user media error', {
              remoteData: {
                error: err
              }
            });

            if (err.name === 'OverconstrainedError' || err.constructor.name === 'OverconstrainedError') {
              if (config.requestVideo !== false) {
                if (retries > 0) {
                  config.resolution = {
                    constraints: {
                      video: {}
                    }
                  };
                  return _this7.createMedia(config, retries - 1).then(resolve).catch(reject);
                }

                _this7.logger.error('OverconstrainedError (retries exceeded) - video', {
                  remoteData: {
                    err: err
                  }
                });

                return reject(err);
              }

              if (!config.requestVideo && err.constraint === 'deviceId') {
                if (retries > 0) {
                  // error specifically with deviceId constraint for audio, try again with default microphone
                  config.microphone = true;
                  config.preferences = null;
                  return _this7.createMedia(config, retries - 1).then(resolve).catch(reject);
                }

                _this7.logger.error('OverconstrainedError (retries exceeded) - audio', {
                  remoteData: {
                    err: err
                  }
                });

                return reject(err);
              }

              _this7.logger.error('OverconstrainedError - general (no retry)', {
                remoteData: {
                  err: err
                }
              });

              return reject(err);
            } // DevicesNotFoundError means we should reset and try again with defaults


            if (_this7.get('waitingForMediaTimeout')) {
              run.cancel(_this7.get('waitingForMediaTimeout'));

              _this7.set('waitingForMediaTimeout', null);
            }

            _this7.resetDefaultDevices();

            config.camera = null;
            config.microphone = null;

            _this7.createMedia(config).then(resolve).catch(reject);
          });
        });
      });
    },
    handleScreenShareError: function handleScreenShareError(err, requestedTime) {
      this.showScreenShareError(requestedTime);
      this.logger.warn(err.message, {
        remoteData: {
          err: err
        }
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      run.cancel(this.get('waitingForMediaTimeout'));
      run.cancel(this.get('initializeAfterEnumeration'));
    }
  });

  _exports.default = _default;
});