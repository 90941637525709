define('ember-custom-fields-components/serializers/schema', ['exports', 'ember-data', 'ember-custom-fields-components/utils/field-util', 'ember-custom-fields-components/utils/schema-serializer-util'], function (exports, _emberData, _fieldUtil, _schemaSerializerUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            checkbox: { embedded: 'always' },
            date: { embedded: 'always' },
            datetime: { embedded: 'always' },
            enabled: { embedded: 'always' },
            enum: { embedded: 'always' },
            identifier: { embedded: 'always' },
            integer: { embedded: 'always' },
            longtext: { embedded: 'always' },
            number: { embedded: 'always' },
            tag: { embedded: 'always' },
            text: { embedded: 'always' },
            url: { embedded: 'always' }
        },

        normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
            var data = {};
            data[primaryModelClass.modelName] = payload;

            data[primaryModelClass.modelName].$schema = data[primaryModelClass.modelName].jsonSchema.$schema;
            data[primaryModelClass.modelName].description = data[primaryModelClass.modelName].jsonSchema.description;
            data[primaryModelClass.modelName].title = data[primaryModelClass.modelName].jsonSchema.title;

            var fields = _schemaSerializerUtil.default.transformFieldObjectToList(data[primaryModelClass.modelName].jsonSchema.properties);

            _fieldUtil.FIELD_TYPES.forEach(function (fieldType) {
                data[primaryModelClass.modelName][fieldType] = fields[fieldType];
            });

            return this._super(store, primaryModelClass, data, id, requestType);
        },
        normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
            var data = {};
            var type = primaryModelClass.modelName;

            if (payload.entities) {
                var _loop = function _loop(i) {
                    if (payload.entities[i].jsonSchema) {
                        payload.entities[i].$schema = payload.entities[i].jsonSchema.$schema;
                        payload.entities[i].description = payload.entities[i].jsonSchema.description;
                        payload.entities[i].title = payload.entities[i].jsonSchema.title;

                        var fields = _schemaSerializerUtil.default.transformFieldObjectToList(payload.entities[i].jsonSchema.properties);

                        _fieldUtil.FIELD_TYPES.forEach(function (fieldType) {
                            payload.entities[i][fieldType] = fields[fieldType];
                        });
                    }
                };

                for (var i = 0; i < payload.entities.length; i++) {
                    _loop(i);
                }

                data[type] = payload.entities;
                delete payload.entities;
            }

            payload = Ember.merge(data, { meta: payload });

            return this._super(store, primaryModelClass, payload, id, requestType);
        },


        // remove root node when posting to api
        serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
            Ember.merge(hash, this.serialize(record, options));
        },

        serialize: function serialize(record, options) {
            var json = this._super(record, options);
            json = _schemaSerializerUtil.default.transformFieldListToObject(json);

            delete json._entity;
            return json;
        }
    });
});