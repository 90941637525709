define('ember-softphone-integration/services/headset', ['exports', 'ember-softphone-integration/mixins/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      inject = Ember.inject,
      computed = Ember.computed,
      RSVP = Ember.RSVP,
      Evented = Ember.Evented,
      observer = Ember.observer;
  exports.default = Service.extend(Evented, _logger.default, {
    plantronics: inject.service('vendor-implementations/plantronics'),
    jabraChrome: inject.service('vendor-implementations/jabra-chrome'),
    jabraNative: inject.service('vendor-implementations/jabra-native'),
    sennheiser: inject.service('vendor-implementations/sennheiser'),
    session: inject.service(),
    intl: inject.service(),
    application: inject.service(),
    webrtc: inject.service(),
    webrtcSdkService: inject.service('webrtcSdk'),

    selectedImplementation: null,
    deviceMuted: computed.reads('selectedImplementation.isMuted'),
    deviceName: computed.reads('selectedImplementation.deviceName'),
    logHeadsetEvents: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._handleActiveMicChange();
    },


    implementations: computed(function () {
      var implementations = Ember.A();

      if (this.get('application.hostedContext').supportsJabra()) {
        if (this.get('application.hostedContext').isHosted()) {
          implementations.pushObject(this.get('jabraNative'));
        } else {
          implementations.pushObject(this.get('jabraChrome'));
        }
      }
      implementations.pushObject(this.get('plantronics'));
      implementations.pushObject(this.get('sennheiser'));
      return implementations;
    }),

    _handleActiveMicChange: observer('implementations.[]', 'webrtc.defaultMicrophone', 'webrtcSdkService.useSdk', function () {
      // we are active but we need to clean stuff up
      if (this.get('webrtcSdkService.useSdk')) {
        this.changeImplementation(null);
      } else {
        var label = this.get('webrtc.defaultMicrophone.label');

        var newImplementation = label && this.get('implementations').find(function (implementation) {
          return implementation.deviceLabelMatchesVendor(label);
        });

        this.changeImplementation(newImplementation);
      }
    }),

    // if possible, this should return information about the device
    // if not possible, return { deviceInfo: null }
    changeImplementation: function changeImplementation(implementation) {
      if (implementation === this.get('selectedImplementation')) {
        return;
      }

      if (this.get('selectedImplementation')) {
        this.get('selectedImplementation').disconnect();
      }

      this.set('selectedImplementation', implementation);

      if (implementation) {
        implementation.connect();
      }

      this.trigger('implementationChanged', implementation);
    },


    // possible options: conversationId, contactName
    incomingCall: function incomingCall(callInfo, hasOtherActiveCalls) {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        this.info('Headset: No vendor headset connected [incomingCall]', { conversationId: callInfo ? callInfo.conversationId : undefined });
        return RSVP.resolve();
      }

      this.info('Headset: call action [incomingCall]', { conversationId: callInfo ? callInfo.conversationId : undefined });

      return service.incomingCall(callInfo, hasOtherActiveCalls);
    },


    // possible options: conversationId, contactName
    outgoingCall: function outgoingCall(callInfo) {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        this.info('Headset: No vendor headset connected [outgoingCall]', { conversationId: callInfo ? callInfo.conversationId : undefined });
        return RSVP.resolve();
      }

      this.info('Headset: call action [outgoingCall]', { conversationId: callInfo ? callInfo.conversationId : undefined });

      return service.outgoingCall(callInfo);
    },
    answerCall: function answerCall(conversationId) {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        this.info('Headset: No vendor headset connected [answerCall]', { conversationId: conversationId });
        return RSVP.resolve();
      }

      this.info('Headset: call action [answerCall]', { conversationId: conversationId });

      return service.answerCall(conversationId);
    },
    setMute: function setMute(value) {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        this.info('Headset: No venddor headset connected [setMute]');
        return RSVP.resolve();
      }

      return service.setMute(value);
    },
    setHold: function setHold(conversationId, value) {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        return this.info('Headset: No vendor headset connected [setHold]', { conversationId: conversationId });
      }

      this.info('Headset: call action [setHold]', { conversationId: conversationId });

      return service.setHold(conversationId, value);
    },
    endCall: function endCall(conversationId, hasOtherActiveCalls) {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        this.info('Headset: No vendor headset connected [endCall]', { conversationId: conversationId, hasOtherActiveCalls: hasOtherActiveCalls });
        return RSVP.resolve();
      }

      this.info('Headset: call action [endCall]', { conversationId: conversationId, hasOtherActiveCalls: hasOtherActiveCalls });

      return service.endCall(conversationId, hasOtherActiveCalls);
    },
    endAllCalls: function endAllCalls() {
      var service = this.get('selectedImplementation');
      if (!service || !service.get('isConnected')) {
        this.info('Headset: No vendor headset connected [endAllCalls]');
        return RSVP.resolve();
      }

      this.info('Headset: call action [endAllCalls]');

      return this.get('selectedImplementation').endAllCalls();
    },
    triggerDeviceAnsweredCall: function triggerDeviceAnsweredCall() {
      this.info('Headset: device answered the call');
      this.trigger('deviceAnsweredCall');
    },
    triggerDeviceRejectedCall: function triggerDeviceRejectedCall(conversationId) {
      this.info('Headset: device rejected the call', { conversationId: conversationId });
      this.trigger('deviceRejectedCall', conversationId);
    },
    triggerDeviceEndedCall: function triggerDeviceEndedCall() {
      this.info('Headset: device ended the call');
      this.trigger('deviceEndedCall');
    },
    triggerDeviceMuteStatusChanged: function triggerDeviceMuteStatusChanged(isMuted) {
      this.info('Headset: device mute status changed', isMuted);
      this.trigger('deviceMuteStatusChanged', isMuted);
    },
    triggerDeviceHoldStatusChanged: function triggerDeviceHoldStatusChanged(holdRequested, toggle) {
      this.info('Headset: device hold status changed', holdRequested);
      this.trigger('deviceHoldStatusChanged', holdRequested, toggle);
    },
    triggerDefaultHeadsetChanged: function triggerDefaultHeadsetChanged(deviceInfo, isRetry) {
      var _this = this;

      this.info('Headset: headset device changed', deviceInfo);
      var microphones = this.get('webrtc.microphoneList').filter(function (device) {
        return deviceInfo.deviceIds.includes(device.deviceId);
      });
      var outputDevices = this.get('webrtc.outputDeviceList').filter(function (device) {
        return deviceInfo.deviceIds.includes(device.deviceId);
      });

      if (!microphones.length) {
        if (isRetry) {
          return this.error(new Error('Failed to find headset device'));
        }

        this.warn('Failed to find vendor headset device, will try again after browser devicechange event');

        this.get('webrtc').one('deviceListsUpdated', function () {
          _this.triggerDefaultHeadsetChanged(deviceInfo, true);
        });
        return;
      }

      this.get('webrtc').one('defaultDeviceChange', function () {
        _this.trigger('headsetChanged', deviceInfo);
      });
      this.get('webrtc').updateDefaultDevices({ microphone: microphones[0], outputDevice: outputDevices[0] });
    }
  });
});