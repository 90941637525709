define("ember-purecloud-components/services/iframe-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _setup: false,
    focused: false,
    setupFocusListener: function setupFocusListener() {
      var _this = this;

      if (this.get('_setup')) {
        return;
      }

      this.set('_setup', true);
      var guid = Ember.guidFor(this);
      Ember.$(window).on("focus.".concat(guid), Ember.run.bind(this, function () {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }

        _this.set('focused', false);
      })).on("blur.".concat(guid), Ember.run.bind(this, function () {
        if (!document || _this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }

        var nodeName = Ember.get(document, 'activeElement.nodeName');

        if (nodeName && nodeName.toLowerCase() === 'iframe') {
          _this.set('focused', true);
        }
      }));
    },
    teardownFocusListener: function teardownFocusListener() {
      var guid = Ember.guidFor(this);
      Ember.$(window).off(".".concat(guid));
      this.set('_setup', false);
    }
  });

  _exports.default = _default;
});