define("@ember-decorators/utils/-private/descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedDescriptorFor = computedDescriptorFor;
  _exports.isComputedDescriptor = isComputedDescriptor;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var DESCRIPTOR = '__DESCRIPTOR__';

  function isCPGetter(getter) {
    // Hack for descriptor traps, we want to be able to tell if the function
    // is a descriptor trap before we call it at all
    return getter !== null && typeof getter === 'function' && getter.toString().indexOf('CPGETTER_FUNCTION') !== -1;
  }

  function isDescriptorTrap(possibleDesc) {
    if (false
    /* HAS_DESCRIPTOR_TRAP */
    && false
    /* DEBUG */
    ) {
      return possibleDesc !== null && _typeof(possibleDesc) === 'object' && possibleDesc[DESCRIPTOR] !== undefined;
    } else {
      throw new Error('Cannot call `isDescriptorTrap` in production');
    }
  }

  function isComputedDescriptor(possibleDesc) {
    return possibleDesc !== null && _typeof(possibleDesc) === 'object' && possibleDesc.isDescriptor;
  }

  function computedDescriptorFor(obj, keyName) {
    (false && !(obj !== null) && Ember.assert('Cannot call `descriptorFor` on null', obj !== null));
    (false && !(obj !== undefined) && Ember.assert('Cannot call `descriptorFor` on undefined', obj !== undefined));
    (false && !(_typeof(obj) === 'object' || typeof obj === 'function') && Ember.assert("Cannot call `descriptorFor` on ".concat(_typeof(obj)), _typeof(obj) === 'object' || typeof obj === 'function'));

    if (false
    /* HAS_NATIVE_COMPUTED_GETTERS */
    ) {
      var meta = Ember.meta(obj);

      if (meta !== undefined && _typeof(meta._descriptors) === 'object') {
        // TODO: Just return the standard descriptor
        return meta._descriptors[keyName];
      }
    } else if (Object.hasOwnProperty.call(obj, keyName)) {
      var _Object$getOwnPropert = Object.getOwnPropertyDescriptor(obj, keyName),
          possibleDesc = _Object$getOwnPropert.value,
          possibleCPGetter = _Object$getOwnPropert.get;

      if (false
      /* DEBUG */
      && false
      /* HAS_DESCRIPTOR_TRAP */
      && isCPGetter(possibleCPGetter)) {
        possibleDesc = possibleCPGetter.call(obj);

        if (isDescriptorTrap(possibleDesc)) {
          return possibleDesc[DESCRIPTOR];
        }
      }

      return isComputedDescriptor(possibleDesc) ? possibleDesc : undefined;
    }
  }
});