define('ember-intl/formatters/format-message', ['exports', 'ember', 'intl-format-cache', 'intl-messageformat', 'ember-intl/formatters/-base'], function (exports, _ember, _intlFormatCache, _intlMessageformat, _emberIntlFormattersBase) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  var FormatMessage = _emberIntlFormattersBase['default'].extend({
    formatter: computed({
      get: function get() {
        return (0, _intlFormatCache['default'])(_intlMessageformat['default']);
      }
    }).readOnly(),

    format: function format(value) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var ctx = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var formats = ctx.formats;
      var locale = ctx.locale;

      var formatter = get(this, 'formatter');

      return formatter(value, locale, formats).format(options);
    }
  });

  exports['default'] = FormatMessage;
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */