define("ember-purecloud-components/adapters/conversation", ["exports", "ember-purecloud-components/adapters/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    namespace: 'api/v2',
    defaultSerializer: 'conversation',
    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      var type = relationship.type;

      switch (type) {
        case 'recording':
          return this.store.query('recording', {
            conversationId: snapshot.id
          });

        default:
          return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});