define('ember-engine-custom-fields/components/schema-editor/field-list/field-list-item/field-data/component', ['exports', 'ember-engine-custom-fields/components/schema-editor/field-list/field-list-item/field-data/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Component.extend({
        layout: _template.default,
        classNames: ['field-data'],

        intl: inject.service(),

        field: null,

        displayableType: computed('field.type', function () {
            var intl = this.get('intl'),
                type = this.get('field.type');
            return intl.t('emberEngineCustomFields.fieldType.' + type);
        })
    });
});