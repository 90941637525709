define("ember-webrtc-components/components/device-selection/component", ["exports", "ember-webrtc-components/components/device-selection/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend(
  /* LoggerMixin, */
  {
    layout: _template.default,
    classNameBindings: [':device-selection'],
    selectedCamera: null,
    selectedMicrophone: null,
    selectedResolution: null,
    selectedOutputDevice: null,
    selectedFilter: null,
    audio: true,
    video: true,
    troubleshoot: true,
    outputDevice: true,
    inputDevice: true,
    resolution: true,
    webrtc: inject.service(),
    audioCallCapable: computed.reads('webrtc.audioCallCapable'),
    videoCallCapable: computed.reads('webrtc.videoCallCapable'),
    // TODO: remove this when we can get an event from intl about translations being loaded
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.get('webrtc').enumerateDevices();

        _this.get('webrtc').enumerateResolutions();
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.send('changeCamera', {
        target: {
          value: this.get('selectedCamera.deviceId')
        }
      });
      this.send('changeMicrophone', {
        target: {
          value: this.get('selectedMicrophone.deviceId')
        }
      });
      this.send('changeResolution', {
        target: {
          value: this.get('selectedResolution.presetId')
        }
      });
      this.send('changeOutputDevice', {
        target: {
          value: this.get('selectedOutputDevice.deviceId')
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('video')) {
        this.set('advancedOptions', null);
      }
    },
    selectedCameraId: computed.reads('selectedCamera.deviceId'),
    selectedResolutionId: computed.reads('selectedResolution.presetId'),
    selectedMicrophoneId: computed.reads('selectedMicrophone.deviceId'),
    selectedOutputDeviceId: computed.reads('selectedOutputDevice.deviceId'),
    selectedNotificationDeviceId: computed.reads('selectedNotificationDevice.deviceId'),
    showTroubleshoot: computed('troubleshoot', function () {
      return this.get('troubleshoot') && typeof this.attrs.openTroubleshoot === 'function';
    }),
    showInputDevicePicker: computed.and('webrtc.microphoneList.length', 'inputDevice', 'audio'),
    showOutputDevicePicker: computed.and('outputDevice', 'audio'),
    showResolutionPicker: computed.and('webrtc.resolutionList.length', 'webrtc.cameraList.length', 'video', 'resolution'),
    actions: {
      openTroubleshoot: function openTroubleshoot() {
        if (typeof this.attrs.openTroubleshoot === 'function') {
          this.attrs.openTroubleshoot();
        }
      },
      changeCamera: function changeCamera(event) {
        var id = event.target.value;

        if (this.get('selectedCamera.deviceId') !== id) {
          this.set('selectedCamera', this.get('webrtc.cameraList').findBy('deviceId', id));
        }
      },
      changeMicrophone: function changeMicrophone(event) {
        var id = event.target.value;

        if (this.get('selectedMicrophone.deviceId') !== id) {
          this.set('selectedMicrophone', this.get('webrtc.microphoneList').findBy('deviceId', id));
        }
      },
      changeOutputDevice: function changeOutputDevice(event) {
        var id = event.target.value;

        if (this.get('selectedOutputDevice.deviceId') !== id) {
          var newDevice = this.get('webrtc.outputDeviceList').findBy('deviceId', id);
          this.set('selectedOutputDevice', newDevice);
        }
      },
      changeResolution: function changeResolution(event) {
        var id = event.target.value && parseInt(event.target.value, 10);

        if (this.get('selectedResolution.presetId') !== id) {
          this.set('selectedResolution', this.get('webrtc.resolutionList').findBy('presetId', id));
        }
      },
      changeFilter: function changeFilter(event) {
        var filter = event.target.value;
        this.set('selectedFilter', filter);
      }
    }
  });

  _exports.default = _default;
});