define("ember-purecloud-components/serializers/recording", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    keyForRelationship: function keyForRelationship(key, relationship) {
      if (relationship === 'belongsTo') {
        return "".concat(key, "Id");
      }
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass) {
      var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var id = arguments.length > 3 ? arguments[3] : undefined;
      var requestType = arguments.length > 4 ? arguments[4] : undefined;
      var data = {};
      data[primaryModelClass.modelName] = payload;
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass) {
      var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var id = arguments.length > 3 ? arguments[3] : undefined;
      var requestType = arguments.length > 4 ? arguments[4] : undefined;
      var data = {};
      data[primaryModelClass.modelName] = payload;
      payload = data;
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});