define('ember-custom-fields-components/adapters/schema', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        namespace: '',
        pathForType: function pathForType() {
            return 'schemas';
        },
        findAll: function findAll() {
            return this.ajax(this.namespace + '/' + this.pathForType() + '?expand=none', 'GET');
        },
        query: function query(store, type, _query) {
            return this.ajax(this.namespace + '/' + this.pathForType() + '?expand=none', 'GET', { data: _query });
        },
        createRecord: function createRecord(store, type, record) {
            var data = store.serializerFor(type.modelName).serialize(record, { includeId: false });
            return this.ajax(this.namespace + '/' + this.pathForType(), 'POST', { data: data });
        },
        findRecord: function findRecord(store, type, id) {
            return this.ajax(this.namespace + '/' + this.pathForType() + '/' + id + '?expand=none', 'GET');
        }
    });
});