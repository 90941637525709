define("ember-purecloud-components/components/audio-player-v2/timeline/component", ["exports", "ember-purecloud-components/components/audio-player-v2/timeline/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // config
    layout: _template.default,
    classNames: ['audio-player__timeline'],
    classNameBindings: ['isDarkTheme:audio-player__timeline--dark'],
    audioPlayerOptions: null,
    isDarkTheme: Ember.computed.equal('theme', 'dark'),
    percentComplete: Ember.computed('totalDuration', 'currentTime', function () {
      var totalDuration = this.get('totalDuration');
      var currentTime = this.get('currentTime');
      return totalDuration && totalDuration > 0 ? currentTime / totalDuration * 100 : 0;
    }),
    // event handlers
    click: function click(event) {
      var percentPosition = event.offsetX / this.element.clientWidth;
      this.sendAction('onPositionChange', percentPosition);
    }
  });

  _exports.default = _default;
});