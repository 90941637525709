define("ember-component-css/initializers/route-styles", ["exports", "ember-component-css/utils/init-route-styles"], function (_exports, _initRouteStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // This file is removed from the build in Ember 3.6+
  Ember.Router.reopen({
    didTransition: function didTransition(routes) {
      this._super.apply(this, arguments);

      (0, _initRouteStyles.default)(Ember.getOwner(this), routes.map(function (route) {
        return route.name;
      }));
    },
    intermediateTransitionTo: function intermediateTransitionTo() {
      this._super.apply(this, arguments);

      var routes = this._routerMicrolib.currentHandlerInfos;
      var routeNames = routes.map(function (route) {
        return route._handler.routeName.replace(/_loading$/, '-loading');
      });
      (0, _initRouteStyles.default)(Ember.getOwner(this), routeNames);
    }
  });

  function initialize() {}

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});