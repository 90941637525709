define('ember-chat-components/serializers/chat-search-result', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    exports.__esModule = true;


    const ChatSearchResultSerializer = _emberData.default.RESTSerializer.extend({
        isNewSerializerAPI: true,

        normalizeQueryResponse(store, primaryModelClass, payload) {
            if (Array.isArray(payload.results)) {
                // diamond search transform
                payload.entities = payload.results.map(result => {
                    const { _id: id, created, body, to, from } = result;
                    const createdDate = new Date(parseInt(created, 10)).toISOString();
                    return { id, createdDate, body, to, from };
                });
            }

            payload.entities = payload.entities || [];
            return this._super(...arguments);
        },

        modelNameFromPayloadKey(key) {
            const modelName = key === 'entities' ? 'chatSearchResults' : key;
            return this._super.apply(this, [modelName]);
        },

        extractMeta(store, modelClass, payload) {
            const metaObj = {};
            for (var prop in payload) {
                if (prop !== 'entities') {
                    metaObj[prop] = payload[prop];
                    delete payload[prop];
                }
            }
            return metaObj;
        }
    });

    exports.default = ChatSearchResultSerializer;
});