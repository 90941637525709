define('ember-intl/models/translation', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var deprecate = _ember['default'].deprecate;

  var TranslationModel = _ember['default'].Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.translations) {
        this.translations = {};
      }
    },

    /**
     * Add a single translation
     */
    addTranslation: function addTranslation(key, value) {
      set(this.translations, key, value);
    },

    /**
     * Adds a translation hash
     */
    addTranslations: function addTranslations(translationsObject) {
      for (var key in translationsObject) {
        if (translationsObject.hasOwnProperty(key)) {
          this.addTranslation(key, translationsObject[key]);
        }
      }
    },

    /**
     * Custom accessor hook that can be overridden.
     * This would enable consumers that have dot notated strings
     * to implement this function as `return this[key];`
     */
    getValue: function getValue(key) {
      var translation = get(this.translations, key);

      if (typeof translation === 'string') {
        return translation;
      }

      translation = get(this, key);

      if (typeof translation === 'string') {
        deprecate('[ember-intl] translations should be added via the `addTranslations`/`addTranslation` API.', false, {
          id: 'ember-intl-add-translation'
        });

        return translation;
      }
    },

    /**
     * Determines if the translation model contains a key
     */
    has: function has(key) {
      return typeof this.getValue(key) === 'string';
    }
  });

  exports['default'] = TranslationModel;
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */